import { NoDataDash, NoDataFallback } from '@common/components/NoDataFallback'
import { useInfrontInstrument } from '@common/hooks/infront/sdk/useInfrontInstrument'
import { InstrumentIdContainer } from '@common/instrumentIdContainer'
import { translateRegionCodes } from '@common/utils/translateRegionCodes'

import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Box,
  FlexCol,
  FlexRow,
  Icon,
  IconExternalLink,
  LinkExternal,
  ListItem,
  ListItemRow,
  SkeletonRect,
  Text,
} from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import { ListItemValue } from '../ListItemValue'

type InstrumentFundsCompanyInformationProps = {
  className?: string
  children?: ReactNode
  instrumentIdContainer: InstrumentIdContainer
}

export const InstrumentFundsCompanyInformation = observer(
  ({ className, instrumentIdContainer }: InstrumentFundsCompanyInformationProps) => {
    const { t } = useTranslation()

    const { observableInstrument, isLoading } = useInfrontInstrument(
      instrumentIdContainer?.infrontInstrument,
      [
        InfrontSDK.SymbolField.CompanyName,
        InfrontSDK.SymbolField.CountryOfIncorporation,
        InfrontSDK.SymbolField.CompanyAddress,
        InfrontSDK.SymbolField.CompanyPostalCode,
        InfrontSDK.SymbolField.CompanyCity,
        InfrontSDK.SymbolField.CompanyPhone,
        InfrontSDK.SymbolField.CompanyURL,
      ],
      { observe: false }
    )

    const companyName = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.CompanyName)
    const countryOfIncorperation = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.CountryOfIncorporation)
    const companyPhone = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.CompanyPhone)
    const companyURL = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.CompanyURL)

    const companyAddress = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.CompanyAddress)
    const companyPostalCode = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.CompanyPostalCode)
    const companyCity = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.CompanyCity)

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const address = `${companyAddress}, ${companyPostalCode} ${companyCity}`

    return (
      <div className={className}>
        <ListItem divider>
          <ListItemRow
            title={<Text variant="support2">{t('Fondbolag')}</Text>}
            value={<ListItemValue loading={isLoading} value={companyName} />}
          />
        </ListItem>
        <ListItem divider>
          <ListItemRow
            title={<Text variant="support2">{t('Legalt säte')}</Text>}
            value={
              <ListItemValue
                loading={isLoading}
                value={
                  translateRegionCodes(countryOfIncorperation) === 'Övriga'
                    ? undefined
                    : translateRegionCodes(countryOfIncorperation)
                }
              />
            }
          />
        </ListItem>
        <ListItem divider>
          <ListItemRow
            title={<Text variant="support2">{t('Adress')}</Text>}
            value={
              isLoading ? (
                <SkeletonRect width={100} height={40} />
              ) : (
                <FlexCol alignItems="flex-end">
                  <Text variant="subtitle2">
                    <NoDataFallback>{companyAddress}</NoDataFallback>
                  </Text>
                  <Text variant="subtitle2">
                    <NoDataFallback>{companyPostalCode}</NoDataFallback> <NoDataFallback>{companyCity}</NoDataFallback>
                  </Text>
                </FlexCol>
              )
            }
          />
        </ListItem>
        <ListItem divider>
          <ListItemRow
            title={<Text variant="support2">{t('Telefon')}</Text>}
            value={<ListItemValue loading={isLoading} value={companyPhone} />}
          />
        </ListItem>
        <ListItem divider>
          <ListItemRow
            title={<Text variant="support2">{t('Hemsida')}</Text>}
            value={
              isLoading ? (
                <SkeletonRect width={100} height={24} />
              ) : companyURL ? (
                <LinkExternal size="small" target="_blank" href={companyURL} title={companyURL}>
                  <FlexRow alignItems="center">
                    {companyURL}
                    <Box mr={8} />
                    <Icon color="current" icon={IconExternalLink} size={16} />
                  </FlexRow>
                </LinkExternal>
              ) : (
                <NoDataDash />
              )
            }
          />
        </ListItem>
      </div>
    )
  }
)
InstrumentFundsCompanyInformation.displayName = 'InstrumentFundsCompanyInformation'
