import { AccountPerformancePeriod, AccountPerformanceResponse } from '../api/response'
import { toSwedishTime } from '../utils/chart'
import { useHttpSwr } from './useHttpSwr'

interface ChartPoint {
  timestamp: Date
  changeSinceStartPercent: number
}

const defaultSeriesPoints: ChartPoint[] = [
  { timestamp: new Date(), changeSinceStartPercent: 0 },
  { timestamp: new Date(new Date().setDate(new Date().getDate() + 1)), changeSinceStartPercent: 0 },
]

export function useAccountPerformance(id: string, period: AccountPerformancePeriod): [number, number][] {
  const key = id && period ? `/accounts/${id}/performance/${period}` : null
  const { data } = useHttpSwr<{ items: AccountPerformanceResponse[] }>(key)
  return (data?.items || defaultSeriesPoints).map((pdp: AccountPerformanceResponse | ChartPoint) => {
    const timeSeriesPoint: [number, number] = [toSwedishTime(pdp.timestamp), pdp.changeSinceStartPercent]
    return timeSeriesPoint
  })
}
