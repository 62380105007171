import { useEffect, useRef } from 'react'

interface UseMutationObserverOptions extends MutationObserverInit {
  callback: MutationCallback
  mutationOptions?: {
    attributeFilter?: string[]
    attributeOldValue?: boolean
    attributes?: boolean
    characterData?: boolean
    characterDataOldValue?: boolean
    childList?: boolean
    subtree?: boolean
  }
}

const useMutationObserver = (options: UseMutationObserverOptions) => {
  const { callback, mutationOptions = { attributes: false, childList: true, subtree: true } } = options

  const targetRef = useRef<HTMLDivElement | null>(null)
  const observerRef = useRef<MutationObserver | null>(null)

  useEffect(() => {
    if (!targetRef.current) return

    const observer = new MutationObserver(callback)
    observerRef.current = observer

    observer.observe(targetRef.current, mutationOptions)

    return () => {
      observer.disconnect()
    }
  }, [callback, mutationOptions])

  return targetRef
}

export default useMutationObserver
