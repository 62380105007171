import { PaginationResponseOfDocumentInfoModel } from '@common/api/response'
import { useHttpSwr } from '@common/hooks/useHttpSwr'

import queryString from 'query-string'

export function useReportStatements(accountId: string, page?: number, pageSize?: number) {
  const request = {
    accountId,
    page,
    pageSize,
  }
  const params = queryString.stringify(request) //making query-params

  const key = `/reports/document/info?${params}`

  const { data, error } = useHttpSwr<PaginationResponseOfDocumentInfoModel>(accountId ? key : undefined)

  return {
    currentPage: data?.currentPage,
    error,
    items: data?.items,
    totalPages: data?.totalPages,
    totalRows: data?.totalRows,
    usingLimit: data?.usingLimit,
  }
}
