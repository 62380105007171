import React from 'react'

import { FlexRow, SkeletonRect } from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import { useInfrontFeedMetadata } from '../hooks/infront/sdk/useInfrontFeedMetadata'
import { InstrumentIdContainer } from '../instrumentIdContainer'
import { CountryCodeFlag } from './CountryCodeToFlag'

type InstrumentFlagProps = {
  instrumentIdContainer: InstrumentIdContainer
  size?: 'small' | 'medium'
}

export const InstrumentFlag = observer(({ instrumentIdContainer, size = 'medium' }: InstrumentFlagProps) => {
  const feedInfoResponseList = useInfrontFeedMetadata(
    instrumentIdContainer?.infrontInstrument?.feed ? [instrumentIdContainer.infrontInstrument.feed] : []
  )

  const languageCode = feedInfoResponseList?.length > 0 && feedInfoResponseList[0].isoCountry

  return (
    <FlexRow
      justifyContent="center"
      alignItems="center"
      height={size === 'medium' ? 24 : 16}
      width={size === 'medium' ? 24 : 16}
    >
      {languageCode ? (
        <CountryCodeFlag countryCode={languageCode} size={size === 'medium' ? 24 : 16} />
      ) : (
        <SkeletonRect css={size === 'medium' ? { width: 20, height: 17 } : { width: 13, height: 9 }} />
      )}
    </FlexRow>
  )
})
