import { PerformanceSeriesItem, PerformanceSeriesResponseItem } from '../api/response'
import { useHttpSwr } from './useHttpSwr'

export function useAccountsPerformanceSeries(): {
  accountsPerformance: PerformanceSeriesItem[]
  accountPerformanceIsLoading: boolean
} {
  const key = `/accounts/performance-series`
  const { data } = useHttpSwr<{ item: { accountsPerformanceSeries: PerformanceSeriesItem[] } }>(key)
  return {
    accountsPerformance: data?.item?.accountsPerformanceSeries,
    accountPerformanceIsLoading: data === undefined,
  }
}

export function useAccountPerformanceSeries(accountId: string): PerformanceSeriesResponseItem {
  const key = `/accounts/${accountId}/performance-series`
  const { data } = useHttpSwr<{ item: { accountsPerformanceSeries: PerformanceSeriesResponseItem[] } }>(key)
  return data?.item?.accountsPerformanceSeries?.[0]
}
