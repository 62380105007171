import { useOpenDrawer } from '@common/components/drawer/useOpenDrawer'

export function useOpenOfferingDrawer() {
  const { openDrawer } = useOpenDrawer()

  const openOfferingsDrawer = (offeringId: number) => {
    openDrawer('offering', offeringId?.toString())
  }

  return { openOfferingsDrawer }
}
