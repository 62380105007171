import { FormattedNumber } from '@common/components/FormattedNumber'
import { MoreButton } from '@common/components/MoreButton'
import { convertToInstrumentIdContainer } from '@common/instrumentIdContainer'

import { useTranslation } from 'react-i18next'

import { InfrontOrder, formatNumber } from '@carnegie/digital-channels-frontend'
import { FlexRow, IconTrashcan, ListItem, ListItemRow, MenuItem, SkeletonText, Text } from '@carnegie/duplo'

import { useOpenInstrumentDrawer } from '@/drawers/instrumentDrawer/useOpenInstrumentDrawer'

import { observer } from 'mobx-react-lite'

import { transformInfrontOrderStatus } from './CurrentOrders'
import { Edit } from './Edit'

type CurrentOrderRowMobileProps = {
  order: InfrontOrder
  deleteOrder: (orderId: string) => void
}

export const CurrentOrderRowMobile = observer(({ order, deleteOrder }: CurrentOrderRowMobileProps) => {
  const { t } = useTranslation()
  const { openInstrumentDrawer } = useOpenInstrumentDrawer()

  const {
    isBuyOrder,
    orderStatus,
    volumeFilled,
    volume,
    orderPrice,
    monetaryAmount,
    orderId,
    currency,
    fullName,
    isDeletable,
    symbolClassification,
    settlementCurrency,
  } = order

  const instrumentIdContainer = convertToInstrumentIdContainer(order.symbolId)

  const isFund = symbolClassification === InfrontSDK.SymbolClassification.Fund

  return (
    <ListItem
      divider
      onClick={() => {
        openInstrumentDrawer(instrumentIdContainer)
      }}
      action={
        isDeletable && (
          <MoreButton size="large" variant="uncontained">
            {() => [
              <Edit key="edit" order={order} size="medium" showMenuItem />,
              <MenuItem
                key="delete"
                onClick={() => {
                  if (orderId) deleteOrder(orderId.toString())
                }}
                icon={IconTrashcan}
              >
                {t('Ta bort order')}
              </MenuItem>,
            ]}
          </MoreButton>
        )
      }
    >
      <ListItemRow
        title={
          <FlexRow minWidth={0}>
            <Text mr={4} variant="subtitle1" color={isBuyOrder ? 'astral' : 'paprika-main'}>
              {isBuyOrder ? t('Köp') : t('Sälj')}
            </Text>
            <Text truncate variant="subtitle1">
              {fullName ? fullName : <SkeletonText width={80} />}
            </Text>
          </FlexRow>
        }
        value={transformInfrontOrderStatus(orderStatus)}
      />
      <ListItemRow
        title={
          <Text variant="overline">{`${formatNumber(volumeFilled, {
            decimals: { min: 0, max: 6 },
          })} / ${formatNumber(volume, { decimals: { min: 0, max: 6 } })}`}</Text>
        }
        value={
          <Text variant="overline">
            <FormattedNumber
              unit={isFund ? settlementCurrency : currency}
              value={isFund ? monetaryAmount : orderPrice}
              decimals={2}
              showZeroAsFallbackValue
            />
          </Text>
        }
      />
    </ListItem>
  )
})
