import { InfrontInstrument } from '@common/api/response'

import { useEffect } from 'react'

import { appInsights } from '@/appInsights'

import { makeObservable, observable, runInAction } from 'mobx'
import { useLocalObservable } from 'mobx-react-lite'
import { debounce } from 'throttle-debounce'

import { getFeedMetaData } from '../../../../drawers/newsDrawer/getFeedMetaData'
import { arrayInsert } from '../../../utils/arrayUtils'
import { isDevMode } from '../../useFeatures'
import { useInfrontSDK } from './useInfrontSdk'

// This is our own news object, we only include what we need here
export class NewsHeadline {
  headline: string
  source: string
  dateTime: Date
  id: string
  type: string[]

  infrontNewsHeadline: InfrontSDK.NewsHeadline

  constructor() {
    makeObservable(this, {
      headline: observable,
      source: observable,
      dateTime: observable,
      id: observable,
      type: observable,
    })
  }
}

class NewsStore {
  headlines: NewsHeadline[] = undefined

  constructor() {
    makeObservable(this, {
      headlines: observable,
    })
  }

  private async addNewsItem(
    sdk: InfrontSDK.SDK,
    infrontNewsHeadline: InfrontSDK.NewsHeadline,
    options: ObservableInfrontNewsOptions | undefined,
    index: number = -1
  ) {
    if (!this.headlines) this.headlines = []

    // We can filter it out even before doing work getting feed metadata etc
    if (options?.filterNewsHeadline && !options?.filterNewsHeadline(infrontNewsHeadline)) return

    const newsItem: NewsHeadline = new NewsHeadline()

    newsItem.headline = infrontNewsHeadline.headline
    newsItem.source = ''
    newsItem.dateTime = infrontNewsHeadline.dateTime
    newsItem.id = infrontNewsHeadline.id
    newsItem.infrontNewsHeadline = infrontNewsHeadline

    if (index >= 0) {
      arrayInsert(this.headlines, index, newsItem)
    } else {
      this.headlines.push(newsItem)
    }

    //  Get feed metadata
    const feedMetadata = await getFeedMetaData(sdk, [infrontNewsHeadline.feed])
    if (feedMetadata.length > 0) {
      newsItem.source = feedMetadata[0].description
      newsItem.type = feedMetadata[0].dataTypes
    }
  }

  // Needed for cleanup
  private initializeHadlinesDebounced: debounce<() => void> = undefined

  setHeadlinesAsInitilized = () => {
    this.initializeHadlinesDebounced?.cancel()

    this.initializeHadlinesDebounced = debounce(4000, () => {
      // Only do this if no data has been loaded

      if (!this.headlines) {
        appInsights.trackEvent({
          name: `NewsStore newsHeadlines timeout 4000ms`,
          properties: {
            description: `Initial load of news headlines from Infront took longer than 4000ms`,
            operation: 'infront timeout',
          },
        })
        this.headlines = []
      }
    })

    this.initializeHadlinesDebounced()
  }

  init = (
    sdk: InfrontSDK.SDK,
    source: InfrontInstrument[] | number[],
    infrontLimit: number,
    options?: ObservableInfrontNewsOptions
  ) => {
    this.dispose()

    if (source !== undefined && source.length > 0) {
      const sdkOptions: InfrontSDK.NewsHeadlinesOptions = {
        source: source, //|| 18045,
        limit: infrontLimit, // NOTE!: Because of a bug (or something) we usually get less than the limit, so say we want 9 items we may get 5 from infront, so right now we also need to limit this in the UI
        subscribe: true,
        onData: (news) => {
          const binding: Infront.IArrayBinding = {
            reInit: async (items: InfrontSDK.NewsHeadline[]) => {
              // We need this so we can know when loading data has stopped
              // If headlines is undefined we are loading but if it has a value (even an empty array) we have gotten data from infront

              // ***Note: This is debounced since a delay exists between this event firing and the first data coming in
              // by waiting a little bit we can avoid the UI going from loading -> empty list -> data in list
              this.setHeadlinesAsInitilized()

              runInAction(() => {
                for (const newsHeadline of items) {
                  this.addNewsItem(sdk, newsHeadline, options)
                }
              })
            },
            itemAdded: async (item: InfrontSDK.NewsHeadline) => {
              this.addNewsItem(sdk, item, options, 0)
            },
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            itemMoved: (item, fromIndex, toIndex) => {
              if (isDevMode()) throw new Error('News moved we must handle this')
            },
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            itemRemoved: (item, index) => {
              if (isDevMode()) throw new Error('News removed we must handle this')
            },
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            itemChanged: (item, index) => {
              if (isDevMode()) throw new Error('News changed we must handle this')
            },
          }
          news.observe(binding)
        },
        onError: (error: unknown) => {
          console.error('Error initializing sdk for news', error)
        },
      }

      this.sdkUnsubscribe = sdk.get(InfrontSDK.newsHeadlines(sdkOptions))

      // const feedIds = infrontInstrumentIds
      //   .map((infrontInstrumentId) => infrontInstrumentId.feed)
      //   .filter((feedNr) => feedNr !== undefined && feedNr !== null)

      // // We need feed metadata for stuff like iso country code
      // this.feedMetadataStore.init(sdk, feedIds, () => this.syncWithFeedMetadata())
    } else if (source !== undefined && source.length === 0) {
      this.headlines = []
    }

    this.initialized = true
  }

  private sdkUnsubscribe: InfrontSDK.Unsubscribe = undefined

  initialized: boolean = false

  //feedMetadataStore = new InfrontFeedMetadataStore()

  dispose = () => {
    this.headlines = undefined
    this.initializeHadlinesDebounced?.cancel()
    if (this.sdkUnsubscribe) this.sdkUnsubscribe()

    //this.instrumentsStore.unsubcribe()
    //this.feedMetadataStore.dispose()
  }
}

type ObservableInfrontNewsOptions = {
  filterNewsHeadline: (newsHeadline: InfrontSDK.NewsHeadline) => boolean
}

/**
 * ## ⚠️IMPORTANT⚠️
 * ### Requires wrapping component in MobX `observer(...)`
 */
export function useInfrontNews(
  source: InfrontInstrument[] | number[],
  maxItems: number | undefined,
  infrontLimit: number,
  options?: ObservableInfrontNewsOptions
) {
  const newsStore = useLocalObservable(() => new NewsStore())
  const { infrontSDK, error } = useInfrontSDK()

  useEffect(() => {
    if (infrontSDK) {
      newsStore.init(infrontSDK, source, infrontLimit, options)
    }

    return () => newsStore.dispose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infrontSDK, JSON.stringify(source), infrontLimit])

  return {
    headlines:
      newsStore.headlines !== undefined
        ? newsStore.headlines
            // We filter out feed 832 here as a workaround for Infront news server issues.
            .filter(({ infrontNewsHeadline }) => infrontNewsHeadline.feed !== 832)
            .slice(0, maxItems ? maxItems : newsStore.headlines.length)
        : newsStore.headlines,
    error,
  }
}
