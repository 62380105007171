import { GetAnalysisRequest } from '@common/api/response'
import { useApi } from '@common/stores/store'

import useSWR from 'swr'

export function useContentAnalysis(body: GetAnalysisRequest) {
  const api = useApi()

  const { data, error } = useSWR(
    body ? `content-analysis/${JSON.stringify(body)}` : undefined,
    () => api.fetchContentAnalysis(body),
    // No need to get data too often in this case
    { dedupingInterval: 30000 }
  )

  return {
    currentPage: data?.item?.currentPage,
    error,
    researchNews: data?.item?.researchNews,
    totalPages: data?.item?.totalPages,
    totalRecords: data?.item?.totalRecords,
  }
}
