import { createContext, useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import delay from 'delay'
import { action, computed, makeObservable, observable } from 'mobx'
import urlJoin from 'url-join'

import { NavigationStore } from '../../stores/navigationStore'

export type DrawerType = string

class Drawer {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: Record<string, any>
  open: boolean
  level: number
  type: string
  id: string

  // Passing props new way
  routeProps?: string

  close = () => {
    this.open = false
  }

  constructor() {
    makeObservable(this, {
      open: observable,
      level: observable,
      close: action,
    })
  }
}

type DrawerRouteParams = {
  drawerType: string
  arg?: string
}

export class DrawerStore {
  // Note: This can contain both opened and closed drawers, we can never remove closed drawers directly from this array since this would break their
  // "animate out" animations. To only deal with the currently opened drawers use the private openedDrawers array instead
  drawers: Drawer[] = []
  private idCounter = 0

  constructor() {
    makeObservable<DrawerStore, 'createDrawer' | 'removeClosedDrawersAfterAnimDelay' | 'syncWithUrl'>(this, {
      drawers: observable,
      drawersOpened: computed,
      closeAllDrawers: action,
      openDrawer: action,
      createDrawer: action,
      removeClosedDrawersAfterAnimDelay: action,
      syncWithUrl: action,
    })
  }

  private get openedDrawers() {
    return this.drawers.filter((d) => d.open)
  }

  private navigationStore: NavigationStore

  get drawersOpened() {
    return this.openedDrawers.length
  }

  private initialized = false

  public get isInitialized() {
    return this.initialized
  }

  init = (navigationStore) => {
    if (this.initialized) return

    this.navigationStore = navigationStore
    this.initialized = true

    // Parse route data
    this.syncWithUrl('url')
  }

  closeDrawer = (level: number) => {
    for (const drawer of this.drawers) {
      if (drawer.level === level) {
        drawer.close()
      }
    }

    drawerStore.syncWithUrl('drawerStore')

    // Once we are sure the animations have completed we can purge away all closed drawers
    this.removeClosedDrawersAfterAnimDelay()
  }

  closeAllDrawers = () => {
    for (const drawer of this.drawers) {
      drawer.close()
    }

    drawerStore.syncWithUrl('drawerStore')

    // Once we are sure the animations have completed we can purge away all closed drawers
    this.removeClosedDrawersAfterAnimDelay()
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  openDrawer = (type: DrawerType, routeProps?: string, props?: Record<string, any>) => {
    this.createDrawer(type, routeProps, props)
    this.syncWithUrl('drawerStore')
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private readonly createDrawer = (type: DrawerType, routeProps: string, props?: Record<string, any>) => {
    this.idCounter += 1
    const newDrawer = new Drawer()

    newDrawer.routeProps = routeProps
    newDrawer.props = props
    newDrawer.id = `drawer-${this.idCounter}`
    newDrawer.open = true
    newDrawer.open = true
    newDrawer.type = type

    // TODO: Move this logic out of drawerStore and implement max limit for a given drawer type
    // Only allow one order drawer at a time
    const openOrderDrawers = this.openedDrawers.filter((d) => d.type === 'order')

    if (openOrderDrawers.length >= 1 && newDrawer.type === 'order') {
      // Close all old ones
      openOrderDrawers.forEach((orderDrawer) => orderDrawer.close())
    }

    this.drawers.push(newDrawer)

    // More than 3 open? Close until we only have 3
    if (this.drawersOpened > 3) {
      const closeNrOfDrawers = this.drawersOpened - 3

      for (let i = 0; i < closeNrOfDrawers; i++) {
        this.openedDrawers[i].close()
      }
    }

    // Recalculate levels
    let level = 1
    for (const drawer of this.openedDrawers) {
      drawer.level = level
      level++
    }

    // Once we are sure the animations have completed we can purge away all closed drawers
    this.removeClosedDrawersAfterAnimDelay()
  }

  private readonly removeClosedDrawersAfterAnimDelay = async () => {
    await delay(1000)
    this.drawers = this.openedDrawers
  }

  private readonly getDrawerForLevel = (level: number) => {
    return this.openedDrawers.filter((d) => d.level === level)[0]
  }

  // When updating the url by calling syncWithUrl('drawerStore') we don't want to listen to url changes
  public ignoreUrlChanges = false

  private readonly levelNames = ['', 'd', 'd2', 'd3']

  public syncWithUrl = (sourceOfTruth: 'url' | 'drawerStore' = 'url') => {
    if (sourceOfTruth === 'url') {
      // Opened by refresh in browser / bookmark etc
      this.syncDrawerStoreWithUrl()
    } else if (sourceOfTruth === 'drawerStore') {
      // Opened by user interaction

      // In this case we have changed the internal state of the drawer store (changs drawers array etc)
      // and must simply sync back to the url
      this.syncUrlWithDrawerStore()
    }

    this.removeClosedDrawersAfterAnimDelay()
  }

  private syncUrlWithDrawerStore() {
    // The first drawer is always /d/ - split on that to get the base url
    const urlWithoutDrawers = window.location.pathname.split('/d/')[0]

    let finalUrl = urlWithoutDrawers

    // Ignore already closed dialogs
    for (const drawer of this.openedDrawers) {
      finalUrl = urlJoin(finalUrl, `/d${drawer.level > 1 ? drawer.level : ''}`, drawer.type, drawer.routeProps ?? '')
    }

    this.ignoreUrlChanges = true
    this.navigationStore.push(finalUrl, undefined, true)
    this.ignoreUrlChanges = false
  }

  private syncDrawerStoreWithUrl() {
    // Remove leading/trailing slashes and split path into segments
    const segments = window.location.pathname.split('/').filter(Boolean)

    // Iterate over each drawer level
    for (let level = 1; level <= 3; level++) {
      // Find the drawer marker for the current level
      const drawerMarker = `d${level > 1 ? level : ''}`
      const drawerIndex = segments.findIndex((segment) => segment === drawerMarker)

      if (drawerIndex !== -1 && drawerIndex + 1 < segments.length) {
        // Extract drawer type and arg
        const drawerParams: DrawerRouteParams = {
          drawerType: segments[drawerIndex + 1],
          arg: segments[drawerIndex + 2],
        }

        // Check if a drawer exists at this level
        const existingDrawer = this.getDrawerForLevel(level)
        const drawerRouteProps = drawerParams.arg

        if (!existingDrawer) {
          // Create a new drawer if none exists
          this.createDrawer(drawerParams.drawerType, drawerRouteProps)
        } else {
          // Update existing drawer if needed
          const existingDrawerUrl = existingDrawer.routeProps
          const newDrawerUrl = urlJoin(drawerParams.drawerType, drawerParams.arg ?? '')

          if (existingDrawerUrl !== newDrawerUrl) {
            existingDrawer.close()
            this.createDrawer(drawerParams.drawerType, drawerRouteProps)
          }
        }
      } else {
        // No drawer marker found at this level, close any existing drawer
        const existingDrawer = this.getDrawerForLevel(level)

        if (existingDrawer) {
          // Close the drawer if it exists but shouldn't
          const matchingDrawers = this.drawers.filter((d) => d.level === level)
          matchingDrawers.forEach((d) => d.close())
        }
      }
    }
  }
}

// Can be moved to context in the future if we wan't to make it prettier
const drawerStore = new DrawerStore()

export function useDrawerStore() {
  return drawerStore
}

export const ActiveDrawerContext = createContext<Drawer>(undefined)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useDrawerProps<TProps = any>() {
  const activeDrawer = useContext(ActiveDrawerContext)

  return { routeProps: activeDrawer.routeProps, props: activeDrawer.props as TProps }
}

// Create a new component to handle URL changes
type DrawerStoreListenerProps = {
  store: DrawerStore
}

export function DrawerStoreListener({ store }: DrawerStoreListenerProps) {
  const location = useLocation()

  useEffect(() => {
    if (!store.ignoreUrlChanges && store.isInitialized) {
      store.syncWithUrl()
    }
  }, [location, store])

  return null
}
