import { Breakpoint, Heading5, LinkExternal, Paragraph, useBreakpoint } from '@carnegie/duplo'

import parse, { Element, HTMLReactParserOptions, domToReact } from 'html-react-parser'

type Props = {
  html: string
}

export function CarnegieMaterialHtml({ html }: Props) {
  const breakpoint = useBreakpoint()

  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (domNode instanceof Element) {
        const { attribs, children, name } = domNode
        if (attribs?.class === 'cta') {
          return <></>
        }
        if (name === 'blockquote') {
          if (children && children?.length > 0) {
            children.forEach((child: Element) => {
              if (child.name === 'cite') {
                //style as H6
                child.attribs.style =
                  'font-weight: 500; font-family: CarnegieSans-Book; font-size: 20px; font-weight: 300; line-height: 24px;'
              }
            })
          }
          return (
            <Heading5 pt={16} pb={32}>
              {domToReact(children, options)}
            </Heading5>
          )
        }
        if (name === 'h1') return <></>
        if (name === 'p' || name === 'figure') {
          const child = children[0]
          if (child instanceof Element && child.name === 'strong') {
            child.attribs.style = 'font-weight: 500;'
          }
          return (
            <Paragraph variant="body1" mb={16}>
              {domToReact(children, options)}
            </Paragraph>
          )
        }
        if (name === 'text') return <div>{domToReact(children, options)}</div>
        if (name === 'ul') {
          attribs.style = 'list-style: disc outside; margin-bottom: 16px;'
        }
        if (name === 'li') {
          attribs.style = 'margin-left: 16px;'
        }
        if (name === 'iframe') {
          attribs.width = '100%'
          attribs.height = '100%'
          attribs.border = 'none'
        }

        if (attribs?.class?.includes('embed-message--youtube') && attribs['data-content']) {
          //Notice: this will replace all the youtube div children
          return <>{parse(attribs['data-content'], options)}</>
        }

        if (name === 'a') {
          const firstChild = children && children[0]

          return (
            <LinkExternal target="_blank" rel="noreferrer" href={attribs.href}>
              {firstChild instanceof Element && firstChild?.name === 'img' ? (
                <img
                  style={{ maxWidth: breakpoint < Breakpoint.Small ? 321 : 480 + 'px' }}
                  src={firstChild.attribs.src}
                  alt="img"
                  height={'auto'}
                  width={100 + '%'}
                ></img>
              ) : (
                domToReact(children, options)
              )}
            </LinkExternal>
          )
        }
      }
    },
  }
  return <>{parse(html, options)}</>
}
CarnegieMaterialHtml.displayName = 'CarnegieMaterialHtml'
