import { AccountsResponse, InfrontInstrument } from '@common/api/response'
import { InfrontTradingWidgetHandler } from '@common/components/InfrontTradingWidgetHandler'
import { useInfrontOrderEntry } from '@common/hooks/infront/widgets/useInfrontOrderEntry'
import useMutationObserver from '@common/hooks/infront/widgets/useMutationObserver'
import { fireTrackEvent } from '@common/utils/analyticsEvent'
import { rgbAlpha } from '@common/utils/colorUtils'
import { css } from '@emotion/react'

import { Ref, forwardRef, useEffect, useImperativeHandle, useRef } from 'react'
import { TFunction, useTranslation } from 'react-i18next'

import { formatNumber } from '@carnegie/digital-channels-frontend'
import { Banner, SkeletonRect, Spacer, colors, useDuploTheme } from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import { orderEntryTranslations } from './orderEntryTranslations'

type OrderEntryProps = {
  instrument: InfrontInstrument
  selectedAccount: AccountsResponse
  initialOrderId?: number
  onStateChanged: (state: Infront.OrderEntryState) => void
  renderForApp?: boolean
}

export type OrderEntryRef = {
  setPrice: (price: number) => void
}

export const OrderEntry = observer(
  forwardRef<OrderEntryRef, OrderEntryProps>((props, ref) => {
    const { t } = useTranslation()

    return (
      <InfrontTradingWidgetHandler
        tradingConnectingContent={<SkeletonRect height={336} />}
        expectedPortfolioName={props.selectedAccount.number}
        wrongPortfolioMessage={
          <Banner
            severity="critical"
            title={t('Något gick fel')}
            description={t('Det valda kontot är inte tillgängligt just nu. Vänligen försök igen.')}
          />
        }
        messageProps={{ p: 0 }}
      >
        <OrderEntryInternal ref={ref} {...props} />
      </InfrontTradingWidgetHandler>
    )
  })
)
OrderEntry.displayName = 'OrderEntry'

const OrderEntryInternal = observer(
  forwardRef<OrderEntryRef, OrderEntryProps>(
    ({ instrument, selectedAccount, onStateChanged, initialOrderId, renderForApp }: OrderEntryProps, ref) => {
      if (!selectedAccount) throw new Error('No account object set')

      const { t } = useTranslation()

      // *** Important info ***
      // Whenever the customOptions object changes we will destroy the old widget and create a new one (see deps array inside useInfrontOrderEntry)
      // to properly initialize everything.
      // To change things without doing this use the widget.modify method instead
      const { containerId, widget } = useInfrontOrderEntry({
        instrument,
        customOptions: {
          decimals: 0,

          // **** Important note: This (initialPortfolio) will "under the hood" set infront.setActivePortfolio(...)
          // **** So instead of setting it here we instead setActivePortfolio ourselves using the
          // **** useSyncActivePortfolio(...) hook
          // **** initialPortfolio: account?.number,
          modifyOrderId: initialOrderId ?? 0, // The default value of this one can never be "undefined" since I have found it can lead to crashes in Infronts code
          // Important note: This (modifyPortfolio) will also "under the hood" set infront.setActivePortfolio(...)
          // Important note 2: Even if we set infront.setActivePortfolio we must specify the modifyPortfolio also
          // when modifying an order otherwise the infront widget will not go into "edit mode"
          modifyPortfolio: initialOrderId ? selectedAccount.number : '', // The default value here should be "" and not undefined

          onTranslate: (message) => translateOrderEntryMessage(message, t),
          orderStatusChangeCallback: (state: Infront.OrderEntryState) => {
            if (state === Infront.OrderEntryState.ERROR) {
              fireTrackEvent('Order', 'order_error')
            }

            onStateChanged?.(state)
          },
        },
        accountId: selectedAccount.id,
        renderForApp: renderForApp,
      })

      // Some APIs like setting price is hard to do right with props (when do you "unset" the prop for example?) so instead we provide a ref that you can call methods on
      useImperativeHandle(
        ref,
        () => ({
          setPrice: (price) => {
            setOrderEntryPriceWorkaround(price)
          },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [widget, initialOrderId, selectedAccount, instrument?.feed, instrument?.ticker]
      )

      const widgetParentRef = useDisableInfrontInputScrollWheels(containerId)

      return (
        <>
          <WidgetWrapper ref={widgetParentRef} style={{ minHeight: 272 }}>
            <Spacer height={16} />
            <div id={containerId} />
          </WidgetWrapper>
        </>
      )
    }
  )
)
OrderEntryInternal.displayName = 'OrderEntryInternal'

function setOrderEntryPriceWorkaround(price: number) {
  // Workaround way

  // Find the order entry price input (requires opts.flexMode = true)
  const priceInput = document.querySelector<HTMLInputElement>('.cell-w-order-entry-flex__price-input > input')

  if (!priceInput) {
    console.error('Could not find price input field in order entry widget')
    return
  }

  if (!priceInput.disabled) {
    // We need to both focus and blur to make the Infront code respond to the change
    priceInput.focus()
    priceInput.value = formatNumber(price)
    priceInput.blur()
  }
}

const WidgetWrapper = forwardRef((props: React.HTMLAttributes<HTMLDivElement>, ref: Ref<HTMLDivElement>) => {
  const duploTheme = useDuploTheme()

  return (
    <div
      ref={ref}
      css={css`
        /* Removes the word "Strategy" from briefly popping up when drawing the widget, not sure when and why it is used, have asked Infront waiting for reply /Johan */
        .cell-w-order-entry-flex__input--strategy {
          display: none;
        }

        [class*='-input'] {
          input[type='text'],
          input[type='number'] {
            cursor: text;
            border-width: 1px;
            border-color: ${colors.bunker[60]};
            border-style: solid;
            border-radius: 4px;
            box-sizing: border-box;
            padding: 20px 0px 4px 8px;
            height: 40px;
            background-color: transparent;
            text-align: left !important;

            font-family: Roboto;
            font-size: 16px;
            line-height: 20px;
            font-weight: 400;

            &:focus {
              border-color: ${colors.viking};
            }
          }

          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          input[type='number'] {
            -moz-appearance: textfield;
          }

          input[disabled] {
            background-color: ${colors.bunker[10]} !important;
            border-color: ${colors.bunker[20]} !important;
            color: ${colors.bunker[100]} !important;
            -webkit-text-fill-color: ${colors.bunker[100]} !important;
            opacity: 1;

            &::placeholder {
              color: ${colors.bunker[100]} !important;
            }
          }
        }

        select {
          border-width: 1px;
          border-color: ${colors.bunker[60]};
          border-style: solid;
          border-radius: 4px;
          box-sizing: border-box;
          background-color: ${colors.offWhite};
          padding: 0 0 0 8px;
          height: 40px;
          color: ${colors.bunker.main};
          text-align: left !important;

          font-family: Roboto;
          font-size: 13px;
          line-height: 20px;
          font-weight: 400;

          &:focus {
            border-color: ${colors.viking};
            background-color: rgba(104, 172, 220, 0.08);
          }
        }

        .cell-w-order-entry-flex__panel {
          background-color: transparent;
          padding: 0;
          margin-right: -16px;

          // -- Only needed when we hide widgets portfolio-selector
          position: relative;
          top: -16px;
          // --

          .cell-h4 {
            display: none;
          }
        }

        .cell-w-order-entry-flex__price {
          .cell-w-order-entry-flex__input--ccy {
            right: 8px;
            top: 9px;
            height: 40px;
            display: flex;
            align-items: center;
          }
        }

        .cell-w-order-entry-flex__volume,
        .cell-w-order-entry-flex__price,
        .cell-w-order-entry-flex__input--validity-date {
          position: relative;

          &-label {
            position: absolute;
            top: 0;
            left: 15px;
            width: 100%;
            font-size: 10px;
            letter-spacing: 0.4px;
            color: ${duploTheme.colors['text-low-emphasis']};
            z-index: 1;
          }
        }

        .cell-w-order-entry-flex__input--validity-date {
          margin-top: 16px;
        }

        .cell-w-order-entry__feedback {
          flex-direction: column;
          background-color: ${colors.hover.primary};
          margin: 16px 0;
          border-left: 4px solid ${colors.astral};
          padding: 10px 16px;

          .cell-w-order-entry__feedback__header {
            margin: 0;
            font-family: Roboto;
            font-size: 15px;
            line-height: 20px;
            font-weight: 500;
          }
          .cell-w-order-entry__feedback__body {
            margin: 0;
            font-family: Roboto;
            font-size: 15px;
            line-height: 24px;
            font-weight: 400;
          }
          .cell-w-order-entry__feedback__error {
            margin: 0;
          }
        }
        .cell-w-order-entry-flex__search {
          display: none;
        }
        //Hide advanced mode input fields completely
        .cell-w-order-entry-flex__panel--advanced {
          display: none;
        }
        .cell-w-order-entry-flex__volume,
        .cell-w-order-entry-flex__price {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 50%;
          flex: 0 0 50%;
          max-width: 50%;
        }

        .cell-w-order-entry-flex__values-row {
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -ms-flex-flow: row wrap;
          flex-flow: row wrap;

          .cell-w-order-entry-flex__data-group:nth-of-type(1) {
            display: none;
          }
        }

        .cell-w-order-entry-flex__data-group {
          margin-top: 16px;
        }

        .cell-w-order-entry-flex__data-group:nth-of-type(2) {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 100%;
          flex: 0 0 100%;
          max-width: 100%;
          -webkit-box-ordinal-group: 2;
          -ms-flex-order: 2;
          order: 2;
        }

        .cell-w-order-entry-flex__data-group:nth-of-type(3) {
          -webkit-box-ordinal-group: 3;
          -ms-flex-order: 3;
          order: 3;
        }

        .cell-w-order-entry-flex__data-group:nth-of-type(4) {
          -webkit-box-ordinal-group: 4;
          -ms-flex-order: 4;
          order: 4;
        }

        .cell-w-order-entry-flex__data-group .cell-flex-row {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
        }

        .cell-flex-column {
          padding-left: 8px;
          padding-right: 8px;
        }

        .cell-flex-row {
          margin-left: -8px;
          margin-right: -8px;
        }

        .cell-w-order-entry-flex__data-group .cell-flex-row .cell-flex-column {
          text-align: left;
          color: ${colors.bunker['main']};
        }

        .cell-w-order-entry-flex__values-row .cell-w-order-entry-flex__data-group .cell-flex-row .cell-flex-column {
          display: flex;
          align-items: baseline;

          span:first-of-type {
            opacity: 1;
            order: 1;
            margin-left: 2px;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
          }
        }

        .cell-w-order-entry-flex__button-row {
          margin-top: 16px;
        }

        .cell-button {
          font-family: Roboto;
          font-size: 15px;
          line-height: 24px;
          font-weight: 400;
          padding: 0px;

          color: ${colors.bunker['main']};

          border-radius: 4px;
          height: 40px;
          border: none;
          background-color: ${colors.regentStreetBlue.main};
          text-transform: none;

          &:hover {
            background-color: ${colors.regentStreetBlue[700]};
            transition: background-color 0.2s ease-in-out;
          }

          &--positive,
          &--confirm-buy {
            font-family: Roboto;
            font-size: 15px;
            line-height: 24px;
            font-weight: 400;

            color: ${colors.carnegiePink};

            border-radius: 4px;
            height: 40px;
            border: none;
            background-color: ${colors.astral};
            text-transform: none;

            &:hover {
              background-color: rgb(31 69 97);
              transition: background-color 0.2s ease-in-out;
            }
          }

          &--negative,
          &--confirm-sell {
            font-family: Roboto;
            font-size: 15px;
            line-height: 24px;
            font-weight: 400;

            color: ${colors.carnegiePink};

            border-radius: 4px;
            height: 40px;
            border: none;
            background-color: ${colors.shiraz};
            text-transform: none;

            &:hover {
              background-color: rgb(133 9 45);
              transition: background-color 0.2s ease-in-out;
            }
          }

          &--cancel,
          &--neutral {
            font-family: Roboto;
            font-size: 15px;
            line-height: 24px;
            font-weight: 400;

            border-radius: 4px;
            height: 40px;
            border: none;
            background-color: ${colors.regentStreetBlue.main};
            text-transform: none;
            border: 1px solid ${colors.buttons.secondaryBorderColor};
            background: transparent;
            transition: background-color 0.2s ease-in-out;

            &:not([disabled]):hover {
              background-color: ${rgbAlpha(colors.regentStreetBlue.main, 0.16)};
              transition: background-color 0.2s ease-in-out;
            }
          }
        }
        .cell-button--disabled,
        .cell-button--disabled:hover {
          background-color: ${colors.bunker[10]} !important;
          border-color: ${colors.bunker[10]} !important;
          color: ${colors.bunker[60]}!important;
        }
        .cell-label {
          font-family: Roboto;
          font-size: 15px;
          letter-spacing: 0.4px;
          line-height: 16px;
          font-weight: 400;
          color: ${duploTheme.colors['text-low-emphasis']};
          margin-bottom: ${duploTheme.space[4]};
        }

        .cell-w-order-entry-flex span {
          font-family: Roboto;
          font-size: 15px;
          line-height: 20px;
          font-weight: 500;
        }

        .cell-w-date-selector {
          border-radius: 4px;
          border: 0.5px solid ${colors.bunker[20]};
          background-color: ${colors.offWhite};
          margin-top: 17px; /* Standard 16px + 1px for offset of border */
          box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12),
            0px 2px 4px -1px rgba(0, 0, 0, 0.2);
        }
        .cell-w-date-selector__top {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        .cell-w-date-selector__month-label {
          float: none;
          font-family: Roboto;
          font-size: 12px;
          letter-spacing: 0.4px;
          line-height: 16px;
          font-weight: 400;
        }
        .cell-w-date-selector__arrow-left,
        .cell-w-date-selector__arrow-right {
          border-bottom-color: ${colors.bunker[200]};
        }
        .cell-w-date-selector__table__row__th {
          font-family: Roboto;
          font-size: 10px;
          letter-spacing: 0.4px;
          line-height: 16px;
          font-weight: 400;
          font-weight: normal;
        }
        .cell-w-date-selector__table__row__td {
          font-family: Roboto;
          font-size: 10px;
          letter-spacing: 0.4px;
          line-height: 16px;
          font-weight: 400;
          padding: 0;
          font-weight: normal;
          &:hover {
            background-color: rgba(214, 229, 240, 0.16);
          }
        }
        .cell-w-date-selector__table__row__td--disabled {
          color: ${colors.bunker[60]}!important;
        }
        .cell-w-date-selector__table__row__td--selected {
          background: rgba(214, 229, 240, 0.16);
          border-radius: 0px;
          border: 1px solid ${colors.viking};
          box-shadow: 0px 0px 4px 0px ${colors.viking};
        }

        div.cell-flex-row.cell-w-order-entry-flex__values-row
          > div:nth-of-type(3)
          > div
          > div:nth-of-type(2)
          > span.cell-3-padding-right.cell-opacity-50 {
          display: none;
        }

        .cell-flex-row.cell-w-order-entry-flex__values-row
          > div:nth-of-type(3)
          > div
          > div:nth-of-type(2)
          > span:nth-of-type(2) {
          cursor: pointer;
        }

        //causing overflow which is reeking havoc in mobile

        .cell-w-order-entry-flex__input--validity-date-label,
        .cell-w-order-entry-flex__price-label,
        .cell-w-order-entry-flex__volume-label {
          width: auto;
        }
      `}
      {...props}
    >
      {props.children}
    </div>
  )
})

WidgetWrapper.displayName = 'WidgetWrapper'

function translateOrderEntryMessage(message: string, t: TFunction): string {
  for (const orderEntryTranslation of orderEntryTranslations) {
    // Make sure its start of line, that dots are escaped and that %s => [\s\S]+
    if (orderEntryTranslation.matchType === 'begins-with') {
      const matchAgainst = new RegExp(
        '^' + orderEntryTranslation.match.replaceAll('%s', '[\\s\\S]+').replaceAll('.', '\\.')
      )

      if (message.match(matchAgainst)) {
        return t(orderEntryTranslation.translationKey)
      }
    } else if (orderEntryTranslation.matchType === 'contains') {
      if (message.includes(orderEntryTranslation.match)) {
        return t(orderEntryTranslation.translationKey)
      }
    }
  }

  // Fallback to message
  return message
}

function useDisableInfrontInputScrollWheels(containerId: string) {
  const unsubscribeEvent = useRef<() => void>(() => {})

  const mutationRef = useMutationObserver({
    callback: () => {
      unsubscribeEvent.current()
      const unsubscribeAllEvents = disableInputScrollWheels(containerId)
      unsubscribeEvent.current = unsubscribeAllEvents
    },
  })

  useEffect(() => {
    return () => unsubscribeEvent.current()
  }, [])

  return mutationRef
}

function disableInputScrollWheels(containerId: string) {
  if (!containerId) return

  const unsubscribeWheelEventContainer: (() => void)[] = []

  const inputFields = document.querySelectorAll<HTMLInputElement>(`#${containerId} input[inputmode="decimal"]`)
  inputFields?.forEach((input) => {
    // Create event handler callback
    const wheelEventHandler = (event: WheelEvent) => {
      event.preventDefault()
    }

    // Add new event handler
    input.addEventListener('wheel', wheelEventHandler)

    // Create unsubscribe event handler callback
    const unsubscribeEventHandler = () => {
      input.removeEventListener('wheel', wheelEventHandler)
    }

    // Push unsubscribeEventHandler to container
    unsubscribeWheelEventContainer.push(unsubscribeEventHandler)
  })

  const unsubscribeAllEvents = () => {
    unsubscribeWheelEventContainer.forEach((unsubscribeEventHandlerCallback) => {
      unsubscribeEventHandlerCallback()
    })
  }

  return unsubscribeAllEvents
}
