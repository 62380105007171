import { AccountSummary } from '@common/api/response'

import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { formatNumber } from '@carnegie/digital-channels-frontend'
import {
  Breakpoint,
  Button,
  DataCell,
  FlexCol,
  FlexRow,
  IconInfoOutlined,
  IconTransfer,
  Link,
  SideDrawer,
  Text,
  Tooltip,
  useBreakpoint,
} from '@carnegie/duplo'

import { useOpenTransfersDrawer } from '@/drawers/transfersDrawer/useOpenTransfersDrawer'

type Props = {
  summary: AccountSummary
}

export const TradingCapacity = ({ summary }: Props) => {
  const { t } = useTranslation()
  const openTransfersDrawer = useOpenTransfersDrawer()
  const [openTradingPowerDrawer, setOpenTradingPowerDrawer] = useState(false)
  const breakpoint = useBreakpoint()
  const isExtraSmallScreen = breakpoint < Breakpoint.Small

  return (
    <FlexCol flex={1} mr={isExtraSmallScreen ? 0 : 16}>
      <Tooltip title={summary.tradingCapacity ? null : t('Ingen data tillgänglig')}>
        <FlexRow>
          <DataCell
            width="full"
            onClick={() => setOpenTradingPowerDrawer(true)}
            icon={IconInfoOutlined}
            label={t('TRADING_CAPACITY')}
            suffix={summary.tradingCapacity?.currencyCode}
          >
            {formatNumber(summary.tradingCapacity?.amount, { decimals: 0 })}
          </DataCell>
        </FlexRow>
      </Tooltip>
      <SideDrawer
        title={t('TRADING_CAPACITY')}
        variant="slide"
        sidebarContent={
          <FlexCol spaceY={16}>
            <Text variant="body2" color="text-low-emphasis">
              {t(
                'Det belopp som är tillgängligt för köp är i grunden kontots likvida medel (saldo). Om kontot har en kredit så finns möjlighet att utnyttja den upp till aktuellt belåningsvärde. Utöver det tas även hänsyn till utestående order, upplupen debetränta, säkerhetskrav m.m. För mer information om tillgängligt för köp,'
              )}
              &nbsp;
              <Link style={{ fontSize: 13 }} to="/profile/contact">
                {t('kontakta oss.')}
              </Link>
            </Text>
            <FlexRow p={16} alignItems="center" justifyContent="center" width="full">
              <Button
                variant="secondary"
                size="medium"
                onClick={() => {
                  setOpenTradingPowerDrawer(false)
                  openTransfersDrawer()
                }}
                disabled={false}
                width="full"
                startIcon={IconTransfer}
              >
                {t('Gör en överföring')}
              </Button>
            </FlexRow>
          </FlexCol>
        }
        open={openTradingPowerDrawer}
        onClose={() => setOpenTradingPowerDrawer(false)}
      />
    </FlexCol>
  )
}
