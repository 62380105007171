import { DocumentInfoModel } from '@common/api/response'
import { fireTrackEvent } from '@common/utils/analyticsEvent'

import { useTranslation } from 'react-i18next'

import { useFormatDate } from '@carnegie/digital-channels-frontend'
import {
  ButtonBox,
  FlexRow,
  Icon,
  IconDocumentLineChart,
  LinkExternal,
  ListItem,
  ListItemRow,
  Text,
} from '@carnegie/duplo'

type ReportStatementMobileRowProps = {
  statement: DocumentInfoModel
}

export const ReportStatementMobileRow = ({ statement }: ReportStatementMobileRowProps) => {
  const { t } = useTranslation()
  const { formatDate } = useFormatDate()

  const statementName = `${t(statement?.name.toString())} ${statement?.suffix}`.replaceAll('+', '_')

  return (
    <ListItem divider>
      <ListItemRow
        title={<Text variant="overline">{statement?.accountNumber}</Text>}
        value={
          <Text variant="overline">
            {statement.createdDate && formatDate(statement.createdDate, 'HH:mm/yyyy-MM-dd')}
          </Text>
        }
      />
      <ListItemRow
        title={
          <ButtonBox
            onClick={() => {
              fireTrackEvent('Reports', 'file_download_statements')
            }}
            minWidth={0}
          >
            <LinkExternal
              variant="subtitle1"
              href={`/api/reports/document/download-file/${statement?.documentId ?? ''}`}
              rel="noreferrer"
              target="_blank"
              download={statementName}
              minWidth={0}
            >
              <FlexRow alignItems="center">
                <Icon size={16} color="current" mr={4} icon={IconDocumentLineChart} />
                <Text variant="subtitle1" truncate>
                  {statementName}
                </Text>
              </FlexRow>
            </LinkExternal>
          </ButtonBox>
        }
      />
      <ListItemRow
        title={
          <Text variant="label1">{`${formatDate(statement?.statementPeriodStart, 'yyyy-MM-dd')} - ${formatDate(
            statement?.statementPeriodEnd,
            'yyyy-MM-dd'
          )}`}</Text>
        }
      />
    </ListItem>
  )
}
