import { ShowAtBreakpoint } from '@common/components/ShowAtBreakpoint'

import * as React from 'react'

import { Breakpoint, Heading5 } from '@carnegie/duplo'

import { StickySectionTitle } from './StickySectionTitle'

type Props = {
  title: string
  children?: React.ReactNode
  sticky?: boolean
}

export const SectionTitle = ({ title, children, sticky }: Props) => {
  if (sticky) {
    return (
      <>
        <ShowAtBreakpoint at={Breakpoint.Xs}>
          <StickySectionTitle title={title}>
            <ActionWrapper>{children}</ActionWrapper>
          </StickySectionTitle>
        </ShowAtBreakpoint>
        <ShowAtBreakpoint greaterThanOrEqual={Breakpoint.Small}>
          <Wrapper>
            <Heading5 style={{ flex: 1 }}>{title}</Heading5>
            <ActionWrapper>{children}</ActionWrapper>
          </Wrapper>
        </ShowAtBreakpoint>
      </>
    )
  }

  return (
    <Wrapper>
      <Heading5 style={{ flex: 1 }}>{title}</Heading5>
      <ActionWrapper>{children}</ActionWrapper>
    </Wrapper>
  )
}

const Wrapper = (props: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div css={{ display: 'flex', alignItems: 'center', width: '100%' }} {...props}>
      {props.children}
    </div>
  )
}
const ActionWrapper = (props: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div css={{ justifyContent: 'flex-end' }} {...props}>
      {props.children}
    </div>
  )
}
