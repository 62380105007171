import { PerformanceSerieResponse } from '@common/api/response'

import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { formatNumber } from '@carnegie/digital-channels-frontend'
import {
  Breakpoint,
  DataCell,
  FlexCol,
  IconInfoOutlined,
  SideDrawer,
  Spacer,
  Text,
  useBreakpoint,
} from '@carnegie/duplo'

type Props = {
  performanceSerie: PerformanceSerieResponse
}

export const UnrealisedResult = ({ performanceSerie }: Props) => {
  const { t } = useTranslation()
  const [openUnrealisedSlide, setOpenUnrealisedSlide] = useState(false)
  const breakpoint = useBreakpoint()
  const isExtraSmallScreen = breakpoint < Breakpoint.Small

  return (
    <FlexCol flex={1} mr={isExtraSmallScreen ? 0 : 16}>
      <DataCell
        onClick={() => setOpenUnrealisedSlide(true)}
        label={t('Orealiserat')}
        icon={IconInfoOutlined}
        suffix={performanceSerie?.unrealisedResult?.currencyCode}
      >
        {formatNumber(performanceSerie?.unrealisedResult?.amount, { decimals: 0 })}
      </DataCell>
      <SideDrawer
        title={t('Orealiserat')}
        variant="slide"
        sidebarContent={
          <FlexCol>
            <Text variant="body2">
              {t(
                'Orealiserat resultat visar vilken potentiell vinst eller förlust som kontot skulle generera utifrån det aktuella marknadsvärdet jämfört med det totala anskaffningsvärdet. Ingen hänsyn tas till skattemässiga regler.'
              )}
            </Text>
            <Spacer height={16} />
            <Text variant="body2">
              {t(
                'Marknadsvärdet baseras på de senast tillgängliga kurserna för respektive innehav. Carnegie redovisar endast kurser från tillförlitliga kurskällor och ansvarar inte för riktigheten av sådana kurser. Avsaknad av uppgifter om marknadsvärde kan tyda på bristande likviditet eller andra faktorer.'
              )}
            </Text>
            <Spacer height={16} />
            <Text variant="body2">
              {t(
                'För vissa värdepapperstyper kan kursen vara indikativ, eller vara per ett äldre datum. Kurser kan även förändras historiskt, som för exempelvis vissa fonder där NAV kan uppdateras i efterhand enligt fondbolagets värderingsprinciper.'
              )}
            </Text>
          </FlexCol>
        }
        open={openUnrealisedSlide}
        onClose={() => setOpenUnrealisedSlide(false)}
      />
    </FlexCol>
  )
}
