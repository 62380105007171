import { AccountsResponse } from '@common/api/response'
import { ShowAll } from '@common/components/ShowAll'
import { SkeletonLoader } from '@common/components/SkeletonLoader'
import { useInfrontTradingStatus } from '@common/hooks/infront/useInfrontTradingConnectedAndPortfolioReady'

import { useTranslation } from 'react-i18next'

import { Box, Segment, Text } from '@carnegie/duplo'

import { useCurrentOrders } from '@/pages/overview/order/CurrentOrders/useCurrentOrders'

import { observer } from 'mobx-react-lite'

import { CurrentOrderRowMobile } from '../../pages/overview/order/CurrentOrders/CurrentOrderRowMobile'

type CurrentOrdersFromSDKListProps = {
  renderForApp: boolean
  title?: string
  selectedAccount: AccountsResponse
}

// Same as the <CurrentOrders/> component but only shows a list, used in the order drawer
export const CurrentOrdersList = observer(({ selectedAccount, renderForApp }: CurrentOrdersFromSDKListProps) => {
  const { t } = useTranslation()

  const { orders, loading, deleteOrder, error } = useCurrentOrders(selectedAccount?.number, {
    sortByChangeTime: true,
    listType: 'current-orders',
  })

  const hasOrders = orders?.length > 0
  const { tradingConnectedAndPortfolioReady } = useInfrontTradingStatus()

  return (
    <Segment
      title={t('Aktuella ordrar för valt konto')}
      noContentPadding
      action={
        renderForApp ? (
          <div
            onClick={() => {
              window.ReactNativeWebView?.postMessage('NAVIGATE_TO_ORDERLIST')
            }}
          >
            <Text variant="subtitle2" color="astral">
              {t('Visa alla')}
            </Text>
          </div>
        ) : (
          <ShowAll to={`/overview/orders/${selectedAccount?.id}`} />
        )
      }
      variant="contained"
      headingVariant="overline"
    >
      {selectedAccount?.id === undefined ? (
        <Box p={16}>{t('Välj ett konto för att se aktuella ordrar.')}</Box>
      ) : (
        <SkeletonLoader
          variant="list"
          height={128}
          p={16}
          error={error}
          loading={(loading && !hasOrders) || !tradingConnectedAndPortfolioReady}
          noDataLoaded={!loading && !hasOrders}
          noDataComponent={<Box>{t('Det finns inga aktuella ordrar för valt konto.')}</Box>}
        >
          {() => (
            <>
              {orders?.map((order) => {
                return <CurrentOrderRowMobile key={order.orderId} order={order} deleteOrder={deleteOrder} />
              })}
            </>
          )}
        </SkeletonLoader>
      )}
    </Segment>
  )
})
CurrentOrdersList.displayName = 'CurrentOrdersList'
