import type { DrawerType } from './types'
import { useDrawerStore } from './useDrawerStore'

export function useOpenDrawer() {
  const drawerStore = useDrawerStore()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const openDrawer = (drawerType: DrawerType, routeProps?: string, props?: Record<string, any>) => {
    drawerStore.openDrawer(drawerType, routeProps, props as Record<string, unknown>)
  }

  return { openDrawer }
}
