export type Features = {
  isProd: boolean
  showCorporateEvents: boolean
  showAnalysis: boolean
  showFundOrder: boolean
  showTransfers: boolean
  // 🐒🐒🐒 Test stuff failing 🐒🐒🐒
  testFailingInfront: boolean
  testFailingHoldings: boolean // /holdings
  testFailingAccounts: boolean // /accounts
  testFailingParty: boolean // /party
  testFailingSecureMessages: boolean // /communication
  testFailingInstruments: boolean // /instruments
  testNoInfrontAccess: boolean
  testFailingTgw: boolean
}

export function featureOverridesActive() {
  return getFeaturesOverrides().length > 0
}

function getFeaturesOverrides() {
  const query = new URLSearchParams(window.location.search)
  const featuresList = query.get('features')

  let featuresArray: string[] = []

  if (featuresList !== null) {
    featuresArray = featuresList.split(',').filter((x) => !!x)

    // Set in local storage so we don't have to bring the query string along on all pages
    localStorage.setItem('features', JSON.stringify(featuresArray))
  } else {
    // No features specified but do we have it in local storage?
    const val = localStorage.getItem('features')

    if (val !== null) {
      featuresArray = JSON.parse(val)
    }
  }

  return featuresArray
}

export function getFeatures(): Features {
  const features: Features = {
    isProd: isProd(),
    showCorporateEvents: true,
    showAnalysis: true,
    showFundOrder: true,
    showTransfers: true,
    // 🐒🐒🐒 Test stuff failing 🐒🐒🐒
    testFailingInfront: false,
    testFailingHoldings: false,
    testFailingAccounts: false,
    testFailingParty: false,
    testFailingInstruments: false,
    testFailingSecureMessages: false,
    testNoInfrontAccess: false,
    testFailingTgw: false,
  }

  const featuresArray = getFeaturesOverrides()

  if (featuresArray.includes('testFailingInfront')) features.testFailingInfront = true
  if (featuresArray.includes('testFailingHoldings')) features.testFailingHoldings = true
  if (featuresArray.includes('testFailingAccounts')) features.testFailingAccounts = true
  if (featuresArray.includes('testFailingParty')) features.testFailingParty = true
  if (featuresArray.includes('testFailingInstruments')) features.testFailingInstruments = true
  if (featuresArray.includes('testFailingSecureMessages')) features.testFailingSecureMessages = true
  if (featuresArray.includes('testNoInfrontAccess')) features.testNoInfrontAccess = true
  if (featuresArray.includes('testFailingTgw')) features.testFailingTgw = true

  return features
}

// This is "per say" not a real React hook yet, but could one day become when we perhaps fetch a config, uses the Context API and so on
export function useFeatures(): Features {
  return getFeatures()
}

export function isDevMode() {
  return !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
}

function isProd() {
  const featuresArray = getFeaturesOverrides()
  const simulateProd = featuresArray.includes('simulateProd')

  return (
    window.location.hostname === 'pb.carnegie.se' ||
    window.location.hostname === 'pb.prod.services.carnegie.se' ||
    simulateProd
  )
}
