import { NoDataDash, NoDataFallback } from '@common/components/NoDataFallback'
import { useInfrontInstrument } from '@common/hooks/infront/sdk/useInfrontInstrument'
import { InstrumentIdContainer } from '@common/instrumentIdContainer'

import React from 'react'
import { useTranslation } from 'react-i18next'

import { formatNumber } from '@carnegie/digital-channels-frontend'
import {
  Box,
  FlexRow,
  Icon,
  IconExternalLink,
  LinkExternal,
  ListItem,
  ListItemRow,
  Paragraph,
  Segment,
  SkeletonRect,
  Spacer,
  Tag,
  TagLabel,
  Text,
} from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import i18n from '../../../i18n'
import { ListItemValue } from '../ListItemValue'

type Props = {
  instrumentIdContainer: InstrumentIdContainer
}

export const InstrumentStocksCompanyInformation = observer(({ instrumentIdContainer }: Props) => {
  const { t } = useTranslation()

  const { observableInstrument, isLoading } = useInfrontInstrument(
    instrumentIdContainer?.infrontInstrument,
    [
      // The EN-suffixed field is empty now, using CompanyDescription for English
      // https://dev.azure.com/carnegieinvestmentbank/CarnegieIT/_workitems/edit/85410
      InfrontSDK.SymbolField.CompanyDescription,
      //InfrontSDK.SymbolField.CompanyDescriptionEN,
      InfrontSDK.SymbolField.CompanyCEO,
      InfrontSDK.SymbolField.CompanyChairman,
      InfrontSDK.SymbolField.NumberShares,
      InfrontSDK.SymbolField.CompanyWebsite,
      InfrontSDK.SymbolField.CompanySubSector,
      InfrontSDK.SymbolField.InfrontSector,
      InfrontSDK.SymbolField.Urls,
      // remove when infront have fixed this issue
      //https://dev.azure.com/carnegieinvestmentbank/CarnegieIT/_workitems/edit/79498/
      InfrontSDK.SymbolField.FundamentalCompanyDesc,
    ],
    { observe: false }
  )

  const isCurrentLanguageSwedish = i18n.language === 'sv'

  //new fields from infront
  const companyCEO = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.CompanyCEO)
  const companyChairman = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.CompanyChairman)
  //const companyDescription = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.CompanyDescription)
  const fundamentalCompanyDesc = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.FundamentalCompanyDesc)
  const companyDescriptionEN = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.CompanyDescription)
  const companySubSector = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.CompanySubSector)
  const companyWebsite = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.CompanyWebsite)

  const numShares = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.NumberShares)

  const urls = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.Urls)
  const industry = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.InfrontSector)

  const website = companyWebsite ? companyWebsite : urls && urls[0]

  //replace fundamentalCompanyDesc with companyDescription when its available in swedish
  const finalDescription = isCurrentLanguageSwedish ? fundamentalCompanyDesc : companyDescriptionEN

  return (
    <Box>
      {isLoading ? (
        <SkeletonRect width="full" height={128} />
      ) : (
        <Paragraph variant="body1" mb={8}>
          <NoDataFallback>{finalDescription}</NoDataFallback>
        </Paragraph>
      )}
      <Segment variant="uncontained" title={t('Fakta')}>
        <Segment noContentPadding>
          <ListItem divider>
            <ListItemRow
              title={<Text variant="support2">{t('VD')}</Text>}
              value={<ListItemValue loading={isLoading} value={companyCEO} />}
            />
          </ListItem>
          <ListItem divider>
            <ListItemRow
              title={<Text variant="support2">{t('Ordförande')}</Text>}
              value={<ListItemValue loading={isLoading} value={companyChairman} />}
            />
          </ListItem>
          <ListItem divider>
            <ListItemRow
              title={<Text variant="support2">{t('Antal aktier')}</Text>}
              value={
                <ListItemValue
                  loading={isLoading}
                  value={numShares ? formatNumber(numShares, { decimals: 0 }) : numShares}
                />
              }
            />
          </ListItem>
          <ListItem>
            <ListItemRow
              title={<Text variant="support2">{t('Hemsida')}</Text>}
              value={
                isLoading ? (
                  <SkeletonRect width="full" height={20} />
                ) : website ? (
                  <FlexRow minWidth={0} alignItems="center" flexWrap="nowrap">
                    <LinkExternal size="small" target="_blank" href={website} title={website}>
                      <FlexRow alignItems="center">
                        {website}
                        <Icon ml={4} color="current" icon={IconExternalLink} size={20} />
                      </FlexRow>
                    </LinkExternal>
                  </FlexRow>
                ) : (
                  <NoDataDash />
                )
              }
            />
          </ListItem>
        </Segment>
      </Segment>
      <Spacer height={16} />
      <Text variant="overline">{t('Bransch')}</Text>
      <Spacer height={8} />
      {isLoading ? (
        <SkeletonRect height={32} width={100} />
      ) : (
        <Tag>{industry ? <TagLabel>{getIndustry(industry)}</TagLabel> : <NoDataDash />}</Tag>
      )}
      <Spacer height={16} />
      <Text variant="overline">{t('Sektor')}</Text>
      <Spacer height={8} />
      {isLoading ? (
        <SkeletonRect height={32} width={100} />
      ) : (
        <Tag>{companySubSector ? <TagLabel>{companySubSector}</TagLabel> : <NoDataDash />}</Tag>
      )}
    </Box>
  )
})
InstrumentStocksCompanyInformation.displayName = 'InstrumentStocksCompanyInformation'

const getIndustry = (industry: string): string => {
  enum InfrontSector {
    'C' = 'Communication',
    'C1' = 'Media & Entertainment',
    'C2' = 'Telecommunication Services',
    'D' = 'Consumer Non-Essentials',
    'D1' = 'Automobiles & Parts',
    'D2' = 'Consumer Goods',
    'D3' = 'Diversified Consumer Goods',
    'D4' = 'Retail',
    'D5' = 'Travel & Leisure',
    'S' = 'Consumer Essentials',
    'S1' = 'Food & Staples Retailing',
    'S2' = 'Food, Beverage & Tobacco',
    'S3' = 'Household & Personal Products',
    'E' = 'Energy',
    'E1' = 'Energy Equipment & Services',
    'E2' = 'Oil, Gas & Consumable Fuels',
    'F' = 'Financials',
    'F1' = 'Banks',
    'F2' = 'Diversified Financials',
    'F3' = 'Insurance',
    'H' = 'Healthcare',
    'H1' = 'Health Care Equipment & Services',
    'H2' = 'Pharmaceuticals, Biotechnology & Life Sciences',
    'I' = 'Industrials',
    'I1' = 'Capital Goods',
    'I2' = 'Industrial Services',
    'I3' = 'Transportation',
    'T' = 'Technology',
    'T1' = 'Semiconductors & Semiconductor Equipment',
    'T2' = 'Software & Services',
    'T3' = 'Technology Hardware & Equipment',
    'M' = 'Materials',
    'M1' = 'Building Materials',
    'M2' = 'Chemicals',
    'M3' = 'Containers & Packaging',
    'M4' = 'Forest, Pulp & Paper',
    'M5' = 'Metals & Mining',
    'R' = 'Real Estate',
    'R1' = 'Real Estate Investment Trusts (REITs)',
    'R2' = 'Real Estate Management & Development',
    'U' = 'Utilities',
    'U1' = 'Electric Utilities',
    'U2' = 'Gas Utilities',
    'U3' = 'Independent Power and Renewable',
    'U4' = 'Multi-Utilities',
    'U5' = 'Water Utilities',
  }

  if (industry) {
    let val = InfrontSector[industry.substring(0, 1)]
    if (industry?.length === 2 && InfrontSector[industry.substring(0, 2)]?.length > 0)
      val += ' - ' + InfrontSector[industry.substring(0, 2)]
    //if (!val && isDevMode()) throw new Error('Unknown industry: ' + industry)

    return val ?? industry
  }

  return industry
}
