import { SkeletonLoader } from '@common/components/SkeletonLoader'
import { useDrawerProps } from '@common/components/drawer/useDrawerStore'
import { usePodcastEpisode } from '@common/hooks/usePodcastEpisode'
import { useKeepUserLoggedIn } from '@common/hooks/useUserActivityManager'

import { Breakpoint, GridContainer, GridItem, Paragraph, Spacer, Text, useBreakpoint } from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import { CarnegieMaterialSimpleHtml } from '../CarnegieMaterialSimpleHtml'
import { CarnegieMaterialTagAndDate } from '../CarnegieMaterialTagAndDate'
import { CarnegieMaterialHeading } from '../seminarDrawer/CarnegieMaterialHeading'

const PodcastDrawer = observer(() => {
  const { routeProps: id } = useDrawerProps()
  const { podcastEpisode } = usePodcastEpisode(id)
  const breakpoint = useBreakpoint()
  const isMobile = breakpoint < Breakpoint.Small

  // Keep resetting the idle timer and update the refresh tokens for a max of 90 minutes
  useKeepUserLoggedIn(90)

  return (
    <SkeletonLoader height={232} loading={!podcastEpisode}>
      {() => (
        <>
          <CarnegieMaterialHeading isMobile={isMobile} title={podcastEpisode?.title} />
          {!isMobile && <Spacer height={8} />}
          <GridContainer spacing={0} style={{ justifyContent: 'flex-start' }}>
            <GridItem md={8} lg={8}>
              {podcastEpisode?.date && (
                <CarnegieMaterialTagAndDate date={new Date(podcastEpisode?.date)} tags={podcastEpisode?.category} />
              )}
              <Spacer height={isMobile ? 16 : 32} />
              {podcastEpisode?.description && (
                <Text variant="subtitle1" truncate={false}>
                  <CarnegieMaterialSimpleHtml html={podcastEpisode?.description} convertLinks />
                </Text>
              )}
              <SoundcloudPlayer isMobile={isMobile} id={id} />
              <Spacer height={isMobile ? 16 : 32} />
              {podcastEpisode?.disclaimerText && (
                <Paragraph variant="body2">
                  <CarnegieMaterialSimpleHtml html={podcastEpisode?.disclaimerText} />
                </Paragraph>
              )}
              <Spacer height={16} />
            </GridItem>
            <GridItem lg={4}>
              <div />
            </GridItem>
          </GridContainer>
        </>
      )}
    </SkeletonLoader>
  )
})
PodcastDrawer.displayName = 'PodcastDrawer'

const SoundcloudPlayer = ({ id, isMobile }: { id: string; isMobile: boolean }) => {
  return (
    <div
      css={{
        iframe: {
          border: 'none',
        },
      }}
    >
      <Spacer height={isMobile ? 16 : 32} />
      <iframe
        width="100%"
        height="176"
        scrolling="no"
        title="episode"
        src={`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${id}&hide_related=true&show_playcount=false`}
      />
    </div>
  )
}
SoundcloudPlayer.displayName = 'SoundcloudPlayer'

export default PodcastDrawer
