import { UserSettingKey } from '@common/api/response'
import { useApi } from '@common/stores/store'

import { useCallback, useMemo } from 'react'

import { appInsights } from '@/appInsights'

import useSWR from 'swr'

export type UserSetting<T> = {
  readonly value: T | null
  readonly valueType?: string | null
  readonly error?: string
  setValue: UpsertUserSetting
}

type UpsertUserSetting = (value: unknown | null, valueType?: string) => Promise<void>

export function useUserSetting<T>(key: UserSettingKey): UserSetting<T> {
  const api = useApi()

  const getUserSettingWithoutEnvelope = useCallback(async () => {
    const result = await api.getUserSetting(key)

    try {
      const resultWithoutEnvelope =
        result?.item && !result.error
          ? { value: JSON.parse(result.item.value) as T, valueType: result.item.valueType }
          : null
      return resultWithoutEnvelope
    } catch (error) {
      // Log it
      appInsights.trackEvent({
        name: 'userSettings.parseError',
        properties: {
          error: error,
        },
      })

      throw error
    }
  }, [api, key])

  // GET
  const { data, error, mutate } = useSWR(`/setting/${key}`, async () => {
    return getUserSettingWithoutEnvelope()
  })

  // PUT
  const upsertUserSetting: UpsertUserSetting = useCallback(
    async (value: unknown | null, valueType?: string) => {
      const type = valueType ? valueType : typeof value // Use this to save any type-name needed

      // Mutation with optimistic UI: https://swr.vercel.app/examples/optimistic-ui
      mutate(
        // Because the upserUserSetting api call currently does not return the new data we must do it ourselves with an extra request
        async () => {
          await api.upsertUserSetting({ key, value, valueType: type })
          return await getUserSettingWithoutEnvelope()
        },
        { optimisticData: { value: value as T, valueType: type } }
      )
    },
    [api, getUserSettingWithoutEnvelope, key, mutate]
  )

  return useMemo<UserSetting<T>>(
    () => ({
      value: data?.value,
      valueType: data?.valueType,
      setValue: upsertUserSetting,
      error,
    }),
    [data?.value, data?.valueType, error, upsertUserSetting]
  )
}
