import { InvalidCode } from '@common/api/response'

import { useTranslation } from 'react-i18next'

import { Banner, Box, GridContainer, GridItem, Link, Paragraph, Segment } from '@carnegie/duplo'

export const ValidationErrorMessage = ({ invalidCode }: { invalidCode: InvalidCode }) => {
  const { t } = useTranslation()

  const ContactUs = () => <Link to="/profile/contact">{t('Kontakta oss')}</Link>

  switch (invalidCode) {
    case 'NotEmployeeOrStaffRelated':
    case 'NotTradableInstrument':
      return (
        <ValidationErrorMessageBox
          title={t('Detta värdepapper är inte tillgängligt för handel')}
          description={
            <Paragraph variant="body1">
              {t('Vi ser löpande över vårt utbud, men ber dig i nuläget att')}{' '}
              <Link to="/profile/contact">{t('kontakta oss')}</Link>{' '}
              {t('för mer information om hur du kan lägga din order.')}
            </Paragraph>
          }
        />
      )
    case 'NotTradableFund':
      return (
        <ValidationErrorMessageBox
          title={t('Den här fonden är inte tillgänglig för handel')}
          description={
            <Paragraph variant="body1">
              <ContactUs /> {t('för mer information om du är intresserad av att lägga en order.')}
            </Paragraph>
          }
        />
      )
    case 'NoAccountsHasStatusOpen':
    case 'AccountsMissingTradePrivilege':
      return (
        <ValidationErrorMessageBox
          title={t('Du saknar möjlighet att handla själv i våra digitala kanaler')}
          description={
            <Paragraph variant="body1">
              <ContactUs />{' '}
              {t(
                'för att få mer information om hur du kan få tillgång till att handla själv, eller få hjälp med att lägga din order.'
              )}
            </Paragraph>
          }
        />
      )
    case 'UserMissingKnowledgeClass':
      return (
        <ValidationErrorMessageBox
          title={t('Du saknar möjlighet till handel i detta värdepapper')}
          description={
            <Paragraph variant="body1">
              <ContactUs />{' '}
              {t('för att uppdatera vår information om din kunskap och erfarenhet i den aktuella värdepapperstypen.')}
            </Paragraph>
          }
        />
      )
    case 'UserIsImpersonator':
      return (
        <ValidationErrorMessageBox
          title={t('Du saknar rättighet till orderflödet')}
          description={t('Nuvarande inloggningsmetod har ej tillgång till orderflödet.')}
        />
      )

    default:
      return null
  }
}

type ValidationErrorMessageBoxProps = {
  title: string
  description: string | React.ReactNode
}

const ValidationErrorMessageBox = ({ title, description }: ValidationErrorMessageBoxProps) => {
  return (
    <GridContainer spacing={4}>
      <GridItem xs={12} md={7}>
        <Segment title="" variant="contained" headingVariant="overline">
          <Banner severity={'information'} title={title} description={description} />
        </Segment>
      </GridItem>
      <GridItem xs={12} md={5}>
        <Box />
      </GridItem>
    </GridContainer>
  )
}
