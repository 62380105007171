import { AccountsResponse } from '@common/api/response'
import { CopyToClipboard } from '@common/components/CopyToClipboard'
import { SkeletonLoader } from '@common/components/SkeletonLoader'
import { useAccountsSummary } from '@common/hooks/useAccountSummary'
import { useHoldingAccounts } from '@common/hooks/useHoldings'
import { getOwnerDescription } from '@common/utils/accountOwnerDescription'

import { useTranslation } from 'react-i18next'

import { formatNumber } from '@carnegie/digital-channels-frontend'
import {
  Badge,
  Box,
  Breakpoint,
  FlexCol,
  FlexRow,
  Link,
  Paragraph,
  Segment,
  SkeletonText,
  Spacer,
  TBody,
  THead,
  Table,
  Td,
  Text,
  Th,
  Tooltip,
  Tr,
  useBreakpoint,
} from '@carnegie/duplo'

const Deposit = () => {
  const { t } = useTranslation()
  const { accounts } = useHoldingAccounts()
  const accountsSummary = useAccountsSummary()
  const breakpoint = useBreakpoint()
  const isExtraSmallScreen = breakpoint <= Breakpoint.Xs

  //We need to be able to check if user has trade or view rights and
  //with exceptions for insurance accounts ie:
  //Depå Kapitalförsäkring	KF, Depå Kapitalpension	KP, Depå Pensionsförsäkring	PF, Depå Tjänstepension	TJP
  //isDepositAndExternalWithdrawEnabled does this check in backend

  //If at least one accounts has isDepositAndExternalWithdrawEnabled = true then dont show empty state
  const hasOneOrMoreDepositAndExternalWithdrawEnabled = accounts?.some(
    (account) => account?.isDepositAndExternalWithdrawEnabled === true
  )

  return (
    <Box p={16}>
      <Paragraph variant="body1">
        {t(
          'Du kan göra en insättning (i SEK) till ditt konto genom en inbetalning till vårt bankgironummer. Som referens anger du det unika OCR-numret för det konto som du ska göra insättningen till.'
        )}
      </Paragraph>
      <Spacer height={8} />
      <FlexRow alignItems="center">
        <Paragraph variant="body1">{t('Bankgironummer') + ':'}</Paragraph>&nbsp;
        <Text variant="subtitle2">{'871-9916'}</Text>&nbsp;
        <CopyToClipboard
          identifier={t('Bankgironummer')}
          value={'871-9916'}
          style={{
            display: 'inline-block',
          }}
        />
      </FlexRow>
      <Spacer height={16} />
      <Segment noContentPadding title={''}>
        <SkeletonLoader
          error={false}
          height={300}
          p={16}
          loading={!accounts}
          noDataComponent={
            <Paragraph variant="body1">
              {t(
                'Du har inga konton som du kan göra en bankgiroinbetalning till med OCR-referens. Se information om andra insättningsalternativ under frågor och svar.'
              )}
            </Paragraph>
          }
          noDataLoaded={accounts?.length === 0 || !hasOneOrMoreDepositAndExternalWithdrawEnabled}
        >
          {() => (
            <Table css={{ tableLayout: 'fixed' }}>
              <THead>
                <Tr>
                  <Th width="35%" textAlign="left">
                    {t('Konto')}
                  </Th>
                  <Th width="20%">{t('OCR')}</Th>
                  {!isExtraSmallScreen && (
                    <Th width="25%" textAlign="right">
                      {t('Eget kapital')}
                    </Th>
                  )}
                  {!isExtraSmallScreen && (
                    <Th width="20%" textAlign="right">
                      {t('Likvida medel')}
                    </Th>
                  )}
                </Tr>
              </THead>
              <TBody>
                {accounts?.map((account: AccountsResponse) => {
                  const summary = accountsSummary?.find((summary) => summary.accountId === account.id) || null
                  return (
                    account?.isDepositAndExternalWithdrawEnabled && (
                      <Tr key={account?.id}>
                        <Td pb={6} pt={6}>
                          <FlexRow alignItems="center">
                            {account?.accountType?.description ? (
                              <Tooltip title={t(account?.accountType?.description)}>
                                <Badge size="medium">{account?.accountType?.shortName}</Badge>
                              </Tooltip>
                            ) : (
                              <Badge size="medium">{account?.accountType?.shortName}</Badge>
                            )}
                            <FlexCol minWidth={0} ml={8}>
                              <Link to={`/accounts/${account?.id}`} variant="support1">
                                {account?.number}
                              </Link>
                              <Tooltip title={getOwnerDescription(account?.owners, account?.policyHolder, t)}>
                                <Text variant="support2" truncate>
                                  {getOwnerDescription(account?.owners, account?.policyHolder, t)}
                                </Text>
                              </Tooltip>
                            </FlexCol>
                          </FlexRow>
                        </Td>
                        <Td pb={6} pt={6}>
                          <FlexRow alignItems="center">
                            <Paragraph variant="body2">{account?.ocrNumber}</Paragraph>
                            <CopyToClipboard
                              identifier={t('OCR-nummer')}
                              value={account?.ocrNumber}
                              style={{
                                display: 'inline-block',
                              }}
                            />
                          </FlexRow>
                        </Td>
                        {!isExtraSmallScreen && (
                          <Td pb={6} pt={6}>
                            <FlexRow alignItems="baseline" justifyContent="flex-end">
                              {summary ? (
                                <>
                                  <Paragraph variant="body2">
                                    {formatNumber(summary.portfolioValue.amount, {
                                      decimals: 0,
                                    })}
                                  </Paragraph>
                                  <Text variant="support3" ml={2}>
                                    {account?.currencyCode}
                                  </Text>
                                </>
                              ) : (
                                <SkeletonText />
                              )}
                            </FlexRow>
                          </Td>
                        )}
                        {!isExtraSmallScreen && (
                          <Td pb={6} pt={6}>
                            <FlexRow alignItems="baseline" justifyContent="flex-end">
                              {summary ? (
                                <>
                                  <Paragraph variant="body2">
                                    {formatNumber(summary.liquidAsset.amount, {
                                      decimals: 0,
                                    })}
                                  </Paragraph>
                                  <Text variant="support3" ml={2}>
                                    {account?.currencyCode}
                                  </Text>
                                </>
                              ) : (
                                <SkeletonText />
                              )}
                            </FlexRow>
                          </Td>
                        )}
                      </Tr>
                    )
                  )
                })}
              </TBody>
            </Table>
          )}
        </SkeletonLoader>
      </Segment>
      <Spacer height={16} />
      <Paragraph variant="body1">
        {t('Observera att det inte är möjligt att göra insättningar enligt ovan till försäkringskonton.')}
      </Paragraph>
    </Box>
  )
}

export default Deposit
