import { NoDataDash } from '@common/components/NoDataFallback'
import { SkeletonLoader } from '@common/components/SkeletonLoader'
import { StarBar } from '@common/components/StarBar'
import { useInfrontInstrument } from '@common/hooks/infront/sdk/useInfrontInstrument'
import { useCarnegieInstrument } from '@common/hooks/useCarnegieInstrument'
import { InstrumentIdContainer } from '@common/instrumentIdContainer'

import { useTranslation } from 'react-i18next'

import { Box, FlexCol, FlexRow, Text, useResponsiveProps } from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

type InstrumentFundsMorningStarProps = {
  className?: string
  instrumentIdContainer: InstrumentIdContainer
}

export const InstrumentFundsMorningStar = observer(
  ({ className, instrumentIdContainer }: InstrumentFundsMorningStarProps) => {
    const { t, i18n } = useTranslation()

    const { carnegieInstrument } = useCarnegieInstrument(instrumentIdContainer, i18n.language)

    const { observableInstrument, isLoading, error } = useInfrontInstrument(
      instrumentIdContainer?.infrontInstrument,
      [InfrontSDK.SymbolField.FundStarRating, InfrontSDK.SymbolField.FundRiskLevel],
      { observe: false }
    )

    const riskLevel = carnegieInstrument?.riskTolerancePRIIPS

    // Wrong typing from infront so recasting
    const stars = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.FundStarRating) as unknown as number

    const rp = useResponsiveProps()

    return (
      <SkeletonLoader height={40} loading={isLoading}>
        {() => (
          <>
            <FlexRow className={className} alignItems="center">
              <Box width={rp(['1/2', '1/3'])}>
                <FlexCol mr={rp([16, 0])} height={40}>
                  <Text variant="label1" color="text-low-emphasis">
                    {t('Risknivå')}
                  </Text>
                  <Text variant="subtitle1" color="bunker-main">
                    {!riskLevel ? <NoDataDash /> : riskLevel + t(' av 7')}
                  </Text>
                </FlexCol>
              </Box>
              <Box width={rp(['1/2', '1/3'])}>
                <FlexCol height={40}>
                  <Text variant="label1" color="text-low-emphasis">
                    {t('Morningstars fondbetyg')}
                  </Text>
                  {stars !== undefined && stars !== null ? (
                    <StarBar stars={stars} data-testid="morningstar-star-bar" />
                  ) : error ? (
                    <NoDataDash />
                  ) : (
                    t('Betyg saknas')
                  )}
                </FlexCol>
              </Box>
            </FlexRow>
          </>
        )}
      </SkeletonLoader>
    )
  }
)
InstrumentFundsMorningStar.displayName = 'InstrumentFundsMorningStar'
