import { getValueColor } from '@common/utils/colorUtils'
import { hasValue } from '@common/utils/hasValue'

import React from 'react'

import { formatNumber } from '@carnegie/digital-channels-frontend'
import { DuploProps, FlexRow, Paragraph } from '@carnegie/duplo'
import { DuploTextVariant } from '@carnegie/duplo/lib/styles/text'

export const FormattedPercentage = (props: FormattedNumberProps) => {
  return <FormattedNumber unit="%" {...props} />
}

type FormattedNumberProps = {
  value: number
  unit?: string
  decimals: number | { min: number; max: number }
  colorized?: boolean
  className?: string
  bold?: boolean
  variant?: DuploTextVariant
  ratio?: boolean
  showZeroAsFallbackValue?: boolean
  skeleton?: boolean
  skeletonWidth?: number
} & DuploProps

export const FormattedNumber = React.forwardRef(
  (
    {
      value,
      unit,
      decimals,
      colorized = false,
      className,
      bold = false,
      variant = 'body2',
      ratio,
      showZeroAsFallbackValue,
      skeleton,
      skeletonWidth,
      ...rest
    }: FormattedNumberProps,
    ref: React.Ref<HTMLDivElement> // Update the type of the ref prop
  ) => {
    const isZeroWithFallback = showZeroAsFallbackValue && value === 0

    return (
      <FlexRow ref={ref} alignItems="baseline" className={className} {...rest}>
        <Paragraph
          skeleton={skeleton}
          skeletonWidth={skeletonWidth}
          truncate
          variant={variant}
          bold={bold}
          color={colorized ? getValueColor(value) : undefined}
        >
          {formatNumber(value, { decimals, ratio, showZeroAsFallbackValue })}
        </Paragraph>
        {unit && hasValue(value) && !isZeroWithFallback && (
          <Paragraph
            truncate
            variant="support3"
            ml={2}
            bold={bold}
            color={colorized ? getValueColor(value) : undefined}
          >
            {unit}
          </Paragraph>
        )}
      </FlexRow>
    )
  }
)
FormattedNumber.displayName = 'FormattedNumber'
