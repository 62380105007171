import { useInfrontTradingStatus } from '@common/hooks/infront/useInfrontTradingConnectedAndPortfolioReady'

import { ReactNode, useEffect, useState } from 'react'

import { Box, DuploProps } from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import { SkeletonLoaderMessage } from './SkeletonLoader'

type InfrontWidgetHandlerProps = {
  children?: ReactNode
  expectedPortfolioName: string
  wrongPortfolioMessage?: ReactNode
  tradingConnectingContent: ReactNode // "Loading"
  messageProps?: DuploProps
}

/**
 * This component should be wrapped around Infront widgets that deal with trading. It makes sure the widget is only shown
 * when the active portfolio is the expected one (based on an account select component for example) and can also show an error
 * message if the TGW disconnects.
 */
export const InfrontTradingWidgetHandler = observer(
  ({
    children,
    expectedPortfolioName,
    wrongPortfolioMessage,
    tradingConnectingContent,
    messageProps,
  }: InfrontWidgetHandlerProps) => {
    const {
      tradingConnectedAndPortfolioReady,
      activePortfolioName,
      lostConnectionOrReconnecting,
      tradingConnectionError,
    } = useInfrontTradingStatus()

    const [portfolioMissmatch, setPortfolioMissmatch] = useState(false)

    // Infront only support one active portfolio at a time (globally) because of this we might show widgets that expect a certain portfolio to be
    // shown but another widget (shown in a drawer) changes the active portfolio causing underlying widgets to get out of sync. The only real way we can
    // handle this now is to disable widgets that show the wrong portfolio.
    // Also because there is a slight delay between changing the active portfolio and components re-rendering (for example because the useSyncActivePortfolio uses useEffect)
    // we need to delay this check.
    useEffect(() => {
      const timeoutId = setTimeout(() => {
        if (
          // Since Infront 11.14 we must make sure that an active portfolio is set before showing
          // for example the OrderEntry widget
          tradingConnectedAndPortfolioReady &&
          expectedPortfolioName !== activePortfolioName
        ) {
          setPortfolioMissmatch(true)
        } else {
          setPortfolioMissmatch(false)
        }
      }, 100)

      return () => {
        clearInterval(timeoutId)
      }
    }, [expectedPortfolioName, activePortfolioName, tradingConnectedAndPortfolioReady])

    if (tradingConnectionError) {
      return (
        <Box p={16} {...messageProps}>
          <SkeletonLoaderMessage messageType="error" />
        </Box>
      )
    }

    // Wrong portfolio handling
    if (portfolioMissmatch) {
      return !wrongPortfolioMessage ? (
        <Box p={16} {...messageProps}>
          <SkeletonLoaderMessage messageType="no-data-loaded" />
        </Box>
      ) : (
        <>{wrongPortfolioMessage}</>
      )
    }

    // Since version 11.14 the OrderEntry widget seems to be sensitive to being displayed before the active portfolio is ready (at least once),
    // this check fixes that problem
    // We also show the widgets even if the connection is lost and it is reconnecting since Infront have built in handling for that
    if (tradingConnectedAndPortfolioReady || lostConnectionOrReconnecting) {
      // Everything good!
      return <>{children}</>
    }

    // Connecting to TGW
    return <>{tradingConnectingContent}</>
  }
)
