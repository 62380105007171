//Copied from dead project https://github.com/tomisu/react-element-scroll-hook/tree/master to allow for later version of React
import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react'

interface ScrollInfo {
  percentage: number | null
  max: number
  current: number
  value: number
}

export interface ScrollState {
  x: ScrollInfo
  y: ScrollInfo
}

// Edge has a bug where scrollHeight is 1px bigger than clientHeight when there's no scroll.
const isEdge = typeof navigator !== 'undefined' && /Edge\/\d./i.test(window.navigator.userAgent)

// Small hook to use ResizeObserver if available. This fixes some issues when the component is resized.
// This needs a polyfill to work on all browsers. The polyfill is not included in order to keep the package light.
function useResizeObserver(ref: MutableRefObject<HTMLElement>, callback: (rect: DOMRectReadOnly) => void) {
  useEffect(() => {
    if (typeof window !== 'undefined' && window.ResizeObserver) {
      const resizeObserver = new ResizeObserver((entries) => {
        // Wrap it in requestAnimationFrame to avoid this error - ResizeObserver loop limit exceeded
        window.requestAnimationFrame(() => {
          if (!Array.isArray(entries) || !entries.length) {
            return
          }
          callback(entries[0].contentRect)
        })
      })

      resizeObserver.observe(ref.current)

      return () => {
        resizeObserver.disconnect()
      }
    }
  }, [callback, ref])
}

function throttle(func: () => void, wait: number) {
  let context, args, result
  let timeout = null
  let previous = 0
  const later = function () {
    timeout = null
    result = func.apply(context, args)
    if (!timeout) {
      context = args = null
    }
  }
  return function (...args) {
    const now = Date.now()
    const remaining = wait - (now - previous)
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout)
        timeout = null
      }
      previous = now
      result = func.apply(context, args)
      if (!timeout) {
        context = args = null
      }
    } else if (!timeout) {
      timeout = setTimeout(later, remaining)
    }
    return result
  }
}

function useScrollInfo(
  throttleTime = 50
): (ScrollState | ((node: HTMLElement) => void) | React.MutableRefObject<HTMLElement | null>)[] {
  const [scroll, setScroll] = useState<ScrollState>({
    x: { percentage: null, max: 0, current: 0, value: 0 },
    y: { percentage: null, max: 0, current: 0, value: 0 },
  })
  const ref = useRef<HTMLElement | null>(null)
  const previousScroll = useRef<ScrollState | null>(null)

  useResizeObserver(ref, () => {
    update()
  })

  function update() {
    const element = ref.current
    let maxY = element.scrollHeight - element.clientHeight
    const maxX = element.scrollWidth - element.clientWidth

    // Edge has a bug where scrollHeight is 1px bigger than clientHeight when there's no scroll.
    if (isEdge && maxY === 1 && element.scrollTop === 0) {
      maxY = 0
    }

    const percentageY = maxY !== 0 ? element.scrollTop / maxY : null
    const percentageX = maxX !== 0 ? element.scrollLeft / maxX : null

    let classNameY = 'no-scroll-y'
    if (percentageY === 0) {
      classNameY = 'scroll-top'
    } else if (percentageY === 1) {
      classNameY = 'scroll-bottom'
    } else if (percentageY) {
      classNameY = 'scroll-middle-y'
    }

    let classNameX = 'no-scroll-x'
    if (percentageX === 0) {
      classNameX = 'scroll-left'
    } else if (percentageX === 1) {
      classNameX = 'scroll-right'
    } else if (percentageX) {
      classNameX = 'scroll-middle-x'
    }

    const previous = previousScroll.current

    const scrollInfo = {
      x: {
        percentage: percentageX,
        value: element.scrollLeft,
        total: maxX,
        className: classNameX,
        direction: previous ? Math.sign(element.scrollLeft - previous.x.value) : 0,
        max: maxX, // Added missing property
        current: element.scrollLeft, // Added missing property
      },
      y: {
        percentage: percentageY,
        value: element.scrollTop,
        total: maxY,
        className: classNameY,
        direction: previous ? Math.sign(element.scrollTop - previous.y.value) : 0,
        max: maxY, // Added missing property
        current: element.scrollTop, // Added missing property
      },
    }
    previousScroll.current = scrollInfo
    setScroll(scrollInfo)
  }

  const throttledUpdate = throttle(update, throttleTime)

  const setRef = useCallback(
    (node: HTMLElement) => {
      if (node) {
        // When the ref is first set (after mounting)
        node.addEventListener('scroll', throttledUpdate)
        if (!window.ResizeObserver) {
          window.addEventListener('resize', throttledUpdate) // Fallback if ResizeObserver is not available
        }
        ref.current = node
        throttledUpdate() // initialization
      } else if (ref.current) {
        // When unmounting
        ref.current.removeEventListener('scroll', throttledUpdate)
        if (!window.ResizeObserver) {
          window.removeEventListener('resize', throttledUpdate)
        }
      }
    },
    [throttledUpdate]
  )

  return [scroll, setRef, ref]
}

export default useScrollInfo
