import { ResearchNews, ResearchTargets } from '@common/api/response'
import { FormattedPercentage } from '@common/components/FormattedNumber'
import { SkeletonLoader } from '@common/components/SkeletonLoader'
import { usePageParam } from '@common/hooks/usePageParam'
import { createValidInstrumentIdContainer } from '@common/instrumentIdContainer'

import { useTranslation } from 'react-i18next'

import { checkToday, formatNumber } from '@carnegie/digital-channels-frontend'
import {
  Breakpoint,
  FlexCol,
  MobileTableRow,
  TBody,
  THead,
  Table,
  Td,
  Text,
  Th,
  Tr,
  useBreakpoint,
  useDuploTheme,
} from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import { useOpenInstrumentDrawer } from '../../../drawers/instrumentDrawer/useOpenInstrumentDrawer'
import { InstrumentName, InstrumentNameMobile } from '../../accounts/holdingsTable/InstrumentName'
import { useContentAnalysis } from '../shared/useContentAnalysis'
import { ResearchNewsMoreMenu } from './ResearchNewsMoreMenu'

type EpsRevisionsRowProps = {
  researchNews: ResearchNews
}

type EpsRevisionsMobileTableRowDataProps = {
  researchTargets?: ResearchTargets
}

export const EpsRevisionsMobileTableRowData = ({ researchTargets }: EpsRevisionsMobileTableRowDataProps) => {
  const { t } = useTranslation()
  const {
    estimatesToYear1,
    estimatesToYear2,
    estimatesToYear3,
    estimatesPercentYear1,
    estimatesPercentYear2,
    estimatesPercentYear3,
    estimatesYearStart,
  } = researchTargets

  const currentYear = estimatesYearStart ?? new Date().getFullYear()

  return (
    <>
      <FlexCol alignItems="flex-start" flex={1}>
        <Text variant="label1">{`${currentYear}e`}</Text>
        <Text variant="subtitle2">{formatNumber(estimatesToYear1)}</Text>
      </FlexCol>
      <FlexCol alignItems="flex-start" flex={1}>
        <Text variant="label1">{t('%')}</Text>
        <FormattedPercentage value={estimatesPercentYear1} justifyContent={'flex-end'} colorized decimals={0} />
      </FlexCol>
      <FlexCol alignItems="flex-start" flex={1}>
        <Text variant="label1">{`${currentYear + 1}e`}</Text>
        <Text variant="subtitle2">{formatNumber(estimatesToYear2)}</Text>
      </FlexCol>
      <FlexCol alignItems="flex-start" flex={1}>
        <Text variant="label1">{t('%')}</Text>
        <FormattedPercentage value={estimatesPercentYear2} justifyContent={'flex-end'} colorized decimals={0} />
      </FlexCol>
      <FlexCol alignItems="flex-start" flex={1}>
        <Text variant="label1">{`${currentYear + 2}e`}</Text>
        <Text variant="subtitle2">{formatNumber(estimatesToYear3)}</Text>
      </FlexCol>
      <FlexCol alignItems="flex-start" flex={1}>
        <Text variant="label1">{t('%')}</Text>
        <FormattedPercentage value={estimatesPercentYear3} justifyContent={'flex-end'} colorized decimals={0} />
      </FlexCol>
    </>
  )
}

const EpsRevisionsRow = observer(({ researchNews }: EpsRevisionsRowProps) => {
  const breakpoint = useBreakpoint()
  const isExtraSmallScreen = breakpoint < Breakpoint.Small

  const { openInstrumentDrawer } = useOpenInstrumentDrawer()

  const {
    estimatesToYear1,
    estimatesToYear2,
    estimatesToYear3,
    estimatesPercentYear1,
    estimatesPercentYear2,
    estimatesPercentYear3,
  } = researchNews?.researchTargets ?? {}

  const instrumentIdContainer = createValidInstrumentIdContainer({
    instrumentIdCarnegie: researchNews?.relatedInstrument?.carnegieInstrumentId,
    infrontInstrument: researchNews?.relatedInstrument?.infrontInstrument,
  })

  if (isExtraSmallScreen) {
    return (
      <MobileTableRow
        divider
        action={
          researchNews?.relatedInstrument && <ResearchNewsMoreMenu instrumentIdContainer={instrumentIdContainer} />
        }
        title={
          <InstrumentNameMobile isoCountryCode={researchNews?.marketIso} name={researchNews?.company?.name} showFlag />
        }
        onClick={() => {
          if (researchNews?.relatedInstrument) {
            openInstrumentDrawer(instrumentIdContainer)
          }
        }}
      >
        <EpsRevisionsMobileTableRowData researchTargets={researchNews?.researchTargets} />
      </MobileTableRow>
    )
  }
  return (
    <Tr key={researchNews.newsId || researchNews.researchId}>
      <Td pb={8} pt={8}>
        <InstrumentName
          isoCountryCode={researchNews.marketIso}
          name={researchNews.company?.name}
          onClick={() => {
            if (researchNews?.relatedInstrument) {
              openInstrumentDrawer(instrumentIdContainer)
            }
          }}
          showFlag
        />
      </Td>
      <Td pb={8} pt={8} textAlign="right">
        {formatNumber(estimatesToYear1)}
      </Td>
      <Td pb={8} pt={8} textAlign="right">
        <FormattedPercentage value={estimatesPercentYear1} justifyContent={'flex-end'} colorized decimals={0} />
      </Td>
      <Td pb={8} pt={8} textAlign="right">
        {formatNumber(estimatesToYear2)}
      </Td>
      <Td pb={8} pt={8} textAlign="right">
        <FormattedPercentage value={estimatesPercentYear2} justifyContent={'flex-end'} colorized decimals={0} />
      </Td>
      <Td pb={8} pt={8} textAlign="right">
        {formatNumber(estimatesToYear3)}
      </Td>
      <Td pb={8} pt={8} textAlign="right">
        <FormattedPercentage value={estimatesPercentYear3} justifyContent={'flex-end'} colorized decimals={0} />
      </Td>
      <Td pb={8} pt={8} textAlign="right">
        {checkToday(new Date(researchNews.created), {
          short: true,
          showTodayAsTime: true,
        })}
      </Td>
      <Td pb={8} pt={8} textAlign="right">
        {researchNews?.relatedInstrument && (
          <ResearchNewsMoreMenu size="small" instrumentIdContainer={instrumentIdContainer} />
        )}
      </Td>
    </Tr>
  )
})

export const EpsRevisionsList = observer(() => {
  const { t } = useTranslation()
  const theme = useDuploTheme()
  const breakpoint = useBreakpoint()
  const isExtraSmallScreen = breakpoint < Breakpoint.Small

  const currentPage = usePageParam()

  const { researchNews } = useContentAnalysis({
    limit: 10,
    page: currentPage,
    researchFlags: {
      isEstimatesFlag: true,
    },
    includeRelatedInstrument: true,
  })

  const currentYear =
    (researchNews && researchNews?.[0]?.researchTargets?.estimatesYearStart) ?? new Date().getFullYear()

  if (isExtraSmallScreen) {
    return (
      <SkeletonLoader p={16} height={128} loading={!researchNews}>
        {() =>
          researchNews?.map((rn: ResearchNews, index: number) => <EpsRevisionsRow researchNews={rn} key={index} />)
        }
      </SkeletonLoader>
    )
  }

  return (
    <SkeletonLoader p={16} height={128} loading={!researchNews}>
      {() => (
        <Table css={{ tableLayout: 'fixed' }}>
          <THead>
            <Tr css={{ borderBottom: `1px solid ${theme.colors['bunker-60']}` }}>
              <Th textAlign="left" width="auto">
                {t('Namn')}
              </Th>
              <Th textAlign="right" width={'10%'}>
                {`${currentYear}e`}
              </Th>
              <Th textAlign="right" width={'8%'}>
                {t('%')}
              </Th>
              <Th textAlign="right" width={'10%'}>
                {`${currentYear + 1}e`}
              </Th>
              <Th textAlign="right" width={'8%'}>
                {t('%')}
              </Th>
              <Th textAlign="right" width={'10%'}>
                {`${currentYear + 2}e`}
              </Th>
              <Th textAlign="right" width={'8%'}>
                {t('%')}
              </Th>
              <Th textAlign="right" width={'10%'}>
                {t('Tid')}
              </Th>
              <Th width={48} textAlign="right"></Th>
            </Tr>
          </THead>
          <TBody>
            {researchNews?.map((rn: ResearchNews, index: number) => {
              return <EpsRevisionsRow researchNews={rn} key={index} />
            })}
          </TBody>
        </Table>
      )}
    </SkeletonLoader>
  )
})
