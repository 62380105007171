import { useDrawerProps } from '@common/components/drawer/useDrawerStore'
import { useArticle } from '@common/hooks/useArticle'

import { Box, Breakpoint, FlexCol, GridContainer, GridItem, Spacer, Text, useBreakpoint } from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import { CarnegieMaterialHtml } from '../CarnegieMaterialHtml'
import { CarnegieMaterialSimpleHtml } from '../CarnegieMaterialSimpleHtml'
import { CarnegieMaterialTagAndDate } from '../CarnegieMaterialTagAndDate'
import { CarnegieMaterialHeading } from '../seminarDrawer/CarnegieMaterialHeading'

const ArticleDrawer = observer(() => {
  const { routeProps: id } = useDrawerProps()
  const { article } = useArticle(id)

  const breakpoint = useBreakpoint()
  const isMobile = breakpoint < Breakpoint.Small

  // Todo real skeleton
  if (!article) return null

  const { author, date, message, messageImage, messageIntroduction, title } = article

  return (
    <>
      <CarnegieMaterialHeading isMobile={isMobile} title={title} />
      {!isMobile && <Spacer height={8} />}
      <GridContainer spacing={0} style={{ justifyContent: 'flex-start' }}>
        <GridItem md={8} lg={8}>
          <CarnegieMaterialTagAndDate
            date={new Date(date)}
            tags={`${article.formattedCategories[0]?.name} ${author ? ` - ${author}` : ''}`}
          />
          <Spacer height={isMobile ? 16 : 32} />
          <Text variant="subtitle1" truncate={false}>
            <CarnegieMaterialSimpleHtml html={messageIntroduction} />
          </Text>
          {messageImage && <ArticleImg isMobile={isMobile} messageImage={messageImage} />}
          <Spacer height={isMobile ? 16 : 32} />
          <Box
            width="full"
            css={{
              iframe: { width: '100%', height: 372 },
            }}
          >
            <CarnegieMaterialHtml html={message} />
          </Box>
        </GridItem>
        <GridItem lg={4}>
          <div />
        </GridItem>
      </GridContainer>
    </>
  )
})

const ArticleImg = ({ isMobile, messageImage }: { isMobile: boolean; messageImage: string }) => {
  return (
    <FlexCol flex="none">
      {/* FlexCol to fix safari height auto issue 
         https://dev.azure.com/carnegieinvestmentbank/CarnegieIT/_workitems/edit/72769
         */}
      <Spacer height={isMobile ? 0 : 16} />
      <img src={messageImage} alt="article-img" />
    </FlexCol>
  )
}

export default ArticleDrawer
