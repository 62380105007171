import { ResearchNews } from '@common/api/response'
import { useOpenDrawer } from '@common/components/drawer/useOpenDrawer'

export function useOpenResearchDrawer() {
  const { openDrawer } = useOpenDrawer()

  const openResearchDrawer = (researchNews: ResearchNews) => {
    const isResearch = researchNews.type === 'Research'
    const researchId = researchNews.id
      ? researchNews.id //RDS Id
      : isResearch
      ? researchNews?.researchId
      : researchNews?.newsId

    openDrawer(isResearch ? 'research' : 'research_comments', researchId?.toString())
  }

  return openResearchDrawer
}
