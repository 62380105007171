import { useMatch } from 'react-router-dom'

/**
 * Returns the selected account id from the url.
 */
export function useSelectedAccountId() {
  const accounts = useMatch('/accounts/:initialSelectedAccountId/*')
  let initialSelectedAccountId = accounts?.params?.initialSelectedAccountId

  if (initialSelectedAccountId) {
    return initialSelectedAccountId
  }

  const orders = useMatch('/overview/orders/:initialSelectedAccountId/*')

  initialSelectedAccountId = orders?.params?.initialSelectedAccountId

  return initialSelectedAccountId
}
