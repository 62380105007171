import { Squircle } from '@common/components/Squircle'

import { ReactNode } from 'react'

import { DuploColor, FlexRow } from '@carnegie/duplo'

import CrIconSvg from './cr.svg'
import GlobeIconSvg from './globe.svg'
import MessageIconSvg from './message.svg'

export const ResearchListItemIcon = ({
  children,
  color,
  backgroundColor = 'paprika-main',
}: ResearchListItemIconProps) => {
  return (
    <Squircle size={40}>
      <FlexRow
        color={color}
        alignItems="center"
        justifyContent="center"
        width={40}
        height={40}
        backgroundColor={backgroundColor}
      >
        {children}
      </FlexRow>
    </Squircle>
  )
}

export const GlobeIcon = () => {
  return <GlobeIconSvg width={27} height={27} />
}

export const MessageIcon = () => {
  return <MessageIconSvg width={27} height={27} />
}

type CommissionedResearchIconProps = {
  size?: 24 | 32
  hasBackground?: boolean
}

export const CommissionedResearchIcon = ({ hasBackground, size = 32 }: CommissionedResearchIconProps) => {
  const color = hasBackground ? '#F3E6DD' : '#7B0024'
  return <CrIconSvg width={size} height={size} style={{ color }} />
}

type ResearchListItemIconProps = {
  children?: ReactNode
  backgroundColor?: DuploColor
  color?: DuploColor
}
