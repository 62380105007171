import { useTranslation } from 'react-i18next'

import { Paragraph } from '@carnegie/duplo'

type DisclaimerProps = {
  instrumentId?: string
  isCommissionedResearch?: boolean
  isInstrument?: boolean
  isFairValue?: true | false | null
}

export const Disclaimer = ({ isInstrument = false, isCommissionedResearch, isFairValue }: DisclaimerProps) => {
  const { t } = useTranslation()

  if (isInstrument && isFairValue === null) {
    return null
  }

  if (isInstrument) {
    return (
      <>
        <Paragraph variant="body2" mt={32}>
          {t('Viktig information om Carnegie Analys')}
        </Paragraph>
        <Paragraph variant="body2" color="text-low-emphasis">
          {isFairValue
            ? t(
                'Detta är en uppdragsanalys framtagen av Carnegie Investment Bank AB (Carnegie). Denna uppdragsanalys tillhandahålls endast för informationsändamål ska inte användas eller betraktas som ett erbjudande att sälja, eller en uppmaning att köpa eller sälja, något relevant finansiellt instrument. Denna uppdragsanalys har beställts och betalats av emittenten och bör därför betraktas som en marknadsföringskommunikation (dvs inte investeringsrekommendation).'
              )
            : t(
                'På den här sidan presenteras investeringsrekommendationer framtagna av Carnegie Investment Bank AB. Investeringsrekommendationen tar ej hänsyn till dina personliga finansiella omständigheter, investeringsmål eller specifika behov. Därför ska informationen inte tolkas som finansiell rådgivning, ett erbjudande om eller uppmaning till transaktioner med finansiella instrument.'
              )}
        </Paragraph>
      </>
    )
  }

  // isResearchDrawer
  return (
    <>
      <Paragraph variant="body2" mt={32}>
        {t('Viktig information om Carnegie Analys')}
      </Paragraph>
      {isCommissionedResearch ? (
        <Paragraph variant="body2" color="text-low-emphasis">
          {t(
            'Detta är en uppdragsanalys framtagen av Carnegie Investment Bank AB (Carnegie). Denna uppdragsanalys tillhandahålls endast för informationsändamål ska inte användas eller betraktas som ett erbjudande att sälja, eller en uppmaning att köpa eller sälja, något relevant finansiellt instrument. Denna uppdragsanalys har beställts och betalats av emittenten och bör därför betraktas som en marknadsföringskommunikation (dvs inte investeringsrekommendation).'
          )}
        </Paragraph>
      ) : (
        <>
          <Paragraph variant="body2" color="text-low-emphasis">
            {t(
              'Carnegie Private Banking inom Carnegie Investment Bank AB fungerar som spridningskanal för de investeringsrekommendationer (rekommendationer) som Carnegie Research inom Carnegie Investment Bank AB tar fram i form av Carnegie Reach. Carnegie Private Banking gör inget urval kring vilka rekommendationer som sprids. Rekommendationen har redan distribuerats till Carnegie Research kunder. Tidpunkten för när rekommendationen spreds första gången återfinns på rekommendationens första sida.'
            )}
          </Paragraph>
          <Paragraph mt={16} variant="body2" color="text-low-emphasis">
            {t(
              'Carnegie Investment Bank AB (Carnegie) står under Finansinspektionens tillsyn och har tillstånd att driva bankrörelse och samtliga tillstånd att bedriva värdepappersrörelse. Carnegie strävar efter att, genom att tillämpa fasta rutiner, undvika intressekonflikter mellan banken och dess kunder eller mellan bankens kunder. Rutinerna är dokumenterade i Carnegies riktlinjer rörande hantering av intressekonflikter. Om rutinerna och de åtgärder som Carnegie har vidtagit för att undvika en intressekonflikt i en specifik situation inte räcker för att förhindra att kundens intressen kan komma att påverkas negativt ska Carnegie informera kunden om arten av eller källan till intressekonflikten.'
            )}
          </Paragraph>
          <Paragraph mt={16} variant="body2" color="text-low-emphasis">
            {t(
              'På den här sidan presenteras investeringsrekommendationer framtagna av Carnegie Investment Bank AB. Investeringsrekommendationen tar ej hänsyn till dina personliga finansiella omständigheter, investeringsmål eller specifika behov. Därför ska informationen inte tolkas som finansiell rådgivning, ett erbjudande om eller uppmaning till transaktioner med finansiella instrument.'
            )}
          </Paragraph>
        </>
      )}
    </>
  )
}

Disclaimer.displayName = 'Disclaimer'
