import { CarnegieInstrument } from '@common/api/response'
import { NoDataDash, NoDataFallback } from '@common/components/NoDataFallback'
import { ObservableInfrontInstrument } from '@common/hooks/infront/sdk/observableInfrontInstrument'
import { useInfrontInstrument } from '@common/hooks/infront/sdk/useInfrontInstrument'
import { useCarnegieInstrument } from '@common/hooks/useCarnegieInstrument'
import { InstrumentIdContainer } from '@common/instrumentIdContainer'

import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { formatNumber } from '@carnegie/digital-channels-frontend'
import { Box, ListItem, ListItemRow, ListItemWithAccordion, Paragraph, Segment, Spacer, Text } from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import i18n from '../../../i18n'
import { ListItemValue } from '../ListItemValue'

type InstrumentFundsTradingAndFeesProps = {
  className?: string
  children?: ReactNode
  instrumentIdContainer: InstrumentIdContainer
  collateralRatio: number
}

function isNullish(val: unknown) {
  return val === undefined || val === null ? true : false
}

export const InstrumentFundsTradingAndFees = observer(
  ({ className, instrumentIdContainer, collateralRatio }: InstrumentFundsTradingAndFeesProps) => {
    const { t } = useTranslation()

    const { carnegieInstrument, isLoading: isLoadingCarnegieInstrument } = useCarnegieInstrument(
      instrumentIdContainer,
      i18n.language
    )

    const { observableInstrument, isLoading: isLoadingInfrontInstrument } = useInfrontInstrument(
      instrumentIdContainer?.infrontInstrument,
      [
        InfrontSDK.SymbolField.Last,
        InfrontSDK.SymbolField.PreLastTradedAt,
        InfrontSDK.SymbolField.LastValidDate,
        InfrontSDK.SymbolField.FundPurchaseFee,
        InfrontSDK.SymbolField.FundRedemptionFee,
        InfrontSDK.SymbolField.FundManagementFee,
        InfrontSDK.SymbolField.FundOngoingCharge,
        InfrontSDK.SymbolField.FundPerformanceFee,
        InfrontSDK.SymbolField.Currency,
      ],
      { observe: false }
    )

    const last = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.Last)
    const preLastTradedAt = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.PreLastTradedAt)
    const lastValidDate = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.LastValidDate)
    const currency = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.Currency)

    //fallback
    const lastOrPreLastTradedAt = last ? last : preLastTradedAt

    return (
      <div className={className}>
        <Text variant="overline" mb={8}>
          {t('Handel')}
        </Text>
        <Segment noContentPadding>
          <ListItem divider>
            <ListItemRow
              title={
                <Text variant="support2">
                  {t('Senast NAV')} (
                  {isLoadingInfrontInstrument ? (
                    ''
                  ) : (
                    <NoDataFallback>{InfrontUtil.formatDate(lastValidDate)}</NoDataFallback>
                  )}
                  )
                </Text>
              }
              value={
                <ListItemValue
                  loading={isLoadingInfrontInstrument}
                  value={
                    lastOrPreLastTradedAt ? formatNumber(lastOrPreLastTradedAt, { decimals: 2 }) : lastOrPreLastTradedAt
                  }
                  unit={currency}
                />
              }
            />
          </ListItem>
          <ListItem divider>
            <ListItemRow
              title={<Text variant="support2">{t('Handelsintervall köp')}</Text>}
              value={
                <ListItemValue loading={isLoadingCarnegieInstrument} value={carnegieInstrument?.tradingPeriodBuy} />
              }
            />
          </ListItem>
          <ListItem divider>
            <ListItemRow
              title={<Text variant="support2">{t('Handelsintervall sälj')}</Text>}
              value={
                <ListItemValue loading={isLoadingCarnegieInstrument} value={carnegieInstrument?.tradingPeriodSell} />
              }
            />
          </ListItem>
          <ListItem divider>
            <ListItemRow
              title={<Text variant="support2">{t('Bryttid för handel')}</Text>}
              value={
                <ListItemValue
                  loading={isLoadingCarnegieInstrument}
                  value={carnegieInstrument?.internalOrderCutOffTime}
                />
              }
            />
          </ListItem>
          <ListItem divider>
            <ListItemRow
              title={<Text variant="support2">{t('Affärsdag köp')}</Text>}
              value={<ListItemValue loading={isLoadingCarnegieInstrument} value={carnegieInstrument?.businessDayBuy} />}
            />
          </ListItem>
          <ListItem divider>
            <ListItemRow
              title={<Text variant="support2">{t('Affärsdag sälj')}</Text>}
              value={
                <ListItemValue loading={isLoadingCarnegieInstrument} value={carnegieInstrument?.businessDaySell} />
              }
            />
          </ListItem>
          <ListItem divider>
            <ListItemRow
              title={<Text variant="support2">{t('Handlas i')}</Text>}
              value={<ListItemValue loading={isLoadingCarnegieInstrument} value={currency} />}
            />
          </ListItem>
          <ListItem divider>
            <ListItemRow
              title={<Text variant="support2">{t('Belåningsgrad')}</Text>}
              value={<ListItemValue value={formatNumber(collateralRatio, { ratio: false, decimals: 0 })} unit="%" />}
            />
          </ListItem>
        </Segment>
        <Spacer height={24} />
        <Text variant="overline" mb={8}>
          {t('Avgifter')}
        </Text>
        <Segment noContentPadding>
          <InstrumentFundsFees
            carnegieInstrument={carnegieInstrument}
            observableInstrument={observableInstrument}
            isLoadingInfrontInstrument={isLoadingCarnegieInstrument}
          />
        </Segment>
      </div>
    )
  }
)
InstrumentFundsTradingAndFees.displayName = 'InstrumentFundsTradingAndFees'

type InstrumentFundsFeesProps = {
  observableInstrument: ObservableInfrontInstrument
  carnegieInstrument: CarnegieInstrument
  isLoadingInfrontInstrument: boolean
  shownFees?: 'all' | 'management-fee'
}

export const InstrumentFundsFees = observer(
  ({
    observableInstrument,
    carnegieInstrument,
    isLoadingInfrontInstrument,
    shownFees = 'all',
  }: InstrumentFundsFeesProps) => {
    const { t } = useTranslation()
    const fundOngoingCharge = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.FundOngoingCharge)
    const fundPurchaseFee = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.FundPurchaseFee)
    const fundRedemptionFee = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.FundRedemptionFee)
    const fundPerformanceFee = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.FundPerformanceFee)

    const managementFee = (
      <ListItemWithAccordion
        divider={shownFees === 'all'}
        expandableContent={
          <Box pt={8} px={16} pb={12}>
            <Text variant="support1" color="text-low-emphasis">
              {t('Varav distributionsavgift')}
            </Text>
            :{' '}
            {!isNullish(carnegieInstrument?.distributionFee) ? (
              <>
                <Text variant="subtitle2" color="text-low-emphasis">
                  {formatNumber(carnegieInstrument?.distributionFee, { decimals: { min: 0, max: 6 } })}
                </Text>
                <Text ml={2} variant="label2" color="text-low-emphasis">
                  %
                </Text>
              </>
            ) : (
              <NoDataDash />
            )}
            <Box m={8} />
            <Paragraph variant="support3" color="text-low-emphasis">
              {t(
                `Utifrån en investering på 100 000 kr i ett år skulle {amount} kr tillfalla Carnegie som distributionsavgift. Distributionsavgift är ersättning som Carnegie erhåller från fond- och försäkringsbolag, eller annan tredje part. Ersättningen är en del av den totala förvaltnings- eller försäkringsavgift som du betalar. Distributionsavgiften innebär således inte någon extra kostnad utan tydliggör enbart hur stor del av förvaltningsavgiften som tillfaller Carnegie som ersättning för distributionen.`
              ).replaceAll('{amount}', formatNumber((100000 * carnegieInstrument?.distributionFee) / 100))}
            </Paragraph>
          </Box>
        }
      >
        <ListItemRow
          title={<Text variant="support1">{t('Förvaltningsavgift')}</Text>}
          value={
            <ListItemValue
              loading={isLoadingInfrontInstrument}
              value={!isNullish(fundOngoingCharge) ? formatNumber(fundOngoingCharge) : fundOngoingCharge}
              unit={'%'}
            />
          }
        />
        <Text variant="support3" color="text-low-emphasis" textAlign="left">
          {t('Avgift som fondbolaget tar ut för att förvalta och administrera fonden.')}
        </Text>
      </ListItemWithAccordion>
    )

    if (shownFees === 'management-fee') return managementFee

    return (
      <>
        {managementFee}
        <ListItem divider>
          <ListItemRow
            title={<Text variant="support2">{t('Administrativ avgift per order')}</Text>}
            value={<ListItemValue value={carnegieInstrument?.fundTransactionFee} unit="SEK" />}
          />
          <Text variant="support3" color="text-low-emphasis" textAlign="left">
            {t('Avgift som Carnegie tar ut för genomförande av order.')}
          </Text>
        </ListItem>
        <ListItem divider>
          <ListItemRow
            title={<Text variant="support2">{t('Teckningsavgift')}</Text>}
            value={
              <ListItemValue
                loading={isLoadingInfrontInstrument}
                value={!isNullish(fundPurchaseFee) ? formatNumber(fundPurchaseFee) : fundPurchaseFee}
                unit={'%'}
              />
            }
          />
          <Text variant="support3" color="text-low-emphasis" textAlign="left">
            {t('Engångsavgift som fondbolaget kan ta ut vid teckning (köpavgift).')}
          </Text>
        </ListItem>
        <ListItem divider>
          <ListItemRow
            title={<Text variant="support2">{t('Inlösenavgift')}</Text>}
            value={
              <ListItemValue
                loading={isLoadingInfrontInstrument}
                value={!isNullish(fundRedemptionFee) ? formatNumber(fundRedemptionFee) : fundRedemptionFee}
                unit={'%'}
              />
            }
          />
          <Text variant="support3" color="text-low-emphasis" textAlign="left">
            {t('Engångsavgift som fondbolaget kan ta ut vid inlösen (säljavgift).')}
          </Text>
        </ListItem>
        <ListItem divider>
          <ListItemRow
            title={<Text variant="support2">{t('Resultatbaserad avgift')}</Text>}
            value={
              <ListItemValue
                loading={isLoadingInfrontInstrument}
                value={!isNullish(fundPerformanceFee) ? formatNumber(fundPerformanceFee) : fundPerformanceFee}
                unit={'%'}
              />
            }
          />
          <Text variant="support3" color="text-low-emphasis" textAlign="left">
            {t('Avgift som tas ut om resultatet överstiger en uppsatt nivå. Se fondfaktabladet för mer information.')}{' '}
          </Text>
        </ListItem>
      </>
    )
  }
)
InstrumentFundsFees.displayName = 'InstrumentFundsFees'
