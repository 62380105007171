import { ReactElement, ReactNode } from 'react'

import { Menu, MenuItems, MenuToggle } from '@carnegie/duplo'

type DropdownButtonProps = {
  children?: ReactNode
  button: (open: boolean) => ReactElement
}

export const DropdownButton = ({ children, button }: DropdownButtonProps) => {
  return (
    <Menu>
      <MenuToggle>{button}</MenuToggle>
      <MenuItems>{children}</MenuItems>
    </Menu>
  )
}
