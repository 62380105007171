/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export type AccountAssetAllocationResponse = {
  /** @format uuid */
  id?: string
  allocationModel?: HoldingAllocationModel[] | null
}

export type AccountDividend = {
  /** @format uuid */
  instrumentId?: string
  instrumentName?: string | null
  account?: CurrencyAmount
  original?: CurrencyAmount
  category?: AccountDividendCategory
  infrontInstrument?: InfrontInstrument
  marketIso?: string | null
}

export enum AccountDividendCategory {
  CouponCash = 'CouponCash',
  DividendCash = 'DividendCash',
  AccruedCoupon = 'AccruedCoupon',
}

export type AccountDividendsResponse = {
  dividends?: AccountDividend[] | null
  couponInterest?: AccountDividend[] | null
  accruedCouponInterest?: AccountDividend[] | null
}

export type AccountInformation = {
  owners?: string[] | null
  relation?: string | null
  policyHolder?: string | null
  accountType?: AccountType
  isPolicyHolder?: boolean
  primaryOwner?: boolean
  accountDescription?: string | null
  serviceType?: string | null
  accountRole?: string | null
  accountResponsibles?: PartyResponsible[] | null
}

export type AccountInterestRate = {
  currencyCode?: string | null
  /** @format double */
  interestRateDebit?: number
  /** @format double */
  interestRateCredit?: number
}

export type AccountPerformanceDataPoint = {
  /** @format date-time */
  timestamp?: string
  /** @format double */
  marketValue?: number
  /** @format double */
  changeSinceStartPercent?: number
}

export type AccountPerformanceSeries = {
  /** @format uuid */
  accountId?: string
  performanceSeries?: PerformanceSeries
}

export type AccountPerformanceSeriesResponse = {
  accountsPerformanceSeries?: AccountPerformanceSeries[] | null
}

export type AccountRegionAllocationResponse = {
  /** @format uuid */
  id?: string
  allocationModel?: HoldingAllocationModel[] | null
}

export type AccountResponse = {
  /** @format uuid */
  id?: string
  number?: string | null
  currencyCode?: string | null
  ocrNumber?: string | null
  information?: AccountInformation
  holdings?: Holdings
  isTradeable?: boolean
  accountInterestRates?: AccountInterestRate[] | null
}

export type AccountSummary = {
  /** @format uuid */
  accountId?: string
  marketValue?: CurrencyAmount
  liquidAsset?: CurrencyAmount
  portfolioValue?: CurrencyAmount
  accrued?: CurrencyAmount
  collateralValue?: CurrencyAmount
  acquisitionValue?: CurrencyAmount
  creditLimit?: CurrencyAmount
  accruedCoupon?: CurrencyAmount
  couponCash?: CurrencyAmount
  dividendCash?: CurrencyAmount
  marginRequirements?: CurrencyAmount
  withdrawableAmount?: CurrencyAmount
  tradingCapacity?: CurrencyAmount
}

export type AccountSummaryResponse = {
  accountSummary?: AccountSummary
  canReceivePendingAmount?: boolean
  canReceivePendingOrderAmount?: boolean
  canReceiveTradingCapacity?: boolean
}

export type AccountType = {
  shortName?: string | null
  description?: string | null
  isDepositAndWithdrawLocked?: boolean
  insuranceType?: string | null
}

export type AccountsResponse = {
  /** @format uuid */
  id?: string
  number?: string | null
  owners?: string[] | null
  currencyCode?: string | null
  primaryOwner?: boolean
  /** @format date-time */
  endDate?: string | null
  isTradeable?: boolean
  isDepositAndExternalWithdrawEnabled?: boolean
  accountType?: AccountType
  ocrNumber?: string | null
  policyHolder?: string | null
  isPolicyHolder?: boolean
  isAccountStatusOpen?: boolean
}

export type AccountsSummariesResponse = {
  accountSummaries?: AccountSummary[] | null
}

export type ActionResult = object

export type AddWatchlistItemRequest = {
  /** @format uuid */
  id?: string
  /** @format uuid */
  instrumentId?: string
}

export type AddWatchlistItemResponse = object

export enum AnalysisTypeName {
  Research = 'Research',
  News = 'News',
}

export type AssertionRequest = {
  token?: string | null
}

export type Asset = {
  instrumentCurrency?: string | null
  instrumentName?: string | null
  /** @format double */
  valuationPrice?: number
  /** @format double */
  holdingMarketValuePortfolio?: number
  /** @format double */
  holdingQuantity?: number
  /** @format double */
  weight?: number
  /** @format uuid */
  instrumentId?: string
}

export type AvailableAccount = {
  /** @format uuid */
  id?: string
  number?: string | null
  accountType?: AccountType
  primaryOwner?: boolean
  owners?: string[] | null
  withdrawableAmount?: CurrencyAmount
  policyHolder?: string | null
  isPolicyHolder?: boolean
  validAsFromAccount?: boolean
  validAsToAccount?: boolean
  isDisabled?: boolean
}

export type AvailableAccountsResponse = {
  availableAccounts?: AvailableAccount[] | null
  availableDefaultAccounts?: Record<string, DefaultAccount[]>
  transferLimit?: TransferAmountLimitsRepsonse
  canReceivePendingOrderAmount?: boolean
  canReceivePendingAmount?: boolean
  validationErrorCodes?: string[] | null
}

export type AvatarResponse = {
  imageBase64?: string | null
}

export type CancelSimpleOrderRequest = {
  /** @format uuid */
  accountId: string
  /** @format uuid */
  orderId: string
}

export type CancelSimpleOrderResponse = {
  canceled?: boolean
}

export type CancelTransferOrderRequest = {
  /** @format int64 */
  transferOrderId?: number
}

export type CarnegieBaseTag = {
  /** @format int32 */
  id?: number
  name?: string | null
}

export enum CarnegieContentType {
  PodcastEpisode = 'PodcastEpisode',
  Article = 'Article',
  Seminar = 'Seminar',
  Subscription = 'Subscription',
  Analysis = 'Analysis',
  Investment = 'Investment',
}

export type CarnegieInstrumentByIdResponse = {
  name?: string | null
}

export enum CarnegieInstrumentGroupType {
  Equity = 'Equity',
  MutualFunds = 'MutualFunds',
  Etf = 'Etf',
  Bonds = 'Bonds',
  CertificatesWarrants = 'CertificatesWarrants',
  OptionsForwardsFutures = 'OptionsForwardsFutures',
  Others = 'Others',
}

export type CarnegieNewsCategory = {
  /** @format int32 */
  id?: number
  /** @format int32 */
  categoryId?: number
  name?: string | null
}

export type CarnegieNewsCategoryResponseDto = {
  /** @format int32 */
  categoryId?: number
  name?: string | null
}

export type CarnegieNewsCategoryResponseDtoEnvelopeItemsDescriptor = {
  items?: CarnegieNewsCategoryResponseDto
  /** @format int32 */
  httpStatus?: number
  error?: string | null
  problemDetails?: ProblemDetails
}

export type CarnegieNewsModel = {
  authorTitle?: string | null
  authorImage?: string | null
  message?: string | null
  messageImage?: string | null
  messageIntroduction?: string | null
  publishDate?: string | null
  highlight?: boolean
  formattedCategories?: CarnegieNewsCategory[] | null
  carnegieContentType?: CarnegieContentType
  id?: string | null
  thumbnail?: string | null
  /** @format date-time */
  date?: string
  title?: string | null
  link?: string | null
  tags?: CarnegieBaseTag[] | null
  summary?: string | null
  description?: string | null
  author?: string | null
  category?: string | null
}

export type CarnegieNewsModelEnvelopeItemDescriptor = {
  item?: CarnegieNewsModel
  /** @format int32 */
  httpStatus?: number
  error?: string | null
  problemDetails?: ProblemDetails
}

export type CarnegieNewsModelPaginationResponse = {
  items?: CarnegieNewsModel[] | null
  /** @format int32 */
  totalPages?: number
  /** @format int32 */
  currentPage?: number
  /** @format int32 */
  usingLimit?: number | null
  /** @format int32 */
  totalRows?: number
  httpStatus?: HttpStatusCode
  error?: string | null
  success?: boolean
  problemDetails?: ProblemDetails
}

export type CarnegieSummaryWrapper = {
  id?: string | null
  carnegieContentType?: CarnegieContentType
  /** @format date-time */
  date?: string
  thumbnail?: string | null
  title?: string | null
  summary?: string | null
  category?: string | null
  categories?: string[] | null
  author?: string | null
  tags?: string[] | null
}

export type CarnegieSummaryWrapperPaginationResponse = {
  items?: CarnegieSummaryWrapper[] | null
  /** @format int32 */
  totalPages?: number
  /** @format int32 */
  currentPage?: number
  /** @format int32 */
  usingLimit?: number | null
  /** @format int32 */
  totalRows?: number
  /** @format int32 */
  httpStatus?: HttpStatusCode
  error?: string | null
  success?: boolean
  problemDetails?: ProblemDetails
}

export type CorePick = {
  relatedInstrument?: RelatedInstrument
  recommendation?: string | null
  /** @format int32 */
  companyId?: number
  companyName?: string | null
  /** @format float */
  insertPrice?: number | null
  isNew?: boolean
  publishDate?: string | null
  /** @format float */
  targetPrice?: number | null
  /** @format float */
  targetPricePrevious?: number | null
  targetPriceCurrency?: string | null
  /** @format date-time */
  targetRevison?: string | null
  marketIso?: string | null
}

export type CreateWatchlistRequest = {
  name?: string | null
}

export type CreateWatchlistResponse = {
  /** @format uuid */
  id?: string
  name?: string | null
  instrumentIds?: string[] | null
}

export type Currency = {
  /** @format double */
  value?: number
  currencyCode?: string | null
}

export type CurrencyAmount = {
  /** @format double */
  amount?: number | null
  currencyCode?: string | null
}

export type CurrencyPosition = {
  currency?: string | null
  /** @format double */
  quantity?: number | null
  /** @format double */
  marketValue?: number | null
  /** @format double */
  fxRate?: number | null
}

export enum CurrencyType {
  NotSet = 'NotSet',
  AED = 'AED',
  AFN = 'AFN',
  ALL = 'ALL',
  AMD = 'AMD',
  ANG = 'ANG',
  AOA = 'AOA',
  ARS = 'ARS',
  AUD = 'AUD',
  AWG = 'AWG',
  AZN = 'AZN',
  BAM = 'BAM',
  BBD = 'BBD',
  BDT = 'BDT',
  BGN = 'BGN',
  BHD = 'BHD',
  BIF = 'BIF',
  BMD = 'BMD',
  BND = 'BND',
  BOB = 'BOB',
  BRL = 'BRL',
  BSD = 'BSD',
  BTN = 'BTN',
  BWP = 'BWP',
  BYN = 'BYN',
  BYR = 'BYR',
  BZD = 'BZD',
  CAD = 'CAD',
  CDF = 'CDF',
  CHF = 'CHF',
  CLP = 'CLP',
  CNH = 'CNH',
  CNY = 'CNY',
  COP = 'COP',
  CRC = 'CRC',
  CUC = 'CUC',
  CUP = 'CUP',
  CVE = 'CVE',
  CZK = 'CZK',
  DJF = 'DJF',
  DKK = 'DKK',
  DOP = 'DOP',
  DZD = 'DZD',
  EGP = 'EGP',
  ERN = 'ERN',
  ETB = 'ETB',
  EUR = 'EUR',
  FJD = 'FJD',
  FKP = 'FKP',
  GBP = 'GBP',
  GBX = 'GBX',
  GEL = 'GEL',
  GGP = 'GGP',
  GHS = 'GHS',
  GIP = 'GIP',
  GMD = 'GMD',
  GNF = 'GNF',
  GRD = 'GRD',
  GTQ = 'GTQ',
  GYD = 'GYD',
  HKD = 'HKD',
  HNL = 'HNL',
  HRK = 'HRK',
  HTG = 'HTG',
  HUF = 'HUF',
  IDR = 'IDR',
  ILS = 'ILS',
  IMP = 'IMP',
  INR = 'INR',
  IQD = 'IQD',
  IRR = 'IRR',
  ISK = 'ISK',
  JEP = 'JEP',
  JMD = 'JMD',
  JOD = 'JOD',
  JPY = 'JPY',
  KES = 'KES',
  KGS = 'KGS',
  KHR = 'KHR',
  KMF = 'KMF',
  KPW = 'KPW',
  KRW = 'KRW',
  KWD = 'KWD',
  KYD = 'KYD',
  KZT = 'KZT',
  LAK = 'LAK',
  LBP = 'LBP',
  LKR = 'LKR',
  LRD = 'LRD',
  LSL = 'LSL',
  LTL = 'LTL',
  LYD = 'LYD',
  MAD = 'MAD',
  MDL = 'MDL',
  MGA = 'MGA',
  MKD = 'MKD',
  MMK = 'MMK',
  MNT = 'MNT',
  MOP = 'MOP',
  MRO = 'MRO',
  MUR = 'MUR',
  MWK = 'MWK',
  MVR = 'MVR',
  MXN = 'MXN',
  MYR = 'MYR',
  MZN = 'MZN',
  NAD = 'NAD',
  NGN = 'NGN',
  NIO = 'NIO',
  NOK = 'NOK',
  NPR = 'NPR',
  NZD = 'NZD',
  OMR = 'OMR',
  PAB = 'PAB',
  PEN = 'PEN',
  PGK = 'PGK',
  PHP = 'PHP',
  PKR = 'PKR',
  PLN = 'PLN',
  PYG = 'PYG',
  QAR = 'QAR',
  RON = 'RON',
  RSD = 'RSD',
  RUB = 'RUB',
  RWF = 'RWF',
  SAR = 'SAR',
  SBD = 'SBD',
  SCR = 'SCR',
  SDG = 'SDG',
  SEK = 'SEK',
  SGD = 'SGD',
  SHP = 'SHP',
  SLL = 'SLL',
  SOS = 'SOS',
  SPL = 'SPL',
  SRD = 'SRD',
  STD = 'STD',
  SVC = 'SVC',
  SYP = 'SYP',
  SZL = 'SZL',
  THB = 'THB',
  TJS = 'TJS',
  TMT = 'TMT',
  TND = 'TND',
  TOP = 'TOP',
  TRY = 'TRY',
  TTD = 'TTD',
  TVD = 'TVD',
  TWD = 'TWD',
  TZS = 'TZS',
  UAH = 'UAH',
  UGX = 'UGX',
  USD = 'USD',
  UYU = 'UYU',
  UZS = 'UZS',
  VEF = 'VEF',
  VND = 'VND',
  WST = 'WST',
  VUV = 'VUV',
  XAF = 'XAF',
  XCD = 'XCD',
  XDR = 'XDR',
  XOF = 'XOF',
  XPF = 'XPF',
  YER = 'YER',
  ZAC = 'ZAC',
  ZAR = 'ZAR',
  ZMW = 'ZMW',
  ZWD = 'ZWD',
}

export type CustomerInbox = {
  /** @format uuid */
  partyId?: string
  name?: string | null
  /** @format int32 */
  unreadCount?: number
  /** @format int32 */
  unreadCountMessages?: number
  /** @format int32 */
  unreadCountMailings?: number
  defaultInbox?: boolean
}

export type CustomerInboxEnvelopeItemsDescriptor = {
  items?: CustomerInbox
  /** @format int32 */
  httpStatus?: number
  error?: string | null
  problemDetails?: ProblemDetails
}

export type CustomerPermissionFlags = {
  hasAnalysisPackage?: boolean
  hasReach?: boolean
}

export type CustomerReportModel = {
  /** @format uuid */
  documentId?: string
  /** @format date-time */
  createDate?: string
  description?: string | null
  documentName?: string | null
  /** @format int32 */
  documentType?: number | null
  documentTypeName?: string | null
  serviceFileType?: ServiceFileType
}

export type CustomerReportModelPaginationResponse = {
  items?: CustomerReportModel[] | null
  /** @format int32 */
  totalPages?: number
  /** @format int32 */
  currentPage?: number
  /** @format int32 */
  usingLimit?: number | null
  /** @format int32 */
  totalRows?: number
  httpStatus?: HttpStatusCode
  error?: string | null
  success?: boolean
  problemDetails?: ProblemDetails
}

export type DefaultAccount = {
  displayNumber?: string | null
  accountType?: AccountType
  validAsToAccount?: boolean
  isDisabled?: boolean
  bankName?: string | null
  /** @format int32 */
  id?: number
  ownerType?: string | null
  /** @format int32 */
  personId?: number
  personName?: string | null
}

export type DeleteWatchlistItemResponse = object

export type DocumentInfoModel = {
  name?: string | null
  /** @format int32 */
  accountNumber?: number
  /** @format date-time */
  statementPeriodStart?: string | null
  /** @format date-time */
  statementPeriodEnd?: string | null
  /** @format uuid */
  documentId?: string
  /** @format date-time */
  createdDate?: string
  suffix?: string | null
}

export type DocumentInfoModelPaginationResponse = {
  items?: DocumentInfoModel[] | null
  /** @format int32 */
  totalPages?: number
  /** @format int32 */
  currentPage?: number
  /** @format int32 */
  usingLimit?: number | null
  /** @format int32 */
  totalRows?: number
  httpStatus?: HttpStatusCode
  error?: string | null
  success?: boolean
  problemDetails?: ProblemDetails
}

export enum DocumentType {
  ContractNote = 'ContractNote',
  AccountStatement = 'AccountStatement',
  AccountStatementYearly = 'AccountStatementYearly',
  IntroductionNotes = 'IntroductionNotes',
  ContractNoteCopy = 'ContractNoteCopy',
  IntroductionNotesCopy = 'IntroductionNotesCopy',
  LoanNotes = 'LoanNotes',
  LoanNotesCopy = 'LoanNotesCopy',
  YearlySummary = 'YearlySummary',
}

export type EmailModel = {
  /** @format int32 */
  id?: number
  email?: string | null
  isPreferred?: boolean | null
}

export type EndAuthorizationRequest = {
  pageUrl?: string | null
}

export type EndAuthorizationResponse = {
  handled?: boolean
  isLoggedIn?: boolean
  csrf?: string | null
}

export type ExtraParams = {
  key?: string | null
  value?: string | null
}

export type FilterOptions = {
  currencies?: string[] | null
  transactionTypes?: StringStringKeyValuePair[] | null
  instruments?: InstrumentFilter[] | null
}

export type FilterRequest = {
  currencies?: string[] | null
  instruments?: string[] | null
  transactionTypes?: string[] | null
  categories?: string[] | null
}

export type FundOrderInfoBuy = {
  /** @format double */
  tradingCapacity?: number | null
  /** @format double */
  transactionFee?: number | null
  /** @format double */
  minimumAmount?: number
  /** @format double */
  maximumAmount?: number | null
}

export type FundOrderInfoSell = {
  /** @format double */
  netAmount?: number | null
  /** @format double */
  ninetyPercentRule?: number | null
  isSellAllAvailable?: boolean
  /** @format double */
  transactionFee?: number | null
  /** @format double */
  minimumAmount?: number
  /** @format double */
  maximumAmount?: number | null
}

export type GenerateReportRequest = {
  /** @format uuid */
  accountId?: string
  /** @format date-time */
  fromDate?: string
  /** @format date-time */
  middleDate?: string
  /** @format date-time */
  toDate?: string
  language?: string | null
  reportName?: ReportTypes
}

export type GenerateReportResponse = {
  filename?: string | null
}

export type GetAnalysisFilterRequest = object

export type GetAnalysisFilterResponse = {
  markets?: ListItem[] | null
  companies?: ListItem[] | null
  sectors?: ListItem[] | null
  productTypes?: ListItem[] | null
  analysts?: ListItem[] | null
}

export type GetAnalysisItemResponse = {
  text?: string | null
  headline?: string | null
  preHeadline?: string | null
  typeName?: string | null
  isin?: string | null
  marketName?: string | null
  marketIso?: string | null
  sectorName?: string | null
  productType?: string | null
  analysts?: string | null
  /** @format date-time */
  date?: string | null
  relatedInstruments?: RelatedInstrument[] | null
  researchTargets?: ResearchTargets
  company?: ListItem
  recommendation?: ListItem
  previousRecommendation?: ListItem
  recommendationArrowDirection?: string | null
  /** @format date-time */
  recommendationRevison?: string | null
  /** @format float */
  targetPrice?: number | null
  /** @format float */
  targetPricePrevious?: number | null
  targetPriceCurrency?: string | null
  /** @format date-time */
  targetPriceRevison?: string | null
}

export type GetAnalysisRequest = {
  /** @format int32 */
  page?: number | null
  /** @format int32 */
  limit?: number | null
  includeRelatedInstrument?: boolean
  includeText?: boolean
  companies?: number[] | null
  markets?: number[] | null
  sectors?: number[] | null
  productTypes?: number[] | null
  analysts?: number[] | null
  typeName?: AnalysisTypeName
  researchFlags?: ResearchFlags
  isTopPick?: boolean | null
  /** @format uuid */
  carnegieInstrumentId?: string | null
  userHasAnalysisPackage?: boolean | null
}

export type GetAnalysisResponse = {
  researchNews?: ResearchNews[] | null
  /** @format int32 */
  totalPages?: number
  /** @format int32 */
  currentPage?: number
  /** @format int32 */
  usingLimit?: number
  /** @format int32 */
  totalRecords?: number
}

export type GetCorePicksRequest = object

export type GetCorePicksResponse = {
  corePicks?: CorePick[] | null
}

export type GetMoversRequest = object

export type GetPossibleSubscribersResponse = {
  internalSubscribers?: PossibleSubscriberModel[] | null
  externalSubscribers?: PossibleSubscriberModel[] | null
}

export type GetRelatedInstrumentsResponse = {
  relatedInstruments?: RelatedInstrument[] | null
}

export type GetSettingsResponse = {
  value?: string | null
  valueType?: string | null
  /** @format date-time */
  latestSavedUtc?: string
}

export type GetSubmissionSummariesResponse = {
  personSubmissionSummary?: PersonSubmissionSummary[] | null
  aggregatedStatus?: PersonStatus
  anyIsExpiringSoon?: boolean
  anyHasExpired?: boolean
}

export type GetSubscriberSettingsResponse = {
  settings?: SubscriberSettingsModel
}

export type GetTransactionFiltersRequest = {
  /** @format uuid */
  accountId: string
  /** @format date-time */
  fromDate?: string | null
  /** @format date-time */
  toDate?: string | null
  language?: string | null
}

export type GetTransactionsRequest = {
  accountIds: string[]
  /** @format date-time */
  fromDate?: string | null
  /** @format date-time */
  toDate?: string | null
  language?: string | null
  /** @format int32 */
  start?: number
  /** @format int32 */
  size?: number
  filter?: FilterRequest
}

export type GetTransferOrdersResponse = {
  /** @format int64 */
  id?: number
  /** @format date-time */
  created?: string
  type?: string | null
  fromAccountNo?: string | null
  /** @format uuid */
  fromAccountId?: string
  toAccountNo?: string | null
  /** @format uuid */
  toAccountId?: string | null
  /** @format double */
  amount?: number
  state?: State
  externalPaymentState?: string | null
  externalPaymentSubState?: string | null
  displayState?: string | null
  isCancelable?: boolean
  /** @format uuid */
  createdByPartyId?: string
}

export type GetTransferOrdersResponseArrayEnvelopeItemsDescriptor = {
  items?: GetTransferOrdersResponse[] | null
  /** @format int32 */
  httpStatus?: number
  error?: string | null
  problemDetails?: ProblemDetails
}

export type GetWatchlistResponse = {
  watchlist?: WatchList
}

export type GetWatchlistsResponse = {
  watchlists?: WatchList[] | null
}

export type HoldingAllocationModel = {
  type?: string | null
  /** @format double */
  amount?: number
  /** @format double */
  percentage?: number
}

export type Holdings = {
  currencies?: CurrencyPosition[] | null
  instruments?: InstrumentPosition[] | null
}

export enum HttpStatusCode {
  Continue = 'Continue',
  SwitchingProtocols = 'SwitchingProtocols',
  Processing = 'Processing',
  EarlyHints = 'EarlyHints',
  OK = 'OK',
  Created = 'Created',
  Accepted = 'Accepted',
  NonAuthoritativeInformation = 'NonAuthoritativeInformation',
  NoContent = 'NoContent',
  ResetContent = 'ResetContent',
  PartialContent = 'PartialContent',
  MultiStatus = 'MultiStatus',
  AlreadyReported = 'AlreadyReported',
  IMUsed = 'IMUsed',
  MultipleChoices = 'MultipleChoices',
  MovedPermanently = 'MovedPermanently',
  Found = 'Found',
  SeeOther = 'SeeOther',
  NotModified = 'NotModified',
  UseProxy = 'UseProxy',
  Unused = 'Unused',
  RedirectKeepVerb = 'RedirectKeepVerb',
  PermanentRedirect = 'PermanentRedirect',
  BadRequest = 'BadRequest',
  Unauthorized = 'Unauthorized',
  PaymentRequired = 'PaymentRequired',
  Forbidden = 'Forbidden',
  NotFound = 'NotFound',
  MethodNotAllowed = 'MethodNotAllowed',
  NotAcceptable = 'NotAcceptable',
  ProxyAuthenticationRequired = 'ProxyAuthenticationRequired',
  RequestTimeout = 'RequestTimeout',
  Conflict = 'Conflict',
  Gone = 'Gone',
  LengthRequired = 'LengthRequired',
  PreconditionFailed = 'PreconditionFailed',
  RequestEntityTooLarge = 'RequestEntityTooLarge',
  RequestUriTooLong = 'RequestUriTooLong',
  UnsupportedMediaType = 'UnsupportedMediaType',
  RequestedRangeNotSatisfiable = 'RequestedRangeNotSatisfiable',
  ExpectationFailed = 'ExpectationFailed',
  MisdirectedRequest = 'MisdirectedRequest',
  UnprocessableEntity = 'UnprocessableEntity',
  Locked = 'Locked',
  FailedDependency = 'FailedDependency',
  UpgradeRequired = 'UpgradeRequired',
  PreconditionRequired = 'PreconditionRequired',
  TooManyRequests = 'TooManyRequests',
  RequestHeaderFieldsTooLarge = 'RequestHeaderFieldsTooLarge',
  UnavailableForLegalReasons = 'UnavailableForLegalReasons',
  InternalServerError = 'InternalServerError',
  NotImplemented = 'NotImplemented',
  BadGateway = 'BadGateway',
  ServiceUnavailable = 'ServiceUnavailable',
  GatewayTimeout = 'GatewayTimeout',
  HttpVersionNotSupported = 'HttpVersionNotSupported',
  VariantAlsoNegotiates = 'VariantAlsoNegotiates',
  InsufficientStorage = 'InsufficientStorage',
  LoopDetected = 'LoopDetected',
  NotExtended = 'NotExtended',
  NetworkAuthenticationRequired = 'NetworkAuthenticationRequired',
}

export type InfrontInstrument = {
  /** @format int32 */
  feed?: number
  ticker?: string | null
}

export type InfrontResponse = {
  token?: string | null
  /** @format date-time */
  expires?: string
  xid?: string | null
  tgwEnabled?: boolean
  /** @format int32 */
  tgwProvider?: number | null
  /** @format int32 */
  tgwService?: number | null
}

export type InsertSimpleOrderRequest = {
  /** @format uuid */
  accountId: string
  /** @format uuid */
  instrumentId: string
  side: Side
  settlementCurrency?: string | null
  sellAll?: boolean
  /** @format double */
  amount?: number | null
  language?: string | null
}

export type InsertSimpleOrderResponse = {
  /** @format uuid */
  orderId?: string
  orderError?: string | null
  orderState?: string | null
}

export type InstrumentFilter = {
  instrumentName?: string | null
  /** @format uuid */
  instrumentId?: string
}

export type InstrumentModel = {
  instrumentIdCarnegie?: string | null
  instrumentType?: string | null
  instrumentTypeName?: string | null
  carnegieInstrumentGroup?: string | null
  isin?: string | null
  lei?: string | null
  symbol?: string | null
  name?: string | null
  currency?: string | null
  /** @format double */
  marketValue?: number | null
  infrontInstrument?: InfrontInstrument
  businessDayBuy?: string | null
  businessDaySell?: string | null
  tradingPeriodBuy?: string | null
  tradingPeriodSell?: string | null
  internalOrderCutOffTime?: string | null
  /** @format double */
  fundTransactionFee?: number | null
  /** @format double */
  distributionFee?: number | null
  marketplaceName?: string | null
  marketList?: string | null
  /** @format double */
  collateralValue?: number | null
  /** @format double */
  managementFee?: number | null
  quotedInCents?: boolean | null
  reservedQuantities?: ReservedQuantity[] | null
  riskTolerancePRIIPS?: string | null
}

export type InstrumentModelSimple = {
  instrumentIdCarnegie?: string | null
  instrumentType?: string | null
  instrumentTypeName?: string | null
  carnegieInstrumentGroup?: string | null
  isin?: string | null
  lei?: string | null
  symbol?: string | null
  name?: string | null
  currency?: string | null
  infrontInstrument?: InfrontInstrument
  /** @format double */
  fundTransactionFee?: number | null
  marketplaceName?: string | null
  riskTolerancePRIIPS?: string | null
}

export type InstrumentModelSimpleArrayEnvelopeItemsDescriptor = {
  items?: InstrumentModelSimple[] | null
  /** @format int32 */
  httpStatus?: number
  error?: string | null
  problemDetails?: ProblemDetails
}

export type InstrumentPosition = {
  instrument?: InstrumentPositionInstrument
  /** @format double */
  quantity?: number | null
  /** @format double */
  lastPrice?: number | null
  /** @format double */
  priceChangeToday?: number | null
  /** @format double */
  priceChangeTodayOriginal?: number | null
  /** @format double */
  priceChangeTodayRatio?: number | null
  /** @format double */
  priceChangeOneDay?: number | null
  /** @format double */
  priceChangeOneDayOriginal?: number | null
  /** @format double */
  priceChangeOneDayRatio?: number | null
  latestEODPrice?: CurrencyAmount
  /** @format date-time */
  latestEODDate?: string | null
  previousEODPrice?: CurrencyAmount
  /** @format date-time */
  previousEODDate?: string | null
  currencyCode?: string | null
  /** @format double */
  marketValue?: number | null
  /** @format double */
  marketValueOriginal?: number | null
  /** @format double */
  unrealized?: number | null
  /** @format double */
  unrealizedRatio?: number | null
  acquisitionCost?: CurrencyAmount
  acquisitionCostOriginal?: CurrencyAmount
  averageAcquisitionPrice?: CurrencyAmount
  averageAcquisitionPriceOriginal?: CurrencyAmount
  /** @format date-time */
  instrumentPriceDateTime?: string | null
  region?: string | null
  /** @format double */
  weight?: number | null
  /** @format double */
  acquisitionFxRate?: number | null
  reservedQuantities?: ReservedQuantity[] | null
  marginRequirements?: CurrencyAmount
}

export type InstrumentPositionInstrument = {
  id?: string | null
  infrontInstrument?: InfrontInstrument
  name?: string | null
  isin?: string | null
  symbol?: string | null
  carnegieInstrumentGroup?: string | null
  typeName?: string | null
  allocation?: string | null
  marketIso?: string | null
  hasDetails?: boolean | null
}

export type InstrumentSearchResponse = {
  stocks?: InstrumentModel[] | null
  funds?: InstrumentModel[] | null
  etfs?: InstrumentModel[] | null
  bonds?: InstrumentModel[] | null
  certificates?: InstrumentModel[] | null
  options?: InstrumentModel[] | null
  others?: InstrumentModel[] | null
}

export enum InstrumentType {
  NotSet = 'NotSet',
  Stock = 'Stock',
  News = 'News',
  Bond = 'Bond',
  EuroOption = 'Euro_Option',
  Futures = 'Futures',
  Commodity = 'Commodity',
  Index = 'Index',
  Forex = 'Forex',
  USOption = 'US_Option',
  Fund = 'Fund',
  Option = 'Option',
  Combo = 'Combo',
  Cfd = 'Cfd',
  Certificate = 'Certificate',
  Unknown = 'Unknown',
}

export type IsLoggedInResponse = {
  isLoggedIn?: boolean
  userPrefix?: string | null
  isImpersonated?: boolean
  hasLimitedView?: boolean
  customerPermissionFlags?: CustomerPermissionFlags
}

export type JwtAssertionRequest = {
  assertionToken?: string | null
}

export type ListItem = {
  /** @format int32 */
  id?: number
  name?: string | null
}

export type LogoutRequest = {
  globalLogout?: boolean
}

export type LogoutUserResponse = {
  url?: string | null
}

export type MarketingEmailInstrumentModel = {
  carnegieId?: string | null
  name?: string | null
}

export type MarketingEmailModelWrapper = {
  /** @format uuid */
  marketingEmailId?: string
  subject?: string | null
  /** @format date-time */
  publishedDate?: string
  body?: string | null
  instruments?: MarketingEmailInstrumentModel[] | null
  subscriptionType?: SubscriptionType
}

export type MarketingEmailModelWrapperEnvelopeItemDescriptor = {
  item?: MarketingEmailModelWrapper
  /** @format int32 */
  httpStatus?: number
  error?: string | null
  problemDetails?: ProblemDetails
}

export type MarketingEmailModelWrapperPaginationResponse = {
  items?: MarketingEmailModelWrapper[] | null
  /** @format int32 */
  totalPages?: number
  /** @format int32 */
  currentPage?: number
  /** @format int32 */
  usingLimit?: number | null
  /** @format int32 */
  totalRows?: number
  httpStatus?: HttpStatusCode
  error?: string | null
  success?: boolean
  problemDetails?: ProblemDetails
}

export type MessageAttachmentModel = {
  /** @format uuid */
  documentId?: string
  name?: string | null
}

export type MessageModelWrapper = {
  /** @format int64 */
  id?: number
  attachments?: MessageAttachmentModel[] | null
  body?: string | null
  /** @format date-time */
  created?: string
  fromName?: string | null
  /** @format uuid */
  fromPartyId?: string | null
  subject?: string | null
  fromCustomer?: boolean
  /** @format int64 */
  threadId?: number
  htmlContent?: boolean
  sentByAttorney?: string | null
  /** @format uuid */
  sentByAttorneyId?: string | null
}

export enum MorningstarDocumentType {
  Prospectus = 'Prospectus',
  AnnualReport = 'AnnualReport',
  FactsheetOld = 'FactsheetOld',
  Factsheet = 'Factsheet',
}

export type NewMessageRequest = {
  internalSubscribers?: string[] | null
  attachments?: string[] | null
  body?: string | null
  subject?: string | null
  /** @format uuid */
  trusteeOverride?: string | null
}

export type NewMessageResponse = {
  /** @format int64 */
  threadId?: number
}

export type NotificationMessageResponse = {
  id?: string | null
  /** @format date-time */
  createdDate?: string | null
  subject?: string | null
  message?: string | null
  severity?: string | null
  route?: string | null
}

export type OrganizationContactInfo = {
  emails?: EmailModel[] | null
  phoneNumbers?: PhoneNumber[] | null
}

export type PartyResponsible = {
  name?: string | null
  /** @format uuid */
  staffPartyId?: string
  title?: string | null
  phoneNumber?: string | null
  organizationContactInfo?: OrganizationContactInfo
  isPrimaryResponsible?: boolean
  roleName?: string | null
}

export type PartyResponsibleResponse = {
  responsibles?: PartyResponsible[] | null
}

export type PerformanceSeries = {
  /** @format double */
  c1D?: number | null
  /** @format double */
  c1DP?: number | null
  /** @format double */
  c1W?: number | null
  /** @format double */
  c1WP?: number | null
  /** @format double */
  c1MP?: number | null
  /** @format double */
  c3M?: number | null
  /** @format double */
  c3MP?: number | null
  /** @format double */
  c6M?: number | null
  /** @format double */
  c6MP?: number | null
  /** @format double */
  c12MP?: number | null
  /** @format double */
  c3Y?: number | null
  /** @format double */
  c3YP?: number | null
  /** @format double */
  c5Y?: number | null
  /** @format double */
  c5YP?: number | null
  /** @format double */
  sinceInception?: number | null
  /** @format double */
  sinceInceptionP?: number | null
  /** @format double */
  ytd?: number | null
  /** @format double */
  ytdP?: number | null
  unrealisedResult?: CurrencyAmount
  realised?: CurrencyAmount
  /** @format double */
  realisedYear?: number | null
}

export enum Period {
  OneWeek = 'OneWeek',
  OneMonth = 'OneMonth',
  ThreeMonth = 'ThreeMonth',
  SixMonth = 'SixMonth',
  Ytd = 'Ytd',
  OneYear = 'OneYear',
  ThreeYear = 'ThreeYear',
  FiveYear = 'FiveYear',
  SinceInception = 'SinceInception',
}

export enum PersonStatus {
  NotFound = 'NotFound',
  Initiated = 'Initiated',
  Processing = 'Processing',
  UpdateRequired = 'UpdateRequired',
  Active = 'Active',
  Inactive = 'Inactive',
  OutOfScope = 'OutOfScope',
}

export type PersonSubmissionSummary = {
  /** @format uuid */
  partyId?: string
  name?: string | null
  isPhysicalPerson?: boolean
  personStatus?: PersonStatus
  submissionSystem?: SubmissionSystem
  /** @format int32 */
  id?: number
  /** @format date-time */
  createDate?: string
  /** @format date-time */
  validFrom?: string | null
  /** @format date-time */
  validTo?: string | null
  hasOngoing?: boolean
  tagType?: string | null
  stateDescription?: string | null
  newUrl?: string | null
  url?: string | null
  editable?: boolean
  renewable?: boolean
  stateIsActive?: boolean
  canInitiateOnboarding?: boolean
  isExpiringSoon?: boolean
  hasExpired?: boolean
}

export type PhoneNumber = {
  /** @format int32 */
  id?: number
  number?: string | null
  isPreferred?: boolean | null
  type?: string | null
}

export type PodcastEpisodeModel = {
  disclaimerText?: string | null
  carnegieContentType?: CarnegieContentType
  id?: string | null
  thumbnail?: string | null
  /** @format date-time */
  date?: string
  title?: string | null
  link?: string | null
  tags?: CarnegieBaseTag[] | null
  summary?: string | null
  description?: string | null
  author?: string | null
  category?: string | null
  highlight?: boolean
}

export type PodcastEpisodeModelEnvelopeItemDescriptor = {
  item?: PodcastEpisodeModel
  /** @format int32 */
  httpStatus?: number
  error?: string | null
  problemDetails?: ProblemDetails
}

export type PodcastEpisodeModelPaginationResponse = {
  items?: PodcastEpisodeModel[] | null
  /** @format int32 */
  totalPages?: number
  /** @format int32 */
  currentPage?: number
  /** @format int32 */
  usingLimit?: number | null
  /** @format int32 */
  totalRows?: number
  httpStatus?: HttpStatusCode
  error?: string | null
  success?: boolean
  problemDetails?: ProblemDetails
}

export type PositionsResponse = {
  infrontInstrument?: InfrontInstrument
  instrumentIdCarnegie?: string | null
}

export type PossibleSubscriberModel = {
  /** @format uuid */
  id?: string
  name?: string | null
  type?: string | null
  managerIds?: number[] | null
}

export type ProblemDetails = {
  type?: string | null
  title?: string | null
  /** @format int32 */
  status?: number
  errors?: Record<string, string[] | null>
}

export type RelatedInstrument = {
  name?: string | null
  isPrimary?: boolean
  /** @format uuid */
  carnegieInstrumentId?: string
  infrontInstrument?: InfrontInstrument
  marketIso?: string | null
}

export type ReplyMessageRequest = {
  attachments?: string[] | null
  body?: string | null
  subject?: string | null
  /** @format int64 */
  threadId?: number
  /** @format uuid */
  trusteeOverride?: string | null
}

export enum ReportTypes {
  PortfolioReport = 'PortfolioReport',
  Realised = 'Realised',
}

export type ResearchFlags = {
  isRatingFlag?: boolean | null
  isTargetFlag?: boolean | null
  isEstimatesFlag?: boolean | null
}

export type ResearchNews = {
  recommendation?: ListItem
  previousRecommendation?: ListItem
  /** @format date-time */
  recommendationRevison?: string | null
  /** @format float */
  targetPrice?: number | null
  /** @format float */
  targetPricePrevious?: number | null
  targetPriceCurrency?: string | null
  /** @format date-time */
  targetPriceRevison?: string | null
  company?: ListItem
  productType?: ListItem
  analysts?: ListItem[] | null
  subject?: string | null
  headline?: string | null
  preHeadline?: string | null
  /** @format int32 */
  researchPages?: number | null
  /** @format date-time */
  created?: string
  type?: string | null
  marketIso?: string | null
  /** @format int32 */
  newsId?: number | null
  /** @format int32 */
  researchId?: number | null
  id: string | null
  researchTargets?: ResearchTargets
  text?: string | null
  relatedInstrument?: RelatedInstrument
}

export type ResearchTargets = {
  ratingTo?: string | null
  ratingFrom?: string | null
  /** @format double */
  targetTo?: number | null
  targetCcy?: string | null
  /** @format double */
  targetFrom?: number | null
  estimatesCcy?: string | null
  /** @format int32 */
  estimatesFirstYear?: number | null
  arrowDirection?: string | null
  /** @format double */
  estimatesToYear1?: number | null
  /** @format double */
  estimatesPercentYear1?: number | null
  /** @format double */
  estimatesToYear2?: number | null
  /** @format double */
  estimatesPercentYear2?: number | null
  /** @format double */
  estimatesToYear3?: number | null
  /** @format double */
  estimatesPercentYear3?: number | null
  /** @format int32 */
  estimatesYearStart?: number | null
  /** @format int32 */
  estimatesYearEnd?: number | null
  isRatingFlag?: boolean | null
  isTargetFlag?: boolean | null
  isEstimatesFlag?: boolean | null
}

export type ReservedQuantity = {
  /** @format double */
  quantity?: number
  reason?: string | null
}

export type Response = {
  /** @format int32 */
  httpStatus?: number
  error?: string | null
  problemDetails?: ProblemDetails
}

export type SentMessageModel = {
  attachments?: MessageAttachmentModel[] | null
  body?: string | null
  /** @format date-time */
  created?: string
  isAdvisory?: boolean
  /** @format date-time */
  read?: string | null
  from?: string | null
  customer?: ThreadCustomerModel
  subject?: string | null
  /** @format int64 */
  threadId?: number
  sentByAttorney?: string | null
  /** @format uuid */
  sentByAttorneyId?: string | null
  /** @format int64 */
  id?: number
}

export type SentMessagesResponse = {
  messages?: SentMessageModel[] | null
  /** @format int64 */
  totalCount?: number
  /** @format int64 */
  currentPage?: number
  /** @format int64 */
  totalPages?: number
}

export enum ServiceFileType {
  CustomerReport = 'CustomerReport',
  EsgReport = 'EsgReport',
}

export enum Side {
  NotSet = 'NotSet',
  Buy = 'Buy',
  Sell = 'Sell',
}

export type SimpleOrder = {
  /** @format int32 */
  orderId?: number
  /** @format uuid */
  carnegieOrderId?: string
  /** @format uuid */
  instrumentId?: string
  instrumentName?: string | null
  instrumentType?: CarnegieInstrumentGroupType
  side?: string | null
  /** @format uuid */
  accountId?: string | null
  accountNumber?: string | null
  orderState?: string | null
  orderSystem?: string | null
  orderSubState?: string | null
  /** @format double */
  quantity?: number | null
  /** @format double */
  monetaryAmount?: number
  tradeCurrency?: string | null
  /** @format date-time */
  orderDate?: string | null
  error?: string | null
  canBeModified?: boolean
  canBeCancelledByClient?: boolean
  orderType?: string | null
  settlementCurrency?: string | null
}

export type SimpleOrderArrayEnvelopeItemsDescriptor = {
  items?: SimpleOrder[] | null
  /** @format int32 */
  httpStatus?: number
  error?: string | null
  problemDetails?: ProblemDetails
}

export type StartAuthorizationRequest = {
  extraParams?: ExtraParams[] | null
}

export type StartAuthorizationResponse = {
  authorizationRequestUrl?: string | null
}

export enum State {
  Created = 'Created',
  PendingApproval = 'PendingApproval',
  Approved = 'Approved',
  BookedInAbaSec = 'BookedInAbaSec',
  Exported = 'Exported',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
  Archived = 'Archived',
}

export type StringStringKeyValuePair = {
  key?: string | null
  value?: string | null
}

export enum SubmissionSystem {
  ClientInformation = 'ClientInformation',
  ClientPortal = 'ClientPortal',
}

export type SubscriberModelWrapper = {
  /** @format int64 */
  id?: number
  name?: string | null
  /** @format date-time */
  createDate?: string
}

export type SubscriberSettingsModel = {
  emailNotification?: boolean
  smsNotification?: boolean | null
  signature?: string | null
}

export enum SubscriptionType {
  ShortTerm = 'ShortTerm',
  MacroInFocus = 'MacroInFocus',
  MorningNews = 'MorningNews',
  AllocationStrategy = 'AllocationStrategy',
}

export type ThreadCustomerModel = {
  defaultManager?: string | null
  /** @format int32 */
  id?: number
  description?: string | null
  hasEmail?: boolean
}

export type ThreadModelWrapper = {
  /** @format uuid */
  assignedToPartyId?: string | null
  /** @format int64 */
  id?: number
  fromName?: string | null
  /** @format uuid */
  fromPartyId?: string | null
  /** @format uuid */
  customerPartyId?: string | null
  canReply?: boolean | null
  customerName?: string | null
  fromCustomer?: boolean
  hasUnreadMessages?: boolean
  /** @format date-time */
  latestMessageDate?: string
  subject?: string | null
  internalSubscribers?: SubscriberModelWrapper[] | null
  messages?: MessageModelWrapper[] | null
  /** @format uuid */
  latestMessagePartyId?: string
  latestMessageStaffName?: string | null
}

export type ThreadsResponse = {
  threads?: ThreadModelWrapper[] | null
  /** @format int64 */
  unreadMessages?: number
  /** @format int64 */
  totalCount?: number
  /** @format int64 */
  currentPage?: number
  /** @format int64 */
  totalPages?: number
}

export type TransactionModel = {
  instrumentName?: string | null
  /** @format double */
  quantity?: number | null
  /** @format date-time */
  accountDate?: string
  /** @format date-time */
  registrationDate?: string | null
  type?: string | null
  infrontInstrument?: InfrontInstrument
  /** @format double */
  rate?: number | null
  amount?: Currency
  price?: Currency
  /** @format uuid */
  accountId?: string
  accountNumber?: string | null
  /** @format date-time */
  cancelDate?: string | null
  isCancelTransaction?: boolean
  currencyType?: CurrencyType
  originalCurrency?: CurrencyType
  /** @format double */
  totalQuantity?: number | null
  /** @format uuid */
  instrumentId?: string | null
  typeId?: string | null
  /** @format double */
  commission?: number | null
  /** @format double */
  otherFee?: number | null
  /** @format double */
  interest?: number | null
  /** @format double */
  fxRate?: number | null
  /** @format date-time */
  tradeDate?: string
  /** @format date-time */
  settlementDate?: string
  transactionText?: string | null
  noteId?: string | null
  isin?: string | null
  /** @format double */
  purchaseAmount?: number | null
  isPreliminaryTransaction?: boolean
  category?: string | null
  /** @format double */
  couponInterest?: number | null
  /** @format double */
  balance?: number | null
}

export type TransactionsResult = {
  transactions?: TransactionModel[] | null
  /** @format int32 */
  totalCount?: number
}

export type TransferAmountLimitsRepsonse = {
  /** @format double */
  currentAmountPerPeriod?: number
  currency?: string | null
  isPeriodLimitExceeded?: boolean
  /** @format double */
  amountLeftToPeriodLimit?: number
  /** @format double */
  customerSingleTransferLimitForRelatedDepot?: number
  /** @format double */
  customerSingleTransferLimitForDefaultAccount?: number
  /** @format double */
  customerPeriodTransferLimitForDefaultAccount?: number
  /** @format int32 */
  customerPeriodInDaysForDefaultAccount?: number
}

export type TransferRequest = {
  /** @format uuid */
  fromAccount?: string
  /** @format double */
  amount?: number
  /** @format uuid */
  toAccount?: string | null
  /** @format int32 */
  toExternalAccount?: number | null
  languageCode?: string | null
}

export type TransferResponse = {
  /** @format int64 */
  transferId?: number | null
  qrData?: string | null
  /** @format uri */
  bankIdSignUriDesktop?: string | null
  /** @format uri */
  bankIdSignUriAppUniversal?: string | null
}

export type UpdateSubscriberSettingsRequest = {
  emailNotification?: boolean
  smsNotification?: boolean | null
  /** @format uuid */
  trusteeOverride?: string | null
}

export type UpdateWatchlistRequest = {
  /** @format uuid */
  id?: string
  name?: string | null
}

export type UpsertSettingsRequest = {
  key?: UserSettingKey
  value: any
  valueType?: string | null
}

export enum UserSettingKey {
  INSTRUMENT_SEARCHES = 'INSTRUMENT_SEARCHES',
  LANGUAGE = 'LANGUAGE',
  SHOW_MORE_COLUMNS = 'SHOW_MORE_COLUMNS',
  WATCHLIST_SELECTED_ID = 'WATCHLIST_SELECTED_ID',
  ACCOUNT_SUMMARY_EXPANDED = 'ACCOUNT_SUMMARY_EXPANDED',
  ACCOUNT_GROUPING = 'ACCOUNT_GROUPING',
  ACCOUNT_CARD_PERIOD = 'ACCOUNT_CARD_PERIOD',
  ACCOUNT_USER_SETTINGS = 'ACCOUNT_USER_SETTINGS',
  ACCOUNT_LAST_SELECTED = 'ACCOUNT_LAST_SELECTED',
  TRANSFER_ONGOING_TRANSACTION = 'TRANSFER_ONGOING_TRANSACTION',
}

export type ValidateFundResponse = {
  isValid?: boolean
  invalidCodesPriorityList?: string[] | null
  invalidCode?: string | null
}

export type ValidateInstrumentResponse = {
  isValid?: boolean
  invalidCodesPriorityList?: string[] | null
  invalidCode?: string | null
}

export type VideoResponseModel = {
  uri?: string | null
  embedHtml?: string | null
  carnegieContentType?: CarnegieContentType
  id?: string | null
  thumbnail?: string | null
  /** @format date-time */
  date?: string
  title?: string | null
  link?: string | null
  tags?: CarnegieBaseTag[] | null
  summary?: string | null
  description?: string | null
  author?: string | null
  category?: string | null
  highlight?: boolean
}

export type VideoResponseModelEnvelopeItemDescriptor = {
  item?: VideoResponseModel
  /** @format int32 */
  httpStatus?: number
  error?: string | null
  problemDetails?: ProblemDetails
}

export type VideoResponseModelPaginationResponse = {
  items?: VideoResponseModel[] | null
  /** @format int32 */
  totalPages?: number
  /** @format int32 */
  currentPage?: number
  /** @format int32 */
  usingLimit?: number | null
  /** @format int32 */
  totalRows?: number
  httpStatus?: HttpStatusCode
  error?: string | null
  success?: boolean
  problemDetails?: ProblemDetails
}

export type WatchList = {
  /** @format uuid */
  id?: string
  name?: string | null
  instruments?: WatchlistInstrument[] | null
}

export type WatchlistInstrument = {
  /** @format uuid */
  id?: string
  infrontInstrument?: InfrontInstrument
  name?: string | null
  group?: string | null
  type?: string | null
  /** @format int32 */
  groupSortOrder?: number
  marketplaceName?: string | null
  typeName?: string | null
  currency?: string | null
  riskTolerancePRIIPS?: string | null
}

export type AccountOffering = {
  /** @format uuid */
  accountId?: string
  accountNumber?: string | null
  accountType?: AccountType
  owners?: string[] | null
  isPrimaryOwner?: boolean | null
  policyHolder?: string | null
  /** @format double */
  price?: number | null
  hasResponded?: boolean
  /** @format uuid */
  latestResponseById?: string | null
  /** @format double */
  acceptedQuantity?: number | null
  /** @format double */
  declinedQuantity?: number | null
  /** @format double */
  totalQuantity?: number
  isChecked?: boolean | null
  /** @format date */
  lastResponseDate?: string | null
  acceptQuantityChanges?: boolean | null
}

export type BooleanNullableItem = {
  error?: string | null
  /** @format int32 */
  httpStatus?: number
  item?: boolean | null
}

export type CancelSimpleOrderResponseItem = {
  error?: string | null
  /** @format int32 */
  httpStatus?: number
  item?: CancelSimpleOrderResponse
}

export enum ContentSummariesLimit {
  XS = 'XS',
  S = 'S',
  M = 'M',
  L = 'L',
  XL = 'XL',
}

export type FileDetails = {
  filename?: string | null
  /** @format byte */
  file?: string | null
  contentType?: string | null
}

export type FundOrderInfoBuyItem = {
  error?: string | null
  /** @format int32 */
  httpStatus?: number
  item?: FundOrderInfoBuy
}

export type FundOrderInfoSellItem = {
  error?: string | null
  /** @format int32 */
  httpStatus?: number
  item?: FundOrderInfoSell
}

export type GetAnalysisFilterResponseItem = {
  error?: string | null
  /** @format int32 */
  httpStatus?: number
  item?: GetAnalysisFilterResponse
}

export type GetAnalysisItemResponseItem = {
  error?: string | null
  /** @format int32 */
  httpStatus?: number
  item?: GetAnalysisItemResponse
}

export type GetAnalysisResponseItem = {
  error?: string | null
  /** @format int32 */
  httpStatus?: number
  item?: GetAnalysisResponse
}

export type GetCorePicksResponseItem = {
  error?: string | null
  /** @format int32 */
  httpStatus?: number
  item?: GetCorePicksResponse
}

export type GetRelatedInstrumentsResponseItem = {
  error?: string | null
  /** @format int32 */
  httpStatus?: number
  item?: GetRelatedInstrumentsResponse
}

export type GetSettingsResponseItem = {
  error?: string | null
  /** @format int32 */
  httpStatus?: number
  item?: GetSettingsResponse
}

export type InsertSimpleOrderResponseItem = {
  error?: string | null
  /** @format int32 */
  httpStatus?: number
  item?: InsertSimpleOrderResponse
}

export enum MorningstarLanguage {
  English = 'English',
  Swedish = 'Swedish',
}

export type OfferingAnswer = {
  /** @format int32 */
  offeringId?: number
  /** @format uuid */
  accountId?: string
  /** @format double */
  acceptedQuantity?: number
}

export type OfferingAnswerStatus = {
  /** @format int32 */
  offeringId?: number
  /** @format uuid */
  accountId?: string
  success?: boolean
}

export enum OfferingStatus {
  Unknown = 'Unknown',
  Open = 'Open',
  Registered = 'Registered',
  Processed = 'Processed',
  PartiallyProcessed = 'PartiallyProcessed',
  Cancelled = 'Cancelled',
}

export enum OfferingType {
  Unknown = 'Unknown',
  RightsIssue = 'RightsIssue',
  Takeover = 'Takeover',
  WarrantsExercise = 'WarrantsExercise',
}

export type OfferingsResponse = {
  /** @format int32 */
  offeringId?: number
  instrumentName?: string | null
  /** @format uuid */
  instrumentId?: string
  /** @format date */
  lastResponseDate?: string | null
  offeringTypeName?: string | null
  offeringType?: OfferingType
  accountOfferings?: AccountOffering[] | null
  /** @format date */
  clearingsPlaceCheckDate?: string | null
  description?: string | null
  /** @format date */
  settlementDate?: string | null
  ratio?: string | null
  /** @format double */
  ratioFrom?: number | null
  /** @format double */
  ratioTo?: number | null
  /** @format double */
  price?: number | null
  currencyCode?: string | null
  status?: OfferingStatus
}

export type OfferingsResponseListItems = {
  error?: string | null
  /** @format int32 */
  httpStatus?: number
  items?: OfferingsResponse[] | null
  /** @format int32 */
  totalItems?: number | null
}

export type RegisterAccountOfferingRequest = {
  offeringAnswers?: OfferingAnswer[] | null
}

export type RegisterAccountOfferingResponse = {
  offeringAnswerStatuses?: OfferingAnswerStatus[] | null
}

export type RegisterAccountOfferingResponseItem = {
  error?: string | null
  /** @format int32 */
  httpStatus?: number
  item?: RegisterAccountOfferingResponse
}

export type SimpleOrderArrayItems = {
  error?: string | null
  /** @format int32 */
  httpStatus?: number
  items?: SimpleOrder[] | null
  /** @format int32 */
  totalItems?: number | null
}

export enum SummariesFilter {
  News = 'News',
  Investment = 'Investment',
  PodcastEpisode = 'PodcastEpisode',
  Article = 'Article',
  Seminar = 'Seminar',
  Subscription = 'Subscription',
  Analysis = 'Analysis',
}

export type TargetAndRecommendationResponse = {
  /** @format uuid */
  instrumentId?: string | null
  /** @format uuid */
  researchId?: string
  /** @format double */
  targetPrice?: number | null
  /** @format double */
  targetPricePrevious?: number | null
  priceCurrency?: string | null
  /** @format date-time */
  targetPriceRevison?: string | null
  /** @format double */
  fairValueHigh?: number | null
  /** @format double */
  fairValueLow?: number | null
  recommendation?: string | null
  previousRecommendation?: string | null
  /** @format date-time */
  recommendationDate?: string | null
  useFairValue?: boolean
}

export type TargetAndRecommendationResponseItem = {
  error?: string | null
  /** @format int32 */
  httpStatus?: number
  item?: TargetAndRecommendationResponse
}

export type ValidateFundResponseItem = {
  error?: string | null
  /** @format int32 */
  httpStatus?: number
  item?: ValidateFundResponse
}
