/**
 * The specification:
 *
 * https://pts.se/sv/bransch/telefoni/nummer-och-adressering/telefoninummerplanen/telefonnummers-struktur/
 */

const subscriberNumberFormats = {
  5: /^(\d{3})(\d{2})$/,
  6: /^(\d{2})(\d{2})(\d{2})$/,
  7: /^(\d{3})(\d{2})(\d{2})$/,
  8: /^(\d{4})(\d{2})(\d{2})$/, // Adjusted the specification to align with the Carnegie format
}

const isSwedishMobilePhoneNumber = (str: string) => /^07[02369]{1}\d{7}$/.test(str)
const isSwedishPhoneNumber = (str: string) => str[0] === '0' && str.length > 6 && str.length < 13
const normalizeSwedishPhoneNumber = (str: string) => str.replace(/[^\d+]/g, '').replace(/^\+46/, '0')
const hasTwoLetterAreaCode = (str: string) => /^08/.test(str)
const hasThreeLetterAreaCode = (str: string) =>
  /^0(11|13|16|18|19|21|23|26|31|33|35|36|40|42|44|46|54|60|63|90)/.test(str)
const formatSwedishMobilePhoneNumber = (str: string) => str.replace(/^(\d{3})(\d{3})(\d{2})(\d{2})$/, '$1-$2 $3 $4')
const formatSubscriberNumber = (str: string) =>
  str.replace(subscriberNumberFormats[str.length], str.length === 5 ? '$1 $2' : '$1 $2 $3')

const formatSwedishPhoneNumber = (str: string) => {
  const [areaCode, subscriberNumber] = splitSwedishPhoneNumber(str)
  return areaCode + '-' + formatSubscriberNumber(subscriberNumber)
}

const splitSwedishPhoneNumber = (str: string) => {
  const pos = hasTwoLetterAreaCode(str) ? 2 : hasThreeLetterAreaCode(str) ? 3 : 4
  return [str.substring(0, pos), str.substring(pos)]
}

export const formatPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) return

  const normalized = normalizeSwedishPhoneNumber(phoneNumber)

  if (isSwedishMobilePhoneNumber(normalized)) {
    return formatSwedishMobilePhoneNumber(normalized)
  } else if (isSwedishPhoneNumber(normalized)) {
    return formatSwedishPhoneNumber(normalized)
  } else {
    return normalized
  }
}
