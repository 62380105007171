import isoCountries from 'i18n-iso-countries'
import langSv from 'i18n-iso-countries/langs/sv.json'

isoCountries.registerLocale(langSv)

export const translateRegionCodes = (type: string) => {
  // translates region iso codes to swedish name
  const result = isoCountries.getName(type, 'sv', { select: 'official' })

  return result ?? 'Övriga'
}
