import { FundOrderType } from '@common/api/response'
import { useDrawerProps } from '@common/components/drawer/useDrawerStore'
import { useOpenDrawer } from '@common/components/drawer/useOpenDrawer'
import { useSelectedAccountId } from '@common/hooks/useSelectedAccountId'
import { InstrumentIdContainer, createValidInstrumentIdContainer } from '@common/instrumentIdContainer'
import { fireTrackEvent } from '@common/utils/analyticsEvent'
import {
  convertInstrumentIdContainerToUrl,
  convertUrlToInstrumentIdContainer,
} from '@common/utils/convertInstrumentIdContainerToFromUrl'

import queryString from 'query-string'

type FundQueryParams = {
  instrument: string
  type: string
}

function getFundQueryParams(instrumentIdContainer: InstrumentIdContainer, fundOrderType: string): string {
  const params: FundQueryParams = {
    instrument: convertInstrumentIdContainerToUrl(createValidInstrumentIdContainer(instrumentIdContainer)),
    type: fundOrderType,
  }
  return queryString.stringify(params)
}

export function useOpenFundOrderDrawer() {
  const { openDrawer } = useOpenDrawer()
  const initialSelectedAccountId = useSelectedAccountId()

  const openFundOrderDrawer = (instrumentIdContainer: InstrumentIdContainer, fundOrderType: FundOrderType) => {
    const eventType: Record<FundOrderType, string> = { buy: 'click_buy_fund', sell: 'click_sell_fund' }
    fireTrackEvent('Order', eventType[fundOrderType])

    const queryParams = getFundQueryParams(instrumentIdContainer, fundOrderType)

    openDrawer('fundorder', queryParams, {
      initialSelectedAccountId,
    })
  }

  return { openFundOrderDrawer }
}

export function useFundOrderDrawerProps() {
  const drawerProps = useDrawerProps<{
    fundOrderType: FundOrderType
    initialSelectedAccountId?: string
    initialOrderId?: number
  }>()

  const fundQueryParams = queryString.parse(drawerProps.routeProps) as FundQueryParams

  return {
    instrumentIdContainer: convertUrlToInstrumentIdContainer(fundQueryParams.instrument),
    fundOrderType: fundQueryParams.type,
    ...drawerProps.props,
  }
}
