import { ReactNode } from 'react'

import { useDelayMount } from '../hooks/useDelayMount'

type DelayMountProps = {
  children?: ReactNode
  delay: number
}

export const DelayMount = ({ children, delay }: DelayMountProps) => {
  const { showComponent } = useDelayMount(delay)

  return showComponent && <>{children}</>
}
