import { UserSettingKey } from '@common/api/response'
import { useUserSetting } from '@common/hooks/useUserSetting'

import { useCallback, useState } from 'react'

const MAX_PREVIOUS_SEARCHES = 10

type UsePreviousSearchesReturn = {
  previousSearches: string[]
  addCurrentSearchToPreviousSearches: (search: string) => void
  loadPreviousSearches: () => void
}

export function usePreviousSearches(): UsePreviousSearchesReturn {
  const [previousSearches, setPreviousSearches] = useState<string[]>([])
  const searchHistoryUserSetting = useUserSetting<string[]>(UserSettingKey.INSTRUMENT_SEARCHES)

  const loadPreviousSearches = useCallback(() => {
    if (searchHistoryUserSetting) {
      setPreviousSearches(Array.isArray(searchHistoryUserSetting.value) ? searchHistoryUserSetting.value : [])
    }
  }, [searchHistoryUserSetting])

  const addCurrentSearchToPreviousSearches = useCallback(
    (query: string) => {
      if (!query?.trim().length) return

      const newPreviousSearches = [query, ...previousSearches.filter((ps) => ps !== query)]
        .filter((item) => item !== '')
        .slice(0, MAX_PREVIOUS_SEARCHES)

      searchHistoryUserSetting.setValue(newPreviousSearches)
      setPreviousSearches(newPreviousSearches)
    },
    [previousSearches, searchHistoryUserSetting]
  )

  return {
    previousSearches,
    addCurrentSearchToPreviousSearches,
    loadPreviousSearches,
  }
}
