import { NavigateFunction } from 'react-router-dom'

export class NavigationStore {
  navigate: NavigateFunction
  location: { pathname: string }

  private readonly internalHistory: string[] = []

  constructor() {
    this.location = { pathname: '' }
    this.internalHistory.push(window.location.pathname)
  }

  setNavigate = (navigate: NavigateFunction) => {
    this.navigate = navigate
  }

  goBack = () => {
    this.navigate(-1)
  }

  push = (route: string, state?: unknown, preserveQuery = false) => {
    let finalUrl = route

    if (preserveQuery) {
      finalUrl = this.addQueryString(finalUrl, window.location.search)
    }

    this.internalHistory.push(finalUrl)
    window.history.pushState(state, '', finalUrl) // Update the browser URL and history - without triggering a navigation event like you would get with this.navigate(finalUrl, { state })
  }

  getBackRoute = (allowedRoutes: string[], fallbackRoute: string) => {
    const internalHistoryReversed = [...this.internalHistory].reverse()

    for (const prevRoute of internalHistoryReversed) {
      if (allowedRoutes.includes(prevRoute)) {
        return prevRoute
      }
    }

    return fallbackRoute
  }

  get currentState() {
    return window.history.state
  }

  get currentQuery() {
    return window.location.search
  }

  replace = (route: string, state?: unknown) => {
    this.navigate(route, { replace: true, state })
  }

  get currentRoute() {
    return window.location.pathname
  }

  private addQueryString(url: string, query: string): string {
    const urlObj = new URL(url, window.location.origin)
    const newParams = new URLSearchParams(query)

    newParams.forEach((value, key) => {
      urlObj.searchParams.set(key, value)
    })

    return urlObj.pathname + urlObj.search
  }
}
