import { AccountsResponse } from '@common/api/response'
import { useInfrontStore } from '@common/stores/store'

import { useEffect } from 'react'

export function useSyncActivePortfolio(selectedAccount: AccountsResponse) {
  const infrontStore = useInfrontStore(true)

  useEffect(() => {
    if (selectedAccount && infrontStore) {
      infrontStore.tradingConnectAndSetPortfolio(selectedAccount.number)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infrontStore, selectedAccount?.id])
}
