import { ResearchNews } from '@common/api/response'
import { CountryCodeFlag } from '@common/components/CountryCodeToFlag'
import { createValidInstrumentIdContainer } from '@common/instrumentIdContainer'

import { checkToday, formatNumber } from '@carnegie/digital-channels-frontend'
import { FlexRow, ListItem, ListItemRow, Text } from '@carnegie/duplo'

import { useOpenInstrumentDrawer } from '../../../drawers/instrumentDrawer/useOpenInstrumentDrawer'
import { useResearchTargetItems } from '../shared/useResearchTargetItems'
import { ResearchListItemIcon } from './ResearchListItemIcon'
import { ResearchNewsMoreMenu } from './ResearchNewsMoreMenu'

type TargetPriceRevisionsListItemProps = {
  researchNews: ResearchNews
}

export const TargetPriceRevisionsListItem = ({ researchNews }: TargetPriceRevisionsListItemProps) => {
  const { openInstrumentDrawer } = useOpenInstrumentDrawer()

  const { researchTargets } = researchNews
  const { arrowIcon, iconBg, iconTextColor, changeText } = useResearchTargetItems(researchTargets?.arrowDirection)

  const instrumentIdContainer = createValidInstrumentIdContainer({
    instrumentIdCarnegie: researchNews?.relatedInstrument?.carnegieInstrumentId,
    infrontInstrument: researchNews?.relatedInstrument?.infrontInstrument,
  })

  return (
    <ListItem
      divider
      noDividerOffset
      support={
        <ResearchListItemIcon backgroundColor={iconBg} color={iconTextColor}>
          {arrowIcon}
        </ResearchListItemIcon>
      }
      onClick={() => {
        if (researchNews?.relatedInstrument) {
          openInstrumentDrawer(instrumentIdContainer)
        }
      }}
      action={
        researchNews?.relatedInstrument ? <ResearchNewsMoreMenu instrumentIdContainer={instrumentIdContainer} /> : null
      }
    >
      <ListItemRow
        css={{ minHeight: 20 }}
        title={
          <Text variant="overline" color="text-low-emphasis" truncate>
            {checkToday(new Date(researchNews.created), {
              short: true,
              showTodayAsTime: true,
            })}
          </Text>
        }
        value={
          <Text variant="subtitle2" truncate color="text-default">
            {formatNumber(researchNews.researchTargets?.targetTo)}
            <Text ml={2} variant="label2">
              {researchNews.researchTargets?.targetCcy}
            </Text>
          </Text>
        }
      />
      <ListItemRow
        css={{ minHeight: 20 }}
        title={
          <FlexRow minWidth={0} alignItems="center" space={4}>
            <CountryCodeFlag size={16} countryCode={researchNews?.marketIso} />
            <Text truncate variant="subtitle1">
              {researchNews.company?.name ?? '???'}
            </Text>
          </FlexRow>
        }
        value={
          <Text variant="support2" truncate color="text-low-emphasis">
            ({formatNumber(researchNews.researchTargets?.targetFrom)})
          </Text>
        }
      />
      <ListItemRow
        css={{ minHeight: 20 }}
        title={
          <Text truncate color="text-default" variant="support2">
            {changeText}
          </Text>
        }
      />
    </ListItem>
  )
}
