import { CurrencyAmount, CurrencyPosition } from '@common/api/response'
import { CurrencyFlag } from '@common/components/CurrencyToFlag'
import { FormattedNumber } from '@common/components/FormattedNumber'
import { SmartTable } from '@common/components/SmartTable'
import { useAccountSummary } from '@common/hooks/useAccountSummary'

import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { formatNumber } from '@carnegie/digital-channels-frontend'
import {
  Banner,
  Breakpoint,
  Button,
  ButtonIcon,
  DataCell,
  FlexCol,
  FlexRow,
  IconInfoOutlined,
  IconTransfer,
  Paragraph,
  SideDrawer,
  Text,
  useBreakpoint,
  useDuploTheme,
} from '@carnegie/duplo'

import { useOpenTransfersDrawer } from '../../../../drawers/transfersDrawer/useOpenTransfersDrawer'

type Props = {
  currencyPositions: CurrencyPosition[]
  accountId: string
}

export const CurrencyPositions = ({ currencyPositions, accountId }: Props) => {
  const { t } = useTranslation()
  const { accountSummary } = useAccountSummary(accountId)
  const [openCurrencyPositionsSlide, setOpenCurrencyPositionsSlide] = useState(false)
  const breakpoint = useBreakpoint()
  const isExtraSmallScreen = breakpoint < Breakpoint.Small
  const hasCurrencyPositions = currencyPositions && currencyPositions.length > 0

  return (
    <FlexCol flex={1} mr={isExtraSmallScreen ? 0 : 16}>
      <DataCell
        onClick={() => hasCurrencyPositions && setOpenCurrencyPositionsSlide(true)}
        label={t('Likvida medel')}
        icon={IconInfoOutlined}
        suffix={accountSummary?.liquidAsset?.currencyCode}
      >
        {formatNumber(accountSummary?.liquidAsset?.amount, { decimals: 0 })}
      </DataCell>
      <SideDrawer
        title={t('Likvida medel')}
        variant="slide"
        sidebarContent={<Content currencyPositions={currencyPositions} accountId={accountId} />}
        open={openCurrencyPositionsSlide}
        onClose={() => setOpenCurrencyPositionsSlide(false)}
        sidebarContentPadding={0}
      />
    </FlexCol>
  )
}

type ContentProps = {
  currencyPositions: CurrencyPosition[]
  accountId: string
}

const Content = ({ currencyPositions, accountId }: ContentProps) => {
  const { t } = useTranslation()
  const theme = useDuploTheme()
  const openTransfersDrawer = useOpenTransfersDrawer()
  const { accountSummary, canReceivePendingAmount, canReceivePendingOrderAmount } = useAccountSummary(accountId)
  const withdrawableAmount = useMemo(() => [accountSummary?.withdrawableAmount], [accountSummary?.withdrawableAmount])

  return (
    <>
      {canReceivePendingAmount === false && (
        <Banner
          severity="warning"
          title=""
          description={
            <Paragraph variant="support2">
              {t(
                'Just nu kan vi inte hämta information om pågående överföringar som kan påverka tillgängligt saldo för uttag.'
              )}
            </Paragraph>
          }
        />
      )}
      {canReceivePendingOrderAmount === false && (
        <Banner
          severity="warning"
          title=""
          description={
            <Paragraph variant="support2">
              {t(
                'Just nu kan vi inte hämta information om pågående köporder som kan påverka tillgängligt saldo för uttag.'
              )}
            </Paragraph>
          }
        />
      )}
      <FlexCol spaceY={8} px={16} pt={16}>
        <Text variant="subtitle1">{t('Likvida medel')}</Text>
        <Paragraph variant="support3" color="text-low-emphasis" css={{ whiteSpace: 'break-spaces' }}>
          {t('Likvida medel visar aktuellt saldo för respektive valuta och är uppdaterat med dagens transaktioner.')}
        </Paragraph>
      </FlexCol>
      <SmartTable<CurrencyPosition, 'currency' | 'value' | 'valueSek'>
        columns={[
          {
            id: 'currency',
            width: 'minmax(0,1fr)',
            renderHeader: () => t('Valuta'),
            renderCell: ({ row }) => (
              <FlexRow alignItems="center">
                <CurrencyFlag currency={row.currency} size={16} />
                <Text variant="body2" ml={8} color="text-link">
                  {row.currency}
                </Text>
              </FlexRow>
            ),
            sortBy: ({ row }) => row.currency,
          },
          {
            id: 'value',
            width: '1fr',
            align: 'right',
            defaultSortOrder: 'desc',
            renderHeader: () => t('Saldo'),
            renderCell: ({ row }) => (
              <FormattedNumber
                variant="body2"
                decimals={{ min: 0, max: 2 }}
                unit={row.currency}
                value={row.quantity ?? 0}
                showZeroAsFallbackValue={false}
              />
            ),
            sortBy: ({ row }) => row.quantity ?? 0,
          },
          {
            id: 'valueSek',
            width: '1fr',
            align: 'right',
            defaultSortOrder: 'desc',
            renderHeader: () => `${t('Saldo')} (SEK)`,
            renderCell: ({ row }) => (
              <FormattedNumber
                variant="body2"
                decimals={{ min: 0, max: 2 }}
                unit="SEK"
                value={row.quantity * row.fxRate}
                showZeroAsFallbackValue={false}
              />
            ),
            sortBy: ({ row }) => row.quantity * row.fxRate,
          },
        ]}
        defaultSortBy="valueSek"
        data={currencyPositions}
      ></SmartTable>
      <FlexRow
        justifyContent="space-between"
        alignItems="baseline"
        px={16}
        py={8}
        css={{
          borderTop: `1px solid ${theme.colors['bunker-60']}`,
        }}
      >
        <Text variant="support2">{t('Totalt likvida medel')}</Text>
        <FlexRow alignItems="baseline">
          <Text variant="subtitle2">
            {formatNumber(accountSummary?.liquidAsset?.amount, {
              decimals: 2,
            })}
          </Text>
          <Text variant="label2" ml={2} color="bunker-main">
            {accountSummary?.liquidAsset?.currencyCode}
          </Text>
        </FlexRow>
      </FlexRow>
      <FlexCol
        spaceY={8}
        px={16}
        pt={16}
        css={{
          borderTop: `1px solid ${theme.colors['bunker-20']}`,
        }}
      >
        <Text variant="subtitle1">{t('Tillgängligt för uttag')}</Text>
        <Paragraph variant="support3" color="text-low-emphasis" css={{ whiteSpace: 'break-spaces' }}>
          {t(
            'Tillgängligt saldo för uttag är kontots disponibla saldo i SEK. Om du sålt värdepapper måste affären först gå till likvid innan pengarna är tillgängliga för uttag. I de fall det finns pågående köporder för kontot reserveras ordervärdet och är därmed inte tillgängligt för uttag.'
          )}
        </Paragraph>
      </FlexCol>
      <SmartTable<CurrencyAmount, 'currency' | 'value'>
        columns={[
          {
            id: 'currency',
            width: 'minmax(0,1fr)',
            renderHeader: () => t('Valuta'),
            renderCell: ({ row }) => (
              <FlexRow alignItems="center">
                <CurrencyFlag currency={row.currencyCode} size={16} />
                <Text variant="body2" ml={8} color="text-link">
                  {row.currencyCode}
                </Text>
              </FlexRow>
            ),
          },
          {
            id: 'value',
            width: '1fr',
            align: 'right',
            renderHeader: () => '',
            renderCell: ({ row }) => (
              <FormattedNumber
                variant="body2"
                decimals={{ min: 0, max: 2 }}
                unit={row.currencyCode}
                value={row.amount ?? 0}
                showZeroAsFallbackValue={false}
              />
            ),
          },
        ]}
        data={withdrawableAmount}
      ></SmartTable>
      <FlexRow p={16} alignItems="center" justifyContent="center" width="full">
        <Button
          variant="secondary"
          size="medium"
          onClick={() => openTransfersDrawer()}
          disabled={false}
          width="full"
          startIcon={<ButtonIcon icon={IconTransfer} />}
        >
          {t('Gör en överföring')}
        </Button>
      </FlexRow>
    </>
  )
}
