import { InstrumentPosition } from '@common/api/response'
import { SkeletonLoader } from '@common/components/SkeletonLoader'
import { SmartTable } from '@common/components/SmartTable'
import { useSelectedHoldingAccount } from '@common/hooks/useHoldings'
import { sum } from '@common/utils/sum'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Box, Breakpoint, useBreakpoint } from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import { ObservableTableInstrument, useTableInstruments } from '../useTableInstruments'
import { TableAcquisitionFooter, TableAcquisitionMobileFooter } from './TableAcquisitionFooter'
import { TableAcquisitionMobile } from './TableAcquisitionMobile'
import { TableAcquisitionRow } from './TableAcquisitionRow'

type TableAcquisitionProps = {
  holdingsInstruments: InstrumentPosition[]
  showPercentage: boolean
  isFund: boolean
  tableSortStorageKey: string
}

export type TableAcquisitionColumnId =
  | 'expander-button'
  | 'name'
  | 'quantity'
  | 'averageAcquisitionPriceOriginal'
  | 'averageAcquisitionPrice'
  | 'acquisitionCostOriginal'
  | 'acquisitionCost'
  | 'acquisitionFxRate'
  | 'button'

export const TableAcquisition = observer(
  ({ holdingsInstruments, showPercentage, isFund, tableSortStorageKey }: TableAcquisitionProps) => {
    const { t } = useTranslation()
    const breakpoint = useBreakpoint()
    const isExtraSmallScreen = breakpoint <= Breakpoint.Xs

    const { account: selectedAccount } = useSelectedHoldingAccount()
    const navigate = useNavigate()

    const { instruments } = useTableInstruments(holdingsInstruments)

    const totalAcquisitionCost = sum('amount', instruments ? instruments.map((x) => x.acquisitionCost) : [])

    const navigateToTransactions = (instrument: ObservableTableInstrument) => {
      navigate(
        `/overview/transactions/?accountid=${selectedAccount?.id}&instruments=${JSON.stringify([
          instrument.key,
        ])}&from=2005-01-01`
      )
    }

    const showExpanderColumn = instruments?.some((instrument) => instrument.hasDetails)

    return (
      <Box>
        <SkeletonLoader p={16} height={300} loading={!instruments} noDataLoaded={instruments?.length === 0}>
          {() => (
            <SmartTable<ObservableTableInstrument, TableAcquisitionColumnId>
              renderMode={isExtraSmallScreen ? 'custom' : 'table'}
              sessionStorageKey={tableSortStorageKey}
              data={instruments}
              defaultSortBy="name"
              columns={[
                {
                  id: 'expander-button',
                  visible: showExpanderColumn,
                  width: 'auto',
                },
                {
                  id: 'name',
                  width: 'minmax(0,2fr)',
                  renderHeader: () => t('Namn'),
                  sortBy: ({ row }) => row.name,
                },
                {
                  align: 'right',
                  id: 'quantity',
                  width: '1fr',
                  renderHeader: () => t('Antal'),
                  sortBy: ({ row }) => row.quantity,
                },
                {
                  align: 'right',
                  id: 'averageAcquisitionPriceOriginal',
                  width: '1fr',
                  renderHeader: () => t('Ansk. kurs'),
                  sortBy: ({ row }) => row.averageAcquisitionPrice?.amount,
                },
                {
                  align: 'right',
                  id: 'averageAcquisitionPrice',
                  width: '1fr',
                  renderHeader: () => t('Ansk. kurs (SEK)'),
                  sortBy: ({ row }) => row.averageAcquisitionPrice?.amount,
                },
                {
                  align: 'right',
                  id: 'acquisitionCostOriginal',
                  width: '1fr',
                  renderHeader: () => t('Ansk. värde'),
                  sortBy: ({ row }) => row.acquisitionCostOriginal?.amount,
                },
                {
                  align: 'right',
                  id: 'acquisitionCost',
                  width: '1fr',
                  renderHeader: () => t('Ansk. värde (SEK)'),
                  sortBy: ({ row }) => row.acquisitionCost?.amount,
                },
                {
                  align: 'right',
                  id: 'acquisitionFxRate',
                  width: '1fr',
                  renderHeader: () => t('Valutakurs'),
                  sortBy: ({ row }) => row.acquisitionFxRate,
                },
                {
                  renderHeader: () => '',
                  id: 'button',
                  width: 48,
                  sortBy: ({ row }) => row.name,
                },
              ]}
              renderRow={({ row: instrument, columns }) =>
                isExtraSmallScreen ? (
                  <TableAcquisitionMobile
                    isFund={isFund}
                    key={instrument.instrumentIdCarnegie}
                    instrument={instrument}
                    showPercentage={showPercentage}
                    transactionOnClick={() => navigateToTransactions(instrument)}
                  />
                ) : (
                  <TableAcquisitionRow
                    columns={columns}
                    isFund={isFund}
                    key={instrument.instrumentIdCarnegie}
                    instrument={instrument}
                    showPercentage={showPercentage}
                    transactionOnClick={() => navigateToTransactions(instrument)}
                  />
                )
              }
              renderFooterRow={({ columns }) =>
                isExtraSmallScreen ? (
                  <TableAcquisitionMobileFooter totalAcquisitionCost={totalAcquisitionCost} />
                ) : (
                  <TableAcquisitionFooter columns={columns} totalAcquisitionCost={totalAcquisitionCost} />
                )
              }
            />
          )}
        </SkeletonLoader>
      </Box>
    )
  }
)
