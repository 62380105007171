import { InstrumentFlag } from '@common/components/InstrumentFlag'
import { NoDataDash, NoDataFallback } from '@common/components/NoDataFallback'
import { useInfrontInstrument } from '@common/hooks/infront/sdk/useInfrontInstrument'
import { useCarnegieInstrument } from '@common/hooks/useCarnegieInstrument'
import { useInstrumentWithFallbackData } from '@common/hooks/useInstrumentWithFallbackData'
import { InstrumentIdContainer } from '@common/instrumentIdContainer'

import { useTranslation } from 'react-i18next'

import { formatAsDate } from '@carnegie/digital-channels-frontend'
import {
  Box,
  FlexRow,
  Icon,
  IconExternalLink,
  LinkExternal,
  ListItem,
  ListItemRow,
  Segment,
  SkeletonRect,
  Spacer,
  Tag,
  TagIcon,
  TagLabel,
  Text,
} from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import i18n from '../../../i18n'
import { ListItemValue } from '../ListItemValue'

type Props = {
  instrumentIdContainer: InstrumentIdContainer
}

const nasdaqLink = `https://www.nasdaq.com/solutions/nordic-equity-derivatives-kid`

/**
 * Handelsplats saknas. Svar: Undersöker.
 * Listor saknas. Svar: Oklart om instrumentet vet vilka listor det tillhör i fronten. Skulle gå att lösa backend.
 */

export const InstrumentDerivativesInformation = observer(({ instrumentIdContainer }: Props) => {
  const { t } = useTranslation()

  const instrumentWithFallback = useInstrumentWithFallbackData(instrumentIdContainer, ['isin', 'marketplace'])

  const { observableInstrument, isLoading: isLoadingInfrontInstrument } = useInfrontInstrument(
    instrumentIdContainer?.infrontInstrument,
    [
      InfrontSDK.SymbolField.ContractSize,
      InfrontSDK.SymbolField.Expiry,
      InfrontSDK.SymbolField.PrimarySegmentDescription,
      InfrontSDK.SymbolField.PrimarySegment,
      InfrontSDK.SymbolField.StrikePrice,
      InfrontSDK.SymbolField.UnderlyingTicker,
    ],
    { observe: false }
  )

  const { isin, marketplaceName } = instrumentWithFallback

  const expiry = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.Expiry)
  const expiryDate = expiry !== undefined && expiry !== null ? formatAsDate(InfrontUtil.DateFromDateNu(expiry)) : null
  const underlyingTicker = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.UnderlyingTicker)
  const strikePrice = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.StrikePrice)
  const contractSize = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.ContractSize)

  const { carnegieInstrument, isLoading: isLoadingCarnegieInstrument } = useCarnegieInstrument(
    instrumentIdContainer,
    i18n.language
  )

  return (
    <Box>
      <Segment noContentPadding>
        <ListItem divider>
          <ListItemRow
            title={<Text variant="support2">{t('Värdepapperstyp')}</Text>}
            value={
              <ListItemValue loading={isLoadingCarnegieInstrument} value={carnegieInstrument?.instrumentTypeName} />
            }
          />
        </ListItem>
        <ListItem divider>
          <ListItemRow
            title={<Text variant="support2">{t('Underliggande')}</Text>}
            value={<ListItemValue loading={isLoadingInfrontInstrument} value={underlyingTicker} />}
          />
        </ListItem>
        <ListItem divider>
          <ListItemRow
            title={<Text variant="support2">{t('Kortnamn')}</Text>}
            value={
              <Text variant="subtitle2">
                {instrumentIdContainer?.infrontInstrument ? (
                  instrumentIdContainer?.infrontInstrument.ticker
                ) : (
                  <NoDataDash />
                )}
              </Text>
            }
          />
        </ListItem>
        <ListItem divider>
          <ListItemRow
            title={<Text variant="support2">{t('Slutdag')}</Text>}
            value={<ListItemValue value={expiryDate} />}
          />
        </ListItem>
        <ListItem divider>
          <ListItemRow title={<Text variant="support2">{t('ISIN')}</Text>} value={<ListItemValue value={isin} />} />
        </ListItem>
        <ListItem divider>
          <ListItemRow
            title={<Text variant="support2">{t('Lösenpris')}</Text>}
            value={<ListItemValue loading={isLoadingInfrontInstrument} value={strikePrice} />}
          />
        </ListItem>
        <ListItem divider>
          <ListItemRow
            title={<Text variant="support2">{t('Antal underliggande')}</Text>}
            value={<ListItemValue loading={isLoadingInfrontInstrument} value={contractSize} />}
          />
        </ListItem>
        <ListItem divider>
          <ListItemRow
            title={<Text variant="support2">{t('Faktablad')}</Text>}
            value={
              isin === undefined ? (
                <SkeletonRect height={20} width={96} />
              ) : (
                <LinkExternal truncate variant="subtitle2" target="_blank" href={nasdaqLink} title={t('Fondfaktablad')}>
                  <FlexRow alignItems="center">
                    <Box mr={4}>
                      <Icon color="current" icon={IconExternalLink} size={16} />
                    </Box>
                    <span>{t('Faktablad')}</span>
                  </FlexRow>
                </LinkExternal>
              )
            }
          />
        </ListItem>
      </Segment>
      <Spacer height={16} />
      <Text variant="overline">{t('Handelsplats')}</Text>
      <Spacer height={8} />
      {marketplaceName === undefined ? (
        <SkeletonRect height={32} width={100} />
      ) : (
        <Tag>
          {instrumentIdContainer?.infrontInstrument && (
            <TagIcon>
              <InstrumentFlag instrumentIdContainer={instrumentIdContainer} />
            </TagIcon>
          )}
          <TagLabel>
            <NoDataFallback>{marketplaceName}</NoDataFallback>
          </TagLabel>
        </Tag>
      )}
    </Box>
  )
})
InstrumentDerivativesInformation.displayName = 'InstrumentDerivativesInformation'
