import { SelectedAccountIdProvider } from '@common/components/SelectedAccountProvider'
import { ShowAll } from '@common/components/ShowAll'
import { Disclaimer } from '@common/components/drawer/Disclaimer'
import { InfrontChartPeriod } from '@common/hooks/infront/infrontChartPeriod'
import { useInfrontInstrument } from '@common/hooks/infront/sdk/useInfrontInstrument'
import { useCarnegieInstrument } from '@common/hooks/useCarnegieInstrument'
import { useFeatures } from '@common/hooks/useFeatures'
import { useInstrumentHoldings } from '@common/hooks/useHoldings'
import { usePeriods } from '@common/hooks/usePeriods'
import { useSelectedAccountId } from '@common/hooks/useSelectedAccountId'
import { InstrumentIdContainer } from '@common/instrumentIdContainer'
import { useAuth } from '@common/stores/store'
import { fireTrackEvent } from '@common/utils/analyticsEvent'
import { isPredefinedInfrontInstrument } from '@common/utils/infrontPredefinedInstruments'

import React, { ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Banner,
  Box,
  Breakpoint,
  Button,
  ButtonSwitch,
  FlexRow,
  GridContainer,
  GridItem,
  Link,
  Paragraph,
  Segment,
  SkeletonRect,
  Spacer,
  Text,
  useBreakpoint,
} from '@carnegie/duplo'

import { useTargetAndRecommendations } from '@/pages/research/shared/useResearchTargetItems'

import { observer } from 'mobx-react-lite'

import { InstrumentFeedback } from '../../pages/overview/order/OrderFeedback'
import { ResearchAndComments } from '../../pages/research/researchExplore/ResearchAndComments'
import { useContentAnalysis } from '../../pages/research/shared/useContentAnalysis'
import { useOpenFundOrderDrawer } from '../fundOrderDrawer/hooks/useOpenFundOrderDrawer'
import { useOpenOrderDrawer } from '../orderDrawer/useOpenOrderDrawer'
import { InstrumentChart } from './InstrumentChart'
import { InstrumentHeader } from './InstrumentHeader'
import { InstrumentMyHoldings } from './InstrumentMyHoldings'
import { InstrumentNews } from './InstrumentNews'
import { InstrumentPerformance } from './InstrumentPerformance'
import { InstrumentTopSegment } from './InstrumentTopSegment'
import { InstrumentDerivativesInformation } from './derivatives/InstrumentDerivativesInformation'
import { InstrumentFundsAllocations } from './funds/InstrumentFundsAllocations'
import { InstrumentFundsMorningStar } from './funds/InstrumentFundsMorningStar'
import { InstrumentFundsOverview } from './funds/InstrumentFundsOverview'
import { InstrumentIndexOverview } from './index/InstrumentIndexOverview'
import { allowWatchAndBuy, showAsIndexType } from './rules'
import { InstrumentStocksOverview } from './stocks/InstrumentStocksOverview'
import { InstrumentStocksPriceInformation } from './stocks/InstrumentStocksPriceInformation'
import { InstrumentUnknownInformation } from './unknown/InstrumentUnknownInformation'
import { useCommonInstrumentType } from './useCommonInstrumentType'
import { useInstrumentDrawerProps } from './useOpenInstrumentDrawer'

const InstrumentDrawerFeedback = () => {
  return (
    <GridContainer>
      <GridItem xs={12}>
        <InstrumentFeedback />
      </GridItem>
    </GridContainer>
  )
}
InstrumentDrawerFeedback.displayName = 'InstrumentDrawerFeedback'

type PerformanceChartPeriodLayoutProps = {
  className?: string
  chart: ReactNode
  performance: ReactNode
  period: ReactNode
}

const PerformanceChartPeriodLayout = ({ className, chart, performance, period }: PerformanceChartPeriodLayoutProps) => {
  const smDown = useBreakpoint() <= Breakpoint.Small

  // https://app.abstract.com/projects/3023bea4-fb7a-4747-93c4-9735b3a3a36f/branches/27013732-124e-46e6-bef5-36204e624b68/commits/latest/files/c5ad3d18-baa0-48cc-8910-920c1b2c752b/layers/423E8193-9B23-4BFC-9E94-2F7736398096?collectionId=2913a002-121a-4fa6-8aae-d20c245cffa6&collectionLayerId=0c87e41f-d73a-473a-8a60-c08093cbba1a&mode=build&selected=3135046866-FD4A981E-5F96-472A-B80D-BDB65386FE77
  return smDown ? (
    <div className={className}>
      <Box>{performance}</Box>
      <Spacer height={8} />
      <Box overflow="hidden" css={{ marginLeft: -8, marginRight: -8 }}>
        <Box css={{ marginLeft: -8, marginRight: -8 }}>{chart}</Box>
      </Box>
      <Spacer height={16} />
      {period}
    </div>
  ) : (
    <div className={className}>
      <FlexRow alignItems="center" justifyContent="space-between">
        {performance}
        <Box width={320} css={{ flexShrink: 0 }}>
          {period}
        </Box>
      </FlexRow>
      <Spacer height={16} />
      {chart}
    </div>
  )
}

PerformanceChartPeriodLayout.displayName = 'PerformanceChartPeriodLayout'

/**
 * Combines the data we need for the drawer into a single hook
 */
function useInstrumentDrawerData(instrumentIdContainer: InstrumentIdContainer) {
  const { i18n } = useTranslation()

  // Get from route url
  //const instrumentIdContainer = useInstrumentDrawerInstrumentIdContainer()

  // Is this an infront instrument (hardcoded list), this is true for some type of instruments like an index instrument etc
  const isPredefinedInstrument = instrumentIdContainer.infrontInstrument?.ticker
    ? isPredefinedInfrontInstrument(instrumentIdContainer.infrontInstrument.ticker)
    : false

  // Conditionally run hook, no need to fetch if it is a predefined instrument since it also means we don't have it in our own service
  const { holdings, error: holdingsError } = useInstrumentHoldings(
    isPredefinedInstrument ? undefined : instrumentIdContainer
  )

  // Will be undefined while loading
  const { carnegieInstrument, error: carnegieInstrumentError } = useCarnegieInstrument(
    isPredefinedInstrument ? undefined : instrumentIdContainer,
    i18n.language
  )

  const collateralValue = carnegieInstrument?.collateralValue

  const instrumentType = useCommonInstrumentType(instrumentIdContainer)

  // If is a predefined instrument we don't need to load anything since we have all the data we need, but if
  // we need to get a carnegieInstrument we need to load it first, this variable keeps this state easy to understand
  let loadingInstrument = true

  if (carnegieInstrument || isPredefinedInstrument || carnegieInstrumentError || holdingsError) {
    loadingInstrument = false
  }

  // Can we get data for this instrument?
  const { error: infrontInstrumentError } = useInfrontInstrument(instrumentIdContainer?.infrontInstrument, [
    InfrontSDK.SymbolField.FullName,
  ])

  return {
    infrontInstrumentError,
    hasErrors: !!(carnegieInstrumentError || holdingsError),
    loadingInstrument,
    instrumentType,
    carnegieInstrumentError,
    carnegieInstrument,
    holdings,
    holdingsError,
    collateralValue,
  }
}

type InstrumentDrawerProps = {
  instrumentIdContainer: InstrumentIdContainer
  selectedAccountId?: string
}

const InstrumentDrawer = observer(() => {
  const { instrumentIdContainer, selectedAccountId = undefined } = useInstrumentDrawerProps()

  // If we are still loading the carnegie id or infront id we wait with rendering the contents of the drawer
  // Because of legacy reasons components might have unexpected errors if this data is not available from the start, it can also
  // cause jank in the UI (charts showing empty data label then the real thing once the infront id comes in etc)
  if (!instrumentIdContainer) {
    // Still loading...
    return null
  }

  return (
    <InstrumentDrawerWithValidInstrument
      instrumentIdContainer={instrumentIdContainer}
      selectedAccountId={selectedAccountId}
    />
  )
})
InstrumentDrawer.displayName = 'InstrumentDrawer'

const InstrumentDrawerWithValidInstrument = observer(
  ({ instrumentIdContainer, selectedAccountId }: InstrumentDrawerProps) => {
    const auth = useAuth()
    const features = useFeatures()
    const breakpoint = useBreakpoint()
    const { t } = useTranslation()

    const { infrontInstrumentError, loadingInstrument, instrumentType, holdings, collateralValue, carnegieInstrument } =
      useInstrumentDrawerData(instrumentIdContainer)

    const { targetAndRecommendations } = useTargetAndRecommendations(carnegieInstrument?.instrumentIdCarnegie)

    const { researchNews } = useContentAnalysis(
      carnegieInstrument?.instrumentIdCarnegie
        ? {
            carnegieInstrumentId: carnegieInstrument?.instrumentIdCarnegie,
            limit: 5,
            page: 1,
            includeRelatedInstrument: true,
            userHasAnalysisPackage: auth?.customerPermissionFlags?.hasAnalysisPackage,
          }
        : undefined
    )

    const isFairValue = targetAndRecommendations?.useFairValue
    const latestResearchNews = researchNews && researchNews[0]

    // Periods stuff
    const periods = usePeriods(instrumentType, instrumentIdContainer?.infrontInstrument?.feed)

    const [selectedPeriod, setSelectedPeriod] = useState<InfrontChartPeriod | null>(null)

    useEffect(() => {
      if (selectedPeriod === null && periods?.length && instrumentType) {
        // The first time we load the available periods, make a period selection based on instrument type
        switch (instrumentType) {
          case 'funds':
            setSelectedPeriod('1Y')
            break
          case 'stocks':
            setSelectedPeriod(periods[0].value)
            break
          case 'derivatives':
          default:
            setSelectedPeriod('3M')
        }
      }
    }, [periods, periods?.length, selectedPeriod, instrumentType])

    // For 'currency', 'interest', 'commodity' we also want to show it like index
    const showAsIndex = showAsIndexType(instrumentType)

    // If we haven't loaded the carnegie instrument we can't determine if we have a stock or fund so we bail out, maybe
    // show a Skeleton etc in the future?
    if (!instrumentType) {
      return null
    }

    const isAllowedInstrumentType =
      instrumentType === 'stocks' ||
      instrumentType === 'funds' ||
      instrumentType === 'derivatives' ||
      instrumentType === 'etf' ||
      instrumentType === 'unknown'

    const topSegment = isAllowedInstrumentType && (
      <InstrumentTopSegment>
        {instrumentType !== 'funds' && (
          <InstrumentStocksPriceInformation instrumentIdContainer={instrumentIdContainer} />
        )}
        {instrumentType === 'funds' && <InstrumentFundsMorningStar instrumentIdContainer={instrumentIdContainer} />}
      </InstrumentTopSegment>
    )

    const overview = (
      <>
        {instrumentType === 'stocks' && (
          <InstrumentStocksOverview instrumentIdContainer={instrumentIdContainer} collateralRatio={collateralValue} />
        )}
        {(instrumentType === 'etf' || instrumentType === 'unknown') && (
          <Segment title={t('Information')} variant="contained" headingVariant="overline">
            <InstrumentUnknownInformation
              instrumentIdContainer={instrumentIdContainer}
              instrumentType={instrumentType}
              collateralRatio={collateralValue}
            />
          </Segment>
        )}
        {showAsIndex && <InstrumentIndexOverview instrumentIdContainer={instrumentIdContainer} />}
        {instrumentType === 'funds' && (
          <InstrumentFundsOverview instrumentIdContainer={instrumentIdContainer} collateralRatio={collateralValue} />
        )}
        {instrumentType === 'derivatives' && (
          <Segment title={t('Information')} variant="contained" headingVariant="overline">
            <InstrumentDerivativesInformation instrumentIdContainer={instrumentIdContainer} />
          </Segment>
        )}
      </>
    )

    const source = (
      <>
        {(instrumentType === 'etf' ||
          instrumentType === 'unknown' ||
          showAsIndex ||
          instrumentType === 'stocks' ||
          instrumentType === 'derivatives') && (
          <>
            <Paragraph variant="body2" color="text-low-emphasis">
              {t(
                'Kurser och marknadsinformationen distribueras av Infront. Källa för data är respektive marknadsplats.'
              )}
            </Paragraph>
            <Disclaimer isInstrument={true} isFairValue={isFairValue} />
          </>
        )}
        {instrumentType === 'funds' && (
          <>
            <Paragraph variant="body2" color="text-low-emphasis">
              {t('Kurser och marknadsinformation distribueras av Infront. Källa för fondinformation är Morningstar.')}
            </Paragraph>
            <Disclaimer isInstrument={true} />
          </>
        )}
      </>
    )

    const showFundsAllocations = instrumentType === 'funds'
    const fundsAllocations = (
      <>{showFundsAllocations && <InstrumentFundsAllocations instrumentIdContainer={instrumentIdContainer} />}</>
    )
    const showMyHoldings = holdings && holdings.length > 0
    const instrumentMyHoldings = showMyHoldings && (
      <InstrumentMyHoldings instrumentIdContainer={instrumentIdContainer} instrumentType={instrumentType} />
    )

    if (
      !loadingInstrument &&
      instrumentIdContainer?.infrontInstrument &&
      (!instrumentIdContainer?.infrontInstrument?.ticker || !instrumentIdContainer?.infrontInstrument?.feed)
    ) {
      return <InstrumentDrawerFeedback />
    }

    const showInstrumentNews = !showAsIndex
    const canWatchAndBuy = allowWatchAndBuy(instrumentType)
    const isExtraSmallScreen = breakpoint < Breakpoint.Small
    const smDown = breakpoint <= Breakpoint.Small
    const isTabletAndUp = breakpoint >= Breakpoint.Medium

    const showResearchAndComments = !!(
      features.showAnalysis &&
      researchNews?.length &&
      !!carnegieInstrument?.instrumentIdCarnegie
    )

    const researchAndComments = showResearchAndComments && (
      <Segment
        title={t('Analyser & kommentarer')}
        noContentPadding
        action={
          auth?.customerPermissionFlags?.hasAnalysisPackage ? (
            <ShowAll
              onClick={() => fireTrackEvent('Research', 'click_showall_researchandcomments')}
              to={`/research/research-and-comments/?companyId=${researchNews[0]?.company?.id ?? ''}&companyName=${
                researchNews[0]?.company?.name ?? ''
              }`}
            />
          ) : null
        }
      >
        <ResearchAndComments showCompanyNameAndTargets={false} researchNews={researchNews} />
      </Segment>
    )

    // New merged version here!
    return (
      <SelectedAccountIdProvider selectedAccountId={selectedAccountId}>
        <GridContainer>
          <GridItem xs={12}>
            <Box>
              <InstrumentHeader
                instrumentIdContainer={instrumentIdContainer}
                selectedAccountId={selectedAccountId}
                instrumentType={instrumentType}
              />
            </Box>
            {infrontInstrumentError && (
              <>
                <Spacer height={smDown ? 16 : 32} />
                <LimitedInfrontDataBanner />
              </>
            )}
            <Spacer height={smDown ? 16 : 32} />
            <PerformanceChartPeriodLayout
              performance={
                selectedPeriod ? (
                  <Box width={isExtraSmallScreen ? 'full' : '1/2'}>
                    <InstrumentPerformance
                      researchNews={latestResearchNews}
                      instrumentType={instrumentType}
                      instrumentIdContainer={instrumentIdContainer}
                      carnegieInstrument={carnegieInstrument}
                      period={selectedPeriod}
                      showTooltip={false}
                    />
                  </Box>
                ) : (
                  <SkeletonRect height={40} />
                )
              }
              chart={
                // The chart can crash for instruments with errors it seems...
                selectedPeriod ? (
                  <InstrumentChart
                    infrontInstrument={instrumentIdContainer?.infrontInstrument}
                    period={selectedPeriod}
                    instrumentType={instrumentType}
                  />
                ) : (
                  <SkeletonRect width="100%" css={{ height: isTabletAndUp ? 292 : 188 }} />
                )
              }
              period={
                periods?.length > 0 && (
                  <ButtonSwitch
                    size="small"
                    options={periods}
                    value={selectedPeriod ?? periods[0].value}
                    onChange={(value) => {
                      setSelectedPeriod(value)
                    }}
                  />
                )
              }
            />
          </GridItem>
          {/* Content (left/right pane) */}
          {smDown ? (
            // Mobile
            <>
              <GridItem xs={12}>
                {topSegment}
                <Box>
                  <Spacer height={16} />
                  {showResearchAndComments && researchAndComments}
                  {showMyHoldings && <Spacer height={16} />}
                  {instrumentMyHoldings}
                  {overview && <Spacer height={16} />}
                  {overview}
                  {showFundsAllocations && <Spacer height={16} />}
                  {fundsAllocations}
                  <Spacer height={16} />
                  {showInstrumentNews && instrumentIdContainer?.infrontInstrument && (
                    <InstrumentNews
                      infrontInstrument={instrumentIdContainer?.infrontInstrument}
                      css={{ marginBottom: 16 }}
                    />
                  )}
                  {source}
                  <Spacer height={64} />
                </Box>
              </GridItem>
              {canWatchAndBuy && (
                <BuySellStickyFooter
                  instrumentIdContainer={instrumentIdContainer}
                  isFund={instrumentType === 'funds'}
                />
              )}
            </>
          ) : (
            // Desktop
            <>
              {/* Left pane */}
              <GridItem xs={8}>
                {topSegment}
                {topSegment && <Spacer height={32} />}
                {overview}
                <Spacer height={32} />
                {showInstrumentNews && instrumentIdContainer?.infrontInstrument && (
                  <InstrumentNews
                    infrontInstrument={instrumentIdContainer?.infrontInstrument}
                    css={{ marginBottom: 32 }}
                  />
                )}
                {source}
              </GridItem>
              {/* Right pane */}
              <GridItem xs={4}>
                {fundsAllocations}
                {showFundsAllocations && <Spacer height={32} />}
                {showResearchAndComments && researchAndComments}
                {showMyHoldings && researchAndComments && <Spacer height={32} />}
                {instrumentMyHoldings}
              </GridItem>
            </>
          )}
        </GridContainer>
      </SelectedAccountIdProvider>
    )
  }
)
InstrumentDrawerWithValidInstrument.displayName = 'InstrumentDrawerWithValidInstrument'

export default InstrumentDrawer

const LimitedInfrontDataBanner = () => {
  const { t } = useTranslation()

  return (
    <>
      <Banner
        severity={'information'}
        title={t('Begränsad information')}
        // onClose={() => setShowBanner(!showBanner)}
        description={
          <Paragraph variant="body1">
            {t(`Vi har begränsad information om det här värdepappret. Det kan bero på flera orsaker,`)}
            &nbsp;
            <Link to="/profile/contact">{t('kontakta oss')}</Link>
            &nbsp;
            {t(`för att få hjälp med eventuella frågor.`)}
          </Paragraph>
        }
      />
    </>
  )
}
LimitedInfrontDataBanner.displayName = 'LimitedInfrontDataBanner'

type BuySellStickyFooterProps = {
  instrumentIdContainer: InstrumentIdContainer
  isFund: boolean
}

const BuySellStickyFooter = ({ instrumentIdContainer, isFund }: BuySellStickyFooterProps) => {
  const { openOrderDrawer } = useOpenOrderDrawer()
  const { openFundOrderDrawer } = useOpenFundOrderDrawer()
  const { t } = useTranslation()

  // From context
  const selectedAccountId = useSelectedAccountId()

  return (
    <FooterContainer>
      <FlexRow flexGrow={1} mx={8} alignItems="center" justifyContent="space-between">
        {isFund ? (
          <>
            <Button
              width="1/2"
              onClick={() => {
                openFundOrderDrawer(instrumentIdContainer, 'buy')
              }}
              size="large"
              variant="dark-blue"
              mr={16}
            >
              <Text color="text-light">{t('Köp')}</Text>
            </Button>
            <Button
              width="1/2"
              onClick={() => {
                openFundOrderDrawer(instrumentIdContainer, 'sell')
              }}
              size="large"
              variant="red"
            >
              <Text color="text-light">{t('Sälj')}</Text>
            </Button>
          </>
        ) : (
          <Button
            mr={8}
            width="full"
            variant="primary"
            size="medium"
            onClick={() => {
              openOrderDrawer(instrumentIdContainer, selectedAccountId)
            }}
          >
            {t('Köp/Sälj')}
          </Button>
        )}
      </FlexRow>
    </FooterContainer>
  )
}
BuySellStickyFooter.displayName = 'BuySellStickyFooter'

const FooterContainer = (props: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      css={{
        position: 'sticky',
        left: '0',
        right: '0',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: '1',
        backgroundColor: '#fdfdfd',
        height: 72,
        bottom: 0,
        paddingTop: 0,
        paddingBottom: 0,
      }}
      {...props}
    >
      {props.children}
    </div>
  )
}
FooterContainer.displayName = 'FooterContainer'
