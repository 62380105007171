import { FundOrderInfoBuy, FundOrderInfoSell, FundOrderType } from '@common/api/response'

import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { debounce } from 'throttle-debounce'

import { validateBuyInput, validateSellInput } from '../utils/validation'

type ValidationProps = {
  value: number
  sellAll: boolean
  fundOrderType: FundOrderType
  fundOrderInfoBuy?: FundOrderInfoBuy
  fundOrderInfoSell?: FundOrderInfoSell
}

export const useFundOrderValidation = ({
  value,
  sellAll,
  fundOrderType,
  fundOrderInfoBuy,
  fundOrderInfoSell,
}: ValidationProps) => {
  const { t } = useTranslation()
  const [inputErrorDebounced, setInputErrorDebounced] = useState('')

  const inputError = useMemo(() => {
    if (fundOrderType === 'buy' && fundOrderInfoBuy) {
      return validateBuyInput(value, fundOrderInfoBuy, t)
    } else if (fundOrderType === 'sell' && fundOrderInfoSell) {
      return validateSellInput(value, sellAll, fundOrderInfoSell, t)
    }
    return ''
  }, [fundOrderType, fundOrderInfoBuy, fundOrderInfoSell, value, t, sellAll])

  const debouncedSetInputError = useMemo(() => {
    return debounce(500, setInputErrorDebounced)
  }, [])

  useEffect(() => {
    debouncedSetInputError(inputError)
  }, [inputError, value, debouncedSetInputError])

  return { inputError, inputErrorDebounced }
}
