import { BoxProps, Flex, FlexCol, Text } from '@carnegie/duplo'

type SubscriptionButtonProps = {
  onClick: () => void
  title: string
  backgroundImage: string
  overline: string
  wide?: boolean
} & BoxProps

const SubscriptionButton = ({ onClick, title, backgroundImage, overline, ...rest }: SubscriptionButtonProps) => {
  return (
    <Flex
      onClick={onClick}
      role="button"
      alignItems="center"
      width="full"
      height={128}
      borderRadius={4}
      css={{
        backgroundSize: 'cover',
        backgroundPosition: 'center 20%',
        backgroundImage: `url(${backgroundImage})`,
        boxShadow:
          '0px 1px 5px rgba(0, 0, 0, 0.2), 0px 2px 1px -1px rgba(0, 0, 0, 0.12), 0px 1px 4px rgba(0, 0, 0, 0.14)',
      }}
      {...rest}
    >
      <FlexCol
        p={16}
        space={8}
        width="full"
        height="full"
        justifyContent="flex-end"
        overflow="hidden"
        borderRadius={4}
        css={{
          background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)',
        }}
      >
        {overline && (
          <Text width="100%" variant="overline" truncate color={'text-light'}>
            {overline}
          </Text>
        )}
        <Text
          width="100%"
          bold
          truncate
          color={'text-light'}
          css={{
            fontSize: '18px',
            lineHeight: '32px',
          }}
        >
          {title}
        </Text>
      </FlexCol>
    </Flex>
  )
}

export default SubscriptionButton
