import { CountryCodeFlag } from '@common/components/CountryCodeToFlag'
import { SkeletonLoader } from '@common/components/SkeletonLoader'
import { useDrawerProps } from '@common/components/drawer/useDrawerStore'
import { createInstrumentIdContainer } from '@common/instrumentIdContainer'

import { useTranslation } from 'react-i18next'

import { checkToday } from '@carnegie/digital-channels-frontend'
import { FlexRow, GridContainer, GridItem, Heading5, Segment, Spacer, Tag, TagLabel, Text } from '@carnegie/duplo'

import parse, { Element, HTMLReactParserOptions, domToReact } from 'html-react-parser'
import { observer } from 'mobx-react-lite'

import { RelatedInstruments } from '../../pages/news/RelatedInstruments'
import { useResearchNewsItem } from '../researchDrawer/ResearchDrawer'

const options: HTMLReactParserOptions = {
  replace: (domNode) => {
    if (domNode instanceof Element) {
      const { attribs, children, name } = domNode

      if (name === 'a') {
        if (!Object.prototype.hasOwnProperty.call(attribs, 'href')) {
          return <>{domToReact(children, options)}</>
        }
        if (attribs.href && attribs.href?.endsWith('.pdf')) {
          return (
            <a target="_blank" rel="noreferrer" href={attribs.href}>
              {domToReact(children, options)}
            </a>
          )
        }
      }
    }
  },
}

const ResearchCommentsDrawer = observer(() => {
  const { routeProps: researchNewsId } = useDrawerProps()

  const { t } = useTranslation()
  const newsItem = useResearchNewsItem(researchNewsId, 'News')

  return (
    <SkeletonLoader loading={!newsItem}>
      {() => (
        <GridContainer spacing={4} style={{ justifyContent: 'flex-start' }}>
          <GridItem xs={12} sm={8}>
            <FlexRow alignItems="center">
              {newsItem?.marketIso && <CountryCodeFlag countryCode={newsItem?.marketIso?.toUpperCase()} size={16} />}
              <Text ml={4} variant="subtitle1">
                {newsItem?.company?.name}
              </Text>
            </FlexRow>
            <Spacer height={8} />
            <Heading5>{newsItem?.headline}</Heading5>
            <Text variant="overline">{`${t('Kommentar')} ${
              newsItem?.analysts ? '- ' + newsItem?.analysts : ''
            } - ${checkToday(new Date(newsItem?.date), {
              short: true,
              showTodayAsTime: true,
            })}`}</Text>
          </GridItem>
          <GridItem xs={12} md={8} lg={8}>
            {newsItem?.text && (
              <>
                {parse(newsItem?.text, options)}
                <Spacer height={32} />
              </>
            )}
          </GridItem>
          <GridItem xs={12} md={8} lg={4}>
            {newsItem?.sectorName && (
              <>
                <Segment title={t('Sektor')} variant="uncontained" headingVariant="overline">
                  <Tag>
                    <TagLabel>{newsItem?.sectorName}</TagLabel>
                  </Tag>
                </Segment>
                <Spacer height={32} />
              </>
            )}
            <RelatedInstruments
              instruments={newsItem?.relatedInstruments?.map((instrument) =>
                createInstrumentIdContainer(instrument.carnegieInstrumentId, instrument.infrontInstrument)
              )}
            />
          </GridItem>
        </GridContainer>
      )}
    </SkeletonLoader>
  )
})
ResearchCommentsDrawer.displayName = 'ResearchCommentsDrawer'

export default ResearchCommentsDrawer
