import { BuySellMenuItem, PlaceFundOrderMenuItem, TransactionMenuItem } from '@common/components/ActionButton'
import { MoreMenuWatchlist } from '@common/components/MoreMenu'

import { useTranslation } from 'react-i18next'

import { formatNumber } from '@carnegie/digital-channels-frontend'
import { FlexCol, FlexRow, MobileTableRow, Text } from '@carnegie/duplo'

import { useOpenInstrumentDrawer } from '../../../../drawers/instrumentDrawer/useOpenInstrumentDrawer'
import { InstrumentNameMobile } from '../InstrumentName'
import { ObservableTableInstrument } from '../useTableInstruments'

type Props = {
  isFund: boolean
  instrument: ObservableTableInstrument
  transactionOnClick?: () => void
  showPercentage: boolean
}

export const TableAboutMobile = ({ isFund, instrument, transactionOnClick }: Props) => {
  const { t } = useTranslation()
  const { openInstrumentDrawer } = useOpenInstrumentDrawer()

  const {
    carnegieInstrumentGroup,
    isoCountryCode,
    marketIso,
    name,
    typeName,
    region,
    weight,
    allocation,
    reservedQuantities,
  } = instrument

  const showFlag = carnegieInstrumentGroup !== 'MutualFunds'

  return (
    <MobileTableRow
      divider
      action={
        <MoreMenuWatchlist instrumentIdContainer={instrument} size="medium">
          {isFund ? (
            <>
              <PlaceFundOrderMenuItem instrumentIdContainer={instrument} fundOrderType={'buy'} />
              <PlaceFundOrderMenuItem instrumentIdContainer={instrument} fundOrderType={'sell'} />
            </>
          ) : (
            <BuySellMenuItem instrumentIdContainer={instrument} />
          )}
          <TransactionMenuItem onClick={transactionOnClick} />
        </MoreMenuWatchlist>
      }
      title={
        <InstrumentNameMobile
          isoCountryCode={isoCountryCode ? isoCountryCode : marketIso}
          name={name}
          reservedQuantities={reservedQuantities}
          showFlag={showFlag}
        />
      }
      onClick={() => openInstrumentDrawer(instrument)}
    >
      <FlexCol alignItems="flex-start" mr={8} flex={1}>
        <Text variant="label1" truncate color="bunker-200">
          {t('Värdepapperstyp')}
        </Text>
        <Text variant="subtitle2" color="bunker-main">
          {typeName}
        </Text>
      </FlexCol>
      <FlexCol alignItems="flex-start" mr={8} flex={1}>
        <Text variant="label1" truncate color="bunker-200">
          {t('Tillgångsslag')}
        </Text>
        <Text variant="subtitle2" color="bunker-main">
          {allocation}
        </Text>
      </FlexCol>
      <FlexCol alignItems="flex-start" mr={8} flex={1}>
        <Text variant="label1" truncate color="bunker-200">
          {t('Region')}
        </Text>
        <Text variant="subtitle2" color="bunker-main">
          {region}
        </Text>
      </FlexCol>
      <FlexCol alignItems="flex-end" flex={1}>
        <Text variant="label1" truncate color="bunker-200">
          {t('Andel')}
        </Text>
        <FlexRow alignItems="baseline" justifyContent="flex-end">
          <Text variant="subtitle2">{formatNumber(weight, { ratio: true })}</Text>
          <Text variant="subtitle2" ml={2}>
            %
          </Text>
        </FlexRow>
      </FlexCol>
    </MobileTableRow>
  )
}
