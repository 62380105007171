import { InfrontChartPeriod } from '../infrontChartPeriod'
import { useInfrontFeedMetadata } from './useInfrontFeedMetadata'

/**
 * ## ⚠️IMPORTANT⚠️
 * ### Requires wrapping component in MobX `observer(...)`
 */
export const useInfrontAvailablePeriods = (feed: number): InfrontChartPeriod[] => {
  const feedInfo = useInfrontFeedMetadata([feed])

  if (feedInfo?.length) {
    const periods = Infront.ChartPeriodButton.kPeriodIds
    const feedData = feedInfo[0]

    const availableInfrontPeriods = Object.entries(periods)
      .map(([period, id]: [InfrontChartPeriod, number]): InfrontChartPeriod => {
        const available = id > 1000 || feedData.trades
        return available ? period : null
      })
      .filter((period) => period !== null)

    return availableInfrontPeriods
  }
}
