import { GetTransferOrdersResponse } from '@common/api/response'
import { TrashCanButtonWithConfirm } from '@common/components/TrashCanButtonWithConfirm'
import { useHoldingAccounts } from '@common/hooks/useHoldings'

import { useTranslation } from 'react-i18next'

import { formatNumber, useFormatDate } from '@carnegie/digital-channels-frontend'
import { FlexRow, Link, Paragraph, Td, Tooltip, Tr } from '@carnegie/duplo'

import TransferStatusIcon from './TransferStatusIcon'

type Props = { ongoingTransfer: GetTransferOrdersResponse; cancelTransfer: (transferOrderId: number) => Promise<void> }

const OngoingTransferRow = ({ ongoingTransfer, cancelTransfer }: Props) => {
  const { t } = useTranslation()
  const { accounts } = useHoldingAccounts()
  const { formatDate } = useFormatDate()
  const { created, fromAccountNo, toAccountNo, amount, displayState, id, isCancelable } = ongoingTransfer
  const formattedAmount = formatNumber(amount, { decimals: 2 })
  const fromInternalAccount = accounts?.find((account) => account.number === fromAccountNo)
  const toInternalAccount = accounts?.find((account) => account.number === toAccountNo)

  return (
    <Tr key={ongoingTransfer.id}>
      <Td>{formatDate(created, 'today HH:mm/yesterday HH:mm/yyyy-MM-dd')}</Td>
      <Td>
        {fromInternalAccount ? (
          <Link to={`/accounts/${fromInternalAccount?.id}`} variant="support1">
            {fromInternalAccount?.number}
          </Link>
        ) : (
          fromAccountNo
        )}
      </Td>
      <Td>
        {toInternalAccount ? (
          <Link to={`/accounts/${toInternalAccount?.id}`} variant="support1">
            {toInternalAccount?.number}
          </Link>
        ) : (
          <Tooltip title={toAccountNo}>
            <Paragraph variant="body2">{toAccountNo}</Paragraph>
          </Tooltip>
        )}
      </Td>
      <Td>{formattedAmount}</Td>
      <Td></Td>
      <Td>
        <Tooltip title={t(displayState)}>
          <FlexRow width="full" justifyContent="center">
            <TransferStatusIcon state={displayState} />
          </FlexRow>
        </Tooltip>
      </Td>
      <Td>
        {isCancelable && (
          <FlexRow width="full" justifyContent="center">
            <TrashCanButtonWithConfirm
              size="small"
              bodyText={t('Är du säker på att du vill ta bort överföringen?')}
              confirmButtonText={t('CONFIRM_REMOVE_TRANSFER')}
              iconTooltipText={t('TOOLTIP_REMOVE_TRANSFER')}
              onClick={() => cancelTransfer(id)}
            />
          </FlexRow>
        )}
      </Td>
    </Tr>
  )
}

export default OngoingTransferRow
