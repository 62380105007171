import { AccountSummary } from '@common/api/response'

import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { formatNumber } from '@carnegie/digital-channels-frontend'
import { Breakpoint, DataCell, FlexCol, useBreakpoint } from '@carnegie/duplo'

import { AccountSummaryItem } from '../../pages/accounts/AccountSummary/AccountSummary'
import { PerformanceSerieResponse } from '../api/response'
import { useLocalStorage } from '../stores/store'

export function useAccountSummaryData(summary: AccountSummary, performanceSerie: PerformanceSerieResponse) {
  const localStorage = useLocalStorage()
  const { t } = useTranslation()
  const breakpoint = useBreakpoint()
  const isExtraSmallScreen = breakpoint < Breakpoint.Small
  const [expanded, setExpanded] = useState(localStorage.getItem<string>('ACCOUNT_SUMMARY_EXPANDED', 'user') === 'true')

  const shouldShowCoupon = !!summary?.accruedCoupon?.amount || !!summary?.couponCash?.amount

  const onExpandClick = () => {
    const notExpanded = !expanded
    setExpanded(notExpanded)
    localStorage.setItem('ACCOUNT_SUMMARY_EXPANDED', notExpanded.toString(), 'user')
  }

  const items: AccountSummaryItem[] = [
    {
      uniqueKey: 'EK',
      translatedLabel: 'Eget kapital',
      currencyCode: summary?.portfolioValue?.currencyCode,
      value: summary?.portfolioValue?.amount,
    },
    {
      uniqueKey: 'LM',
      translatedLabel: 'Likvida medel',
      currencyCode: summary?.liquidAsset?.currencyCode,
      value: summary?.liquidAsset?.amount,
    },
    {
      uniqueKey: 'MV',
      translatedLabel: 'Marknadsvärde',
      currencyCode: summary?.marketValue?.currencyCode,
      value: summary?.marketValue?.amount,
    },
    {
      uniqueKey: 'RYR',
      translatedLabel: 'Realiserat i år',
      currencyCode: performanceSerie?.realised?.currencyCode,
      value: performanceSerie?.realisedYear,
    },
    {
      uniqueKey: 'BV',
      translatedLabel: 'Belåningsvärde',
      currencyCode: summary?.collateralValue?.currencyCode,
      value: summary?.collateralValue?.amount,
    },
    {
      uniqueKey: 'KL',
      translatedLabel: 'Kreditlimit',
      currencyCode: summary?.creditLimit?.currencyCode,
      value: summary?.creditLimit?.amount,
    },
  ]

  const getAccountSummaryItem = (searchKey: string) => {
    const item = items.find((x) => x.uniqueKey === searchKey)
    return (
      <FlexCol flex={1} mr={isExtraSmallScreen ? 0 : 16}>
        <DataCell label={t(item?.translatedLabel)} suffix={item?.currencyCode}>
          {formatNumber(item?.value, { decimals: 0 })}
        </DataCell>
      </FlexCol>
    )
  }

  return {
    expanded,
    setExpanded,
    onExpandClick,
    shouldShowCoupon,
    getAccountSummaryItem,
    isExtraSmallScreen,
  }
}
