import { useEffect } from 'react'

import { useLocalObservable } from 'mobx-react-lite'

import { AccountDividendsResponse } from '../api/response'
import { AccountDividendsStore } from '../stores/accountDividendsStore'
import { useSessionStorage } from '../stores/store'
import { useHttpSwr } from './useHttpSwr'

function useAccountDividendsData(accountId: string) {
  const { data, error } = useHttpSwr<{ item: AccountDividendsResponse }>(`/accounts/${accountId}/dividends`)

  return {
    dividends: data?.item.dividends,
    couponInterest: data?.item.couponInterest,
    accruedCouponInterest: data?.item.accruedCouponInterest,
    error: error,
  }
}

export function useAccountDividends(accountId: string) {
  const sessionStorage = useSessionStorage()
  const STORAGE_KEY = `ACCOUNT_DIVIDENDS`
  const accountDividendsStore = useLocalObservable(() => new AccountDividendsStore(sessionStorage, STORAGE_KEY))

  const { dividends, error } = useAccountDividendsData(accountId)

  useEffect(() => {
    if (dividends) accountDividendsStore.init(dividends)

    return () => accountDividendsStore.dispose()
  }, [accountDividendsStore, dividends])

  return {
    dividends: accountDividendsStore.accountDividends,
    sortedBy: accountDividendsStore.sortedBy,
    sort: accountDividendsStore.sort,
    error: error,
  }
}

export function useCouponInterest(accountId: string) {
  const sessionStorage = useSessionStorage()
  const STORAGE_KEY = `COUPON_INTEREST`
  const accountDividendsStore = useLocalObservable(() => new AccountDividendsStore(sessionStorage, STORAGE_KEY))

  const { couponInterest, error } = useAccountDividendsData(accountId)

  useEffect(() => {
    if (couponInterest) accountDividendsStore.init(couponInterest)

    return () => accountDividendsStore.dispose()
  }, [accountDividendsStore, couponInterest])

  return {
    dividends: accountDividendsStore.accountDividends,
    sortedBy: accountDividendsStore.sortedBy,
    sort: accountDividendsStore.sort,
    error: error,
  }
}

export function useAccruedCouponInterest(accountId: string) {
  const sessionStorage = useSessionStorage()
  const STORAGE_KEY = `ACCRUED_COUPON_INTEREST`
  const accountDividendsStore = useLocalObservable(() => new AccountDividendsStore(sessionStorage, STORAGE_KEY))

  const { accruedCouponInterest, error } = useAccountDividendsData(accountId)

  useEffect(() => {
    if (accruedCouponInterest) accountDividendsStore.init(accruedCouponInterest)

    return () => accountDividendsStore.dispose()
  }, [accountDividendsStore, accruedCouponInterest])

  return {
    dividends: accountDividendsStore.accountDividends,
    sortedBy: accountDividendsStore.sortedBy,
    sort: accountDividendsStore.sort,
    error: error,
  }
}
