import { BuySellMenuItem } from '@common/components/ActionButton'
import { MoreMenu, WatchlistMenuItems } from '@common/components/MoreMenu'
import { InstrumentIdContainer } from '@common/instrumentIdContainer'

export const ResearchNewsMoreMenu = ({ instrumentIdContainer, size = 'medium' }: ResearchNewsMoreMenuProps) => {
  return (
    <MoreMenu size={size} variant="uncontained">
      <WatchlistMenuItems instrumentIdContainer={instrumentIdContainer}>
        <BuySellMenuItem instrumentIdContainer={instrumentIdContainer} />
      </WatchlistMenuItems>
    </MoreMenu>
  )
}

type ResearchNewsMoreMenuProps = {
  instrumentIdContainer: InstrumentIdContainer
  size?: 'medium' | 'small'
}
