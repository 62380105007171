import {
  CancelSimpleOrderRequest,
  EnvelopeItemsDescriptorOfSimpleOrder,
  InsertSimpleOrderRequest,
  InstrumentType,
} from '@common/api/response'
import { EventEmitter, useEventEmitterSubscriber } from '@common/hooks/eventEmitter'
import { useHttpSwr } from '@common/hooks/useHttpSwr'
import { useWebSocket } from '@common/hooks/useWebSocket'
import { useApi } from '@common/stores/store'

import { useEffect, useRef } from 'react'

import queryString from 'query-string'

export function useSimpleOrder({
  accountId,
  fromDate,
  instrumentType,
  toDate,
  orderIds,
}: {
  accountId: string
  fromDate: string
  instrumentType: InstrumentType
  toDate: string
  orderIds?: number[]
}) {
  const request = {
    accountId,
    fromDate,
    instrumentType,
    toDate,
    orderIds,
  }

  const params = queryString.stringify(request) //making query-params

  const key = `/digital-channels/order/simple-order?${params}`
  const { data, error, mutate } = useHttpSwr<EnvelopeItemsDescriptorOfSimpleOrder>(accountId ? key : undefined)

  // A fallback to detect and refresh orders after insert or cancel in the cases that the websocket message is not working properly
  // This hook can be removed if we manage to fix these problems in the future
  useFallbackRefreshOrders(mutate, data)

  useWebSocket((message) => {
    if (message.type === 'ORDER_MODIFIED') {
      mutate()
    }
  })

  return {
    orders: data?.items,
    error,
    mutate,
  }
}

export function useSimpleOrderInsert() {
  const api = useApi()

  const insertOrder = async (body: InsertSimpleOrderRequest) => {
    const response = await api.insertOrder(body)

    simpleOrderEventEmitter.emit(SIMPLE_ORDER_MODIFIED)

    return response?.item
  }
  return {
    insertOrder: insertOrder,
  }
}

export function useSimpleOrderCancel() {
  const api = useApi()

  const cancelOrder = async (body: CancelSimpleOrderRequest) => {
    const response = await api.cancelOrder(body)

    simpleOrderEventEmitter.emit(SIMPLE_ORDER_MODIFIED)

    return response?.item?.canceled ?? false
  }
  return {
    cancelOrder: cancelOrder,
  }
}

/**
 * Refresh orders when an order is modified (cancelled or inserted) this is just a fallback solution and should optimally be removed if / when websocket messages work better again.
 * @param mutate
 * @param data
 */
function useFallbackRefreshOrders(mutate: () => void, data: EnvelopeItemsDescriptorOfSimpleOrder) {
  const manualRevalidation = useRef(false)

  useEffect(() => {
    // The new data has arrived after we called mutate, we now wait 5 more seconds and do it again to make sure we catch any status updates
    // this method is not perfect and can miss an update currently
    if (manualRevalidation.current) {
      manualRevalidation.current = false

      setTimeout(() => {
        mutate()
      }, 5000)
    }
  }, [data, mutate])

  // Triggered when an event is sent by useSimpleOrderInsert or useSimpleOrderCancel
  useEventEmitterSubscriber(simpleOrderEventEmitter, SIMPLE_ORDER_MODIFIED, () => {
    manualRevalidation.current = true
    mutate()
  })
}

const simpleOrderEventEmitter = new EventEmitter()
const SIMPLE_ORDER_MODIFIED = 'SIMPLE_ORDER_MODIFIED'
