/**
 * Returns the sum of the values of a key in an array of objects.
 * If the value to be added is not a number, it is ignored.
 * @param {string} key
 * @param {Array<Object>} array
 * @returns {number} The sum of the values of the key in the array
 */
export const sum = <T>(key: keyof T, array: T[]): number => {
  if (!array.length) return null

  return array.reduce((a: number, b: T) => {
    const value = b[key]
    if (typeof value !== 'number') return a
    return a + value
  }, null)
}
