import { Asset } from '@common/api/response'
import { useHttpSwr } from '@common/hooks/useHttpSwr'

import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

// Market value is needed for currency conversion etc
export function useUnderlyingAssets(instrumentIdCarnegie: string | undefined, marketValue: number) {
  const { t } = useTranslation()

  const { data, error } = useHttpSwr<{ items: Asset[] }>(
    instrumentIdCarnegie
      ? `/instruments/underlying-assets/${instrumentIdCarnegie}?marketValue=${marketValue}`
      : undefined
  )

  const resortedItems = useMemo(() => {
    if (data?.items) {
      let items = data.items
      // Words that begin with the name "KASSA", should be moved to the end of the sorting + translated
      // Read more: https://dev.azure.com/carnegieinvestmentbank/CarnegieIT/_sprints/taskboard/Team%20Client/CarnegieIT/PI-15/PI-15%20Sprint%204?workitem=84163
      const cashRow = items
        .filter((ua) => ua.instrumentName.toLowerCase() === 'kassa')
        .map((cr) => ({
          ...cr,
          instrumentName: t(cr.instrumentName),
          holdingQuantity: round(cr.holdingQuantity, 2),
        }))

      items = data.items.sort((a, b) => b.weight - a.weight)
      items = [...items.filter((ua) => ua.instrumentName.toLowerCase() !== 'kassa'), ...cashRow]

      return items
    } else {
      return data?.items
    }
  }, [data?.items, t])

  return { details: resortedItems, error }
}

function round(number: number, precision: number) {
  const power = 10 ** precision
  return Math.round(number * power) / power
}
