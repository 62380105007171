import { appInsights } from '../../appInsights'

function logInfrontError(error: InfrontSDK.ErrorBase) {
  appInsights.trackEvent({
    name: error.title,
    properties: {
      description: error.description,
      parameters: error.parameters,
      type: error.type,
      operation: 'sdk.get',
    },
  })
}

export { logInfrontError }
