import { ShowAtBreakpoint } from '@common/components/ShowAtBreakpoint'
import { css } from '@emotion/react'

import React, { ReactNode } from 'react'

import { Breakpoint, FlexCol, colors, lg, md, sm } from '@carnegie/duplo'

type ResponsiveSizes = { xs: number; sm: number; md: number; lg: number }

const appShellConfig = {
  navBarHeight: 56,
  sideBarWidth: 96,
  // The space between the header/menu and the content/sidebar
  headerContentSpacing: { xs: 16, sm: 16, md: 32, lg: 32 },
  sideBarTops: { xs: 56 + 16, sm: 56 + 16, md: 56 + 32, lg: 56 + 32 },
  outerMargins: { xs: 8, sm: 16, md: 16, lg: 32 },
  logoMarginRight: { xs: 0, sm: 0, md: 16, lg: 32 },
  sideBarMarginRight: { xs: 0, sm: 0, md: 16, lg: 32 },
}

function getPaddingsTop(sizes: ResponsiveSizes) {
  return `
    padding-top: ${sizes.xs}px;
    ${sm(`padding-top: ${sizes.sm}px;`)}
    ${md(`padding-top: ${sizes.md}px;`)}
    ${lg(`padding-top: ${sizes.lg}px;`)}`
}

function getTops(sizes: ResponsiveSizes) {
  return `
    top: ${sizes.xs}px;
    ${sm(`top: ${sizes.sm}px;`)}
    ${md(`top: ${sizes.md}px;`)}
    ${lg(`top: ${sizes.lg}px;`)}`
}

function getMarginsRight(sizes: ResponsiveSizes) {
  return `
    margin-right: ${sizes.xs}px;
    ${sm(`margin-right: ${sizes.sm}px;`)}
    ${md(`margin-right: ${sizes.md}px;`)}
    ${lg(`margin-right: ${sizes.lg}px;`)}`
}

function getPaddingsX(sizes: ResponsiveSizes) {
  return `
      padding: 0px ${sizes.xs}px;
      ${sm(`padding: 0px ${sizes.sm}px;`)}
      ${md(`padding: 0px ${sizes.md}px;`)}
      ${lg(`padding: 0px ${sizes.lg}px;`)}`
}

type AppShellProps = {
  sideBar?: ReactNode
  children?: ReactNode
  topNavBar?: ReactNode
  footer?: ReactNode
  topNavBarStyle?: React.CSSProperties
}

type NavBarProps = {
  logoContent?: ReactNode
  children?: ReactNode
  style?: React.CSSProperties
}

export const AppShell = ({ children, topNavBar, sideBar, footer, topNavBarStyle }: AppShellProps) => {
  return (
    <FlexCol width="full" minHeight="100vh">
      {/* Header */}
      <NavBar style={topNavBarStyle}>{topNavBar}</NavBar>
      {/* Content */}
      <Body sideBarContent={sideBar}>{children}</Body>
      {/* Footer */}
      {footer}
    </FlexCol>
  )
}

const NavBar = ({ children, style }: NavBarProps) => {
  return (
    <header
      style={style}
      css={{
        zIndex: '10',
        position: 'sticky',
        top: '0',
        background: `${colors.gradients.blueDark}`,
        color: 'white',
        height: `${appShellConfig.navBarHeight}px`,
      }}
    >
      <Container
        css={css`
          height: 100%;
          align-items: center;
          display: flex;
          flex-direction: row;
          align-items: center;
          ${getPaddingsX(appShellConfig.outerMargins)}
        `}
      >
        <NavBarContainer>{children}</NavBarContainer>
      </Container>
    </header>
  )
}

const Body = ({ children, sideBarContent }: { children?: ReactNode; sideBarContent?: ReactNode }) => {
  return (
    <Container
      css={css`
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        ${getPaddingsX(appShellConfig.outerMargins)}
        ${getPaddingsTop(appShellConfig.headerContentSpacing)}
      `}
    >
      {/* Sidebar content area */}
      <ShowAtBreakpoint greaterThanOrEqual={Breakpoint.Medium}>
        <SidebarContainer>{sideBarContent}</SidebarContainer>
      </ShowAtBreakpoint>
      {/* Main content area */}
      <MainContentArea>{children}</MainContentArea>
      {/* </div> */}
    </Container>
  )
}

const MainContentArea = ({ children }: { children: ReactNode }) => {
  return (
    <div
      css={css`
        min-width: 0;
        flex-grow: 1;
      `}
    >
      {children}
    </div>
  )
}

const NavBarContainer = ({ children }: { children?: ReactNode }) => {
  return (
    <div
      css={css`
        height: 100%;
        width: 100%;
        display: flex;
        flex-grow: 1;
        align-items: center;
        // width: 100%;
      `}
    >
      {children}
    </div>
  )
}

const SidebarContainer = ({ children }: { children: ReactNode }) => {
  return (
    <div
      css={css`
        flex-shrink: 0;
        width: ${appShellConfig.sideBarWidth}px;
        ${getMarginsRight(appShellConfig.sideBarMarginRight)}
      `}
    >
      <div
        css={css`
          position: sticky;
          ${getTops(appShellConfig.sideBarTops)}
        `}
      >
        {children}
      </div>
    </div>
  )
}

const Container = (props: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div css={{ width: '100%', marginLeft: 'auto', marginRight: 'auto', maxWidth: 1560 }} {...props}>
      {props.children}
    </div>
  )
}
