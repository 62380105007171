import { useTranslation } from 'react-i18next'

import { Button, Card, FlexCol, Heading5, Paragraph } from '@carnegie/duplo'

import { PublicPage } from '../PublicPage'

const LoginErrorPage = () => {
  const { t } = useTranslation()

  return (
    <PublicPage>
      <Card>
        <FlexCol alignItems="center" p={24}>
          <Heading5 mb={32}>{t('Du har blivit utloggad')}</Heading5>
          <Paragraph variant="body1" textAlign="center">
            {t('Din session har gått ut. Vänligen logga in igen.')}
          </Paragraph>
          <Button onClick={() => (document.location = '/')} variant="primary" size="large" mt={32}>
            {t('Logga in')}
          </Button>
        </FlexCol>
      </Card>
    </PublicPage>
  )
}

export default LoginErrorPage
