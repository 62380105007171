import { PaginationResponseOfReportResponseModel } from '@common/api/response'
import { useHttpSwr } from '@common/hooks/useHttpSwr'

import queryString from 'query-string'

export function useCustomerReports(accountId: string, page?: number, pageSize?: number, languageCode?: string) {
  const request = {
    page,
    pageSize,
    languageCode,
  }
  const params = queryString.stringify(request) //making query-params

  const key = `/reports/customer-reports/${accountId}?${params}`

  const { data, error, mutate } = useHttpSwr<PaginationResponseOfReportResponseModel>(accountId ? key : undefined)

  return {
    items: data?.items,
    refresh: mutate,
    error,
    currentPage: data?.currentPage,
    totalPages: data?.totalPages,
    totalRows: data?.totalRows,
    usingLimit: data?.usingLimit,
  }
}
