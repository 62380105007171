import { CountryCodeFlag } from '@common/components/CountryCodeToFlag'
import { useInfrontInstrument } from '@common/hooks/infront/sdk/useInfrontInstrument'
import { createInstrumentIdContainer } from '@common/instrumentIdContainer'
import { fireTrackEvent } from '@common/utils/analyticsEvent'
import { getValueColor } from '@common/utils/colorUtils'
import { getLastOrPreLast } from '@common/utils/infront/getLastOrPreLast'

import { useTranslation } from 'react-i18next'

import { checkToday, formatNumber } from '@carnegie/digital-channels-frontend'
import { FlexRow, Link, ListItem, ListItemRow, SimpleTabs, SkeletonText, Text } from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import { useOpenInstrumentDrawer } from '../../../drawers/instrumentDrawer/useOpenInstrumentDrawer'
import { CorePick } from '../shared/corePicks'
import { useAnalysisSmallCap } from '../shared/useTopPicks'

const TopPickStocks = () => {
  const { t } = useTranslation()
  const { smallCap } = useAnalysisSmallCap()

  return (
    <SimpleTabs
      tabs={[
        {
          value: 'tab-analysis-small-cap',
          label: t('Small Caps'),
          content: (
            <>
              {smallCap?.map((smallCapPick, index) => (
                <TopPickRow key={index} corePick={smallCapPick} />
              ))}
              <FlexRow height={40} width="full" alignItems="center" justifyContent="center">
                <Link
                  onClick={() => fireTrackEvent('Research', 'click_showmore_smallcaps')}
                  to="/research/small-caps"
                  size="small"
                >
                  <Text variant="subtitle2">{t('Visa mer information')}</Text>
                </Link>
              </FlexRow>
            </>
          ),
        },
      ]}
    />
  )
}

interface TopPickRowProps {
  corePick: CorePick
}

const TopPickRow = observer(({ corePick }: TopPickRowProps) => {
  const { publishDate, companyName, marketIso, relatedInstrument } = corePick
  const { openInstrumentDrawer } = useOpenInstrumentDrawer()

  const infrontInstrument = relatedInstrument?.infrontInstrument

  const instrumentIdContainer = createInstrumentIdContainer(relatedInstrument?.carnegieInstrumentId, infrontInstrument)

  const { observableInstrument, isLoading } = useInfrontInstrument(infrontInstrument, [
    InfrontSDK.SymbolField.ChangePercent,
    InfrontSDK.SymbolField.Last,
    InfrontSDK.SymbolField.PreLastTradedAt,
    InfrontSDK.BasicField.PriceDecimals,
  ])

  const priceDecimals = observableInstrument?.getFieldValue(InfrontSDK.BasicField.PriceDecimals)

  const last = getLastOrPreLast(observableInstrument)
  const changePercent = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.ChangePercent)

  return (
    <ListItem divider onClick={() => openInstrumentDrawer(instrumentIdContainer)}>
      <ListItemRow
        title={
          <Text variant="overline">
            {checkToday(new Date(publishDate), {
              short: true,
              showTodayAsTime: true,
            })}
          </Text>
        }
        value={
          isLoading ? (
            <SkeletonText height={20} width={64} />
          ) : (
            <Text variant="subtitle1" color={getValueColor(changePercent)}>
              {formatNumber(changePercent)}
              <Text variant="label1" ml={2} color={getValueColor(changePercent)}>
                %
              </Text>
            </Text>
          )
        }
      />
      <ListItemRow
        title={
          <FlexRow minWidth={0} alignItems="center">
            {
              <FlexRow alignItems="center" mr={4}>
                <CountryCodeFlag countryCode={marketIso} size={16} />
              </FlexRow>
            }
            <Text variant="subtitle1" truncate>
              {companyName}
            </Text>
          </FlexRow>
        }
        value={
          isLoading ? (
            <SkeletonText height={20} width={64} />
          ) : (
            <Text variant="subtitle1">{formatNumber(last, { decimals: { min: 2, max: priceDecimals } })}</Text>
          )
        }
      />
    </ListItem>
  )
})

export default TopPickStocks
