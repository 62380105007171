import { CurrencyPosition } from '@common/api/response'

import { useEffect } from 'react'

import { makeObservable, observable } from 'mobx'
import { useLocalObservable } from 'mobx-react-lite'

export class Currency {
  key: string
  currencyName: string
  fxRate: number
  marketValue: number
  quantity: number
}

class CurrencyStore {
  currencies: Currency[] = []
  private readonly storageKey: string
  private static idCounter = 0

  constructor() {
    makeObservable(this, {
      currencies: observable,
    })

    if (this.storageKey === '') {
      throw new Error(`Creating a CurrencyStore without a storage key will make the storage global.`)
    }
  }

  init = (currencies: CurrencyPosition[]) => {
    this.dispose()

    // We create one currency position per holdings currencies instrument
    for (const currency of currencies) {
      this.addNewCurrencyPosition(currency)
    }

    this.initialized = true
  }

  initialized = false

  dispose = () => {
    this.currencies = []
  }

  private readonly addNewCurrencyPosition = (holdingsCurrencyPosition: CurrencyPosition) => {
    const currencyPosition = new Currency()

    // Add and transform data from holdings
    CurrencyStore.idCounter += 1
    currencyPosition.key = holdingsCurrencyPosition.currency ?? `,currency-${CurrencyStore.idCounter}`

    currencyPosition.currencyName = holdingsCurrencyPosition.currency
    currencyPosition.fxRate = holdingsCurrencyPosition.fxRate
    currencyPosition.marketValue = holdingsCurrencyPosition.marketValue
    currencyPosition.quantity = holdingsCurrencyPosition.quantity

    this.currencies.push(currencyPosition)
  }
}

/**
 * ## ⚠️IMPORTANT⚠️
 * ### Requires wrapping component in MobX `observer(...)`
 */
export function useCurrencyPositions(currencyPositions: CurrencyPosition[]) {
  const currencyStore = useLocalObservable(() => new CurrencyStore())

  useEffect(() => {
    currencyStore.init(currencyPositions)
    return () => currencyStore.dispose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencyPositions])

  return {
    currencies: currencyStore.currencies,
  }
}
