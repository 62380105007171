import { useInfrontSDK } from '@common/hooks/infront/sdk/useInfrontSdk'
import { getInfrontNewsStory } from '@common/utils/infront/getInfrontNewsStory'

import { useEffect, useState } from 'react'

import { getFeedMetaData } from './getFeedMetaData'

export function useNewsStory(newsHeadlineId, feed = 18045) {
  const { infrontSDK } = useInfrontSDK()
  const [newsStory, setNewsStory] = useState<InfrontSDK.NewsStory>(undefined)
  const [source, setSource] = useState<string>('')

  useEffect(() => {
    async function load() {
      if (infrontSDK) {
        const story = await getInfrontNewsStory(infrontSDK, {
          feed,
          id: newsHeadlineId,
        })
        //  Get feed metadata
        const feedMetadata = await getFeedMetaData(infrontSDK, [feed])
        if (feedMetadata.length > 0) {
          setSource(feedMetadata[0].description)
        }
        setNewsStory(story)
      }
    }

    load()
  }, [feed, infrontSDK, newsHeadlineId])

  return { newsStory, source }
}
