import { ObservableInfrontInstrument } from '../../hooks/infront/sdk/observableInfrontInstrument'

export function getFormattedTimestamp(
  observableInfrontInstrument: ObservableInfrontInstrument,
  isFund: boolean
): string {
  const lastValidDate = observableInfrontInstrument?.getFieldValue(InfrontSDK.SymbolField.LastValidDate)
  const preLastTradeDate = observableInfrontInstrument?.getFieldValue(InfrontSDK.SymbolField.PreLastTradeDate)
  const tradeTime = observableInfrontInstrument?.getFieldValue(InfrontSDK.SymbolField.TradeTime)

  // Use exact timestamp, if missing fallback to last traded date
  let timestring
  if (isFund) {
    timestring = InfrontUtil.formatDate(lastValidDate)
  } else {
    timestring = tradeTime
      ? InfrontUtil.formatStandardTimestamp(tradeTime)
      : preLastTradeDate
      ? InfrontUtil.formatDate(preLastTradeDate)
      : null
  }

  return timestring
}
