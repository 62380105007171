import React, { ReactNode } from 'react'

import { AnimatePresence, motion } from 'framer-motion'

type FadeInProps = {
  style?: React.CSSProperties
  className?: string
  children?: ReactNode
  delay?: number
  duration?: number
  show?: boolean
}

export const FadeIn = ({ style, children, className, delay, duration, show = true }: FadeInProps) => {
  return (
    <AnimatePresence>
      {show && (
        <motion.div
          style={style}
          className={className}
          transition={{ delay, duration }}
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  )
}
