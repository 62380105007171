import { action, makeObservable, observable, runInAction } from 'mobx'

import { logInfrontError } from '../../../utils/infrontAppInsights'
import { getInfrontContentOptions } from './getInfrontContentOptions'

// Fields in this list will never be observed (streamed)
const staticInfrontFields: InfrontSDK.SymbolField[] = [
  InfrontSDK.SymbolField.Ticker,
  InfrontSDK.SymbolField.Feed,
  InfrontSDK.SymbolField.Market, // This field doesn't always work for some reason, but MIC does
  InfrontSDK.SymbolField.MIC,
  InfrontSDK.SymbolField.ISIN,
  // This can actually be null first and come in later... :p InfrontSDK.SymbolField.FullName,
  InfrontSDK.SymbolField.Currency,
]

export type ObservableInfrontInstrumentOptions = {
  onDataLoaded?: (instrument: ObservableInfrontInstrument) => void
  onError?: (instrument: ObservableInfrontInstrument, error: InfrontSDK.ErrorBase) => void
  onFieldChanged?: (instrument: ObservableInfrontInstrument, field: InfrontSDK.SymbolField) => void
  onAllFieldsLoaded?: (instrument: ObservableInfrontInstrument) => void
  observe?: boolean
}

function getSymbolIdFromSymbolData(
  symbolData: InfrontSDK.SymbolData,
  symbolIds: InfrontSDK.SymbolId[]
): InfrontSDK.SymbolId {
  const feed = symbolData.get(InfrontSDK.SymbolField.Feed)
  const ticker = symbolData.get(InfrontSDK.SymbolField.Ticker)

  const matching = symbolIds.find((symbolId) => symbolId.feed === feed && symbolId.ticker === ticker)

  if (matching) {
    return matching
  } else {
    throw new Error(`No matching symbol id found for symbol data with feed: ${feed} and ticker: ${ticker}`)
  }
}

export class ObservableInfrontInstrument {
  private static idCounter = 0
  readonly key: string
  instrumentId: InfrontSDK.SymbolId = undefined
  fields: Map<InfrontSDK.SymbolField, unknown> = new Map<InfrontSDK.SymbolField, unknown>()
  private readonly subscriptions: InfrontSDK.Unbind[] = []
  private options: ObservableInfrontInstrumentOptions = undefined
  private usedFields: InfrontSDK.SymbolField[] = []
  dataLoaded = false
  initialized = false
  streamingFields: InfrontSDK.SymbolField[] = []
  error: InfrontSDK.ErrorBase<unknown> = undefined

  private sdkUnsubscribe: InfrontSDK.Unsubscribe = undefined

  constructor() {
    makeObservable<ObservableInfrontInstrument, 'initWithSymbolData'>(this, {
      initWithSymbolData: action,
      fields: observable,
      dataLoaded: observable,
      initialized: observable,
      error: observable,
    })

    ObservableInfrontInstrument.idCounter += 1
    this.key = `infront-${ObservableInfrontInstrument.idCounter}`

    // this.debouncedSetField = debounce(this.debouncedSetField, 1000)
  }

  static initFromList(
    sdk: InfrontSDK.SDK,
    instrumentIds: InfrontSDK.SymbolId[],
    usedFields: InfrontSDK.SymbolField[],
    options: ObservableInfrontInstrumentOptions = { observe: true }
  ): { instruments: ObservableInfrontInstrument[]; sdkUnsubscribe: InfrontSDK.Unsubscribe } {
    const observableInfrontInstruments: ObservableInfrontInstrument[] = []

    for (const id of instrumentIds) {
      const observableInstrument = new ObservableInfrontInstrument()
      observableInstrument.init(sdk, id, usedFields, options, true)

      observableInfrontInstruments.push(observableInstrument)
    }

    const getObservableInfrontInstrumentFromSymbolData = (symbolData: InfrontSDK.SymbolData) => {
      const matchingSymbolId = getSymbolIdFromSymbolData(symbolData, instrumentIds)
      const matching = observableInfrontInstruments.find((o) => o.instrumentId === matchingSymbolId)
      return matching
    }

    const sdkUnsubscribe = sdk.get(
      InfrontSDK.symbolData({
        content: getInfrontContentOptions(usedFields),
        id: instrumentIds,
        subscribe: options?.observe ?? true,
        onData: (data: Infront.ObservableArray<InfrontSDK.SymbolData>) => {
          data.observe({
            reInit: (cachedItems: InfrontSDK.SymbolData[]) => {
              for (const cachedItem of cachedItems) {
                const matchingObservableInstrument = getObservableInfrontInstrumentFromSymbolData(cachedItem)

                matchingObservableInstrument.initWithSymbolData(cachedItem)
              }
            },
            itemAdded: (symbolData: InfrontSDK.SymbolData) => {
              const matchingObservableInstrument = getObservableInfrontInstrumentFromSymbolData(symbolData)

              if (!matchingObservableInstrument) {
                console.error(
                  "getObservableInfrontInstrumentFromSymbolData() didn't return anything, symbolData - ticker:",
                  symbolData.get(InfrontSDK.SymbolField.Ticker),
                  'feed:',
                  symbolData.get(InfrontSDK.SymbolField.Feed),
                  'fullname:',
                  symbolData.get(InfrontSDK.SymbolField.FullName)
                )
              } else {
                matchingObservableInstrument.initWithSymbolData(symbolData)
              }
            },
            itemMoved: (_, __) => {
              console.log('itemMoved()', _)
              console.log('itemMoved()', __)
            },
            itemRemoved: (_, __) => {
              console.log('itemRemoved()', _)
              console.log('itemRemoved()', __)
            },
            itemChanged: (_, __) => {
              console.log('itemChanged()', _)
              console.log('itemChanged()', __)
            },
          })
        },
        onError: (error: InfrontSDK.ErrorBase<unknown>) => {
          logInfrontError(error)
          options?.onError?.(undefined, error)
        },
      })
    )

    return { instruments: observableInfrontInstruments, sdkUnsubscribe: sdkUnsubscribe }
  }

  init = (
    sdk: InfrontSDK.SDK,
    instrumentId: InfrontSDK.SymbolId,
    usedFields: InfrontSDK.SymbolField[],
    options: ObservableInfrontInstrumentOptions = { observe: true },
    handleSdkManually = false
  ) => {
    this.dispose()

    this.options = options
    this.usedFields = usedFields
    // We add this so we can map it to existing instruments etc
    this.streamingFields = this.usedFields.filter((field) => !staticInfrontFields.includes(field))

    this.instrumentId = instrumentId // this.getSymbolField(symbolData, InfrontSDK.SymbolField.Feed)

    // When initializing from a list we wan't to handle this outside the observable instrument
    // for example when using initFromList
    if (!handleSdkManually) {
      this.sdkUnsubscribe = sdk.get(
        InfrontSDK.symbolData({
          content: getInfrontContentOptions(usedFields),
          id: instrumentId,
          subscribe: this.options?.observe ?? true,
          onData: (data: InfrontSDK.SymbolData) => {
            this.initWithSymbolData(data)
          },
          onError: (error) => {
            logInfrontError(error)
            this.options?.onError?.(this, error)
            this.error = error
          },
        })
      )
    }

    this.initialized = true
  }

  // Internal only
  private readonly initWithSymbolData = (symbolData: InfrontSDK.SymbolData) => {
    if (this.dataLoaded) {
      console.error('initWithSymbolData run again but data was already loaded for this instance')
      return
    }

    // TODO: Make some fields get by default (not observable)
    for (const field of this.usedFields) {
      // Get initial value always
      this.fields.set(field, this.getSymbolField(symbolData, field))
    }

    // Do not move this line!
    this.dataLoaded = true
    this.options?.onDataLoaded?.(this)

    // Now we set up observable for some fields that require it
    for (const field of this.streamingFields) {
      // observable fields unless set to static
      this.observeSymbolField(symbolData, field, (val: Date | string) => {
        // This is a workaround to fix the fact that Infront sometimes seems to mutate their date objects directly, this in
        // turn makes mobx not understand that a new value has been provided
        if (val instanceof Date && typeof val.getMonth === 'function') {
          runInAction(() => {
            this.fields.set(field, new Date(val))
          })
        } else {
          runInAction(() => {
            this.fields.set(field, val)
          })
        }

        // valueLoaded[field] = true
        if (this.options?.onAllFieldsLoaded) {
          if (this.streamingFields.every((streamingField) => this.fields.get(streamingField) !== undefined)) {
            this.options.onAllFieldsLoaded(this)
            this.options.onAllFieldsLoaded = undefined // Only call this once for each symbol
          }
        }

        // In some more advanced scenarios we can use callbacks to get a notification when a field was changed
        if (this.options?.onFieldChanged) this.options?.onFieldChanged(this, field)
      })
    }
  }

  hasField = (field: InfrontSDK.SymbolField) => {
    return this.fields.has(field)
  }

  getFieldValue = <TField extends InfrontSDK.SymbolField>(field: TField) => {
    // Can't use this warning anymore, there are cases where you want to reinitialize the hook with new "used fields"
    // and because this is a use effect it takes one frame for the changes to take effect making this code trigger even though nothing is wrong

    // if (!this.usedFields.includes(field))
    //   throw new Error(
    //     'You have not added the field ' +
    //       field +
    //       ' to your used fields list which is: ' +
    //       JSON.stringify(this.usedFields)
    //   )

    return this.fields.get(field) as InfrontSDK.SymbolFieldTypeBase[TField]
  }

  private getSymbolField(symbol: InfrontSDK.SymbolData, field: InfrontSDK.SymbolField) {
    return symbol.get(field)
  }

  private observeSymbolField(
    symbol: InfrontSDK.SymbolData,
    field: InfrontSDK.SymbolField,
    callback: (val: unknown) => void
  ) {
    const unsubscribe = symbol.observe(field, (newVal) => {
      callback(newVal)
    })

    // There are cases when we don't get an unsubscribe method back, probably because this
    // is a field we can't access etc, we must make sure to check if
    if (unsubscribe) {
      this.subscriptions.push(unsubscribe)
    }
  }

  dispose = () => {
    // Clean up stuff since this instance can be re-used
    this.options = undefined
    this.instrumentId = undefined
    this.dataLoaded = false
    this.initialized = false
    this.usedFields = []
    this.fields = new Map<InfrontSDK.SymbolField, unknown>()
    this.instrumentId = undefined

    // When creating observable instruments using initFromList this will not be set and needs to be
    // handled on the outside
    if (this.sdkUnsubscribe) this.sdkUnsubscribe()

    this.subscriptions.forEach((unsubscribe) => unsubscribe())
  }
}
