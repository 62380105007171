import { ResearchNews } from '@common/api/response'
import { CountryCodeFlag } from '@common/components/CountryCodeToFlag'
import { createValidInstrumentIdContainer } from '@common/instrumentIdContainer'

import { useTranslation } from 'react-i18next'

import { checkToday } from '@carnegie/digital-channels-frontend'
import { FlexRow, ListItem, ListItemRow, Text } from '@carnegie/duplo'

import { useOpenInstrumentDrawer } from '../../../drawers/instrumentDrawer/useOpenInstrumentDrawer'
import { RecommendationNames } from '../shared/researchNewsItem'
import { useResearchAndComments } from '../shared/useResearchAndComments'
import { RecommendationSquircle } from './RecommendationSquircle'
import { ResearchNewsMoreMenu } from './ResearchNewsMoreMenu'

type RatingUpdatesListItemProps = {
  researchNews: ResearchNews
}

export const RatingUpdatesListItem = ({ researchNews }: RatingUpdatesListItemProps) => {
  const { t } = useTranslation()

  const recommendation = (researchNews.recommendation?.name as RecommendationNames) ?? ''
  const previousRecommendation = researchNews.previousRecommendation?.name ?? ''

  const { openInstrumentDrawer } = useOpenInstrumentDrawer()
  const { translateRecommendation } = useResearchAndComments()

  const instrumentIdContainer = createValidInstrumentIdContainer({
    instrumentIdCarnegie: researchNews?.relatedInstrument?.carnegieInstrumentId,
    infrontInstrument: researchNews?.relatedInstrument?.infrontInstrument,
  })

  return (
    <ListItem
      divider
      noDividerOffset
      support={recommendation && <RecommendationSquircle size="large" type={recommendation} />}
      onClick={() => {
        if (researchNews?.relatedInstrument) {
          openInstrumentDrawer(instrumentIdContainer)
        }
      }}
      action={
        researchNews?.relatedInstrument ? <ResearchNewsMoreMenu instrumentIdContainer={instrumentIdContainer} /> : null
      }
    >
      <ListItemRow
        css={{ minHeight: 20 }}
        title={
          <Text variant="overline" color="text-low-emphasis" truncate>
            {checkToday(new Date(researchNews.created), {
              short: true,
              showTodayAsTime: true,
            })}
          </Text>
        }
      />
      <ListItemRow
        css={{ minHeight: 20 }}
        title={
          <FlexRow minWidth={0} alignItems="center" space={4}>
            <CountryCodeFlag size={16} countryCode={researchNews?.marketIso.toUpperCase()} />
            {}
            <Text truncate variant="subtitle1">
              {researchNews.company?.name ?? '???'}
            </Text>
          </FlexRow>
        }
      />
      <ListItemRow
        css={{ minHeight: 20 }}
        title={
          <Text truncate color="text-default" variant="support2">
            {`${t('Ändrad från')} ${translateRecommendation(previousRecommendation)} ${t(
              'till'
            )} ${translateRecommendation(recommendation)}`}
          </Text>
        }
      />
    </ListItem>
  )
}
