import { usePreviousSearches } from '@common/hooks/usePreviousSearches'
import { useLocalStorage } from '@common/stores/store'

import { useTranslation } from 'react-i18next'
import { useLocation, useMatch, useNavigate } from 'react-router-dom'

import { FlexRow, Icon, IconSearch, Input } from '@carnegie/duplo'

export const SearchBar = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const localStorage = useLocalStorage()
  const match = useMatch('/search/:query/*')
  const query = match?.params?.query?.replace(/-/g, ' ') || ''
  const isOnSearchPage = !!useMatch('/search/*')
  const { addCurrentSearchToPreviousSearches } = usePreviousSearches()

  const clearSearch = () => {
    navigate('/search', { replace: true })
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    if (newValue) {
      navigate(`/search/${newValue.replace(/ /g, '-')}`, { replace: true })
    } else {
      navigate('/search', { replace: true })
    }
  }

  const handleInputBlur = () => {
    addCurrentSearchToPreviousSearches(query)
  }

  return (
    <FlexRow
      alignItems="center"
      css={{
        maxWidth: 672,
      }}
      onClick={() => {
        if (!isOnSearchPage) {
          localStorage.setItem('searchHistoryBackLink', location.pathname, 'user')
          navigate('/search')
        }
      }}
      width="100%"
    >
      <Input
        type="search"
        id="search-field"
        size="small"
        data-testid="search-bar"
        autoComplete="off"
        value={query}
        width="100%"
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        placeholder={t('Sök värdepapper')}
        clearButton={!!query}
        onClearClick={clearSearch}
        startIcon={<Icon color={'text-low-emphasis'} icon={IconSearch} size={20} />}
      />
    </FlexRow>
  )
}
