import { TFunction } from 'i18next'

import { isNullish } from './isNullish'

export const infrontPredefinedInstruments = {
  indices: {
    // Note: The order here matters the first 5 will be shown until you press "show all"
    OMXS30: { feed: 'SSE', symbol: 'OMXS30', feedNumber: 17921, countryOverride: '' },
    OMXSPI: { feed: 'SSE', symbol: 'OMXSPI', feedNumber: 17921, countryOverride: '' },
    OMXC20: { feed: '', symbol: 'OMXC20', feedNumber: 17665, countryOverride: '' },
    DJI: { feed: 'WDJ', symbol: 'DJI', feedNumber: 20, countryOverride: '' },
    DJUS: { feed: '', symbol: 'DJUS', feedNumber: 20, countryOverride: '' },
    // These six are shown when clicking "show all"
    COMP: { feed: 'NSx', symbol: 'COMP', feedNumber: 2087, countryOverride: '' },
    DE30: { feed: '', symbol: 'DE30', feedNumber: 20, countryOverride: 'DE' },
    UK50: { feed: '', symbol: 'UK50', feedNumber: 20, countryOverride: '' },
    HSI: { feed: 'HSIX', symbol: 'HSI', feedNumber: 2198, countryOverride: 'HK' },
    N225: { feed: 'NIK', symbol: 'N225', feedNumber: 6150, countryOverride: 'JP' },
    CSXSE: { feed: '', symbol: 'CSXSE', feedNumber: 2104, countryOverride: '' },
    // These will not be shown except in map etc
    OMXN40: { feed: '', symbol: 'OMXN40', feedNumber: 18009, countryOverride: '' },
    E1DOW: { feed: '', symbol: 'E1DOW', feedNumber: 20, countryOverride: '' },
    W1DOW: { feed: '', symbol: 'W1DOW', feedNumber: 20, countryOverride: '' },
    PX1: { feed: '', symbol: 'PX1', feedNumber: 2089, countryOverride: '' },
    RTSI: { feed: '', symbol: 'RTSI', feedNumber: 2162, countryOverride: '' },
    OBX: { feed: '', symbol: 'OBX', feedNumber: 18177, countryOverride: '' },
    OMXH25: { feed: '', symbol: 'OMXH25', feedNumber: 100, countryOverride: '' },
    OAAX: { feed: '', symbol: 'OAAX', feedNumber: 18177, countryOverride: '' },
    OSEBX: { feed: '', symbol: 'OSEBX', feedNumber: 18177, countryOverride: '' },
    OMXNORDICSEKPI: { feed: '', symbol: 'OMXNORDICSEKPI', feedNumber: 18009, countryOverride: '' },
    OMXHPI: { feed: '', symbol: 'OMXHPI', feedNumber: 100, countryOverride: '' },
    OMXCPI: { feed: '', symbol: 'OMXCPI', feedNumber: 17665, countryOverride: '' },
    SP500: { feed: 'SPx', symbol: 'SP500', feedNumber: 2088, countryOverride: '' },
    FRDOW: { feed: '', symbol: 'FRDOW', feedNumber: 20, countryOverride: '' },
  },

  commodity: {
    BRT: { feed: 'GTEN', symbol: 'BRT', feedNumber: 2229 },
    WTI: { feed: 'GTEN', symbol: 'WTI', feedNumber: 2229 },
    GLDSPOT: { feed: 'GTPM', symbol: 'GLDSPOT', feedNumber: 2159 },
    SLVSPOT: { feed: 'GTPM', symbol: 'SLVSPOT', feedNumber: 2159 },
  },

  interest: {
    BMSE5Y: { feed: 'GTFI', symbol: 'BMSE-5Y', feedNumber: 2158 },
    BMSE10Y: { feed: 'GTFI', symbol: 'BMSE-10Y', feedNumber: 2158 },
  },

  currency: {
    USDSEK: { feed: '', symbol: 'USDSEK', feedNumber: 12 },
    EURSEK: { feed: '', symbol: 'EURSEK', feedNumber: 12 },
    EURUSD: { feed: '', symbol: 'EURUSD', feedNumber: 12 },
    GBPSEK: { feed: '', symbol: 'GBPSEK', feedNumber: 12 },
    NOKSEK: { feed: '', symbol: 'NOKSEK', feedNumber: 12 },
    CHFSEK: { feed: '', symbol: 'CHFSEK', feedNumber: 12 },
    USDJPY: { feed: '', symbol: 'USDJPY', feedNumber: 12 },
    DKKSEK: { feed: '', symbol: 'DKKSEK', feedNumber: 12 },
    JPYSEK: { feed: '', symbol: 'JPYSEK', feedNumber: 12 },
    EURGBP: { feed: '', symbol: 'EURGBP', feedNumber: 12 },
  },
}

export type InfrontPredefinedInstrumentType = keyof typeof infrontPredefinedInstruments

export function isPredefinedInfrontInstrument(ticker: string) {
  const infrontPredefinedInstrumentType = getInfrontPredefinedInstrumentType(ticker)
  const isPredefinedInfrontInstrument = infrontPredefinedInstrumentType !== undefined
  return isPredefinedInfrontInstrument
}

export function getInfrontPredefinedInstrumentType(ticker: string): InfrontPredefinedInstrumentType | undefined {
  if (isNullish(ticker)) {
    return undefined
  }

  for (const instrumentType in infrontPredefinedInstruments) {
    const list = infrontPredefinedInstruments[instrumentType]
    const symbol = ticker.replace(/ /g, '').replace(/-/g, '')

    const matchingInstrument = list[symbol]

    if (matchingInstrument) {
      return instrumentType as keyof typeof infrontPredefinedInstruments
    }
  }

  return undefined
}

// These are typically instruments we don't have in our own backend service so it's more or less a "hardcoded database" of instruments with some
// infront specific data attached
export function getInfrontPredefinedInstrument(ticker: string, t?: TFunction): Infront.Instrument | undefined {
  /* NOTICE: Race condition if importing useTranslation() here, therefore t as param */

  const symbol = ticker.replace(/ /g, '').replace(/-/g, '')
  const flattenIndexLookupTable = {}

  for (const [, obj] of Object.entries(infrontPredefinedInstruments)) {
    Object.assign(flattenIndexLookupTable, obj)
  }

  const instrument = flattenIndexLookupTable[symbol]

  if (!instrument) {
    console.error('No Infront instrument index found for symbol: ', symbol)
    return undefined
  }

  const infrontInstrument = new Infront.Instrument(instrument.feedNumber, instrument.symbol)

  const tKey = `INFRONT_INDEX_${symbol}` //Use carnegie translation
  const translation = t && t(tKey)
  infrontInstrument.full_name = translation === tKey ? undefined : translation

  return infrontInstrument
}
