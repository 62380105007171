import { CarnegieInstrument } from '@common/api/response'
import { useCarnegieInstrument } from '@common/hooks/useCarnegieInstrument'
import { InstrumentIdContainer } from '@common/instrumentIdContainer'
import {
  InfrontPredefinedInstrumentType,
  getInfrontPredefinedInstrumentType,
} from '@common/utils/infrontPredefinedInstruments'

import i18n from '../../i18n'

export function useCommonInstrumentType(instrumentIdContainer: InstrumentIdContainer): CommonInstrumentType {
  // If it is a predefined instrument it means we do not have data about it in our instrument service
  const infrontPredefinedInstrumentType = instrumentIdContainer?.infrontInstrument
    ? getInfrontPredefinedInstrumentType(instrumentIdContainer.infrontInstrument.ticker)
    : undefined

  const isPredefinedInfrontInstrument = infrontPredefinedInstrumentType !== undefined

  const { carnegieInstrument, error: carnegieInstrumentError } = useCarnegieInstrument(
    !isPredefinedInfrontInstrument ? instrumentIdContainer : undefined,
    i18n.language
  )

  let instrumentType: CommonInstrumentType = undefined

  if (!carnegieInstrument && carnegieInstrumentError && !infrontPredefinedInstrumentType) {
    // If we had an error getting the carnegie instrument and no predefined instrument type
    // we fall back to "unknown", currently there is no good way to map the infront instrument symbol type to our instrument
    // types according to Lotta
    instrumentType = 'unknown'
  } else {
    // In this case we have a carnegie instrument or predefined instrument type so pick one
    instrumentType = getCommonInstrumentType(carnegieInstrument, infrontPredefinedInstrumentType)
  }

  return instrumentType
}

function getCommonInstrumentType(
  carnegieInstrument: CarnegieInstrument,
  infrontInstrumentType: InfrontPredefinedInstrumentType
): CommonInstrumentType {
  if (carnegieInstrument) {
    switch (carnegieInstrument.carnegieInstrumentGroup) {
      case 'Equity':
        return 'stocks'
      case 'MutualFunds':
        return 'funds'
      case 'OptionsForwardsFutures':
        return 'derivatives'
      case 'Etf':
        return 'etf'
      default:
        return 'unknown'
    }
  } else if (infrontInstrumentType) {
    switch (infrontInstrumentType) {
      case 'indices':
        return 'index'
      case 'commodity':
        return 'commodity'
      case 'currency':
        return 'currency'
      case 'interest':
        return 'interest'
      default:
        return 'unknown'
    }
  }

  // This case is typical while data is loading
  return undefined
}

export type CommonInstrumentType =
  | 'stocks'
  | 'funds'
  | 'derivatives'
  | 'etf'

  // These are all shown as "index" right now in the UI
  | 'index'
  | 'currency'
  | 'interest'
  | 'commodity'

  // Fallback / default
  | 'unknown'
  | undefined // Loading
