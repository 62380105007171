import { InstrumentHoldingsResponse } from '@common/api/response'
import { ReservedQuantityRows } from '@common/components/ReservedQuantityRows'
import { ShowAtBreakpoint } from '@common/components/ShowAtBreakpoint'
import { SkeletonListItem } from '@common/components/SkeletonLoader'
import { useInstrumentHoldings } from '@common/hooks/useHoldings'
import { InstrumentIdContainer } from '@common/instrumentIdContainer'
import { ValueColor } from '@common/utils/valueColor'

import { useTranslation } from 'react-i18next'

import { formatAsTime, formatNumber } from '@carnegie/digital-channels-frontend'
import {
  Box,
  Breakpoint,
  DividerLine,
  FlexRow,
  Icon,
  IconLock,
  ListItemRow,
  ListItemWithAccordion,
  Paragraph,
  Segment,
  Text,
  Tooltip,
} from '@carnegie/duplo'

import { CommonInstrumentType } from './useCommonInstrumentType'

const SKELETON_ROWS = 3

const HoldingsRow = ({ support2, subtitle1, label = 'SEK' }) => {
  return (
    <FlexRow height={40} alignItems="center" justifyContent="space-between">
      <Text variant="support2">{support2}</Text>
      <Box css={{ whiteSpace: 'nowrap' }}>
        <Text variant="subtitle2">
          {subtitle1}
          {label && (
            <Text variant="label2" color="bunker-main" ml={2}>
              {label}
            </Text>
          )}
        </Text>
      </Box>
    </FlexRow>
  )
}
HoldingsRow.displayName = 'HoldingsRow'

const HoldingExpanded = ({
  holding,
  timeFetched,
}: {
  holding: InstrumentHoldingsResponse
  timeFetched: Date
  instrumentType: CommonInstrumentType
}) => {
  const { t } = useTranslation()
  const {
    instrumentPosition: {
      quantity,
      marketValue,
      acquisitionCost,
      averageAcquisitionPriceAccount,
      unrealizedResultRatio,
      unrealizedResult,
      collateralAmount,
    },
  } = holding

  const showReservedQuantities = holding && holding.instrumentPosition && holding.instrumentPosition.reservedQuantities

  return (
    <Box m={16}>
      <FlexRow alignItems="center" justifyContent="space-between">
        <Text variant="support2">{t('Antal')}</Text>
        <FlexRow alignItems="center" justifyContent="space-between">
          {showReservedQuantities && (
            <Tooltip
              title={
                <ReservedQuantityRows
                  includeIcon={false}
                  reservedQuantities={holding?.instrumentPosition?.reservedQuantities}
                />
              }
            >
              <Icon icon={IconLock} size={16} mr={4} alignSelf={'center'} />
            </Tooltip>
          )}
          <Text variant="subtitle2">
            {formatNumber(quantity, { decimals: { min: 0, max: 10 } })}
            <Text variant="label2" color="bunker-main" ml={2}>
              {t('st')}
            </Text>
          </Text>
        </FlexRow>
      </FlexRow>
      <ShowAtBreakpoint lessThanOrEqual={Breakpoint.Medium}>
        {showReservedQuantities && (
          <FlexRow alignItems="center">
            <Text truncate variant="support2" color="text-disabled">
              <ReservedQuantityRows
                includeIcon={false}
                reservedQuantities={holding?.instrumentPosition?.reservedQuantities}
              />
            </Text>
          </FlexRow>
        )}
      </ShowAtBreakpoint>
      <HoldingsRow
        support2={t('Marknadsvärde')}
        subtitle1={formatNumber(marketValue.account.amount, { decimals: 0 })}
      />
      <HoldingsRow
        support2={t('Anskaffningsvärde')}
        subtitle1={formatNumber(acquisitionCost.account.amount, { decimals: 0 })}
        label={acquisitionCost.account.currencyCode}
      />
      <HoldingsRow
        support2={t('Anskaffningskurs')}
        subtitle1={formatNumber(averageAcquisitionPriceAccount.amount, { decimals: 2 })}
        label={averageAcquisitionPriceAccount.currencyCode}
      />
      <FlexRow height={40} alignItems="center" justifyContent="space-between">
        <Text variant="support2">{t('Orealiserat')} %</Text>
        <Box css={{ whiteSpace: 'nowrap' }}>
          <ValueColor value={unrealizedResultRatio}>
            {(color) => (
              <>
                <Text variant="subtitle2" color={color}>
                  {formatNumber(unrealizedResultRatio, { ratio: true })}
                  <Text variant="label2" color={color} ml={2}>
                    %
                  </Text>
                </Text>
              </>
            )}
          </ValueColor>
        </Box>
      </FlexRow>
      <HoldingsRow
        support2={t('Orealiserat')}
        subtitle1={formatNumber(unrealizedResult.account.amount, { decimals: 0 })}
        label={unrealizedResult.account.currencyCode}
      />
      <HoldingsRow
        support2={t('Belåningsvärde')}
        subtitle1={formatNumber(collateralAmount.amount, { decimals: 0 })}
        label={collateralAmount.currencyCode}
      />
      <HoldingsRow
        support2={t('Senast uppdaterad')}
        subtitle1={formatAsTime(timeFetched, { withSeconds: false })}
        label=""
      />
    </Box>
  )
}
HoldingExpanded.displayName = 'HoldingExpanded'

type Props = {
  instrumentIdContainer: InstrumentIdContainer
  instrumentType: CommonInstrumentType
}

export const InstrumentMyHoldings = ({ instrumentIdContainer, instrumentType }: Props) => {
  const { t } = useTranslation()
  const { holdings, timeFetched, isValidating } = useInstrumentHoldings(instrumentIdContainer)

  const skeletons = Array(SKELETON_ROWS)
    .fill('')
    .map((_, index) => <SkeletonListItem key={index} divider />)

  return (
    <Box>
      <Segment noContentPadding title={t('Mitt innehav')} variant="contained" headingVariant="overline">
        {!holdings || isValidating ? (
          <>{skeletons}</>
        ) : (
          holdings.map((holding, index) => {
            return (
              <Box key={index}>
                <ListItemWithAccordion
                  expandableContent={
                    <HoldingExpanded holding={holding} timeFetched={timeFetched} instrumentType={instrumentType} />
                  }
                >
                  <ListItemRow
                    title={<Text variant="subtitle1">{holding.accountNumber}</Text>}
                    value={
                      <FlexRow alignItems="center">
                        {holding && holding.instrumentPosition && holding.instrumentPosition.reservedQuantities && (
                          <Tooltip
                            title={
                              <ReservedQuantityRows
                                includeIcon={false}
                                reservedQuantities={holding?.instrumentPosition?.reservedQuantities}
                              />
                            }
                          >
                            <Icon icon={IconLock} size={16} mr={4} alignSelf={'center'} />
                          </Tooltip>
                        )}
                        <Text variant="subtitle1">
                          {formatNumber(holding.instrumentPosition.quantity, { decimals: { min: 0, max: 10 } })}
                          <Text variant="label1" color="bunker-main" ml={2}>
                            {t('st')}
                          </Text>
                        </Text>
                      </FlexRow>
                    }
                  />
                  <ListItemRow
                    title={
                      <Paragraph
                        variant="body2"
                        color="bunker-200"
                        css={{ overflow: 'hidden', textOverflow: 'ellipsis', display: 'block', whiteSpace: 'nowrap' }}
                      >
                        {holding.accountOwner}
                      </Paragraph>
                    }
                    value={
                      <Box>
                        <Text variant="subtitle1">
                          {formatNumber(holding.instrumentPosition.marketValue.account.amount, {
                            decimals: 0,
                          })}
                          <Text variant="label1" color="bunker-main" ml={2}>
                            {holding.instrumentPosition.marketValue.account.currencyCode}
                          </Text>
                        </Text>
                      </Box>
                    }
                  />
                </ListItemWithAccordion>
                {index + 1 < holdings.length && <DividerLine />}
              </Box>
            )
          })
        )}
      </Segment>
    </Box>
  )
}
InstrumentMyHoldings.displayName = 'InstrumentMyHoldings'
