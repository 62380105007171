import { useTranslation } from 'react-i18next'

import { Box, Link, ListItemRow, ListItemWithAccordion, Paragraph, Segment, Text } from '@carnegie/duplo'

const WHITE_SPACE_BREAK_SPACES = 'break-spaces'

const TransferFAQInternalExternal = () => {
  const { t } = useTranslation()
  return (
    <Segment variant="contained" headingVariant="overline" title={t('Frågor och svar')} noContentPadding>
      <ListItemWithAccordion
        divider
        expandableContent={
          <Box p={16}>
            <Paragraph variant="body2">
              {t(
                'Generellt gäller att du kan göra uttag från dina egna konton, samt konton som du har fullmakt att göra uttag för. Det finns några undantag som exempelvis försäkringskonton och konton med tjänsten Portföljförvaltning. Om du önskar göra ett uttag från ett konto som inte är tillgängligt,'
              )}
              &nbsp;
              <Link variant="subtitle2" to="/profile/contact">
                {t('kontakta oss')}
              </Link>
              &nbsp;
              {t('för mer information.')}
            </Paragraph>
          </Box>
        }
      >
        <ListItemRow
          title={
            <Text textAlign="left" display="block" variant="subtitle2" css={{ whiteSpace: WHITE_SPACE_BREAK_SPACES }}>
              {t('Från vilka konton kan jag göra uttag?')}
            </Text>
          }
        />
      </ListItemWithAccordion>
      <ListItemWithAccordion
        divider
        expandableContent={
          <Box p={16}>
            <Paragraph variant="body2">
              {t(
                'Överföringar mellan konton hos Carnegie (interna överföringar) handläggs under helgfria bankdagar mellan kl. 9-17 och bokförs så snart som möjligt efter att de registrerats, normalt inom en timme.'
              )}
            </Paragraph>
          </Box>
        }
      >
        <ListItemRow
          title={
            <Text textAlign="left" display="block" variant="subtitle2" css={{ whiteSpace: WHITE_SPACE_BREAK_SPACES }}>
              {t('Hur lång tid tar en överföring mellan konton hos Carnegie?')}
            </Text>
          }
        />
      </ListItemWithAccordion>
      <ListItemWithAccordion
        divider
        expandableContent={
          <Box p={16}>
            <Paragraph variant="body2">
              {t(
                'I de flesta fall ska ett uttag till ett förvalt bankkonto som registreras före kl. 12.00 på en helgfri bankdag synas på mottagarkontot samma dag. Görs uttaget senare på dagen registreras insättningen dagen efter.'
              )}
            </Paragraph>
          </Box>
        }
      >
        <ListItemRow
          title={
            <Text textAlign="left" display="block" variant="subtitle2" css={{ whiteSpace: WHITE_SPACE_BREAK_SPACES }}>
              {t('När kan jag förvänta mig att ett uttag till annan bank syns på mottagarkontot?')}
            </Text>
          }
        />
      </ListItemWithAccordion>
      <ListItemWithAccordion
        divider
        expandableContent={
          <Box p={16}>
            <Paragraph variant="body2">
              {t(
                'Det saldo som visas som tillgängligt för uttag är kontots disponibla saldo i SEK. Om du sålt värdepapper måste affären först gå till likvid innan pengarna är tillgängliga för uttag. I de fall det finns pågående köporder för kontot reserveras ordervärdet och är därmed inte tillgängligt för uttag.'
              )}
            </Paragraph>
          </Box>
        }
      >
        <ListItemRow
          title={
            <Text textAlign="left" display="block" variant="subtitle2" css={{ whiteSpace: WHITE_SPACE_BREAK_SPACES }}>
              {t('Vad innebär tillgängligt saldo för uttag?')}
            </Text>
          }
        />
      </ListItemWithAccordion>
      <ListItemWithAccordion
        divider
        expandableContent={
          <Box p={16}>
            <Paragraph variant="body2">
              <Link variant="subtitle2" to="/profile/contact">
                {t('Kontakta oss')}
              </Link>
              &nbsp;
              {t('för att få hjälp med att göra överföringen.')}
            </Paragraph>
          </Box>
        }
      >
        <ListItemRow
          title={
            <Text textAlign="left" display="block" variant="subtitle2" css={{ whiteSpace: WHITE_SPACE_BREAK_SPACES }}>
              {t('Hur gör jag en överföring i utländsk valuta?')}
            </Text>
          }
        />
      </ListItemWithAccordion>
      <ListItemWithAccordion
        divider
        expandableContent={
          <Box p={16}>
            <Paragraph variant="body2">
              {t(
                'Insättningar och uttag av pengar på ISK påverkar kapitalunderlaget som ligger till grund för beräkningen av schablonskatt. Om du gör en överföring mellan egna ISK-konton som du är ägare till så påverkas dock inte kapitalunderlaget. För mer information om hur överföringar påverkar kapitalunderlaget,'
              )}
              &nbsp;
              <Link variant="subtitle2" to="/profile/contact">
                {t('kontakta oss.')}
              </Link>
            </Paragraph>
          </Box>
        }
      >
        <ListItemRow
          title={
            <Text textAlign="left" display="block" variant="subtitle2" css={{ whiteSpace: WHITE_SPACE_BREAK_SPACES }}>
              {t('Vad ska jag tänka på vid överföring till/från investeringssparkonto (ISK)?')}
            </Text>
          }
        />
      </ListItemWithAccordion>
      <ListItemWithAccordion
        divider
        expandableContent={
          <Box p={16}>
            <Paragraph variant="body2">
              {t(
                'Förvalt konto är ett konto som är registrerat hos oss för uttag. Det är endast möjligt att göra uttag till kontoägarens förvalda konton. Om du vill lägga till eller ta bort ett konto,'
              )}
              &nbsp;
              <Link variant="subtitle2" to="/profile/contact">
                {t('kontakta oss.')}
              </Link>
            </Paragraph>
          </Box>
        }
      >
        <ListItemRow
          title={
            <Text textAlign="left" display="block" variant="subtitle2" css={{ whiteSpace: WHITE_SPACE_BREAK_SPACES }}>
              {t('Vad är ett förvalt konto, och hur lägger jag till/tar bort ett konto?')}
            </Text>
          }
        />
      </ListItemWithAccordion>
      <ListItemWithAccordion
        divider
        expandableContent={
          <Box p={16}>
            <Paragraph variant="body2">
              {t(
                'För överföringar som du själv registrerar finns en beloppsgräns per överföring. För uttag till annan bank finns även en beloppsgräns för hur mycket som får föras över under en vecka. Om ditt belopp överstiger en gräns visas information om det.'
              )}
            </Paragraph>
          </Box>
        }
      >
        <ListItemRow
          title={
            <Text textAlign="left" display="block" variant="subtitle2" css={{ whiteSpace: WHITE_SPACE_BREAK_SPACES }}>
              {t('Finns det någon beloppsgräns för överföringar?')}
            </Text>
          }
        />
      </ListItemWithAccordion>
      <ListItemWithAccordion
        divider
        expandableContent={
          <Box p={16} spaceY={8}>
            <Paragraph variant="body2">
              {t(
                'När du registrerar en överföring handläggs den så snart som möjligt och du kan följa statusen för att se när den är genomförd.'
              )}
            </Paragraph>
            <Paragraph variant="body2">
              <Text variant="body2" bold>
                "{t('Registrerad')}"
              </Text>
              &nbsp;
              {t(
                'Överföringen är skapad men har ännu inte börjat handläggas. Om du skapat överföringen själv kan du i det här läget ta bort den.'
              )}
            </Paragraph>
            <Paragraph variant="body2">
              <Text variant="body2" bold>
                "{t('Pågående')}"
              </Text>
              &nbsp;
              {t('Visas under tiden en överföring är under bearbetning av oss, eller mottagande bank.')}
            </Paragraph>

            <Paragraph variant="body2">
              <Text variant="body2" bold>
                "{t('Genomförd')}"
              </Text>
              &nbsp;
              {t(
                'Överföringen är bokförd och du kan se transaktionen på ditt konto. För uttag till annan bank innebär det att överföringen är mottagen av den andra banken.'
              )}
            </Paragraph>

            <Paragraph variant="body2">
              <Text variant="body2" bold>
                "{t('Avvisad')}"
              </Text>
              &nbsp;
              {t(
                'Överföringen har inte kunnat genomföras vilket kan bero på flera orsaker. För mer information kontakta oss.'
              )}
            </Paragraph>
            <Paragraph variant="body2">
              <Text variant="body2" bold>
                "{t('Borttagen')}"
              </Text>
              &nbsp;
              {t('Överföringen har tagits bort.')}
            </Paragraph>
          </Box>
        }
      >
        <ListItemRow
          title={
            <Text textAlign="left" display="block" variant="subtitle2" css={{ whiteSpace: WHITE_SPACE_BREAK_SPACES }}>
              {t('Vad betyder statusen som visas för en registrerad överföring?')}
            </Text>
          }
        />
      </ListItemWithAccordion>
      <ListItemWithAccordion
        divider
        expandableContent={
          <Box p={16} spaceY={8}>
            <Paragraph variant="body2">
              {t(
                'Ja, du kan ta bort en överföring som du skapat själv när den har status "Registrerad". Klicka på papperskorgen vid överföringen i tabellen för Tidigare överföringar.'
              )}
            </Paragraph>
          </Box>
        }
      >
        <ListItemRow
          title={
            <Text textAlign="left" display="block" variant="subtitle2" css={{ whiteSpace: WHITE_SPACE_BREAK_SPACES }}>
              {t('Kan jag ta bort en överföring?')}
            </Text>
          }
        />
      </ListItemWithAccordion>
    </Segment>
  )
}

export default TransferFAQInternalExternal
