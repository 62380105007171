type InfrontContentOptions = {
  Basic?: boolean
  CompanyMetaData?: boolean
  CompanyFundamentals?: boolean
  HistoricalPerformance?: boolean
  FundDetails?: boolean
}

const requiresHistoricalPerformance: InfrontSDK.SymbolField[] = [
  InfrontSDK.SymbolField.LastTradedAt,
  InfrontSDK.SymbolField.PreLastTradeDate,
  InfrontSDK.SymbolField.PreChangePercent1W,
  InfrontSDK.SymbolField.PreChangePercent1M,
  InfrontSDK.SymbolField.PreChangePercent3M,
  InfrontSDK.SymbolField.PreChangePercent6M,
  InfrontSDK.SymbolField.PreChangePercent1Y,
  InfrontSDK.SymbolField.PreChangePercent2Y,
  InfrontSDK.SymbolField.PreChangePercent5Y,
]

const requiresCompanyMetadata: InfrontSDK.SymbolField[] = [
  InfrontSDK.SymbolField.Urls,
  InfrontSDK.SymbolField.CompanyWebsite,
  InfrontSDK.SymbolField.CompanyName,
  InfrontSDK.SymbolField.CompanyDescription,
  InfrontSDK.SymbolField.CompanyDescriptionEN,
  InfrontSDK.SymbolField.CompanyCEO,
  InfrontSDK.SymbolField.CompanyChairman,
  InfrontSDK.SymbolField.FundamentalCompanyDesc,
]

const requiresCompanyFundamentals: InfrontSDK.SymbolField[] = [InfrontSDK.SymbolField.FundamentalCompanyDesc]

const requiresFundDetails: InfrontSDK.SymbolField[] = [
  InfrontSDK.SymbolField.FundTopCountries,
  InfrontSDK.SymbolField.FundTopHoldings,
  InfrontSDK.SymbolField.FundTopSectors,
  InfrontSDK.SymbolField.FundManager,
]

export function getInfrontContentOptions(usedFields: InfrontSDK.SymbolField[]): InfrontContentOptions {
  // Check for forbidden fields
  if (usedFields.includes(InfrontSDK.SymbolField.LastTradeDate))
    throw new Error(
      'Do not use InfrontSDK.SymbolField.LastTradeDate use InfrontSDK.SymbolField.PreLastTradeDate instead because of inconsistent behavior in 2.9.11'
    )

  const useHistoricalPerformance = usedFields.some((field) => requiresHistoricalPerformance.includes(field))

  const useCompanyMetadata = usedFields.some((field) => requiresCompanyMetadata.includes(field))
  const useCompanyFundamentals = usedFields.some((field) => requiresCompanyFundamentals.includes(field))

  const useFundDetails = usedFields.some((field) => requiresFundDetails.includes(field))

  return {
    Basic: true,
    CompanyMetaData: useCompanyMetadata,
    CompanyFundamentals: useCompanyFundamentals,
    // Static: true,
    HistoricalPerformance: useHistoricalPerformance,
    FundDetails: useFundDetails,
  }
}

// {
//   Basic: true,
//   CompanyMetaData: true,
//   Static: true,
//   HistoricalPerformance: true,
//   FundDetails,
//   CompanyFundamentals
// }
