import { ReservedQuantity } from '@common/api/response'

import { useTranslation } from 'react-i18next'

import { formatNumber } from '@carnegie/digital-channels-frontend'
import { FlexRow, Icon, IconLock, Text } from '@carnegie/duplo'

type Props = {
  includeIcon: boolean
  reservedQuantities?: ReservedQuantity[]
}

export const ReservedQuantityRows = ({ includeIcon, reservedQuantities }: Props) => {
  const { t } = useTranslation()

  if (reservedQuantities && reservedQuantities?.length > 0) {
    return (
      <>
        {reservedQuantities.map((reservedQuantity) => (
          <FlexRow key={reservedQuantity.reason} width="full">
            {includeIcon && <Icon icon={IconLock} size={16} mr={4} alignSelf={'center'} />}
            <Text truncate>{t(reservedQuantity.reason)}</Text>
            <Text ml={4}>{`(${formatNumber(reservedQuantity.quantity, { decimals: { min: 0, max: 6 } })} ${t(
              'st'
            )})`}</Text>
          </FlexRow>
        ))}
      </>
    )
  }
  return null
}
