import { NoDataDash } from '@common/components/NoDataFallback'

import { ReactNode } from 'react'

import { DuploSize, FlexRow, SkeletonRect, Text } from '@carnegie/duplo'

type ListItemValueProps = {
  value: ReactNode
  unit?: string
  skeletonWidth?: DuploSize
  showEmptyStateForZeroValues?: boolean
  loading?: boolean
}

export const ListItemValue = ({
  value,
  unit,
  skeletonWidth,
  showEmptyStateForZeroValues,
  loading,
}: ListItemValueProps) => {
  // If the loading prop hasn't been set we treat value undefined as loading
  // but in some cases we specifically want to show a skeleton while loading and a "-" when value is undefined
  const showSkeleton = loading || (loading === undefined && value === undefined)

  return (
    <>
      {showSkeleton ? (
        <SkeletonRect height={20} width={skeletonWidth ?? 70} />
      ) : (
        <>
          {value === null || value === undefined || value === '-' || (showEmptyStateForZeroValues && value === 0) ? (
            <NoDataDash /> // Show "-"
          ) : (
            <FlexRow alignItems="baseline" space={2}>
              <Text variant="subtitle2">
                {value}
                {unit !== undefined && (
                  <Text variant="label2" ml={2} color="bunker-main">
                    {unit}
                  </Text>
                )}
              </Text>
            </FlexRow>
          )}
        </>
      )}
    </>
  )
}
ListItemValue.displayName = 'ListItemValue'
