import React, { ReactNode } from 'react'

import { PageDrawer } from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import { useDrawerStore } from './useDrawerStore'

type DrawerProps = {
  className?: string
  children?: ReactNode
  level?: number
  open: boolean
  type?: 'default' | 'menu'
  onClose?: () => void
}

export const Drawer = observer(({ children, level = 1, open, type = 'default', onClose }: DrawerProps) => {
  const drawerStore = useDrawerStore()

  // This is needed so that each drawer can now if they will show a back button or "X" button etc
  const levelsOpened = drawerStore.drawersOpened //useDrawerLevelsOpened()

  const onCloseAllDrawers = () => {
    // const rootLevelUrl = getDrawerUrlUpUntilLevel(0, location.pathname)
    // if (type === 'default') nav.push(rootLevelUrl)

    if (type === 'default') {
      drawerStore.closeAllDrawers()
    }

    if (onClose) onClose()
  }

  const onCloseSingleDrawer = () => {
    if (type === 'default') {
      drawerStore.closeDrawer(level)
    }

    if (onClose) onClose()
  }

  return (
    <PageDrawer
      disableSwipeToOpen={true}
      levelsOpened={levelsOpened}
      level={level}
      open={open}
      type={type}
      onClose={(closeType) => {
        // For swipe drawer event seems to be undefined
        if (closeType === 'overlay-backdrop') {
          onCloseAllDrawers()
        } else {
          onCloseSingleDrawer()
        }
      }}
    >
      <React.Suspense fallback={<></>}>{children}</React.Suspense>
    </PageDrawer>
  )
})

export default Drawer
