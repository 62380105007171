import { useDuploTheme } from '@carnegie/duplo'

import StarSvg from './star.svg'

type StarsProps = {
  filled?: boolean
}

export const Star = ({ filled = true }: StarsProps) => {
  const duploTheme = useDuploTheme()
  return filled ? (
    <StarSvg width={20} height={20} fill="rgb(242, 211, 37)" />
  ) : (
    <StarSvg width={20} height={20} fill={duploTheme.colors['bunker-60']} />
  )
}
