import { getImpersonationKeyValuesFromSearchParams } from '@common/utils/impersonation'

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { StartRequest } from '@carnegie/curity-auth/lib/types'

type LoginProps = {
  onLogin: (requestData?: StartRequest) => Promise<void>
}

const LoginPage = ({ onLogin }: LoginProps) => {
  const { i18n } = useTranslation()

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const extraParams = getImpersonationKeyValuesFromSearchParams(searchParams)

    extraParams.push({
      key: 'ui_locales',
      value: searchParams.get('ui_locales') || i18n.language,
    })

    onLogin({ extraParams })
  }, [onLogin, i18n.language])

  return null
}

export default LoginPage
