import { TrashCanButtonWithConfirm } from '@common/components/TrashCanButtonWithConfirm'
import { useAuth } from '@common/stores/store'

import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { InfrontOrder } from '@carnegie/digital-channels-frontend'

import { observer } from 'mobx-react-lite'

export const CurrentOrdersTrashCanButton = observer(
  ({
    order,
    size = 'small',
    deleteOrder,
  }: {
    order: InfrontOrder
    size?: 'small' | 'medium' | 'large'
    deleteOrder: (orderId: string) => void
  }) => {
    const auth = useAuth()
    const { t } = useTranslation()
    const [pendingCancelOrder, setPendingCancelOrder] = useState(false)

    const { orderId, isDeletable } = order

    const onClick = () => {
      if (auth.isImpersonated) {
        alert('Du saknar rättighet till åtgärden') //todo byt till snackbar (material ui/duplo)
      } else {
        if (orderId) {
          deleteOrder(orderId.toString())
          setPendingCancelOrder(true)
        }
      }
    }

    return (
      isDeletable && (
        <TrashCanButtonWithConfirm
          loading={pendingCancelOrder}
          size={size}
          onClick={onClick}
          iconTooltipText={t('Ta bort order')}
          confirmButtonText={t('Ta bort order')}
        />
      )
    )
  }
)
