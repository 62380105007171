import { AccountsResponse, InstrumentType } from '@common/api/response'
import { ShowAll } from '@common/components/ShowAll'
import { SkeletonLoader } from '@common/components/SkeletonLoader'

import { useTranslation } from 'react-i18next'

import { Box, Breakpoint, GridBox, Segment, Text, useBreakpoint } from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import { SimpleOrderRowMobile } from '../../pages/overview/order/SimpleOrder/SimpleOrderRow'
import { useSimpleOrder, useSimpleOrderCancel } from '../../pages/overview/order/useSimpleOrder'

type SimpleOrderListProps = {
  renderForApp: boolean
  selectedAccount: AccountsResponse
}

export const SimpleOrderList = observer(({ renderForApp, selectedAccount }: SimpleOrderListProps) => {
  const { t } = useTranslation()
  const breakpoint = useBreakpoint()
  const isMobile = breakpoint < Breakpoint.Small

  const { orders, error } = useSimpleOrder({
    accountId: selectedAccount?.id,
    fromDate: undefined,
    instrumentType: InstrumentType.Fund,
    toDate: undefined,
  })

  const { cancelOrder } = useSimpleOrderCancel()

  return (
    <GridBox order={isMobile ? 3 : 2}>
      <Segment
        title={t('Fondordrar för valt konto')}
        variant="contained"
        headingVariant="overline"
        noContentPadding
        action={
          renderForApp ? (
            <div
              onClick={() => {
                window.ReactNativeWebView?.postMessage('NAVIGATE_TO_ORDERLIST')
              }}
            >
              <Text variant="subtitle2" color="astral">
                {t('Visa alla')}
              </Text>
            </div>
          ) : (
            <ShowAll to={`/overview/orders/${selectedAccount?.id}`} />
          )
        }
      >
        {selectedAccount?.id === undefined ? (
          <Box p={16}>{t('Välj ett konto för att se aktuella ordrar.')}</Box>
        ) : (
          <SkeletonLoader
            variant="list"
            height={128}
            p={16}
            error={error}
            loading={!orders}
            noDataLoaded={orders && orders.length === 0}
            noDataComponent={<Box>{t('Det finns inga aktuella ordrar för valt konto.')}</Box>}
          >
            {() => (
              <>
                {orders?.map((simpleOrder, index) => {
                  return (
                    <SimpleOrderRowMobile
                      key={simpleOrder?.orderId ?? index}
                      simpleOrder={simpleOrder}
                      cancelOrder={cancelOrder}
                    />
                  )
                })}
              </>
            )}
          </SkeletonLoader>
        )}
      </Segment>
    </GridBox>
  )
})
