import { ShowAtBreakpoint } from '@common/components/ShowAtBreakpoint'
import { Body1Style } from '@common/components/Styles'
import { createInstrumentIdContainer } from '@common/instrumentIdContainer'
import { css } from '@emotion/react'

import React from 'react'

import { formatAsDateTime } from '@carnegie/digital-channels-frontend'
import {
  Box,
  Breakpoint,
  GridContainer,
  GridItem,
  Heading4,
  Heading5,
  SkeletonRect,
  Spacer,
  Text,
  useBreakpoint,
} from '@carnegie/duplo'

import parse, { Element, HTMLReactParserOptions, domToReact } from 'html-react-parser'
import { observer } from 'mobx-react-lite'

import { RelatedInstruments } from '../../pages/news/RelatedInstruments'
import { useNewsStory } from './useNewsStory'
import { useNewsDrawerProps } from './useOpenNewsDrawer'

export type NewsDrawerProps = {
  newsHeadlineId?: string
  newsHeadline?: InfrontSDK.NewsHeadline
}

const NewsDrawer = observer(() => {
  const { newsHeadlineId, newsHeadline } = useNewsDrawerProps()
  const { newsStory, source } = useNewsStory(newsHeadlineId)

  return (
    <Box data-testid="news-drawer">
      <ShowAtBreakpoint at={[Breakpoint.Xs, Breakpoint.Small]}>
        <Mobile newsStory={newsStory} source={source} relatedInstruments={newsHeadline?.symbols} />
      </ShowAtBreakpoint>
      <ShowAtBreakpoint greaterThanOrEqual={Breakpoint.Medium}>
        <Desktop newsStory={newsStory} source={source} relatedInstruments={newsHeadline?.symbols} />
      </ShowAtBreakpoint>
    </Box>
  )
})
NewsDrawer.displayName = 'NewsDrawer'

export default NewsDrawer

const Mobile = ({
  newsStory,
  source,
  relatedInstruments,
}: {
  newsStory: InfrontSDK.NewsStory
  source: string
  relatedInstruments?: InfrontSDK.SymbolId[]
}) => {
  const breakpoint = useBreakpoint()
  const isExtraSmallScreen = breakpoint < Breakpoint.Small
  const mobileHeading = isExtraSmallScreen ? (
    <Heading5 truncate={false}>{newsStory?.headline?.headline}</Heading5>
  ) : (
    <Heading4 truncate={false}>{newsStory?.headline?.headline}</Heading4>
  )

  return (
    <GridContainer>
      <GridItem xs={12} sm={8}>
        <Spacer height={8} />
        {newsStory ? mobileHeading : <SkeletonRect height={80} width={'100%'} />}
        <Date dateTime={newsStory?.headline?.dateTime} source={source} />
        <Content body={newsStory?.body} />
        <RelatedInstruments
          instruments={relatedInstruments?.map((instrument) => createInstrumentIdContainer(undefined, instrument))}
        />
      </GridItem>
    </GridContainer>
  )
}
Mobile.displayName = 'Mobile'

const Desktop = ({
  newsStory,
  source,
  relatedInstruments,
}: {
  newsStory: InfrontSDK.NewsStory
  source: string
  relatedInstruments?: InfrontSDK.SymbolId[]
}) => {
  return (
    <>
      <GridContainer style={{ justifyContent: 'flex-start' }}>
        <GridItem md={7} lg={6}>
          <Spacer height={8} />
          {newsStory ? (
            <Heading4 truncate={false}>{newsStory?.headline?.headline}</Heading4>
          ) : (
            <SkeletonRect height={80} width={'100%'} />
          )}
          <Date dateTime={newsStory?.headline?.dateTime} source={source} />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem md={7} lg={6}>
          <Content body={newsStory?.body} />
        </GridItem>
        <ShowAtBreakpoint greaterThanOrEqual={Breakpoint.Large}>
          <GridItem lg={2}>
            <div></div>
          </GridItem>
        </ShowAtBreakpoint>
        <GridItem md={5} lg={4}>
          <RelatedInstruments
            instruments={relatedInstruments?.map((instrument) => createInstrumentIdContainer(undefined, instrument))}
          />
          <Spacer height={32} />
        </GridItem>
      </GridContainer>
    </>
  )
}
Desktop.displayName = 'Desktop'

const Date = observer(({ dateTime, source }: { dateTime: Date; source: string }) => {
  return (
    <>
      <Spacer height={8} />
      {dateTime ? (
        <Text variant="overline">{`${source} - ${formatAsDateTime(dateTime)}`}</Text>
      ) : (
        <SkeletonRect height={24} width={'100%'} />
      )}
    </>
  )
})
Date.displayName = 'Date'

const Content = observer(({ body }: { body: string }) => {
  const breakpoint = useBreakpoint()

  const options: HTMLReactParserOptions = {
    replace: (domNode: Element) => {
      if (domNode.name === 'h1') return <></>
      if (domNode.name === 'text') return <div>{domToReact(domNode.children)}</div>

      if (domNode.name === 'a') {
        return (
          <a target="_blank" rel="noreferrer" href={domNode.attribs.href}>
            {domNode.children && (domNode.children[0] as Element).name === 'img' ? (
              <img
                style={{ maxWidth: breakpoint < Breakpoint.Small ? 321 : 480 + 'px' }}
                src={(domNode.children[0] as Element).attribs.src}
                alt="news"
                height={'auto'}
                width={100 + '%'}
              ></img>
            ) : (
              domToReact(domNode.children)
            )}
          </a>
        )
      }
    },
  }

  return (
    <>
      {body ? (
        <Body>
          <div id="market-news-wrapper">{parse(body, options)}</div>
        </Body>
      ) : (
        <>
          <Spacer height={24} />
          <SkeletonRect height={600} width={'100%'} />
        </>
      )}
      <Spacer height={24} />
    </>
  )
})
Content.displayName = 'Content'

const Body = (props: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      css={css`
        p {
          ${Body1Style};
          margin: ${24}px 0;
        }

        #instrument-news-wrapper {
          > hr:first-of-type {
            display: none;
          }
          > table:first-of-type {
            display: none;
          }
          .header1 {
            display: none;
          }
        }

        #market-news-wrapper {
          > hr:first-of-type {
            display: none;
          }
          > table:first-of-type {
            display: none;
          }
          > div > p:first-of-type {
            margin-top: 0%;
          }
          .header1 {
            display: none;
          }
        }

        #market-news-wrapper > h2 {
          display: none;
        }
      `}
      {...props}
    >
      {props.children}
    </div>
  )
}
Body.displayName = 'Body'
