import { AccountsResponse, CustomerReportModel } from '@common/api/response'
import { fireTrackEvent } from '@common/utils/analyticsEvent'

import { useTranslation } from 'react-i18next'

import { useFormatDate } from '@carnegie/digital-channels-frontend'
import {
  ButtonBox,
  FlexRow,
  Icon,
  IconDocumentLineChart,
  LinkExternal,
  ListItem,
  ListItemRow,
  Text,
} from '@carnegie/duplo'

type CustomerReportMobileRowProps = {
  report: CustomerReportModel
  selectedAccount: AccountsResponse
}

export const CustomerReportMobileRow = ({ report, selectedAccount }: CustomerReportMobileRowProps) => {
  const { t } = useTranslation()
  const { formatDate } = useFormatDate()

  const reportDescription = report?.description?.replaceAll('+', '_')

  return (
    <ListItem divider>
      <ListItemRow
        title={<Text variant="overline">{selectedAccount?.number}</Text>}
        value={
          <Text variant="overline">{report?.createDate && formatDate(report.createDate, 'HH:mm/yyyy-MM-dd')}</Text>
        }
      />
      <ListItemRow
        title={
          <ButtonBox
            onClick={() => {
              fireTrackEvent('Reports', 'file_download_report')
            }}
            minWidth={0}
          >
            <LinkExternal
              truncate
              variant="subtitle1"
              href={`/api/reports/customer-reports/download-file/${report?.serviceFileType}/${
                report?.documentId ?? ''
              }`}
              rel="noreferrer"
              target="_blank"
              download={reportDescription}
              minWidth={0}
            >
              <FlexRow alignItems="center">
                <Icon size={16} color="current" mr={4} icon={IconDocumentLineChart} />
                <Text truncate variant="subtitle1">
                  {reportDescription?.replaceAll('_', ' ')}
                </Text>
              </FlexRow>
            </LinkExternal>
          </ButtonBox>
        }
      />
      <ListItemRow title={<Text variant="support1">{t(report?.documentTypeName.toString())}</Text>} />
    </ListItem>
  )
}
