import { useTranslation } from 'react-i18next'

export function useResearchAndComments() {
  const { t } = useTranslation()

  //add shared stuff here

  const translateRecommendation = (type: string) => {
    switch (type.toLowerCase()) {
      case 'buy':
        return t('Köp')
      case 'sell':
        return t('Sälj')
      case 'hold':
        return t('Behåll')
      case 'not rated':
        return t('NR (Not rated)')
      case 'under bid':
        return t('UB (Under bid)')
      case 'under review':
        return t('UR (Under review)')
      default:
        return '-'
    }
  }
  return { translateRecommendation }
}
