import styled from '@emotion/styled'

import { useEffect, useRef, useState } from 'react'
import * as React from 'react'

import { Heading5, Heading6 } from '@carnegie/duplo'

import { motion, useAnimation } from 'framer-motion'

type Props = {
  title: string
  children?: React.ReactNode
}

const top = 56

export const StickySectionTitle = ({ title, children }: Props) => {
  const ref = useRef<HTMLDivElement>(null)
  const isStickyRef = useRef<boolean>(false)
  const [isSticky, setIsSticky] = useState(false)
  const fadeAnimation = useAnimation()

  useEffect(() => {
    const scrollListener = () => {
      const boundingRect = ref.current.getBoundingClientRect()
      if (boundingRect.top <= top) {
        if (!isStickyRef.current) {
          fadeAnimation.start({
            backgroundColor: 'rgba(253, 253, 253, 1)',
            boxShadow: `0px 4px 5px 0px rgba(0, 0, 0, 0.14),
                        0px 1px 10px 0px rgba(0, 0, 0, 0.12),
                        0px 2px 4px -1px rgba(0, 0, 0, 0.2)`,
            transition: { duration: 0.2, ease: 'easeInOut' },
          })
          isStickyRef.current = true
          setIsSticky(true)
        }
      } else {
        if (isStickyRef.current) {
          fadeAnimation.start({
            backgroundColor: 'rgba(253, 253, 253, 0)',
            boxShadow: `0px 4px 5px 0px rgba(0, 0, 0, 0),
            0px 1px 10px 0px rgba(0, 0, 0, 0),
            0px 2px 4px -1px rgba(0, 0, 0, 0)`,
            transition: { duration: 0.2, ease: 'easeInOut' },
          })
          isStickyRef.current = false
          setIsSticky(false)
        }
      }
    }
    const app = document.getElementById('app')
    app.addEventListener('scroll', scrollListener, false)
    return () => app.removeEventListener('scroll', scrollListener)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StickyWrapper
      ref={ref}
      initial={{
        backgroundColor: 'rgba(253, 253, 253, 0)',
        boxShadow: `0px 4px 5px 0px rgba(0, 0, 0, 0),
        0px 1px 10px 0px rgba(0, 0, 0, 0),
        0px 2px 4px -1px rgba(0, 0, 0, 0)`,
      }}
      animate={fadeAnimation}
      style={{ top }}
    >
      {isSticky ? <Heading6 style={{ flex: 1 }}>{title}</Heading6> : <Heading5 style={{ flex: 1 }}>{title}</Heading5>}
      {children}
    </StickyWrapper>
  )
}

// Need to override parent padding
const StickyWrapper = styled(motion.div)`
  position: sticky;
  padding-left: 8px;
  padding-right: 8px;
  margin-left: -8px;
  margin-right: -8px;
  z-index: 9;
  height: 40px;
  display: flex;
  align-items: center;
`
