import { useLocation } from 'react-router-dom'

type PageCategory = 'overview' | 'market' | 'explore' | 'profile' | 'search' | 'messages'

// Because we never want to show "search" as the active category we use a global variable instead to track the previous
// valid result, this is probably not bulletproof but works for now
let previousResult: PageCategory = 'overview'

export function useActivePageCategory(): PageCategory {
  const location = useLocation()

  let result: PageCategory = 'overview'

  if (location.pathname.includes('/search')) result = previousResult

  if (location.pathname.includes('/overview') || location.pathname.includes('/accounts')) result = 'overview'

  if (location.pathname.includes('/market')) result = 'market'

  if (location.pathname.includes('/explore') || location.pathname.includes('/research')) result = 'explore'

  if (location.pathname.includes('/profile')) result = 'profile'

  if (location.pathname.includes('/messages')) result = 'messages'

  // Keep track so we can show the active page when in the search route
  previousResult = result

  return result
}
