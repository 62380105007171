import { useHttpSwr } from '@common/hooks/useHttpSwr'

import i18n from '@/i18n'

import { AllocationModel } from './AllocationModel'

export function useAccountAllocationAsset(accountId: string): AllocationModel[] {
  const { data } = useHttpSwr<{ item: { allocationModel: AllocationModel[] } }>(
    accountId === undefined ? null : `/accounts/${accountId}/allocation/asset`
  )

  return data?.item?.allocationModel?.map((x) => {
    return { ...x, type: translateAssetAllocations(x.type) }
  })
}
export const useAccountAllocationRegion = (accountId: string): AllocationModel[] => {
  const { data } = useHttpSwr<{ item: { allocationModel: AllocationModel[] } }>(
    accountId === undefined ? null : `/accounts/${accountId}/allocation/region/${i18n.language}`
  )

  return data?.item?.allocationModel
}

const translateAssetAllocations = (type: string) => {
  switch (type) {
    case 'Stock':
      return 'Aktier'
    case 'FixedIncome':
      return 'Räntebärande'
    case 'Alternative':
      return 'Alternativa'
    case 'Currency':
      return 'Likvida medel'
    case 'Other':
    default:
      return 'Övriga'
  }
}
