type UseUpdateUrlNoReloadReturn = {
  updateUrlNoReload: (newUrl: string) => void
}

export const useUpdateUrlNoReload = (): UseUpdateUrlNoReloadReturn => {
  const updateUrlNoReload = (newUrl: string) => {
    window.history.replaceState(null, '', newUrl)
  }

  return { updateUrlNoReload }
}
