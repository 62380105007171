import { CancelSimpleOrderRequest, SimpleOrder } from '@common/api/response'
import { TrashCanButtonWithConfirm } from '@common/components/TrashCanButtonWithConfirm'

import { useState } from 'react'
import { useTranslation } from 'react-i18next'

type SimpleOrderTrashCanButtonProps = {
  simpleOrder: SimpleOrder
  cancelOrder: (body: CancelSimpleOrderRequest) => void
  size: 'small' | 'medium'
}

export const SimpleOrderTrashCanButton = ({ size, simpleOrder, cancelOrder }: SimpleOrderTrashCanButtonProps) => {
  const [pendingCancelOrder, setPendingCancelOrder] = useState(false)
  const { t } = useTranslation()

  return (
    <TrashCanButtonWithConfirm
      loading={pendingCancelOrder && simpleOrder.canBeCancelledByClient}
      size={size}
      iconTooltipText={t('Ta bort order')}
      confirmButtonText={t('Ta bort order')}
      onClick={async () => {
        // Because there is a delay between cancelling an order
        // and the response returning from the server we use a bit of
        // "optimistic ui" here
        setPendingCancelOrder(true)
        cancelOrder({
          accountId: simpleOrder?.accountId,
          orderId: simpleOrder?.carnegieOrderId,
        })
      }}
    />
  )
}
