import {
  EnvelopeItemDescriptorOfMarketingEmailModelWrapper,
  PaginationResponseOfMarketingEmailModelWrapper,
  SubscriptionType,
} from '@common/api/response'

import { useHttpSwr } from './useHttpSwr'

const subscriptionTypeToUrlFragment: Record<SubscriptionType, string> = {
  [SubscriptionType.ShortTerm]: 'shortterm',
  [SubscriptionType.MacroInFocus]: 'macro',
  [SubscriptionType.MorningNews]: 'morningnews',
  [SubscriptionType.AllocationStrategy]: 'allocationstrategy',
}

export function useShortTerm(page: number, pageSize: number) {
  const { error, data } = useHttpSwr<PaginationResponseOfMarketingEmailModelWrapper>(
    `/content/subscriptions/shortterm?page=${page}&pageSize=${pageSize}`
  )
  const { currentPage, items, totalPages, totalRows, usingLimit, success } = data ?? {}

  return {
    shortTerms: data && success ? items : undefined,
    currentPage,
    error,
    totalPages,
    totalRows,
    usingLimit,
  }
}

export function useMorningNews(page: number, pageSize: number) {
  const { error, data } = useHttpSwr<PaginationResponseOfMarketingEmailModelWrapper>(
    `/content/subscriptions/morningnews?page=${page}&pageSize=${pageSize}`
  )
  const { currentPage, items: morningNews, totalPages, totalRows, usingLimit } = data ?? {}

  return {
    morningNews,
    currentPage,
    error,
    totalPages,
    totalRows,
    usingLimit,
  }
}

export function useMacroInFocus(page: number, pageSize: number) {
  const { error, data } = useHttpSwr<PaginationResponseOfMarketingEmailModelWrapper>(
    `/content/subscriptions/macro?page=${page}&pageSize=${pageSize}`
  )
  const { currentPage, items, totalPages, totalRows, usingLimit, success } = data ?? {}

  return {
    macroInFocus: data && success ? items : undefined,
    currentPage,
    error,
    totalPages,
    totalRows,
    usingLimit,
  }
}

export function useFetchSubscription(marketingEmailId: string, subscriptionType: SubscriptionType) {
  const endpoint = subscriptionTypeToUrlFragment[subscriptionType]

  const { error, data } = useHttpSwr<EnvelopeItemDescriptorOfMarketingEmailModelWrapper>(
    endpoint ? `/content/subscriptions/${endpoint}/${marketingEmailId}` : null
  )

  return {
    subscription: data && !error ? data.item : undefined,
    error,
  }
}
