import { FormattedNumber, FormattedPercentage } from '@common/components/FormattedNumber'
import { ExpandableTr } from '@common/components/SmartTable'
import { useUnderlyingAssets } from '@common/hooks/useUnderlyingAssets'
import { sum } from '@common/utils/sum'

import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { SkeletonText, Td, Text } from '@carnegie/duplo'

import { TableAboutColumnId } from './TableAbout/TableAbout'
import { TableAcquisitionColumnId } from './TableAcquisition/TableAcquisition'
import { TableOverviewColumnId } from './TableOverview/TableOverview'
import { ObservableTableInstrument } from './useTableInstruments'

type UnderlyingAssetsRowProps = {
  instrument: ObservableTableInstrument
  columns: { id?: TableAboutColumnId | TableOverviewColumnId | TableAcquisitionColumnId }[]
}

export const UnderlyingAssetsRows = ({ columns, instrument }: UnderlyingAssetsRowProps) => {
  const { details } = useUnderlyingAssets(instrument.instrumentIdCarnegie, instrument.marketValue)
  const { t } = useTranslation()

  return (
    <>
      {details ? (
        <>
          {details.map((detailsRow) => {
            const idToCell: Partial<
              Record<TableAboutColumnId | TableOverviewColumnId | TableAcquisitionColumnId, ReactNode>
            > = {
              'expander-button': '',
              button: '',
              weight: <FormattedPercentage value={detailsRow.weight} decimals={2} />,
              name: detailsRow.instrumentName,
              quantity: <FormattedNumber value={detailsRow.holdingQuantity} decimals={{ min: 0, max: 5 }} />,
              acquisitionCost: '-',
              acquisitionCostOriginal: '-',
              acquisitionFxRate: '-',
              averageAcquisitionPrice: '-',
              averageAcquisitionPriceOriginal: '-',
              marketValue: (
                <FormattedNumber value={detailsRow.holdingMarketValuePortfolio} decimals={0} unit={t('SEK')} />
              ),
            }

            return (
              <ExpandableTr key={detailsRow.instrumentId + detailsRow.instrumentName} subRow>
                {columns.map(({ id }) => {
                  return (
                    <Td key={id}>
                      <Text truncate>{idToCell[id] ?? '-'}</Text>{' '}
                    </Td>
                  )
                })}
              </ExpandableTr>
            )
          })}
          {/* Sum row */}
          <ExpandableTr key="sum" lastRow subRow bordered>
            {columns.map(({ id }) => {
              const idToCell: Partial<
                Record<TableAboutColumnId | TableOverviewColumnId | TableAcquisitionColumnId, ReactNode>
              > = {
                name: <Text variant="subtitle2">{t('Total')}</Text>,
                weight: <FormattedPercentage value={sum('weight', details)} decimals={2} />,
                marketValue: (
                  <FormattedNumber
                    decimals={0}
                    variant="subtitle2"
                    unit="SEK"
                    value={sum('holdingMarketValuePortfolio', details)}
                  />
                ),
              }

              return (
                <Td key={id}>
                  <Text truncate>{idToCell[id]}</Text>
                </Td>
              )
            })}
          </ExpandableTr>
        </>
      ) : (
        [1, 2, 3, 4, 5].map((i) => (
          <ExpandableTr subRow key={i}>
            {columns.map(({ id }) => {
              return <Td key={id}>{id !== 'expander-button' && id !== 'button' && <SkeletonText width={'100%'} />}</Td>
            })}
          </ExpandableTr>
        ))
      )}
    </>
  )
}
