import { SkeletonLoader } from '@common/components/SkeletonLoader'

import { ReactNode, useMemo } from 'react'
import { ChartProps, Doughnut } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'

import { formatNumber } from '@carnegie/digital-channels-frontend'
import { Box, Breakpoint, useBreakpoint, useDuploTheme } from '@carnegie/duplo'

import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js'
import { observer } from 'mobx-react-lite'

import { useAccountAllocationAsset, useAccountAllocationRegion } from './useAccountAllocation'

//Need to register the used chart elements from v4 of chartjs for tree shaking
//Labels and tooltips wont show if this is removed.
ChartJS.register(ArcElement, Tooltip, Legend)

type Props = {
  accountId: string
  className?: string
  children?: ReactNode
  type: 'assets' | 'region'
}

export const AccountAllocationChart = observer(({ accountId, type }: Props) => {
  const { t } = useTranslation()
  const assetData = useAccountAllocationAsset(accountId)
  const regionData = useAccountAllocationRegion(accountId)
  const breakpoint = useBreakpoint()
  const theme = useDuploTheme()

  const isMobile = breakpoint < Breakpoint.Medium
  const isTablet = breakpoint < Breakpoint.Large
  const chartWidth = isMobile ? 312 : isTablet ? 376 : 288
  const chartHeight = 160

  const chartColors = useMemo(
    () => [
      theme.colors['paprika-900'],
      theme.colors['paprika-100'],
      theme.colors['paprika-main'],
      theme.colors['regent-st-900'],
      theme.colors['regent-st-main'],
      theme.colors['carnegie-dark-blue'],
    ],
    [theme]
  )

  const assetChartData: ChartProps<'doughnut', unknown>['data'] = useMemo(
    () =>
      assetData && {
        labels: assetData.filter((a) => a.percentage > 0).map((a) => t(a.type)),
        datasets: [
          {
            data: assetData.filter((a) => a.percentage > 0).map((a) => Number(a.percentage * 100).toFixed(2)),
            backgroundColor: chartColors,
          },
        ],
      },
    [assetData, chartColors, t]
  )

  const regionChartData: ChartProps<'doughnut', unknown>['data'] = useMemo(
    () =>
      regionData && {
        labels: regionData.filter((a) => a.percentage > 0).map((a) => t(a.type)),
        datasets: [
          {
            data: regionData.filter((a) => a.percentage > 0).map((a) => Number(a.percentage * 100).toFixed(2)),
            backgroundColor: chartColors,
          },
        ],
      },
    [regionData, chartColors, t]
  )

  const chartOptions: ChartProps<'doughnut'>['options'] = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'right',
        align: 'start',
        labels: {
          boxWidth: 8,
          color: theme.colors['bunker-200'],
          font: { family: 'Roboto' },
          usePointStyle: true,
        },
      },
      tooltip: {
        backgroundColor: theme.colors['bunker-main'],
        bodyFont: { size: 11, family: 'Roboto' },
        bodyColor: theme.colors['off-white'],
        padding: 16,
        caretSize: 0,
        bodyAlign: 'center',
        usePointStyle: true,
        callbacks: {
          label: function (tooltipItem) {
            return tooltipItem.label + ': ' + formatNumber(tooltipItem.parsed)
          },
        },
      },
    },
  }

  return (
    <Box p={16}>
      <SkeletonLoader
        height={184}
        loading={accountId === undefined || assetData === undefined || regionData === undefined}
      >
        {() => (
          <Box>
            <Doughnut
              height={chartHeight}
              width={chartWidth}
              options={chartOptions}
              data={type === 'assets' ? assetChartData : regionChartData}
            />
          </Box>
        )}
      </SkeletonLoader>
    </Box>
  )
})
