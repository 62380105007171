import { getCookieConsent, registerCookieConsentEvent } from '@common/utils/cookieConsent'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { ApplicationInsights, ITelemetryItem, ITelemetryPlugin } from '@microsoft/applicationinsights-web'

import { Breakpoint } from '@carnegie/duplo'

import { getConfig } from './common/config'
import i18n from './i18n'

const isApplicationInsightsEnabled = getConfig()?.AI_KEY?.length > 0

const appId = 'PBOnline'
const instanceName = window.location.hostname

const reactPlugin = new ReactPlugin()

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: getConfig().AI_KEY,
    disableFetchTracking: false,
    enableCorsCorrelation: true,
    enableAutoRouteTracking: true,
    extensions: [reactPlugin as unknown as ITelemetryPlugin],
    disableCookiesUsage: getDisableCookiesUsage(),
    correlationHeaderExcludedDomains: ['*.infrontservices.com', '*.sentry.io'],
  },
})

registerCookieConsentEvent((consentType) => {
  if (consentType === 'accepted') {
    appInsights.core.getCookieMgr().setEnabled(true)
  } else {
    appInsights.core.getCookieMgr().setEnabled(false)
  }
})

function getDisableCookiesUsage() {
  return getCookieConsent() !== 'accepted'
}

const getCurrentBreakpoint = () => {
  const mediaQueries = [
    { name: 'xl', query: `(min-width: ${Breakpoint.Xl}px)` },
    { name: 'lg', query: `(min-width: ${Breakpoint.Large}px)` },
    { name: 'md', query: `(min-width: ${Breakpoint.Medium}px)` },
    { name: 'sm', query: `(min-width: ${Breakpoint.Small}px)` },
    { name: 'xs', query: `(min-width: ${Breakpoint.Xs}px)` },
  ]

  const mediaQueryLists = mediaQueries.map(({ query }) => window.matchMedia(query))
  const index = mediaQueryLists.findIndex((mql) => mql.matches)

  return mediaQueries[index]?.name ?? 'xs'
}

const addRoleAndInstance = (envelope: ITelemetryItem) => {
  if (!envelope.tags) {
    envelope.tags = []
  }

  envelope.tags.push({ 'ai.cloud.role': appId })
  envelope.tags.push({ 'ai.cloud.roleInstance': instanceName })
}

const addCustomData = (envelope: ITelemetryItem) => {
  envelope.data = { ...envelope?.data, breakpoint: getCurrentBreakpoint(), language: i18n.language }
}

const initApplicationInsights = () => {
  if (isApplicationInsightsEnabled) {
    appInsights.loadAppInsights()
    appInsights.addTelemetryInitializer(addRoleAndInstance)
    appInsights.addTelemetryInitializer(addCustomData)
    appInsights.trackPageView()
  }
}

export { appInsights, initApplicationInsights, reactPlugin }
