import { useInfrontInstrument } from '@common/hooks/infront/sdk/useInfrontInstrument'
import { InstrumentIdContainer } from '@common/instrumentIdContainer'

import { useTranslation } from 'react-i18next'

import { Paragraph, Segment, Spacer } from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import { InstrumentIndexInformation } from './InstrumentIndexInformation'

type Props = {
  instrumentIdContainer: InstrumentIdContainer
}

export const InstrumentIndexOverview = observer(({ instrumentIdContainer }: Props) => {
  const { t } = useTranslation()

  const { observableInstrument } = useInfrontInstrument(
    instrumentIdContainer?.infrontInstrument,
    [InfrontSDK.SymbolField.IndexDescription],
    {
      observe: false,
    }
  )

  const indexDescription = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.IndexDescription) ?? ''

  return (
    <Segment title={t('Information')} variant="contained" headingVariant="overline">
      <Paragraph variant="body1">{t(indexDescription)}</Paragraph>
      {indexDescription && <Spacer height={16} />}
      <InstrumentIndexInformation instrumentIdContainer={instrumentIdContainer} />
    </Segment>
  )
})
InstrumentIndexOverview.displayName = 'InstrumentIndexOverview'
