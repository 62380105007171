import { useTranslation } from 'react-i18next'

import { Link } from '@carnegie/duplo'

type Props = {
  to: string
  onClick?: () => void
  state?: object
  title?: string
  count?: number
}

export const ShowAll = ({ to, onClick, title = 'Visa alla', count }: Props) => {
  const { t } = useTranslation()

  return (
    <Link to={to} onClick={onClick} size="small">
      {t(title)}
      {count !== undefined ? ` (${count})` : ''}
    </Link>
  )
}
