import { InfrontInstrument } from '@common/api/response'
import { SkeletonLoader } from '@common/components/SkeletonLoader'
import { NewsHeadline, useInfrontNews } from '@common/hooks/infront/sdk/useInfrontNews'

import { useTranslation } from 'react-i18next'

import { checkToday } from '@carnegie/digital-channels-frontend'
import { ListItem, ListItemRow, Segment, Text } from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import { useOpenNewsDrawer } from '../newsDrawer/useOpenNewsDrawer'

type InstrumentNewsProps = {
  infrontInstrument: InfrontInstrument
  className?: string
}

export const InstrumentNews = observer(({ infrontInstrument: instrument, className }: InstrumentNewsProps) => {
  const { openNewsDrawer } = useOpenNewsDrawer()

  const { t } = useTranslation()

  const newsItemSelected = async (headline: NewsHeadline) => {
    openNewsDrawer(headline.id, headline?.infrontNewsHeadline)
  }

  const { headlines } = useInfrontNews([instrument], undefined, 10)

  if (headlines && headlines.length === 0) return null

  return (
    <>
      <Segment
        className={className}
        title={t('Nyheter')}
        noContentPadding
        variant="contained"
        headingVariant="overline"
      >
        <SkeletonLoader p={16} loading={!headlines}>
          {() => (
            <>
              {headlines.map((headline) => {
                return (
                  <ListItem
                    divider
                    data-testid="instrument-news-item"
                    key={headline.id}
                    onClick={() => newsItemSelected(headline)}
                  >
                    <ListItemRow
                      title={<Text variant="overline">{headline.source}</Text>}
                      value={<Text variant="overline">{checkToday(headline.dateTime)}</Text>}
                    />
                    <ListItemRow title={<Text variant="subtitle2">{headline.headline}</Text>} />
                  </ListItem>
                )
              })}
            </>
          )}
        </SkeletonLoader>
      </Segment>
    </>
  )
})
InstrumentNews.displayName = 'InstrumentNews'
