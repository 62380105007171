import { SkeletonLoader } from '@common/components/SkeletonLoader'
import { useInfrontInstrument } from '@common/hooks/infront/sdk/useInfrontInstrument'
import { InstrumentIdContainer } from '@common/instrumentIdContainer'
import { translateRegionCodes } from '@common/utils/translateRegionCodes'

import { ChartProps, Doughnut } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'

import { formatNumber } from '@carnegie/digital-channels-frontend'
import { Box, FlexCol, SegmentWithTabs, SimpleTabs, useDuploTheme } from '@carnegie/duplo'

import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js'
import { observer } from 'mobx-react-lite'

//Need to register the used chart elements from v4 of chartjs for tree shaking
//This will crash if removed
ChartJS.register(ArcElement, Tooltip, Legend)

type Props = {
  instrumentIdContainer: InstrumentIdContainer
}

const sum = (arr: Array<[string, number]>) => arr.reduce((tutti: number, item: [string, number]) => tutti + item[1], 0)

const sumExposure = (arr: Array<{ Exposure: number }>) => arr.reduce((total: number, item) => total + item.Exposure, 0)

const MAX_ITEMS_IN_PIE = 6

export const InstrumentFundsAllocations = observer(({ instrumentIdContainer }: Props) => {
  const { t } = useTranslation()
  const theme = useDuploTheme()

  const chartColors = [
    theme.colors['paprika-900'],
    theme.colors['paprika-100'],
    theme.colors['paprika-main'],
    theme.colors['regent-st-900'],
    theme.colors['regent-st-main'],
    theme.colors['carnegie-dark-blue'],
  ]

  const translateSector = (sector: string) => {
    switch (sector) {
      case 'Industrials':
        return t('Industri')
      case 'Technology':
        return t('Teknik')
      case 'Consumer Cyclical':
        return t('Konsument, cyklisk')
      case 'Financial Services':
        return t('Finans')
      case 'Real Estate':
        return t('Fastigheter')
      case 'Consumer Defensive':
        return t('Konsument, stabil')
      case 'Healthcare':
        return t('Sjukvård')
      case 'Utilities':
        return t('Allmännyttigt')
      case 'Communication Services':
        return t('Kommunikation')
      case 'Energy':
        return t('Energi')
      case 'Basic Materials':
        return t('Råvaror(Bransch)')
      default:
        return sector
    }
  }

  const splitAndAddOthers = (arr: Array<[string, number]>, exposure = false) => {
    if (arr.length < MAX_ITEMS_IN_PIE) return arr
    const first = arr.slice(0, 5)
    const rest = arr.slice(5, arr.length)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (exposure) return [...first, [t('Övriga'), sumExposure(rest as any)]]

    return [...first, [t('Övriga'), sum(rest)]]
  }

  const { observableInstrument } = useInfrontInstrument(
    instrumentIdContainer?.infrontInstrument,
    [
      InfrontSDK.SymbolField.FundTopCountries,
      InfrontSDK.SymbolField.FundTopHoldings,
      InfrontSDK.SymbolField.FundTopSectors,
    ],
    { observe: false }
  )

  //CountryCode: (...)
  //Exposure: (...)
  const fundTopCountries = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.FundTopCountries)
  //0: "Investor AB B"
  //1: 27.941150665283
  const fundTopHoldings = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.FundTopHoldings)

  //Exposure: 79.42414855957
  //Sector: "Financial Services"
  const fundTopSectors = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.FundTopSectors)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const holdingsData: ChartProps<'doughnut', any>['data'] = fundTopHoldings && {
    labels: splitAndAddOthers(fundTopHoldings as unknown as Array<[string, number]>).map((a) => a[0]),
    datasets: [
      {
        data: splitAndAddOthers(fundTopHoldings as unknown as Array<[string, number]>).map((a) => a[1]),
        backgroundColor: chartColors,
      },
    ],
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const countriesData: ChartProps<'doughnut', any>['data'] = fundTopCountries && {
    labels: splitAndAddOthers(fundTopCountries as unknown as Array<[string, number]>, true).map((a) =>
      t(translateRegionCodes(a[0] as string))
    ),
    datasets: [
      {
        data: fundTopCountries.map((a) => {
          return Number(a[1]).toFixed(1)
        }),
        backgroundColor: chartColors,
      },
    ],
  }

  const topSectorsData: ChartProps<'doughnut', unknown>['data'] = fundTopSectors && {
    labels: splitAndAddOthers(fundTopSectors as unknown as Array<[string, number]>, true).map((a) =>
      translateSector(a[0] as string)
    ),
    datasets: [
      {
        data: splitAndAddOthers(fundTopSectors as unknown as Array<[string, number]>, true).map((a) =>
          Number(a[1]).toFixed(1)
        ),
        backgroundColor: chartColors,
      },
    ],
  }

  const chartOptions: ChartProps<'doughnut'>['options'] = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'right',
        align: 'start',
        fullSize: false,
        labels: {
          font: { family: 'Roboto', size: 11 },
          boxWidth: 8,
          color: theme.colors['bunker-200'],
          usePointStyle: true,
        },
      },
      tooltip: {
        backgroundColor: theme.colors['bunker-main'],
        bodyFont: { size: 11, family: 'Roboto' },
        bodyColor: theme.colors['off-white'],
        padding: 16,
        caretSize: 0,
        bodyAlign: 'center',
        usePointStyle: true,
        callbacks: {
          label: function (tooltipItem) {
            return tooltipItem.label + ': ' + formatNumber(tooltipItem.parsed)
          },
        },
      },
    },
  }

  return (
    <SegmentWithTabs
      data-testid="instrument-allocation-chart"
      title={t('Allokering')}
      variant="contained"
      headingVariant="overline"
    >
      <SimpleTabs
        tabs={[
          {
            value: 'tab-holding',
            label: t('Största innehaven'),
            content: (
              <SkeletonLoader
                p={16}
                height={128}
                loading={!observableInstrument}
                noDataLoaded={!holdingsData || (holdingsData?.datasets[0]?.data as unknown[]).length === 0}
              >
                {() => (
                  <Box p={16}>
                    <FlexCol alignItems="flex-start">
                      {holdingsData && <Doughnut options={chartOptions} data={holdingsData} />}
                    </FlexCol>
                  </Box>
                )}
              </SkeletonLoader>
            ),
          },
          {
            value: 'tab-region',
            label: t('Regioner'),
            content: (
              <SkeletonLoader p={16} height={128} loading={!observableInstrument} noDataLoaded={!countriesData}>
                {() => (
                  <Box p={16}>
                    <FlexCol alignItems="flex-start">
                      {countriesData && <Doughnut options={chartOptions} data={countriesData} />}
                    </FlexCol>
                  </Box>
                )}
              </SkeletonLoader>
            ),
          },
          {
            value: 'tab-branch',
            label: t('Branscher'),
            content: (
              <SkeletonLoader p={16} height={128} loading={!observableInstrument} noDataLoaded={!topSectorsData}>
                {() => (
                  <Box p={16}>
                    <FlexCol alignItems="flex-start">
                      {topSectorsData && <Doughnut options={chartOptions} data={topSectorsData} />}
                    </FlexCol>
                  </Box>
                )}
              </SkeletonLoader>
            ),
          },
        ]}
      />
    </SegmentWithTabs>
  )
})
InstrumentFundsAllocations.displayName = 'InstrumentFundsAllocations'
