import { AccountsResponse, CustomerReportModel } from '@common/api/response'
import { SkeletonLoader } from '@common/components/SkeletonLoader'
import { SmartTable } from '@common/components/SmartTable'
import { usePageParam } from '@common/hooks/usePageParam'
import { WebSocketMessage, useWebSocket } from '@common/hooks/useWebSocket'
import { scrollToTop } from '@common/utils/document'

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Banner, Box, Breakpoint, Spacer, Text, useBreakpoint } from '@carnegie/duplo'

import i18n from '@/i18n'

import { observer } from 'mobx-react-lite'

import { CustomerReportMobileRow } from './CustomerReportMobileRow'
import { CustomerReportRow } from './CustomerReportRow'
import { useCustomerReports } from './useCustomerReports'

type CustomerReportsProps = {
  selectedAccount: AccountsResponse
}

const STORAGE_KEY = `CUSTOMER_REPORTS`

export type CustomerReportColumnId = 'description' | 'documentTypeName' | 'accountNumber' | 'createDate'

const PAGE_SIZE = 25

export const CustomerReports = observer(({ selectedAccount }: CustomerReportsProps) => {
  const { t } = useTranslation()
  const breakpoint = useBreakpoint()
  const navigate = useNavigate()
  const isExtraSmallScreen = breakpoint <= Breakpoint.Xs

  const page = usePageParam()

  // We do not use the pagination right now since it won't allow us to sort the table across pages
  const { items, error, refresh } = useCustomerReports(selectedAccount?.id, 1, 200, i18n.language)

  useWebSocket((message: WebSocketMessage) => {
    if (message.type === 'REPORT_GENERATED') {
      refresh()
    }
  })

  const [downloadingDocumentError, setDownloadingDocumentError] = useState(false)

  return (
    <SkeletonLoader
      error={error}
      p={16}
      loading={!items}
      noDataLoaded={items?.length === 0}
      noDataComponent={<Text variant="body2">{t('Det finns inga rapporter för valt konto.')}</Text>}
    >
      {() => (
        <Box>
          {downloadingDocumentError && (
            <Box p={16}>
              <Banner
                severity="critical"
                title={t('Något gick fel')}
                description={t('Filen kan inte laddas ner. Kontakta oss för mer information.')}
                onClose={() => {
                  setDownloadingDocumentError(false)
                }}
              />
            </Box>
          )}
          <SmartTable<CustomerReportModel, CustomerReportColumnId>
            data={items}
            rowKey={(row) => row.documentId + row.createDate}
            sessionStorageKey={STORAGE_KEY}
            defaultSortBy="createDate"
            page={page}
            pageSize={PAGE_SIZE}
            onPageChange={(currentPage) => {
              navigate(`/profile/reports/archive/${selectedAccount?.id}/${currentPage}`)
              scrollToTop()
            }}
            columns={[
              {
                id: 'description',
                width: 'minmax(0,2fr)',
                renderHeader: () => t('Namn'),
                sortBy: ({ row }) => row.description,
                renderCell: ({ row: report }) => (
                  <CustomerReportRow cell="description" report={report} selectedAccount={selectedAccount} />
                ),
              },
              {
                id: 'documentTypeName',
                width: '1fr',
                renderHeader: () => t('Typ'),
                sortBy: ({ row }) => row.documentTypeName,
                renderCell: ({ row: report }) => (
                  <CustomerReportRow cell="documentTypeName" report={report} selectedAccount={selectedAccount} />
                ),
              },
              {
                id: 'accountNumber',
                width: '1fr',
                renderHeader: () => t('Konto'),
                renderCell: ({ row: report }) => (
                  <CustomerReportRow cell="accountNumber" report={report} selectedAccount={selectedAccount} />
                ),
              },
              {
                id: 'createDate',
                width: '1fr',
                renderHeader: () => t('Skapad'),
                sortBy: ({ row }) => row.createDate,
                renderCell: ({ row: report }) => (
                  <CustomerReportRow cell="createDate" report={report} selectedAccount={selectedAccount} />
                ),
                defaultSortOrder: 'desc',
              },
            ]}
            renderMode={isExtraSmallScreen ? 'custom' : 'table'}
            renderRow={
              isExtraSmallScreen
                ? ({ row: report, index }) => {
                    // Mobile table
                    return <CustomerReportMobileRow report={report} key={index} selectedAccount={selectedAccount} />
                  }
                : undefined
            }
          />
          <Spacer height={2} />
        </Box>
      )}
    </SkeletonLoader>
  )
})
