import { UserSettingKey } from '@common/api/response'
import { Guid } from '@common/guid'

import { useUserSetting } from './useUserSetting'

type AccountUserSettings = Record<Guid, AccountUserSetting>

export type AccountUserSetting = {
  isFavorite: boolean
}

export function useAccountUserSettings() {
  const { value, setValue, error } = useUserSetting<AccountUserSettings>(UserSettingKey.ACCOUNT_USER_SETTINGS)

  return {
    error,
    accountUserSettings: value,
    setAccountUserSettings: async (accountId: Guid, settings: AccountUserSetting) => {
      const newData = { ...value, [accountId]: settings }

      await setValue(newData)
    },
  }
}
