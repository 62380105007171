import { useSelectedHoldingAccount } from '@common/hooks/useHoldings'

import { useTranslation } from 'react-i18next'

import { Box } from '@carnegie/duplo'

import { HoldingsSegment } from './HoldingsSegment'

class CarnegieInstrumentGroups {
  //TODO: Use the real type instead
  static Equity = ['Equity']
  static MutualFunds = ['MutualFunds']
  static Etf = ['Etf']
  static Bonds = ['Bonds']
  static CertificatesWarrants = ['CertificatesWarrants']
  static OptionsForwardsFutures = ['OptionsForwardsFutures']
  static Others = ['Others']
}

type Props = {
  showPercentage
  showMoreColumns: boolean
}

export const GroupName = ({ showPercentage, showMoreColumns }: Props) => {
  const { t } = useTranslation()
  const { account } = useSelectedHoldingAccount()
  const instruments = account?.holdings?.instruments

  const filterBy = (groupName: string[]) => {
    return instruments?.filter((x) => groupName.includes(x?.instrument?.carnegieInstrumentGroup))
  }

  const bonds = filterBy(CarnegieInstrumentGroups.Bonds)
  const equities = filterBy(CarnegieInstrumentGroups.Equity)
  const mutualFunds = filterBy(CarnegieInstrumentGroups.MutualFunds)
  const etfs = filterBy(CarnegieInstrumentGroups.Etf)
  const certificates = filterBy(CarnegieInstrumentGroups.CertificatesWarrants)
  const options = filterBy(CarnegieInstrumentGroups.OptionsForwardsFutures)
  const others = filterBy(CarnegieInstrumentGroups.Others)

  return (
    <Box>
      <HoldingsSegment
        title={t('Aktier')}
        instruments={equities}
        showPercentage={showPercentage}
        showMoreColumns={showMoreColumns}
        tableSortStorageKey={`GROUP_EQUITIES`}
      />
      <HoldingsSegment
        title={t('Fonder')}
        instruments={mutualFunds}
        showPercentage={showPercentage}
        showMoreColumns={showMoreColumns}
        tableSortStorageKey={`GROUP_MUTUAL_FUNDS`}
        isFund
      />
      <HoldingsSegment
        title={t('ETF:er')}
        instruments={etfs}
        showPercentage={showPercentage}
        showMoreColumns={showMoreColumns}
        tableSortStorageKey={`GROUP_ETFS`}
      />
      <HoldingsSegment
        title={t('Obligationer')}
        instruments={bonds}
        showPercentage={showPercentage}
        showMoreColumns={showMoreColumns}
        tableSortStorageKey={`GROUP_BONDS`}
      />
      <HoldingsSegment
        title={t('Certifikat och warranter')}
        instruments={certificates}
        showPercentage={showPercentage}
        showMoreColumns={showMoreColumns}
        tableSortStorageKey={`GROUP_CERTIFICATES`}
      />
      <HoldingsSegment
        title={t('Optioner och terminer')}
        instruments={options}
        showPercentage={showPercentage}
        showMoreColumns={showMoreColumns}
        tableSortStorageKey={`GROUP_OPTIONS`}
      />
      <HoldingsSegment
        title={t('Övriga')}
        instruments={others}
        showPercentage={showPercentage}
        showMoreColumns={showMoreColumns}
        tableSortStorageKey={`GROUP_OTHERS`}
      />
    </Box>
  )
}
