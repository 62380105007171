import { Guid } from '@common/guid'

import { useMessageAvatar } from '@carnegie/digital-channels-frontend'
import { SkeletonCircle } from '@carnegie/duplo'

import CustomerAvatarImg from './customerAvatar.svg'

export const CustomerAvatar = () => {
  return <CustomerAvatarImg css={{ width: '100%', height: '100%', borderRadius: 9999 }} />
}

type EmployeeAvatarProps = { partyId?: Guid }

export const EmployeeAvatar = ({ partyId = '' }: EmployeeAvatarProps) => {
  const { avatarImageSrc } = useMessageAvatar(partyId)

  return (
    <>
      {!avatarImageSrc ? (
        <SkeletonCircle width="100%" height="100%" />
      ) : (
        <img css={{ width: '100%', height: '100%', borderRadius: 9999 }} alt="avatar" src={avatarImageSrc} />
      )}
    </>
  )
}
