import { FullPageSpinner } from '@common/components/FullPageSpinner'

import { useLocation } from 'react-router-dom'

import { AuthProvider } from '@carnegie/curity-auth'

import LoginErrorPage from '@/pages/login/LoginErrorPage'
import LoginPage from '@/pages/login/LoginPage'
import PermissionDeniedPage from '@/pages/login/PermissionDeniedPage'

interface AuthProviderContainerProps {
  children: JSX.Element
}

const publicPaths = ['/login/support', '/error']

export const AuthProviderContainer = ({ children }: AuthProviderContainerProps) => {
  const apiBaseUrl = document.location.origin + '/api'
  const location = useLocation()

  return publicPaths.includes(location.pathname) ? (
    children
  ) : (
    <AuthProvider
      apiBaseUrl={apiBaseUrl}
      cookieNamePrefix="portal"
      loadingComponent={() => <FullPageSpinner ariaLabel="Loggar in..." />}
      permissionDeniedPage={() => <PermissionDeniedPage />}
      loginPage={(props) => <LoginPage {...props} />}
      loginErrorPage={() => <LoginErrorPage />}
    >
      {children}
    </AuthProvider>
  )
}
