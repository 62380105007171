import { FadeIn } from '@common/components/animation/FadeIn'
import { useMobileDetect } from '@common/hooks/useMobileDetect'
import useScrollInfo, { ScrollState } from '@common/hooks/useScrollInfo'
import { css } from '@emotion/react'

import { ReactNode, useCallback, useRef } from 'react'

import { ButtonRound, FlexCol, IconChevronLeft, IconChevronRight, useDuploTheme } from '@carnegie/duplo'

type CarouselProps = {
  className?: string
  children?: ReactNode
  hideButtons?: boolean
  scrollAmount?: number
  blurColor?: 'off-white' | 'background-canvas'
}

export const Carousel = ({
  children,
  className,
  scrollAmount = 100,
  hideButtons = false,
  blurColor = 'off-white',
}: CarouselProps) => {
  const [scrollInfo, setRef] = useScrollInfo() as [ScrollState, (ref: HTMLDivElement) => void]
  const scrollContainerRef = useRef<HTMLDivElement>(null)

  const percentageScrolledX: number = scrollInfo.x.percentage
  const scrolledX = scrollInfo.x.value

  // We need to memo this or we get lots of extra renders
  const setRefs = useCallback((r: HTMLDivElement) => {
    setRef(r)
    scrollContainerRef.current = r
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showLeftScroll = scrolledX > 0
  const showRightScroll = percentageScrolledX !== null && percentageScrolledX < 0.9

  return (
    <div className={className} css={{ width: '100%', position: 'relative' }}>
      {!hideButtons && (
        <>
          <ScrollButton
            show={showLeftScroll}
            direction="left"
            onClick={() => {
              if (scrollContainerRef.current) {
                scrollContainerRef.current.scrollBy(-scrollAmount, 0)
              }
            }}
          />
          <ScrollButton
            show={showRightScroll}
            direction="right"
            onClick={() => {
              if (scrollContainerRef.current) {
                scrollContainerRef.current.scrollBy(scrollAmount, 0)
              }
            }}
          />
        </>
      )}
      {showLeftScroll && <SideBlur direction="left" blurColor={blurColor} />}
      {showRightScroll && <SideBlur direction="right" blurColor={blurColor} />}
      <div
        ref={setRefs}
        css={{
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          overflowY: 'hidden',
          overflowX: 'scroll',
          scrollBehavior: 'smooth',
          scrollSnapType: 'x mandatory',
          scrollSnapPointsX: `${scrollAmount}px`,
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {children}
      </div>
    </div>
  )
}

type ScrollButtonProps = {
  direction: 'left' | 'right'
  onClick: () => void
  show: boolean
}

const ScrollButton = ({ direction, onClick, show }: ScrollButtonProps) => {
  const detectMobile = useMobileDetect()
  const isMobile = detectMobile.isMobile()

  if (isMobile) return null

  return (
    <FadeIn
      show={show}
      duration={0.35}
      style={{
        ...(direction === 'left' ? { left: 0 } : { right: 0 }),
        cursor: 'pointer',
        position: 'absolute',
        zIndex: 9,
        height: '100%',
      }}
    >
      <FlexCol height="100%" justifyContent="center">
        <ButtonRound size={40} onClick={onClick} icon={direction === 'left' ? IconChevronLeft : IconChevronRight} />
      </FlexCol>
    </FadeIn>
  )
}

type SideBlurProps = {
  direction: 'left' | 'right'
  blurColor: 'off-white' | 'background-canvas'
}

const SideBlur = ({ direction, blurColor }: SideBlurProps) => {
  const theme = useDuploTheme()
  const fadeToColor = theme.colors[blurColor] ?? blurColor
  return (
    <div
      css={css`
        pointer-events: none;
        z-index: 2;
        ${direction === 'right' ? 'right: 0' : 'left: 0'};
        width: 20px;
        height: 100%;
        position: absolute;
        background-image: ${direction === 'right'
          ? `linear-gradient(to right, transparent, ${fadeToColor})`
          : `linear-gradient(to right, ${fadeToColor}, transparent)`};
      `}
    />
  )
}
