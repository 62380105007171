export type ChartDataPoint = { 0: number; 1: number }

export function getNumOfDays(dataPoints: ChartDataPoint[]) {
  if (!dataPoints || dataPoints.length === 0) return 0
  const firstDataPoint = dataPoints[0]
  const lastDataPoints = dataPoints[dataPoints.length - 1]
  const diffInTime = lastDataPoints[0] - firstDataPoint[0]
  const numOfDays = Math.ceil((diffInTime / (1000 * 3600 * 24)) * 1.25) // Add a couple of days to be sure
  return numOfDays
}

export function toDataPoint(infrontDataPoint: { date: Date; last: number }): ChartDataPoint {
  return [toSwedishTime(infrontDataPoint.date), infrontDataPoint.last]
}

export function removeRedundantDataPoints(dataPoints: ChartDataPoint[], infrontDataPoints: ChartDataPoint[]) {
  const validTimestamps = dataPoints.map((apdp) => apdp[0])
  return infrontDataPoints.filter((idp) => validTimestamps.includes(idp[0]))
}

export function convertToCompareIndexDataPoints(dataPoints: ChartDataPoint[]) {
  if (dataPoints.length === 0) return []

  const initialValue = dataPoints[0][1]
  const result: ChartDataPoint[] = []

  for (let i = 0; i < dataPoints.length; i++) {
    const dp = dataPoints[i]
    const value = ((dp[1] - initialValue) / initialValue) * 100
    result.push([dp[0], value])
  }

  return result
}

export function toSwedishTime(date: Date | string | number) {
  return new Date(new Date(date).toLocaleDateString('sv-SE')).getTime()
}
