import { Breakpoint, Link, useBreakpoint } from '@carnegie/duplo'

import CarnegieLogoSmall from './carnegielogosmall.svg'
import CarnegieLogoText from './carnegielogotext.svg'

export const CarnegieLogo = () => {
  const breakpoint = useBreakpoint()

  if (breakpoint <= Breakpoint.Xs) {
    return (
      <Link display="block" height={32} width={32} to="/overview/home">
        <CarnegieLogoSmall style={{ width: 32 }} />
      </Link>
    )
  } else {
    return (
      <Link display="block" height={32} width={96} to="/overview/home">
        <CarnegieLogoText style={{ width: 96 }} />
      </Link>
    )
  }
}
