import { AccountsResponse } from '@common/api/response'
import { CopyToClipboard } from '@common/components/CopyToClipboard'
import CreateReportSideDrawer from '@common/components/CreateReportSideDrawer'
import { CurrencyFlag } from '@common/components/CurrencyToFlag'
import { FormattedPercentage } from '@common/components/FormattedNumber'
import { ToggleFavoriteMenuItem } from '@common/components/ToggleFavoriteMenuItem'
import { useSelectedHoldingAccount } from '@common/hooks/useHoldings'
import { useAuth } from '@common/stores/store'
import { fireTrackEvent } from '@common/utils/analyticsEvent'
import { formatPhoneNumber } from '@common/utils/phoneNumber'

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import {
  Badge,
  Box,
  Breakpoint,
  Button,
  ButtonIcon,
  FlexRow,
  Icon,
  IconButton,
  IconDeposit,
  IconDocumentLineChart,
  IconDocumentLineChartAdd,
  IconInfoOutlined,
  IconInternalTransfer,
  IconMore,
  IconOrder,
  IconTransaction,
  IconWithdrawal,
  LinkExternal,
  ListItem,
  ListItemRow,
  ListItemWithAccordion,
  Menu,
  MenuDivider,
  MenuItem,
  MenuItems,
  MenuToggle,
  SideDrawer,
  SkeletonCircle,
  SkeletonText,
  Text,
  Tooltip,
  useBreakpoint,
} from '@carnegie/duplo'

import { TransferDrawerTabs } from '@/drawers/transfersDrawer/TransfersDrawer'

import { observer } from 'mobx-react-lite'

import { IconOffering } from '../../common/components/icons/IconOffering'
import { useOpenTransfersDrawer } from '../../drawers/transfersDrawer/useOpenTransfersDrawer'
import { EmployeeAvatar } from '../messages/shared/avatar/Avatar'

type AccountShortcutsProps = {
  selectedAccount: AccountsResponse
}

const policyHolderAccountTypes = ['KF', 'KP', 'PF', 'TJP', 'DP']

export const AccountShortcuts = observer(({ selectedAccount }: AccountShortcutsProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const openTransfersDrawer = useOpenTransfersDrawer()
  const [openCreateReportDrawer, setCreateReportDrawer] = useState(false)
  const { account: selectedHoldingAccount } = useSelectedHoldingAccount()
  const [openAccountInformation, setOpenAccountInformation] = useState(false)
  const breakpoint = useBreakpoint()
  const isExtraSmallScreen = breakpoint < Breakpoint.Small
  const auth = useAuth()

  const accountInformationSideDrawer = (
    <SideDrawer
      title={t('Kontoinformation')}
      variant="slide"
      open={openAccountInformation}
      onClose={() => {
        setOpenAccountInformation(false)
      }}
      sidebarContentPadding={0}
      sidebarContent={
        <Box>
          <ListItem>
            <ListItemRow title={<Text variant="subtitle2">{t('Information')}</Text>} />
          </ListItem>

          {selectedAccount ? (
            <ListItem compact divider>
              <ListItemRow
                title={
                  <Text variant="support1" color="text-low-emphasis">
                    {t('Kontotyp')}
                  </Text>
                }
                value={
                  <FlexRow alignItems={'center'}>
                    {selectedAccount?.accountType?.description ? (
                      <Tooltip title={t(selectedAccount?.accountType?.description)}>
                        <Badge size="medium">{selectedAccount?.accountType?.shortName}</Badge>
                      </Tooltip>
                    ) : (
                      <Badge size="medium">{selectedAccount?.accountType?.shortName}</Badge>
                    )}
                    <Text variant="subtitle2" ml={4}>
                      {t(selectedAccount?.accountType?.description)}
                    </Text>
                  </FlexRow>
                }
              />
            </ListItem>
          ) : (
            <ListItem compact divider>
              <ListItemRow title={<SkeletonText width={100} />} value={<SkeletonText width={100} />} />
            </ListItem>
          )}

          {selectedAccount ? (
            <ListItem compact divider>
              <ListItemRow
                title={
                  <Text variant="support1" color="text-low-emphasis">
                    {t('Kontonummer')}
                  </Text>
                }
                value={
                  <FlexRow alignItems={'center'}>
                    <Text variant="subtitle2" mr={4}>
                      {selectedAccount?.number}
                    </Text>
                    <CopyToClipboard
                      identifier={t('Kontonummer')}
                      value={selectedAccount?.number}
                      style={{
                        display: 'inline-block',
                      }}
                    />
                  </FlexRow>
                }
              />
            </ListItem>
          ) : (
            <ListItem compact divider>
              <ListItemRow title={<SkeletonText width={100} />} value={<SkeletonText width={100} />} />
            </ListItem>
          )}

          {selectedHoldingAccount ? (
            selectedHoldingAccount?.information?.owners?.length > 1 ? (
              <ListItemWithAccordion
                compact
                divider
                expandableContent={selectedHoldingAccount?.information?.owners?.map((owner, index) => (
                  <ListItem compact key={index}>
                    <ListItemRow
                      title={''}
                      value={
                        <>
                          <Tooltip title={owner}>
                            <Text variant="subtitle2">{owner}</Text>
                          </Tooltip>
                          <Box></Box>
                        </>
                      }
                    />
                  </ListItem>
                ))}
              >
                {/* We don't use <ListItemRow> here because it handles truncation between title and value poorly */}
                <FlexRow justifyContent={'space-between'} space={4} css={{ whiteSpace: 'nowrap' }}>
                  <Box>
                    <Text variant="support1" color="text-low-emphasis">
                      {t('Kontoägare')}
                    </Text>
                  </Box>
                  <Box overflow="hidden" css={{ textOverflow: 'ellipsis' }}>
                    <Tooltip title={selectedHoldingAccount?.information?.owners?.[0] ?? ''}>
                      <Text variant="subtitle2">{selectedHoldingAccount?.information?.owners?.[0]}</Text>
                    </Tooltip>
                  </Box>
                </FlexRow>
              </ListItemWithAccordion>
            ) : (
              <ListItem compact divider>
                {/* We don't use <ListItemRow> here because it handles truncation between title and value poorly */}
                <FlexRow justifyContent="space-between" space={4} css={{ whiteSpace: 'nowrap' }}>
                  <Box>
                    <Text variant="support1" color="text-low-emphasis">
                      {t('Kontoägare')}
                    </Text>
                  </Box>
                  <Box overflow="hidden" css={{ textOverflow: 'ellipsis' }}>
                    <Tooltip title={selectedHoldingAccount?.information?.owners?.[0] ?? ''}>
                      <Text variant="subtitle2">{selectedHoldingAccount?.information?.owners?.[0]}</Text>
                    </Tooltip>
                  </Box>
                </FlexRow>
              </ListItem>
            )
          ) : (
            <ListItem compact divider>
              <ListItemRow title={<SkeletonText width={100} />} value={<SkeletonText width={100} />} />
            </ListItem>
          )}

          {selectedHoldingAccount ? (
            <ListItem compact divider>
              <ListItemRow
                title={
                  <Text variant="support1" color="text-low-emphasis">
                    {t('Din roll')}
                  </Text>
                }
                value={
                  <Text variant="subtitle2" mr={4}>
                    {t(selectedHoldingAccount?.information?.accountRole ?? '')}
                  </Text>
                }
              />
            </ListItem>
          ) : (
            <ListItem compact divider>
              <ListItemRow title={<SkeletonText width={100} />} value={<SkeletonText width={100} />} />
            </ListItem>
          )}

          {selectedHoldingAccount ? (
            <ListItem compact divider>
              <ListItemRow
                title={
                  <Text variant="support1" color="text-low-emphasis">
                    {t('Tjänst')}
                  </Text>
                }
                value={
                  <Text variant="subtitle2" mr={4}>
                    {t(selectedHoldingAccount?.information?.serviceType ?? '')}
                  </Text>
                }
              />
            </ListItem>
          ) : (
            <ListItem compact divider>
              <ListItemRow title={<SkeletonText width={100} />} value={<SkeletonText width={100} />} />
            </ListItem>
          )}

          {selectedHoldingAccount ? (
            policyHolderAccountTypes.includes(selectedHoldingAccount?.information?.accountType?.shortName ?? '') ? (
              <>
                <ListItem>
                  <ListItemRow title={<Text variant="subtitle2">{t('Försäkringsinformation')}</Text>} />
                </ListItem>
                <ListItem compact divider>
                  <ListItemRow
                    title={
                      <Text variant="support1" color="text-low-emphasis">
                        {t('Försäkringstyp')}
                      </Text>
                    }
                    value={
                      <Text variant="subtitle2" mr={4}>
                        {t(selectedHoldingAccount?.information?.accountType?.insuranceType)}
                      </Text>
                    }
                  />
                </ListItem>
                <ListItem compact divider>
                  <ListItemRow
                    title={
                      <Text variant="support1" color="text-low-emphasis">
                        {t('Försäkringstagare')}
                      </Text>
                    }
                    value={
                      <Text variant="subtitle2" mr={4}>
                        {selectedHoldingAccount?.information?.policyHolder}
                      </Text>
                    }
                  />
                </ListItem>
                <ListItem compact divider>
                  <ListItemRow
                    title={
                      <Text variant="support1" color="text-low-emphasis">
                        {t('Försäkringsnummer')}
                      </Text>
                    }
                    value={
                      <Text variant="subtitle2" mr={4}>
                        {selectedHoldingAccount?.information?.accountDescription}
                      </Text>
                    }
                  />
                </ListItem>
              </>
            ) : null
          ) : (
            <>
              <ListItem>
                <ListItemRow title={<SkeletonText width={100} />} value={<SkeletonText width={100} />} />
              </ListItem>
              <ListItem compact divider>
                <ListItemRow title={<SkeletonText width={100} />} value={<SkeletonText width={100} />} />
              </ListItem>
              <ListItem compact divider>
                <ListItemRow title={<SkeletonText width={100} />} value={<SkeletonText width={100} />} />
              </ListItem>
              <ListItem compact divider>
                <ListItemRow title={<SkeletonText width={100} />} value={<SkeletonText width={100} />} />
              </ListItem>
            </>
          )}

          {selectedHoldingAccount && selectedHoldingAccount.accountInterestRates && (
            <ListItem>
              <ListItemRow title={<Text variant="subtitle2">{t('Kontoränta')}</Text>} />
            </ListItem>
          )}
          {selectedHoldingAccount &&
            selectedHoldingAccount.accountInterestRates.map((row) => {
              return (
                <ListItemWithAccordion
                  key={row.currencyCode}
                  compact
                  defaultOpen={row.currencyCode === 'SEK'}
                  expandableContent={
                    <>
                      <ListItem compact>
                        <ListItemRow
                          title={
                            <Text variant="support1" color="text-low-emphasis">
                              {t('Ränta kredit')}
                            </Text>
                          }
                          value={
                            <FormattedPercentage
                              value={row.interestRateCredit}
                              justifyContent={'flex-end'}
                              decimals={2}
                              variant="subtitle2"
                              mr={4}
                              showZeroAsFallbackValue
                            />
                          }
                        />
                      </ListItem>
                      <ListItem compact>
                        <ListItemRow
                          title={
                            <Text variant="support1" color="text-low-emphasis">
                              {t('Ränta debet')}
                            </Text>
                          }
                          value={
                            <FormattedPercentage
                              value={row.interestRateDebit}
                              justifyContent={'flex-end'}
                              decimals={2}
                              variant="subtitle2"
                              mr={4}
                              showZeroAsFallbackValue
                            />
                          }
                        />
                      </ListItem>
                    </>
                  }
                >
                  <FlexRow alignItems="baseline">
                    <Box pr={4} flexShrink={0}>
                      <CurrencyFlag currency={row.currencyCode} size={16} />
                    </Box>
                    <Text variant="support1" color="text-low-emphasis">
                      {row.currencyCode}
                    </Text>
                  </FlexRow>
                </ListItemWithAccordion>
              )
            })}

          <ListItem>
            <ListItemRow title={<Text variant="subtitle2">{t('Kontoansvarig')}</Text>} />
          </ListItem>

          {selectedHoldingAccount ? (
            selectedHoldingAccount?.information?.accountResponsibles?.map((responsible, index) => {
              let phoneNumber = ''
              if (responsible.organizationContactInfo) {
                phoneNumber = responsible.organizationContactInfo.phoneNumbers?.sort((x) =>
                  x?.isPreferred ? -1 : 1
                )[0]?.number
              } else {
                phoneNumber = responsible.phoneNumber
              }
              return (
                <ListItem
                  key={index}
                  divider
                  support={
                    <Box width={40} height={40}>
                      <EmployeeAvatar partyId={responsible.staffPartyId} />
                    </Box>
                  }
                >
                  <ListItemRow title={<Text variant="input2">{responsible.name}</Text>} />
                  <ListItemRow
                    title={
                      <LinkExternal
                        style={{
                          lineHeight: '16px',
                        }}
                        href={`tel:${phoneNumber}`}
                      >
                        <Text variant="support1" color="text-low-emphasis">
                          {formatPhoneNumber(phoneNumber)}
                        </Text>
                      </LinkExternal>
                    }
                  />
                </ListItem>
              )
            })
          ) : (
            <>
              <ListItem compact divider support={<SkeletonCircle width="100%" height="100%" />}>
                <ListItemRow title={<SkeletonText width={100} />} />
                <ListItemRow title={<SkeletonText width={100} />} />
              </ListItem>
              <ListItem compact divider support={<SkeletonCircle width="100%" height="100%" />}>
                <ListItemRow title={<SkeletonText width={100} />} />
                <ListItemRow title={<SkeletonText width={100} />} />
              </ListItem>
            </>
          )}
        </Box>
      }
    />
  )

  return (
    <>
      <FlexRow space={8}>
        {!isExtraSmallScreen && (
          <>
            <Button
              variant="tertiary"
              size="medium"
              onClick={() => setCreateReportDrawer(!openCreateReportDrawer)}
              width={'auto'}
              startIcon={<ButtonIcon icon={IconDocumentLineChart} />}
            >
              {t('Skapa rapport')}
            </Button>
            <Button
              variant="tertiary"
              size="medium"
              width={'auto'}
              onClick={() => {
                fireTrackEvent('Accounts', 'account_click_transfers')
                openTransfersDrawer({ defaultAccount: selectedAccount.id })
              }}
              startIcon={<ButtonIcon icon={IconInternalTransfer} />}
            >
              {t('Gör överföring')}
            </Button>
          </>
        )}
        {accountInformationSideDrawer}
        <Menu id="duplo-menu-default" popperPlacementType="bottom-start">
          <MenuToggle>
            {isExtraSmallScreen ? (
              <IconButton variant="contained" icon={IconMore} size="medium" />
            ) : (
              <Button variant="secondary" size="medium" width="auto" endIcon={<Icon icon={IconMore} />}>
                {t('Fler val')}
              </Button>
            )}
          </MenuToggle>
          <MenuItems>
            {isExtraSmallScreen ? (
              <>
                <MenuItem
                  key="create"
                  onClick={() => setCreateReportDrawer(!openCreateReportDrawer)}
                  icon={IconDocumentLineChartAdd}
                >
                  {t('Skapa rapport')}
                </MenuItem>
                <MenuItem
                  key="information"
                  onClick={() => {
                    fireTrackEvent('Accounts', 'account_click_transfers')
                    openTransfersDrawer({ defaultAccount: selectedAccount.id })
                  }}
                  icon={IconInternalTransfer}
                >
                  {t('Gör överföring')}
                </MenuItem>
                <MenuDivider />
              </>
            ) : null}
            <MenuItem
              key="transactions"
              onClick={() => navigate(`/overview/transactions/?accountid=${selectedAccount?.id}`)}
              icon={IconTransaction}
            >
              {t('Transaktioner')}
            </MenuItem>
            {auth.hasLimitedView || (
              <MenuItem
                key="order"
                icon={IconOrder}
                onClick={() => navigate(`/overview/orders/${selectedAccount?.id}`)}
              >
                {t('Ordrar')}
              </MenuItem>
            )}
            <MenuItem
              key="reports"
              onClick={() => navigate(`/profile/reports/archive/${selectedAccount?.id}`)}
              icon={IconDocumentLineChart}
            >
              {t('Rapporter')}
            </MenuItem>
            <MenuItem
              key="statements"
              onClick={() => navigate(`/profile/reports/statement/${selectedAccount?.id}`)}
              icon={IconDocumentLineChart}
            >
              {t('Kontobesked')}
            </MenuItem>
            <MenuDivider />
            <MenuItem
              key="deposit"
              onClick={() => {
                fireTrackEvent('Accounts', 'account_click_deposit')
                openTransfersDrawer({ defaultAccount: selectedAccount.id })
              }}
              icon={IconDeposit}
            >
              {t('Insättning')}
            </MenuItem>
            <MenuItem
              key="internalTransfer"
              onClick={() => {
                fireTrackEvent('Accounts', 'account_click_internaltransfers')
                openTransfersDrawer({
                  defaultTab: TransferDrawerTabs.INTERNAL_DEPOSIT,
                  defaultAccount: selectedAccount.id,
                })
              }}
              icon={IconInternalTransfer}
            >
              {t('Intern överföring')}
            </MenuItem>
            <MenuItem
              key="withdrawal"
              onClick={() => {
                fireTrackEvent('Accounts', 'account_click_withdrawals')
                openTransfersDrawer({ defaultTab: TransferDrawerTabs.WITHDRAWAL, defaultAccount: selectedAccount.id })
              }}
              icon={IconWithdrawal}
            >
              {t('Uttag')}
            </MenuItem>
            <MenuDivider />
            <MenuItem
              key="offerings"
              onClick={() => {
                navigate('/offerings')
              }}
              icon={<IconOffering />}
            >
              {t('Erbjudanden')}
            </MenuItem>
            <MenuDivider />
            <MenuItem key="information" onClick={() => setOpenAccountInformation(true)} icon={IconInfoOutlined}>
              {t('Kontoinformation')}
            </MenuItem>
            <ToggleFavoriteMenuItem selectedAccountId={selectedAccount?.id} />
          </MenuItems>
        </Menu>
        {accountInformationSideDrawer}
        <SideDrawer
          title={t('Skapa rapport')}
          variant="slide"
          sidebarContent={<CreateReportSideDrawer initialAccount={selectedAccount} />}
          open={openCreateReportDrawer}
          onClose={() => setCreateReportDrawer(false)}
        />
      </FlexRow>
    </>
  )
})
