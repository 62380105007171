import { MarketingEmailModelWrapper } from '@common/api/response'
import { useFetchSubscription } from '@common/hooks/useSubscriptions'

import { useMemo, useRef } from 'react'

import { formatDate } from '@carnegie/digital-channels-frontend'
import { CSSGrid, GridBox, Text } from '@carnegie/duplo'

import { RelatedInstrumentsCombined } from '@/pages/news/RelatedInstrumentsCombined'

import useCarnegieInstrumentsFromCarnegieIds from './getInfrontInstrumentFromCarnegieId'
import { useSubscriptionDrawerProps } from './useOpenSubscriptionDrawer'

export type SubscriptionDrawerProps = Pick<MarketingEmailModelWrapper, 'marketingEmailId' | 'subscriptionType'>

const SubscriptionDrawer = () => {
  const { subscriptionType, marketingEmailId } = useSubscriptionDrawerProps()
  const { subscription } = useFetchSubscription(marketingEmailId, subscriptionType)
  const { body, publishedDate, instruments } = subscription ?? {}

  const carnegieIds = useMemo(() => instruments?.map((instrument) => instrument.carnegieId), [instruments])
  const { carnegieInstruments } = useCarnegieInstrumentsFromCarnegieIds(carnegieIds)
  const relatedInstruments = carnegieInstruments
    ?.map((carnegieInstrument) => carnegieInstrument.infrontInstrument)
    .filter(({ feed, ticker }) => feed !== 0 && !!ticker)

  const iframe = useRef<HTMLIFrameElement>(null)

  return subscription ? (
    <CSSGrid rows={'auto 1fr auto'} height="full" gap={16} cols={1}>
      <GridBox>
        <Text width="100%" variant="overline" truncate color={'text-low-emphasis'}>
          {formatDate(publishedDate, 'HH:mm/yesterday HH:mm/yyyy-MM-dd')}
        </Text>
        {relatedInstruments && relatedInstruments.length ? (
          <RelatedInstrumentsCombined instruments={relatedInstruments} />
        ) : null}
      </GridBox>
      <GridBox>
        <iframe ref={iframe} style={{ height: '100%', width: '100%' }} title="test" srcDoc={body}></iframe>
      </GridBox>
    </CSSGrid>
  ) : null
}
SubscriptionDrawer.displayName = 'SubscriptionDrawer'
export default SubscriptionDrawer
