import { useTranslation } from 'react-i18next'

import { Tooltip } from '@carnegie/duplo'

export const NoDataDash = () => {
  const { t } = useTranslation()

  return (
    <Tooltip title={t('Ingen data tillgänglig')}>
      <span>-</span>
    </Tooltip>
  )
}

type NoDataFallbackProps = {
  children?: number | string
}

/**
 * Shows a dash ("-") + tooltip for data that is undefined, null or "-"
 * @param param0
 * @returns
 */
export const NoDataFallback = ({ children }: NoDataFallbackProps) => {
  // We also need to handle the case of getting "-" because some of our formatting functions passes a simple dash back and we
  // want to add the tooltip "No data available" in these cases
  if (children === undefined || children === null || children === '-' || children.toString() === 'Invalid Date')
    return <NoDataDash />

  return <>{children}</>
}
