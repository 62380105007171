import * as signalR from '@microsoft/signalr'
import { HubConnection } from '@microsoft/signalr'

import { useEffect, useRef } from 'react'

export function useWebSocket(onMessage: WebSocketCallback) {
  const connectionRef = useRef<HubConnection>(undefined)

  const onMessageRef = useRef<WebSocketCallback>()
  onMessageRef.current = onMessage

  useEffect(() => {
    const newConnection = new signalR.HubConnectionBuilder().withUrl('/api/socket').withAutomaticReconnect().build()

    // Not sure if needed, it should probably not be garbage collected etc but in case...
    connectionRef.current = newConnection

    newConnection.start().catch((error) => console.error('SignalR error', error))

    newConnection.on('message', (data: MessageBody) => {
      // We convert to a format that looks more JavaScript like (camelCase etc)
      onMessageRef.current({ type: data.Type, error: data.Error, payload: data.Payload })
    })

    return () => {
      newConnection.off('message')
      newConnection.stop()
    }
  }, [onMessage])
}

// From the .NET side
type MessageBody = {
  Type: string
  Error?: string
  Payload?: unknown
}

export type WebSocketMessage = {
  type: string
  error: string
  payload: unknown
}

export type WebSocketCallback = (message: WebSocketMessage) => void
