import {
  EnvelopeItemDescriptorOfVideoResponseModel,
  PaginationResponseOfVideoResponseModel,
} from '@common/api/response'

import { useHttpSwr } from './useHttpSwr'

export function useSeminar(id: string) {
  const { data, error } = useHttpSwr<EnvelopeItemDescriptorOfVideoResponseModel>(`/content/seminar/${id}`)
  return {
    seminar: data?.item,
    error,
  }
}

export function useSeminars({ limit, page }: { limit?: number; page?: number }) {
  const { data, error } = useHttpSwr<PaginationResponseOfVideoResponseModel>(
    `/content/seminars?limit=${limit}&page=${page}`
  )

  return {
    currentPage: data?.currentPage,
    error,
    items: data?.items,
    totalPages: data?.totalPages,
    totalRows: data?.totalRows,
    usingLimit: data?.usingLimit,
  }
}
