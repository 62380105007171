import { InstrumentPosition } from '@common/api/response'
import { SkeletonLoader } from '@common/components/SkeletonLoader'
import { SmartTable } from '@common/components/SmartTable'
import { useSelectedHoldingAccount } from '@common/hooks/useHoldings'
import { sum } from '@common/utils/sum'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Box, Breakpoint, useBreakpoint } from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import { ObservableTableInstrument, useTableInstruments } from '../useTableInstruments'
import { TableAboutFooter, TableAboutMobileFooter } from './TableAboutFooter'
import { TableAboutMobile } from './TableAboutMobile'
import { TableAboutRow } from './TableAboutRow'

type TableAboutProps = {
  isFund: boolean
  holdingsInstruments: InstrumentPosition[]
  showPercentage: boolean
  tableSortStorageKey: string
}

export type TableAboutColumnId = 'expander-button' | 'name' | 'typeName' | 'allocation' | 'region' | 'weight' | 'button'

export const TableAbout = observer(
  ({ isFund, holdingsInstruments, showPercentage, tableSortStorageKey }: TableAboutProps) => {
    const { t } = useTranslation()
    const breakpoint = useBreakpoint()
    const isExtraSmallScreen = breakpoint <= Breakpoint.Xs

    const { instruments } = useTableInstruments(holdingsInstruments)
    const { account: selectedAccount } = useSelectedHoldingAccount()
    const navigate = useNavigate()

    const totalWeight = sum('weight', instruments ? instruments : [])

    const navigateToTransactions = (instrument: ObservableTableInstrument) => {
      navigate(
        `/overview/transactions/?accountid=${selectedAccount?.id}&instruments=${JSON.stringify([
          instrument.key,
        ])}&from=2005-01-01`
      )
    }

    const showExpanderColumn = instruments?.some((instrument) => instrument.hasDetails)

    return (
      <Box>
        <SkeletonLoader p={16} height={300} loading={!instruments} noDataLoaded={instruments?.length === 0}>
          {() => (
            <SmartTable<ObservableTableInstrument, TableAboutColumnId>
              sessionStorageKey={tableSortStorageKey}
              data={instruments}
              defaultSortBy="name"
              columns={[
                {
                  id: 'expander-button',
                  visible: showExpanderColumn,
                  width: 'auto',
                },
                {
                  id: 'name',
                  width: 'minmax(0,2fr)',
                  renderHeader: () => t('Namn'),
                  sortBy: ({ row }) => row.name,
                },
                {
                  align: 'right',
                  id: 'typeName',
                  width: '1fr',
                  renderHeader: () => t('Värdepapperstyp'),
                  sortBy: ({ row }) => row.typeName,
                },
                {
                  align: 'right',
                  id: 'allocation',
                  width: '1fr',
                  renderHeader: () => t('Tillgångsslag'),
                  sortBy: ({ row }) => row.allocation,
                },
                {
                  align: 'right',
                  id: 'region',
                  width: '1fr',
                  renderHeader: () => t('Region'),
                  sortBy: ({ row }) => row.region,
                },
                {
                  align: 'right',
                  id: 'weight',
                  width: '1fr',
                  renderHeader: () => t('Andel'),
                  sortBy: ({ row }) => row.weight,
                },
                {
                  id: 'button',
                  width: 48,
                },
              ]}
              renderMode={isExtraSmallScreen ? 'custom' : 'table'}
              renderRow={({ row: instrument, columns }) =>
                isExtraSmallScreen ? (
                  <TableAboutMobile
                    isFund={isFund}
                    key={instrument.instrumentIdCarnegie}
                    instrument={instrument}
                    showPercentage={showPercentage}
                    transactionOnClick={() => navigateToTransactions(instrument)}
                  />
                ) : (
                  <TableAboutRow
                    isFund={isFund}
                    columns={columns}
                    key={instrument.instrumentIdCarnegie}
                    instrument={instrument}
                    transactionOnClick={() => navigateToTransactions(instrument)}
                  />
                )
              }
              renderFooterRow={({ columns }) =>
                isExtraSmallScreen ? (
                  <TableAboutMobileFooter totalWeight={totalWeight} />
                ) : (
                  <TableAboutFooter columns={columns} totalWeight={totalWeight} />
                )
              }
            />
          )}
        </SkeletonLoader>
      </Box>
    )
  }
)
