import { FormattedNumber } from '@common/components/FormattedNumber'
import { getValueColor } from '@common/utils/colorUtils'

import { useTranslation } from 'react-i18next'

import { formatNumber } from '@carnegie/digital-channels-frontend'
import { FlexRow, Td, Text, Tr, useDuploTheme } from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import { TableOverviewColumnId } from './TableOverview'

type TableOverviewFooterProps = {
  showMoreColumns: boolean
  showPercentage: boolean
  totalAcquisitionCost: number
  totalPerformanceToday: number
  totalMarketValues: number
  totalUnrealized: number
  columns: { id?: TableOverviewColumnId }[]
}

export const TableOverviewMobileFooter = observer(
  ({
    totalPerformanceToday,
    totalMarketValues,
    totalUnrealized,
    showPercentage,
  }: {
    totalPerformanceToday: number
    totalMarketValues: number
    totalUnrealized: number
    showPercentage: boolean
  }) => {
    const theme = useDuploTheme()
    const { t } = useTranslation()

    return (
      <>
        {!showPercentage && (
          <>
            <FlexRow
              p={16}
              css={{ borderTop: `1px solid ${theme.colors['bunker-60']}` }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Text variant="support2">{t('Total utveckling idag')}</Text>
              <FlexRow justifyContent="space-between" alignItems="baseline">
                <Text variant="subtitle1" color={getValueColor(totalPerformanceToday)}>
                  {formatNumber(totalPerformanceToday, { decimals: 0 })}
                </Text>
                <Text variant="label1" ml={2} color={getValueColor(totalPerformanceToday)}>
                  {'SEK'}
                </Text>
              </FlexRow>
            </FlexRow>
          </>
        )}
        <FlexRow
          p={16}
          css={{ borderTop: `1px solid ${theme.colors['bunker-60']}` }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Text variant="support2">{t('Totalt marknadsvärde')}</Text>
          <FlexRow justifyContent="space-between" alignItems="baseline">
            <Text variant="subtitle1">{formatNumber(totalMarketValues, { decimals: 0 })}</Text>
            <Text variant="label1" ml={2} color="bunker-main">
              {'SEK'}
            </Text>
          </FlexRow>
        </FlexRow>
        {!showPercentage && (
          <FlexRow
            p={16}
            css={{ borderTop: `1px solid ${theme.colors['bunker-60']}` }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Text variant="support2">{t('Totalt orealiserat')}</Text>
            <FlexRow justifyContent="space-between" alignItems="baseline">
              <Text variant="subtitle1" color={getValueColor(totalUnrealized)}>
                {formatNumber(totalUnrealized, { decimals: 0 })}
              </Text>
              <Text variant="label1" ml={2} color={getValueColor(totalUnrealized)}>
                {'SEK'}
              </Text>
            </FlexRow>
          </FlexRow>
        )}
      </>
    )
  }
)

export const TableOverviewFooter = observer(
  ({
    totalPerformanceToday,
    totalMarketValues,
    totalUnrealized,
    showPercentage,
    showMoreColumns,
    totalAcquisitionCost,
    columns,
  }: TableOverviewFooterProps) => {
    const theme = useDuploTheme()
    const { t } = useTranslation()
    const hasExpanderColumn = columns.some((columnDef) => columnDef.id === 'expander-button')

    return (
      <tfoot css={{ borderTop: `1px solid ${theme.colors['bunker-60']}` }}>
        <Tr>
          {hasExpanderColumn && <Td />}
          <Td textAlign="left" css={{ fontWeight: '500' }}>
            {t('Total')}
          </Td>
          <Td textAlign="right"></Td>
          {!showMoreColumns && showPercentage && <Td textAlign="right"></Td>}
          {(showMoreColumns || !showPercentage) && (
            <Td textAlign="right">
              <FormattedNumber
                bold
                decimals={0}
                justifyContent="flex-end"
                unit="SEK"
                value={totalPerformanceToday}
                colorized
              />
            </Td>
          )}
          <Td textAlign="right"></Td>
          {showMoreColumns && <Td textAlign="right"></Td>}
          <Td textAlign="right">
            <FormattedNumber decimals={0} justifyContent="flex-end" unit="SEK" bold value={totalMarketValues} />
          </Td>
          {showMoreColumns ? (
            <Td textAlign="right">
              <FormattedNumber
                decimals={0}
                justifyContent="flex-end"
                unit="SEK"
                bold
                value={totalUnrealized}
                colorized
              />
            </Td>
          ) : (
            <Td textAlign="right">
              {!showPercentage && (
                <FormattedNumber
                  decimals={0}
                  justifyContent="flex-end"
                  unit="SEK"
                  bold
                  value={totalUnrealized}
                  colorized
                />
              )}
            </Td>
          )}
          {showMoreColumns && <Td textAlign="right"></Td>}
          {showMoreColumns && <Td textAlign="right"></Td>}
          {showMoreColumns && (
            <Td textAlign="right">
              <FormattedNumber decimals={0} bold justifyContent="flex-end" unit="SEK" value={totalAcquisitionCost} />
            </Td>
          )}
          <Td textAlign="right"></Td>
        </Tr>
      </tfoot>
    )
  }
)
