import { AccountSummary } from '@common/api/response'
import { FormattedNumber } from '@common/components/FormattedNumber'
import { SkeletonLoader } from '@common/components/SkeletonLoader'
import { SmartTable } from '@common/components/SmartTable'
import { useSelectedHoldingAccount } from '@common/hooks/useHoldings'
import { createValidInstrumentIdContainer } from '@common/instrumentIdContainer'

import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { formatNumber } from '@carnegie/digital-channels-frontend'
import {
  Box,
  Breakpoint,
  DataCell,
  FlexCol,
  IconInfoOutlined,
  Link,
  Paragraph,
  SideDrawer,
  Td,
  Tr,
  useBreakpoint,
} from '@carnegie/duplo'

import { useOpenInstrumentDrawer } from '@/drawers/instrumentDrawer/useOpenInstrumentDrawer'

import { InstrumentName } from '../../holdingsTable/InstrumentName'
import { ObservableTableInstrument, useTableInstruments } from '../../holdingsTable/useTableInstruments'

type Props = {
  summary: AccountSummary
}

const STORAGE_KEY = 'MARGIN_REQUIREMENT'

const MarginRequirement = ({ summary }: Props) => {
  const { t } = useTranslation()
  const [openAcquisitionSlide, setOpenAcquisitionSlide] = useState(false)
  const { account } = useSelectedHoldingAccount()
  const { instruments, error } = useTableInstruments(account?.holdings?.instruments)
  const filteredInstruments = instruments?.filter(({ marginRequirements }) => marginRequirements.amount !== null)
  const { openInstrumentDrawer } = useOpenInstrumentDrawer()
  const breakpoint = useBreakpoint()
  const isExtraSmallScreen = breakpoint < Breakpoint.Small

  const showMarginRequirementDisclaimer =
    Math.round(summary?.marginRequirements?.amount) !==
    Math.round(filteredInstruments?.reduce((acc, instrument) => acc + instrument.marginRequirements.amount, 0))

  return (
    <FlexCol flex={1} mr={isExtraSmallScreen ? 0 : 16}>
      <DataCell
        onClick={() => setOpenAcquisitionSlide(true)}
        icon={IconInfoOutlined}
        label={t('Säkerhetskrav')}
        suffix={summary.marginRequirements?.currencyCode}
      >
        {formatNumber(summary.marginRequirements?.amount, { decimals: 0 })}
      </DataCell>
      <SideDrawer
        title={t('Säkerhetskrav')}
        variant="slide"
        sidebarContentPadding={0}
        sidebarContent={
          <SkeletonLoader
            variant="list"
            height={128}
            p={16}
            error={error}
            loading={!instruments}
            noDataLoaded={instruments && filteredInstruments.length === 0}
            noDataComponent={
              <Box>
                <Paragraph variant="body2">
                  {t('Säkerhetskravet kommer från innehav som finns på ett annat konto.')}
                  &nbsp;
                  <Link variant="body2" to="/profile/contact">
                    {t('Kontakta oss')}
                  </Link>
                  &nbsp;
                  {t('om du har några frågor.')}
                </Paragraph>
              </Box>
            }
          >
            {() => (
              <FlexCol>
                <SmartTable<ObservableTableInstrument, 'instrument' | 'amount'>
                  data={filteredInstruments}
                  sessionStorageKey={STORAGE_KEY}
                  rowKey={(row) => row.name}
                  defaultSortBy="amount"
                  renderRow={({ row }) => {
                    return (
                      <Tr>
                        <Td>
                          <InstrumentName
                            showFlag={row.carnegieInstrumentGroup !== 'MutualFunds'}
                            name={row.name}
                            isoCountryCode={row.marketIso}
                            onClick={() => {
                              openInstrumentDrawer(createValidInstrumentIdContainer(row))
                            }}
                          />
                        </Td>
                        <Td>
                          <Paragraph variant="body2">
                            <FormattedNumber
                              value={row.marginRequirements.amount}
                              unit={row.marginRequirements.currencyCode}
                              decimals={0}
                            />
                          </Paragraph>
                        </Td>
                      </Tr>
                    )
                  }}
                  columns={[
                    {
                      id: 'instrument',
                      width: 'minmax(0,2fr)',
                      renderHeader: () => t('Namn'),
                      sortBy: ({ row }) => row.name,
                    },
                    {
                      id: 'amount',
                      width: '1fr',
                      align: 'right',
                      defaultSortOrder: 'desc',
                      renderHeader: () => t('Säkerhetskrav'),
                      sortBy: ({ row }) => row.marginRequirements.amount,
                    },
                  ]}
                />
                {showMarginRequirementDisclaimer && (
                  <Box p={16}>
                    <Paragraph variant="body2">
                      {t(
                        'Summeringen av säkerhetskrav för innehaven kan avvika från totalsumman för kontot om det finns ytterligare säkerhetskrav från innehav på annat konto.'
                      )}
                      &nbsp;
                      <Link variant="body2" to="/profile/contact">
                        {t('Kontakta oss')}
                      </Link>
                      &nbsp;
                      {t('om du har några frågor.')}
                    </Paragraph>
                  </Box>
                )}
              </FlexCol>
            )}
          </SkeletonLoader>
        }
        open={openAcquisitionSlide}
        onClose={() => setOpenAcquisitionSlide(false)}
      />
    </FlexCol>
  )
}

export default MarginRequirement
