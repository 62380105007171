import {
  AccountsResponse,
  CarnegieInstrument,
  FundOrderInfoBuy,
  FundOrderInfoSell,
  FundOrderType,
  InsertSimpleOrderResponse,
  Side,
} from '@common/api/response'
import { AccountSelect } from '@common/components/AccountSelect'
import { ExchangeCurrencyInfo } from '@common/components/ExchangeCurrencyInfo'
import { FormattedNumber } from '@common/components/FormattedNumber'
import { fireTrackEvent } from '@common/utils/analyticsEvent'

import { useEffect, useState } from 'react'
import { TFunction, useTranslation } from 'react-i18next'

import { formatNumber } from '@carnegie/digital-channels-frontend'
import {
  Banner,
  Box,
  Button,
  FilterChip,
  FlexCol,
  FlexRow,
  Heading3,
  IconButton,
  IconInfoOutlined,
  Input,
  Popper,
  Segment,
  SkeletonText,
  Spacer,
  Text,
} from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import { FundOrderSent } from './FundOrderSent'
import { useFundOrderInfoBuy, useFundOrderInfoSell } from './hooks/useFundOrderInfo'
import { useFundOrderSubmit } from './hooks/useFundOrderSubmit'
import { useFundOrderValidation } from './hooks/useFundOrderValidation'

type FundOrderEntryProps = {
  renderForApp: boolean
  selectedAccount: AccountsResponse
  onSelectAccount: (accountId: string) => void
  carnegieInstrument: CarnegieInstrument
  fundOrderType: FundOrderType
}

export const FundOrderEntry = observer(
  ({ renderForApp, selectedAccount, onSelectAccount, carnegieInstrument, fundOrderType }: FundOrderEntryProps) => {
    const [orderSent, setOrderSent] = useState(false)
    const [insertOrderResult, setInsertOrderResult] = useState<InsertSimpleOrderResponse | Error>()
    const [value, setValue] = useState('')
    const numericValue = value === '' ? undefined : parseFloat(value)

    const { t } = useTranslation()
    const accountSelected = !!selectedAccount

    useEffect(() => {
      // When selected account changes reset the input field
      setValue('')
    }, [selectedAccount?.id])

    const { fundOrderInfo: fundOrderInfoBuy, error: fundOrderInfoBuyError } = useFundOrderInfoBuy(
      fundOrderType === 'buy' && selectedAccount?.id,
      fundOrderType === 'buy' && carnegieInstrument?.instrumentIdCarnegie
    )

    const { fundOrderInfo: fundOrderInfoSell, error: fundOrderInfoSellError } = useFundOrderInfoSell(
      fundOrderType === 'sell' && selectedAccount?.id,
      fundOrderType === 'sell' && carnegieInstrument?.instrumentIdCarnegie
    )

    const fundOrderCriticalError = fundOrderInfoSellError || fundOrderInfoBuyError

    const loadingFundOrderInfo =
      (fundOrderType === 'buy' && fundOrderInfoBuy === undefined) ||
      (fundOrderType === 'sell' && fundOrderInfoSell === undefined)

    const sellAll =
      fundOrderType === 'sell' && fundOrderInfoSell
        ? fundOrderInfoSell?.maximumAmount === numericValue && numericValue > 0
        : false

    const totalAmount = getTotalAmount(numericValue, fundOrderType, fundOrderInfoBuy, fundOrderInfoSell)
    const availableAmount = fundOrderType === 'buy' ? fundOrderInfoBuy?.tradingCapacity : fundOrderInfoSell?.netAmount

    const isSellAllChipVisible = fundOrderType === 'sell' && fundOrderInfoSell && availableAmount > 0

    // "Belopp" label
    const amountLabel = getAmountLabel(fundOrderType, sellAll, t)

    // Label on top / inside input field
    const inputLabel = getInputLabel(fundOrderType, sellAll, t)

    const { inputError, inputErrorDebounced } = useFundOrderValidation({
      value: numericValue,
      sellAll,
      fundOrderType,
      fundOrderInfoBuy,
      fundOrderInfoSell,
    })

    const { submitOrder } = useFundOrderSubmit({
      selectedAccount,
      carnegieInstrument,
      fundOrderType,
    })

    return (
      <Box>
        {fundOrderCriticalError && (
          <Banner
            mb={32}
            severity="critical"
            title={t('Problem med fondorder')}
            description={t('Just nu går det inte lägga fondorder. Vänligen försök igen senare.')}
          />
        )}
        <Segment variant="contained" headingVariant="overline">
          {orderSent ? (
            <FundOrderSent
              selectedAccount={selectedAccount}
              insertOrderResult={insertOrderResult}
              renderForApp={renderForApp}
              totalAmount={totalAmount}
              amountLabel={amountLabel}
              fundOrderType={fundOrderType}
            />
          ) : (
            <FlexCol>
              <AccountSelect
                id="fund-order-entry-account-select"
                onSelectAccount={onSelectAccount}
                selectedAccount={selectedAccount}
                splitByTradable
              />
              {accountSelected && !fundOrderCriticalError && (
                <>
                  <Spacer height={16} />
                  <FlexRow alignItems="baseline">
                    <Text variant="input2" mr={4}>
                      {`${fundOrderType === 'buy' ? t('Tillgängligt för köp') : t('Tillgängligt för sälj')}:`}
                    </Text>
                    <Text variant="body1" data-testid="fund-order-entry-available-amount-label">
                      {!loadingFundOrderInfo ? (
                        availableAmount > 0 ? (
                          <>
                            {formatNumber(availableAmount, {
                              decimals: { min: 0, max: 5 },
                            })}
                            <Text ml={4} color="text-default" variant="label1">
                              SEK
                            </Text>
                          </>
                        ) : (
                          '-'
                        )
                      ) : (
                        <SkeletonText width={100} />
                      )}
                    </Text>
                  </FlexRow>
                  {fundOrderType === 'sell' && (
                    <>
                      <Spacer height={8} />
                      <Text variant="support1" color="text-low-emphasis">
                        {t('Motsvarar marknadsvärdet utifrån senast kända kurs.')}
                      </Text>
                    </>
                  )}
                  <Spacer height={16} />
                  <Input
                    type="text"
                    inputFormat={{
                      type: 'numeric',
                      allowedDecimals: fundOrderType === 'buy' ? 0 : 2,
                      thousandSeparator: true,
                    }}
                    size="medium"
                    autoComplete="off"
                    value={value}
                    label={inputLabel}
                    width="100%"
                    onChange={(event) => {
                      setValue(event.target.value)
                    }}
                    // This basically means that if we do not have an error we remove any error text instantly,
                    // however if an error has occurred we wait 500ms before displaying it giving the user time to complete typing
                    error={inputError ? !!inputErrorDebounced : false}
                    helperText={inputError ? inputErrorDebounced : false}
                  />
                  <Spacer height={16} />
                  <Box>
                    {isSellAllChipVisible && (
                      <>
                        <FilterChip
                          onClick={() => {
                            if (fundOrderType === 'sell') {
                              setValue(fundOrderInfoSell.maximumAmount.toString())
                            }
                          }}
                          selected={sellAll}
                        >
                          {t('Sälj allt')}
                        </FilterChip>
                        <Spacer height={16} />
                      </>
                    )}
                  </Box>
                  <FlexRow width="full" alignItems="baseline">
                    <Text variant="input2">{t('Administrativ avgift')}</Text>
                    <Box mx={4} css={{ borderBottom: '2px dotted black' }} flexGrow={1}></Box>
                    {!loadingFundOrderInfo ? (
                      <>
                        <FormattedNumber
                          variant="input2"
                          decimals={{ min: 0, max: 2 }}
                          unit="SEK"
                          value={
                            fundOrderType === 'buy'
                              ? fundOrderInfoBuy?.transactionFee
                              : fundOrderInfoSell?.transactionFee
                          }
                        />
                      </>
                    ) : (
                      <SkeletonText width={50} />
                    )}
                  </FlexRow>
                  <Spacer height={16} />
                  <FlexCol alignItems="center">
                    <FlexRow alignItems="center">
                      <Text variant="input2">{amountLabel}</Text>
                      {sellAll && (
                        <Box ml={4}>
                          <Popper
                            id="fund-order-info"
                            toggle={
                              <FlexRow>
                                <IconButton size="small" variant="uncontained" icon={IconInfoOutlined} />
                              </FlexRow>
                            }
                          >
                            <Box p={16} css={{ width: 500, maxWidth: '90vw' }}>
                              <Text>
                                {t(
                                  'När du säljer hela innehavet sker försäljning av samtliga andelar. Beloppet som visas är en uppskattning utifrån senast kända kurs. När andelarna är sålda och transaktionen bokas på ditt konto ser du exakt belopp utifrån den kurs försäljningen skedde till.'
                                )}
                              </Text>
                            </Box>
                          </Popper>
                        </Box>
                      )}
                    </FlexRow>
                    <Spacer height={16} />
                    <Heading3>
                      {formatNumber(totalAmount)}
                      <Text ml={8} variant="input2">
                        {'SEK'}
                      </Text>
                    </Heading3>
                    <Spacer height={16} />
                    <Button
                      variant={fundOrderType === 'buy' ? 'dark-blue' : 'red'}
                      size="medium"
                      width={'full'}
                      onClick={async () => {
                        if (numericValue !== undefined) {
                          setOrderSent(true)

                          const analyticsData = {
                            instrument: carnegieInstrument?.name,
                            side: fundOrderType === 'buy' ? Side.Buy : Side.Sell,
                          }

                          try {
                            const response = await submitOrder(numericValue, sellAll)
                            setInsertOrderResult(response)

                            if (response.orderId && !response.orderError) {
                              fireTrackEvent('Order', 'fund_order_successful', analyticsData)
                            } else if (response.orderError) {
                              fireTrackEvent('Order', 'fund_order_rejected', analyticsData)
                            }
                          } catch (err) {
                            setInsertOrderResult(err)
                            fireTrackEvent('Order', 'fund_order_error', analyticsData)
                          }
                        }
                      }}
                      disabled={!numericValue || !!inputError || loadingFundOrderInfo}
                    >
                      {t(fundOrderType === 'buy' ? 'Köp' : 'Sälj')}
                    </Button>
                    {carnegieInstrument?.currency && carnegieInstrument?.currency !== 'SEK' && (
                      <>
                        <Spacer height={16} />
                        <ExchangeCurrencyInfo type="fund" />
                      </>
                    )}
                  </FlexCol>
                </>
              )}
            </FlexCol>
          )}
        </Segment>
        {accountSelected && (
          <Box mt={32}>
            <Text variant="body2" color="text-low-emphasis">
              {t(
                'För den här transaktionen har Carnegie inte gjort en fullständig utvärdering om du tillhör det finansiella instrumentets målgrupp eller om instrumentet matchar dina hållbarhetspreferenser.'
              )}
            </Text>
          </Box>
        )}
      </Box>
    )
  }
)

function getTotalAmount(
  value: number,
  fundOrderType: string,
  fundOrderInfoBuy: FundOrderInfoBuy,
  fundOrderInfoSell: FundOrderInfoSell
) {
  let totalAmount = 0

  if (value > 0) {
    if (fundOrderType === 'buy' && fundOrderInfoBuy) {
      totalAmount = value + fundOrderInfoBuy.transactionFee
    } else if (fundOrderType === 'sell' && fundOrderInfoSell) {
      totalAmount = Math.max(0, value - fundOrderInfoSell.transactionFee)
    }
  }

  return totalAmount
}

function getAmountLabel(fundOrderType: string, sellAll: boolean, t: TFunction) {
  if (fundOrderType === 'sell') {
    return sellAll ? t('Uppskattat belopp att erhålla') : t('Belopp att erhålla')
  } else {
    return t('Totalt belopp inkl. avgift')
  }
}

function getInputLabel(fundOrderType: string, sellAll: boolean, t: TFunction) {
  let inputLabel = ''

  if (fundOrderType === 'buy') {
    inputLabel = t('Belopp i SEK')
  } else {
    inputLabel = sellAll ? t('Uppskattat belopp i SEK') : t('Belopp i SEK')
  }

  return inputLabel
}
