import { RouteLoadingIndicator } from '@common/components/RouteLoadingIndicator'
import { ScrollToTop } from '@common/components/ScrollToTop'
import { useSystemMessage } from '@common/components/systemMessages'
import { featureOverridesActive } from '@common/hooks/useFeatures'
import { useAuth } from '@common/stores/store'

import { lazy } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { Banner, Box, GridContainer, useDuploTheme } from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import { AppErrorBoundary } from './AppErrorBoundary'
import { Drawers } from './Drawers'
import ArticleDrawer from './drawers/articleDrawer/ArticleDrawer'
import { OfferingsDrawer } from './drawers/corporateActionsDrawer/OfferingsDrawer'
import FundOrderDrawer from './drawers/fundOrderDrawer/FundOrderDrawer'
import InstrumentDrawer from './drawers/instrumentDrawer/InstrumentDrawer'
import NewsDrawer from './drawers/newsDrawer/NewsDrawer'
import OrderDrawer from './drawers/orderDrawer/OrderDrawer'
import PodcastDrawer from './drawers/podcastDrawer/PodcastDrawer'
import ResearchCommentsDrawer from './drawers/researchCommentsDrawer/ResearchCommentsDrawer'
import ResearchDrawer from './drawers/researchDrawer/ResearchDrawer'
import SeminarDrawer from './drawers/seminarDrawer/SeminarDrawer'
import SubscriptionDrawer from './drawers/subscriptionDrawer/SubscriptionDrawer'
import TransfersDrawer from './drawers/transfersDrawer/TransfersDrawer'
import AccountPage from './pages/accounts/AccountPage'
import { Reports } from './pages/profile/profilePage/Reports'
import ExploreResearchPage from './pages/research/researchExplore/ExploreResearchPage'
import { AppShell } from './pages/shell/AppShell'
import { Footer } from './pages/shell/Footer'
import { TopNavBar } from './pages/shell/Header'
import { MainMenu } from './pages/shell/Menu'

// Lazy-loaded components
const MarketPage = lazy(() => import('./pages/market/MarketPage'))
const ProfilePage = lazy(() => import('./pages/profile/ProfilePage'))
const ExplorePage = lazy(() => import('./pages/explore/ExplorePage'))
const OverviewPage = lazy(() => import('./pages/overview/OverviewPage'))
const OverviewHomePage = lazy(() => import('./pages/overview/home/OverviewHomePage'))
const SearchPage = lazy(() => import('./pages/search/SearchPage'))
const ResearchAndCommentsPage = lazy(() => import('./pages/research/researchAndComments/ResearchAndCommentsPage'))
const MessagesStartPage = lazy(() => import('./pages/messages/MessagesStartPage'))
const SmallCaps = lazy(() => import('./pages/research/researchExplore/SmallCaps'))
const ShortTermPage = lazy(() => import('./pages/subscriptions/ShortTermPage'))
const MorningNewsPage = lazy(() => import('./pages/subscriptions/MorningNewsPage'))
const MacroInFocusPage = lazy(() => import('./pages/subscriptions/MacroInFocusPage'))
const OfferingsPage = lazy(() => import('./pages/offerings/OfferingsPage'))
const LogoutPage = lazy(() => import('./pages/logout/Logout'))

const routes = [
  {
    path: '/',
    element: () => <Navigate to="/overview/home" replace />,
  },
  {
    path: '/overview/*',
    element: <OverviewPage />,
    routes: [
      {
        path: '',
        element: () => {
          const auth = useAuth()
          return auth.hasLimitedView ? (
            <Navigate to="/overview/accounts" replace />
          ) : (
            <Navigate to="/overview/home" replace />
          )
        },
      },
      {
        path: 'home/*',
        element: () => {
          const auth = useAuth()
          return auth.hasLimitedView ? <Navigate to="/overview/accounts" replace /> : <OverviewHomePage />
        },
      },
      { path: 'orders/:accountId/*', element: <OverviewPage /> },
      { path: ':tab/*', element: <OverviewPage /> },
    ],
  },
  {
    path: 'accounts/*',
    element: <AccountPage />,
    routes: [{ path: ':accountId' }, { path: ':accountId/*' }],
  },
  {
    path: '/market/*',
    element: <MarketPage />,
    routes: [
      { path: ':tab', element: <MarketPage /> },
      { path: ':tab/*', element: <MarketPage /> },
    ],
  },
  {
    path: '/explore/research',
    element: <ExploreResearchPage />,
  },
  {
    path: '/explore/*',
    element: <ExplorePage />,
    routes: [
      { path: ':tab', element: <ExplorePage /> },
      { path: ':tab/d/:drawer/*', element: <ExplorePage /> },
      { path: ':tab/:subtab', element: <ExplorePage /> },
    ],
  },
  {
    path: '/profile/*',
    element: <ProfilePage />,
    routes: [
      { path: ':tab', element: <ProfilePage /> },
      { path: ':tab/:subtab', element: <ProfilePage /> },
      { path: ':tab/:subtab/:id', element: <Reports /> },
      { path: ':tab/:subtab/:id/:page', element: <Reports /> },
    ],
  },
  { path: '/search/*', element: <SearchPage /> },
  {
    path: '/research/*',
    element: <ResearchAndCommentsPage />,
    routes: [
      { path: 'research-and-comments', element: <ResearchAndCommentsPage /> },
      { path: 'research-and-comments/:page', element: <ResearchAndCommentsPage /> },
      { path: 'small-caps', element: <SmallCaps /> },
    ],
  },
  {
    path: '/subscriptions/*',
    element: <ShortTermPage />,
    routes: [
      { path: 'short-term', element: <ShortTermPage /> },
      { path: 'short-term/:page', element: <ShortTermPage /> },
      { path: 'morning-news', element: <MorningNewsPage /> },
      { path: 'morning-news/:page', element: <MorningNewsPage /> },
      { path: 'macro-in-focus', element: <MacroInFocusPage /> },
      { path: 'macro-in-focus/:page', element: <MacroInFocusPage /> },
    ],
  },
  {
    path: '/messages/*',
    element: <MessagesStartPage />,
  },

  { path: '/logout', element: <LogoutPage /> },
  { path: '*', element: <Navigate to="/overview/home" replace /> },
  { path: '/offerings/*', element: <OfferingsPage /> },
]

const renderRoutes = (theRoutes) => (
  <>
    {theRoutes.map((route) => (
      <Route
        key={route.path}
        path={route.path}
        element={
          <RouteLoadingIndicator>
            {typeof route.element === 'function' ? route.element() : route.element}
          </RouteLoadingIndicator>
        }
      >
        {route.routes && renderRoutes(route.routes)}
      </Route>
    ))}
  </>
)

const AppRoutes = observer(() => {
  const duploTheme = useDuploTheme()
  const { messages = [], onClose } = useSystemMessage()
  const message = messages?.[0]

  return (
    <Box color="text-default">
      {message?.id && (
        <Banner
          key={message.id}
          severity={message.severity}
          title={message.subject}
          description={message.message}
          onClose={() => onClose(message.id)}
        />
      )}
      <AppShell
        topNavBarStyle={{
          background: featureOverridesActive() ? duploTheme.colors.shiraz : undefined,
        }}
        sideBar={<MainMenu />}
        topNavBar={<TopNavBar />}
        footer={<Footer />}
      >
        <AppErrorBoundary>
          <GridContainer justify="flex-start" alignContent="flex-start" style={{ minWidth: 0 }}>
            <ScrollToTop />
            <Routes>{renderRoutes(routes)}</Routes>
          </GridContainer>
        </AppErrorBoundary>
      </AppShell>
      <Drawers
        renderDrawer={(drawerType) => {
          if (drawerType === 'instrument') return <InstrumentDrawer />
          if (drawerType === 'news') return <NewsDrawer />
          if (drawerType === 'order') return <OrderDrawer />
          if (drawerType === 'fundorder') return <FundOrderDrawer />
          if (drawerType === 'article') return <ArticleDrawer />
          if (drawerType === 'seminar') return <SeminarDrawer />
          if (drawerType === 'podcast') return <PodcastDrawer />
          if (drawerType === 'research_comments') return <ResearchCommentsDrawer />
          if (drawerType === 'research') return <ResearchDrawer />
          if (drawerType === 'transfers') return <TransfersDrawer />
          if (drawerType === 'subscription') return <SubscriptionDrawer />
          if (drawerType === 'offering') return <OfferingsDrawer />

          // Will throw an error
          return undefined
        }}
      />
    </Box>
  )
})

export default AppRoutes
