import { AccountSummary, AccountSummaryResponse } from '@common/api/response'

import { useHttpSwr } from './useHttpSwr'

export function useAccountsSummary(): AccountSummary[] {
  const key = `/accounts/summary`
  const { data } = useHttpSwr<{ item: { accountSummaries: AccountSummary[] } }>(key)
  return data?.item?.accountSummaries
}

export function useAccountSummary(accountId?: string): AccountSummaryResponse {
  const key = `/accounts/${accountId}/summary`
  const { data } = useHttpSwr<{ item: AccountSummaryResponse }>(accountId ? key : undefined)
  return {
    accountSummary: data?.item?.accountSummary,
    canReceivePendingAmount: data?.item?.canReceivePendingAmount,
    canReceivePendingOrderAmount: data?.item?.canReceivePendingOrderAmount,
    canReceiveTradingCapacity: data?.item?.canReceiveTradingCapacity,
  }
}
