import { Icon, IconError, IconInProgress, IconRegistrated, IconSuccess } from '@carnegie/duplo'

interface TransferStatusProps {
  state: string
}

const TransferStatusIcon = ({ state }: TransferStatusProps) => {
  switch (state) {
    case 'Registered':
      return <Icon size={16} icon={IconRegistrated} color="status-positive" />
    case 'In progress':
      return <Icon size={16} icon={IconInProgress} color="status-positive" />
    case 'Completed':
      return <Icon size={16} icon={IconSuccess} color="status-success" />
    case 'Deleted':
      return <Icon size={16} icon={IconError} color="icon-low-emphasis" />
    case 'Rejected':
      return <Icon size={16} icon={IconError} color="status-negative" />
    default:
      return null
  }
}

export default TransferStatusIcon
