import { AccountsResponse, FundOrderType, InsertSimpleOrderResponse } from '@common/api/response'

import { TFunction, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { formatNumber } from '@carnegie/digital-channels-frontend'
import { Button, FlexCol, Heading3, Icon, IconError, IconSuccess, LoadingIcon, Spacer, Text } from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

type FundOrderSentProps = {
  renderForApp: boolean
  totalAmount: number
  amountLabel: string
  fundOrderType: FundOrderType
  selectedAccount: AccountsResponse
  insertOrderResult: InsertSimpleOrderResponse | Error
}

function isOrderResponse(
  orderResponse: InsertSimpleOrderResponse | unknown
): orderResponse is InsertSimpleOrderResponse {
  return (orderResponse as InsertSimpleOrderResponse).orderId !== undefined
}

export const FundOrderSent = observer(
  ({
    renderForApp,
    totalAmount,
    amountLabel,
    fundOrderType,
    insertOrderResult,
    selectedAccount,
  }: FundOrderSentProps) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const loading = insertOrderResult === undefined

    const orderResponse = !loading && isOrderResponse(insertOrderResult) ? insertOrderResult : undefined
    const error = !loading && !isOrderResponse(insertOrderResult) ? insertOrderResult : undefined
    const success = !loading && !error && !orderResponse?.orderError

    const resultLabel = getResultLabel(fundOrderType, loading, success, t)

    return (
      <FlexCol alignItems="center">
        {loading ? (
          <LoadingIcon size={56} />
        ) : (
          <Icon
            icon={success ? IconSuccess : IconError}
            size={56}
            color={success ? 'status-positive' : 'status-negative'}
          />
        )}
        <Spacer height={16} />
        {/* A non-breaking zero width space makes sure we still get the line height of the text even when empty (avoid jumping ui when text disappears)  */}
        <Text variant="subtitle1">{resultLabel ? resultLabel : '\u200b'}</Text>
        {error && (
          <>
            <Spacer height={16} />
            <Text variant="support1" color="text-low-emphasis">
              {t('Försök igen, eller kontakta oss för mer information.')}
            </Text>
          </>
        )}
        <Spacer height={32} />
        <Text variant="input2">{amountLabel}</Text>
        <Spacer height={16} />
        <Heading3>
          {formatNumber(totalAmount, { decimals: { min: 0, max: 5 } })}
          <Text ml={8} variant="input2">
            {'SEK'}
          </Text>
        </Heading3>
        <Spacer height={32} />
        <Button
          variant="primary"
          size="small"
          width={144}
          onClick={() => {
            renderForApp
              ? window.ReactNativeWebView?.postMessage('NAVIGATE_TO_ORDERLIST')
              : navigate(`/overview/orders/${selectedAccount?.id}`)
          }}
        >
          {t('Visa alla ordrar')}
        </Button>
      </FlexCol>
    )
  }
)

function getResultLabel(fundOrderType: FundOrderType, loading: boolean, success: boolean, t: TFunction) {
  if (loading) return ''

  if (fundOrderType === 'buy') {
    return success ? t('Din köporder är registrerad') : t('Din köporder blev avvisad')
  } else {
    return success ? t('Din säljorder är registrerad') : t('Din säljorder blev avvisad')
  }
}
