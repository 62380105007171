import { AccountOffering, RegisterAccountOfferingResponse } from '@common/api/response'
import { FormattedNumber } from '@common/components/FormattedNumber'
import { Offering } from '@common/hooks/useOfferings'
import { getOwnerDescription } from '@common/utils/accountOwnerDescription'

import { useTranslation } from 'react-i18next'

import { formatNumber } from '@carnegie/digital-channels-frontend'
import {
  Badge,
  Banner,
  Box,
  FlexCol,
  FlexRow,
  Input,
  ListItem,
  ListItemRow,
  Radio,
  RadioGroup,
  Tag,
  Text,
  Tooltip,
} from '@carnegie/duplo'

import { FormOfferingAnswer, parseNumberIncludingEmptyString } from './CorporateActionRespondForm'

type OfferingItemProps = {
  submitting?: boolean // Disables the controls and shows a skeleton for the responded status
  disabled?: boolean
  showValidationErrors?: boolean
  offering?: Offering
  isValidatingData?: boolean
  accountOffering: AccountOffering | undefined
  registerResponse?: RegisterAccountOfferingResponse
  offeringAnswer?: FormOfferingAnswer
  onChangeAnswer?: (offering: AccountOffering, radioButtonValue: boolean, acceptedQuantity?: number | '') => void
}

export const OfferingItem = ({
  accountOffering,
  registerResponse,
  offeringAnswer,
  onChangeAnswer,
  offering,
  showValidationErrors,
  disabled: disabledProp,
  submitting,
  isValidatingData,
}: OfferingItemProps) => {
  const { t } = useTranslation()

  const showResponseStatusSkeleton = !accountOffering || submitting || isValidatingData
  const disabled = submitting ? true : disabledProp

  return (
    <>
      <ListItem compact>
        <ListItemRow
          title={
            <FlexRow width="full" alignItems="center">
              {/* Icon */}
              <Box mr={8}>
                {accountOffering?.accountType?.description ? (
                  <Tooltip title={t(accountOffering?.accountType?.description)}>
                    <Badge size="medium">{accountOffering?.accountType?.shortName}</Badge>
                  </Tooltip>
                ) : (
                  <Badge size="medium">{accountOffering?.accountType?.shortName}</Badge>
                )}
              </Box>
              {/* Text */}
              <FlexCol>
                <FlexRow width="full">
                  <Text variant="subtitle1" skeleton={!accountOffering} skeletonWidth={100}>
                    {accountOffering?.accountNumber}
                  </Text>
                </FlexRow>
                <FlexRow alignItems="flex-start" width="full">
                  <Text variant="overline" truncate skeleton={!accountOffering} skeletonWidth={150}>
                    {getOwnerDescription(accountOffering?.owners, accountOffering?.policyHolder, t)}
                  </Text>
                </FlexRow>
              </FlexCol>
            </FlexRow>
          }
          value={
            <Tag
              size="small"
              skeleton={showResponseStatusSkeleton}
              skeletonWidth={76.5}
              type={accountOffering?.hasResponded ? 'success' : 'warning'}
            >
              {accountOffering?.hasResponded ? t('Besvarad') : t('Obesvarad')}
            </Tag>
          }
        />
      </ListItem>
      {registerResponse?.offeringAnswerStatuses?.find((response) => response.accountId === accountOffering?.accountId)
        ?.success === false && (
        <Banner
          severity="critical"
          title={t('Svaret kunde inte registreras')}
          description={t('Försök igen, eller kontakta oss om felet kvarstår.')}
          m={8}
        />
      )}
      {offeringAnswer?.errors?.notCheckedError && showValidationErrors && (
        <Banner severity="critical" title="" description={t(offeringAnswer.errors?.notCheckedError)} m={8} />
      )}
      <ListItem compact>
        <ListItemRow
          title={
            <>
              <Text variant="support2" bold mr={2}>
                {`${t('totalt_antal')}:`}
              </Text>
              <FormattedNumber
                skeleton={!accountOffering}
                skeletonWidth={60}
                variant="support2"
                bold
                value={accountOffering?.totalQuantity}
                decimals={0}
                unit={t('st')}
              />
            </>
          }
        />
      </ListItem>
      <ListItem compact divider>
        <RadioGroup>
          <Box backgroundColor={offeringAnswer?.isChecked === true ? 'focus-background' : 'off-white'} borderRadius={4}>
            <FlexRow width="full" textAlign="left" alignItems="center">
              <Radio
                name={accountOffering?.accountId}
                disabled={!accountOffering || disabled}
                onChange={() => onChangeAnswer(accountOffering, true, offeringAnswer?.acceptedQuantity)}
                checked={offeringAnswer?.isChecked === true}
                label={
                  <Text variant="input2">
                    {offering?.offeringType === 'Takeover'
                      ? t('Ja, jag accepterar erbjudandet enligt villkoren')
                      : t('Ja, jag önskar teckna aktier enligt villkoren')}
                  </Text>
                }
                width="full"
              />
            </FlexRow>
            {offeringAnswer?.isChecked === true && (
              <>
                <FlexRow width="full" textAlign="left" alignItems="center" pl={40} pb={8} pr={16}>
                  <Input
                    error={showValidationErrors && !!offeringAnswer.errors?.acceptedQuantityError}
                    helperText={showValidationErrors && offeringAnswer.errors?.acceptedQuantityError}
                    size="medium"
                    label={t('Antal att delta med:')}
                    type="text"
                    disabled={!accountOffering || disabled}
                    inputMode={'numeric'}
                    inputFormat={{
                      type: 'numeric',
                      allowedDecimals: 0,
                      fixedDecimalScale: true,
                      thousandSeparator: true,
                    }}
                    onChange={(event) => {
                      const acceptedQuantityValue = event.target.value
                      onChangeAnswer(
                        accountOffering,
                        true,
                        acceptedQuantityValue === '' ? '' : Number(acceptedQuantityValue)
                      )
                    }}
                    value={offeringAnswer?.acceptedQuantity}
                  />
                </FlexRow>
                {offering?.offeringType !== 'Takeover' && (
                  <FlexRow pl={48} pb={8} pr={16}>
                    <Text variant="support2" color="text-low-emphasis">
                      {t('Belopp att ha tillgängligt på kontot innan sista likviddag: ') +
                        formatNumber(
                          Math.floor(
                            parseNumberIncludingEmptyString(offeringAnswer?.acceptedQuantity) / offering?.ratioFrom
                          ) *
                            (offering?.ratioTo * offering?.price),
                          { ratio: false, decimals: 2, roundValue: false }
                        ) +
                        ' ' +
                        offering?.currencyCode}
                    </Text>
                  </FlexRow>
                )}
              </>
            )}
          </Box>
          <Box
            backgroundColor={offeringAnswer?.isChecked === false ? 'focus-background' : 'off-white'}
            borderRadius={4}
          >
            <FlexCol width="full" textAlign="left" alignItems="center">
              <Radio
                name={accountOffering?.accountId}
                disabled={!accountOffering || disabled}
                style={{ fontSize: '20px' }}
                label={<Text variant="input2">{t('Nej, jag önskar inte acceptera erbjudandet')}</Text>}
                onChange={() => onChangeAnswer(accountOffering, false, offeringAnswer?.acceptedQuantity)}
                width="full"
                checked={offeringAnswer?.isChecked === false}
              ></Radio>
              {offering?.offeringType !== 'Takeover' && offeringAnswer?.isChecked === false && (
                <Text ml={40} mb={8} variant="support2" color="text-low-emphasis">
                  {offering?.offeringType === 'WarrantsExercise'
                    ? t(
                        'Teckningsoptionerna kommer om möjligt säljas på sista handelsdagen. Courtage utgår enligt gällande prislista vid eventuell försäljning.'
                      )
                    : t(
                        'Teckningsrätterna kommer om möjligt säljas på sista handelsdagen. Courtage utgår enligt gällande prislista vid eventuell försäljning.'
                      )}
                </Text>
              )}
            </FlexCol>
          </Box>
        </RadioGroup>
      </ListItem>
    </>
  )
}
