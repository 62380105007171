import DateSquircle from '@common/components/DateSquircle'

import { checkToday } from '@carnegie/digital-channels-frontend'
import { ListItem, ListItemRow, Text } from '@carnegie/duplo'

type CalendarListItemProps = {
  className?: string
  onClick?: () => void
  divider?: boolean
  date: Date
  category?: string
  description?: string
  extraInfo?: string
}

export const CalendarListItem = ({
  className,
  onClick,
  divider,
  date,
  category,
  description,
  extraInfo,
}: CalendarListItemProps) => {
  return (
    <ListItem className={className} divider={divider} onClick={onClick} support={<DateSquircle date={date} />}>
      <ListItemRow
        title={<Text variant="overline">{category}</Text>}
        value={<Text variant="overline">{checkToday(date, { short: true, showTodayAsTime: false })}</Text>}
      />
      <ListItemRow
        title={
          <Text variant="subtitle2" color="text-default" bold>
            {description}
          </Text>
        }
        value={
          <Text variant="overline" color="text-positive" bold>
            {extraInfo}
          </Text>
        }
        valueWrapperStyle={{ marginLeft: 8 }}
      />
    </ListItem>
  )
}
