import { AccountsResponse, DocumentInfoModel } from '@common/api/response'
import { fireTrackEvent } from '@common/utils/analyticsEvent'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useFormatDate } from '@carnegie/digital-channels-frontend'
import { ButtonBox, FlexRow, Icon, IconDocumentLineChart, LinkButton, LinkExternal, Text } from '@carnegie/duplo'

import { ReportStatementColumnId } from './ReportsStatement'

type ReportStatementRowProps = {
  statement: DocumentInfoModel
  selectedAccount: AccountsResponse
  cell: ReportStatementColumnId
}

export const ReportStatementRow = ({ statement, selectedAccount, cell }: ReportStatementRowProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { formatDate } = useFormatDate()

  const statementName = `${t(statement?.name.toString())} ${statement?.suffix}`.replaceAll('+', '_')

  switch (cell) {
    case 'name':
      return (
        <ButtonBox
          onClick={() => {
            fireTrackEvent('Reports', 'file_download_statements')
          }}
          minWidth={0}
        >
          <LinkExternal
            variant="body2"
            href={`/api/reports/document/download-file/${statement?.documentId ?? ''}`}
            rel="noreferrer"
            target="_blank"
            download={statementName}
            minWidth={0}
            truncate
          >
            <FlexRow alignItems="center">
              <Icon size={16} color="current" mr={4} icon={IconDocumentLineChart} />
              <Text variant="body2" truncate>
                {statementName}
              </Text>
            </FlexRow>
          </LinkExternal>
        </ButtonBox>
      )

    case 'statementPeriodEnd':
      return (
        <Text variant="body2">{`${formatDate(statement?.statementPeriodStart, 'yyyy-MM-dd')} - ${formatDate(
          statement?.statementPeriodEnd,
          'yyyy-MM-dd'
        )}`}</Text>
      )

    case 'accountNumber':
      return (
        <LinkButton
          variant="body2"
          minWidth={0}
          onClick={() => {
            navigate(`/accounts/${selectedAccount?.id}?returnroute=/profile/reports/statement/${selectedAccount?.id}`)
          }}
        >
          {statement.accountNumber}
        </LinkButton>
      )

    case 'createdDate':
      return (
        <Text variant="body2">{statement?.createdDate && formatDate(statement.createdDate, 'HH:mm/yyyy-MM-dd')}</Text>
      )
  }
}
