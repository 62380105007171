import { Guid } from '../guid'
import { InstrumentIdContainer } from '../instrumentIdContainer'
import { CustomerReportModel, InfrontInstrument, ResearchNews } from './schemaDoNotImportDirectly'

export { AnalysisTypeName, OfferingStatus, OfferingType } from './schemaDoNotImportDirectly' //enums
export type {
  AccountOffering,
  CustomerReportModel,
  GetAnalysisFilterRequest,
  GetAnalysisItemResponse,
  GetAnalysisRequest,
  GetAnalysisResponse,
  GetCorePicksRequest,
  GetCorePicksResponse,
  GetMoversRequest,
  GetRelatedInstrumentsResponse,
  InfrontInstrument,
  OfferingAnswer,
  OfferingsResponse,
  RegisterAccountOfferingRequest,
  RegisterAccountOfferingResponse,
  RelatedInstrument,
  ResearchFlags,
  ResearchNews,
  ResearchTargets,
  TargetAndRecommendationResponse,
} from './schemaDoNotImportDirectly'

// The auto generated type is a bit wrong here, since in C# value is defined as object, the auto generated version also uses object but in TS this will not include primitive types which it does in C#
export type UpsertSettingsRequest = { value: unknown | null; valueType?: string | null; key?: UserSettingKey }

export type BaseResponse = {
  error: string
}

export type ApiResponse = BaseResponse

export type ApiItemResponse<T> = {
  item: T
} & BaseResponse

export type ApiItemsResponse<T> = {
  items: T[]
} & BaseResponse

export interface IsLoggedInResponse {
  isLoggedIn: boolean
  userPrefix: string
  isImpersonated: boolean
  hasLimitedView: boolean
  customerPermissionFlags: CustomerPermissionFlags
}

export type CustomerPermissionFlags = {
  hasAnalysisPackage: boolean
  hasReach: boolean
}

export type ResearchNewsResponse = {
  currentPage: number
  researchNews: ResearchNews[]
  totalRecords: number
  totalPages: number
  usingLimit: number
}

export type AccountPerformanceResponse = {
  timestamp: string
  marketValue: number
  changeSinceStartPercent: number
}

export enum AccountPerformancePeriod {
  OneWeek = 'OneWeek',
  OneMonth = 'OneMonth',
  ThreeMonth = 'ThreeMonth',
  SixMonth = 'SixMonth',
  Ytd = 'Ytd',
  OneYear = 'OneYear',
  ThreeYear = 'ThreeYear',
  FiveYear = 'FiveYear',
  SinceInception = 'SinceInception',
}

export type PerformanceSeriesItem = {
  accountId: string
  performanceSeries: PerformanceSeries
}

export type PerformanceSeries = Pick<
  PerformanceSerieResponse,
  | 'c1D'
  | 'c1DP'
  | 'c1WP'
  | 'c1MP'
  | 'c3M'
  | 'c3MP'
  | 'c6MP'
  | 'ytd'
  | 'ytdP'
  | 'c12MP'
  | 'c3YP'
  | 'c5YP'
  | 'sinceInceptionP'
  | 'unrealisedResult'
>

export type SearchInstrumentResponse = {
  stocks: SearchInstrumentItemResponse[]
  funds: SearchInstrumentItemResponse[]
  etfs: SearchInstrumentItemResponse[]
  bonds: SearchInstrumentItemResponse[]
  certificates: SearchInstrumentItemResponse[]
  options: SearchInstrumentItemResponse[]
  others: SearchInstrumentItemResponse[]
}

type Severity = 'critical' | 'information' | 'warning'
export type SystemMessageResponse = {
  id: string
  createdDate: string
  message: string
  subject: string
  severity: Severity
}

export type InstrumentValidityResponse = {
  isValid: boolean
  invalidCode: InvalidCode
  InvalidCodeList: string[]
}

export type InvalidCode =
  | 'NotEmployeeOrStaffRelated'
  | 'NotTradableInstrument'
  | 'NotTradableFund'
  | 'NoAccountsHasStatusOpen'
  | 'AccountsMissingTradePrivilege'
  | 'UserMissingKnowledgeClass'
  | 'UserIsImpersonator'

export type SearchInstrumentItemResponse = {
  infrontInstrument: Infront.Instrument
  instrumentIdCarnegie: string
  isin: string
  name: string
  symbol: string
  currency: string
  type: string
  marketplaceName: string
}

export type InstrumentNameResponse = {
  item: {
    name: string
  }
} & BaseResponse

export type InfrontToken = {
  token: string
  expires: string
  xid: string //partyId
  tgwEnabled: boolean
  tgwService?: number
  tgwProvider?: number
}

export type ProfileResponse = {
  firstName: string
  surName: string
  isPhysicalPerson: boolean
  address: Address
  phone: string
  email: string
}
type ValueAndCurrency = {
  value: number
  currencyCode: string
}

export type TransactionResponseItem = {
  totalCount: number
  // filterOptions: TransactionsFilterOptions
  transactions: Transaction[]
}
export type TransactionResponse = {
  item: TransactionResponseItem
}

export type Watchlist = {
  id: string
  name: string
  instruments: WatchlistInstrument[]
}
export type WatchlistInstrument = {
  id: string
  infrontInstrument: { feed: number; ticker: string }
  name: string
  group: string
  type: string
  groupSortOrder: number
  marketplaceName: string
  typeName: string
  currency: string
  riskTolerancePRIIPS: string
}
export type WatchlistsResponse = {
  watchlists: Watchlist[]
}

export type PersonStatus =
  | 'NotFound'
  | 'Initiated'
  | 'Processing'
  | 'UpdateRequired'
  | 'Active'
  | 'Inactive'
  | 'OutOfScope'

export type PersonSubmissionSummary = {
  isPhysicalPerson: boolean
  partyId: string
  name: string
  personStatus: PersonStatus
  submissionSystem: string
  id: number
  createDate: string
  validFrom: string | null
  validTo: string | null
  hasOngoing: boolean
  tagType: TagType | null
  stateDescription: string | null
  newUrl: string | null
  url: string | null
  editable: boolean
  renewable: boolean
  stateIsActive: boolean
  canInitiateOnboarding: boolean
  isExpiringSoon: boolean
  hasExpired: boolean
}

export type GetSubmissionsResponse = {
  personSubmissionSummary: PersonSubmissionSummary[]
  aggregatedStatus: PersonStatus
  anyIsExpiringSoon: boolean
  anyHasExpired: boolean
}

type TagType = 'DEFAULT' | 'ERROR' | 'SUCCESS' | 'WARNING' | 'POSITIVE'

export class TransactionsFilterOptions {
  currencies: string[] = []
  transactionTypes: TransactionType[] = []
  instruments: TransactionInstrument[] = []
}

export type TransactionInstrument = {
  instrumentName: string
  instrumentId: string
}

export type TransactionType = {
  value: string
  key: string
}

export type Transaction = {
  key?: string
  accountDate: string
  accountId: string
  accountNumber: number
  amount: ValueAndCurrency
  infrontInstrument: Infront.Instrument
  instrumentName: string
  instrumentId: string
  isCancelTransaction: boolean
  isPreliminaryTransaction: boolean
  price: ValueAndCurrency
  quantity: number
  rate: number
  registrationDate: string
  type: string
  typeId: string
  commission: number
  otherFee: number
  interest: number
  fxRate: number
  tradeDate: string
  settlementDate: string
  transactionText: string
  noteId: string
  isin: string
  purchaseAmount: number
  cancelDate: string
  currencyType: string
  originalCurrency: string
  totalQuantity: number
  category: string
  couponInterest: number
  balance: number
}

type PartyResponsible = {
  name: string
  staffPartyId: string
  title: string
  organizationContactInfo: OrganizationContactInfo
  phoneNumber: string
}

export type ResponsibleResponse = {
  responsibles: PartyResponsible[]
}

class OrganizationContactInfo {
  emails: {
    email: string
    isPreferred?: boolean
  }[]
  phoneNumbers: {
    number: string
    isPreferred?: boolean
    type: string
  }[]
}

type Address = {
  address1: string
  address2: string
  address3: string
  attentionName: string
  postalCode: string
  city: string
  country: {
    countryCode: string
    name: string
  }
}

export enum CarnegieInstrumentGroup {
  EQUITY = 'Equity',
  FUNDS = 'MutualFunds',
  ETF = 'Etf',
  BOND = 'Bonds',
  CERTIFICATE_WARRANTS = 'CertificatesWarrants',
  OPTIONS_FORWARDS_FUTURES = 'OptionsForwardsFutures',
  OTHERS = 'Others',
}

export type CarnegieInstrument = {
  businessDay: string
  carnegieInstrumentGroup: CarnegieInstrumentGroup
  collateralValue: number
  currency: string
  distributionFee: number
  fundTransactionFee: number
  instrumentIdCarnegie?: Guid
  instrumentType: string
  instrumentTypeName: string
  internalOrderCutOffTime: string
  isin: number
  lei: string
  managementFee: number
  marketList: string
  marketplaceName: string
  name: string
  quotedInCents: boolean
  tradingPeriod: string

  businessDayBuy: string
  businessDaySell: string
  tradingPeriodBuy: string
  tradingPeriodSell: string
  riskTolerancePRIIPS: string
} & Required<InstrumentIdContainer>

export type PerformanceSeriesResponseItem = {
  accountId: string
  performanceSeries: PerformanceSerieResponse
}

export type PerformanceSerieResponse = {
  c1D: number
  c1DP: number
  c1MP: number
  c1WP: number
  c3M: number
  c3MP: number
  c6MP: number
  c12MP: number
  c3YP: number
  c5YP: number
  sinceInceptionP: number
  unrealisedResult: CurrencyAmount
  realised: CurrencyAmount
  realisedYear: number
  ytd: number
  ytdP: number
}

export type InstrumentHoldingsResponse = {
  accountNumber: string
  accountOwner: string
  instrumentPosition: InstrumentHolding
}

type InstrumentHolding = {
  quantity: number
  marketValue: PositionCurrencyAmounts
  acquisitionCost: PositionCurrencyAmounts
  averageAcquisitionPriceAccount: CurrencyAmount
  unrealizedResult: PositionCurrencyAmounts
  unrealizedResultRatio: number
  collateralAmount: CurrencyAmount
  reservedQuantities?: ReservedQuantity[]
  marginRequirements?: CurrencyAmount | null
}

type PositionCurrencyAmounts = {
  account: CurrencyAmount
  original: CurrencyAmount
}

export type AccountDividend = {
  instrumentId: string
  instrumentName: string
  account: CurrencyAmount
  original: CurrencyAmount
  category: string
  infrontInstrument: Infront.Instrument
  marketIso: string
}

export type AccountDividendsResponse = {
  dividends: AccountDividend[]
  couponInterest: AccountDividend[]
  accruedCouponInterest: AccountDividend[]
}

export type FundOrderType = 'buy' | 'sell'

export type ValidateFundResponse = {
  isValid: boolean
  invalidCodesPriorityList?: string[] | null
  invalidCode?: InvalidCode | null
}

export type LoginAssertionResponse = {
  accessToken: string
}

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export type AccountType = {
  shortName?: string | null
  description?: string | null
  isDepositAndWithdrawLocked: boolean
  insuranceType?: string | null
}

export type AccountsResponse = {
  /** @pattern ^[{(]?[0-9a-fA-F]{8}[-]?(?:[0-9a-fA-F]{4}[-]?){3}[0-9a-fA-F]{12}[)}]?$ */
  id: string
  number?: string | null
  owners?: string[] | null
  currencyCode?: string | null
  primaryOwner: boolean
  /** @format date-time */
  endDate?: string | null
  isTradeable: boolean
  isDepositAndExternalWithdrawEnabled: boolean
  accountType?: AccountType | null
  ocrNumber?: string | null
  policyHolder?: string | null
  isPolicyHolder: boolean
  isAccountStatusOpen: boolean
}

type AccountInformation = {
  owners?: string[] | null
  relation?: string | null
  policyHolder?: string | null
  accountType?: AccountType | null
  isPolicyHolder: boolean
  primaryOwner: boolean
  accountDescription?: string | null
  serviceType?: string | null
  accountRole?: string | null
  accountResponsibles?: PartyResponsible[] | null
}

export type CurrencyPosition = {
  currency?: string | null
  /** @format decimal */
  quantity?: number | null
  /** @format decimal */
  marketValue?: number | null
  /** @format decimal */
  fxRate?: number | null
}

type InstrumentPositionInstrument = {
  id?: string | null
  infrontInstrument?: InfrontInstrument | null
  name?: string | null
  isin?: string | null
  symbol?: string | null
  carnegieInstrumentGroup?: string | null
  typeName?: string | null
  allocation?: string | null
  marketIso?: string | null
  hasDetails?: boolean | null
}

export type CurrencyAmount = {
  /** @format decimal */
  amount?: number | null
  currencyCode?: string | null
}

export type ReservedQuantity = {
  /** @format decimal */
  quantity: number
  reason?: string | null
}

export type InstrumentPosition = {
  instrument?: InstrumentPositionInstrument | null
  /** @format decimal */
  quantity?: number | null
  /** @format decimal */
  lastPrice?: number | null
  /** @format decimal */
  priceChangeToday?: number | null
  /** @format decimal */
  priceChangeTodayOriginal?: number | null
  /** @format decimal */
  priceChangeTodayRatio?: number | null
  /** @format decimal */
  priceChangeOneDay?: number | null
  /** @format decimal */
  priceChangeOneDayOriginal?: number | null
  /** @format decimal */
  priceChangeOneDayRatio?: number | null
  latestEODPrice?: CurrencyAmount | null
  /** @format date-time */
  latestEODDate?: string | null
  previousEODPrice?: CurrencyAmount | null
  /** @format date-time */
  previousEODDate?: string | null
  currencyCode?: string | null
  /** @format decimal */
  marketValue?: number | null
  /** @format decimal */
  marketValueOriginal?: number | null
  /** @format decimal */
  unrealized?: number | null
  /** @format decimal */
  unrealizedRatio?: number | null
  acquisitionCost?: CurrencyAmount | null
  acquisitionCostOriginal?: CurrencyAmount | null
  averageAcquisitionPrice?: CurrencyAmount | null
  averageAcquisitionPriceOriginal?: CurrencyAmount | null
  /** @format date-time */
  instrumentPriceDateTime?: string | null
  region?: string | null
  /** @format decimal */
  weight?: number | null
  /** @format decimal */
  acquisitionFxRate?: number | null
  reservedQuantities?: ReservedQuantity[] | null
  marginRequirements?: CurrencyAmount | null
}

type Holdings = {
  currencies?: CurrencyPosition[] | null
  instruments?: InstrumentPosition[] | null
}

type AccountInterestRate = {
  currencyCode?: string | null
  /** @format decimal */
  interestRateDebit: number
  /** @format decimal */
  interestRateCredit: number
}

export type AccountResponse = {
  /** @pattern ^[{(]?[0-9a-fA-F]{8}[-]?(?:[0-9a-fA-F]{4}[-]?){3}[0-9a-fA-F]{12}[)}]?$ */
  id: string
  number?: string | null
  currencyCode?: string | null
  ocrNumber?: string | null
  information?: AccountInformation | null
  holdings?: Holdings | null
  isTradeable: boolean
  accountInterestRates?: AccountInterestRate[] | null
}

export type AccountSummary = {
  /** @pattern ^[{(]?[0-9a-fA-F]{8}[-]?(?:[0-9a-fA-F]{4}[-]?){3}[0-9a-fA-F]{12}[)}]?$ */
  accountId: string
  marketValue?: CurrencyAmount | null
  liquidAsset?: CurrencyAmount | null
  portfolioValue?: CurrencyAmount | null
  accrued?: CurrencyAmount | null
  collateralValue?: CurrencyAmount | null
  acquisitionValue?: CurrencyAmount | null
  creditLimit?: CurrencyAmount | null
  accruedCoupon?: CurrencyAmount | null
  couponCash?: CurrencyAmount | null
  dividendCash?: CurrencyAmount | null
  marginRequirements?: CurrencyAmount | null
  withdrawableAmount?: CurrencyAmount | null
  tradingCapacity?: CurrencyAmount | null
}

export type AccountSummaryResponse = {
  accountSummary?: AccountSummary | null
  canReceivePendingAmount: boolean
  canReceivePendingOrderAmount: boolean
  canReceiveTradingCapacity: boolean
}

type MessageAttachmentModel = {
  /** @pattern ^[{(]?[0-9a-fA-F]{8}[-]?(?:[0-9a-fA-F]{4}[-]?){3}[0-9a-fA-F]{12}[)}]?$ */
  documentId: string
  name?: string | null
}

export type PossibleSubscriberModel = {
  /** @pattern ^[{(]?[0-9a-fA-F]{8}[-]?(?:[0-9a-fA-F]{4}[-]?){3}[0-9a-fA-F]{12}[)}]?$ */
  id: string
  name?: string | null
  type?: string | null
  managerIds?: number[] | null
}

export enum CarnegieContentType {
  PodcastEpisode = 'PodcastEpisode',
  Article = 'Article',
  Seminar = 'Seminar',
  Subscription = 'Subscription',
  Analysis = 'Analysis',
}

type CarnegieBaseTag = {
  /** @format int32 */
  id: number
  name?: string | null
}

export type PodcastEpisodeModel = {
  disclaimerText?: string | null
  carnegieContentType: CarnegieContentType
  id?: string | null
  thumbnail?: string | null
  /** @format date-time */
  date: string
  title?: string | null
  link?: string | null
  tags?: CarnegieBaseTag[] | null
  summary?: string | null
  description?: string | null
  author?: string | null
  category?: string | null
}

export enum HttpStatusCode {
  Continue = 'Continue',
  SwitchingProtocols = 'SwitchingProtocols',
  Processing = 'Processing',
  EarlyHints = 'EarlyHints',
  OK = 'OK',
  Created = 'Created',
  Accepted = 'Accepted',
  NonAuthoritativeInformation = 'NonAuthoritativeInformation',
  NoContent = 'NoContent',
  ResetContent = 'ResetContent',
  PartialContent = 'PartialContent',
  MultiStatus = 'MultiStatus',
  AlreadyReported = 'AlreadyReported',
  IMUsed = 'IMUsed',
  MultipleChoices = 'MultipleChoices',
  Ambiguous = 'Ambiguous',
  MovedPermanently = 'MovedPermanently',
  Moved = 'Moved',
  Found = 'Found',
  Redirect = 'Redirect',
  SeeOther = 'SeeOther',
  RedirectMethod = 'RedirectMethod',
  NotModified = 'NotModified',
  UseProxy = 'UseProxy',
  Unused = 'Unused',
  TemporaryRedirect = 'TemporaryRedirect',
  RedirectKeepVerb = 'RedirectKeepVerb',
  PermanentRedirect = 'PermanentRedirect',
  BadRequest = 'BadRequest',
  Unauthorized = 'Unauthorized',
  PaymentRequired = 'PaymentRequired',
  Forbidden = 'Forbidden',
  NotFound = 'NotFound',
  MethodNotAllowed = 'MethodNotAllowed',
  NotAcceptable = 'NotAcceptable',
  ProxyAuthenticationRequired = 'ProxyAuthenticationRequired',
  RequestTimeout = 'RequestTimeout',
  Conflict = 'Conflict',
  Gone = 'Gone',
  LengthRequired = 'LengthRequired',
  PreconditionFailed = 'PreconditionFailed',
  RequestEntityTooLarge = 'RequestEntityTooLarge',
  RequestUriTooLong = 'RequestUriTooLong',
  UnsupportedMediaType = 'UnsupportedMediaType',
  RequestedRangeNotSatisfiable = 'RequestedRangeNotSatisfiable',
  ExpectationFailed = 'ExpectationFailed',
  MisdirectedRequest = 'MisdirectedRequest',
  UnprocessableEntity = 'UnprocessableEntity',
  Locked = 'Locked',
  FailedDependency = 'FailedDependency',
  UpgradeRequired = 'UpgradeRequired',
  PreconditionRequired = 'PreconditionRequired',
  TooManyRequests = 'TooManyRequests',
  RequestHeaderFieldsTooLarge = 'RequestHeaderFieldsTooLarge',
  UnavailableForLegalReasons = 'UnavailableForLegalReasons',
  InternalServerError = 'InternalServerError',
  NotImplemented = 'NotImplemented',
  BadGateway = 'BadGateway',
  ServiceUnavailable = 'ServiceUnavailable',
  GatewayTimeout = 'GatewayTimeout',
  HttpVersionNotSupported = 'HttpVersionNotSupported',
  VariantAlsoNegotiates = 'VariantAlsoNegotiates',
  InsufficientStorage = 'InsufficientStorage',
  LoopDetected = 'LoopDetected',
  NotExtended = 'NotExtended',
  NetworkAuthenticationRequired = 'NetworkAuthenticationRequired',
}

type ProblemDetails = {
  type?: string | null
  title?: string | null
  /** @format int32 */
  status: number
  errors?: Record<string, string[]>
}

export type PaginationResponseOfPodcastEpisodeModel = {
  items?: PodcastEpisodeModel[] | null
  /** @format int32 */
  totalPages: number
  /** @format int32 */
  currentPage: number
  /** @format int32 */
  usingLimit?: number | null
  /** @format int32 */
  totalRows: number
  error?: string | null
  httpStatus: HttpStatusCode
  success: boolean
  problemDetails?: ProblemDetails | null
}

type CarnegieNewsCategory = {
  /** @format int32 */
  id: number
  /** @format int32 */
  categoryId: number
  name?: string | null
}

export type CarnegieNewsModel = {
  authorTitle?: string | null
  authorImage?: string | null
  message?: string | null
  messageImage?: string | null
  messageIntroduction?: string | null
  publishDate?: string | null
  formattedCategories?: CarnegieNewsCategory[] | null
  carnegieContentType: CarnegieContentType
  id?: string | null
  thumbnail?: string | null
  /** @format date-time */
  date: string
  title?: string | null
  link?: string | null
  tags?: CarnegieBaseTag[] | null
  summary?: string | null
  description?: string | null
  author?: string | null
  category?: string | null
}

export type PaginationResponseOfCarnegieNewsModel = {
  items?: CarnegieNewsModel[] | null
  /** @format int32 */
  totalPages: number
  /** @format int32 */
  currentPage: number
  /** @format int32 */
  usingLimit?: number | null
  /** @format int32 */
  totalRows: number
  error?: string | null
  httpStatus: HttpStatusCode
  success: boolean
  problemDetails?: ProblemDetails | null
  isHighlight?: boolean | null
}

export type VideoResponseModel = {
  uri?: string | null
  embedHtml?: string | null
  carnegieContentType: CarnegieContentType
  id?: string | null
  thumbnail?: string | null
  /** @format date-time */
  date: string
  title?: string | null
  link?: string | null
  tags?: CarnegieBaseTag[] | null
  summary?: string | null
  description?: string | null
  author?: string | null
  category?: string | null
}

export type PaginationResponseOfVideoResponseModel = {
  items?: VideoResponseModel[] | null
  /** @format int32 */
  totalPages: number
  /** @format int32 */
  currentPage: number
  /** @format int32 */
  usingLimit?: number | null
  /** @format int32 */
  totalRows: number
  error?: string | null
  httpStatus: HttpStatusCode
  success: boolean
  problemDetails?: ProblemDetails | null
}

export type CarnegieSummaryWrapper = {
  id?: string | null
  highlight?: boolean | null
  carnegieContentType: CarnegieContentType
  /** @format date-time */
  date: string
  thumbnail?: string | null
  title?: string | null
  summary?: string | null
  category?: string | null
  categories?: string[] | null
  author?: string | null
  tags?: string[] | CarnegieBaseTag[] | null
}

export type PaginationResponseOfCarnegieSummaryWrapper = {
  items?: CarnegieSummaryWrapper[] | null
  /** @format int32 */
  totalPages: number
  /** @format int32 */
  currentPage: number
  /** @format int32 */
  usingLimit?: number | null
  /** @format int32 */
  totalRows: number
  error?: string | null
  httpStatus: HttpStatusCode
  success: boolean
  problemDetails?: ProblemDetails | null
}

export type EnvelopeItemDescriptorOfPodcastEpisodeModel = {
  item?: PodcastEpisodeModel | null
  error?: string | null
}

export type EnvelopeItemDescriptorOfCarnegieNewsModel = {
  item?: CarnegieNewsModel | null
  error?: string | null
}

export type EnvelopeItemDescriptorOfVideoResponseModel = {
  item?: VideoResponseModel | null
  error?: string | null
}

type MarketingEmailInstrumentModel = {
  carnegieId?: string | null
  name?: string | null
}

export enum SubscriptionType {
  ShortTerm = 'ShortTerm',
  MacroInFocus = 'MacroInFocus',
  MorningNews = 'MorningNews',
  AllocationStrategy = 'AllocationStrategy',
}

export type MarketingEmailModelWrapper = {
  /** @pattern ^[{(]?[0-9a-fA-F]{8}[-]?(?:[0-9a-fA-F]{4}[-]?){3}[0-9a-fA-F]{12}[)}]?$ */
  marketingEmailId: string
  subject?: string | null
  /** @format date-time */
  publishedDate: string
  body?: string | null
  instruments?: MarketingEmailInstrumentModel[] | null
  subscriptionType: SubscriptionType
}

export type PaginationResponseOfMarketingEmailModelWrapper = {
  items?: MarketingEmailModelWrapper[] | null
  /** @format int32 */
  totalPages: number
  /** @format int32 */
  currentPage: number
  /** @format int32 */
  usingLimit?: number | null
  /** @format int32 */
  totalRows: number
  error?: string | null
  httpStatus: HttpStatusCode
  success: boolean
  problemDetails?: ProblemDetails | null
}

export type EnvelopeItemDescriptorOfMarketingEmailModelWrapper = {
  item?: MarketingEmailModelWrapper | null
  error?: string | null
}

export type Asset = {
  instrumentCurrency?: string | null
  instrumentName?: string | null
  /** @format decimal */
  valuationPrice: number
  /** @format decimal */
  holdingMarketValuePortfolio: number
  /** @format decimal */
  holdingQuantity: number
  /** @format decimal */
  weight: number
  /** @pattern ^[{(]?[0-9a-fA-F]{8}[-]?(?:[0-9a-fA-F]{4}[-]?){3}[0-9a-fA-F]{12}[)}]?$ */
  instrumentId: string
}

type InstrumentModelSimple = {
  instrumentIdCarnegie?: string | null
  instrumentType?: string | null
  instrumentTypeName?: string | null
  carnegieInstrumentGroup?: string | null
  isin?: string | null
  lei?: string | null
  symbol?: string | null
  name?: string | null
  currency?: string | null
  infrontInstrument?: InfrontInstrument | null
  /** @format double */
  fundTransactionFee?: number | null
  marketplaceName?: string | null
  riskTolerancePRIIPS?: string | null
}

export type EnvelopeItemsDescriptorOfInstrumentModelSimple = {
  items?: InstrumentModelSimple[] | null
  error?: string | null
}

export enum InstrumentType {
  NotSet = 'NotSet',
  Stock = 'Stock',
  News = 'News',
  Bond = 'Bond',
  EuroOption = 'Euro_Option',
  Futures = 'Futures',
  Commodity = 'Commodity',
  Index = 'Index',
  Forex = 'Forex',
  USOption = 'US_Option',
  Fund = 'Fund',
  Option = 'Option',
  Combo = 'Combo',
  Cfd = 'Cfd',
  Certificate = 'Certificate',
  Unknown = 'Unknown',
}

enum CarnegieInstrumentGroupType {
  Equity = 'Equity',
  MutualFunds = 'MutualFunds',
  Etf = 'Etf',
  Bonds = 'Bonds',
  CertificatesWarrants = 'CertificatesWarrants',
  OptionsForwardsFutures = 'OptionsForwardsFutures',
  Others = 'Others',
}

export type SimpleOrder = {
  /** @format int32 */
  orderId: number
  /** @pattern ^[{(]?[0-9a-fA-F]{8}[-]?(?:[0-9a-fA-F]{4}[-]?){3}[0-9a-fA-F]{12}[)}]?$ */
  carnegieOrderId: string
  /** @pattern ^[{(]?[0-9a-fA-F]{8}[-]?(?:[0-9a-fA-F]{4}[-]?){3}[0-9a-fA-F]{12}[)}]?$ */
  instrumentId: string
  instrumentName?: string | null
  instrumentType?: CarnegieInstrumentGroupType | null
  side?: string | null
  /** @pattern ^[{(]?[0-9a-fA-F]{8}[-]?(?:[0-9a-fA-F]{4}[-]?){3}[0-9a-fA-F]{12}[)}]?$ */
  accountId?: string | null
  accountNumber?: string | null
  orderState?: string | null
  orderSystem?: string | null
  orderSubState?: string | null
  /** @format decimal */
  quantity?: number | null
  /** @format decimal */
  monetaryAmount: number
  tradeCurrency?: string | null
  /** @format date-time */
  orderDate?: string | null
  error?: string | null
  canBeModified: boolean
  canBeCancelledByClient: boolean
  orderType?: string | null
  settlementCurrency?: string | null
}

export type EnvelopeItemsDescriptorOfSimpleOrder = {
  items?: SimpleOrder[] | null
  error?: string | null
}

export enum Side {
  NotSet = 'NotSet',
  Buy = 'Buy',
  Sell = 'Sell',
}

export type InsertSimpleOrderRequest = {
  /** @pattern ^[{(]?[0-9a-fA-F]{8}[-]?(?:[0-9a-fA-F]{4}[-]?){3}[0-9a-fA-F]{12}[)}]?$ */
  accountId: string
  /** @pattern ^[{(]?[0-9a-fA-F]{8}[-]?(?:[0-9a-fA-F]{4}[-]?){3}[0-9a-fA-F]{12}[)}]?$ */
  instrumentId: string
  side: Side
  settlementCurrency?: string | null
  sellAll: boolean
  /** @format decimal */
  amount?: number | null
  language?: string | null
}

export type InsertSimpleOrderResponse = {
  /** @pattern ^[{(]?[0-9a-fA-F]{8}[-]?(?:[0-9a-fA-F]{4}[-]?){3}[0-9a-fA-F]{12}[)}]?$ */
  orderId: string
  orderError?: string | null
  orderState?: string | null
}

export type CancelSimpleOrderRequest = {
  /** @pattern ^[{(]?[0-9a-fA-F]{8}[-]?(?:[0-9a-fA-F]{4}[-]?){3}[0-9a-fA-F]{12}[)}]?$ */
  accountId: string
  /** @pattern ^[{(]?[0-9a-fA-F]{8}[-]?(?:[0-9a-fA-F]{4}[-]?){3}[0-9a-fA-F]{12}[)}]?$ */
  orderId: string
}

export type CancelSimpleOrderResponse = {
  canceled: boolean
}

export type FundOrderInfoBuy = {
  /** @format decimal */
  tradingCapacity?: number | null
  /** @format double */
  transactionFee?: number | null
  /** @format decimal */
  minimumAmount: number
  /** @format decimal */
  maximumAmount?: number | null
}

export type FundOrderInfoSell = {
  /** @format decimal */
  netAmount?: number | null
  /** @format decimal */
  ninetyPercentRule?: number | null
  isSellAllAvailable: boolean
  /** @format double */
  transactionFee?: number | null
  /** @format decimal */
  minimumAmount: number
  /** @format decimal */
  maximumAmount?: number | null
}

export type DocumentInfoModel = {
  name?: string | null
  /** @format int32 */
  accountNumber: number
  /** @format date-time */
  statementPeriodStart?: string | null
  /** @format date-time */
  statementPeriodEnd?: string | null
  /** @pattern ^[{(]?[0-9a-fA-F]{8}[-]?(?:[0-9a-fA-F]{4}[-]?){3}[0-9a-fA-F]{12}[)}]?$ */
  documentId: string
  /** @format date-time */
  createdDate: string
  suffix?: string | null
}

export type PaginationResponseOfDocumentInfoModel = {
  items?: DocumentInfoModel[] | null
  /** @format int32 */
  totalPages: number
  /** @format int32 */
  currentPage: number
  /** @format int32 */
  usingLimit?: number | null
  /** @format int32 */
  totalRows: number
  error?: string | null
  httpStatus: HttpStatusCode
  success: boolean
  problemDetails?: ProblemDetails | null
}

export type PaginationResponseOfReportResponseModel = {
  items?: CustomerReportModel[] | null
  /** @format int32 */
  totalPages: number
  /** @format int32 */
  currentPage: number
  /** @format int32 */
  usingLimit?: number | null
  /** @format int32 */
  totalRows: number
  error?: string | null
  httpStatus: HttpStatusCode
  success: boolean
  problemDetails?: ProblemDetails | null
}

export enum UserSettingKey {
  INSTRUMENT_SEARCHES = 'INSTRUMENT_SEARCHES',
  LANGUAGE = 'LANGUAGE',
  SHOW_MORE_COLUMNS = 'SHOW_MORE_COLUMNS',
  SELECTED_WATCHLIST_ID = 'SELECTED_WATCHLIST_ID',
  ACCOUNT_SUMMARY_EXPANDED = 'ACCOUNT_SUMMARY_EXPANDED',
  ACCOUNT_GROUPING = 'ACCOUNT_GROUPING',
  ACCOUNT_CARD_PERIOD = 'ACCOUNT_CARD_PERIOD',
  ACCOUNT_USER_SETTINGS = 'ACCOUNT_USER_SETTINGS',
}

export type GetSettingsResponse = {
  value?: string | null
  valueType?: string | null
}

export type AvailableAccount = {
  /** @pattern ^[{(]?[0-9a-fA-F]{8}[-]?(?:[0-9a-fA-F]{4}[-]?){3}[0-9a-fA-F]{12}[)}]?$ */
  id: string
  number?: string | null
  accountType?: AccountType | null
  primaryOwner: boolean
  owners?: string[] | null
  withdrawableAmount?: CurrencyAmount | null
  policyHolder?: string | null
  isPolicyHolder: boolean
  validAsFromAccount: boolean
  validAsToAccount: boolean
  isDisabled: boolean
}

export type DefaultAccount = {
  displayNumber?: string | null
  accountType?: AccountType | null
  validAsToAccount: boolean
  isDisabled: boolean
  bankName?: string | null
  /** @format int32 */
  id: number
  ownerType?: string | null
  /** @format int32 */
  personId: number
  personName?: string | null
}

type TransferAmountLimitsRepsonse = {
  /** @format decimal */
  currentAmountPerPeriod: number
  currency?: string | null
  isPeriodLimitExceeded: boolean
  /** @format decimal */
  amountLeftToPeriodLimit: number
  /** @format decimal */
  customerSingleTransferLimitForRelatedDepot: number
  /** @format decimal */
  customerSingleTransferLimitForDefaultAccount: number
  /** @format decimal */
  customerPeriodTransferLimitForDefaultAccount: number
  /** @format int32 */
  customerPeriodInDaysForDefaultAccount: number
}

export type AvailableAccountsResponse = {
  availableAccounts?: AvailableAccount[] | null
  availableDefaultAccounts?: Record<string, DefaultAccount[]>
  transferLimit?: TransferAmountLimitsRepsonse | null
  canReceivePendingOrderAmount: boolean
  canReceivePendingAmount: boolean
  validationErrorCodes?: string[] | null
}

export type TransferRequest = {
  /** @pattern ^[{(]?[0-9a-fA-F]{8}[-]?(?:[0-9a-fA-F]{4}[-]?){3}[0-9a-fA-F]{12}[)}]?$ */
  fromAccount: string
  /** @format decimal */
  amount: number
  /** @pattern ^[{(]?[0-9a-fA-F]{8}[-]?(?:[0-9a-fA-F]{4}[-]?){3}[0-9a-fA-F]{12}[)}]?$ */
  toAccount?: string | null
  /** @format int32 */
  toExternalAccount?: number | null

  languageCode?: string
}

export type TransferResponse = {
  /** @format int64 */
  transferId: number
  qrData?: string
  bankIdSignUriDesktop?: string
  bankIdSignUriAppUniversal?: string
}

enum State {
  Created = 'Created',
  PendingApproval = 'PendingApproval',
  Approved = 'Approved',
  BookedInAbaSec = 'BookedInAbaSec',
  Exported = 'Exported',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
  Archived = 'Archived',
}

export type GetTransferOrdersResponse = {
  /** @format int64 */
  id: number
  /** @format date-time */
  created: string
  type?: string | null
  fromAccountNo?: string | null
  /** @pattern ^[{(]?[0-9a-fA-F]{8}[-]?(?:[0-9a-fA-F]{4}[-]?){3}[0-9a-fA-F]{12}[)}]?$ */
  fromAccountId: string
  toAccountNo?: string | null
  /** @pattern ^[{(]?[0-9a-fA-F]{8}[-]?(?:[0-9a-fA-F]{4}[-]?){3}[0-9a-fA-F]{12}[)}]?$ */
  toAccountId?: string | null
  /** @format decimal */
  amount: number
  state: State
  externalPaymentState?: string | null
  externalPaymentSubState?: string | null
  displayState?: string | null
  isCancelable: boolean
  /** @pattern ^[{(]?[0-9a-fA-F]{8}[-]?(?:[0-9a-fA-F]{4}[-]?){3}[0-9a-fA-F]{12}[)}]?$ */
  createdByPartyId: string
}

export type EnvelopeItemsDescriptorOfGetTransferOrdersResponse = {
  items?: GetTransferOrdersResponse[] | null
  error?: string | null
}

// Helper
//type Modify<T, R> = R & Omit<T, keyof R>
