import { FundOrderType } from '@common/api/response'
import { SystemMessageList } from '@common/components/systemMessages'
import { useInfrontInstrument } from '@common/hooks/infront/sdk/useInfrontInstrument'
import { useInstrumentWithFallbackData } from '@common/hooks/useInstrumentWithFallbackData'
import { InstrumentIdContainer } from '@common/instrumentIdContainer'
import { getFormattedTimestamp } from '@common/utils/infront/getFormattedTimestamp'

import { useTranslation } from 'react-i18next'

import { Box, FlexRow, Heading3, SkeletonRect, Spacer, Text } from '@carnegie/duplo'

type FundOrderHeaderProps = {
  fundOrderType: FundOrderType
  instrumentIdContainer: InstrumentIdContainer
}

export const FundOrderHeader = ({ fundOrderType, instrumentIdContainer }: FundOrderHeaderProps) => {
  const { observableInstrument, isLoading } = useInfrontInstrument(instrumentIdContainer?.infrontInstrument, [
    InfrontSDK.SymbolField.Currency,
    InfrontSDK.SymbolField.SymbolType,
    InfrontSDK.SymbolField.TradeTime,
    InfrontSDK.SymbolField.PreLastTradeDate,
    InfrontSDK.SymbolField.Last,
    InfrontSDK.SymbolField.PreLastTradedAt,
  ])

  const { t } = useTranslation()
  const instrumentWithFallback = useInstrumentWithFallbackData(instrumentIdContainer, ['name', 'currency', 'isin'])
  const name = instrumentWithFallback?.name
  const currency = instrumentWithFallback.currency
  const isFund = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.SymbolType) === 'Funds'
  const formattedTimestamp = !isLoading ? getFormattedTimestamp(observableInstrument, isFund) : undefined

  return (
    <Box>
      <FlexRow alignItems="center">
        <Text variant="overline" color="text-default">
          {fundOrderType === 'buy' && t('Köporder')}
          {fundOrderType === 'sell' && t('Säljorder')}
        </Text>
        <Text variant="overline" ml={4} mr={4} color="text-low-emphasis">
          {formattedTimestamp}
        </Text>
        {currency && (
          <Text variant="overline" color="text-low-emphasis">
            - {currency}
          </Text>
        )}
      </FlexRow>
      <Box spaceY={16}>
        {name ? <Heading3>{name}</Heading3> : <SkeletonRect py={4} maxWidth={288} height={48} width="full" />}
        <SystemMessageList segmentId="fund-orders" />
      </Box>
      <Spacer height={8} />
    </Box>
  )
}
