import { SkeletonLoader } from '@common/components/SkeletonLoader'
import { useSelectedHoldingAccount } from '@common/hooks/useHoldings'
import { css } from '@emotion/react'

import { useTranslation } from 'react-i18next'

import { FlexRow, SegmentWithTabs, Tab, TabPanel, Tabs, TabsList, Text } from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import { AccountAllocationChart } from './accountAllocation/AccountAllocationChart'

export const AccountAllocation = observer(function AccountAllocation() {
  const { t } = useTranslation()
  const { account: selectedHoldingAccount, isLoading } = useSelectedHoldingAccount()

  const showGraph = selectedHoldingAccount?.holdings?.instruments?.length > 0
  const noGraphPlaceholder = (
    <FlexRow
      p={16}
      justifyContent="center"
      alignItems="center"
      css={css`
        height: 146px;
      `}
    >
      <Text variant="body2" color="text-low-emphasis">
        {t('Här visas allokeringen när det finns innehav på kontot.')}
      </Text>
    </FlexRow>
  )

  const getPanel = (type) => (
    <SkeletonLoader height={184} loading={isLoading} m={16}>
      {() =>
        showGraph ? <AccountAllocationChart accountId={selectedHoldingAccount?.id} type={type} /> : noGraphPlaceholder
      }
    </SkeletonLoader>
  )

  return (
    <SegmentWithTabs title={t('Allokering')} height={288}>
      {/* set height to 288 as equal to graph segment */}
      <Tabs defaultValue="asset">
        <TabsList>
          <Tab value="asset">
            <Text variant="body1">{t('Tillgångsslag')}</Text>
          </Tab>
          <Tab value="region">
            <Text variant="body1">{t('Region')}</Text>
          </Tab>
        </TabsList>
        <TabPanel value="asset">{getPanel('assets')}</TabPanel>
        <TabPanel value="region">{getPanel('region')}</TabPanel>
      </Tabs>
    </SegmentWithTabs>
  )
})

AccountAllocation.displayName = 'AccountAllocation'
