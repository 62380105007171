import React, { ReactNode } from 'react'

import { FlexCol, SkeletonRect, SkeletonText } from '@carnegie/duplo'

import { DelayMount } from './DelayMount'

export const RouteLoadingIndicator = ({ skeletonBackLink, children }: RouteLoadingIndicatorProps) => {
  return <React.Suspense fallback={<FallbackDisplay skeletonBackLink={skeletonBackLink} />}>{children}</React.Suspense>
}

type FallbackDisplayProps = {
  skeletonBackLink?: boolean
}

const FallbackDisplay = ({ skeletonBackLink }: FallbackDisplayProps) => {
  // We only show the fallback skeleton if more than 300ms passes

  return (
    <DelayMount delay={300}>
      <FlexCol p={16}>
        {skeletonBackLink && <SkeletonRect height={20} width={90} />}
        <SkeletonText width={170} height={48} />
      </FlexCol>
    </DelayMount>
  )
}

type RouteLoadingIndicatorProps = {
  children?: ReactNode
  skeletonBackLink?: boolean
}
