import useSWR from 'swr'

import { useHttp } from '../stores/store'

export function useHttpSwr<T>(key: string, processData?: (data: T) => T, dedupingInterval?: number) {
  const http = useHttp()

  const swr = useSWR<T>(
    key,
    async (): Promise<T> => {
      const data = await http.get<T>(key) // Assuming http.get now correctly uses the generic T
      return processData ? processData(data) : data
    },
    { dedupingInterval: dedupingInterval }
  )

  return swr
}
