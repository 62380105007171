import parse, { Element } from 'html-react-parser'

type HtmlNode = Pick<Element, 'tagName' | 'attribs'> & { text: string }

type HtmlWithCustomComponentsProps = {
  html: string
  replaceWithComponent: (htmlNode: HtmlNode) => JSX.Element
}

export const HtmlWithCustomComponents = ({ html, replaceWithComponent }: HtmlWithCustomComponentsProps) => {
  return (
    <>
      {parse(html, {
        replace: (domNode) => {
          if (domNode instanceof Element && domNode.attribs) {
            return replaceWithComponent({
              tagName: domNode.tagName,
              attribs: domNode.attribs,
              text: domNode.children.reduce((acc, c) => {
                // Check if the node is a text node before attempting to access its data
                if (c.nodeType === Node.TEXT_NODE) {
                  acc += (c as unknown as Text).data
                }
                return acc
              }, ''),
            })
          }
        },
      })}
    </>
  )
}
