import { InfrontInstrument } from '@common/api/response'

import { useEffect } from 'react'

import { useLocalObservable } from 'mobx-react-lite'

import { useFeatures } from '../../useFeatures'
import { ObservableInfrontInstrument, ObservableInfrontInstrumentOptions } from './observableInfrontInstrument'
import { useInfrontSDK } from './useInfrontSdk'

// Feed 5460 is feed we have no access to hence used for simulating infront problems
const NO_ACCESS_INFRONT_FEED = 5460

interface Return {
  observableInstrument?: ObservableInfrontInstrument
  error: InfrontSDK.ErrorBase | { title: string; description: string }
  isLoading: boolean
}

/**
 * ## ⚠️IMPORTANT⚠️
 * @deprecated Use the `useInstrumentData` or `useInfrontInstrumentDataList` hook from `@carnegie/digital-channels-frontend` instead.
 * ### Requires wrapping component in MobX `observer(...)`
 * @param infrontInstrument
 * @param usedFields
 * @param options
 * @returns
 */
export function useInfrontInstrument(
  infrontInstrument: InfrontInstrument,
  usedFields: InfrontSDK.SymbolField[],
  options: ObservableInfrontInstrumentOptions = { observe: true }
): Return {
  const observableInstrument = useLocalObservable(() => new ObservableInfrontInstrument())
  const { infrontSDK } = useInfrontSDK()
  const { testNoInfrontAccess } = useFeatures()

  useEffect(() => {
    // We must be able to conditionally run this hook since in some cases we might be missing instrumentId
    if (infrontSDK && infrontInstrument) {
      observableInstrument.init(
        infrontSDK,
        testNoInfrontAccess
          ? new Infront.Instrument(NO_ACCESS_INFRONT_FEED, infrontInstrument.ticker)
          : infrontInstrument,
        usedFields,
        options
      )
    }

    return () => observableInstrument.dispose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infrontSDK, JSON.stringify(infrontInstrument), JSON.stringify(usedFields)])

  // We must be able to conditionally run this hook since in some cases we might be missing instrumentId
  if (!infrontInstrument) {
    return {
      observableInstrument: undefined,
      error: { title: 'Instrument id missing', description: 'Instrument id missing' },
      isLoading: false,
    }
  } else {
    return {
      observableInstrument: observableInstrument.dataLoaded ? observableInstrument : undefined,
      error: observableInstrument.error,
      isLoading: !!(!observableInstrument.dataLoaded && !observableInstrument.error),
    }
  }
}
