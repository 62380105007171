import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Button, FlexRow, IconButton, IconTrashcan, Paragraph, Popper, Spacer } from '@carnegie/duplo'

type TrashCanButtonWithConfirmProps = {
  onClick: () => void
  size: 'small' | 'medium' | 'large'
  loading?: boolean
  iconTooltipText?: ReactNode
  bodyText?: ReactNode
  confirmButtonText?: ReactNode
}

export const TrashCanButtonWithConfirm = ({
  onClick,
  size,
  loading,
  iconTooltipText,
  bodyText,
  confirmButtonText,
}: TrashCanButtonWithConfirmProps) => {
  const { t } = useTranslation()

  return (
    <Popper
      id="confirm-delete"
      toggle={
        <IconButton
          loading={loading}
          size={size}
          variant="uncontained"
          icon={IconTrashcan}
          tooltip={iconTooltipText ?? t('Ta bort')}
        />
      }
    >
      {({ close }) => (
        <Box p={16}>
          <Paragraph variant="body1">{bodyText ?? t('Är du säker på att du vill ta bort?')}</Paragraph>
          <Spacer height={16} />
          <FlexRow alignItems="center">
            <Button
              variant="primary"
              size="medium"
              mr={16}
              onClick={async () => {
                close()
                onClick()
              }}
            >
              {confirmButtonText ?? t('Ta bort')}
            </Button>
            <Button variant="secondary" size="medium" onClick={close}>
              {t('Avbryt')}
            </Button>
          </FlexRow>
        </Box>
      )}
    </Popper>
  )
}
