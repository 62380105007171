import { FundOrderInfoBuy, FundOrderInfoSell } from '@common/api/response'
import { useHttpSwr } from '@common/hooks/useHttpSwr'

export function useFundOrderInfoBuy(accountId: string, instrumentId: string) {
  const { data, error } = useHttpSwr<{ item: FundOrderInfoBuy }>(
    accountId && instrumentId && `/digital-channels/order/fund-order-info/${accountId}/${instrumentId}/buy`
  )

  return { fundOrderInfo: data?.item, error }
}

export function useFundOrderInfoSell(accountId: string, instrumentId: string) {
  const { data, error } = useHttpSwr<{ item: FundOrderInfoSell }>(
    accountId && instrumentId && `/digital-channels/order/fund-order-info/${accountId}/${instrumentId}/sell`
  )

  return { fundOrderInfo: data?.item, error }
}
