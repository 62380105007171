import { FundOrderType } from '@common/api/response'
import { NoDataDash } from '@common/components/NoDataFallback'
import { StarBar } from '@common/components/StarBar'
import { useInfrontInstrument } from '@common/hooks/infront/sdk/useInfrontInstrument'
import { useCarnegieInstrument } from '@common/hooks/useCarnegieInstrument'
import { useInstrumentWithFallbackData } from '@common/hooks/useInstrumentWithFallbackData'
import { InstrumentIdContainer } from '@common/instrumentIdContainer'
import { getMorningStarFactsheetLink } from '@common/utils/morningstarLink'

import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { formatNumber } from '@carnegie/digital-channels-frontend'
import {
  Box,
  DividerLine,
  FlexRow,
  Icon,
  IconChevronDown,
  IconChevronUp,
  IconDocumentLineChart,
  LinkButton,
  LinkExternal,
  ListItem,
  ListItemRow,
  Segment,
  SkeletonRect,
  Text,
} from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import { ListItemValue } from '../instrumentDrawer/ListItemValue'
import { InstrumentFundsFees } from '../instrumentDrawer/funds/InstrumentFundsTradingAndFees'

type FundOrderInformationProps = {
  isMobile: boolean
  instrumentIdContainer: InstrumentIdContainer
  fundOrderType: FundOrderType
}

export const FundOrderInformation = observer(
  ({ isMobile, instrumentIdContainer, fundOrderType }: FundOrderInformationProps) => {
    const { observableInstrument, error } = useInfrontInstrument(instrumentIdContainer?.infrontInstrument, [
      InfrontSDK.SymbolField.TradeTime,
      InfrontSDK.SymbolField.PreLastTradeDate,
      InfrontSDK.SymbolField.Last,
      InfrontSDK.SymbolField.PreLastTradedAt,
      InfrontSDK.SymbolField.FundStarRating,
      InfrontSDK.SymbolField.FundRiskLevel,
      InfrontSDK.SymbolField.FundPurchaseFee,
      InfrontSDK.SymbolField.FundRedemptionFee,
      InfrontSDK.SymbolField.FundManagementFee,
      InfrontSDK.SymbolField.FundOngoingCharge,
      InfrontSDK.SymbolField.FundPerformanceFee,
    ])
    const [expanded, setExpanded] = useState(false)
    const { t, i18n } = useTranslation()

    const { carnegieInstrument, isLoading: isLoadingCarnegieInstrument } = useCarnegieInstrument(
      instrumentIdContainer,
      i18n.language
    )

    const instrumentWithFallback = useInstrumentWithFallbackData(instrumentIdContainer, ['name', 'currency', 'isin'])
    const isin = instrumentWithFallback?.isin
    const morningStarDocLink = getMorningStarFactsheetLink(carnegieInstrument?.instrumentIdCarnegie, i18n.language)

    const fundRiskLevel = carnegieInstrument?.riskTolerancePRIIPS

    // Wrong typing from infront so recasting
    const stars = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.FundStarRating) as unknown as number

    const fundFactSheet =
      isin === undefined ? (
        <SkeletonRect height={20} width={96} />
      ) : (
        <LinkExternal truncate variant="subtitle2" target="_blank" href={morningStarDocLink} title={t('Fondfaktablad')}>
          <FlexRow>
            <Box mr={4}>
              <Icon color="current" icon={IconDocumentLineChart} size={20} />
            </Box>
            <span>{t('Fondfaktablad')}</span>
          </FlexRow>
        </LinkExternal>
      )

    return (
      <Segment variant="contained" headingVariant="overline" title={t('Information')} noContentPadding>
        {isMobile ? (
          <ListItem divider>
            <ListItemRow title={fundFactSheet} />
          </ListItem>
        ) : (
          <ListItem divider>
            <ListItemRow title={<Text variant="support2">{t('Faktablad')}</Text>} value={fundFactSheet} />
          </ListItem>
        )}
        <ListItem divider>
          <ListItemRow
            title={<Text variant="support2">{t('Risknivå')}</Text>}
            value={
              <ListItemValue
                loading={fundRiskLevel === undefined}
                value={fundRiskLevel ? fundRiskLevel + t(' av 7') : '-'}
              />
            }
          />
        </ListItem>
        <ListItem divider>
          <ListItemRow
            title={<Text variant="support2">{t('Morningstars fondbetyg')}</Text>}
            value={
              <ListItemValue
                loading={stars === undefined}
                value={
                  stars ? (
                    <StarBar stars={stars} data-testid="morningstar-star-bar-order-information" />
                  ) : error ? (
                    <NoDataDash />
                  ) : (
                    t('Betyg saknas')
                  )
                }
              />
            }
          />
        </ListItem>
        {!expanded ? (
          <InstrumentFundsFees
            shownFees="management-fee"
            carnegieInstrument={carnegieInstrument}
            observableInstrument={observableInstrument}
            isLoadingInfrontInstrument={isLoadingCarnegieInstrument}
          />
        ) : (
          <>
            <InstrumentFundsFees
              shownFees="all"
              carnegieInstrument={carnegieInstrument}
              observableInstrument={observableInstrument}
              isLoadingInfrontInstrument={isLoadingCarnegieInstrument}
            />
            <ListItem divider>
              <ListItemRow
                title={
                  <Text variant="support2">
                    {fundOrderType === 'buy' ? t('Handelsintervall köp') : t('Handelsintervall sälj')}
                  </Text>
                }
                value={
                  <ListItemValue
                    loading={isLoadingCarnegieInstrument}
                    value={
                      fundOrderType === 'buy'
                        ? carnegieInstrument?.tradingPeriodBuy
                        : carnegieInstrument?.tradingPeriodSell
                    }
                  />
                }
              />
            </ListItem>
            <ListItem divider>
              <ListItemRow
                title={<Text variant="support2">{t('Bryttid för handel')}</Text>}
                value={
                  <ListItemValue
                    loading={isLoadingCarnegieInstrument}
                    value={carnegieInstrument?.internalOrderCutOffTime}
                  />
                }
              />
            </ListItem>
            <ListItem divider>
              <ListItemRow
                title={
                  <Text variant="support2">{fundOrderType === 'buy' ? t('Affärsdag köp') : t('Affärsdag sälj')}</Text>
                }
                value={
                  <ListItemValue
                    value={
                      fundOrderType === 'buy' ? carnegieInstrument?.businessDayBuy : carnegieInstrument?.businessDaySell
                    }
                    loading={isLoadingCarnegieInstrument}
                  />
                }
              />
            </ListItem>
            <ListItem divider>
              <ListItemRow
                title={<Text variant="support2">{t('Handlas i')}</Text>}
                value={<ListItemValue loading={isLoadingCarnegieInstrument} value={carnegieInstrument?.currency} />}
              />
            </ListItem>
            <ListItem>
              <ListItemRow
                title={<Text variant="support2">{t('Belåningsgrad')}</Text>}
                value={
                  <ListItemValue
                    loading={isLoadingCarnegieInstrument}
                    value={formatNumber(carnegieInstrument?.collateralValue, { ratio: false, decimals: 0 })}
                    unit="%"
                  />
                }
              />
            </ListItem>
          </>
        )}
        <DividerLine />
        <FlexRow height={40} width="full" alignItems="center" justifyContent="center">
          <LinkButton onClick={() => setExpanded(!expanded)} size="small">
            <Text variant="subtitle2">{expanded ? t('Minimera') : t('Mer information')}</Text>
            <Icon size={16} color="current" mr={4} icon={expanded ? IconChevronUp : IconChevronDown} />
          </LinkButton>
        </FlexRow>
      </Segment>
    )
  }
)
