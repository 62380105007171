import React, { ReactNode } from 'react'

export const SelectedAccountIdContext = React.createContext<string>(undefined)

type SelectedAccountIdProviderProps = {
  selectedAccountId: string
  children?: ReactNode
}

export const SelectedAccountIdProvider = ({ children, selectedAccountId }: SelectedAccountIdProviderProps) => {
  return <SelectedAccountIdContext.Provider value={selectedAccountId}>{children}</SelectedAccountIdContext.Provider>
}
