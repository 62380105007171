import { useScrollToTop } from '@common/components/ScrollToTop'
import { useDocumentTitle } from '@common/hooks/useDocumentTitle'
import { useFeatures } from '@common/hooks/useFeatures'
import { useProfile } from '@common/hooks/useProfile'
import { useApi, useAuth } from '@common/stores/store'
import { getTitleFromRoute } from '@common/utils/urlHelpers'
import { withAITracking } from '@microsoft/applicationinsights-react-js'
import * as Sentry from '@sentry/react'

import React, { Suspense, useEffect, useState } from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import { BrowserRouter, Link, Route, Routes, useLocation } from 'react-router-dom'

import { DuploProvider } from '@carnegie/duplo'

import { ClickToComponent } from 'click-to-react-component'
import { observer } from 'mobx-react-lite'
import { useIsTesting } from 'tests/utils/useIsTesting'

import { AppErrorBoundary } from './AppErrorBoundary'
import AppRoutes from './AppRoutes'
import { reactPlugin } from './appInsights'
import { AuthProviderContainer } from './common/components/AuthProviderContainer'
import { DrawerStoreListener, useDrawerStore } from './common/components/drawer/useDrawerStore'
import { useSetupUserActivityManager } from './common/hooks/useUserActivityManager'
import { GlobalStylePortal } from './globalStyles'
import i18n from './i18n'

const LoginSupportPage = React.lazy(() => import('./pages/login/LoginSupportPage'))
const PermissionDeniedPage = React.lazy(() => import('./pages/login/PermissionDeniedPage'))
const NotFoundPage = React.lazy(() => import('./pages/notFound/NotFoundPage'))
const AppOrderPage = React.lazy(() => import('./pages/AppOrderPage'))
const EnvironmentBanner = React.lazy(() => import('./EnvironmentBanner'))

type RequireAuthenticationProps = {
  children: JSX.Element
}

if (!import.meta.env.VITE_MOCK_API) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.DEV ? 'development' : 'production',
    //https://carnegie-investment-bank.sentry.io/issues/12768944/?notification_uuid=2f51c1f6-33c4-4890-a821-3d364d4a9f47&project=4508182198485072&referrer=weekly_report
    ignoreErrors: ['TypeError: Failed to fetch', 'Load failed TypeError'],
    beforeSend(event) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception && event.event_id) {
        //Temporarily disable the report dialog
        // const sentryConfig: object = JSON.parse(sessionStorage.getItem('sentryConfig') || '{}')
        // Sentry.showReportDialog({
        //   ...sentryConfig,
        //   eventId: event.event_id,
        //   colorScheme: 'system',
        //   showBranding: false,
        //   enableScreenshot: true,
        // })
      }
      return event
    },
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayCanvasIntegration(),
      Sentry.replayIntegration({
        blockAllMedia: false,
        beforeAddRecordingEvent: (event) => {
          // Do not capture fetch/xhr requests, unless the response code is 500
          if (
            event.data.tag === 'performanceSpan' &&
            (event.data.payload.op === 'resource.fetch' || event.data.payload.op === 'resource.xhr') &&
            event.data.payload.data.statusCode < 299
          ) {
            return null
          }

          return event
        },
      }),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

const RequireAuthentication = observer(({ children }: RequireAuthenticationProps) => {
  const auth = useAuth()

  // Initialize the user activity manager that keeps track of if the user is
  // idle and can then logout.
  useSetupUserActivityManager()

  if (auth.isAuthenticating) return null

  if (!auth.isAuthenticated) return <PermissionDeniedPage />

  return children
})

const Pages = observer(() => {
  const { pathname } = useLocation()
  const title = getTitleFromRoute(pathname)

  useDocumentTitle(title)
  useScrollToTop()

  return (
    <Suspense fallback={null}>
      <Routes>
        <Route path="/login/support" element={<LoginSupportPage />} />
        <Route
          path="*"
          element={
            <RequireAuthentication>
              <AppErrorBoundary>
                <AppRoutes />
              </AppErrorBoundary>
            </RequireAuthentication>
          }
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Suspense>
  )
})

const App = observer(() => {
  const { t } = useTranslation()
  const drawerStore = useDrawerStore()

  return (
    <BrowserRouter>
      <DuploProvider
        componentSettings={{ menu: { size: 'large' }, link: { routerLink: Link } }}
        componentTranslations={{
          select: {
            allValuesText: t('Alla valda'),
            multipleValuesText: (valuesCount) => `${valuesCount} ${t('valda')}`,
          },
        }}
      >
        <Routes>
          <Route
            path="/webviewsforapp/*"
            element={
              <>
                <GlobalStylePortal insideAppWebview />
                <AppForWebView />
              </>
            }
          />
          <Route
            path="*"
            element={
              <AuthProviderContainer>
                <AppWeb />
              </AuthProviderContainer>
            }
          />
        </Routes>
      </DuploProvider>
      <DrawerStoreListener store={drawerStore} />
    </BrowserRouter>
  )
})
App.displayName = 'App'

const AppForWebView = observer(() => {
  const api = useApi()
  const [gotCurityCookie, setGotCurityCookie] = useState(false)
  const auth = useAuth()

  const searchParams = new URLSearchParams(window.location.search)
  const params = Object.fromEntries(searchParams.entries())

  const instrumentIdContainer = {
    infrontInstrument: new Infront.Instrument(parseInt(params.feed), decodeURIComponent(params.ticker)),
  }

  const forcedCarnegieToken = params.carnegieToken || window.carnegieToken
  auth.forcedCarnegieToken = forcedCarnegieToken

  useEffect(() => {
    i18n.changeLanguage(params.language)

    async function fn() {
      try {
        // Send in carnegietoken from app - get an assertiontoken for Curity auth
        const res = await api.loginAssertion(forcedCarnegieToken)
        const assertionToken = res.item?.accessToken

        // This response contains Set-cookie headers which makes us authenticated
        await api.jwtAssertion(assertionToken)
        setGotCurityCookie(true)
      } catch (e) {
        console.log('Error', JSON.stringify(e))
      }
    }
    fn()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const tgwProvider = parseInt(params.tgwProvider)
  const tgwService = parseInt(params.tgwService)
  const tgwEnabled = params.tgwEnabled === 'true'
  const expires = params.expires

  const isNumber = (numberString: string): boolean => !isNaN(Number(numberString))
  const orderId = isNumber(params.orderId) ? parseInt(params.orderId) : undefined

  if (!gotCurityCookie) {
    return null
  }

  return (
    <Routes>
      <Route
        path="order"
        element={
          <AppOrderPage
            infrontToken={params.infrontToken || window.infrontToken}
            tgwProvider={tgwProvider}
            tgwService={tgwService}
            tgwEnabled={tgwEnabled}
            expires={expires}
            xid={params.xid}
            instrumentIdContainer={instrumentIdContainer}
            accountId={params.accountId}
            orderId={orderId}
            phone={params.phone}
          />
        }
      />
    </Routes>
  )
})
AppForWebView.displayName = 'AppForWebView'

const AppWeb = observer(() => {
  const auth = useAuth()
  const features = useFeatures()
  const { profile } = useProfile()
  const { t } = useTranslation()

  //Handle settings when running tests: ?isTesting=true
  useIsTesting()

  useEffect(() => {
    auth.checkImpersonationViaURLParams()

    if (!auth.impersonationViaUrlParamsInitiated) {
      auth.checkIfLoggedIn()
    }
  }, [auth])

  useEffect(() => {
    if (auth.isAuthenticated) {
      // Intentionally hide the user's email, username and name to comply with GDPR - leaving the code here for now
      const user = {
        isPhysicalPerson: profile?.isPhysicalPerson,
        // email: profile?.email,
        // username: profile?.firstName + ' ' + profile?.surName,
        //name: '',
        email: 'no-email-because-of-gdpr@nowhere.com',
        username: 'no-username-because-of-gdpr',
        name: 'no-name-because-of-gdpr',
      }
      // user.name = user.username

      const lang = {
        title: t('Det ser ut som vi har problem'),
        subtitle: t('Vårt team har blivit notifierade och jobbar på en snabb lösning'),
        subtitle2: t('Om du har några frågor, vänligen kontakta oss.'),
        labelName: t('Namn'),
        labelEmail: t('E-post'),
        labelComments: t('Vad hände?'),
        messagePlaceholder: t('Beskriv vad som hände'),
        labelClose: t('Stäng'),
        labelSubmit: t('Skicka'),
        errorGeneric: t('Något gick fel'),
        errorFormEntry: t('Vänligen fyll i alla fält'),
        successMessage: t('Tack för att du hjälper oss att förbättra vår service!'),
      }

      Sentry.setUser(user)
      sessionStorage.setItem('sentryConfig', JSON.stringify({ ...user, ...lang }))
    }
  }, [auth.isAuthenticated, profile, t])

  return auth.impersonationViaUrlParamsInitiated ? null : (
    <>
      <ClickToComponent editor="cursor" />
      {!features.isProd && <EnvironmentBanner />}
      <GlobalStylePortal />
      <AppErrorBoundary>
        <Pages />
      </AppErrorBoundary>
    </>
  )
})
AppWeb.displayName = 'AppWeb'

export default Sentry.withProfiler(
  withAITracking(reactPlugin, withTranslation()(App), 'PBOnline', 'application-insight-wrapper')
)
