import { InfrontTradingAppIds } from '@carnegie/digital-channels-frontend'

import { makeObservable } from 'mobx'

import { appInsights } from '../../appInsights'
import i18n from '../../i18n'
import { Api } from '../api/api'
import { InfrontToken } from '../api/response'
import { getFeatures } from '../hooks/useFeatures'
import { fireTrackEvent } from '../utils/analyticsEvent'

type TradingEventType =
  | 'onActivePortfolioReady'
  | 'onActivePortfolioChanged'
  | 'onTradingConnected'
  | 'onTradingLostConnection'

const debugLog = false

type TradingConnectionStatus =
  | 'uninitialized'
  | 'connecting'
  | 'connected'
  | 'connected-and-portfolio-ready'
  | 'disconnected'
  | 'error'
  | 'lost-connection'
  | 'reconnecting'

export class InfrontStore {
  private api: Api
  // Not needed just do Infront. (global) infront: typeof Infront
  infrontUtil: typeof InfrontUtil
  infrontUi: Infront.UI
  infrontSDK: InfrontSDK.SDK

  hasLoaded = false

  logout = () => {
    this.infrontUi.logout()
  }

  /**
   * Note: This is related to infront being initialized correctly, if there is an error with trading for example
   * that error status is located in the tradingConnectionStatus property **/
  hasError: boolean = false

  tradingReconnectCount = 0
  isTgwEnabled: boolean = false
  activePortfolioStatus: 'none' | 'selected' | 'changed' | 'ready' = 'none'
  activePortfolioName: string

  // If we got a portfolio ready event once it means Infront has connected to the TGW and at least once fired the portfolio ready event
  // this seems to indicate that Infront has fetched all portfolio info. We need this for the OrderEntry widget because if we display it before
  // the active portfolio has been ready at least once it doesn't load any volume of last price info
  private activePortfolioReady = false

  infrontProviderId: number = 0

  // Our own abstraction of the TGW connection status basically the same as the Infront version but we have also added an error status when the
  // connection times out
  tradingConnectionStatus: TradingConnectionStatus = 'uninitialized'

  constructor(api?: Api) {
    makeObservable(this, {
      hasLoaded: true,
      hasError: true,
      tradingReconnectCount: true,
      isTgwEnabled: true,
      activePortfolioStatus: true,
      activePortfolioName: true,
      tradingConnectAndSetPortfolio: true,
      tradingConnectionStatus: true,
    })

    this.api = api

    const { testFailingTgw } = getFeatures()

    if (testFailingTgw) this.simulateTgwError = testFailingTgw
  }

  // Can also be set by setting ?features=testFailingTgw in the url
  private simulateTgwError = false

  tradingConnectAndSetPortfolio = async (portfolioName: string) => {
    if (!this.hasLoaded) {
      throw new Error('Infront has not loaded yet')
    }

    this.updateTradingConnectionStatus()

    // Should we try to login to TGW?
    // If we are already in the process of connecting skip this step
    if (
      this.tradingConnectionStatus === 'uninitialized' ||
      this.tradingConnectionStatus === 'disconnected' ||
      this.tradingConnectionStatus === 'error'
    ) {
      // Start logging in
      if (!this.simulateTgwError) {
        this.infrontUi.getModel().delayedAutoLoginTrading()
      }

      try {
        await this.waitForInfrontTradingConnected()
      } catch (error) {
        this.tradingConnectionStatus = 'error'
        console.error('Trading connection failed', error)
      }
    }

    // Wait for connection to complete
    if (
      this.tradingConnectionStatus === 'uninitialized' ||
      this.tradingConnectionStatus === 'disconnected' ||
      this.tradingConnectionStatus === 'error' ||
      this.tradingConnectionStatus === 'connecting'
    ) {
      try {
        await this.waitForInfrontTradingConnected()
      } catch (error) {
        this.tradingConnectionStatus = 'error'
        console.error('Trading connection failed', error)
      }
    }

    if (portfolioName && this.activePortfolioName !== portfolioName) {
      await this.setActivePortfolio(portfolioName)
    }
  }

  private setActivePortfolio = async (portfolioName: string) => {
    this.activePortfolioStatus = 'selected'
    this.activePortfolioName = portfolioName

    return new Promise<void>((resolve, reject) => {
      const removeEventHandler = this.addTradingEventHandler((type, data) => {
        if (type === 'onActivePortfolioReady') {
          const portfolioDataSet = data as Infront.PortfolioDataSet

          // Make sure this is "our" event
          if (portfolioDataSet?.name === portfolioName) {
            clearTimeout(timeoutId)
            removeEventHandler()
            resolve()
          }
        }
      })

      const timeoutId = setTimeout(() => {
        removeEventHandler()
        reject(
          new Error(
            'Set active portfolio timed out, never received the onActivePortfolioReady event (' + portfolioName + ')'
          )
        )
      }, 3000)

      // This will fire event that we subscribe to and resolve (or reject) the promise
      this.infrontUi.getModel().setActivePortfolio(portfolioName)
    })
  }

  // In some cases Infront has not properly implemented a status (for example for things like
  // reconnecting so we need a way to overide their value and use our own instead)
  private updateTradingConnectionStatus(overrideStatus?: TradingConnectionStatus) {
    const statusMap: Record<Infront.InfrontStatus, TradingConnectionStatus> = {
      [Infront.InfrontStatus.Uninitialized]: 'uninitialized',
      [Infront.InfrontStatus.Connecting]: 'connecting',
      [Infront.InfrontStatus.Connected]: 'connected',
      [Infront.InfrontStatus.Disconnected]: 'disconnected',
    }
    let status = overrideStatus ? overrideStatus : statusMap[this.infrontUi.getModel().getTradingStatus()]

    if (status === 'connected' && this.activePortfolioReady) {
      status = 'connected-and-portfolio-ready'
    }

    this.tradingConnectionStatus = status
  }

  private waitForInfrontTradingConnected() {
    return new Promise<void>((resolve, reject) => {
      const timeoutId = setTimeout(() => {
        removeEventHandler()
        clearTimeout(timeoutId)
        reject(new Error('Trading connected timed out'))
      }, 3000)

      const removeEventHandler = this.addTradingEventHandler((type) => {
        if (type === 'onTradingConnected') {
          removeEventHandler()
          clearTimeout(timeoutId)

          resolve()
        }
      })
    })
  }

  private awaitDomContentLoaded = () => {
    const domHasContentLoaded = /complete|interactive|loaded/.test(document.readyState)
    if (!domHasContentLoaded) {
      return new Promise<void>((resolve) => {
        const callback = () => {
          resolve()
          document.removeEventListener('DOMContentLoaded', callback)
        }

        document.addEventListener('DOMContentLoaded', callback)
      })
    }
  }

  async init(infrontToken?: InfrontToken, insideWebView = false) {
    if (this.infrontUi) return

    this.hasLoaded = false
    this.hasError = false

    try {
      let infrontTokenResponse: InfrontToken

      if (!infrontToken) {
        console.log('Fetching token from API...')
        const [tokenResponse] = await Promise.all([
          this.api.fetchInfrontToken(),
          // Dom content must be loaded before we are allowed to interact with Infront.UI, read more here: https://doc.infrontfinance.com/v3/GettingStarted
          this.awaitDomContentLoaded(),
        ])
        if (!tokenResponse) {
          throw new Error('Could not get an infront token, the value was: ' + infrontTokenResponse)
        } else {
          infrontTokenResponse = tokenResponse
        }
      } else {
        infrontTokenResponse = infrontToken
      }

      //This needs to be set as infront needs to be able to send this to other systems eg the terminal/abasec
      //for Omni orders to show source
      //https://dev.azure.com/carnegieinvestmentbank/CarnegieIT/_workitems/edit/75223
      Infront.endUserApplication = Infront.EndUserApplications.AppnuWebToolkitDirect

      this.infrontUtil = InfrontUtil
      this.infrontUtil.formatSettings.dateFormat = 'yyyy-MM-dd'
      this.infrontUtil.formatSettings.timeFormat = 'HH:mm:ss'
      this.infrontUtil.formatSettings.decimalSeparator = ','
      this.infrontUtil.formatSettings.browserDecimalSeparator = ','
      this.infrontUtil.formatSettings.thousandsSeparator = ' '
      this.infrontUtil.formatSettings.browserThousandsSeparator = ' '

      // Not really tested or supported yet, setting the language map will not automatically retranslate open widgets
      i18n.on('languageChanged', async () => {
        window.location.reload() //can we re-init all infront widgets and then run await this.init() here instead?
      })

      this.setTranslations()

      const uiOptions = this.createInfrontUIOptions(infrontTokenResponse, insideWebView)

      // For extra safety
      if (this.infrontUi) throw new Error("Infront UI already initialized, this can't be done multiple times")

      this.infrontUi = new Infront.UI(uiOptions) //Login

      this.infrontUi.init()

      this.attachEventObserversToInfrontUi(this.infrontUi)

      const { tgwProvider, tgwService } = infrontTokenResponse

      this.infrontProviderId = this.infrontUtil.getProviderId(tgwProvider, tgwService)

      // We can't use infront SDK before hasLoaded has loaded for Infront UI (tested and confirmed)
      this.infrontSDK = this.infrontUi.sdk
    } catch (err) {
      appInsights.trackException({ exception: err })
      console.error(err)
      this.hasError = true
    }
  }

  private createInfrontUIOptions = (
    infrontTokenResponse: InfrontToken,
    insideWebView: boolean
  ): Infront.InfrontUIOptions => {
    const uiOptions = new Infront.InfrontUIOptions()
    // // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // const features = getFeatures()
    uiOptions.signed_token = getFeatures().testFailingInfront ? '' : infrontTokenResponse.token //todo: ta bort denna när infront säger till
    uiOptions.token_type = 'JWT'
    uiOptions.secureConnection = Infront.ConnectionSecurity.Require
    uiOptions.useDefaultStateStorage = true
    uiOptions.language = 'sv' // TODO: Denna ska eventuellt ändras beroende på valt språk antar jag
    uiOptions.streaming = true // TODO: Oklart om denna alltid ska vara true. Kanske vi inte vill ha för vissa kunder. Vet ej exakt vad hos Infront som påverkas av denna.
    uiOptions.enableLoginDialog = false
    uiOptions.visualWidgetAccess = false

    if (infrontTokenResponse.tgwEnabled) {
      this.isTgwEnabled = true
      uiOptions.tradingOptions = this.getTradingLoginOptions(infrontTokenResponse, insideWebView)
    } else {
      this.isTgwEnabled = false
    }

    return uiOptions
  }

  private tradingEventHandlers: ((
    type: TradingEventType,
    data: Infront.TradingConnectedEvent | Infront.PortfolioDataSet
  ) => void)[] = []

  /**
   * For hooking into the trading channel observer when creating promises that waits for certain infront trading events.
   * @param handler
   * @returns
   */
  addTradingEventHandler = (handler: (type: TradingEventType, data: unknown) => void) => {
    this.tradingEventHandlers.push(handler)

    // Unsubscribe
    return () => {
      this.tradingEventHandlers = this.tradingEventHandlers.filter((th) => th !== handler)
    }
  }

  private invokeTradingEventHandlers(
    type: TradingEventType,
    data: Infront.TradingConnectedEvent | Infront.PortfolioDataSet
  ) {
    this.tradingEventHandlers.forEach((tradingEventHandler) => {
      tradingEventHandler(type, data)
    })
  }

  private readonly attachEventObserversToInfrontUi = (infrontUi: Infront.UI) => {
    const trackInfrontEvent = (event, logType: 'log' | 'warn' | 'error' = 'error') => {
      console[logType](event.name, event)
      appInsights.trackEvent({
        name: 'pbonline.infront.' + event.name,
        properties: event,
      })
    }

    // Events that set the hasError flag.
    const errorEvents = ['onDisconnect', 'onLoginFailed']
    errorEvents.forEach((eventName) => {
      infrontUi.registerEventObserver(eventName, (event) => {
        this.hasError = true
        console.error(eventName, event)
      })
    })

    infrontUi.addTradingChannelObserver({
      onAvailablePortfoliosChanged: () => {
        this.updateTradingConnectionStatus()
      },
      onActivePortfolioChanged: (portfolioDataSet) => {
        if (debugLog) console.log('📈 onActivePortfolioChanged', portfolioDataSet)
        this.updateTradingConnectionStatus()

        if (portfolioDataSet) {
          this.activePortfolioStatus = 'changed'
          this.activePortfolioName = portfolioDataSet.name
          this.invokeTradingEventHandlers('onActivePortfolioChanged', portfolioDataSet)
        }
      },
      onActivePortfolioReady: (portfolioDataSet) => {
        if (debugLog) console.log('📈 onActivePortfolioReady', portfolioDataSet)
        this.activePortfolioReady = true
        this.updateTradingConnectionStatus()

        if (portfolioDataSet) {
          this.activePortfolioStatus = 'ready'
          this.activePortfolioName = portfolioDataSet.name

          this.invokeTradingEventHandlers('onActivePortfolioReady', portfolioDataSet)
        }
      },
      onTradingConnected: (event) => {
        this.updateTradingConnectionStatus()
        console.log('📈 onTradingConnected', event)

        this.invokeTradingEventHandlers('onTradingConnected', event)
      },
      onTradingDisconnected: (event) => {
        this.updateTradingConnectionStatus()
        console.log('📈 onTradingDisconnected', event)

        trackInfrontEvent(event)
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onTradingLostConnection: (event: any) => {
        // We must override the status with our own here since Infront doesn't update their
        // status for this event
        this.updateTradingConnectionStatus('lost-connection')
        console.log('📈 onTradingLostConnection', event)

        trackInfrontEvent(event)

        this.invokeTradingEventHandlers('onTradingLostConnection', event)
      },
      onTradingReconnecting: (event) => {
        // We must override the status with our own here since Infront doesn't update their
        // status for this event
        this.updateTradingConnectionStatus('reconnecting')
        console.log('📈 onTradingReconnecting', event)

        this.tradingReconnectCount += 1
      },
      onTradingReconnected: (event) => {
        this.updateTradingConnectionStatus() // Will result in the status being connected
        console.log('📈 onTradingReconnected', event)
        trackInfrontEvent(event, 'warn')
      },
      onTradingTerminated: (event) => {
        this.updateTradingConnectionStatus()
        console.log('📈 onTradingTerminated', event)
        trackInfrontEvent(event)
      },
    })

    infrontUi.registerEventObserver('onTradingLoginCanceled', (event) => {
      trackInfrontEvent(event)
    })

    infrontUi.registerEventObserver('onTradingError', (event) => {
      trackInfrontEvent(event)
      fireTrackEvent('Order', 'order_rejected')
    })

    infrontUi.registerEventObserver('onReady', () => {
      this.hasLoaded = true
    })

    infrontUi.registerEventObserver('onTradingLoginFailed', (event: Infront.TradingLoginFailedEvent) => {
      // For some reason infront triggers a login failed event even before we have tried logging in (no provider, gateway etc have been set)
      // This code ignores that error
      const ignoreEvent = !event.tradingGateway && !event.providerId && event.error_code === -1

      if (!ignoreEvent) {
        infrontUi.hideTradingDialog()
        trackInfrontEvent(event)
      }
    })

    infrontUi.registerEventObserver('onTradeExecuted', () => {
      fireTrackEvent('Order', 'order_successful')
    })
  }

  private getTradingLoginOptions = (infrontToken: InfrontToken, insideWebView: boolean) => {
    const tradingLoginOptions = new Infront.TradingLoginOptions()
    tradingLoginOptions.signed_token = infrontToken.token
    tradingLoginOptions.user_id = infrontToken.xid
    tradingLoginOptions.provider = infrontToken.tgwProvider //Carnegie
    tradingLoginOptions.service = infrontToken.tgwService
    tradingLoginOptions.token_type = 'JWT'
    tradingLoginOptions.app_id = insideWebView ? InfrontTradingAppIds.App : InfrontTradingAppIds.Web
    tradingLoginOptions.locale_string = 'sv-SE'
    tradingLoginOptions.enableAutoLogin = false

    console.warn('Authenticating Trading (service: ' + infrontToken.tgwService + ')', tradingLoginOptions)
    return tradingLoginOptions
  }

  private setTranslations = () => {
    // Access globally (we can't use the hook inside a class), but remember that we run this again when language is changed
    const t = i18n.t.bind(i18n)

    Infront.languageMap['sv'] = {
      global: {
        cancel: t('Avbryt'),
        ok: t('OK'),
        yes: t('Ja'),
        no: t('Nej'),
        confirm_delete: t('Bekräfta cancellering'),
        ticker: t('Symbol'),
        high: t('Högst'),
        low: t('Lägst'),
        logIn: t('Logga in'),
        buy: t('Köp'),
        sell: t('Sälj'),
        ask: t('Säljkurs'),
        bid: t('Köpkurs'),
        kilo: t('k'),
        mega: t('m'),
        total: t('Total'),
        snapshot: t('Snapshotdata.'),
      },
      orderBook: {
        asksize: t('Antal'),
        bidsize: t('Antal'),
        ask: t('Säljkurs'),
        bid: t('Köpkurs'),
      },
      orderEntry: {
        price: t('Kurs'),
        volume: t('Antal'),
        validity: t('Order är giltig'),
        validityDate: t('Ordern är giltig t.o.m.'),
        orderTotal: t('Summa exkl. courtage'),
        tradingPower: t('TRADING_CAPACITY'),
        owned: t('Tillgängligt antal'),
        portfolio: t('Välj konto'),
        buy: t('Köp'),
        sell: t('Sälj'),
        confirm: t('Bekräfta order'),
        confirmBuy: t('Bekräfta köporder'),
        confirmSell: t('Bekräfta säljorder'),
        confirmCaption: t('Bekräfta'),
        deleteCaption: t('Ta bort order'),
        confirmModifyBuy: t('Bekräfta köporder'),
        confirmModifySell: t('Bekräfta säljorder'),
        cancel: t('Avbryt'),
        modify: t('Ändra'),
        modifyOrder: t('Ändra'),
        new: t('Lägg en ny order'),
        close: t('Stäng'),
        orderPlaced: t('Order lagd'),
        orderModified: t('Order ändrad'),
        INACTIVE_ORDER: t('Order inaktiv'),
        EXPIRED_ORDER: t('Order utgången'),
        EXECUTED_ORDER: t('Order lagd'),
        EXCHANGE_ORDER: t('Aktiv order'),
        REJECTED_ORDER: t('Order avvisad'),
        PENDING_INSERT_ORDER: t('Lägger order...'),
      },
      orders: {
        confirmDelete: t('Ta bort order') + '?', //for translation to work
      },
      orderWidget: {
        EXECUTED_ORDER: t('Lagd'),
        EXCHANGE_ORDER: t('Aktiv'),
        INACTIVE_ORDER: t('Inaktiv'),
        PENDING_INSERT_ORDER: t(''),
        DELETED_ORDER: t('Borttagen'),
        avgPrice: t('Snittpris'),
      },
      portfolioSelect: {
        widgetTitle: t('Välj konto'),
      },
      columns: {
        TRADING_POWER: t('TRADING_CAPACITY'),
        VOLUME: t('Tillgängligt antal'),
        S_ORDER_VALUE: t('Summa exkl. courtage'),
      },
    }
  }
}
