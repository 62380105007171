import { useInfrontInstrument } from '@common/hooks/infront/sdk/useInfrontInstrument'
import { InstrumentIdContainer } from '@common/instrumentIdContainer'

import React from 'react'
import { useTranslation } from 'react-i18next'

import { DEFAULT_PRECISION, formatNumber } from '@carnegie/digital-channels-frontend'

import { observer } from 'mobx-react-lite'

import { PerformanceLabel } from '../PerformanceLabel'
import { CommonInstrumentType } from '../useCommonInstrumentType'

type InstrumentIndexPerformanceItemsProps = {
  instrumentIdContainer: InstrumentIdContainer
  showTooltip?: boolean
  instrumentType: CommonInstrumentType
}

export const InstrumentIndexPerformanceItems = observer(
  ({ instrumentIdContainer, showTooltip = true, instrumentType }: InstrumentIndexPerformanceItemsProps) => {
    const { t } = useTranslation()

    const { observableInstrument, isLoading } = useInfrontInstrument(instrumentIdContainer?.infrontInstrument, [
      InfrontSDK.SymbolField.High,
      InfrontSDK.SymbolField.Low,
      InfrontSDK.BasicField.PriceDecimals,
    ])

    const isIndex = instrumentType === 'index'

    const priceDecimals = observableInstrument?.getFieldValue(InfrontSDK.BasicField.PriceDecimals)

    const high = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.High)
    const low = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.Low)

    const highFormatted = formatNumber(high, { decimals: isIndex ? DEFAULT_PRECISION : { min: 2, max: priceDecimals } })
    const lowFormatted = formatNumber(low, { decimals: isIndex ? DEFAULT_PRECISION : { min: 2, max: priceDecimals } })

    return (
      <>
        <PerformanceLabel
          css={{ width: '25%' }}
          loading={isLoading}
          title={t('Högst')}
          value={highFormatted}
          showTooltip={showTooltip}
        />
        <PerformanceLabel
          css={{ width: '25%' }}
          loading={isLoading}
          title={t('Lägst')}
          value={lowFormatted}
          showTooltip={showTooltip}
        />
      </>
    )
  }
)
InstrumentIndexPerformanceItems.displayName = 'InstrumentIndexPerformanceItems'
