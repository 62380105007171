import * as Sentry from '@sentry/react'

import React, { ErrorInfo } from 'react'

interface Props {
  children: React.ReactNode
}

interface State {
  hasError: boolean
}

export class AppErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(): State {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtra('componentStack', errorInfo.componentStack)
      Sentry.captureException(error)
    })

    const eventId = Sentry.lastEventId()
    const sentryConfig = JSON.parse(sessionStorage.getItem('sentryConfig') || '{}')

    Sentry.showReportDialog({
      ...sentryConfig,
      eventId: eventId,
      colorScheme: 'system',
      showBranding: false,
      enableScreenshot: true,
      onClose: () => {
        this.setState({ hasError: false })
        // Use window.location to navigate to root URL
        window.location.href = '/'
      },
    })
  }

  render() {
    if (this.state.hasError) {
      return null // The Sentry dialog will be shown, so we don't need to render anything here
    }

    return this.props.children
  }
}
