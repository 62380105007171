import { InfrontInstrument } from '@common/api/response'
import { SkeletonLoader } from '@common/components/SkeletonLoader'
import { CustomOptions, useInfrontFinancialCalendar } from '@common/hooks/infront/sdk/useInfrontFinancialCalendar'
import { convertToInstrumentIdContainer } from '@common/instrumentIdContainer'

import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { Segment, Spacer } from '@carnegie/duplo'

import { addYears } from 'date-fns'
import { startOfTomorrow } from 'date-fns/esm'

import { useOpenInstrumentDrawer } from '../../../drawers/instrumentDrawer/useOpenInstrumentDrawer'
import { CalendarListItem } from './CalendarListItem'

type Props = {
  customOptions?: CustomOptions
  instrument?: InfrontInstrument
  from?: Date
  to?: Date
  useSegmentWrapper?: boolean
  segmentWrapperTitle?: string
  noDataComponent?: ReactNode
}

const tomorrow = startOfTomorrow()
const nextYear = addYears(tomorrow, 1)

export const MarketHappenings = ({
  instrument,
  from,
  to,
  customOptions,
  useSegmentWrapper,
  segmentWrapperTitle,
  noDataComponent,
}: Props) => {
  const { t } = useTranslation()

  const { calendarEvents, error } = useInfrontFinancialCalendar({
    instrument: instrument,
    customOptions: customOptions,
    from: from || tomorrow,
    to: to || nextYear,
  })

  const { openInstrumentDrawer } = useOpenInstrumentDrawer()

  const calendarEventItems =
    calendarEvents &&
    calendarEvents.map((calendarEvent, i) => (
      <CalendarListItem
        divider={i !== calendarEvents.length - 1}
        key={calendarEvent.id}
        date={calendarEvent.dateTime}
        category={t(calendarEvent.category)}
        description={calendarEvent.description}
        extraInfo={calendarEvent.symbolId.ticker}
        onClick={
          instrument
            ? undefined
            : () => {
                openInstrumentDrawer(
                  convertToInstrumentIdContainer(
                    new Infront.Instrument(calendarEvent.symbolId.feed, calendarEvent.symbolId.ticker)
                  )
                )
              }
        }
      />
    ))

  const noData = noDataComponent !== undefined ? { noDataComponent } : {}

  return (
    <SkeletonLoader
      variant="list"
      error={error}
      loading={!calendarEvents}
      noDataLoaded={calendarEvents && calendarEvents.length === 0}
      {...noData}
    >
      {() =>
        useSegmentWrapper ? (
          <>
            <Segment variant="uncontained" title={segmentWrapperTitle}>
              <Segment noContentPadding>{calendarEventItems}</Segment>
            </Segment>
            <Spacer height={16} />
          </>
        ) : (
          calendarEventItems
        )
      }
    </SkeletonLoader>
  )
}
