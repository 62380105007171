import { SkeletonLoader } from '@common/components/SkeletonLoader'
import { useDrawerProps } from '@common/components/drawer/useDrawerStore'
import { useSeminar } from '@common/hooks/useSeminar'
import { useKeepUserLoggedIn } from '@common/hooks/useUserActivityManager'

import { Breakpoint, GridContainer, GridItem, Spacer, Text, useBreakpoint } from '@carnegie/duplo'

import parse, { Element, HTMLReactParserOptions } from 'html-react-parser'
import { observer } from 'mobx-react-lite'

import { CarnegieMaterialTagAndDate } from '../CarnegieMaterialTagAndDate'
import { CarnegieMaterialHeading } from './CarnegieMaterialHeading'

const SeminarDrawer = observer(() => {
  const { routeProps: id } = useDrawerProps()
  const { seminar } = useSeminar(id)
  const breakpoint = useBreakpoint()
  const isMobile = breakpoint < Breakpoint.Small

  // Keep resetting the idle timer and update the refresh tokens for a max of 90 minutes
  useKeepUserLoggedIn(90)

  const parseIframe: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (domNode instanceof Element) {
        return (
          <iframe
            width="100%"
            height="176"
            scrolling="no"
            title={seminar?.title}
            allowFullScreen
            css={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
            src={domNode?.attribs?.src}
          />
        )
      }
    },
  }

  return (
    <SkeletonLoader height={232} loading={!seminar}>
      {() => (
        <>
          <CarnegieMaterialHeading isMobile={isMobile} title={seminar?.title} />
          {!isMobile && <Spacer height={8} />}
          <GridContainer spacing={0} style={{ justifyContent: 'flex-start' }}>
            <GridItem xs={12} md={8} lg={8}>
              {seminar?.date && <CarnegieMaterialTagAndDate date={new Date(seminar?.date)} tags={seminar?.category} />}{' '}
              <Spacer height={isMobile ? 16 : 32} />
              {seminar?.summary && (
                <>
                  <Text variant="subtitle1" truncate={false}>
                    {seminar?.summary}
                  </Text>
                  <Spacer height={isMobile ? 16 : 32} />
                </>
              )}
              {seminar?.embedHtml && (
                <div
                  css={{
                    iframe: {
                      border: 'none',
                    },
                    position: 'relative',
                    paddingBottom: '56.25%',
                    height: '0',
                    overflow: 'hidden',
                    maxWidth: '100%',
                  }}
                >
                  {parse(seminar?.embedHtml, parseIframe)}
                </div>
              )}
            </GridItem>
            <GridItem lg={4}>
              <div />
            </GridItem>
          </GridContainer>
        </>
      )}
    </SkeletonLoader>
  )
})

SeminarDrawer.displayName = 'SeminarDrawer'
export default SeminarDrawer
