import { CurrencyFlag } from '@common/components/CurrencyToFlag'
import { FormattedNumber } from '@common/components/FormattedNumber'
import { SmartTable } from '@common/components/SmartTable'
import { useSelectedHoldingAccount } from '@common/hooks/useHoldings'
import { sum } from '@common/utils/sum'

import { useTranslation } from 'react-i18next'

import { formatNumber } from '@carnegie/digital-channels-frontend'
import { Box, Breakpoint, FlexRow, Segment, Td, Text, Tr, useBreakpoint } from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import { Currency, useCurrencyPositions } from '../useCurrencyPositions'
import { CurrencyPositionsMobile } from './CurrencyPositionMobile'
import { CurrencyPositionsFooter, CurrencyPositionsMobileFooter } from './CurrencyPositionsFooter'

const STORAGE_KEY = `CURRENCY_POSITION`

export const CurrencyPositions = observer(() => {
  const { t } = useTranslation()

  const { account } = useSelectedHoldingAccount()

  const breakpoint = useBreakpoint()
  const isExtraSmallScreen = breakpoint <= Breakpoint.Xs

  const { currencies } = useCurrencyPositions(account?.holdings?.currencies ?? [])
  const totalMarketValue = sum('marketValue', currencies)

  return (
    <Segment noContentPadding title={t('Likvida medel')} variant="contained" headingVariant="overline">
      {isExtraSmallScreen ? (
        <Box>
          {currencies.map((currency, index) => {
            return <CurrencyPositionsMobile currency={currency} key={index} />
          })}
          <CurrencyPositionsMobileFooter totalMarketValue={totalMarketValue} />
        </Box>
      ) : (
        <SmartTable
          renderMode={isExtraSmallScreen ? 'custom' : 'table'}
          sessionStorageKey={STORAGE_KEY}
          data={currencies}
          defaultSortBy="name"
          columns={[
            {
              id: 'name',
              width: 'minmax(0,2fr)',
              renderHeader: () => t('Valuta'),
              sortBy: ({ row }) => row.currencyName,
            },
            {
              id: 'quantity',
              width: '1fr',
              align: 'right',
              renderHeader: () => t('Saldo'),
              sortBy: ({ row }) => row.quantity,
            },
            {
              id: 'marketValue',
              width: '1fr',
              align: 'right',
              renderHeader: () => t('Saldo') + ` (SEK)`,
              sortBy: ({ row }) => row.marketValue,
            },
            {
              id: 'fxRate',
              width: '1fr',
              align: 'right',
              renderHeader: () => t('Valutakurs'),
              sortBy: ({ row }) => row.fxRate,
            },
          ]}
          renderRow={({ row: currency, index }) =>
            isExtraSmallScreen ? (
              <CurrencyPositionsMobile currency={currency} key={currency.currencyName ?? index} />
            ) : (
              <CurrencyRow key={currency.currencyName ?? index} currency={currency} />
            )
          }
          renderFooterRow={() =>
            isExtraSmallScreen ? (
              <CurrencyPositionsMobileFooter totalMarketValue={totalMarketValue} />
            ) : (
              <CurrencyPositionsFooter totalMarketValue={totalMarketValue} />
            )
          }
        />
      )}
    </Segment>
  )
})

type CurrencyRowProps = {
  currency: Currency
}

const CurrencyRow = observer(({ currency }: CurrencyRowProps) => {
  const { currencyName, quantity, marketValue, fxRate } = currency

  //https://dev.azure.com/carnegieinvestmentbank/CarnegieIT/_workitems/edit/74700
  if (marketValue === null) return null

  return (
    <Tr>
      <Td>
        <FlexRow css={{ maxWidth: 150 }} alignItems="center">
          <Box flexShrink={0}>
            <CurrencyFlag currency={currencyName} size={16} />
          </Box>
          <Text variant="body2" truncate ml={4}>
            {currencyName}
          </Text>
        </FlexRow>
      </Td>
      <Td>
        <FormattedNumber justifyContent="flex-end" value={quantity} unit={currencyName} decimals={2} />
      </Td>
      <Td>
        <FormattedNumber justifyContent="flex-end" unit="SEK" value={marketValue} decimals={2} />
      </Td>
      <Td textAlign="right">
        <Text variant="body2">{formatNumber(fxRate, { decimals: 2 })}</Text>
      </Td>
    </Tr>
  )
})
