import { useInfrontTradingStatus } from '@common/hooks/infront/useInfrontTradingConnectedAndPortfolioReady'
import { useInfrontStore } from '@common/stores/store'
import { arraySort } from '@common/utils/arraySort'

import { InfrontOrder, useInfrontOrders } from '@carnegie/digital-channels-frontend'

import { isFuture, isToday } from 'date-fns'

type UseCurrentOrdersOptions = {
  sortByChangeTime?: boolean
  listType: UseCurrentOrdersListType
}

type UseCurrentOrdersListType = 'current-orders' | 'current-orders-no-funds' | 'historical-orders'

export function useCurrentOrders(portfolioName: string, options: UseCurrentOrdersOptions) {
  const infrontStore = useInfrontStore()
  const { tradingConnectedAndPortfolioReady } = useInfrontTradingStatus()

  const { orders, loading, deleteOrder } = useInfrontOrders(
    portfolioName && tradingConnectedAndPortfolioReady
      ? {
          portfolioName: portfolioName,
          providerId: infrontStore.infrontProviderId,
          filter: options.listType === 'historical-orders' ? infrontHistoricalOrderFilter : infrontOrderFilter,
          daysBack: options.listType === 'historical-orders' ? 30 : undefined,
        }
      : undefined
  )

  const sortByChangeTime = !!options?.sortByChangeTime

  // It's import we filter out fund orders after we get the data, if we do it during the infront data fetching
  // in the useSdkOrders hook the symbol classification might not have loaded yet
  // Because of mobx this hook will run as soon as a symbolClassification changes for a symbol which makes this faster
  let shownOrders: InfrontOrder[] = undefined

  switch (options.listType) {
    case 'current-orders':
    case 'historical-orders':
      shownOrders = orders
      break

    case 'current-orders-no-funds':
      shownOrders = orders?.slice().filter((order) => {
        const symbolClassification = order.symbolClassification

        if (
          // We can't show unknown (non loaded) symbol classifications either since we get an effect where we see every type of order
          // then the list collapses because we suddenly find out these were funds
          symbolClassification === InfrontSDK.SymbolClassification.Fund ||
          symbolClassification === undefined ||
          symbolClassification === InfrontSDK.SymbolClassification.Unknown
        ) {
          return false
        } else {
          return true
        }
      })
      break
  }

  // Remove broken orders that have no symbolId etc
  //shownOrders = shownOrders?.filter((order) => !!order.symbolId)

  if (sortByChangeTime && shownOrders) {
    shownOrders = arraySort(shownOrders, (order) => order.changeDateTime, true)
  }

  return { orders: shownOrders, deleteOrder: deleteOrder, loading: loading, error: undefined }
}

function infrontHistoricalOrderFilter(order: InfrontSDK.Trading.PortfolioItem) {
  return !infrontOrderFilter(order)
}

function infrontOrderFilter(order: InfrontSDK.Trading.PortfolioItem) {
  const orderStatus = order.get(InfrontSDK.TradingField.OrderStatus) as InfrontSDK.Trading.OrderState
  const createDate = order.get(InfrontSDK.TradingField.CreateDate)
  const changeDate = order.get(InfrontSDK.TradingField.ChangeDate)
  const validDate = order.get(InfrontSDK.TradingField.ValidDate)

  // We have seen a rogue "sell" order that has all of these set to null, we remove it explicitly
  if (validDate === null && createDate === null && changeDate === null) {
    return false
  }

  // Current understanding of change date changes:
  // ---------------------------------------------
  // Executed - WILL NOT change "change date"
  // Deleted + rejected + inactive WILL change "change date"

  //Filter out all orders where validDate has passed
  if (isPastDay(validDate)) {
    return false
  }

  if (
    // We do not include "Executed" in this list because its change
    // date does not change by executing the order
    isPastDay(createDate) &&
    isPastDay(changeDate) &&
    (orderStatus === 'Deleted' || orderStatus === 'Rejected' || orderStatus === 'Inactive')
  ) {
    return false
  }

  return true
}

const isTodayOrFuture = (date: Date) => {
  return isToday(date) || isFuture(date)
}

const isPastDay = (date: Date) => {
  return !isTodayOrFuture(date)
}
