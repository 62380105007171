import { SeverityLevel } from '@microsoft/applicationinsights-web'

import { appInsights } from '@/appInsights'

type Data = {
  [key: string]: string | number | boolean
  category?: never
}

export function fireTrackEvent(category: string, eventName: string, data?: Data) {
  appInsights.trackEvent({
    name: `${category}.${eventName}`,
    properties: {
      ...data,
      type: category,
      operation: eventName,
    },
  })
}

export function trackException(optionalEventName: string, errorMessage: string) {
  appInsights.trackException({
    error: {
      message: errorMessage,
      name: optionalEventName,
    },
    severityLevel: SeverityLevel.Error,
  })
}
