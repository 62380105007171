import { FundOrderType } from '@common/api/response'
import { useCarnegieInstrument } from '@common/hooks/useCarnegieInstrument'
import { useFeatures } from '@common/hooks/useFeatures'
import { useHoldingAccounts } from '@common/hooks/useHoldings'
import { useSyncActivePortfolio } from '@common/hooks/useSyncActivePortfolio'
import { useValidateFund } from '@common/hooks/useValidateFund'
import { InstrumentIdContainer } from '@common/instrumentIdContainer'
import { getValidAccount } from '@common/utils/getValidAccount'

import { useEffect, useState } from 'react'

import { Box, Breakpoint, CSSGrid, GridBox, Spacer, useBreakpoint } from '@carnegie/duplo'

import { SimpleOrderList } from '@/drawers/fundOrderDrawer/SimpleOrderList'
import i18n from '@/i18n'
import { ValidationErrorMessage } from '@/pages/overview/order/ValidationErrorMessage'

import { observer } from 'mobx-react-lite'

import { FundOrderEntry } from './FundOrderEntry'
import { FundOrderHeader } from './FundOrderHeader'
import { FundOrderInformation } from './FundOrderInformation'
import { useFundOrderDrawerProps } from './hooks/useOpenFundOrderDrawer'

type FundOrderDrawerProps = {
  instrumentIdContainer: InstrumentIdContainer
  initialSelectedAccountId?: string
  initialOrderId?: number
  renderForApp?: boolean
  phone?: string
  fundOrderType: FundOrderType
}

const FundOrderDrawer = () => {
  const fundOrderDrawerProps = useFundOrderDrawerProps()

  return (
    <FundOrderDrawerAppAndWeb
      instrumentIdContainer={fundOrderDrawerProps.instrumentIdContainer}
      initialSelectedAccountId={fundOrderDrawerProps.initialSelectedAccountId}
      fundOrderType={fundOrderDrawerProps.fundOrderType}
    />
  )
}

// In its own component because it is also used for the App and here we don't read the props from the url
const FundOrderDrawerAppAndWeb = observer(
  ({ instrumentIdContainer, fundOrderType, initialSelectedAccountId, renderForApp, phone }: FundOrderDrawerProps) => {
    const breakpoint = useBreakpoint()
    const isMobile = breakpoint < Breakpoint.Small
    const features = useFeatures()

    const { carnegieInstrument } = useCarnegieInstrument(instrumentIdContainer, i18n.language)
    const { fundValidity } = useValidateFund(carnegieInstrument?.instrumentIdCarnegie, fundOrderType)
    const [isValid, setIsValid] = useState(false)
    const [selectedAccountId, setSelectedAccountId] = useState(initialSelectedAccountId)
    const { findAccountById, accounts } = useHoldingAccounts()
    const selectedAccount = getValidAccount(findAccountById(selectedAccountId), accounts)

    // instrumentIdContainer.infrontInstrument is always(?) missing, so we need to set it here
    instrumentIdContainer.infrontInstrument =
      instrumentIdContainer.infrontInstrument || carnegieInstrument?.infrontInstrument

    // Sync active portfolio with the selected account
    useSyncActivePortfolio(selectedAccount)

    useEffect(() => {
      setIsValid(fundValidity && fundValidity.isValid)
    }, [fundValidity])

    // On android mobile, make room for a virtual keyboard
    return (
      <Box css={isMobile && renderForApp && phone === 'android' && { paddingBottom: '37%' }}>
        {renderForApp ? null : (
          <FundOrderHeader fundOrderType={fundOrderType} instrumentIdContainer={instrumentIdContainer} />
        )}
        {!features?.showFundOrder ? (
          <ValidationErrorMessage invalidCode={'NotTradableInstrument'} />
        ) : !isValid ? (
          <ValidationErrorMessage invalidCode={fundValidity?.invalidCode} />
        ) : (
          <>
            <Spacer height={16} />
            <CSSGrid
              rows={isMobile ? undefined : 'minmax(0px, auto) minmax(0px, 1fr)'}
              cols={isMobile ? 1 : 'minmax(0px, 55fr) minmax(0px, 45fr)'}
              gap={0}
              gapX={64}
              gapY={32}
            >
              <GridBox order={1}>
                <FundOrderEntry
                  fundOrderType={fundOrderType}
                  carnegieInstrument={carnegieInstrument}
                  renderForApp={renderForApp}
                  selectedAccount={selectedAccount}
                  onSelectAccount={(accountId) => setSelectedAccountId(accountId)}
                />
              </GridBox>
              <SimpleOrderList selectedAccount={selectedAccount} renderForApp={false} />
              <GridBox
                order={isMobile ? 2 : 3}
                rowStart={isMobile ? 'auto' : 1}
                colStart={isMobile ? 1 : 2}
                rowSpan={isMobile ? 'auto' : 2}
              >
                <FundOrderInformation
                  fundOrderType={fundOrderType}
                  instrumentIdContainer={instrumentIdContainer}
                  isMobile={isMobile}
                />
              </GridBox>
            </CSSGrid>
            <Spacer height={isMobile ? 16 : 32} />
          </>
        )}
      </Box>
    )
  }
)

export default FundOrderDrawer
