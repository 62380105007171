import { BuySellMenuItem, PlaceFundOrderMenuItem, TransactionMenuItem } from '@common/components/ActionButton'
import { FormattedNumber } from '@common/components/FormattedNumber'
import { MoreMenuWatchlist } from '@common/components/MoreMenu'
import { ExpandTableButton, ExpandableTr } from '@common/components/SmartTable'
import { fireTrackEvent } from '@common/utils/analyticsEvent'

import { useState } from 'react'

import { formatNumber } from '@carnegie/digital-channels-frontend'
import { Paragraph, Td } from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import { useOpenInstrumentDrawer } from '../../../../drawers/instrumentDrawer/useOpenInstrumentDrawer'
import { useAccountHoldingsSideDrawer } from '../../accountHoldings/useAccountHoldingsSideDrawer'
import { InstrumentName } from '../InstrumentName'
import { UnderlyingAssetsRows } from '../UnderlyingAssetsRows'
import { ObservableTableInstrument } from '../useTableInstruments'
import { TableAcquisitionColumnId } from './TableAcquisition'

type TableAcquisitionRowProps = {
  instrument: ObservableTableInstrument
  transactionOnClick?: () => void
  showPercentage: boolean
  isFund: boolean
  columns: { id?: TableAcquisitionColumnId }[]
}

export const TableAcquisitionRow = observer(
  ({ instrument, transactionOnClick, isFund, columns }: TableAcquisitionRowProps) => {
    const { openInstrumentDrawer } = useOpenInstrumentDrawer()

    const {
      acquisitionCost,
      averageAcquisitionPrice,
      carnegieInstrumentGroup,
      isoCountryCode,
      marketIso,
      name,
      quantity,
      averageAcquisitionPriceOriginal,
      acquisitionCostOriginal,
      acquisitionFxRate,
      reservedQuantities,
    } = instrument

    const [expanded, setExpanded] = useState(false)
    const showFlag = carnegieInstrumentGroup !== 'MutualFunds'

    const hasExpanderColumn = columns.some((columnDef) => columnDef.id === 'expander-button')

    const { openSideDrawer } = useAccountHoldingsSideDrawer()

    return (
      <>
        <ExpandableTr expanded={expanded}>
          {hasExpanderColumn && (
            <Td>
              {instrument.hasDetails && (
                <ExpandTableButton
                  expanded={expanded}
                  onClick={() => {
                    if (!expanded) {
                      fireTrackEvent('Holdings', 'expand_underlying_fundholding')
                    }
                    setExpanded(!expanded)
                  }}
                />
              )}
            </Td>
          )}
          <Td>
            <InstrumentName
              isoCountryCode={isoCountryCode ? isoCountryCode : marketIso}
              name={name}
              reservedQuantities={reservedQuantities}
              onClick={() => openInstrumentDrawer(instrument)}
              showFlag={showFlag}
              showInfoButton={instrument.hasDetails}
              onInfoButtonClick={() => {
                fireTrackEvent('Holdings', 'click_showmore_underlying_fundholding')
                openSideDrawer()
              }}
            />
          </Td>
          <Td>
            <Paragraph variant="body2" css={{ textAlign: 'right' }}>
              {formatNumber(quantity, { decimals: { min: 0, max: 10 } })}
            </Paragraph>
          </Td>
          <Td>
            <FormattedNumber
              justifyContent="flex-end"
              value={averageAcquisitionPriceOriginal.amount}
              unit={averageAcquisitionPriceOriginal.currencyCode}
              decimals={2}
            />
          </Td>
          <Td>
            <FormattedNumber
              justifyContent="flex-end"
              value={averageAcquisitionPrice.amount}
              unit={averageAcquisitionPrice.currencyCode}
              decimals={2}
            />
          </Td>
          <Td>
            <FormattedNumber
              decimals={0}
              justifyContent="flex-end"
              value={acquisitionCostOriginal.amount}
              unit={acquisitionCostOriginal.currencyCode}
            />
          </Td>
          <Td>
            <FormattedNumber
              decimals={0}
              justifyContent="flex-end"
              value={acquisitionCost.amount}
              unit={acquisitionCost.currencyCode}
            />
          </Td>
          <Td>
            <Paragraph variant="body2" css={{ textAlign: 'right' }}>
              {formatNumber(acquisitionFxRate, { decimals: 2 })}
            </Paragraph>
          </Td>
          <Td>
            <MoreMenuWatchlist instrumentIdContainer={instrument}>
              {isFund ? (
                <>
                  <PlaceFundOrderMenuItem instrumentIdContainer={instrument} fundOrderType={'buy'} />
                  <PlaceFundOrderMenuItem instrumentIdContainer={instrument} fundOrderType={'sell'} />
                </>
              ) : (
                <BuySellMenuItem instrumentIdContainer={instrument} />
              )}
              <TransactionMenuItem onClick={transactionOnClick} />
            </MoreMenuWatchlist>
          </Td>
        </ExpandableTr>
        {expanded && <UnderlyingAssetsRows instrument={instrument} columns={columns} />}
      </>
    )
  }
)
