import { action, makeObservable, observable } from 'mobx'

import i18n from '../../i18n'
import { Api } from '../api/api'
import { InfrontInstrument, Watchlist } from '../api/response'
import { InstrumentIdContainer } from '../instrumentIdContainer'
import { fireTrackEvent } from '../utils/analyticsEvent'
import { base64encode } from '../utils/base64encode'

export class WatchlistStore {
  api: Api
  error: boolean = false
  watchlists: Watchlist[]
  hasLoaded: boolean = false

  constructor(api: Api) {
    makeObservable<
      WatchlistStore,
      | 'addWatchlistItemInfront'
      | 'addWatchlistItemCarnegie'
      | 'deleteWatchlistItemInfront'
      | 'deleteWatchlistItemCarnegie'
      | 'isCheckedCarnegie'
      | 'isCheckedInfront'
    >(this, {
      error: observable,
      watchlists: observable,
      hasLoaded: observable,
      init: action,
      fetchWatchlists: action,
      createWatchlist: action,
      deleteWatchlist: action,
      updateWatchlist: action,
      addWatchlistItem: action,
      addWatchlistItemInfront: action,
      addWatchlistItemCarnegie: action,
      deleteWatchlistItem: action,
      deleteWatchlistItemInfront: action,
      deleteWatchlistItemCarnegie: action,
      isChecked: action,
      isCheckedCarnegie: action,
      isCheckedInfront: action,
      isWatching: action,
      isWatchingOneOrMoreInstruments: action,
    })

    this.api = api
  }

  async init(isImpersonated: boolean) {
    if (!this.hasLoaded) {
      await this.fetchWatchlists()

      // Make sure the user has one watchlist at least
      // This should normally be run at login etc
      if (this.watchlists.length === 0 && !isImpersonated) {
        // We do not await this but let it load in the background (so no await), MobX will re-render when needed instead
        this.createWatchlist(i18n.t('Min bevakningslista'))
      }

      if (this.watchlists) this.hasLoaded = true
    }
  }

  getWatchlistsForInstrument(instrument: InstrumentIdContainer): Watchlist[] {
    const watchlistsWithInstrument = []
    this.watchlists?.forEach((watchlist) => {
      if (this.isChecked(watchlist, instrument)) {
        watchlistsWithInstrument.push(watchlist)
      }
    })

    return watchlistsWithInstrument
  }

  async fetchWatchlists() {
    const response = await this.api.fetchWatchlists()
    if (!response?.error) {
      this.watchlists = response.item?.watchlists
    }
  }

  createWatchlist = async (name: string) => {
    const response = await this.api?.createWatchlist(name)
    if (!response?.error) {
      fireTrackEvent('Market', 'create_watchlist')
      this.fetchWatchlists()
      return response.item
    }
  }

  deleteWatchlist = async (id: string) => {
    const response = await this.api?.deleteWatchlist(id)
    if (!response?.error) {
      this.fetchWatchlists()
    }
  }

  updateWatchlist = async (id: string, name: string) => {
    const response = await this.api?.updateWatchlist(id, name)
    if (!response?.error) {
      this.fetchWatchlists()
    }
  }

  addWatchlistItem = (id: string, instrumentIdContainer: InstrumentIdContainer) => {
    if (instrumentIdContainer?.instrumentIdCarnegie) {
      this.addWatchlistItemCarnegie(id, instrumentIdContainer?.instrumentIdCarnegie)
    } else if (instrumentIdContainer?.infrontInstrument) {
      this.addWatchlistItemInfront(id, instrumentIdContainer?.infrontInstrument)
    }
    fireTrackEvent('Market', 'add_to_watchlist')
    return
  }

  private async addWatchlistItemInfront(id: string, infrontInstrument: InfrontInstrument) {
    const infrontInstrumentIdBase64 = infrontInstrument ? base64encode(infrontInstrument) : ''
    const { instrumentIdCarnegie } = await this.api.fetchCarnegieInstrument(infrontInstrumentIdBase64, i18n.language)
    this.addWatchlistItemCarnegie(id, instrumentIdCarnegie)
  }

  private async addWatchlistItemCarnegie(id: string, instrumentIdCarnegie: string) {
    const response = await this.api?.addWatchlistItem(id, instrumentIdCarnegie)
    if (!response?.error) {
      this.fetchWatchlists()
    }
  }

  deleteWatchlistItem = (id: string, instrumentIdContainer: InstrumentIdContainer) => {
    if (instrumentIdContainer?.instrumentIdCarnegie) {
      this.deleteWatchlistItemCarnegie(id, instrumentIdContainer?.instrumentIdCarnegie)
    } else if (instrumentIdContainer?.infrontInstrument) {
      this.deleteWatchlistItemInfront(id, instrumentIdContainer?.infrontInstrument)
    }
    return
  }

  private async deleteWatchlistItemInfront(id: string, infrontInstrument: InfrontInstrument) {
    const infrontInstrumentIdBase64 = infrontInstrument ? base64encode(infrontInstrument) : ''
    const { instrumentIdCarnegie } = await this.api.fetchCarnegieInstrument(infrontInstrumentIdBase64, i18n.language)
    this.deleteWatchlistItemCarnegie(id, instrumentIdCarnegie)
  }

  private async deleteWatchlistItemCarnegie(id: string, instrumentIdCarnegie: string) {
    const response = await this.api?.deleteWatchlistItem(id, instrumentIdCarnegie)
    if (!response?.error) {
      this.fetchWatchlists()
    }
  }

  isChecked(watchlist: Watchlist, instrumentIdContainer: InstrumentIdContainer) {
    if (instrumentIdContainer?.instrumentIdCarnegie) {
      return this.isCheckedCarnegie(watchlist, instrumentIdContainer?.instrumentIdCarnegie)
    } else if (instrumentIdContainer?.infrontInstrument) {
      return this.isCheckedInfront(watchlist, instrumentIdContainer?.infrontInstrument)
    }
    return false
  }

  private isCheckedCarnegie(watchlist: Watchlist, instrumentIdCarnegie: string) {
    return watchlist?.instruments?.some((instrument) => instrument.id === instrumentIdCarnegie)
  }

  private isCheckedInfront(watchlist: Watchlist, infrontInstrument: InfrontInstrument) {
    return watchlist?.instruments?.some(
      (instrument) =>
        instrument?.infrontInstrument?.feed === infrontInstrument?.feed &&
        instrument?.infrontInstrument?.ticker?.toUpperCase() === infrontInstrument?.ticker?.toUpperCase()
    )
  }

  isWatching(instrumentIdContainer: InstrumentIdContainer) {
    if (instrumentIdContainer?.instrumentIdCarnegie) {
      return this.watchlists?.some((watchlist: Watchlist) =>
        this.isCheckedCarnegie(watchlist, instrumentIdContainer?.instrumentIdCarnegie)
      )
    } else if (instrumentIdContainer?.infrontInstrument) {
      return this.watchlists?.some((watchlist: Watchlist) =>
        this.isCheckedInfront(watchlist, instrumentIdContainer?.infrontInstrument)
      )
    }
    return
  }

  isWatchingOneOrMoreInstruments(instrument: InstrumentIdContainer) {
    const watchlists = this.getWatchlistsForInstrument(instrument)
    return watchlists?.length > 0
  }
}
