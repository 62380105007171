import { isDate } from 'date-fns'

type ItemValue<TItem, TValue extends string | number | boolean | Date> = {
  item: TItem
  value: TValue
}

/**
 * Sort an array by selecting a property, expression etc to sort by.
 * Desc = large to small
 * Asc = small to large
 * @param arr
 * @param sortBy
 * @param desc
 * @returns
 */
export function arraySort<T>(
  arr: T[],
  sortBy: (item: T) => string | number | boolean | Date,
  desc: boolean = false
): T[] {
  const itemAndValues: ItemValue<T, string | number | boolean | Date>[] = arr.map((item) => {
    return {
      item: item,
      value: sortBy(item),
    }
  })

  // No need to sort this one
  const nullUndefinedArr = itemAndValues.filter((el) => el.value === undefined || el.value === null)

  const numArr = itemAndValues
    .filter((el) => typeof el.value === 'number')
    .sort((a: ItemValue<T, number>, b: ItemValue<T, number>) => {
      return desc ? b.value - a.value : a.value - b.value
    })

  const stringArr = itemAndValues
    .filter((el) => typeof el.value === 'string')
    .sort((a: ItemValue<T, string>, b: ItemValue<T, string>) => {
      return desc
        ? b.value.localeCompare(a.value.toString(), 'sv', { sensitivity: 'base' })
        : a.value.localeCompare(b.value.toString(), 'sv', { sensitivity: 'base' })
    })

  const dateArr = itemAndValues
    .filter((el) => isDate(el.value))
    .sort((a: ItemValue<T, Date>, b: ItemValue<T, Date>) => {
      return desc ? +b.value - +a.value : +a.value - +b.value
    })

  return [...numArr, ...stringArr, ...dateArr, ...nullUndefinedArr].map((a) => a.item)
}
