import { InstrumentFlag } from '@common/components/InstrumentFlag'
import { NoDataDash, NoDataFallback } from '@common/components/NoDataFallback'
import { useInfrontInstrument } from '@common/hooks/infront/sdk/useInfrontInstrument'
import { useCarnegieInstrument } from '@common/hooks/useCarnegieInstrument'
import { useInstrumentWithFallbackData } from '@common/hooks/useInstrumentWithFallbackData'
import { InstrumentIdContainer } from '@common/instrumentIdContainer'
import { getMorningStarFactsheetLink, getMorningStarProspectusLink } from '@common/utils/morningstarLink'

import { useTranslation } from 'react-i18next'

import { formatAsDate, formatNumber } from '@carnegie/digital-channels-frontend'
import {
  Box,
  Breakpoint,
  FlexRow,
  Icon,
  IconDocumentLineChart,
  LinkExternal,
  ListItem,
  ListItemRow,
  Segment,
  SkeletonRect,
  Spacer,
  Tag,
  TagIcon,
  TagLabel,
  Text,
  useBreakpoint,
} from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import { ListItemValue } from '../ListItemValue'
import { CommonInstrumentType } from '../useCommonInstrumentType'

type InstrumentUnknownInformationProps = {
  instrumentIdContainer: InstrumentIdContainer
  instrumentType: CommonInstrumentType
  collateralRatio: number
}

/**
 * Handelsplats saknas. Svar: Undersöker.
 * Listor saknas. Svar: Oklart om instrumentet vet vilka listor det tillhör i fronten. Skulle gå att lösa backend.
 */

export const InstrumentUnknownInformation = observer(
  ({ instrumentIdContainer, instrumentType, collateralRatio }: InstrumentUnknownInformationProps) => {
    const { t, i18n } = useTranslation()
    const breakpoint = useBreakpoint()
    const isMobile = breakpoint < Breakpoint.Small

    const { carnegieInstrument, isLoading: isLoadingCarnegieInstrument } = useCarnegieInstrument(
      instrumentIdContainer,
      i18n.language
    )

    const { isin, marketplaceName } = useInstrumentWithFallbackData(instrumentIdContainer, ['isin', 'marketplace'])

    const kiidLink = !isin ? (
      <SkeletonRect height={20} width={96} />
    ) : (
      <LinkExternal
        truncate
        variant="subtitle2"
        href={getMorningStarFactsheetLink(carnegieInstrument?.instrumentIdCarnegie, i18n.language)}
        target="_blank"
        title={t('Faktablad')}
      >
        <FlexRow>
          <Box mr={4}>
            <Icon color="current" icon={IconDocumentLineChart} size={20} />
          </Box>
          <span>{t('Faktablad')}</span>
        </FlexRow>
      </LinkExternal>
    )

    const prospectusLink = !isin ? (
      <SkeletonRect height={20} width={96} />
    ) : (
      <LinkExternal
        truncate
        variant="subtitle2"
        href={getMorningStarProspectusLink(carnegieInstrument?.instrumentIdCarnegie, i18n.language)}
        target="_blank"
        title={t('Informationsbroschyr')}
      >
        <FlexRow>
          <Box mr={4}>
            <Icon color="current" icon={IconDocumentLineChart} size={20} />
          </Box>
          <span>{t('Informationsbroschyr')}</span>
        </FlexRow>
      </LinkExternal>
    )

    const { observableInstrument, isLoading: isLoadingInfrontInstrument } = useInfrontInstrument(
      instrumentIdContainer?.infrontInstrument,
      [
        InfrontSDK.SymbolField.Country,
        InfrontSDK.SymbolField.IssueDate,
        InfrontSDK.SymbolField.PrimarySegmentDescription,
        InfrontSDK.SymbolField.PrimarySegment,
      ],
      { observe: false }
    )

    const issueDateValue = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.IssueDate) as unknown as Date
    const issueDate = issueDateValue !== undefined && issueDateValue !== null ? formatAsDate(issueDateValue) : undefined

    return (
      <Box>
        <Segment noContentPadding>
          <ListItem divider>
            <ListItemRow
              title={<Text variant="support2">{t('Värdepapperstyp')}</Text>}
              value={
                <ListItemValue loading={isLoadingCarnegieInstrument} value={carnegieInstrument?.instrumentTypeName} />
              }
            />
          </ListItem>
          <ListItem divider>
            <ListItemRow
              title={<Text variant="support2">{t('Kortnamn')}</Text>}
              value={
                <Text variant="subtitle2">{instrumentIdContainer?.infrontInstrument?.ticker ?? <NoDataDash />}</Text>
              }
            />
          </ListItem>
          <ListItem divider>
            <ListItemRow title={<Text variant="support2">{t('ISIN')}</Text>} value={<ListItemValue value={isin} />} />
          </ListItem>
          <ListItem divider>
            <ListItemRow
              title={<Text variant="support2">{t('Noteringsdatum')}</Text>}
              value={<ListItemValue loading={isLoadingInfrontInstrument} value={issueDate} />}
            />
          </ListItem>
          <ListItem divider={instrumentType === 'etf'}>
            <ListItemRow
              title={<Text variant="support2">{t('Belåningsgrad')}</Text>}
              value={<ListItemValue value={formatNumber(collateralRatio, { decimals: 0 })} unit="%" />}
            />
          </ListItem>
          {instrumentType === 'etf' && (
            <>
              <ListItem divider>
                <ListItemRow
                  title={<Text variant="support2">{t('Avgift')}</Text>}
                  value={
                    <ListItemValue value={formatNumber(carnegieInstrument.managementFee, { decimals: 2 })} unit="%" />
                  }
                />
              </ListItem>
              <ListItem divider>
                {isMobile ? (
                  <ListItemRow title={kiidLink} />
                ) : (
                  <ListItemRow title={<Text variant="support2">{t('Faktablad')}</Text>} value={kiidLink} />
                )}
              </ListItem>
              <ListItem>
                {isMobile ? (
                  <ListItemRow title={prospectusLink} />
                ) : (
                  <ListItemRow
                    title={<Text variant="support2">{t('Informationsbroschyr inkl. hållbarhet')}</Text>}
                    value={prospectusLink}
                  />
                )}
              </ListItem>
            </>
          )}
        </Segment>
        <Spacer height={16} />
        <Text variant="overline">{t('Handelsplats')}</Text>
        <Spacer height={8} />
        {marketplaceName === undefined ? (
          <SkeletonRect height={32} width={100} />
        ) : (
          <Tag>
            {instrumentIdContainer?.infrontInstrument && (
              <TagIcon>
                <InstrumentFlag instrumentIdContainer={instrumentIdContainer} />
              </TagIcon>
            )}
            <TagLabel>
              <NoDataFallback>{marketplaceName}</NoDataFallback>
            </TagLabel>
          </Tag>
        )}
      </Box>
    )
  }
)
