import { AccountResponse, PerformanceSerieResponse } from '@common/api/response'
import { useInfrontTimeSeries } from '@common/hooks/infront/sdk/useInfrontTimeSeries'
import { useAccountPerformance } from '@common/hooks/useAccountPerformance'
import {
  ChartDataPoint,
  convertToCompareIndexDataPoints,
  getNumOfDays,
  removeRedundantDataPoints,
  toDataPoint,
} from '@common/utils/chart'
import { getValueColor } from '@common/utils/colorUtils'
import { useTickers } from '@common/utils/infront/getTickers'
import { mapPeriodToPerformancePeriod } from '@common/utils/infront/mapPeriodToPerformancePeriod'
import { css } from '@emotion/react'

import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { formatNumber } from '@carnegie/digital-channels-frontend'
import {
  Box,
  Breakpoint,
  DataCell,
  FlexCol,
  FlexRow,
  IconInfoOutlined,
  ListItemRow,
  ListItemWithAccordion,
  Segment,
  SideDrawer,
  Spacer,
  Text,
  useBreakpoint,
} from '@carnegie/duplo'

import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js'
import { observer } from 'mobx-react-lite'

import { AreaChart } from '../overview/home/AreaChart'
import { AccountPerformanceItem } from './AccountPerformanceItem'
import { AccountPerformanceTabItem } from './AccountPerformanceTabItem'
import { CompareIndexSelector } from './CompareIndexSelector'

//Need to register the used chart elements from v4 of chartjs for tree shaking
//This will crash if removed
ChartJS.register(ArcElement, Tooltip, Legend)

type Ticker = {
  instrument: Infront.Instrument
  name: string
}

type AccountPerformanceProps = {
  accountId: string
  accountNumber: string
  performanceSerie: PerformanceSerieResponse
  selectedHoldingAccount: AccountResponse
}

export const AccountPerformance = observer(
  ({ accountId, accountNumber, performanceSerie, selectedHoldingAccount }: AccountPerformanceProps) => {
    const { t } = useTranslation()
    const breakpoint = useBreakpoint()
    const hasHoldings = selectedHoldingAccount?.holdings?.instruments?.length > 0
    const isMobile = breakpoint < Breakpoint.Medium

    const periods = [
      { value: 'c1WP', label: t('1 vecka'), description: t('1 vecka') },
      { value: 'c1MP', label: t('1m'), description: t('1 mån') },
      { value: 'c3MP', label: t('3m'), description: t('3 mån') },
      { value: 'c6MP', label: t('6m'), description: t('6 mån') },
      { value: 'ytdP', label: t('YTD'), description: t('YTD') },
      { value: 'c12MP', label: t('1 år'), description: t('1 år') },
      { value: 'c3YP', label: t('3 år'), description: t('3 år') },
      { value: 'c5YP', label: t('5 år'), description: t('5 år') },
      { value: 'sinceInceptionP', label: t('Sedan start'), description: t('Sedan start') },
    ]

    const tickers = useTickers()

    const [openDevelopmentSlide, setOpenDevelopmentSlide] = useState(false)
    const [selectedTicker, selectTicker] = useState<Ticker>(null)

    const [selectedPeriod, setSelectedPeriod] = useState(periods[2])
    const performanceDataPoints = useAccountPerformance(accountId, mapPeriodToPerformancePeriod(selectedPeriod.value))

    const numOfDays = getNumOfDays(performanceDataPoints)
    const infrontTimeSeriesPoints = useInfrontTimeSeries(selectedTicker?.instrument, numOfDays)
    let dataPoints = infrontTimeSeriesPoints.map(toDataPoint)

    // Need to duplicate 12-30 since Infront wont delivers data for 12-31 and we use that value as base for account performance (Due to CFW reasons)
    if (selectedPeriod.value === 'ytdP') {
      const dayBeforeNewYears = dataPoints.find((dp) => {
        const date = new Date(dp[0])
        const month = date.getMonth() + 1
        const day = date.getDate()
        return month === 12 && day === 30
      })

      if (dayBeforeNewYears) {
        const newDate = new Date(dayBeforeNewYears[0])
        newDate.setDate(newDate.getDate() + 1)
        const newTimestamp = newDate.getTime()
        const newYearsEve: ChartDataPoint = [newTimestamp, dayBeforeNewYears[1]]
        dataPoints = [...dataPoints, newYearsEve].sort((a, b) => a[0] - b[0])
      }
    }
    //

    const validDataPoints = removeRedundantDataPoints(performanceDataPoints, dataPoints)
    const compareIndexDataPoints = convertToCompareIndexDataPoints(validDataPoints)

    return (
      <Segment title="" noContentPadding variant="contained" height={288}>
        {/* set height to 288 as equal to allocations segment */}
        <FlexRow m={16} alignItems="center">
          <FlexCol flex={1}>
            <FlexRow alignItems="center">
              <DataCell onClick={() => setOpenDevelopmentSlide(true)} label={t('Utv. idag')} icon={IconInfoOutlined}>
                <FlexRow alignItems="center">
                  <AccountPerformanceItem
                    value={performanceSerie ? formatNumber(performanceSerie?.c1DP)?.toString() : '0'}
                    color={getValueColor(performanceSerie?.c1DP)}
                  />
                  &nbsp;
                  <AccountPerformanceItem
                    value={performanceSerie ? formatNumber(performanceSerie?.c1D, { decimals: 0 }).toString() : '0'}
                    color={getValueColor(performanceSerie?.c1D)}
                    suffix={'SEK'}
                    showParentheses
                  />
                </FlexRow>
              </DataCell>
            </FlexRow>
            <SideDrawer
              title={t('Utveckling')}
              variant="slide"
              sidebarContentPadding={0}
              sidebarContent={
                <FlexCol>
                  <ListItemWithAccordion
                    divider
                    expandableContent={
                      <Box p={16}>
                        <Text variant="body2">
                          {t(
                            'Utveckling idag visar hur kontot har utvecklats under dagen. Den värdepappersutveckling som ingår är förändring sedan gårdagens stängningskurs för de värdepapper som uppdateras intradag. För innehav noterade i annan valuta än SEK sker omräkning till gårdagens valutakurs.'
                          )}
                        </Text>
                        <Spacer height={16} />
                        <Text variant="body2">
                          {t(
                            'Observera att siffran inte tar hänsyn till tidpunkt för eventuella förändringar i ditt innehav under dagen.'
                          )}
                        </Text>
                        <Spacer height={16} />
                        <Text variant="body2">
                          {t(
                            'I vissa fall kan utveckling idag i värde skilja sig från utveckling i procent. Det beror på att ”Idag +/-” räknar om alla belopp till SEK. I utveckling ”Idag %” behålls beloppen i värdepapprets handelsvaluta.'
                          )}
                        </Text>
                      </Box>
                    }
                  >
                    <ListItemRow title={<Text variant="subtitle2">{t('Utveckling idag')}</Text>} />
                  </ListItemWithAccordion>
                  <ListItemWithAccordion
                    divider
                    expandableContent={
                      <Box p={16}>
                        <Text variant="body2">
                          {t(
                            'Här visas hur ditt konto har utvecklas under en viss tidsperiod. Dessa värden uppdateras varje natt vilket innebär att utveckling idag inkluderas först dagen efter. Observera att utveckling ”sedan start” för konton med lång historik inte alltid innebär utveckling sedan kontot öppnades.'
                          )}
                        </Text>
                        <Spacer height={16} />
                        <Text variant="body2">
                          {t('Du kan välja att lägga till ett index för att jämföra din utveckling.')}
                        </Text>
                        <Spacer height={16} />
                        <Text variant="body2">
                          {t(
                            'Kontots innehav samt avkastningsberäkningar är baserade på värderingar gjorda till senast kända kurs och kan i vissa fall vara indikativa. Kurser för vissa fonder uppdateras av respektive fondbolag i efterhand enligt bolagens värderingsprinciper. Värderingar och avkastning kan förändras historiskt, vilket även kan inkludera transaktioner som inte är slutgiltigt bokade. Portföljens avkastning inkluderar transaktionskostnader, eventuella debiteringar av värde- och avkastningsbaserade förvaltningsarvoden, depåavgifter och moms. Tidsviktad avkastning visar utvecklingen i din portfölj under en tidsperiod och används bland annat av Pensionsmyndigheten som avkastningsmått för premiepensionssparandet. Metoden delar upp avkastningen i olika tidsperioder för att eliminera effekten av insättningar och uttag under perioden. En tidsviktad avkastning är en mer rättvis bild av den totala avkastningen i din portfölj och är ett bra mått för att kunna jämföra avkastningen med andra investeringar.'
                          )}
                        </Text>
                      </Box>
                    }
                  >
                    <ListItemRow title={<Text variant="subtitle2">{t('Kontografen')}</Text>} />
                  </ListItemWithAccordion>
                </FlexCol>
              }
              open={openDevelopmentSlide}
              onClose={() => {
                setOpenDevelopmentSlide(false)
              }}
            ></SideDrawer>
          </FlexCol>
          <FlexCol>
            <CompareIndexSelector
              tickers={tickers}
              selectedTicker={selectedTicker}
              onSelectTicker={(ticker) => selectTicker(ticker)}
            />
          </FlexCol>
        </FlexRow>
        <FlexRow mb={16}>
          {!performanceSerie || !performanceDataPoints || performanceDataPoints?.length === 0 ? (
            <FlexRow css={{ height: 140 }} alignItems="center" justifyContent="center" flex={1}>
              <Text variant="body2" color="text-low-emphasis">
                {selectedHoldingAccount &&
                  (hasHoldings
                    ? t('Ingen data tillgänglig')
                    : t('Här visas utvecklingen när det finns innehav på kontot.'))}
              </Text>
            </FlexRow>
          ) : (
            <AreaChart
              dataName={accountNumber}
              dataPoints={performanceDataPoints}
              compareDataName={selectedTicker && selectedTicker.name}
              compareDataPoints={compareIndexDataPoints}
              style={{ height: 140, width: '100%', margin: 0 }}
              language="sv"
              options={{
                chart: { marginBottom: 25 },
                tooltip: {
                  enabled: true,
                },
                plotOptions: {
                  area: {
                    enableMouseTracking: true,
                  },
                },
              }}
              showAxis={true}
            />
          )}
        </FlexRow>
        <FlexRow
          alignItems="center"
          flexWrap="nowrap"
          css={css`
            ${isMobile &&
            `
                    overflow-y: hidden;
                    overflow-x: scroll;
                    scroll-behavior: smooth;
                    scrollbar-width: none;
                    &::-webkit-scrollbar {
                      display: none;
                    }
                    `}
            white-space: nowrap;
          `}
          mb={16}
        >
          {periods?.map((period, index) => (
            <AccountPerformanceTabItem
              key={period?.value}
              label={period?.label}
              value={performanceSerie && formatNumber(performanceSerie[period?.value])}
              color={(performanceSerie && getValueColor(performanceSerie[period?.value])) || 'current'}
              hideBorder={index === periods.length - 1}
              onClick={() => setSelectedPeriod(period)}
              isSelected={selectedPeriod?.value === period?.value}
            />
          ))}
        </FlexRow>
      </Segment>
    )
  }
)
