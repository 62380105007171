import { useActivePageCategory } from '@common/hooks/useActivePageCategory'
import { useAuth } from '@common/stores/store'
import { css } from '@emotion/react'
import styled from '@emotion/styled'

import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Icon,
  IconBarChartPositive,
  IconBarChartPositiveFilled,
  IconHome,
  IconHomeFilled,
  IconUserDetails,
  IconUserDetailsFilled,
  Link,
  LinkButton,
  LinkExternal,
  LinkUnstyled,
  Paragraph,
  Spacer,
  colors,
  md,
  sm,
} from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import { IconCarnegieCLogoFilled } from './CarnegieCLogo'

export const MainMenu = observer(() => {
  const { t } = useTranslation()
  const auth = useAuth()

  const activePageCategory = useActivePageCategory()

  return (
    <div style={{ width: 96, position: 'sticky', top: 60 }}>
      <MainMenuListHolder>
        <MainMenuListItem selected={activePageCategory === 'overview'}>
          <StyledLink to="/overview/home">
            <Icon icon={activePageCategory === 'overview' ? IconHomeFilled : IconHome} size={32} mb={2} />
            <Paragraph variant="body1">{t('Översikt')}</Paragraph>
          </StyledLink>
        </MainMenuListItem>

        {auth.hasLimitedView || (
          <>
            <MainMenuListItem selected={activePageCategory === 'market'}>
              <StyledLink to="/market/overview">
                <Icon
                  icon={activePageCategory === 'market' ? IconBarChartPositiveFilled : IconBarChartPositive}
                  size={32}
                  mb={2}
                />
                <Paragraph variant="body1">{t('Marknad')}</Paragraph>
              </StyledLink>
            </MainMenuListItem>

            <MainMenuListItem selected={activePageCategory === 'explore'}>
              <StyledLink to="/explore/publications">
                <Icon icon={IconCarnegieCLogoFilled} size={32} mb={2} />
                <Paragraph variant="body1">{t('Utforska')}</Paragraph>
              </StyledLink>
            </MainMenuListItem>
          </>
        )}

        <MainMenuListItem selected={activePageCategory === 'profile'}>
          <StyledLink to="/profile/mydata">
            <Icon icon={activePageCategory === 'profile' ? IconUserDetailsFilled : IconUserDetails} size={32} mb={2} />
            <Paragraph variant="body1">{t('Profil')}</Paragraph>
          </StyledLink>
        </MainMenuListItem>
      </MainMenuListHolder>
    </div>
  )
})

const StyledLink = styled(LinkUnstyled)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-decoration: none !important;
  color: ${colors.bunker.main};
`

const MainMenuListHolder = (props: React.HTMLAttributes<HTMLUListElement>) => {
  return (
    <ul
      css={{
        listStyle: 'none',
        margin: '0',
        padding: '0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      {...props}
    >
      {props.children}
    </ul>
  )
}

type MainMenuListItemProps = { selected: boolean; className?: string; children?: ReactNode }

const MainMenuListItem = ({ children, className, selected }: MainMenuListItemProps) => {
  return (
    <li
      css={css`
        width: 80px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;
        border-radius: 8px;
        margin-bottom: 24px;
        transition: background-color 400ms ease;
        ${md(`&:hover {
    background-color: rgb(235, 220, 210, 0.6);
  }`)};
        ${selected &&
        `background-color: rgba(214, 229, 240, .16); ${md(`background-color: ${colors.carnegieDarkPink}`)};`}
      `}
      className={className}
    >
      {children}
    </li>
  )
}

export const FooterMenu = () => {
  const { t, i18n } = useTranslation()

  return (
    <>
      <StyledFooterMenu>
        <MenuItem>
          <Link color={'text-light'} to="/profile/contact">
            {t('Kontakta oss')}
          </Link>
        </MenuItem>
      </StyledFooterMenu>
      <Spacer height={16} />
      <StyledFooterMenu>
        <MenuItem>
          <LinkExternal
            color={'text-light'}
            target="_blank"
            href={
              i18n.language === 'sv'
                ? 'https://www.carnegie.se/personuppgifter/'
                : 'https://www.carnegie.se/en/personaldata/'
            }
          >
            {t('Behandling av personuppgifter')}
          </LinkExternal>
        </MenuItem>
        <MenuItem>
          <LinkExternal
            color={'text-light'}
            target="_blank"
            href="https://www.carnegie.se/allmanna/allmanna_bestammelser/"
          >
            {t('Allmänna bestämmelser')}
          </LinkExternal>
        </MenuItem>
        <MenuItem>
          <LinkExternal color={'text-light'} target="_blank" href="https://www.carnegie.se/cookies/">
            {t('Cookies')}
          </LinkExternal>
        </MenuItem>
        <MenuItem>
          <LinkButton
            color={'text-light'}
            onClick={() => {
              window.CookieScript.instance.show()
            }}
          >
            {t('Inställningar för cookies')}
          </LinkButton>
        </MenuItem>
        <MenuItem>
          <LinkExternal color={'text-light'} target="_blank" href="https://www.carnegie.se/anvandarvillkor/">
            {t('Användarvillkor')}
          </LinkExternal>
        </MenuItem>
        <MenuItem>
          <LinkExternal
            color={'text-light'}
            target="_blank"
            href="https://www.carnegie.se/private-banking/dokument-och-underlag/prislista/"
          >
            {t('Kostnader och avgifter')}
          </LinkExternal>
        </MenuItem>
      </StyledFooterMenu>
    </>
  )
}

const StyledFooterMenu = (props: React.HTMLAttributes<HTMLUListElement>) => {
  return (
    <ul
      css={css`
        list-style: none;
        padding: 0;
        display: flex;
        flex-direction: column;
        ${sm('flex-direction: row; flex-wrap: wrap;')}
      `}
      {...props}
    >
      {props.children}
    </ul>
  )
}

const MenuItem = (props: React.LiHTMLAttributes<HTMLLIElement>) => {
  return (
    <li
      css={css`
        flex: 1 1 auto;
        margin-bottom: 8px;
        ${sm('flex: 0 1 auto; margin-right: 32px; margin-bottom: 0;')}

        a {
          color: ${colors.carnegiePink};
          text-decoration: underline;
        }
        button {
          text-decoration: underline;
        }
      `}
      {...props}
    >
      {props.children}
    </li>
  )
}
