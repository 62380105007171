import { FundOrderInfoBuy, FundOrderInfoSell } from '@common/api/response'

import { TFunction } from 'i18next'

export function validateBuyInput(numericValue: number, fundOrderInfoBuy: FundOrderInfoBuy, t: TFunction) {
  let inputError = ''

  if (numericValue < fundOrderInfoBuy.minimumAmount) {
    inputError = t('Minsta belopp är {amount} SEK').replaceAll('{amount}', fundOrderInfoBuy.minimumAmount.toString())
  } else if (numericValue > fundOrderInfoBuy.maximumAmount) {
    inputError = t('Beloppet överskrider tillgängligt för köp')
  }

  return inputError
}
export function validateSellInput(
  numericValue: number,
  sellAll: boolean,
  fundOrderInfoSell: FundOrderInfoSell,
  t: TFunction
) {
  let inputError = ''

  if (!fundOrderInfoSell.isSellAllAvailable && sellAll) {
    inputError = t(
      'Det finns en pågående order i den här fonden som måste bli klar innan du kan sälja hela innehavet. Kontakta oss för mer information.'
    )
  } else if (numericValue < fundOrderInfoSell.minimumAmount) {
    inputError = t('Minsta belopp är {amount} SEK').replaceAll('{amount}', fundOrderInfoSell.minimumAmount.toString())
  } else if (numericValue > fundOrderInfoSell.ninetyPercentRule && numericValue < fundOrderInfoSell.maximumAmount) {
    // > 90% och < 100%
    inputError = t(
      'Beloppet får max uppgå till 90 % av tillgängligt innehav. Vill du sälja för mer, ta kontakt med oss eller välj alternativet ”Sälj allt”.'
    )
  } else if (numericValue > fundOrderInfoSell.maximumAmount) {
    // > 100%
    inputError = t('Beloppet överskrider tillgängligt innehav')
  }

  return inputError
}
