import { useEffect, useRef } from 'react'

const __DEV__ = process.env.NODE_ENV !== 'production'
const HOOK_NAME = 'useLocalStore'
const NO_DEPS_HINT = 'If there are no dependencies, use "const [state] = useState(fn)" instead'

/**
 * Copied from from React sources and adjusted
 * inlined Object.is polyfill to avoid requiring consumers ship their own
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/is
 */
function is(x, y) {
  return (x === y && (x !== 0 || 1 / x === 1 / y)) || (x !== x && y !== y)
}

// Copied from React sources and adjusted
function areHookInputsEqual(nextDeps, prevDeps) {
  if (__DEV__) {
    // Don't bother comparing lengths in prod because these arrays should be
    // passed inline.
    if (nextDeps.length !== prevDeps.length) {
      console.error(
        'Warning: ' +
          'The final argument passed to %s changed size between renders. The ' +
          'order and size of this array must remain constant.\n\n' +
          'Previous: %s\n' +
          'Incoming: %s',
        HOOK_NAME,
        `[${nextDeps.join(', ')}]`,
        `[${prevDeps.join(', ')}]`
      )
    }
  }
  for (let i = 0; i < prevDeps.length && i < nextDeps.length; i++) {
    if (is(nextDeps[i], prevDeps[i])) {
      continue
    }
    return false
  }
  return true
}

/**
 * Sync props to a MobX store during render.
 * In some cases we wan't to sync the store with props coming in to a component during render (and not useEffect)
 * since we wan't to use the result of the store during the same render.
 * Handle this with caution, make sure to not run any async code inside the syncAction method. Make sure to correctly use
 * the deps array or you might get infinite renders.
 * @param syncAction
 * @param deps
 */
export function useSyncPropsToStore(syncAction: () => void, deps: ReadonlyArray<unknown>) {
  const prevDepsRef = useRef<ReadonlyArray<unknown>>(deps)
  if (deps === prevDepsRef.current) {
    // First run
    syncAction()
  } else if (!areHookInputsEqual(deps, prevDepsRef.current)) {
    syncAction()
    prevDepsRef.current = deps
  }
}

/**
 * Creates a new store when the deps array is changed. Will return the store during first render (unlike useEffect).
 * Inspired by this github issue: https://github.com/mobxjs/mobx-react-lite/issues/74
 * and gist: https://gist.github.com/urugator/5c78da03a7b1a7682919cc1cf68ff8e9
 */
export function useLocalStore<T>(factory: () => T, deps: ReadonlyArray<unknown>, onStoreCleanup?: (store: T) => void) {
  if (__DEV__) {
    if (typeof factory !== 'function') {
      console.error('Warning: The first argument of %s must be a function, received: %s', HOOK_NAME, factory)
    }
    if (!Array.isArray(deps)) {
      console.error(
        'Warning: The final argument of %s must be an array, received: %s\n' + NO_DEPS_HINT,
        HOOK_NAME,
        deps
      )
    }
    if (deps.length === 0) {
      console.error('Warning: The array of dependencies passed to %s must not be empty\n' + NO_DEPS_HINT, HOOK_NAME)
    }
  }

  const stateRef = useRef<T>()
  const onCleanupRef = useRef<(store: T) => void>()
  const prevDepsRef = useRef<ReadonlyArray<unknown>>(deps)

  if (deps === prevDepsRef.current) {
    // First run
    stateRef.current = factory()
    onCleanupRef.current = onStoreCleanup
  } else if (!areHookInputsEqual(deps, prevDepsRef.current)) {
    // Destroy old object if used
    if (stateRef.current) {
      onStoreCleanup?.(stateRef.current)
    }
    stateRef.current = factory()
    onCleanupRef.current = onStoreCleanup
    prevDepsRef.current = deps
  }

  // Cleanup on unmount
  useEffect(() => {
    if (stateRef.current) {
      return () => {
        onCleanupRef.current?.(stateRef.current)
      }
    }
  }, [])

  return stateRef.current
}
