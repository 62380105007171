import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, DuploSpaceProps, FlexRow, ListItem, Paragraph, SkeletonRect, SkeletonText } from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

type SkeletonLoaderProps = {
  loading?: boolean
  className?: string
  children?: () => ReactNode
  noDataLoaded?: boolean
  error?: unknown
  errorComponent?: ReactNode
  height?: number | string
  skeletonItemCount?: number
  variant?: 'rect' | 'list'
  skeletonComponent?: ReactNode
  // Only used with variant = "list"
  skeletonListItemContent?: ReactNode
  noDataComponent?: ReactNode
} & DuploSpaceProps

type SkeletonListItemProps = {
  divider: boolean
  skeletonListItemContent?: ReactNode
}

export const SkeletonListItem = ({ divider, skeletonListItemContent }: SkeletonListItemProps) => {
  return (
    <ListItem divider={divider}>
      {skeletonListItemContent ? (
        skeletonListItemContent
      ) : (
        <>
          <SkeletonText />
          <SkeletonText />
        </>
      )}
    </ListItem>
  )
}

type SkeletonLoaderMessageProps = {
  messageType: 'no-data-loaded' | 'error'
  errorComponent?: ReactNode
  className?: string
}

export const SkeletonLoaderMessage = ({
  messageType,
  className,
  errorComponent,
  ...rest
}: SkeletonLoaderMessageProps) => {
  const { t } = useTranslation()

  let loaderMessage: ReactNode = t('Ingen data tillgänglig')

  if (messageType === 'error') {
    loaderMessage = t('Fel vid datahämtning')
  }

  return (
    <FlexRow alignItems="center" className={className} {...rest}>
      {errorComponent ? (
        errorComponent
      ) : (
        <Paragraph variant="body1" color="text-low-emphasis">
          {loaderMessage}
        </Paragraph>
      )}
    </FlexRow>
  )
}

export const SkeletonLoader = observer(
  ({
    error,
    noDataLoaded,
    children,
    className,
    loading,
    height = 300,
    skeletonItemCount = 5,
    variant = 'rect',
    skeletonComponent,
    noDataComponent,
    skeletonListItemContent,
    errorComponent,
    ...rest
  }: SkeletonLoaderProps) => {
    if (error) {
      return (
        <Box className={className} {...rest}>
          <SkeletonLoaderMessage errorComponent={errorComponent} messageType="error" />
        </Box>
      )
    }

    if (noDataLoaded && !loading) {
      return (
        <Box className={className} {...rest}>
          {noDataComponent === undefined ? <SkeletonLoaderMessage messageType="no-data-loaded" /> : noDataComponent}
        </Box>
      )
    }

    return (
      <>
        {!loading ? (
          children()
        ) : variant === 'rect' ? (
          skeletonComponent === undefined ? (
            <Box data-testid="skeleton-loader" className={className} {...rest}>
              <SkeletonRect css={{ height: height }} width="100%" />
            </Box>
          ) : (
            skeletonComponent
          )
        ) : (
          [...Array(skeletonItemCount)].map((item, index) => (
            <SkeletonListItem
              data-testid="skeleton-loader"
              key={index}
              divider={index !== skeletonItemCount - 1}
              skeletonListItemContent={skeletonListItemContent}
            />
          ))
        )}
      </>
    )
  }
)
