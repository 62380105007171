import { NoDataFallback } from '@common/components/NoDataFallback'
import { useInfrontInstrument } from '@common/hooks/infront/sdk/useInfrontInstrument'
import { usePrevious } from '@common/hooks/usePrevious'
import { InstrumentIdContainer } from '@common/instrumentIdContainer'
import { fadeOutIn } from '@common/utils/animations'

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { formatNumber } from '@carnegie/digital-channels-frontend'
import { SkeletonRect, Text } from '@carnegie/duplo'

import { useAnimation } from 'framer-motion'
import { observer } from 'mobx-react-lite'

import { Carousel } from '../../../pages/overview/home/Carousel'
import { ValueContainer } from '../InstrumentTopSegment'

type InstrumentStocksPriceInformationProps = {
  instrumentIdContainer: InstrumentIdContainer
}

export const InstrumentStocksPriceInformation = observer(
  ({ instrumentIdContainer }: InstrumentStocksPriceInformationProps) => {
    const { t } = useTranslation()

    const { observableInstrument, isLoading } = useInfrontInstrument(instrumentIdContainer?.infrontInstrument, [
      InfrontSDK.SymbolField.Bid,
      InfrontSDK.SymbolField.Ask,
      InfrontSDK.SymbolField.High,
      InfrontSDK.SymbolField.Low,
      InfrontSDK.SymbolField.Turnover,
      InfrontSDK.BasicField.PriceDecimals,
    ])

    const bidValueAnimation = useAnimation()

    const priceDecimals = observableInstrument?.getFieldValue(InfrontSDK.BasicField.PriceDecimals)

    const bid = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.Bid)
    const bidFormatted = formatNumber(bid, { decimals: { min: 2, max: priceDecimals } })

    const prevBid = usePrevious(bidFormatted)

    const askValueAnimation = useAnimation()
    const ask = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.Ask)
    const askFormatted = formatNumber(ask, { decimals: { min: 2, max: priceDecimals } })
    const prevAsk = usePrevious(askFormatted)

    const highValueAnimation = useAnimation()
    const high = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.High)
    const highFormatted = formatNumber(high, { decimals: { min: 2, max: priceDecimals } })
    const prevHigh = usePrevious(highFormatted)

    const lowValueAnimation = useAnimation()
    const low = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.Low)
    const lowFormatted = formatNumber(low, { decimals: { min: 2, max: priceDecimals } })
    const prevLow = usePrevious(lowFormatted)

    const turnoverValueAnimation = useAnimation()
    const turnoverFormatted = formatNumber(observableInstrument?.getFieldValue(InfrontSDK.SymbolField.Turnover), {
      decimals: 0,
    })

    const prevTurnover = usePrevious(turnoverFormatted)

    useEffect(() => {
      if (bidFormatted !== prevBid) {
        fadeOutIn(bidValueAnimation)
      }
      if (askFormatted !== prevAsk) {
        fadeOutIn(askValueAnimation)
      }
      if (highFormatted !== prevHigh) {
        fadeOutIn(highValueAnimation)
      }
      if (lowFormatted !== prevLow) {
        fadeOutIn(lowValueAnimation)
      }
      if (turnoverFormatted !== prevTurnover) {
        fadeOutIn(turnoverValueAnimation)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bidFormatted, askFormatted, highFormatted, lowFormatted, turnoverFormatted])

    return (
      <>
        {isLoading ? (
          <SkeletonRect width="full" height={40} />
        ) : (
          <Carousel hideButtons>
            <ValueContainer>
              <Text variant="label1">{t('Köp')}</Text>
              <Text variant="subtitle1">
                <NoDataFallback>{bidFormatted}</NoDataFallback>
              </Text>
            </ValueContainer>
            <ValueContainer>
              <Text variant="label1">{t('Sälj')}</Text>
              <Text variant="subtitle1">
                <NoDataFallback>{askFormatted}</NoDataFallback>
              </Text>
            </ValueContainer>
            <ValueContainer>
              <Text variant="label1">{t('Högst')}</Text>
              <Text variant="subtitle1">
                <NoDataFallback>{highFormatted}</NoDataFallback>
              </Text>
            </ValueContainer>
            <ValueContainer>
              <Text variant="label1">{t('Lägst')}</Text>
              <Text variant="subtitle1">
                <NoDataFallback>{lowFormatted}</NoDataFallback>
              </Text>
            </ValueContainer>
            <ValueContainer>
              <Text variant="label1">{t('Omsättning')}</Text>
              <Text variant="subtitle1">
                <NoDataFallback>{turnoverFormatted}</NoDataFallback>
              </Text>
            </ValueContainer>
          </Carousel>
        )}
      </>
    )
  }
)
InstrumentStocksPriceInformation.displayName = 'InstrumentStocksPriceInformation'
