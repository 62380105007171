import React from 'react'

import { Box, Breakpoint, DuploColor, FlexRow, Text, useBreakpoint } from '@carnegie/duplo'

type AccountPerformanceItemProps = { color: DuploColor; showParentheses?: boolean; suffix?: string; value: string }

export const AccountPerformanceItem = ({
  value,
  color = 'bunker-main',
  suffix = '%',
  showParentheses = false,
}: AccountPerformanceItemProps) => {
  const breakpoint = useBreakpoint()
  const isExtraSmallScreen = breakpoint < Breakpoint.Small

  return (
    <FlexRow alignItems="baseline">
      <Text variant="subtitle1" css={{ fontSize: `${isExtraSmallScreen ? '14px' : '16px'}` }}>
        {showParentheses && ` ( `}
        <Box as="span" color={color}>
          {value}
        </Box>
        <Text variant="label1" ml={2} css={{ fontSize: `${isExtraSmallScreen ? '10px' : '12px'}` }} color={color}>
          {suffix}
        </Text>
        {showParentheses && ` )`}
      </Text>
    </FlexRow>
  )
}
