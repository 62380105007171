import { NoDataDash } from '@common/components/NoDataFallback'
import { InstrumentIdContainer } from '@common/instrumentIdContainer'

import React from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Segment, SegmentWithTabs, SimpleTabs, Spacer } from '@carnegie/duplo'

import { startOfYesterday } from 'date-fns'
import { endOfToday, startOfToday, sub } from 'date-fns/esm'

import { MarketHappenings } from '../../../pages/market/overview/MarketHappenings'
import { InstrumentStocksCompanyInformation } from './InstrumentStocksCompanyInformation'
import { InstrumentStocksInformation } from './InstrumentStocksInformation'

const todayStart = startOfToday()
const todayEnd = endOfToday()
const yesterday = startOfYesterday()
const prevYear = sub(todayStart, { years: 1 })

type InstrumentStocksOverviewProps = {
  instrumentIdContainer: InstrumentIdContainer
  collateralRatio: number
}

export const InstrumentStocksOverview = ({ instrumentIdContainer, collateralRatio }: InstrumentStocksOverviewProps) => {
  const { t } = useTranslation()

  return (
    <SegmentWithTabs variant="contained" headingVariant="overline">
      <SimpleTabs
        tabs={[
          {
            value: 'tab-information',
            label: t('Information'),
            content: (
              <Box p={16}>
                <InstrumentStocksInformation
                  instrumentIdContainer={instrumentIdContainer}
                  collateralRatio={collateralRatio}
                />
              </Box>
            ),
          },
          {
            value: 'tab-company',
            label: t('Bolaget'),
            content: (
              <Box p={16}>
                <InstrumentStocksCompanyInformation instrumentIdContainer={instrumentIdContainer} />
              </Box>
            ),
          },
          {
            value: 'tab-events',
            label: t('Bolagshändelser'),
            content: (
              <Box p={16}>
                {instrumentIdContainer?.infrontInstrument ? (
                  <MarketHappenings
                    instrument={instrumentIdContainer?.infrontInstrument}
                    from={todayStart}
                    to={todayEnd}
                    useSegmentWrapper
                    segmentWrapperTitle={t('Idag')}
                    noDataComponent={null}
                  />
                ) : (
                  <>
                    <Segment variant="uncontained" title={t('Idag')}>
                      <NoDataDash />
                    </Segment>
                    <Spacer height={16} />
                  </>
                )}

                {instrumentIdContainer?.infrontInstrument ? (
                  <MarketHappenings
                    instrument={instrumentIdContainer?.infrontInstrument}
                    useSegmentWrapper
                    segmentWrapperTitle={t('Kommande')}
                  />
                ) : (
                  <>
                    <Segment variant="uncontained" title={t('Kommande')}>
                      <NoDataDash />
                    </Segment>
                    <Spacer height={16} />
                  </>
                )}

                {instrumentIdContainer?.infrontInstrument ? (
                  <MarketHappenings
                    instrument={instrumentIdContainer?.infrontInstrument}
                    customOptions={{ desc: true }}
                    from={prevYear}
                    to={yesterday}
                    useSegmentWrapper
                    segmentWrapperTitle={t('Tidigare')}
                  />
                ) : (
                  <>
                    <Segment variant="uncontained" title={t('Tidigare')}>
                      <NoDataDash />
                    </Segment>
                    <Spacer height={16} />
                  </>
                )}
              </Box>
            ),
          },
        ]}
      />
    </SegmentWithTabs>
  )
}
InstrumentStocksOverview.displayName = 'InstrumentStocksOverview'
