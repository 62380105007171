import { useState } from 'react'

enum HoldingsGroup {
  InstrumentGroupName = 'InstrumentGroupName',
  Allocation = 'Allocation',
}

export const useHoldingsGroup = () => {
  const [groupBy, setGroupBy] = useState(HoldingsGroup.InstrumentGroupName) //default

  return {
    holdingsGroups: HoldingsGroup,
    groupBy,
    setGroupBy,
  }
}
