import { OfferingType } from '@common/api/response'
import { Offering } from '@common/hooks/useOfferings'
import { fireTrackEvent } from '@common/utils/analyticsEvent'

import { useTranslation } from 'react-i18next'

import { formatDate } from '@carnegie/digital-channels-frontend'
import { Box, Card, DuploColor, Flex, FlexCol, FlexRow, Tag, Text } from '@carnegie/duplo'

import { useOpenOfferingDrawer } from '@/drawers/corporateActionsDrawer/useOpenOfferingDrawer'

export type OfferingCardProps = {
  offering: Offering | undefined // Undefined will show a skeleton
}

const KEYS = {
  '/overview/home': 'Overview/Home',
  '/overview/account': 'Account',
  '/overview/accounts': 'Accounts',
  '/messages': 'Messages',
} as const

const TRACK_EVENT = 'click_card_offering' as const

const getCategory = () => {
  const { pathname } = window.location
  let category = KEYS[pathname] || pathname

  if (pathname.startsWith('/messages')) category = KEYS['/messages']

  return category
}

export const OfferingCard = ({ offering }: OfferingCardProps) => {
  const statusColor: DuploColor = offering?.hasResponded === true ? 'status-success' : 'status-warning'

  const { t } = useTranslation()

  const lastResponseDateText = offering ? formatDate(offering.lastResponseDate, 'yyyy-MM-dd') : undefined

  const { openOfferingsDrawer } = useOpenOfferingDrawer()
  const openDrawer = () => {
    if (offering) {
      const category = getCategory()
      fireTrackEvent(category, TRACK_EVENT)
      openOfferingsDrawer(offering.offeringId)
    }
  }

  const offeringTypeText: Record<OfferingType, string> = {
    [OfferingType.RightsIssue]: t('RightsIssue'),
    [OfferingType.Unknown]: '-',
    [OfferingType.Takeover]: t('Uppköpserbjudande'),
    [OfferingType.WarrantsExercise]: t('Teckningsoption'),
  }

  let hasRespondedText = ''

  switch (offering?.hasResponded) {
    case true:
      hasRespondedText = t('Besvarad')
      break
    case false:
      hasRespondedText = t('Obesvarad')
      break
    case 'mixed':
      hasRespondedText = t('Delvis besvarad')
      break
  }

  return (
    <Card
      width="full"
      onClick={openDrawer}
      textAlign="left"
      display="flex"
      position="relative"
      flexDirection="row"
      overflow="hidden"
    >
      {/* Status border */}
      <Flex
        left={0}
        width={8}
        top={0}
        bottom={0}
        position="absolute"
        height="full"
        backgroundColor={offering ? statusColor : 'border-light'}
      ></Flex>
      <Box ml={8} p={16}>
        <FlexRow justifyContent="flex-start" mb={8}>
          <Tag
            size="small"
            type={offering ? (offering.hasResponded === true ? 'success' : 'warning') : 'default'}
            skeleton={!offering}
            skeletonWidth={76.5}
          >
            {hasRespondedText}
          </Tag>
        </FlexRow>
        <FlexCol>
          <Text variant="subtitle1" skeletonWidth={100} skeleton={!offering}>
            {offering?.instrumentName}
          </Text>
          <Text variant="body1" skeletonWidth={100} skeleton={!offering}>
            {offeringTypeText[offering?.offeringType]}
          </Text>
          <Text variant="body1" skeletonWidth={150} skeleton={!offering}>
            {t('Sista svarsdag')}: <Text whiteSpace="nowrap">{lastResponseDateText}</Text>
          </Text>
        </FlexCol>
      </Box>
    </Card>
  )
}
