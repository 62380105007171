import { useMobileDetect } from '@common/hooks/useMobileDetect'

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FlexRow, Icon, IconButton, IconCopy, IconSuccess, Tooltip } from '@carnegie/duplo'

type CopyToClipboardProps = {
  identifier: string
  value: string | number
  size?: 'small' | 'medium' | 'large'
  style?: React.CSSProperties
}

export const CopyToClipboard = ({ identifier, value, size = 'small', style }: CopyToClipboardProps) => {
  const [isCopied, setIsCopied] = useState(false)
  const { t } = useTranslation()
  const { isIos } = useMobileDetect()

  useEffect(() => {
    let timer: NodeJS.Timeout

    if (isCopied) {
      navigator.clipboard.writeText(value.toString())

      timer = setTimeout(() => setIsCopied(false), 3 * 1000)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [value, isCopied])

  const titleIsCopied = (
    <FlexRow alignItems="center">
      <Icon color="status-success" icon={IconSuccess} size={16} mr={4} />
      {identifier + ' ' + t('kopierat')}
    </FlexRow>
  )

  const handleCopy = (event) => {
    event.stopPropagation()
    setIsCopied(true)
  }

  return isIos() ? null : (
    <Tooltip title={isCopied ? titleIsCopied : t('Kopiera') + ' ' + identifier} placement="bottom">
      <div style={style}>
        <IconButton size={size} variant="uncontained" onPointerDown={handleCopy} icon={IconCopy} m={4} />
      </div>
    </Tooltip>
  )
}
