export function removeHtmlTags(text: string) {
  if (text) {
    const regex = /(<([^>]+)>)/gi
    const result = text.replace(regex, '')

    if (decodeHtmlEntity) {
      return decodeHtmlEntity(result)
    } else {
      return result
    }
  }

  return text
}

function decodeHtmlEntity(str: string) {
  return str.replace(/&#(\d+);/g, function (match, dec) {
    return String.fromCharCode(dec)
  })
}
