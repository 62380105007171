import { useEffect } from 'react'

export class EventEmitter<T extends string> {
  listeners: Map<T, (() => void)[]> = new Map()
  addEventListener = (eventType: T, callback: () => void) => {
    let callbacks: (() => void)[]

    if (this.listeners.has(eventType)) {
      callbacks = this.listeners.get(eventType)
    } else {
      callbacks = []
      this.listeners.set(eventType, callbacks)
    }
    callbacks.push(callback)

    return () => {
      callbacks = callbacks.filter((cb) => cb !== callback && cb)
    }
  }
  emit = (eventType: T) => {
    if (this.listeners.has(eventType)) {
      this.listeners.get(eventType).forEach((cb) => cb())
    }
  }
}

export function useEventEmitterSubscriber<T extends string>(
  eventEmitter: EventEmitter<T>,
  eventType: T,
  callback: () => void
) {
  useEffect(() => {
    const removeEventListener = eventEmitter.addEventListener(eventType, () => {
      callback()
    })
    return removeEventListener
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
