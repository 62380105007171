import { InfrontInstrument } from '@common/api/response'
import { useInfrontInstrument } from '@common/hooks/infront/sdk/useInfrontInstrument'
import { useInfrontIntradayTrades } from '@common/hooks/infront/widgets/useInfrontIntradayTrades'
import { usePrevious } from '@common/hooks/usePrevious'
import { fadeOutIn } from '@common/utils/animations'
import { css } from '@emotion/react'

import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { formatNumber } from '@carnegie/digital-channels-frontend'
import { Breakpoint, FlexCol, FlexRow, Segment, Text, useBreakpoint, useDuploTheme } from '@carnegie/duplo'

import { useAnimation } from 'framer-motion'
import { observer } from 'mobx-react-lite'

type OrderLatestTradesProps = {
  instrument: InfrontInstrument
  onPriceClick: (price: number) => void
}

export const OrderLatestTrades = observer(({ instrument, onPriceClick }: OrderLatestTradesProps) => {
  const { t } = useTranslation()
  const breakpoint = useBreakpoint()

  const isSmallScreen = breakpoint < Breakpoint.Medium

  // We need to pass a ref to the callback to infront since they will never re-initialize if we send in a new callback reference
  const onPriceClickRef = useRef(onPriceClick)
  onPriceClickRef.current = onPriceClick

  const { observableInstrument } = useInfrontInstrument(instrument, [
    InfrontSDK.SymbolField.High,
    InfrontSDK.SymbolField.Low,
    InfrontSDK.SymbolField.VWAP,
    InfrontSDK.BasicField.PriceDecimals,
  ])

  const priceDecimals = observableInstrument?.getFieldValue(InfrontSDK.BasicField.PriceDecimals)

  const highValueAnimation = useAnimation()

  const high = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.High)
  const highFormatted = formatNumber(high, { decimals: { min: 2, max: priceDecimals } })
  const prevHigh = usePrevious(highFormatted)

  const lowValueAnimation = useAnimation()

  const low = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.Low)
  const lowFormatted = formatNumber(low, { decimals: { min: 2, max: priceDecimals } })
  const prevlow = usePrevious(lowFormatted)

  const vwapValueAnimation = useAnimation()

  const vwap = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.VWAP)
  const vwapFormatted = formatNumber(vwap, { decimals: { min: 2, max: priceDecimals } })
  const prevVwap = usePrevious(vwapFormatted)

  useEffect(() => {
    if (highFormatted !== prevHigh) {
      fadeOutIn(highValueAnimation)
    }
    if (lowFormatted !== prevlow) {
      fadeOutIn(lowValueAnimation)
    }
    if (vwapFormatted !== prevVwap) {
      fadeOutIn(vwapValueAnimation)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highFormatted, lowFormatted, vwapFormatted])

  const trades = useInfrontIntradayTrades({
    instrument,
    customOptions: {
      columns: [
        { name: 'TIME', heading: t('Tid') },
        {
          name: 'LAST',
          heading: t('Kurs'),
          onClick: (item) => {
            if (onPriceClickRef.current) onPriceClickRef.current(item.last)
          },
        },
        { name: 'VOLUME', heading: t('Antal') },
        { name: 'BUYER', heading: t('Köpare') },
        { name: 'SELLER', heading: t('Säljare') },
      ],
    },
  })

  return (
    <Segment title={t('Senaste avslut')} variant="contained" headingVariant="overline" noContentPadding>
      <FlexRow p={16} alignItems="center" width="full" spaceX={4}>
        <FlexCol flexGrow={1}>
          <Text variant="label1">{t('Högst')}</Text>
          <Text variant="subtitle1">
            <Value onClick={() => onPriceClick(high)}>{highFormatted}</Value>
          </Text>
        </FlexCol>
        <FlexCol flexGrow={1}>
          <Text variant="label1">{t('Lägst')}</Text>
          <Text variant="subtitle1">
            <Value onClick={() => onPriceClick(low)}>{lowFormatted}</Value>
          </Text>
        </FlexCol>
        <FlexCol css={{ flexGrow: 2, maxWidth: isSmallScreen ? '33%' : 'none' }}>
          <Text variant="label1">{t('Volymvägt snittpris (VWAP)')}</Text>
          <Text variant="subtitle1">
            <Value onClick={() => onPriceClick(vwap)}>{vwapFormatted}</Value>
          </Text>
        </FlexCol>
      </FlexRow>
      <Wrapper clickable={!!onPriceClick} id={trades} />
    </Segment>
  )
})
OrderLatestTrades.displayName = 'OrderLatestTrades'

const Value = (props: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div css={{ cursor: 'pointer' }} {...props}>
      {props.children}
    </div>
  )
}
Value.displayName = 'Value'

const Wrapper = ({ clickable, children, ...rest }: { clickable: boolean } & React.HTMLAttributes<HTMLDivElement>) => {
  const theme = useDuploTheme()

  return (
    <div
      css={css`
        .cell-table__num.cell-table__num--last.cell-tablecell--interaction {
          ${clickable ? undefined : 'cursor: text;'}
        }

        .cell-w-single-value--value {
          font-family: Roboto;
          font-size: 15px;
          line-height: 20px;
          font-weight: 500;
        }
        .cell-h4 {
          display: none !important;
        }

        .cell-table tr:not(:last-of-type) td {
          border: none;
        }

        td:first-of-type {
          padding-left: 16px;
        }
        td:last-child {
          padding-right: 16px;
        }

        th {
          font-family: Roboto;
          font-size: 13px;
          line-height: 16px;
          font-weight: 400;

          text-transform: none;
          line-height: 16px !important;

          &:first-of-type {
            padding-left: 16px;
          }
          &:last-child {
            padding-right: 16px;
          }
        }
        table > thead > tr > th.cell-table__num.cell-table__num--volume,
        table > thead > tr > th.cell-table__num.cell-table__num--last {
          text-align: right !important;
        }
        .cell-table__num,
        .cell-table__txt {
          font-family: Roboto;
          font-size: 13px;
          line-height: 24px;
          font-weight: 400;

          height: 40px;
          text-align: left !important;
          padding: 8px 4px;
        }
        tbody {
          tr {
            &:nth-of-type(odd) {
              background-color: ${theme.colors['regent-st-100']};
            }
            &:hover {
              background-color: ${theme.colors['primary-hover']};
            }
          }
          tr > td.cell-table__num.cell-table__num--last.cell-tablecell--interaction,
          tr > td.cell-table__num.cell-table__num--volume {
            text-align: right !important;
          }
        }
      `}
      {...rest}
    >
      {children}
    </div>
  )
}
Wrapper.displayName = 'Wrapper'
