import { useMemo } from 'react'

import { Box, Html, Link, LinkExternal, ListItemRow, ListItemWithAccordion, Text, useDuploTheme } from '@carnegie/duplo'

import { removeHtmlTags } from '../utils/removeHtmlTags'
import { HtmlWithCustomComponents } from './HtmlWithCustomComponentsProps'

type QuestionsAndAnswersProps = {
  className?: string
  htmlFromMarkdownFile: string
}

type QuestionAndAnswers = {
  id: string
  title: string
  content: string
}

// Parse markdown->html to QuestionAndAnswers[]
function getQuestionsAndAnswersFromHtml(html: string): QuestionAndAnswers[] {
  // We split each QA by <hr> tag which is created by doing "---" in markdown
  const questionAndAnswers: QuestionAndAnswers[] = []

  const qasHtml = html.split('<hr>').map((q) => q.trim())

  for (const qaHtml of qasHtml) {
    let title = removeHtmlTags(qaHtml.substring(0, qaHtml.indexOf('\n')))

    let id = ''

    title = title.replace(/\[[a-z_0-9]+]/, (replaceValue) => {
      id = replaceValue.replaceAll('[', '').replaceAll(']', '').trim()
      return ''
    })

    const rest = qaHtml.substring(qaHtml.indexOf('\n') + 1)

    if (title) {
      questionAndAnswers.push({ title: title, id: id, content: rest?.trim() })
    }
  }

  return questionAndAnswers
}

// Parses a markdown file to show question and answers
export const QuestionsAndAnswersList = ({ className, htmlFromMarkdownFile }: QuestionsAndAnswersProps) => {
  const qas = useMemo(() => getQuestionsAndAnswersFromHtml(htmlFromMarkdownFile), [htmlFromMarkdownFile])
  const theme = useDuploTheme()

  return (
    <Box>
      {qas.map((qa, index) => (
        <ListItemWithAccordion
          className={className}
          key={index}
          divider
          expandableContent={
            <Box
              p={16}
              className="duplo-text-body2"
              css={{
                p: { paddingBottom: 8 },
                ul: { paddingLeft: 32 },
                li: { listStyleType: 'disc' },
                'table, th, td': {
                  textAlign: 'left',
                  paddingLeft: 8,
                  paddingRight: 8,
                  border: '1px solid ' + theme.colors['border-light'],
                },
                th: {
                  fontWeight: 500,
                  color: theme.colors['bunker-200'],
                },
              }}
            >
              <HtmlWithCustomComponents
                replaceWithComponent={(node) => {
                  if (node.tagName.toLowerCase() === 'a') {
                    if (node.attribs?.href?.startsWith('http')) {
                      return (
                        <LinkExternal variant="body2" href={node.attribs.href}>
                          {node.text}
                        </LinkExternal>
                      )
                    }
                    return (
                      <Link variant="body2" to={node.attribs.href}>
                        {node.text}
                      </Link>
                    )
                  }
                }}
                html={qa.content}
              />
            </Box>
          }
        >
          <ListItemRow
            title={
              <Text textAlign="left" display="block" variant="subtitle2" css={{ whiteSpace: 'break-spaces' }}>
                <Html html={qa.title} />
              </Text>
            }
          />
        </ListItemWithAccordion>
      ))}
    </Box>
  )
}
