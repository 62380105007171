import { InfrontInstrument } from '@common/api/response'

import { useInfrontWidget } from './useInfrontWidget'

export function useInfrontOrderbook({
  infrontInstrument,
  customOptions,
  onPriceClick,
}: {
  infrontInstrument: InfrontInstrument
  customOptions?: Partial<Infront.OrderbookWidgetOptions>
  onPriceClick: (price: number) => void
}) {
  const { containerId, widget, widgetLoaded } = useInfrontWidget((infrontUi, containerId) => {
    let opts = new Infront.OrderbookWidgetOptions()
    opts.instrument = new Infront.Instrument(infrontInstrument?.feed, infrontInstrument?.ticker)
    opts.levels = 5
    opts.layout = Infront.OrderbookRowLayout.COMPACT

    if (onPriceClick) {
      opts.onPriceClick = (price) => {
        onPriceClick(price)
      }
    }
    opts = { ...opts, ...customOptions }
    return infrontUi.orderbookWidget(containerId, opts)
  })
  return { containerId, widget, widgetLoaded }
}
