import { useEffect } from 'react'

import { makeObservable, observable, runInAction } from 'mobx'
import { useLocalObservable } from 'mobx-react-lite'

import { logInfrontError } from '../../../utils/infrontAppInsights'
import { useInfrontSDK } from './useInfrontSdk'

export class InfrontFeedMetadataStore {
  feedMetadata: Infront.FeedMetaData[] = []
  private sdkUnsubscribe: InfrontSDK.Unsubscribe = undefined

  init = (sdk: InfrontSDK.SDK, feeds: number[], onData?: (data: Infront.FeedMetaData[]) => void) => {
    this.dispose()
    this.feedMetadata = []

    if (!sdk) throw new Error('Infront SDK has not been initialized yet!')

    this.sdkUnsubscribe = sdk.get(
      InfrontSDK.feedInfo({
        infoType: InfrontSDK.FeedInfoType.MetaData,
        feed: feeds,
        onData: (data: Infront.FeedMetaData[]) => {
          runInAction(() => {
            this.feedMetadata = data

            onData?.(this.feedMetadata)
          })
        },
        onError: (error) => {
          logInfrontError(error)
          console.error('Error: useInfrontFeedInfo()', error, 'feeds:', feeds)
        },
      })
    )
  }

  dispose = () => {
    if (this.sdkUnsubscribe) this.sdkUnsubscribe()
  }

  constructor() {
    makeObservable(this, {
      feedMetadata: observable,
    })
  }
}

/**
 * ## ⚠️IMPORTANT⚠️
 * ### Requires wrapping component in MobX `observer(...)`
 */
export function useInfrontFeedMetadata(feeds: number[]): Infront.FeedMetaData[] {
  const { infrontSDK } = useInfrontSDK()
  const infrontFeedMetadataStore = useLocalObservable(() => new InfrontFeedMetadataStore())

  useEffect(() => {
    if (infrontSDK && feeds) {
      infrontFeedMetadataStore.init(infrontSDK, feeds)
    }

    return () => infrontFeedMetadataStore.dispose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infrontSDK, JSON.stringify(feeds)])

  return infrontFeedMetadataStore.feedMetadata
}
