import { useState } from 'react'

import { Box, IconButton, IconMore, Menu, MenuItem, MenuItems, MenuToggle } from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import { InstrumentIdContainer } from '../instrumentIdContainer'
import { useWatchlistsStore } from '../stores/store'
import { AddWatchlistItemMenuItem, AmendWatchlistItemMenuItem } from './ActionButton'

type PopperPlacementType =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top'

type MoreMenuProps = {
  id?: string
  size?: 'medium' | 'small'
  variant?: 'uncontained' | 'contained'
  children?: React.ReactElement | React.ReactElement[]
  onClose?: () => void
  popperPlacementType?: PopperPlacementType
}

export const MoreMenu = observer(
  ({
    id,
    variant = 'uncontained',
    children,
    size = 'small',
    onClose,
    popperPlacementType = 'bottom-end',
  }: MoreMenuProps) => {
    return (
      <Menu
        id={id}
        onClose={onClose}
        popperPlacementType={popperPlacementType}
        muiPopperStyle={{
          zIndex: 10,
        }}
        muiPaperStyle={{
          maxHeight: 400,
          overflowY: 'auto',
        }}
      >
        <MenuToggle>
          <IconButton size={size} variant={variant} icon={IconMore} />
        </MenuToggle>
        <MenuItems>{children}</MenuItems>
      </Menu>
    )
  }
)

type MoreMenuWatchlistProps = {
  size?: 'medium' | 'small'
  instrumentIdContainer?: InstrumentIdContainer
  children?: React.ReactElement | React.ReactElement[]
  popperPlacementType?: PopperPlacementType
}

export const MoreMenuWatchlist = observer(
  ({ size, instrumentIdContainer, children, popperPlacementType = 'bottom-end' }: MoreMenuWatchlistProps) => {
    return (
      <MoreMenu size={size} popperPlacementType={popperPlacementType}>
        <WatchlistMenuItems instrumentIdContainer={instrumentIdContainer}>{children}</WatchlistMenuItems>
      </MoreMenu>
    )
  }
)

type MoreMenuWatchlistItemsProps = {
  instrumentIdContainer: InstrumentIdContainer
  children?: React.ReactElement | React.ReactElement[]
}

export const WatchlistMenuItems = observer(({ instrumentIdContainer, children }: MoreMenuWatchlistItemsProps) => {
  const watchlistsStore = useWatchlistsStore()
  const [openSubMenu, setOpenSubMenu] = useState(false)

  return (
    <>
      {openSubMenu ? (
        <Box>
          {watchlistsStore?.watchlists.map((watchlist) => {
            return (
              <MenuItem
                checked={watchlistsStore.isChecked(watchlist, instrumentIdContainer)}
                key={watchlist?.id}
                onClick={async (event: React.MouseEvent) => {
                  event.preventDefault()
                  if (watchlistsStore.isChecked(watchlist, instrumentIdContainer)) {
                    watchlistsStore.deleteWatchlistItem(watchlist?.id, instrumentIdContainer)
                  } else {
                    watchlistsStore.addWatchlistItem(watchlist?.id, instrumentIdContainer)
                  }
                }}
              >
                {watchlist?.name}
              </MenuItem>
            )
          })}
        </Box>
      ) : (
        <Box>
          {children}
          {watchlistsStore.isWatching(instrumentIdContainer) ? (
            <AmendWatchlistItemMenuItem
              onClick={(event: React.MouseEvent) => {
                event.preventDefault()
                setOpenSubMenu(true)
              }}
            />
          ) : (
            <AddWatchlistItemMenuItem
              onClick={(event: React.MouseEvent) => {
                event.preventDefault()
                setOpenSubMenu(true)
              }}
            />
          )}
        </Box>
      )}
    </>
  )
})
