import { useDrawerProps } from '@common/components/drawer/useDrawerStore'
import { useSelectedAccountId } from '@common/hooks/useSelectedAccountId'

import { useNavigate } from 'react-router-dom'

import { TransferDrawerTabs } from './TransfersDrawer'

type TransferDrawerOptions = {
  defaultAccount?: string
  defaultTab?: TransferDrawerTabs
}

export function useOpenTransfersDrawer() {
  const navigate = useNavigate()
  const selectedAccountId = useSelectedAccountId()
  const openTransfersDrawer = (options?: TransferDrawerOptions) => {
    const showAccount = options?.defaultAccount || selectedAccountId
    !options
      ? navigate('/overview/accounts/d/transfers')
      : navigate(`/accounts/${showAccount ? `${showAccount}/` : ''}d/transfers/${options?.defaultTab || ''}`)
  }

  return openTransfersDrawer
}

export function useTransfersDrawerProps() {
  const drawerProps = useDrawerProps<TransferDrawerOptions>()

  return {
    ...drawerProps.props,
  }
}
