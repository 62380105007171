import { AccountsResponse } from '@common/api/response'
import { SkeletonLoader } from '@common/components/SkeletonLoader'
import { SmartTable } from '@common/components/SmartTable'
import { useInfrontTradingStatus } from '@common/hooks/infront/useInfrontTradingConnectedAndPortfolioReady'

import { useTranslation } from 'react-i18next'

import { getInfrontTradingOrigin } from '@carnegie/digital-channels-frontend'
import { Box, Breakpoint, useBreakpoint } from '@carnegie/duplo'

import i18n from '@/i18n'

import { observer } from 'mobx-react-lite'

import { CurrentOrderRow } from './CurrentOrderRow'
import { CurrentOrderRowMobile } from './CurrentOrderRowMobile'
import { CurrentOrdersTrashCanButton } from './CurrentOrdersTrashCanButton'
import { Edit } from './Edit'
import { useCurrentOrders } from './useCurrentOrders'

type CurrentOrdersProps = {
  selectedAccount: AccountsResponse
  showHistoricalOrders?: boolean
}

export const CurrentOrders = observer(function CurrentOrders({
  selectedAccount,
  showHistoricalOrders,
}: CurrentOrdersProps) {
  const { t } = useTranslation()
  const breakpoint = useBreakpoint()
  const isSmallScreen = breakpoint < Breakpoint.Medium

  const { tradingConnectedAndPortfolioReady, tradingConnectionError } = useInfrontTradingStatus()

  const { orders, deleteOrder, loading } = useCurrentOrders(selectedAccount?.number, {
    listType: showHistoricalOrders ? 'historical-orders' : 'current-orders-no-funds',
  })

  const hasOrders = orders?.length > 0

  return (
    <SkeletonLoader
      // Because we debounce the no data loaded we need to "restart the clock" by resetting the component as the account changes
      key={selectedAccount?.number}
      height={128}
      p={16}
      error={tradingConnectionError}
      // Only show the loading skeleton when we also have no orders since loading can be true while orders are coming in
      loading={(loading && !hasOrders) || !tradingConnectedAndPortfolioReady}
      noDataLoaded={!loading && !hasOrders}
      noDataComponent={
        <Box>
          {showHistoricalOrders
            ? t('Du har inga historiska ordrar för de senaste 30 dagarna för valt konto.')
            : t(
                'Det finns inga aktuella ordrar för valt konto. Hitta inspiration för handel genom att besöka våra marknadssidor.'
              )}
        </Box>
      }
    >
      {() => (
        <SmartTable
          pageSize={50}
          rowKey={(row) => row?.orderId}
          data={orders ?? []}
          defaultSortBy="changed"
          renderMode={isSmallScreen ? 'custom' : 'table'}
          renderRow={
            isSmallScreen
              ? ({ row: order }) => {
                  // Mobile table
                  return <CurrentOrderRowMobile key={order.orderId} order={order} deleteOrder={deleteOrder} />
                }
              : undefined
          }
          columns={[
            {
              id: 'fullname',
              width: 'minmax(0,4fr)',
              align: 'left',
              sortBy: ({ row }) => row.fullName,
              renderHeader: () => t('Värdepapper'),
              renderCell: ({ row: order }) => <CurrentOrderRow order={order} cell="fullName" />,
            },
            {
              id: 'orderStatus',
              width: 'minmax(auto,1.5fr)',
              align: 'left',
              sortBy: ({ row }) => transformInfrontOrderStatus(row.orderStatus),
              renderHeader: () => t('Status'),
              renderCell: ({ row: order }) => <CurrentOrderRow order={order} cell="orderStatus" />,
            },
            {
              id: 'portfolio',
              width: 'minmax(auto,1fr)',
              align: 'left',
              renderHeader: () => t('Konto'),
              renderCell: ({ row: order }) => <CurrentOrderRow order={order} cell="portfolio" />,
            },
            {
              id: 'buyOrSell',
              width: 'minmax(auto,1fr)',
              align: 'left',
              sortBy: ({ row }) => row.buyOrSell,
              renderHeader: () => t('Typ'),
              renderCell: ({ row: order }) => <CurrentOrderRow order={order} cell="buyOrSell" />,
            },
            {
              id: 'source',
              width: 'minmax(auto,1fr)',
              align: 'left',
              sortBy: ({ row }) => {
                const orderAppId = row.orderAppId
                const orderAppNumber = row.orderAppNumber
                return getInfrontTradingOrigin(orderAppId, orderAppNumber)
              },
              renderHeader: () => t('Källa'),
              renderCell: ({ row: order }) => <CurrentOrderRow order={order} cell="source" />,
            },
            {
              id: 'pctFill',
              width: 'minmax(auto,1fr)',
              align: 'right',
              sortBy: ({ row }) => row.volume,
              renderHeader: () => t('Avslut/Antal'),
              renderCell: ({ row: order }) => <CurrentOrderRow order={order} cell="pctFill" />,
            },
            {
              id: 'limit',
              width: 'minmax(auto,1fr)',
              align: 'right',
              sortBy: ({ row }) => row.orderPrice,
              renderHeader: () => t('Kurslimit'),
              renderCell: ({ row: order }) => <CurrentOrderRow order={order} cell="limit" />,
            },
            {
              id: 'amount',
              visible: !!showHistoricalOrders,
              width: 'minmax(auto,1fr)',
              align: 'right',
              sortBy: ({ row }) => row.monetaryAmount,
              renderHeader: () => t('Belopp'),
              renderCell: ({ row: order }) => <CurrentOrderRow order={order} cell="monetaryAmount" />,
            },
            {
              id: 'changed',
              width: 'minmax(auto,1fr)',
              align: 'right',
              sortBy: ({ row: order }) => {
                return order.changeDateTime
              },
              renderHeader: () => t('Tid'),
              renderCell: ({ row: order }) => <CurrentOrderRow order={order} cell="changed" />,
              defaultSortOrder: 'desc',
            },
            {
              id: 'sValidUntil',
              width: '1fr',
              align: 'right',
              sortBy: ({ row }) => {
                return row.validDate
              },
              renderHeader: () => t('Giltig t.o.m.'),
              renderCell: ({ row: order }) => <CurrentOrderRow order={order} cell="sValidUntil" />,
              defaultSortOrder: 'desc',
            },
            {
              id: 'modify',
              width: 32,
              align: 'left',
              renderCell: ({ row: order }) => {
                return <Edit order={order} />
              },
            },
            {
              id: 'delete',
              width: 40,
              align: 'right',
              renderCell: ({ row: order }) => {
                return <CurrentOrdersTrashCanButton order={order} deleteOrder={deleteOrder} />
              },
            },
          ]}
        />
      )}
    </SkeletonLoader>
  )
})

CurrentOrders.displayName = 'CurrentOrders'

export function transformInfrontOrderStatus(status: string): string {
  const orderStatusToSwedish = {
    Deleted: 'Borttagen',
    DeletedExecuted: 'Borttagen',
    DeletedRefused: 'Borttagen',
    Done: 'Klar',
    Active: 'Aktiv',
    Executed: 'Exekverad',
    Expired: 'Utgången',
    Inactive: 'Inaktiv',
    Rejected: 'Avvisad',
    Ongoing: 'Pågående',
    Pending: 'Väntar',
    PendingInsert: 'Väntar',
    PendingModify: 'Väntar på ändring',
    PendingCancel: 'Väntar på borttag',
    PendingDelete: 'Väntar',
    Request: 'Mottagen',
  }
  const orderStatusToEnglish = {
    Deleted: 'Deleted',
    DeletedExecuted: 'Deleted',
    DeletedRefused: 'Deleted',
    Done: 'Done',
    Active: 'Active',
    Executed: 'Executed',
    Expired: 'Expired',
    Inactive: 'Inactive',
    Rejected: 'Rejected',
    Ongoing: 'Ongoing',
    Pending: 'Pending',
    PendingInsert: 'Pending',
    PendingModify: 'Pending modify',
    PendingCancel: 'Pending cancel',
    PendingDelete: 'Pending',
    Request: 'Received',
  }

  if (status === 'Offline') status = 'Pending'
  return i18n.language === 'en' ? orderStatusToEnglish[status] ?? status : orderStatusToSwedish[status] ?? status
}

export const transformCarnegieOrderStatus = (orderState: string, orderSubState: string) => {
  switch (orderState) {
    case 'Active':
      if (orderSubState === 'NotSet') {
        return i18n.language === 'en' ? 'Pending' : 'Väntar'
      }
      if (orderSubState === 'Registrated') {
        return i18n.language === 'en' ? 'Received' : 'Mottagen'
      }
      if (orderSubState === 'Aggregated' || orderSubState === 'WaitFX' || orderSubState === 'Confirmed') {
        return i18n.language === 'en' ? 'Active' : 'Aktiv'
      }
      break
    case 'Canceled':
      return i18n.language === 'en' ? 'Deleted' : 'Borttagen'
    case 'PendingCancel':
    case 'PendingCancelOrderService':
      return i18n.language === 'en' ? 'Pending cancel' : 'Väntar på borttag'
    case 'PendingModify':
    case 'PendingModifyOrderService':
      return i18n.language === 'en' ? 'Pending modify' : 'Väntar på ändring'
    case 'PendingCreate':
    case 'PendingCreateOrderService':
    case 'NotSet':
    case 'SentOrder':
      return i18n.language === 'en' ? 'Pending' : 'Väntar'
    case 'Traded':
      return i18n.language === 'en' ? 'Executed' : 'Exekverad'
    case 'Failed':
      return i18n.language === 'en' ? 'Rejected' : 'Avvisad'
    default:
      return orderState
  }
}

export const TranslateSimpleOrderSystem = ({ orderSystem }: { orderSystem: string }) => {
  const { t } = useTranslation()

  let translateOrderSystem

  switch (orderSystem) {
    case 'App':
      translateOrderSystem = 'App'
      break
    case 'Web':
      translateOrderSystem = 'Webb'
      break
    default:
      translateOrderSystem = 'Carnegie'
      break
  }
  return <>{t(translateOrderSystem)}</>
}
