import { BuySellMenuItem, PlaceFundOrderMenuItem, TransactionMenuItem } from '@common/components/ActionButton'
import { FormattedNumber, FormattedPercentage } from '@common/components/FormattedNumber'
import { MoreMenuWatchlist } from '@common/components/MoreMenu'

import { useTranslation } from 'react-i18next'

import { FlexCol, MobileTableRow, Text } from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import { useOpenInstrumentDrawer } from '../../../../drawers/instrumentDrawer/useOpenInstrumentDrawer'
import { InstrumentNameMobile } from '../InstrumentName'
import { ObservableTableInstrument } from '../useTableInstruments'

type Props = {
  instrument: ObservableTableInstrument
  accountCurrencyCode: string
  isFund: boolean
  showPercentage: boolean
  transactionOnClick?: () => void
}

export const TableOverviewMobile = observer(
  ({ instrument, accountCurrencyCode, showPercentage, isFund, transactionOnClick }: Props) => {
    const { t } = useTranslation()

    const { openInstrumentDrawer } = useOpenInstrumentDrawer()

    const {
      carnegieInstrumentGroup,
      isoCountryCode,
      marketIso,
      marketValue,
      name,
      priceChangeToday,
      priceChangeTodayRatio,
      unrealized,
      unrealizedRatio,
      priceChangeOneDay,
      priceChangeOneDayRatio,
    } = instrument

    const showFlag = carnegieInstrumentGroup !== 'MutualFunds'

    return (
      <MobileTableRow
        divider
        action={
          <MoreMenuWatchlist instrumentIdContainer={instrument} size="medium">
            {isFund ? (
              <>
                <PlaceFundOrderMenuItem instrumentIdContainer={instrument} fundOrderType={'buy'} />
                <PlaceFundOrderMenuItem instrumentIdContainer={instrument} fundOrderType={'sell'} />
              </>
            ) : (
              <BuySellMenuItem instrumentIdContainer={instrument} />
            )}
            <TransactionMenuItem onClick={transactionOnClick} />
          </MoreMenuWatchlist>
        }
        title={
          <InstrumentNameMobile
            isoCountryCode={isoCountryCode ? isoCountryCode : marketIso}
            name={name}
            reservedQuantities={instrument.reservedQuantities}
            showFlag={showFlag}
          />
        }
        onClick={() => openInstrumentDrawer(instrument)}
      >
        <FlexCol alignItems="flex-start" flex={1}>
          <Text variant="label1" truncate>
            {isFund ? t('1 dag') : t('Idag')}
          </Text>
          {showPercentage ? (
            <FormattedPercentage
              colorized
              decimals={2}
              variant="subtitle2"
              value={isFund ? priceChangeOneDayRatio : priceChangeTodayRatio}
              ratio
            />
          ) : (
            <FormattedNumber
              variant="subtitle2"
              decimals={0}
              value={isFund ? priceChangeOneDay : priceChangeToday}
              unit={accountCurrencyCode}
              colorized
            />
          )}
        </FlexCol>
        <FlexCol alignItems="flex-start" flex={1}>
          <Text variant="label1" truncate>
            {t('Marknadsvärde')}
          </Text>
          <FormattedNumber variant="subtitle2" decimals={0} unit="SEK" value={marketValue} />
        </FlexCol>
        <FlexCol alignItems="flex-end" flex={1}>
          <Text variant="label1" truncate>
            {t('Orealiserat')}
          </Text>
          {showPercentage ? (
            <FormattedPercentage colorized decimals={2} value={unrealizedRatio} ratio variant={'subtitle2'} />
          ) : (
            <FormattedNumber variant="subtitle2" decimals={0} unit="SEK" value={unrealized} colorized />
          )}
        </FlexCol>
      </MobileTableRow>
    )
  }
)
