import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { scrollToTop } from '../utils/document'

export const ScrollToTop = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    //     scrollToTop()
  }, [pathname])

  return null
}

export function useScrollToTop(enable: boolean = true) {
  useEffect(() => {
    if (enable) scrollToTop()
  }, [enable])
}
