import { useApi } from '@common/stores/store'

import useSWR from 'swr'

export function useAnalysisSmallCap() {
  const api = useApi()

  const { data, error } = useSWR(
    `/content/analysis/corepicks/`,
    () => api.fetchAnalysisCorePicks(),
    // No need to get data too often in this case
    { dedupingInterval: 30000 }
  )

  return {
    error,
    smallCap: data?.item?.corePicks,
  }
}
