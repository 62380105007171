import { useTranslation } from 'react-i18next'

import { Box, Link, ListItemRow, ListItemWithAccordion, Paragraph, Segment, Spacer, Text } from '@carnegie/duplo'

const WHITE_SPACE_BREAK_SPACES = 'break-spaces'

const TransferFAQDeposit = () => {
  const { t } = useTranslation()

  return (
    <Segment variant="contained" headingVariant="overline" title={t('Frågor och svar')} noContentPadding>
      <ListItemWithAccordion
        divider
        expandableContent={
          <Box p={16}>
            <Paragraph variant="body2">
              {t(
                'Vanligtvis ska en insättning via internetbank som görs en helgfri bankdag före kl. 9.45 med OCR-referens synas på ditt konto under dagen. Stopptiden för att nå fram samma dag varierar dock mellan bankerna, se därför aktuell information hos din bank.'
              )}
            </Paragraph>
          </Box>
        }
      >
        <ListItemRow
          title={
            <Text textAlign="left" display="block" variant="subtitle2" css={{ whiteSpace: WHITE_SPACE_BREAK_SPACES }}>
              {t('Hur lång tid tar en insättning från annan bank?')}
            </Text>
          }
        />
      </ListItemWithAccordion>
      <ListItemWithAccordion
        divider
        expandableContent={
          <Box p={16}>
            <Paragraph variant="body2">
              {t('Observera att inbetalning till bankgiro endast kan göras i SEK.')}
            </Paragraph>
            <Spacer height={16} />
            <Paragraph variant="body2">
              {t('Vid insättning i annan valuta ska överföring göras till Carnegies bankkonto.')}
              &nbsp;
              <Link variant="subtitle2" to="/profile/contact">
                {t('Kontakta oss')}
              </Link>
              &nbsp;
              {t(
                'för information om vilket bankkonto som är aktuellt (beroende på valuta). Ange ditt kontonummer som referens vid insättning.'
              )}
            </Paragraph>
          </Box>
        }
      >
        <ListItemRow
          title={
            <Text textAlign="left" display="block" variant="subtitle2" css={{ whiteSpace: WHITE_SPACE_BREAK_SPACES }}>
              {t('Vilka valutor kan jag göra insättning i?')}
            </Text>
          }
        />
      </ListItemWithAccordion>
      <ListItemWithAccordion
        divider
        expandableContent={
          <Box p={16}>
            <Paragraph variant="body2">{t('Om du vill göra en insättning i SEK använd följande konto:')}</Paragraph>
            <Spacer height={16} />
            <Paragraph variant="body2">{t('IBAN: SE18 5000 0000 0522 1101 3139')} </Paragraph>
            <Paragraph variant="body2"> {t('Beneficiary Bank: SEB')} </Paragraph>
            <Paragraph variant="body2">{t('SWIFT/BIC: ESSESESS')} </Paragraph>
            <Paragraph variant="body2">{t('Ange ditt kontonummer som referens.')} </Paragraph>
            <Spacer height={16} />
            <Paragraph variant="body2">
              {t(
                "Om du vill göra insättningar från utlandet i andra valutor, se 'Vilka valutor kan jag göra insättning i'."
              )}
            </Paragraph>
          </Box>
        }
      >
        <ListItemRow
          title={
            <Text textAlign="left" display="block" variant="subtitle2" css={{ whiteSpace: WHITE_SPACE_BREAK_SPACES }}>
              {t('Hur gör jag en insättning från utlandet?')}
            </Text>
          }
        />
      </ListItemWithAccordion>
      <ListItemWithAccordion
        divider
        expandableContent={
          <Box p={16}>
            <Paragraph variant="body2">
              {t(
                "I listan visas enbart de konton du kan göra insättning till via bankgiro med OCR-referens. För insättning till försäkringskonton, se 'Hur gör jag en insättning till försäkringskonton?'."
              )}
            </Paragraph>
          </Box>
        }
      >
        <ListItemRow
          title={
            <Text textAlign="left" display="block" variant="subtitle2" css={{ whiteSpace: WHITE_SPACE_BREAK_SPACES }}>
              {t('Varför syns inte alla mina konton i listan?')}
            </Text>
          }
        />
      </ListItemWithAccordion>
      <ListItemWithAccordion
        divider
        expandableContent={
          <Box p={16}>
            <Paragraph variant="body2">
              {t(
                'Observera att insättning endast kan göras till kapitalförsäkringar. Du kan se vilken typ av försäkring du har under information på respektive konto. Om du ändå är osäker på vilken typ av försäkring du har,'
              )}
              &nbsp;
              <Link variant="subtitle2" to="/profile/contact">
                {t('kontakta oss')}
              </Link>
              &nbsp;
              {t('innan insättning görs.')}
            </Paragraph>
            <Spacer height={16} />
            <Text
              textAlign="left"
              display="block"
              variant="overline"
              css={{
                whiteSpace: WHITE_SPACE_BREAK_SPACES,
              }}
            >
              {t('Kapitalförsäkring hos Movestic och Futur')}
            </Text>
            <Spacer height={8} />
            <Paragraph variant="body2">
              {t(
                'För insättning till kapitalförsäkring hos Movestic och Futur, använd något av Carnegies konton nedan och ange ditt kontonummer hos oss som referens.'
              )}
            </Paragraph>
            <Spacer height={16} />
            <Paragraph variant="body2">{t('SEB: 5221-10 131 39')}</Paragraph>
            <Paragraph variant="body2">{t('Svenska Handelsbanken: 6111-151 992 312')}</Paragraph>
            <Paragraph variant="body2">{t('Nordea: 1702 17 081 65')}</Paragraph>
            <Paragraph variant="body2">{t('Bankgiro: 468-7612')}</Paragraph>
            <Paragraph variant="body2">{t('Plusgiro: 6 04 30-6')}</Paragraph>
            <Spacer height={16} />
            <Text variant="overline">{t('KAPITALFÖRSÄKRING HOS SEB LIFE')}</Text>
            <Spacer height={8} />
            <Paragraph variant="body2">
              {t(
                'För insättning till kapitalförsäkring hos SEB Life, använd SEB Lifes kontonummer 5815-10 032 35 och ange ditt kontonummer eller försäkringsnummer som referens.'
              )}
            </Paragraph>
            <Spacer height={16} />
            <Text variant="overline">{t('ÖVRIGA FÖRSÄKRINGSBOLAG')}</Text>
            <Spacer height={8} />
            <Paragraph variant="body2">
              {t('För insättning till kapitalförsäkring hos annat försäkringsbolag,')}
              &nbsp;
              <Link variant="subtitle2" to="/profile/contact">
                {t('kontakta oss.')}
              </Link>
              &nbsp;
            </Paragraph>
          </Box>
        }
      >
        <ListItemRow
          title={
            <Text textAlign="left" display="block" variant="subtitle2" css={{ whiteSpace: WHITE_SPACE_BREAK_SPACES }}>
              {t('Hur gör jag en insättning till försäkringskonton?')}
            </Text>
          }
        />
      </ListItemWithAccordion>
    </Segment>
  )
}

export default TransferFAQDeposit
