import { CancelSimpleOrderRequest, SimpleOrder } from '@common/api/response'
import { FormattedNumber } from '@common/components/FormattedNumber'
import { useCarnegieInstrument } from '@common/hooks/useCarnegieInstrument'
import { convertToInstrumentIdContainer } from '@common/instrumentIdContainer'
import { useNavigation } from '@common/stores/store'

import { useTranslation } from 'react-i18next'

import { formatNumber, useFormatDate } from '@carnegie/digital-channels-frontend'
import { FlexRow, LinkButton, ListItem, ListItemRow, Text } from '@carnegie/duplo'

import { useOpenInstrumentDrawer } from '@/drawers/instrumentDrawer/useOpenInstrumentDrawer'
import i18n from '@/i18n'
import { InstrumentName } from '@/pages/accounts/holdingsTable/InstrumentName'

import { TranslateSimpleOrderSystem, transformCarnegieOrderStatus } from '../CurrentOrders/CurrentOrders'
import { SimpleOrderTrashCanButton } from './SimpleOrderTrashCanButton'

type SimpleOrderRowProps = {
  simpleOrder: SimpleOrder
  cell:
    | 'instrumentId'
    | 'accountId'
    | 'monetaryAmount'
    | 'orderDate'
    | 'orderState'
    | 'orderSystem'
    | 'quantity'
    | 'side'
    | 'tradeCurrency'
    | ''
}

export const SimpleOrderRow = ({ simpleOrder, cell }: SimpleOrderRowProps) => {
  const { t } = useTranslation()
  const { openInstrumentDrawer } = useOpenInstrumentDrawer()
  const navigation = useNavigation()
  const { formatDate } = useFormatDate()
  const {
    accountNumber,
    accountId,
    instrumentId,
    instrumentName,
    monetaryAmount,
    orderDate,
    quantity,
    orderState,
    orderSystem,
    side,
    orderSubState,
    settlementCurrency,
  } = simpleOrder

  const instrumentIdContainer = convertToInstrumentIdContainer(instrumentId)

  // Will be undefined while loading
  const { carnegieInstrument } = useCarnegieInstrument(instrumentIdContainer, i18n.language)

  switch (cell) {
    case 'instrumentId':
      return (
        <InstrumentName
          showFlag={false}
          name={instrumentName}
          isoCountryCode={'SE'}
          showInfoButton={false}
          onClick={() => openInstrumentDrawer(convertToInstrumentIdContainer(carnegieInstrument?.infrontInstrument))}
        />
      )

    case 'side': {
      const translation = side === 'Buy' ? t('Köp') : t('Sälj')
      return <>{t(translation)}</>
    }

    case 'accountId':
      return (
        <LinkButton
          variant="body2"
          minWidth={0}
          onClick={() => {
            navigation.replace(`/accounts/${accountId}?returnroute=/overview/orders/${accountId}`)
          }}
        >
          {accountNumber}
        </LinkButton>
      )

    case 'orderState':
      return <>{transformCarnegieOrderStatus(orderState, orderSubState)}</>

    case 'orderSystem':
      return <TranslateSimpleOrderSystem orderSystem={orderSystem} />

    case 'quantity':
      return <>{formatNumber(quantity, { decimals: { min: 0, max: 6 }, showZeroAsFallbackValue: true })}</>

    case 'monetaryAmount':
      return <FormattedNumber unit={settlementCurrency} value={monetaryAmount} decimals={0} showZeroAsFallbackValue />

    case 'orderDate':
      return <>{formatDate(orderDate, 'HH:mm/yyyy-MM-dd')}</>
  }
}

type SimpleOrderRowMobileProps = {
  simpleOrder: SimpleOrder
  cancelOrder: (body: CancelSimpleOrderRequest) => void
}

export const SimpleOrderRowMobile = ({ simpleOrder, cancelOrder }: SimpleOrderRowMobileProps) => {
  const { t } = useTranslation()
  const { openInstrumentDrawer } = useOpenInstrumentDrawer()
  const {
    instrumentId,
    monetaryAmount,
    instrumentName,
    orderState,
    side,
    settlementCurrency,
    quantity,
    orderSubState,
  } = simpleOrder
  const instrumentIdContainer = convertToInstrumentIdContainer(instrumentId)
  const isBuyOrder = side === 'Buy'

  // Will be undefined while loading
  const { carnegieInstrument } = useCarnegieInstrument(instrumentIdContainer, i18n.language)
  const status = transformCarnegieOrderStatus(orderState, orderSubState)
  return (
    <ListItem
      divider
      onClick={() => {
        openInstrumentDrawer(convertToInstrumentIdContainer(carnegieInstrument?.infrontInstrument))
      }}
      action={
        simpleOrder?.canBeCancelledByClient && (
          <SimpleOrderTrashCanButton size="medium" simpleOrder={simpleOrder} cancelOrder={cancelOrder} />
        )
      }
    >
      <ListItemRow
        title={
          <FlexRow minWidth={0}>
            <Text mr={4} variant="subtitle1" color={isBuyOrder ? 'astral' : 'paprika-main'}>
              {t(isBuyOrder ? 'KÖP' : 'SÄLJ')}
            </Text>
            <Text truncate variant="subtitle1">
              {instrumentName}
            </Text>
          </FlexRow>
        }
        value={<Text variant="subtitle1">{status}</Text>}
      />
      <ListItemRow
        title={
          <Text variant="overline">
            {formatNumber(quantity, { decimals: { min: 0, max: 6 }, showZeroAsFallbackValue: true })}
          </Text>
        }
        value={
          <Text variant="overline">
            <FormattedNumber showZeroAsFallbackValue unit={settlementCurrency} value={monetaryAmount} decimals={0} />
          </Text>
        }
      />
    </ListItem>
  )
}
