import { DuploProps, FlexRow } from '@carnegie/duplo'

import { Star } from './Star'

type StarBarProps = {
  stars: number
} & DuploProps

export const StarBar = ({ stars, ...rest }: StarBarProps) => {
  return (
    <FlexRow {...rest}>
      <Star filled={stars >= 1} />
      <Star filled={stars >= 2} />
      <Star filled={stars >= 3} />
      <Star filled={stars >= 4} />
      <Star filled={stars >= 5} />
    </FlexRow>
  )
}
