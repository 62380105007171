import { action, makeObservable, observable } from 'mobx'

import { AccountDividend } from '../api/response'
import { arraySort } from '../utils/arraySort'
import { WebStorage } from './webStore'

export type SortByProperty = 'name' | 'amount'

export type SortBy<TProperty extends string> = { property: TProperty; desc: boolean }

export class AccountDividendsStore {
  accountDividends: AccountDividend[] = undefined
  sortedBy: SortBy<SortByProperty> = { property: 'name', desc: false }
  private storage: WebStorage
  private storageKey: string

  constructor(storage: WebStorage, storageKey: string) {
    makeObservable(this, {
      accountDividends: observable,
      sort: action,
    })

    this.storage = storage
    this.storageKey = storageKey ?? ''

    if (this.storageKey === '') {
      throw new Error(`Creating an AccountDividendsStore without a storage key will make the storage global.`)
    }
  }

  init = (dividends: AccountDividend[]) => {
    this.dispose()

    if (dividends) {
      for (const dividend of dividends) {
        this.addNewAccountDividend(dividend)
      }

      if (dividends.length === 0) this.accountDividends = []

      const defaultSort = this.storage.getItem<SortBy<SortByProperty>>(this.storageKey, 'user')
      if (defaultSort) {
        const { property, desc } = defaultSort
        this.sort(property, desc)
      }
    }

    this.initialized = true
  }

  sort = (property: SortByProperty, desc: boolean) => {
    // Sort
    this.accountDividends = arraySort(
      this.accountDividends.slice(),
      (field) => {
        switch (property) {
          case 'name':
            return field.instrumentName
          case 'amount':
            return field.original.amount
          default:
            return field.instrumentName
        }
      },
      desc
    )

    this.sortedBy = { desc: desc, property: property }
    this.storage.setItem(this.storageKey, this.sortedBy, 'user')
  }

  initialized = false

  dispose = () => {
    this.accountDividends = undefined
  }

  private addNewAccountDividend = (accountDividend: AccountDividend) => {
    if (!this.accountDividends) this.accountDividends = []

    this.accountDividends.push(accountDividend)
  }
}
