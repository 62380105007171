type InfrontNewsStoryOptions =
  | Omit<InfrontSDK.NewsStoryOptionsByHeadline, keyof InfrontSDK.DataRequestOptions>
  | Omit<InfrontSDK.NewsStoryOptionsByFeedAndID, keyof InfrontSDK.DataRequestOptions>

export function getInfrontNewsStory(
  sdk: InfrontSDK.SDK,
  options: InfrontNewsStoryOptions
): Promise<InfrontSDK.NewsStory> {
  let unsubscribe: InfrontSDK.Unsubscribe

  const promise = new Promise<InfrontSDK.NewsStory>((resolve, reject) => {
    unsubscribe = sdk.get(
      InfrontSDK.newsStory({
        feed: options?.feed,
        headline: options?.headline,
        id: options?.id,

        onData: (story: InfrontSDK.NewsStory) => {
          resolve(story)
        },
        onError: (error: InfrontSDK.ErrorBase) => {
          reject(error)
        },
      })
    )
  })

  promise.finally(() => unsubscribe && unsubscribe())

  return promise
}
