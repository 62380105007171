import { BoxProps, Flex } from '@carnegie/duplo'

import svgShape from './squircle.svg?url'

type SquircleProps = {
  size: number
} & BoxProps

export const Squircle = ({ children, size, className, backgroundColor, ...rest }: SquircleProps) => {
  return (
    <div
      css={{
        margin: 0,
        display: 'flex',
        minWidth: size,
        minHeight: size,
        width: size,
        height: size,
        maskImage: `url('${svgShape}')`,
        maskSize: size,
      }}
      className={className}
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        css={{ width: size, height: size }}
        backgroundColor={backgroundColor}
        {...rest}
      >
        {children}
      </Flex>
    </div>
  )
}
