import { Guid } from '@common/guid'
import { InstrumentIdContainer } from '@common/instrumentIdContainer'
import { base64encode } from '@common/utils/base64encode'

import useSwr from 'swr'

import { useApi } from '../stores/store'

export function useCarnegieInstrument(instrumentId?: Guid | InstrumentIdContainer, languageCode?: string) {
  const api = useApi()

  let apiId = ''

  if (typeof instrumentId === 'string') {
    apiId = instrumentId
  } else {
    // This is an instrument id container
    apiId = instrumentId?.instrumentIdCarnegie
      ? instrumentId?.instrumentIdCarnegie
      : // Getting a carnegie instrument by passing the base64 encoded id of an infront instrument is deprectated in backend but still works
        base64encode({
          feed: instrumentId?.infrontInstrument?.feed,
          ticker: instrumentId?.infrontInstrument?.ticker,
        })
  }

  const { data, error, isValidating } = useSwr(instrumentId ? `/instruments/${apiId}` : undefined, async () => {
    return await api.fetchCarnegieInstrument(apiId, languageCode)
  })

  return { carnegieInstrument: data, error, isLoading: data === undefined && !error, isValidating }
}
