export function allowWatchAndBuy(instrumentType: string) {
  return (
    instrumentType === 'funds' ||
    instrumentType === 'stocks' ||
    instrumentType === 'derivatives' ||
    instrumentType === 'etf' ||
    instrumentType === 'unknown'
  )
}

export function showAsIndexType(instrumentType: string) {
  return (
    instrumentType === 'index' ||
    instrumentType === 'currency' ||
    instrumentType === 'interest' ||
    instrumentType === 'commodity'
  )
}
