import { useTranslation } from 'react-i18next'

import { Box, Button, FlexRow, Icon, IconInfoOutlined, Paragraph, Popper, Text } from '@carnegie/duplo'

type ExchangeCurrencyInfoProps = { type: 'fund' | 'other' }

export const ExchangeCurrencyInfo = ({ type }: ExchangeCurrencyInfoProps) => {
  const { t } = useTranslation()

  return (
    <FlexRow justifyContent="center">
      <Box>
        <Popper
          id="fund-order-exchange-info"
          toggle={
            <Button variant={'tertiary'} size="small">
              <FlexRow alignItems="center">
                <Paragraph variant="body1" color="text-low-emphasis">
                  {t('Information om valutaväxling')}
                </Paragraph>
                <Icon color="icon-low-emphasis" height={16} icon={IconInfoOutlined} />
              </FlexRow>
            </Button>
          }
        >
          <Box p={16} css={{ width: 500, maxWidth: '90vw' }}>
            <Text>
              {type === 'fund'
                ? t(
                    'Beloppet kommer växlas innan ordern skickas till fondbolaget. För växlingen tillkommer en avgift på 0,30 % som dras från beloppet.'
                  )
                : t(
                    'Om valutakonto saknas kommer ordern växlas och bokas i SEK. För växlingen tillkommer en avgift på 0,30 %.'
                  )}
            </Text>
          </Box>
        </Popper>
      </Box>
    </FlexRow>
  )
}
