import { InfrontInstrument } from './api/response'
import { Guid } from './guid'

// An object that contains both an infront instrument id and carnegie id in the same format as in the CarnegieInstrument
export type InstrumentIdContainer = {
  infrontInstrument?: InfrontInstrument
  instrumentIdCarnegie?: Guid
}

/**
 * Utility function to make it a bit easier / less verbose to create an instrument container. Also handles objects where the feed and ticker might be optional.
 *
 * @param instrumentIdCarnegie
 * @param infrontInstrument
 * @returns
 */
export function createInstrumentIdContainer(instrumentIdCarnegie?: Guid, infrontInstrument?: InfrontInstrument) {
  // If the feed and ticker are 0 / "" we also want to set the infront instrument to undefined so we can do checks on the object more easily
  const isValidInfrontInstrument = infrontInstrument && infrontInstrument.feed && infrontInstrument.ticker

  const instrumentIdContainer: InstrumentIdContainer = {
    infrontInstrument: isValidInfrontInstrument
      ? { feed: infrontInstrument.feed, ticker: infrontInstrument.ticker }
      : undefined,
    instrumentIdCarnegie: instrumentIdCarnegie,
  }

  return instrumentIdContainer
}

/**
 * In some cases we will get instruments from our backend services that contain a feed of 0 and null ticker, for these cases we
 * want to set the infrontInstrument to undefined so we more easily can do checks on the object.
 * @param infrontInstrument
 * @param instrumentIdCarnegie
 */
export function createValidInstrumentIdContainer(instrumentIdContainer: InstrumentIdContainer) {
  const validInstrumentIdContainer: InstrumentIdContainer = {
    instrumentIdCarnegie: instrumentIdContainer.instrumentIdCarnegie,
    infrontInstrument:
      instrumentIdContainer.infrontInstrument &&
      instrumentIdContainer.infrontInstrument.feed &&
      instrumentIdContainer.infrontInstrument.ticker
        ? instrumentIdContainer.infrontInstrument
        : undefined,
  }

  return validInstrumentIdContainer
}

/**
 * This is a temporary helper for parts of the code that haven't been updated to use instrument id container yet.
 */
export function convertToInstrumentIdContainer(
  instrumentId: Infront.Instrument | Guid | { feed?: number; ticker?: string }
): InstrumentIdContainer {
  if (typeof instrumentId === 'string') {
    return {
      instrumentIdCarnegie: instrumentId,
    }
  } else {
    // Is it a real infront instrument class instance?
    if (instrumentId instanceof Infront.Instrument) {
      return {
        infrontInstrument: instrumentId,
      }
    } else {
      return {
        infrontInstrument: new Infront.Instrument(instrumentId?.feed, instrumentId?.ticker),
      }
    }
  }
}
