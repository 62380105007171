import React from 'react'
import { useTranslation } from 'react-i18next'

import { Option, Select } from '@carnegie/duplo'

type Ticker = {
  instrument: Infront.Instrument
  name: string
}

type CompareIndexSelectorProps = {
  tickers: Ticker[]
  selectedTicker: Ticker
  onSelectTicker: (ticker: Ticker) => void
}
export function CompareIndexSelector({ tickers, onSelectTicker, selectedTicker }: CompareIndexSelectorProps) {
  const { t } = useTranslation()

  return (
    <Select
      minWidth={160}
      width="full"
      label={t('Välj jämförelse')}
      value={selectedTicker ? selectedTicker.name : ''}
      clearButton
      onClearClick={() => {
        onSelectTicker(null)
      }}
      onChange={(_, tickerName) => {
        const ticker = tickers.find((x) => x.name === tickerName)
        onSelectTicker(ticker)
      }}
      size="small"
    >
      {tickers.map((ticker) => {
        return (
          <Option key={ticker.instrument?.ticker || 'pick'} value={ticker.name}>
            {ticker.name}
          </Option>
        )
      })}
    </Select>
  )
}
