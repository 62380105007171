import { useParams } from 'react-router-dom'

type PageParams = {
  page?: string
}

export function usePageParam() {
  const params = useParams<PageParams>()
  let page = 1

  if (params && params.page) {
    page = parseInt(params.page)

    if (isNaN(page)) {
      page = 1
    }
  }
  return page
}
