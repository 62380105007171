import { useTranslation } from 'react-i18next'

import { CommonInstrumentType } from '../../drawers/instrumentDrawer/useCommonInstrumentType'
import { InfrontChartPeriod } from './infront/infrontChartPeriod'
import { useInfrontAvailablePeriods } from './infront/sdk/useInfrontAvailablePeriods'

type Period = {
  value: InfrontChartPeriod
  label: string
}

export const usePeriods = (instrumentType: CommonInstrumentType, feed?: number) => {
  const { t } = useTranslation()
  const availablePeriods = useInfrontAvailablePeriods(feed ?? 0)

  const defaultPeriods: Period[] = [
    { value: '3M', label: t('3m') },
    { value: '6M', label: t('6m') },
    { value: 'YTD', label: t('YTD') },
    { value: '1Y', label: t('1 år') },
    { value: '3Y', label: t('3 år') },
    { value: '5Y', label: t('5 år') },
    { value: 'ALL', label: t('Max') },
  ]

  const stockIntradayPeriods: Period[] = [
    { value: '1D', label: t('Idag') },
    { value: '5D', label: t('1v') },
    { value: '30D', label: t('1m') },
  ]

  const stockPeriods: Period[] = [
    { value: '1W', label: t('1v') },
    { value: '1M', label: t('1m') },
  ]

  const fundPeriods: Period[] = [{ value: '1M', label: t('1m') }]

  // A falsy feed means we don't care about availability and just want the default period list
  if (!feed) {
    return [...stockIntradayPeriods, ...defaultPeriods]
  }

  if (!availablePeriods?.length) {
    return
  }

  const hasIntraday =
    availablePeriods?.includes('1D') && availablePeriods?.includes('5D') && availablePeriods?.includes('30D')

  switch (instrumentType) {
    case 'funds':
      return [...fundPeriods, ...defaultPeriods]
    case 'stocks': {
      const period = hasIntraday ? stockIntradayPeriods : stockPeriods
      return [...period, ...defaultPeriods]
    }
    default:
      return [...stockIntradayPeriods, ...defaultPeriods]
  }
}
