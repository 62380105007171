import { getTranslatedMarketDescription, getTranslatedMarketList } from '@carnegie/digital-channels-frontend'

import { useCommonInstrumentType } from '../../drawers/instrumentDrawer/useCommonInstrumentType'
import i18n from '../../i18n'
import { CarnegieInstrument } from '../api/response'
import { InstrumentIdContainer } from '../instrumentIdContainer'
import { isPredefinedInfrontInstrument } from '../utils/infrontPredefinedInstruments'
import { useInfrontInstrument } from './infront/sdk/useInfrontInstrument'
import { useCarnegieInstrument } from './useCarnegieInstrument'

type InstrumentWithFallbackFields = 'name' | 'isin' | 'marketplace' | 'marketList' | 'currency'

/**
 * Returns a hybrid of some fields coming from: A. Carnegie instrument if available, B. Infront.
 * Because we can get data from different sources we can't have a single isLoading property, to handle
 * this you can assume that if a field returns undefined it is loading, if it is null or a value it got loaded.
 * ## ⚠️IMPORTANT⚠️
 * ### Requires wrapping component in MobX `observer(...)`
 */
export function useInstrumentWithFallbackData(
  instrumentIdContainer: InstrumentIdContainer,
  usedFields: InstrumentWithFallbackFields[]
) {
  // If it is a predefined instrument it means we do not have data about it in our instrument service
  const isPredefined = instrumentIdContainer?.infrontInstrument
    ? isPredefinedInfrontInstrument(instrumentIdContainer.infrontInstrument.ticker)
    : false

  // Data from our own "instrument service"
  const { carnegieInstrument, error: carnegieInstrumentError } = useCarnegieInstrument(
    isPredefined ? undefined : instrumentIdContainer,
    i18n.language
  )

  const instrumentType = useCommonInstrumentType(instrumentIdContainer)

  const includeName = usedFields.includes('name')
  const includeMarketplace = usedFields.includes('marketplace')
  const includeMarketList = usedFields.includes('marketList')
  const includeIsin = usedFields.includes('isin')
  const includeCurrency = usedFields.includes('currency')

  // Data from infront
  const { observableInstrument: infrontInstrument, error: infrontInstrumentError } = useInfrontInstrument(
    instrumentIdContainer?.infrontInstrument,
    [
      includeName && InfrontSDK.SymbolField.FullName,
      includeMarketplace && InfrontSDK.SymbolField.MIC,
      includeMarketList && InfrontSDK.SymbolField.PrimarySegmentDescription,
      includeIsin && InfrontSDK.SymbolField.ISIN,
      includeCurrency && InfrontSDK.SymbolField.Currency,
    ].filter(Boolean),
    { observe: false }
  )

  const name = includeName
    ? getValueWithInfrontFallback(
        carnegieInstrument?.name,
        infrontInstrument?.getFieldValue(InfrontSDK.SymbolField.FullName),
        carnegieInstrument,
        carnegieInstrumentError,
        infrontInstrumentError,
        isPredefined
      )
    : undefined

  const marketplaceName = includeMarketplace
    ? getValueWithInfrontFallback(
        carnegieInstrument?.marketplaceName,
        getTranslatedMarketDescription(infrontInstrument?.getFieldValue(InfrontSDK.SymbolField.MIC)),
        carnegieInstrument,
        carnegieInstrumentError,
        infrontInstrumentError,
        isPredefined
      )
    : undefined

  const marketList = includeMarketList
    ? getValueWithInfrontFallback(
        carnegieInstrument?.marketList,
        getTranslatedMarketList(infrontInstrument?.getFieldValue(InfrontSDK.SymbolField.PrimarySegmentDescription)),
        carnegieInstrument,
        carnegieInstrumentError,
        infrontInstrumentError,
        isPredefined
      )
    : undefined

  const isin = includeIsin
    ? getValueWithInfrontFallback(
        carnegieInstrument?.isin,
        infrontInstrument?.getFieldValue(InfrontSDK.SymbolField.ISIN),
        carnegieInstrument,
        carnegieInstrumentError,
        infrontInstrumentError,
        isPredefined
      )
    : undefined

  const fallbackCurrency = carnegieInstrument?.quotedInCents
    ? infrontInstrument?.getFieldValue(InfrontSDK.SymbolField.Currency)
    : getValueWithInfrontFallback(
        carnegieInstrument?.currency,
        infrontInstrument?.getFieldValue(InfrontSDK.SymbolField.Currency),
        carnegieInstrument,
        carnegieInstrumentError,
        infrontInstrumentError,
        isPredefined
      )
  //if quoteInCents = true then instrument currency is handled in pence
  //i.e currency code is GBX instead of GBP
  //https://dev.azure.com/carnegieinvestmentbank/CarnegieIT/_workitems/edit/74216
  const currency = includeCurrency ? fallbackCurrency : undefined

  return {
    name,
    instrumentType,
    marketplaceName,
    marketList,
    isin,
    currency,
    ticker: instrumentIdContainer?.infrontInstrument?.ticker,
    feed: instrumentIdContainer?.infrontInstrument?.feed,
  }
}

/**
 *
 * @param carnegieInstrumentValue
 * @param infrontFallbackValue
 * @param carnegieInstrument
 * @param carnegieInstrumentError
 * @param infrontInstrumentError
 * @param isPredefinedInfrontInstrument
 * @returns Data from either carnegie (prio 1) or infront (prio 2) or null (not undefined) when all options have been exhausted.
 */
function getValueWithInfrontFallback<T>(
  carnegieInstrumentValue: T,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  infrontFallbackValue: any,
  carnegieInstrument: CarnegieInstrument,
  carnegieInstrumentError: unknown,
  infrontInstrumentError: unknown,
  isPredefinedInfrontInstrument: boolean
): T {
  // isPredefinedInfrontInstrument means we do not have it as a carnegie instrument so using infront data straight away
  if (isPredefinedInfrontInstrument || carnegieInstrumentError || (carnegieInstrument && !carnegieInstrumentValue))
    if (infrontInstrumentError) {
      // This is so we can see the difference between data loading (undefined) vs "we tried everything but got nothing"
      return null
    } else {
      return infrontFallbackValue
    }
  if (carnegieInstrument) {
    return carnegieInstrumentValue
  } else {
    // We are still loading the carnegie instrument so we can't return any fallback data just yet or the UI would "jump" between infront data and our own data
    return undefined
  }
}
