import { InstrumentIdContainer } from '@common/instrumentIdContainer'

import React from 'react'
import { useTranslation } from 'react-i18next'

import { Box, SegmentWithTabs, SimpleTabs } from '@carnegie/duplo'

import { InstrumentFundsCompanyInformation } from './InstrumentFundsCompanyInformation'
import { InstrumentFundsInformation } from './InstrumentFundsInformation'
import { InstrumentFundsTradingAndFees } from './InstrumentFundsTradingAndFees'

type InstrumentFundsOverviewProps = {
  instrumentIdContainer: InstrumentIdContainer
  collateralRatio: number
}

export const InstrumentFundsOverview = ({ instrumentIdContainer, collateralRatio }: InstrumentFundsOverviewProps) => {
  const { t } = useTranslation()

  return (
    <SegmentWithTabs variant="contained" headingVariant="overline">
      <SimpleTabs
        tabs={[
          {
            value: 'tab-information',
            label: t('Information'),
            content: (
              <Box p={16}>
                <InstrumentFundsInformation instrumentIdContainer={instrumentIdContainer} />
              </Box>
            ),
          },
          {
            value: 'tab-company',
            label: t('Bolaget'),
            content: (
              <Box>
                <InstrumentFundsCompanyInformation instrumentIdContainer={instrumentIdContainer} />
              </Box>
            ),
          },
          {
            value: 'tab-fees',
            label: t('Handel & avgifter'),
            content: (
              <Box p={16}>
                <InstrumentFundsTradingAndFees
                  instrumentIdContainer={instrumentIdContainer}
                  collateralRatio={collateralRatio}
                />
              </Box>
            ),
          },
        ]}
      />
    </SegmentWithTabs>
  )
}
InstrumentFundsOverview.displayName = 'InstrumentFundsOverview'
