const MATCH_TYPE_BEGINS_WITH = 'begins-with'

export const orderEntryTranslations: {
  match: string
  matchType: 'begins-with' | 'contains'
  translationKey: string
}[] = [
  {
    match: 'Aktiv order',
    matchType: MATCH_TYPE_BEGINS_WITH,
    translationKey: 'Aktiv order',
  },
  {
    match: 'Pending modify order',
    matchType: MATCH_TYPE_BEGINS_WITH,
    translationKey: 'Väntar på ändring',
  },
  {
    match: 'Pending delete order',
    matchType: MATCH_TYPE_BEGINS_WITH,
    translationKey: 'Väntar på borttag',
  },
  {
    match: 'Order rejected, insufficient trading power.',
    matchType: MATCH_TYPE_BEGINS_WITH,
    translationKey: 'Order avvisad. Det saknas tillräckligt med kapacitet för att genomföra ordern.',
  },
  {
    match: 'Order rejected, shorting not allowed',
    matchType: MATCH_TYPE_BEGINS_WITH,
    translationKey: 'Order avvisad. Angivet antal överstiger tillgängligt innehav.',
  },
  {
    match: 'max order days',
    matchType: 'contains',
    translationKey: 'Order avvisad. Giltighetstiden för order får vara max 7 dagar framåt.',
  },
  {
    match: 'no portfolio access',
    matchType: 'contains',
    translationKey: 'Marknaden är stängd. Ordern skickas nästkommande handelsdag.',
  },
  {
    match: 'Validity automatically extended',
    matchType: 'contains',
    translationKey: 'Order avvisad. Valt konto är inte tillgängligt för handel.',
  },
  {
    match: 'Order has an erroneous validity time.',
    matchType: MATCH_TYPE_BEGINS_WITH,
    translationKey: 'Order avvisad. Giltighetstid felaktig. Kontrollera och försök igen.',
  },
  {
    match: 'Limit orders must specify a Price',
    matchType: MATCH_TYPE_BEGINS_WITH,
    translationKey: 'Order avvisad. Kurslimit måste vara ifyllt.',
  },
  {
    match: 'Quantity must be greater than zero',
    matchType: MATCH_TYPE_BEGINS_WITH,
    translationKey: 'Order avvisad. Antal måste vara ifyllt.',
  },
  {
    match: 'Offline order',
    matchType: MATCH_TYPE_BEGINS_WITH,
    translationKey: 'Order mottagen. Marknaden är stängd men ordern skickas så snart den öppnat.',
  },
  {
    match: 'Validity automatically extended',
    matchType: MATCH_TYPE_BEGINS_WITH,
    translationKey: 'Order mottagen. Marknaden är stängd men ordern skickas så snart den öppnat.',
  },
  {
    match: 'No access to trade this symbol',
    matchType: MATCH_TYPE_BEGINS_WITH,
    translationKey: 'Detta värdepapper är inte tillgängligt för handel',
  },
  {
    match: 'Deleted order',
    matchType: MATCH_TYPE_BEGINS_WITH,
    translationKey: 'Order borttagen',
  },
  {
    match: 'No currency code received for this order.',
    matchType: MATCH_TYPE_BEGINS_WITH,
    translationKey: 'Något gick fel, ordern kunde inte genomföras. Kontakta oss om problemet kvarstår.',
  },
  {
    match: 'YOUR RISK CATEGORY IS NOT SUITABLE FOR TRADING THIS INSTRUMENT',
    matchType: MATCH_TYPE_BEGINS_WITH,
    translationKey: 'Något gick fel, ordern kunde inte genomföras. Kontakta oss om problemet kvarstår.',
  },
]
