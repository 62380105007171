import { AccountSummary } from '@common/api/response'

import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { formatNumber } from '@carnegie/digital-channels-frontend'
import {
  Breakpoint,
  DataCell,
  FlexCol,
  IconInfoOutlined,
  Link,
  SideDrawer,
  Spacer,
  Text,
  useBreakpoint,
} from '@carnegie/duplo'

type Props = {
  summary: AccountSummary
}

export const AcquisitionValue = ({ summary }: Props) => {
  const { t } = useTranslation()
  const [openAcquisitionSlide, setOpenAcquisitionSlide] = useState(false)
  const breakpoint = useBreakpoint()
  const isExtraSmallScreen = breakpoint < Breakpoint.Small

  return (
    <FlexCol flex={1} mr={isExtraSmallScreen ? 0 : 16}>
      <DataCell
        onClick={() => setOpenAcquisitionSlide(true)}
        icon={IconInfoOutlined}
        label={t('Anskaffningsvärde')}
        suffix={summary.acquisitionValue?.currencyCode}
      >
        {formatNumber(summary.acquisitionValue?.amount, { decimals: 0 })}
      </DataCell>
      <SideDrawer
        title={t('Anskaffningsvärde')}
        variant="slide"
        sidebarContent={
          <FlexCol>
            <Text variant="body2">
              {t(
                'Anskaffningsvärdet, även kallat omkostnadsbelopp, visar den totala genomsnittliga inköpskostnaden för innehaven på kontot. Värdet bygger på den information som vi har fått tillgänglig. Vänligen kontrollera att det stämmer överens med dina noteringar för respektive innehav. Det är anskaffningsvärdet som ligger till grund för beräkningen av realiserat samt orealiserat resultat.'
              )}
            </Text>
            <Spacer height={16} />
            <Text variant="body2">
              {t('Om du saknar ett anskaffningsvärde för ett visst innehav, eller har frågor kring värdet ber vi dig')}
              &nbsp;
              <Link style={{ fontSize: 14 }} to="/profile/contact">
                {t('kontakta oss.')}
              </Link>
            </Text>
          </FlexCol>
        }
        open={openAcquisitionSlide}
        onClose={() => setOpenAcquisitionSlide(false)}
      />
    </FlexCol>
  )
}
