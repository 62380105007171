enum MorningStarDocumentType {
  Prospectus = 'prospectus',
  Factsheet = 'factsheet', // New factsheet format (priips) with fallback to old in the api
  AnnualReport = 'annualreport',
}

function getMorningStarDocLink(
  carnegieInstrumentId: string,
  documentType: MorningStarDocumentType,
  languageCode: string
) {
  return `/api/content/morningstar/latest-doc/${carnegieInstrumentId}/${documentType}/${languageCode}`
}

export function getMorningStarFactsheetLink(carnegieInstrumentId: string, languageCode: string) {
  return getMorningStarDocLink(carnegieInstrumentId, MorningStarDocumentType.Factsheet, languageCode)
}

export function getMorningStarAnnualReportLink(carnegieInstrumentId: string, languageCode: string) {
  return getMorningStarDocLink(carnegieInstrumentId, MorningStarDocumentType.AnnualReport, languageCode)
}

export function getMorningStarProspectusLink(carnegieInstrumentId: string, languageCode: string) {
  return getMorningStarDocLink(carnegieInstrumentId, MorningStarDocumentType.Prospectus, languageCode)
}
