import { AccountsResponse } from '@common/api/response'

import { useCallback, useMemo } from 'react'

import useSWR from 'swr'

import i18n from '../../i18n'
import { InstrumentIdContainer } from '../instrumentIdContainer'
import { useApi } from '../stores/store'
import { base64encode } from '../utils/base64encode'
import { isValidInfrontInstrument } from '../utils/isValidInstrument'
import { AccountUserSetting, useAccountUserSettings } from './useAccountUserSettings'
import { useHttpSwr } from './useHttpSwr'
import { useSelectedAccountIdParams } from './useSelectedAccountIdParams'

export function useInstrumentHoldings(instrumentIdContainer: InstrumentIdContainer) {
  const api = useApi()
  const { infrontInstrument, instrumentIdCarnegie } = instrumentIdContainer || {}
  // Prioritize guid when fetching
  const id = infrontInstrument ? base64encode(infrontInstrument) : instrumentIdCarnegie

  const key = `/holdings/${id}`

  const { data, error, isValidating } = useSWR(id ? key : null, async () => {
    if (infrontInstrument && !isValidInfrontInstrument(infrontInstrument))
      throw new Error('Invalid instrument ' + JSON.stringify(instrumentIdContainer, undefined, 2))

    const holdings = await api.fetchInstrumentHoldings(id)

    return { holdings, timeFetched: new Date() }
  })

  return { holdings: data?.holdings, error, timeFetched: data?.timeFetched, isValidating }
}

export type AccountWithUserSettings = AccountsResponse & { userSettings?: AccountUserSetting }

export function useHoldingAccounts() {
  const { data, error, mutate, isValidating } = useHttpSwr<{ items: AccountsResponse[] }>(
    '/accounts/',
    undefined,
    // We cache this in backend already at something like 10 minutes so no point in not caching for a long time in frontend also
    300000 // 5 min
  )

  const { accountUserSettings } = useAccountUserSettings()

  const accounts = useMemo(() => {
    return data?.items
      .filter((x) => !x.endDate)
      ?.map((account) => {
        const accountWithUserSettings: AccountWithUserSettings = {
          ...account,
          userSettings: accountUserSettings?.[account.id],
        }
        return accountWithUserSettings
      })
  }, [accountUserSettings, data?.items])

  const findAccountById = useCallback(
    (accountId: string) => accounts?.find((account) => account.id === accountId),
    [accounts]
  )

  const findAccountByNumber = useCallback(
    (accountNumber: string) => accounts?.find((account) => account.number === accountNumber),
    [accounts]
  )

  return {
    accounts: accounts,
    findAccountById: findAccountById,
    findAccountByNumber: findAccountByNumber,
    error: error,
    isValidating,
    updateData: () => {
      mutate()
    },
    isLoading: !data,
  }
}

function useHoldingAccount(accountId: string) {
  const shouldFetch = accountId !== undefined && accountId !== null ? true : false

  const { data, error, mutate, isValidating } = useHttpSwr(
    shouldFetch ? `/accounts/${accountId}/${i18n.language}` : null,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (data: any) => {
      // We append the current timestamp to the data
      // This needs to be done here so that swr can know to re-render components properly
      return { account: data.item, timeUpdated: new Date() }
    }
  )

  return {
    account: data?.account,
    timeUpdated: data?.timeUpdated,
    error: error,
    updateData: () => {
      mutate()
    },
    isValidating,
    isLoading: data === undefined,
  }
}

export function useSelectedHoldingAccount() {
  // Get the account id from the route
  const accountId = useSelectedAccountIdParams()

  if (accountId === null || accountId === undefined)
    throw new Error('Could not get a selected account number from the current route')

  const accountIdData = useHoldingAccount(accountId)

  return {
    account: accountIdData.account,
    error: accountIdData.error,
    timeUpdated: accountIdData.timeUpdated,
    isValidating: accountIdData.isValidating,
    updateData: accountIdData.updateData,
    isLoading: accountIdData.isLoading,
  }
}

export function usePositions(shouldLoad = true) {
  const api = useApi()
  // https://swr.vercel.app/docs/conditional-fetching
  const { data: accountInstruments, error } = useSWR(shouldLoad ? 'positions' : null, () => api.fetchPositions())

  return { accountInstruments, error }
}
