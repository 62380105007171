import { FundOrderType } from '@common/api/response'

import { useTranslation } from 'react-i18next'

import {
  Icon,
  IconEye,
  IconEyeFilled,
  IconMinus,
  IconPlus,
  IconTransaction,
  IconTrashcan,
  MenuItem,
  Paragraph,
} from '@carnegie/duplo'

import { useOpenFundOrderDrawer } from '@/drawers/fundOrderDrawer/hooks/useOpenFundOrderDrawer'

import { useOpenOrderDrawer } from '../../drawers/orderDrawer/useOpenOrderDrawer'
import { InstrumentIdContainer } from '../instrumentIdContainer'
import { useAuth } from '../stores/store'

type BuySellMenuItemProps = {
  onClick?: () => void
  instrumentIdContainer: InstrumentIdContainer
}

type FundOrderMenuItemProps = {
  onClick?: () => void
  instrumentIdContainer: InstrumentIdContainer
  fundOrderType: FundOrderType
}

export const BuySellMenuItem = ({ onClick, instrumentIdContainer }: BuySellMenuItemProps) => {
  const { t } = useTranslation()
  const { openOrderDrawer } = useOpenOrderDrawer()

  const auth = useAuth()
  if (auth.hasLimitedView) return null

  return (
    <MenuItem
      key="buy"
      onClick={() => {
        openOrderDrawer(instrumentIdContainer)
        onClick?.()
      }}
    >
      <Icon icon={IconPlus} size={20} />
      <Paragraph variant="body1">{t('Köp/Sälj')}</Paragraph>
    </MenuItem>
  )
}

export const PlaceFundOrderMenuItem = ({ onClick, instrumentIdContainer, fundOrderType }: FundOrderMenuItemProps) => {
  const { t } = useTranslation()
  const { openFundOrderDrawer } = useOpenFundOrderDrawer()

  const auth = useAuth()
  if (auth.hasLimitedView) return null

  return (
    <MenuItem
      key={fundOrderType}
      onClick={() => {
        openFundOrderDrawer(instrumentIdContainer, fundOrderType)
        onClick?.()
      }}
    >
      {fundOrderType === 'buy' && (
        <>
          <Icon icon={IconPlus} size={20} />
          <Paragraph variant="body1">{t('Köp')}</Paragraph>
        </>
      )}
      {fundOrderType === 'sell' && (
        <>
          <Icon icon={IconMinus} size={20} />
          <Paragraph variant="body1">{t('Sälj')}</Paragraph>
        </>
      )}
    </MenuItem>
  )
}

PlaceFundOrderMenuItem.displayName = 'PlaceFundOrderMenuItem'

export const TransactionMenuItem = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation()

  return (
    <MenuItem key="transaction" onClick={onClick}>
      <Icon icon={IconTransaction} size={20} />
      <Paragraph variant="body1">{t('Se transaktioner')}</Paragraph>
    </MenuItem>
  )
}

TransactionMenuItem.displayName = 'TransactionMenuItem'

export const DeleteWatchlistItemMenuItem = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation()

  const auth = useAuth()
  if (auth.hasLimitedView) return null

  return (
    <MenuItem key="remove" onClick={onClick}>
      <Icon icon={IconTrashcan} size={20} />
      <Paragraph variant="body1">{t('Ta bort från listan')}</Paragraph>
    </MenuItem>
  )
}

DeleteWatchlistItemMenuItem.displayName = 'DeleteWatchlistItemMenuItem'

export const AddWatchlistItemMenuItem = ({ onClick }: { onClick: (event: unknown) => void }) => {
  const { t } = useTranslation()

  const auth = useAuth()
  if (auth.hasLimitedView) return null

  return (
    <MenuItem key="add-watchlist-item" onClick={onClick}>
      <Icon icon={IconEye} size={20} />
      <Paragraph variant="body1">{t('Bevaka')}</Paragraph>
    </MenuItem>
  )
}

AddWatchlistItemMenuItem.displayName = 'AddWatchlistItemMenuItem'

export const AmendWatchlistItemMenuItem = ({ onClick }: { onClick: (event: unknown) => void }) => {
  const { t } = useTranslation()

  const auth = useAuth()
  if (auth.hasLimitedView) return null

  return (
    <MenuItem key="add-watchlist-item" onClick={onClick}>
      <Icon color="status-success" icon={IconEyeFilled} size={20} />
      <Paragraph variant="body1">{t('Ändra bevakning')}</Paragraph>
    </MenuItem>
  )
}

AmendWatchlistItemMenuItem.displayName = 'AmendWatchlistItemMenuItem'
