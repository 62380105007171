import {
  Icon,
  IconFlagAustralia,
  IconFlagAustria,
  IconFlagBelgium,
  IconFlagBermuda,
  IconFlagBrazil,
  IconFlagBritishVirginIslands,
  IconFlagCanada,
  IconFlagCaymanIslands,
  IconFlagChina,
  IconFlagCyprus,
  IconFlagDenmark,
  IconFlagEstonia,
  IconFlagEu,
  IconFlagFinland,
  IconFlagFrance,
  IconFlagGermany,
  IconFlagGreece,
  IconFlagHongkong,
  IconFlagIceland,
  IconFlagIreland,
  IconFlagIsrael,
  IconFlagItaly,
  IconFlagJapan,
  IconFlagLatvia,
  IconFlagLuxembourg,
  IconFlagMexico,
  IconFlagNetherlands,
  IconFlagNewZealand,
  IconFlagNorway,
  IconFlagPhilippines,
  IconFlagPoland,
  IconFlagPortugal,
  IconFlagRussia,
  IconFlagSingapore,
  IconFlagSouthKorea,
  IconFlagSouthafrica,
  IconFlagSpain,
  IconFlagSweden,
  IconFlagSwitzerland,
  IconFlagThailand,
  IconFlagUk,
  IconFlagUkraine,
  IconFlagUsa,
} from '@carnegie/duplo'

export const CountryCodeFlag = ({ countryCode, size = 32 }: { countryCode: string; size?: number }) => {
  switch (countryCode) {
    case 'AT':
      return <Icon flexShrink={0} icon={IconFlagAustria} size={size} />
    case 'AU':
      return <Icon flexShrink={0} icon={IconFlagAustralia} size={size} />
    case 'BE':
      return <Icon flexShrink={0} icon={IconFlagBelgium} size={size} />
    case 'BM':
      return <Icon flexShrink={0} icon={IconFlagBermuda} size={size} />
    case 'BR':
      return <Icon flexShrink={0} icon={IconFlagBrazil} size={size} />
    case 'CA':
      return <Icon flexShrink={0} icon={IconFlagCanada} size={size} />
    case 'CH':
      return <Icon flexShrink={0} icon={IconFlagSwitzerland} size={size} />
    case 'CN':
      return <Icon flexShrink={0} icon={IconFlagChina} size={size} />
    case 'CY':
      return <Icon flexShrink={0} icon={IconFlagCyprus} size={size} />
    case 'DE':
      return <Icon flexShrink={0} icon={IconFlagGermany} size={size} />
    case 'DK':
      return <Icon flexShrink={0} icon={IconFlagDenmark} size={size} />
    case 'EE':
      return <Icon flexShrink={0} icon={IconFlagEstonia} size={size} />
    case 'ES':
      return <Icon flexShrink={0} icon={IconFlagSpain} size={size} />
    case 'EU':
      return <Icon flexShrink={0} icon={IconFlagEu} size={size} />
    case 'FI':
      return <Icon flexShrink={0} icon={IconFlagFinland} size={size} />
    case 'FR':
      return <Icon flexShrink={0} icon={IconFlagFrance} size={size} />
    case 'GB':
      return <Icon flexShrink={0} icon={IconFlagUk} size={size} />
    case 'GR':
      return <Icon flexShrink={0} icon={IconFlagGreece} size={size} />
    case 'HK':
      return <Icon flexShrink={0} icon={IconFlagHongkong} size={size} />
    case 'IE':
      return <Icon flexShrink={0} icon={IconFlagIreland} size={size} />
    case 'IL':
      return <Icon flexShrink={0} icon={IconFlagIsrael} size={size} />
    case 'IS':
      return <Icon flexShrink={0} icon={IconFlagIceland} size={size} />
    case 'IT':
      return <Icon flexShrink={0} icon={IconFlagItaly} size={size} />
    case 'JP':
      return <Icon flexShrink={0} icon={IconFlagJapan} size={size} />
    case 'KR':
      return <Icon flexShrink={0} icon={IconFlagSouthKorea} size={size} />
    case 'KY':
      return <Icon flexShrink={0} icon={IconFlagCaymanIslands} size={size} />
    case 'LU':
      return <Icon flexShrink={0} icon={IconFlagLuxembourg} size={size} />
    case 'LV':
      return <Icon flexShrink={0} icon={IconFlagLatvia} size={size} />
    case 'MX':
      return <Icon flexShrink={0} icon={IconFlagMexico} size={size} />
    case 'NL':
      return <Icon flexShrink={0} icon={IconFlagNetherlands} size={size} />
    case 'NO':
    case 'NOR':
      return <Icon flexShrink={0} icon={IconFlagNorway} size={size} />
    case 'NZ':
      return <Icon flexShrink={0} icon={IconFlagNewZealand} size={size} />
    case 'PH':
      return <Icon flexShrink={0} icon={IconFlagPhilippines} size={size} />
    case 'PL':
      return <Icon flexShrink={0} icon={IconFlagPoland} size={size} />
    case 'PT':
      return <Icon flexShrink={0} icon={IconFlagPortugal} size={size} />
    case 'RU':
      return <Icon flexShrink={0} icon={IconFlagRussia} size={size} />
    case 'SE':
      return <Icon flexShrink={0} icon={IconFlagSweden} size={size} />
    case 'SG':
      return <Icon flexShrink={0} icon={IconFlagSingapore} size={size} />
    case 'TH':
      return <Icon flexShrink={0} icon={IconFlagThailand} size={size} />
    case 'UA':
      return <Icon flexShrink={0} icon={IconFlagUkraine} size={size} />
    case 'US':
      return <Icon flexShrink={0} icon={IconFlagUsa} size={size} />
    case 'VG':
      return <Icon flexShrink={0} icon={IconFlagBritishVirginIslands} size={size} />
    case 'ZA':
      return <Icon flexShrink={0} icon={IconFlagSouthafrica} size={size} />
    case 'UNKNOWN':
      return null
    default:
      return <>{countryCode}</>
  }
}
