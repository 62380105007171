import { NoDataDash } from '@common/components/NoDataFallback'
import { useInstrumentWithFallbackData } from '@common/hooks/useInstrumentWithFallbackData'
import { InstrumentIdContainer } from '@common/instrumentIdContainer'

import { useTranslation } from 'react-i18next'

import { Box, ListItem, ListItemRow, Segment, Text } from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import { ListItemValue } from '../ListItemValue'

type Props = {
  instrumentIdContainer: InstrumentIdContainer
}

export const InstrumentIndexInformation = observer(({ instrumentIdContainer }: Props) => {
  const { t } = useTranslation()

  const instrumentWithFallback = useInstrumentWithFallbackData(instrumentIdContainer, ['isin'])

  const isin = instrumentWithFallback?.isin

  return (
    <Box>
      <Segment noContentPadding>
        <ListItem divider>
          <ListItemRow
            title={<Text variant="support2">{t('Kortnamn')}</Text>}
            value={
              <Text variant="subtitle2">{instrumentIdContainer?.infrontInstrument?.ticker ?? <NoDataDash />}</Text>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemRow title={<Text variant="support2">{t('ISIN')}</Text>} value={<ListItemValue value={isin} />} />
        </ListItem>
      </Segment>
    </Box>
  )
})
InstrumentIndexInformation.displayName = 'InstrumentIndexInformation'
