import { CarnegieInstrument } from '@common/api/response'
import { useApi } from '@common/stores/store'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const useCarnegieInstrumentsFromCarnegieIds = (carnegieIds: string[]) => {
  const api = useApi()
  const { i18n } = useTranslation()
  const [carnegieInstruments, setCarnegieInstruments] = useState<CarnegieInstrument[]>(null)
  const [error, setError] = useState<Error>(null)

  useEffect(() => {
    const loadCarnegieIds = async () => {
      const instrumentPromises = carnegieIds.map((carnegieId) => api.fetchCarnegieInstrument(carnegieId, i18n.language))
      const instruments = await Promise.all(instrumentPromises).catch((reason) => {
        setError(new Error(reason))
        return []
      })
      setCarnegieInstruments(instruments)
    }

    loadCarnegieIds()

    return () => setError(null)
  }, [api, carnegieIds, i18n.language])

  return { carnegieInstruments, error }
}

export default useCarnegieInstrumentsFromCarnegieIds
