import { useAvailableAccounts } from '@common/hooks/useTransfer'

import { ReactNode, createContext, useContext, useState } from 'react'

export type TransferState = 'sent' | 'success' | 'error'
interface TransferPage {
  page: number
  setPage: (currentPage: number) => void
  registeringTransfer: TransferState
  setRegisteringTransfer: (transferState: TransferState) => void
  accounts: ReturnType<typeof useAvailableAccounts>
}

const TransferPageContext = createContext<TransferPage>({
  page: 1,
  setPage: () => {},
  registeringTransfer: null,
  setRegisteringTransfer: () => {},
  accounts: null,
})

export const TransferPageProvider = ({
  includeDefaultAccounts = false,
  children,
}: {
  includeDefaultAccounts: boolean
  children?: ReactNode
}) => {
  const [page, setPage] = useState(1)
  const [registeringTransfer, setRegisteringTransfer] = useState<TransferState>(null)
  const accounts = useAvailableAccounts({ includeDefaultAccounts })

  return (
    <TransferPageContext.Provider
      value={{
        page,
        setPage,
        registeringTransfer,
        setRegisteringTransfer,
        accounts,
      }}
    >
      {children}
    </TransferPageContext.Provider>
  )
}

export const useTransferPage = () => useContext(TransferPageContext)
