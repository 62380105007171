import { SubscriptionType } from '@common/api/response'
import { useDrawerProps } from '@common/components/drawer/useDrawerStore'
import { useOpenDrawer } from '@common/components/drawer/useOpenDrawer'

import { SubscriptionDrawerProps } from './SubscriptionDrawer'

const DELIMITER = '|'

const useOpenSubscriptionDrawer = () => {
  const { openDrawer } = useOpenDrawer()

  const openSubscriptionDrawer: (props: SubscriptionDrawerProps) => void = (props) => {
    const routeProps = convertSubscriptionDrawerPropsToUrl(props)
    openDrawer('subscription', routeProps, props)
  }

  return { openSubscriptionDrawer }
}

export function useSubscriptionDrawerProps() {
  const { routeProps } = useDrawerProps()
  return convertUrlToSubscriptionDrawerProps(routeProps)
}

function convertSubscriptionDrawerPropsToUrl({ marketingEmailId, subscriptionType }: SubscriptionDrawerProps): string {
  if (marketingEmailId && isSubscriptionType(subscriptionType)) {
    return `${subscriptionType}${DELIMITER}${marketingEmailId}`
  }

  throw new Error(
    `Missing or invalid arguments to Subscription drawer props to url. ID: ${marketingEmailId} Type: ${subscriptionType}`
  )
}

function convertUrlToSubscriptionDrawerProps(urlSegment: string): SubscriptionDrawerProps {
  const parts = urlSegment.split(DELIMITER)

  if (validateDrawerProps(parts)) {
    const [subscriptionType, marketingEmailId] = parts
    return { subscriptionType, marketingEmailId }
  }

  throw new Error('Invalid url arguments to Subscription drawer, the value was: ' + urlSegment)
}

const validateDrawerProps = (parts: string[]): parts is [SubscriptionType, string] => {
  const [subscriptionType, marketingEmailId] = parts
  return parts.length === 2 && marketingEmailId !== '' && isSubscriptionType(subscriptionType)
}

const isSubscriptionType = (type: string): type is SubscriptionType =>
  Object.values(SubscriptionType).includes(type as SubscriptionType)

export default useOpenSubscriptionDrawer
export { isSubscriptionType }
