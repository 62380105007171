import { useFormatDate } from '@carnegie/digital-channels-frontend'
import { FlexRow, Text } from '@carnegie/duplo'

type Props = {
  date: Date
  tags: string
}

export const CarnegieMaterialTagAndDate = ({ date, tags }: Props) => {
  const { formatDate } = useFormatDate()

  return (
    <FlexRow alignItems="center">
      <Text variant="overline">{tags}</Text>
      <Text variant="overline" ml={4} mr={4}>
        -
      </Text>
      <Text variant="overline">{formatDate(date, 'yyyy-MM-dd HH:mm')}</Text>
    </FlexRow>
  )
}
CarnegieMaterialTagAndDate.displayName = 'CarnegieMaterialTagAndDate'
