import { ResearchNews } from '@common/api/response'
import { SkeletonLoader } from '@common/components/SkeletonLoader'
import { fireTrackEvent } from '@common/utils/analyticsEvent'

import { useOpenResearchDrawer } from '../shared/useOpenResearchDrawer'
import { ResearchNewsListItem } from './ResearchNewsListItem'

type ResearchAndCommentsProps = {
  researchNews: ResearchNews[]
  showCompanyNameAndTargets?: boolean
}

export const ResearchAndComments = ({ researchNews, showCompanyNameAndTargets = true }: ResearchAndCommentsProps) => {
  const openResearchDrawer = useOpenResearchDrawer()

  return (
    <SkeletonLoader p={16} loading={!researchNews}>
      {() =>
        researchNews.map((rn, index) => {
          return (
            <ResearchNewsListItem
              researchNews={rn}
              key={index}
              onClick={() => {
                fireTrackEvent(
                  'Research',
                  rn.type === 'Research'
                    ? 'open_research'
                    : rn.type === 'News'
                    ? 'open_research_comment'
                    : `open_research_${rn.type}`
                )
                openResearchDrawer(rn)
              }}
              showResearchTargets
              showCompanyNameAndTargets={showCompanyNameAndTargets}
            />
          )
        })
      }
    </SkeletonLoader>
  )
}
