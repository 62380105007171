import { useDrawerProps } from '@common/components/drawer/useDrawerStore'
import { useOfferings } from '@common/hooks/useOfferings'

import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Banner, Breakpoint, CSSGrid, GridBox, Heading3, Spacer, Text, useBreakpoint } from '@carnegie/duplo'

import { CorporateActionRespondForm } from './CorporateActionRespondForm'
import { OfferInfo } from './OfferInfo'

export const OfferingsDrawer = () => {
  const { t } = useTranslation()
  const { routeProps: offeringIdFromRoute } = useDrawerProps<string>()
  const { offerings, registerCorporateActions, isValidating } = useOfferings({
    offeringId: Number(offeringIdFromRoute),
    type: null,
  })
  const [isRegistringSuccess, setIsRegistringSuccess] = useState<boolean>(undefined)
  const breakpoint = useBreakpoint()
  const isMobile = breakpoint < Breakpoint.Small
  const isTablet = breakpoint < Breakpoint.Medium

  const offering = offerings?.[0]

  return (
    <div>
      <div id="offerings-drawer-top" />
      <Heading3 id="offerings-drawer-header" skeletonWidth={300} skeleton={!offering}>
        {offering?.instrumentName}
      </Heading3>
      <Spacer height={32} />
      {isRegistringSuccess === true && (
        <Banner
          severity="success"
          title={t('Erbjudandet är besvarat')}
          description={t('Ditt svar är registrerat. Du kan ändra ditt svar fram tills sista svarsdatum.')}
          mb={32}
        />
      )}
      {isRegistringSuccess === false && (
        <Banner
          severity="critical"
          title={t('Svaret kunde inte registreras för ett eller flera konton')}
          description={t('Försök igen, eller kontakta oss om felet kvarstår.')}
          mb={32}
        />
      )}
      <Text variant="body1">
        {t(
          'Du har fått ett erbjudande om att delta i den här bolagshändelsen eftersom du hade innehav på ett eller flera av dina konton i det aktuella bolaget på avstämningsdagen. Läs igenom villkoren och ta ställning till om du vill delta. Du kan ändra ditt svar fram till sista svarsdatum.'
        )}
      </Text>
      <Spacer height={32} />
      <CSSGrid rows="auto,auto" cols={12} height="full" gap={16}>
        <GridBox colSpan={isMobile || isTablet ? 12 : 5}>
          <OfferInfo offering={offering} />
        </GridBox>
        <GridBox colSpan={isMobile || isTablet ? 12 : 7}>
          <CorporateActionRespondForm
            isValidatingData={isValidating}
            registerCorporateActions={registerCorporateActions}
            offering={offering}
            setIsRegistringSuccess={setIsRegistringSuccess}
          />
        </GridBox>
      </CSSGrid>
    </div>
  )
}
