import styled from '@emotion/styled'

import { ReactNode } from 'react'

import { Segment } from '@carnegie/duplo'

import { motion } from 'framer-motion'

export const ValueContainer = styled(motion.div)`
  flex: 1;
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  white-space: nowrap;
  margin-top: 2px;
  margin-bottom: 2px;
`

type InstrumentPriceInformationWrapperProps = {
  children?: ReactNode
}

export const InstrumentTopSegment = ({ children }: InstrumentPriceInformationWrapperProps) => {
  return (
    <Segment variant="contained" headingVariant="h6">
      {children}
    </Segment>
  )
}

InstrumentTopSegment.displayName = 'InstrumentTopSegment'
