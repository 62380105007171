import { AccountsResponse } from '@common/api/response'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Banner,
  Box,
  Button,
  DatePicker,
  FlexCol,
  FlexRow,
  Icon,
  IconButton,
  IconInfoOutlined,
  IconPlus,
  Link,
  LoadingIcon,
  Option,
  Popper,
  Select,
  SkeletonRect,
  Text,
} from '@carnegie/duplo'

import {
  add,
  isBefore,
  isValid,
  isWithinInterval,
  lastDayOfMonth,
  previousMonday,
  startOfMonth,
  startOfToday,
  startOfYear,
  startOfYesterday,
  sub,
  subMonths,
} from 'date-fns'

import { ObservableAccount } from '../../pages/overview/accounts/useAccounts'
import { useHoldingAccounts } from '../hooks/useHoldings'
import { useApi } from '../stores/store'
import { fireTrackEvent } from '../utils/analyticsEvent'
import { AccountSelect } from './AccountSelect'

export type CreateReportBody = {
  accountId: string
  fromDate: Date
  middleDate: Date
  toDate: Date
  language: string
  reportName: string
}

enum ValidationErrors {
  noError = '',
  fromInvalid = 'FROM_INVALID',
  toInvalid = 'TO_INVALID',
  middleInvalid = 'MIDDLE_INVALID',
  fromError = 'FROM_ERROR',
  toError = 'TO_ERROR',
  middleError = 'MIDDLE_ERROR',
  accountError = 'NO_ACCOUNT_ERROR',
}

type Props = {
  initialAccount?: AccountsResponse | ObservableAccount
  onAccountSelect?: (account: AccountsResponse) => void
  closeDrawer?: () => void
}

const validateFromDate = (from: Date) => {
  return isBefore(from, startOfToday())
}

const validateToDate = (from: Date, to: Date) => {
  return isBefore(from, to)
}

const validateMiddleDate = (from: Date, to: Date, middle: Date) => {
  return isBefore(from, to) && isWithinInterval(middle, { start: from, end: to })
}

const CreateReportSideDrawer = ({ initialAccount, onAccountSelect, closeDrawer }: Props) => {
  const { t } = useTranslation()
  const api = useApi()
  const { accounts } = useHoldingAccounts()

  const reportNameMap = {
    PortfolioReport: t('Portföljrapport'),
    Realised: t('Realiseratrapport'),
  }

  const reportButtonTextMap = {
    PortfolioReport: t('Skapa portföljrapport'),
    Realised: t('Skapa realiseratrapport'),
  }

  const reportButtonTextCreatingMap = {
    PortfolioReport: t('Skapar portföljrapport'),
    Realised: t('Skapar realiseratrapport'),
  }

  const reportAnalyticsMap = {
    PortfolioReport: 'create_report_portfolio',
    Realised: 'create_report_realised',
  }

  const errorMessages = {
    [ValidationErrors.fromInvalid]: t(
      'Det valda från-datumet har inte rätt format. Ändra datum för att skapa en rapport.'
    ),
    [ValidationErrors.toInvalid]: t(
      'Det valda till-datumet har inte rätt format. Ändra datum för att skapa en rapport.'
    ),
    [ValidationErrors.middleInvalid]: t(
      'Det valda datumet för extra avstämning har inte rätt format. Ändra datum för att skapa en rapport.'
    ),
    [ValidationErrors.fromError]: t(
      'Det valda från-datumet måste vara innan till-datumet samt innan dagens datum. Ändra datum för att skapa en rapport.'
    ),
    [ValidationErrors.toError]: t(
      'Det valda till-datumet måste vara efter från-datumet. Ändra datum för att skapa en rapport.'
    ),
    [ValidationErrors.middleError]: t(
      'Extra avstämningsdatum måste vara inom den valda perioden. Ändra datum för att skapa en rapport.'
    ),
    [ValidationErrors.accountError]: t('Du har inte valt något konto. Välj ett konto för att skapa en rapport.'),
  }

  const [selectedAccount, setSelectedAccount] = useState<AccountsResponse | ObservableAccount>(initialAccount)
  const [selectedAccountValid, setSelectedAccountValid] = useState(true)
  const [reportName, setReportName] = useState<'PortfolioReport' | 'Realised'>('PortfolioReport')
  const [fromDate, setFromDate] = useState(startOfYear(new Date()))
  const [fromDateValid, setFromDateValid] = useState(true)
  const [toDate, setToDate] = useState(null)
  const [toDateValid, setToDateValid] = useState(true)
  const [middleDate, setMiddleDate] = useState(null)
  const [middleDateValid, setMiddleDateValid] = useState(true)
  const [language, setLanguage] = useState<'sv' | 'en'>('sv')
  const [reportCreated, setReportCreated] = useState(false)
  const [reportError, setReportError] = useState(false)
  const [reportConfigError, setReportConfigError] = useState<ValidationErrors>(ValidationErrors.noError)
  const [creatingReport, setCreatingReport] = useState(false)

  const validateDates = (from: Date, to: Date, middle: Date, showErrorBanner = false) => {
    setFromDateValid(true)
    setToDateValid(true)
    setMiddleDateValid(true)
    setReportConfigError(ValidationErrors.noError)

    let valid = true

    if (reportName === 'PortfolioReport' && !isValid(middle)) {
      showErrorBanner && setReportConfigError(ValidationErrors.middleInvalid)
      setMiddleDateValid(false)
      valid = false
    }

    if (!isValid(to)) {
      showErrorBanner && setReportConfigError(ValidationErrors.toInvalid)
      setToDateValid(false)
      valid = false
    }

    if (!isValid(from)) {
      showErrorBanner && setReportConfigError(ValidationErrors.fromInvalid)
      setFromDateValid(false)
      valid = false
    }

    return valid
  }

  const validateReportConfig = (config: CreateReportBody) => {
    const { accountId, fromDate, middleDate, toDate, reportName } = config

    if (!accountId) {
      setSelectedAccountValid(false)
      setReportConfigError(ValidationErrors.accountError)
      return false
    }

    const showErrorBanner = true
    if (!validateDates(fromDate, toDate, middleDate, showErrorBanner)) {
      return false
    }

    if (!validateFromDate(fromDate)) {
      setReportConfigError(ValidationErrors.fromError)
      setFromDateValid(false)
      return false
    }

    if (!validateToDate(fromDate, toDate)) {
      setReportConfigError(ValidationErrors.toError)
      setToDateValid(false)
      return false
    }

    if (reportName === 'PortfolioReport' && !validateMiddleDate(fromDate, toDate, middleDate)) {
      setReportConfigError(ValidationErrors.middleError)
      setMiddleDateValid(false)
      return false
    }

    return true
  }

  useEffect(() => {
    const lastBankingDayPreviousMonth = async () => {
      const TODAY = new Date()
      const isFirstMonth = TODAY.getMonth() === 0
      const isFirstDayOfYear = isFirstMonth && TODAY.getDate() === 1

      if (isFirstDayOfYear) return setMiddleDate(TODAY)
      if (isFirstMonth) return setMiddleDate(startOfMonth(TODAY))

      const lastDayOfPreviousMonth = lastDayOfMonth(subMonths(startOfToday(), 1))
      const date = await api.fetchLastBankingDay(lastDayOfPreviousMonth)

      setMiddleDate((date && new Date(date)) ?? startOfYesterday())
    }

    lastBankingDayPreviousMonth()
  }, [api])

  useEffect(() => {
    const lastBankingDay = async () => {
      const yesterday = startOfYesterday()
      const date = await api.fetchLastBankingDay(yesterday)
      setToDate((date && new Date(date)) ?? yesterday)

      if (date && new Date(date)) {
        const thisToDate = new Date(date)

        if (isBefore(thisToDate, fromDate)) {
          //Edge case - creating report around non banking day
          // Ex; date is 2024-01-01. toDate will be 2023-12-29 and fromDate
          const previousDay = subMonths(thisToDate, 1)
          const newFromDate = await api.fetchLastBankingDay(previousDay)
          setFromDate((newFromDate && new Date(newFromDate)) ?? previousMonday(yesterday))
        }
      }
    }

    lastBankingDay()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api])

  const createReport = async () => {
    setCreatingReport(true)

    const reportConfig: CreateReportBody = {
      accountId: selectedAccount?.id,
      fromDate,
      middleDate,
      toDate,
      language,
      reportName,
    }

    if (!validateReportConfig(reportConfig)) {
      setCreatingReport(false)
      return
    }

    const response = await api.createReport(reportConfig)
    if (!response?.error) {
      fireTrackEvent('Reports', reportAnalyticsMap[reportName] ?? 'create_report_unknown')
      setReportCreated(true)
    } else {
      console.error(response?.error ?? 'Unknown error creating report', response)
      setReportError(true)
    }
  }

  if (reportError) {
    return (
      <Banner
        severity="critical"
        title={t('Något gick fel')}
        description={<Text variant="body1">{t('Kunde inte generera rapport. Kontakta oss för mer information.')}</Text>}
      />
    )
  }

  if (reportCreated) {
    return (
      <Banner
        severity="success"
        title={t('Rapport genereras')}
        description={
          <Text variant="body1">
            {t('Rapporten tar några minuter att skapa men finns snart tillgänglig i ditt ')}
            <Link to={`/profile/reports/archive/${selectedAccount?.id ?? ''}`} onClick={closeDrawer}>
              {t('Rapportarkiv')}
            </Link>
          </Text>
        }
      />
    )
  }

  return (
    <FlexCol space={16}>
      <AccountSelect
        id="create-report-account-select"
        error={!selectedAccountValid}
        onSelectAccount={(accountId) => {
          const account = accounts.find((account) => account?.id === accountId)
          if (account) {
            setSelectedAccount(account)
            setReportConfigError(ValidationErrors.noError)
            setSelectedAccountValid(true)
            onAccountSelect?.(account)
          }
        }}
        selectedAccount={selectedAccount}
      />

      <Select
        width="full"
        label={t('Rapporttyp')}
        value={reportName}
        onChange={(_, value) => {
          setReportName(value)
          validateDates(fromDate, toDate, middleDate)
        }}
        renderValue={(selectedValue) => {
          if (selectedValue === undefined) return

          return reportNameMap[selectedValue]
        }}
        size="medium"
      >
        <Option value="PortfolioReport">{t('Portföljrapport')}</Option>
        <Option value="Realised">{t('Realiseratrapport')}</Option>
      </Select>

      <FlexRow space={16}>
        <DatePicker
          error={!fromDateValid}
          id="from"
          label={t('Från')}
          onChange={(newFrom) => {
            setFromDate(newFrom)
            validateDates(newFrom, toDate, middleDate)
          }}
          value={fromDate}
          maxDate={sub(toDate, { days: 1 })}
          autoComplete="off"
        />

        {toDate ? (
          <DatePicker
            error={!toDateValid}
            id="to"
            label={t('Till')}
            onChange={(newTo) => {
              setToDate(newTo)
              validateDates(fromDate, newTo, middleDate)
            }}
            value={toDate}
            minDate={add(fromDate, { days: 1 })}
            autoComplete="off"
          />
        ) : (
          <SkeletonRect height={40} width="100%" />
        )}
      </FlexRow>

      {reportName === 'PortfolioReport' && (
        <>
          <FlexRow space={16}>
            {middleDate ? (
              <DatePicker
                error={!middleDateValid}
                id="reconciliation"
                label={t('Extra avstämningsdatum')}
                onChange={(newMiddle) => {
                  setMiddleDate(newMiddle)
                  validateDates(fromDate, toDate, newMiddle)
                }}
                value={middleDate}
                minDate={fromDate}
                maxDate={toDate}
                autoComplete="off"
              />
            ) : (
              <SkeletonRect height={40} width="100%" />
            )}

            <Popper
              id="reconciliationinfo"
              toggle={<IconButton size="large" variant="uncontained" icon={IconInfoOutlined} />}
            >
              <Box p={16}>
                <Text variant="body2">
                  {t('Ange ett extra avstämningsdatum för rapporten. Förvalt är innevarande månad.')}
                </Text>
              </Box>
            </Popper>
          </FlexRow>
        </>
      )}
      <Select
        width="full"
        label={t('Språk')}
        value={language}
        onChange={(_, value) => setLanguage(value)}
        renderValue={(selectedValue) => (selectedValue === undefined ? undefined : t(selectedValue))}
        size="medium"
      >
        <Option value="sv">{t('sv')}</Option>
        <Option value="en">{t('en')}</Option>
      </Select>

      {reportConfigError !== ValidationErrors.noError && (
        <Banner
          severity="critical"
          title={t('Något gick fel')}
          description={<Text variant="body1">{errorMessages[reportConfigError]}</Text>}
        />
      )}

      <Button
        variant="primary"
        size="medium"
        onClick={createReport}
        disabled={creatingReport}
        startIcon={creatingReport ? <LoadingIcon size={24} /> : <Icon icon={IconPlus} />}
      >
        {creatingReport ? reportButtonTextCreatingMap[reportName] : reportButtonTextMap[reportName]}
      </Button>
    </FlexCol>
  )
}

export default CreateReportSideDrawer
