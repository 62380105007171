import { ReservedQuantity } from '@common/api/response'
import { CountryCodeFlag } from '@common/components/CountryCodeToFlag'
import { CurrencyFlag } from '@common/components/CurrencyToFlag'
import { ReservedQuantityRows } from '@common/components/ReservedQuantityRows'
import { useAuth } from '@common/stores/store'

import { useTranslation } from 'react-i18next'

import {
  FlexCol,
  FlexRow,
  Icon,
  IconButton,
  IconInfoOutlined,
  IconLock,
  LinkButton,
  Text,
  Tooltip,
} from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

type InstrumentNameProps = {
  isoCountryCode: string
  name: string
  reservedQuantities?: ReservedQuantity[]
  onClick: () => void
  showFlag: boolean
  showInfoButton?: boolean
  onInfoButtonClick?: () => void
}

export const InstrumentName = observer(
  ({
    isoCountryCode,
    name,
    reservedQuantities,
    onClick,
    showFlag,
    showInfoButton,
    onInfoButtonClick,
  }: InstrumentNameProps) => {
    const auth = useAuth()
    const { t } = useTranslation()

    let flag
    if (isoCountryCode !== 'Unknown') flag = showFlag && <CountryCodeFlag countryCode={isoCountryCode} size={16} />

    const tooltipTitle = (
      <Text variant="support2" color="off-white">
        {name}
      </Text>
    )

    return (
      <FlexRow data-testid="instrument-name" width="full">
        <FlexRow alignItems="center" width="full">
          {flag && (
            <FlexRow alignItems="center" mr={4} flexShrink={0}>
              {flag}
            </FlexRow>
          )}
          <FlexRow minWidth={0} alignItems="baseline" spaceX={4}>
            {auth.hasLimitedView ? (
              <Tooltip title={tooltipTitle}>
                <Text variant="body2" truncate minWidth={0}>
                  {name}
                </Text>
              </Tooltip>
            ) : (
              <>
                <Tooltip title={tooltipTitle}>
                  <LinkButton
                    variant="body2"
                    truncate
                    minWidth={0}
                    onClick={onClick}
                    data-testid="instrument-name-button"
                  >
                    {name}
                  </LinkButton>
                </Tooltip>
                {reservedQuantities && (
                  <Tooltip title={<ReservedQuantityRows includeIcon={false} reservedQuantities={reservedQuantities} />}>
                    <Icon icon={IconLock} size={16} ml={8} alignSelf={'center'} />
                  </Tooltip>
                )}
              </>
            )}
            {showInfoButton && (
              <IconButton
                alignSelf="center"
                onClick={onInfoButtonClick}
                icon={IconInfoOutlined}
                color="icon-low-emphasis"
                size="small"
                variant="uncontained"
                flexShrink={0}
                tooltip={t('Se information om fondens innehav.')}
              />
            )}
          </FlexRow>
        </FlexRow>
      </FlexRow>
    )
  }
)

type InstrumentNameMobileProps = {
  isCurrency?: boolean
  isoCountryCode: string
  name: string
  reservedQuantities?: ReservedQuantity[]
  showFlag?: boolean
}

export const InstrumentNameMobile = observer(
  ({ isCurrency, isoCountryCode, name, reservedQuantities, showFlag = false }: InstrumentNameMobileProps) => {
    const auth = useAuth()

    let flag
    if (isCurrency) flag = <CurrencyFlag currency={name} size={16} />
    else if (isoCountryCode !== 'Unknown') flag = showFlag && <CountryCodeFlag countryCode={isoCountryCode} size={16} />

    return (
      <FlexCol alignItems="flex-start" flex={1} width="full">
        <FlexRow maxWidth={'3/4'} alignItems="center">
          {flag && (
            <FlexRow alignItems="center" mr={4} flexShrink={0}>
              {flag}
            </FlexRow>
          )}
          <Text
            display="block"
            variant="subtitle1"
            color={auth.hasLimitedView ? 'current' : 'text-high-emphasis'}
            truncate
          >
            {name}
          </Text>
        </FlexRow>
        {reservedQuantities && (
          <FlexRow alignItems="center" mt={6}>
            <Text variant="support2" color="text-disabled">
              <ReservedQuantityRows includeIcon={true} reservedQuantities={reservedQuantities} />
            </Text>
          </FlexRow>
        )}
      </FlexCol>
    )
  }
)
