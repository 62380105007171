import { useInfrontStore } from '../../../stores/store'

/**
 * ## ⚠️IMPORTANT⚠️
 * ### Requires wrapping component in MobX `observer(...)`
 */
export function useInfrontSDK() {
  const infrontStore = useInfrontStore()

  // Because hasLoaded is observable the component will be re-rendered when hasLoaded gets changed which will call this hook again

  return {
    infrontSDK: infrontStore.hasLoaded ? infrontStore.infrontSDK : undefined,
    hasLoaded: infrontStore.hasLoaded,
    error: infrontStore.hasError,
  }
}
