import { useSelectedHoldingAccount } from '@common/hooks/useHoldings'

import { useTranslation } from 'react-i18next'

import { Box } from '@carnegie/duplo'

import { HoldingsSegment } from './HoldingsSegment'

class CarnegieAllocationNames {
  //TODO: Use the real type instead
  static Stock = ['Stock', 'Aktier']
  static FixedIncome = ['FixedIncome', 'Räntebärande']
  static Alternative = ['Alternative', 'Alternativa']
  static Other = ['Other']
}

type Props = {
  showMoreColumns: boolean
  showPercentage: boolean
}

export const Allocations = ({ showPercentage, showMoreColumns }: Props) => {
  const { t } = useTranslation()
  const { account } = useSelectedHoldingAccount()
  const instruments = account?.holdings?.instruments

  const filterBy = (allocation: string[]) => {
    if (instruments) return instruments.filter((x) => allocation.includes(x?.instrument?.allocation))
  }

  const stock = filterBy(CarnegieAllocationNames.Stock)
  const fixedIncome = filterBy(CarnegieAllocationNames.FixedIncome)
  const alternative = filterBy(CarnegieAllocationNames.Alternative)
  const other = filterBy(CarnegieAllocationNames.Other)
  return (
    <Box>
      <HoldingsSegment
        title={t('Aktier')}
        instruments={stock}
        showPercentage={showPercentage}
        showMoreColumns={showMoreColumns}
        tableSortStorageKey={`ALLOCATION_STOCK`}
      />
      <HoldingsSegment
        title={t('Räntebärande')}
        instruments={fixedIncome}
        showPercentage={showPercentage}
        showMoreColumns={showMoreColumns}
        tableSortStorageKey={`ALLOCATION_FIXED_INCOME`}
      />
      <HoldingsSegment
        title={t('Alternativa')}
        instruments={alternative}
        showPercentage={showPercentage}
        showMoreColumns={showMoreColumns}
        tableSortStorageKey={`ALLOCATION_ALTERNATIVE`}
      />
      <HoldingsSegment
        title={t('Övriga')}
        instruments={other}
        showPercentage={showPercentage}
        showMoreColumns={showMoreColumns}
        tableSortStorageKey={`ALLOCATION_OTHER`}
      />
    </Box>
  )
}
