import { FundOrderType } from '@common/api/response'
import { Guid } from '@common/guid'

import useSwr from 'swr'

import { useApi } from '../stores/store'

export function useValidateFund(carnegieInstrumentId: Guid, side: FundOrderType) {
  const api = useApi()

  const key = `/digital-channels/order/validate/fund/${carnegieInstrumentId}/${side}`

  const { data } = useSwr(carnegieInstrumentId ? key : undefined, async () => {
    return await api.validateFundView(carnegieInstrumentId, side)
  })

  return { fundValidity: data }
}
