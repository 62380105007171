import { SkeletonLoader } from '@common/components/SkeletonLoader'

import { useContentAnalysis } from '../shared/useContentAnalysis'
import { RatingUpdatesListItem } from './RatingUpdatesListItem'

export const RatingUpdates = () => {
  const { researchNews } = useContentAnalysis({
    page: 1,
    limit: 5,
    researchFlags: { isRatingFlag: true },
    includeRelatedInstrument: true,
  })

  return (
    <SkeletonLoader p={16} loading={!researchNews}>
      {() => researchNews.map((rn) => <RatingUpdatesListItem key={rn?.company?.id} researchNews={rn} />)}
    </SkeletonLoader>
  )
}
