import { ResearchNews } from '@common/api/response'
import { CountryCodeFlag } from '@common/components/CountryCodeToFlag'
import { SkeletonLoader } from '@common/components/SkeletonLoader'
import { useOpenDrawer } from '@common/components/drawer/useOpenDrawer'
import { fireTrackEvent } from '@common/utils/analyticsEvent'

import { checkToday } from '@carnegie/digital-channels-frontend'
import { Card, FlexCol, FlexRow, ListItem, ListItemRow, Text } from '@carnegie/duplo'

import { useContentAnalysis } from '../shared/useContentAnalysis'
import { GlobeIcon, ResearchListItemIcon } from './ResearchListItemIcon'

export const TopPickResearch = () => {
  const { researchNews } = useContentAnalysis({
    includeText: true,
    isTopPick: true,
    limit: 3,
    page: 1,
  })

  return (
    <SkeletonLoader p={16} loading={!researchNews}>
      {() => (
        <FlexCol space={16}>
          {researchNews.map((rn) => (
            <TopPickResearchCard key={rn?.company?.id} researchNews={rn} />
          ))}
        </FlexCol>
      )}
    </SkeletonLoader>
  )
}

type TopPickResearchCardProps = {
  researchNews: ResearchNews
}

const TopPickResearchCard = ({ researchNews }: TopPickResearchCardProps) => {
  const { openDrawer } = useOpenDrawer()

  return (
    <Card>
      <ListItem
        onClick={() => {
          fireTrackEvent('Research', 'open_top_pick_research')
          openDrawer('research', researchNews?.researchId?.toString())
        }}
        support={
          <ResearchListItemIcon backgroundColor="carnegie-dark-blue">
            <GlobeIcon />
          </ResearchListItemIcon>
        }
      >
        <ListItemRow
          title={
            <Text variant="overline" color="text-negative" truncate>
              {researchNews?.analysts && researchNews.analysts.length > 0 ? researchNews.analysts[0].name : ''}
            </Text>
          }
          value={
            <Text variant="overline" color="text-low-emphasis">
              {checkToday(new Date(researchNews.created), {
                short: true,
                showTodayAsTime: true,
              })}
            </Text>
          }
        />
        <ListItemRow
          title={
            <FlexRow minWidth={0} alignItems="center" space={4}>
              {researchNews?.marketIso && (
                <CountryCodeFlag size={16} countryCode={researchNews.marketIso.toUpperCase()} />
              )}
              <Text variant="subtitle2" truncate>
                {researchNews?.company?.name}
              </Text>
            </FlexRow>
          }
        />
        <ListItemRow
          title={
            <Text color="text-low-emphasis" variant="label1" truncate>
              {researchNews?.headline}
            </Text>
          }
        />
      </ListItem>
    </Card>
  )
}
