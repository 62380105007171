import { AccountPerformancePeriod } from '../../api/response'

export function mapPeriodToPerformancePeriod(period: string) {
  switch (period) {
    case 'c1WP':
      return AccountPerformancePeriod.OneWeek
    case 'c1MP':
      return AccountPerformancePeriod.OneMonth
    case 'c3MP':
      return AccountPerformancePeriod.ThreeMonth
    case 'c6MP':
      return AccountPerformancePeriod.SixMonth
    case 'ytdP':
      return AccountPerformancePeriod.Ytd
    case 'c12MP':
      return AccountPerformancePeriod.OneYear
    case 'c3YP':
      return AccountPerformancePeriod.ThreeYear
    case 'c5YP':
      return AccountPerformancePeriod.FiveYear
    case 'sinceInceptionP':
      return AccountPerformancePeriod.SinceInception
    default:
      return null
  }
}
