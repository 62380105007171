import { FormattedNumber } from '@common/components/FormattedNumber'
import { Offering } from '@common/hooks/useOfferings'
import { createInstrumentIdContainer } from '@common/instrumentIdContainer'

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { LinkButton, ListItem, ListItemRow, Segment, Text } from '@carnegie/duplo'

import i18n from '@/i18n'

import { useOpenInstrumentDrawer } from '../instrumentDrawer/useOpenInstrumentDrawer'

type OfferInfoProps = {
  offering: Offering | undefined // undefined to show skeleton
}

export const OfferInfo = ({ offering }: OfferInfoProps) => {
  const { t } = useTranslation()
  const { openInstrumentDrawer } = useOpenInstrumentDrawer()
  const {
    instrumentId,
    instrumentName,
    offeringType,
    ratio,
    price,
    currencyCode,
    clearingsPlaceCheckDate,
    lastResponseDate,
    settlementDate,
    description,
  } = offering || {}

  const instrumentIdContainer = instrumentId ? createInstrumentIdContainer(instrumentId) : null

  return (
    <Segment noContentPadding title={t('Erbjudande').toUpperCase()} variant="contained" headingVariant="overline">
      <ListItem divider>
        <ListItemRow
          title={<Text variant="support2">{t('Värdepapper')}</Text>}
          value={
            instrumentIdContainer ? (
              <LinkButton
                skeletonWidth={150}
                skeleton={!offering}
                variant="subtitle2"
                onClick={() => openInstrumentDrawer(instrumentIdContainer)}
              >
                {instrumentName}
              </LinkButton>
            ) : (
              <Text variant="subtitle2" skeleton={!offering} skeletonWidth={150}>
                {instrumentName}
              </Text>
            )
          }
        />
      </ListItem>
      <ListItem divider>
        <ListItemRow
          title={<Text variant="support2">{t('Erbjudandetyp')}</Text>}
          value={
            <Text variant="subtitle2" skeleton={!offering} skeletonWidth={100}>
              {t(offeringType)}
            </Text>
          }
        />
      </ListItem>
      <ListItem divider>
        <ListItemRow
          title={<Text variant="support2">{t('Villkor i korthet')}</Text>}
          value={
            <Text variant="subtitle2" skeleton={!offering} skeletonWidth={100}>
              {offeringType === 'Takeover' ? `${price} ${currencyCode} ${t('per aktie')}` : `${ratio}`}
            </Text>
          }
        />
      </ListItem>
      <ListItem divider isHidden={offeringType !== 'WarrantsExercise'}>
        <ListItemRow
          title={<Text variant="support2">{t('Teckningskurs')}</Text>}
          value={
            <FormattedNumber
              skeleton={!offering}
              skeletonWidth={100}
              variant="subtitle2"
              decimals={2}
              justifyContent="flex-end"
              value={price}
              unit={currencyCode}
            ></FormattedNumber>
          }
        />
      </ListItem>
      <ListItem divider isHidden={offeringType === 'Takeover' || offeringType === 'WarrantsExercise'}>
        <ListItemRow
          title={<Text variant="support2">{t('Avstämningsdag')}</Text>}
          value={
            <Text variant="subtitle2" skeleton={!offering} skeletonWidth={100}>
              {clearingsPlaceCheckDate}
            </Text>
          }
        />
      </ListItem>
      <ListItem divider>
        <ListItemRow
          title={<Text variant="support2">{t('Sista svarsdag')}</Text>}
          value={
            <Text variant="subtitle2" skeleton={!offering} skeletonWidth={100}>
              {lastResponseDate}
            </Text>
          }
        />
      </ListItem>
      <ListItem divider isHidden={offeringType === 'Takeover' || offeringType === 'WarrantsExercise'}>
        <ListItemRow
          title={<Text variant="support2">{t('Likviddag')}</Text>}
          value={
            <Text variant="subtitle2" skeleton={!offering} skeletonWidth={100}>
              {settlementDate}
            </Text>
          }
        />
      </ListItem>
      <ListItem divider>
        {i18n.language === 'sv' ? (
          <Text variant="support2" mb={8} skeleton={!offering}>
            {description}
          </Text>
        ) : null}
        <Text variant="support2">
          {t('Mer information hittar du på bolagets hemsida. För ytterligare frågor är du välkommen att') + ' '}
          <Link to="/profile/contact">{t('kontakta oss')}</Link>.
        </Text>
      </ListItem>
    </Segment>
  )
}
