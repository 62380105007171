import { SortBy } from './sortBy'

export function getSortOrder<TProperty extends string>(sortedBy: SortBy<TProperty>, propertyName: TProperty) {
  if (sortedBy.property === propertyName) {
    if (sortedBy.desc) return 'desc'
    else return 'asc'
  }

  return undefined
}
