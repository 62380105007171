import { useLocation } from 'react-router-dom'

import { Banner, DuploProps, FlexCol } from '@carnegie/duplo'

import { useSystemMessage } from './useSystemMessages'

export default function SystemMessageList({ segmentId, ...rest }: { segmentId?: string } & DuploProps) {
  const location = useLocation()
  const routeOrSegmentId = segmentId ?? location.pathname.slice(1) // Remove leading slash
  const { messages = [], onClose } = useSystemMessage(routeOrSegmentId)

  if (messages?.length === 0) return null

  return (
    <FlexCol gap={8} {...rest}>
      {messages?.map((message) => (
        <Banner
          key={message.id}
          severity={message.severity}
          title={message.subject}
          description={message.message}
          onClose={() => onClose(message.id)}
        />
      ))}
    </FlexCol>
  )
}
