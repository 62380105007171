import { AccountsResponse, CustomerReportModel } from '@common/api/response'
import { fireTrackEvent } from '@common/utils/analyticsEvent'

import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useFormatDate } from '@carnegie/digital-channels-frontend'
import { ButtonBox, FlexRow, Icon, IconDocumentLineChart, LinkButton, LinkExternal, Text } from '@carnegie/duplo'

import { CustomerReportColumnId } from './CustomerReports'

type CustomerReportRowProps = {
  report: CustomerReportModel
  selectedAccount: AccountsResponse
  cell: CustomerReportColumnId
}

export const CustomerReportRow = ({ report, selectedAccount, cell }: CustomerReportRowProps) => {
  const navigate = useNavigate()
  const { formatDate } = useFormatDate()
  const { t } = useTranslation()

  const reportDescription = report?.description?.replaceAll('+', '_')

  type CellComponents = {
    [K in CustomerReportColumnId]: React.JSX.Element
  }

  const cellComponents = useMemo<CellComponents>(
    () => ({
      description: (
        <ButtonBox
          onClick={() => {
            fireTrackEvent('Reports', 'file_download_report')
          }}
          minWidth={0}
        >
          <LinkExternal
            variant="body2"
            href={`/api/reports/customer-reports/download-file/${report?.serviceFileType}/${report?.documentId ?? ''}`}
            rel="noreferrer"
            target="_blank"
            download={reportDescription}
            minWidth={0}
            truncate
          >
            <FlexRow alignItems="center">
              <Icon size={16} color="current" mr={4} icon={IconDocumentLineChart} />
              <Text variant="body2" truncate>
                {reportDescription?.replaceAll('_', ' ')}
              </Text>
            </FlexRow>
          </LinkExternal>
        </ButtonBox>
      ),

      documentTypeName: (
        <Text variant="body2" truncate>
          {t(report?.documentTypeName.toString())}
        </Text>
      ),

      accountNumber: (
        <LinkButton
          variant="body2"
          minWidth={0}
          onClick={() => {
            navigate(`/accounts/${selectedAccount?.id}?returnroute=/profile/reports/archive/${selectedAccount?.id}`)
          }}
        >
          {selectedAccount?.number}
        </LinkButton>
      ),

      createDate: (
        <Text variant="body2">{report?.createDate && formatDate(report.createDate, 'HH:mm/yyyy-MM-dd')}</Text>
      ),
    }),
    [report, selectedAccount, navigate, formatDate, t, reportDescription]
  )

  return cellComponents[cell]
}
