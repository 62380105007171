import { AccountDividend, AccountSummary } from '@common/api/response'
import { CurrencyFlag } from '@common/components/CurrencyToFlag'
import { FormattedNumber } from '@common/components/FormattedNumber'
import { useAccruedCouponInterest } from '@common/hooks/useAccountDividends'
import { convertToInstrumentIdContainer } from '@common/instrumentIdContainer'
import { SortBy, SortByProperty } from '@common/stores/accountDividendsStore'
import { sum } from '@common/utils/sum'

import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { formatNumber } from '@carnegie/digital-channels-frontend'
import {
  Box,
  Breakpoint,
  DataCell,
  FlexCol,
  FlexRow,
  IconInfoOutlined,
  LinkButton,
  Paragraph,
  SideDrawer,
  TBody,
  TFoot,
  THead,
  Table,
  Td,
  Text,
  Th,
  Tooltip,
  Tr,
  useBreakpoint,
} from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import { useOpenInstrumentDrawer } from '../../../../drawers/instrumentDrawer/useOpenInstrumentDrawer'
import { getSortOrder } from '../../holdingsTable/getSortOrder'

type Props = {
  summary: AccountSummary
  accountId: string
}

export const AccruedCoupon = observer(({ summary, accountId }: Props) => {
  const { t } = useTranslation()
  const [openAccruedCouponSlide, setOpenAccruedCouponSlide] = useState(false)
  const breakpoint = useBreakpoint()
  const isExtraSmallScreen = breakpoint < Breakpoint.Small
  const isTablet = breakpoint < Breakpoint.Large

  const { dividends, sortedBy, sort } = useAccruedCouponInterest(accountId)

  return (
    <FlexCol flex={1} mr={isExtraSmallScreen ? 0 : 16}>
      <DataCell
        onClick={() => setOpenAccruedCouponSlide(true)}
        icon={IconInfoOutlined}
        label={isTablet ? t('Uppl. räntekupong') : t('Upplupen räntekupong')}
        suffix={summary.accruedCoupon?.currencyCode}
      >
        {formatNumber(summary.accruedCoupon?.amount, { decimals: 0 })}
      </DataCell>
      <SideDrawer
        title={t('Upplupen räntekupong')}
        sidebarContentPadding={0}
        variant="slide"
        sidebarContent={
          <Content
            dividends={dividends}
            sortedBy={sortedBy}
            sort={sort}
            closeSideDrawer={() => setOpenAccruedCouponSlide(false)}
          ></Content>
        }
        open={openAccruedCouponSlide}
        onClose={() => setOpenAccruedCouponSlide(false)}
      />
    </FlexCol>
  )
})

type ContentProps = {
  dividends: AccountDividend[]
  sortedBy: SortBy<SortByProperty>
  sort: (property: SortByProperty, desc: boolean) => void
  closeSideDrawer: () => void
}

const Content = observer(({ dividends, sortedBy, sort, closeSideDrawer }: ContentProps) => {
  const { t } = useTranslation()
  const { openInstrumentDrawer } = useOpenInstrumentDrawer()
  const currentCurrency = dividends?.[0]?.account.currencyCode

  if (!dividends || dividends?.length < 1)
    return (
      <Box pt={16}>
        <Text pl={16} variant="body2">
          {t('Det finns ingen upplupen räntekupong.')}
        </Text>
      </Box>
    )

  return (
    <Box pt={16}>
      <Text pl={16} variant="subtitle2">
        {t('Räntekupong utbetalt i ') + currentCurrency.toUpperCase()}
      </Text>
      <Table mt={8} css={{ tableLayout: 'fixed' }}>
        <THead>
          <Tr>
            <Th
              onClick={() => sort('name', !sortedBy.desc)}
              textAlign="left"
              sortOrder={getSortOrder(sortedBy, 'name')}
              sortable={true}
              width={'65%'}
            >
              {t('Namn')}
            </Th>
            <Th
              onClick={() => sort('amount', !sortedBy.desc)}
              textAlign="right"
              sortOrder={getSortOrder(sortedBy, 'amount')}
              sortable={true}
              width={'35%'}
            >
              {t('Räntekupong')}
            </Th>
          </Tr>
        </THead>
        <TBody>
          {dividends.map((dividend: AccountDividend) => {
            const instrument = convertToInstrumentIdContainer(dividend.infrontInstrument)
            return (
              <Tr key={dividend?.instrumentId}>
                <Td>
                  <FlexRow alignItems="center">
                    <Box pr={4} flexShrink={0}>
                      <CurrencyFlag currency={dividend.marketIso} size={16} />
                    </Box>
                    <Tooltip title={dividend.instrumentName}>
                      <LinkButton
                        variant="body2"
                        truncate
                        minWidth={0}
                        onClick={() => {
                          closeSideDrawer()
                          openInstrumentDrawer(instrument)
                        }}
                      >
                        <Paragraph variant="body2" truncate css={{ cursor: 'pointer' }}>
                          {dividend.instrumentName}
                        </Paragraph>
                      </LinkButton>
                    </Tooltip>
                  </FlexRow>
                </Td>

                <Td textAlign="right">
                  {dividend.account.currencyCode !== dividend.original.currencyCode ? (
                    <Tooltip
                      title={`${formatNumber(dividend.original.amount, { decimals: 0 })} ${
                        dividend.original.currencyCode
                      }`}
                    >
                      <FormattedNumber
                        decimals={0}
                        justifyContent="flex-end"
                        value={dividend.account.amount}
                        unit={dividend.account.currencyCode}
                      ></FormattedNumber>
                    </Tooltip>
                  ) : (
                    <FormattedNumber
                      decimals={0}
                      justifyContent="flex-end"
                      value={dividend.account.amount}
                      unit={dividend.account.currencyCode}
                    ></FormattedNumber>
                  )}
                </Td>
              </Tr>
            )
          })}
        </TBody>
        <TFoot>
          <Tr>
            <Td textAlign="left" css={{ fontWeight: '500' }}>
              {t('Total')}
            </Td>
            <Td textAlign="right">
              <FormattedNumber
                decimals={0}
                justifyContent="flex-end"
                unit="SEK"
                bold={true}
                value={sum('amount', dividends ? dividends.map((x) => x.account) : [])}
              />
            </Td>
          </Tr>
        </TFoot>
      </Table>
    </Box>
  )
})
