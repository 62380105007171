import { isDate } from 'date-fns'
import { makeAutoObservable } from 'mobx'

import { useSessionStorage } from '../stores/store'
import { WebStorage } from '../stores/webStore'
import { arraySort } from '../utils/arraySort'
import { numberOfPages, paginate } from '../utils/paginate'
import { useLocalStore, useSyncPropsToStore } from './useLocalStore'

export type SortOrder = 'asc' | 'desc'

type TableOptions<TColumnId> = {
  defaultSortBy?: TColumnId
  sessionStorageKey?: string
  pageSize?: number
  defaultPage?: number
}

class TableStore<TRow extends { [key: string]: unknown }, TColumnId extends string> {
  constructor(private storage: WebStorage, columns: TableColumn<TColumnId, TRow>[], options: TableOptions<TColumnId>) {
    makeAutoObservable<TableStore<TRow, TColumnId>, 'originalData'>(this, { originalData: false })
    this.init(columns, options)
  }

  rows: TRow[] = []

  // We need to keep this data around because of pagination
  private originalData: TRow[] = []
  sortedBy: TColumnId | undefined = undefined
  sortOrder: SortOrder // asc = top to bottom, desc = bottom to top
  private columns: TableColumn<TColumnId, TRow>[] = []
  headers: TableHeader<TColumnId>[] = []
  options: TableOptions<TColumnId>
  numberOfPages = 1
  currentPage = 1

  getHeaderById = (id: TColumnId) => {
    return this.headers.find((h) => h.id === id)
  }

  setCurrentPage = (pageNr: number) => {
    if (pageNr && this.currentPage !== pageNr) {
      this.currentPage = pageNr
      this.resort()
    }
  }

  resort = () => {
    this.sort(this.sortedBy, this.sortOrder)
  }

  sort = (columnId: TColumnId, sortOrder: SortOrder) => {
    this.sortedBy = columnId
    this.sortOrder = sortOrder

    // Here we use original data as a source since we might paginate and don't want to loose data
    this.rows = arraySort(
      this.originalData.slice(),
      (row) => {
        // Get custom sort from the column data if one exists
        const matchingColumn = this.columns.find((col) => col.id === columnId)

        if (!matchingColumn) {
          console.error(
            `No matching column with id: ${columnId.toString()} found, this can occur if you are conditionally changing the column definitions array, use the visible property instead to control which columns are shown.`
          )
          return undefined
        }

        if (matchingColumn && matchingColumn.sortBy) {
          const sortValue = matchingColumn.sortBy({ row: row })

          // Make sure sortValue is set since null is also considered an object
          if (sortValue && typeof sortValue === 'object' && !isDate(sortValue))
            throw new Error(
              'The property named: ' +
                columnId.toString() +
                ` has a value of type object (with value: ${sortValue}), you need to resolve this value using a custom sort function (defined in the columns array)`
            )

          return sortValue
        } else {
          return undefined
        }
      },
      sortOrder === 'desc' ? true : false
    )

    // Since the active column could have change we must refresh all headers to reflect this
    this.refreshHeadersSortOrder()

    // Use pagination?
    if (this.options.pageSize) {
      this.numberOfPages = numberOfPages(this.originalData.length, this.options.pageSize)

      // This can happen if a new list of data is supplied that makes the number of pages decrease
      if (this.currentPage > this.numberOfPages) {
        this.currentPage = 1
      }

      this.rows = paginate(this.rows, this.options.pageSize, this.currentPage ?? 1)
    }
  }

  private saveSortSettings = () => {
    this.storage.setItem(this.options.sessionStorageKey.toUpperCase() + '_SORT_BY', this.sortedBy.toString(), 'user')
    this.storage.setItem(this.options.sessionStorageKey.toUpperCase() + '_SORT_ORDER', this.sortOrder, 'user')
  }

  // This is a dev feature that warns us if we are re-rendering too much probably by mistake
  private initializeCount = 0

  private init = (columns: TableColumn<TColumnId, TRow>[], options: TableOptions<TColumnId>) => {
    if (!columns || columns.length === 0) throw new Error("You haven't defined any columns")

    const defaultSortBy = options.defaultSortBy ? options.defaultSortBy : columns[0].id
    let defaultSortByColumn = columns.find((column) => column.id === defaultSortBy)

    if (!defaultSortByColumn) {
      defaultSortByColumn = columns[0]
    }

    const defaultSortOrder = defaultSortByColumn.defaultSortOrder ? defaultSortByColumn.defaultSortOrder : 'asc'

    // When it comes to sort order and sort by we only set that the first time since
    // if the user want to re-initialize by changing data or deps we don't want to loose the current sort order
    // If session storage is enabled we default sort by it instead
    if (options?.sessionStorageKey) {
      const savedSortBy = this.storage.getItem<TColumnId>(options.sessionStorageKey.toUpperCase() + '_SORT_BY', 'user')
      const savedSortOrder = this.storage.getItem<SortOrder>(
        options.sessionStorageKey.toUpperCase() + '_SORT_ORDER',
        'user'
      )

      this.sortedBy = savedSortBy ? savedSortBy : defaultSortBy
      this.sortOrder = savedSortOrder ? savedSortOrder : defaultSortOrder
    } else {
      // Default when no session storage has been enabled
      this.sortedBy = defaultSortBy
      this.sortOrder = defaultSortOrder
    }

    if (options?.defaultPage) {
      this.currentPage = options.defaultPage
    }

    this.columns = columns

    this.options = options

    // A header is very similair to a column but designed to return props that can be spread on an actual <th> element
    // It will also contain the current direction of the sort order (arrow)
    this.headers = this.columns.map((col) => {
      return {
        onClick: () => {
          const isActiveHeader = this.sortedBy === col.id

          if (isActiveHeader) {
            this.sort(col.id, this.sortOrder === 'asc' ? 'desc' : 'asc')
          } else {
            this.sort(col.id, col.defaultSortOrder)
          }

          if (this.options.sessionStorageKey) this.saveSortSettings()
        },
        id: col.id,
        // This will only be set for the active column and changed for all headers when calling this.sort
        sortOrder: undefined,
      }
    })

    this.refreshHeadersSortOrder()
    this.resort()

    if (process.env.NODE_ENV === 'development') {
      this.initializeCount++
    }

    // If we pass in a brand new data object every render we might get loops that re-initialize the table a lot
    // this will warn us if we have re-initialized a table over 500 times, could be modified in the future
    // to take renders per second etc into account also
    if (process.env.NODE_ENV === 'development' && this.initializeCount > 500) {
      throw new Error(
        'You are re-initializing this table a lot (over 500 times) you have probably forgot the memoize your data or are passing a deps array that causes too frequent re-initializations'
      )
    }
  }

  setData = (data: TRow[]) => {
    this.rows = []
    this.originalData = data

    this.resort()
  }

  // Refresh the sort order (arrow) direction of all headers
  // Must be done once initially and then everytime we resort the table
  private refreshHeadersSortOrder = () => {
    for (const header of this.headers) {
      if (header.id === this.sortedBy) {
        header.sortOrder = this.sortOrder
      } else {
        header.sortOrder = undefined
      }
    }
  }
}

// Column definitions
type TableColumn<TColumnId extends string, TRow extends { [key: string]: unknown }> = {
  id: TColumnId
  sortBy: (props: { row: TRow }) => string | number | boolean | Date
  defaultSortOrder?: SortOrder
}

// Props to use when rendering headers (not same as column definitions but very similar)
type TableHeader<TColumnId> = {
  id: TColumnId
  onClick: () => void
  sortOrder?: SortOrder // So the user can position sorting arrows etc, pro tip: (sortOrder === 'asc' ? <UpArrow /> : <DownArrow />)
}

/**
 * ## ⚠️IMPORTANT⚠️
 * ### Requires wrapping component in MobX `observer(...)`
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useTable<TColumnId extends string, TRow extends { [key: string]: any }>(
  columns: TableColumn<TColumnId, TRow>[],
  data: TRow[],
  options: TableOptions<TColumnId> = { sessionStorageKey: undefined, defaultPage: 1 }
) {
  const sessionStorage = useSessionStorage()
  const columnsJson = JSON.stringify(columns)

  const tableStore = useLocalStore(
    () => new TableStore<TRow, TColumnId>(sessionStorage, columns, options),
    // When one of these deps changes a new table store gets created
    [columnsJson, options?.defaultSortBy, options?.pageSize, options?.sessionStorageKey]
  )

  // Will only do something if data !== oldData
  // This is like a useEffect but happens during the same render
  // which makes sure we don't see an empty table when loading in new data
  useSyncPropsToStore(() => {
    tableStore.setData(data)
  }, [tableStore, data])

  return {
    headers: tableStore.headers,
    getHeaderById: tableStore.getHeaderById,
    rows: tableStore.rows,
    sortedBy: tableStore.sortedBy,
    sort: tableStore.sort,
    sortOrder: tableStore.sortOrder,
    numberOfPages: tableStore.numberOfPages,
    currentPage: tableStore.currentPage,
    setCurrentPage: tableStore.setCurrentPage,
    resort: tableStore.resort,
  }
}
