import { BuySellMenuItem, PlaceFundOrderMenuItem, TransactionMenuItem } from '@common/components/ActionButton'
import { FormattedNumber, FormattedPercentage } from '@common/components/FormattedNumber'
import { MoreMenuWatchlist } from '@common/components/MoreMenu'
import { ExpandTableButton, ExpandableTr } from '@common/components/SmartTable'
import { fireTrackEvent } from '@common/utils/analyticsEvent'
import { hasValue } from '@common/utils/hasValue'

import { useState } from 'react'

import { formatNumber } from '@carnegie/digital-channels-frontend'
import { Box, Paragraph, Td, Text, Tooltip } from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import { useOpenInstrumentDrawer } from '../../../../drawers/instrumentDrawer/useOpenInstrumentDrawer'
import { useAccountHoldingsSideDrawer } from '../../accountHoldings/useAccountHoldingsSideDrawer'
import { InstrumentName } from '../InstrumentName'
import { UnderlyingAssetsRows } from '../UnderlyingAssetsRows'
import { ObservableTableInstrument } from '../useTableInstruments'
import { TableOverviewColumnId } from './TableOverview'

type TableOverviewRowProps = {
  instrument: ObservableTableInstrument
  accountCurrencyCode: string
  showPercentage: boolean
  transactionOnClick?: () => void
  isFund: boolean
  showMoreColumns: boolean
  columns: { id?: TableOverviewColumnId }[]
}

export const TableOverviewRow = observer(function TableOverviewRow({
  instrument,
  accountCurrencyCode,
  showPercentage,
  transactionOnClick,
  isFund,
  showMoreColumns,
  columns,
}: TableOverviewRowProps) {
  const { openInstrumentDrawer } = useOpenInstrumentDrawer()

  const {
    acquisitionCost,
    acquisitionCostOriginal,
    averageAcquisitionPrice,
    averageAcquisitionPriceOriginal,
    carnegieInstrumentGroup,
    currencyCode,
    instrumentPriceDateTime,
    isoCountryCode,
    lastPrice,
    marketIso,
    marketValue,
    marketValueOriginal,
    name,
    priceChangeToday,
    priceChangeTodayOriginal,
    priceChangeTodayRatio,
    priceChangeOneDayRatio,
    priceChangeOneDay,
    priceChangeOneDayOriginal,
    unrealized,
    unrealizedRatio,
    quantity,
  } = instrument

  const [expanded, setExpanded] = useState(false)

  const showFlag = carnegieInstrumentGroup !== 'MutualFunds'

  const _priceChangeHasValue = hasValue(isFund ? priceChangeOneDayOriginal : priceChangeTodayOriginal)
  const _priceChangeValue = isFund
    ? `${formatNumber(priceChangeOneDayOriginal, { decimals: 0 })} ${currencyCode}`
    : `${formatNumber(priceChangeTodayOriginal, { decimals: 0 })} ${currencyCode}`

  const showTooltipInInstrumentCurrency = currencyCode && accountCurrencyCode !== currencyCode

  const priceChangeOneDayChangeTodayTooltip = _priceChangeHasValue ? _priceChangeValue : undefined

  const marketValueOriginalTooltip =
    showTooltipInInstrumentCurrency && marketValueOriginal
      ? `${formatNumber(marketValueOriginal, { decimals: 0 })} ${currencyCode}`
      : undefined

  const averageAcquisitionPriceOriginalTooltip =
    showTooltipInInstrumentCurrency && averageAcquisitionPriceOriginal?.amount
      ? `${formatNumber(averageAcquisitionPriceOriginal?.amount, { decimals: 2 })} ${currencyCode}`
      : undefined

  const acquisitionCostOriginalTooltip =
    showTooltipInInstrumentCurrency && acquisitionCostOriginal?.amount
      ? `${formatNumber(acquisitionCostOriginal?.amount, { decimals: 0 })} ${currencyCode}`
      : undefined

  /* If the date is today, we show just time (hh:mm:ss),
   * if the date is not today, we show just the date (DD.MM.YYYY).
   */
  const timeOrDate =
    instrumentPriceDateTime && InfrontUtil.formatStandardTimestamp(new Date(instrumentPriceDateTime), true)

  const hasExpanderColumn = columns.some((columnDef) => columnDef.id === 'expander-button')

  const { openSideDrawer } = useAccountHoldingsSideDrawer()

  return (
    <>
      <ExpandableTr expanded={expanded}>
        {/* For expander columns */}
        {hasExpanderColumn && (
          <Td>
            {instrument.hasDetails && (
              <ExpandTableButton
                expanded={expanded}
                onClick={() => {
                  if (!expanded) {
                    fireTrackEvent('Holdings', 'expand_underlying_fundholding')
                  }

                  setExpanded(!expanded)
                }}
              />
            )}
          </Td>
        )}
        <Td>
          <InstrumentName
            showFlag={showFlag}
            name={name}
            reservedQuantities={instrument.reservedQuantities}
            isoCountryCode={isoCountryCode ? isoCountryCode : marketIso}
            showInfoButton={instrument.hasDetails}
            onInfoButtonClick={() => {
              fireTrackEvent('Holdings', 'click_showmore_underlying_fundholding')
              openSideDrawer()
            }}
            onClick={() => {
              openInstrumentDrawer(instrument)
            }}
          />
        </Td>
        <Td align="right">
          <Paragraph variant="body2">{formatNumber(quantity, { decimals: { min: 0, max: 10 } })}</Paragraph>
        </Td>
        {showMoreColumns && (
          <Td>
            {_priceChangeHasValue && showTooltipInInstrumentCurrency ? (
              <Tooltip
                placement="bottom-end"
                title={
                  <Text variant="support2" color="off-white">
                    {priceChangeOneDayChangeTodayTooltip}
                  </Text>
                }
              >
                <FormattedNumber
                  decimals={0}
                  justifyContent="flex-end"
                  value={isFund ? priceChangeOneDay : priceChangeToday}
                  unit={accountCurrencyCode}
                  colorized
                />
              </Tooltip>
            ) : (
              <FormattedNumber
                decimals={0}
                justifyContent="flex-end"
                value={isFund ? priceChangeOneDay : priceChangeToday}
                unit={accountCurrencyCode}
                colorized
              />
            )}
          </Td>
        )}
        <Td>
          {showPercentage ? (
            <FormattedPercentage
              value={isFund ? priceChangeOneDayRatio : priceChangeTodayRatio}
              ratio
              decimals={2}
              justifyContent={'flex-end'}
              colorized
            />
          ) : _priceChangeHasValue && showTooltipInInstrumentCurrency ? (
            <Tooltip
              placement="bottom-end"
              title={
                <Text variant="support2" color="off-white">
                  {priceChangeOneDayChangeTodayTooltip}
                </Text>
              }
            >
              <FormattedNumber
                decimals={0}
                justifyContent="flex-end"
                value={isFund ? priceChangeOneDay : priceChangeToday}
                unit={accountCurrencyCode}
                colorized
              />
            </Tooltip>
          ) : (
            <FormattedNumber
              decimals={0}
              justifyContent="flex-end"
              value={isFund ? priceChangeOneDay : priceChangeToday}
              unit={accountCurrencyCode}
              colorized
            />
          )}
        </Td>
        <Td>
          <Tooltip placement="bottom-end" title={timeOrDate}>
            <FormattedNumber justifyContent="flex-end" value={lastPrice} decimals={2} unit={currencyCode} />
          </Tooltip>
        </Td>
        <Td>
          {showTooltipInInstrumentCurrency && marketValueOriginalTooltip ? (
            <Tooltip
              placement="bottom-end"
              title={
                <Text variant="support2" color="off-white">
                  {marketValueOriginalTooltip}
                </Text>
              }
            >
              <FormattedNumber decimals={0} justifyContent="flex-end" unit="SEK" value={marketValue} />
            </Tooltip>
          ) : (
            <FormattedNumber decimals={0} justifyContent="flex-end" unit="SEK" value={marketValue} />
          )}
        </Td>
        {showMoreColumns && (
          <Td>{<FormattedNumber decimals={0} justifyContent="flex-end" unit="SEK" value={unrealized} colorized />}</Td>
        )}
        <Td>
          {showPercentage ? (
            <FormattedPercentage value={unrealizedRatio} ratio decimals={2} justifyContent={'flex-end'} colorized />
          ) : (
            <FormattedNumber decimals={0} justifyContent="flex-end" unit="SEK" value={unrealized} colorized />
          )}
        </Td>
        {showMoreColumns && (
          <Td>
            {showTooltipInInstrumentCurrency && averageAcquisitionPriceOriginalTooltip ? (
              <Tooltip
                placement="bottom-end"
                title={
                  <Text variant="support2" color="off-white">
                    {averageAcquisitionPriceOriginalTooltip}
                  </Text>
                }
              >
                <FormattedNumber
                  justifyContent="flex-end"
                  value={averageAcquisitionPrice?.amount}
                  unit={averageAcquisitionPrice?.currencyCode}
                  decimals={2}
                />
              </Tooltip>
            ) : (
              <FormattedNumber
                justifyContent="flex-end"
                value={averageAcquisitionPrice?.amount}
                unit={averageAcquisitionPrice?.currencyCode}
                decimals={2}
              />
            )}
          </Td>
        )}
        {showMoreColumns && (
          <Td>
            {showTooltipInInstrumentCurrency && acquisitionCostOriginalTooltip ? (
              <Tooltip
                placement="bottom-end"
                title={
                  <Text variant="support2" color="off-white">
                    {acquisitionCostOriginalTooltip}
                  </Text>
                }
              >
                <FormattedNumber
                  decimals={0}
                  justifyContent="flex-end"
                  value={acquisitionCost?.amount}
                  unit={acquisitionCost?.currencyCode}
                />
              </Tooltip>
            ) : (
              <FormattedNumber
                decimals={0}
                justifyContent="flex-end"
                value={acquisitionCost?.amount}
                unit={acquisitionCost?.currencyCode}
              />
            )}
          </Td>
        )}
        <Td>
          <Box>
            <MoreMenuWatchlist instrumentIdContainer={instrument}>
              {isFund ? (
                <>
                  <PlaceFundOrderMenuItem instrumentIdContainer={instrument} fundOrderType={'buy'} />
                  <PlaceFundOrderMenuItem instrumentIdContainer={instrument} fundOrderType={'sell'} />
                </>
              ) : (
                <BuySellMenuItem instrumentIdContainer={instrument} />
              )}
              <TransactionMenuItem onClick={transactionOnClick} />
            </MoreMenuWatchlist>
          </Box>
        </Td>
      </ExpandableTr>
      {expanded && <UnderlyingAssetsRows instrument={instrument} columns={columns} />}
    </>
  )
})

TableOverviewRow.displayName = 'TableOverviewRow'
