import { useApi } from '@common/stores/store'

import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { DuploColor, Icon, IconArrowRightDown, IconArrowRightUp } from '@carnegie/duplo'

import useSWR from 'swr'

import TargetSvg from './target.svg'

export function useTargetAndRecommendations(instrumentId) {
  const api = useApi()

  const { data, error, isValidating } = useSWR(
    instrumentId
      ? `/digital-channels/content/analysis/target-recommendations?instrumentIds=${instrumentId}`
      : undefined,
    async () => await api.fetchTargetAndRecommendations(instrumentId)
  )

  return {
    error,
    targetAndRecommendations: data?.items?.[0],
    isValidating,
  }
}

export function useResearchTargetItems(arrowDirection: string) {
  const { t } = useTranslation()

  let arrowIcon: ReactNode = null
  let iconBg: DuploColor = 'carnegie-dark-blue'
  let iconTextColor: DuploColor = 'text-light'
  let changeText = '-'

  switch (arrowDirection.toUpperCase()) {
    case 'UP':
      iconBg = 'carnegie-dark-blue'
      arrowIcon = <Icon size={32} color="off-white" icon={IconArrowRightUp} />
      changeText = t('Höjd riktkurs')
      break
    case 'DOWN':
      iconBg = 'paprika-main'
      arrowIcon = <Icon size={32} color="off-white" icon={IconArrowRightDown} />
      changeText = t('Sänkt riktkurs')
      break
    case 'UNKNOWN':
      iconBg = 'carnegie-pink'
      iconTextColor = 'text-low-emphasis'
      arrowIcon = <TargetSvg />
      changeText = '-'
      break
  }

  return {
    arrowIcon,
    iconBg,
    iconTextColor,
    changeText,
  }
}
