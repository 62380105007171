import { useDrawerProps } from '@common/components/drawer/useDrawerStore'
import { useOpenDrawer } from '@common/components/drawer/useOpenDrawer'
import { useInstrumentIdContainerFromUrlSegment } from '@common/hooks/useInstrumentIdContainerFromUrlSegment'
import { InstrumentIdContainer, createValidInstrumentIdContainer } from '@common/instrumentIdContainer'
import { convertInstrumentIdContainerToUrl } from '@common/utils/convertInstrumentIdContainerToFromUrl'

export function useOpenOrderDrawer() {
  const { openDrawer } = useOpenDrawer()

  const openOrderDrawer = (
    instrumentIdContainer: InstrumentIdContainer,
    initialSelectedAccountId?: string,
    initialOrderId?: number
  ) => {
    const routeProps = convertInstrumentIdContainerToUrl(createValidInstrumentIdContainer(instrumentIdContainer))

    openDrawer(
      'order',
      routeProps,
      // Not saved in url
      { initialSelectedAccountId: initialSelectedAccountId, initialOrderId: initialOrderId }
    )
  }

  return { openOrderDrawer }
}

export function useOrderDrawerProps() {
  const drawerProps = useDrawerProps<{ initialSelectedAccountId?: string; initialOrderId?: number }>()

  const { instrumentIdContainer } = useInstrumentIdContainerFromUrlSegment(drawerProps.routeProps)

  return { instrumentIdContainer, ...drawerProps.props }
}
