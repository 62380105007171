import { useLocalStore } from '@common/hooks/useLocalStore'

import { useEffect, useState } from 'react'

import { add, addMonths, getDaysInMonth, sub } from 'date-fns'
import { makeAutoObservable, runInAction } from 'mobx'

import { arraySort } from '../../../utils/arraySort'
import { useInfrontSDK } from './useInfrontSdk'

export type CustomOptions = {
  source?: InfrontSDK.FinancialCalendarOptions['source']
  desc?: boolean
  limit?: number
}

// Note: month is zero based like always in JS
type YearMonth = { year: number; month: number }

class InfrontFinancialCalendarWithPreloadStore {
  private infrontSDK: InfrontSDK.SDK

  // Stack (LIFO) of year + month to load
  private monthsStack: YearMonth[] = []
  private processingMonthsStack = false
  private subscriptions: InfrontSDK.Unsubscribe[] = []
  private disposed = false

  // Key is "year-month"
  calendarEvents = new Map<string, InfrontSDK.CalendarEvent[]>()
  preloadedCalendarEvents: InfrontSDK.CalendarEvent[] = []

  constructor(infrontSDK: InfrontSDK.SDK, source: FinancialCalendarSource) {
    makeAutoObservable(this)
    this.infrontSDK = infrontSDK
    this.source = source
  }

  private source: FinancialCalendarSource = 'SE'

  isCalendarDataLoaded = (year: number, month: number) => {
    return this.calendarEvents.has(year + '-' + month)
  }

  loadCalendarData = (year: number, month: number, preload6Months: boolean) => {
    // Never add to queue if already in it or if it has been loaded before
    if (!this.isCalendarDataLoaded(year, month)) {
      if (preload6Months) {
        for (let i = 1; i <= 6; i++) {
          // Add +1 and -1
          const plusOneMonthDate = addMonths(new Date(year, month, 1), i)
          const minusOneMonthDate = addMonths(new Date(year, month, 1), -i)

          const plusOneMonth: YearMonth = {
            year: plusOneMonthDate.getFullYear(),
            month: plusOneMonthDate.getMonth(),
          }

          const minusOneMonth: YearMonth = {
            year: minusOneMonthDate.getFullYear(),
            month: minusOneMonthDate.getMonth(),
          }

          // -1
          this.monthsStack.push({ year: minusOneMonth.year, month: minusOneMonth.month })

          // +1
          this.monthsStack.push({ year: plusOneMonth.year, month: plusOneMonth.month })
        }
      }

      // This will be given the highest priority
      this.monthsStack.push({ year: year, month: month })

      if (!this.processingMonthsStack) {
        this.processMonthsStack()
      }
    }
  }

  private processMonthsStack = () => {
    if (this.monthsStack.length > 0) {
      this.processingMonthsStack = true

      // Get last item off queue
      const yearMonthToLoad = this.monthsStack.pop()

      if (!this.calendarEvents.has(yearMonthToLoad.year + '-' + yearMonthToLoad.month)) {
        this.loadInfrontFinancialCalendarData(yearMonthToLoad.year, yearMonthToLoad.month, () => {
          // Process next on queue
          this.processMonthsStack()
        })
      } else {
        // Process next on queue
        this.processMonthsStack()
      }
    } else {
      this.processingMonthsStack = false
    }
  }

  // Does the actual loading from infront
  private loadInfrontFinancialCalendarData = (year: number, month: number, onDataLoaded: () => void) => {
    const infrontSDK = this.infrontSDK

    const startDate = sub(new Date(year, month, 1), { days: 7 })
    const endDate = add(new Date(year, month, getDaysInMonth(startDate)), { days: 7 })

    const options: InfrontSDK.FinancialCalendarOptions = {
      source: this.source,
      from: startDate,
      to: endDate,
      subscribe: false,
      limit: 50000,
      onData: (calendarEvents) => {
        // *** Got the data ***

        if (this.disposed) {
          // Component got unmounted etc so do nothing with data
          return
        }

        runInAction(() => {
          this.calendarEvents.set(year + '-' + month, calendarEvents)
          this.preloadedCalendarEvents.push(...calendarEvents)
          onDataLoaded()
        })
      },
    }

    // Returns dispose method

    // *** Workaround for strange Infront behavior:
    // When passing an empty array as source Infront will interpret this as including the default source (SE etc) instead of returning
    // an empty result, so to fix this we simply do not get any data for this case
    if (Array.isArray(options.source) && options.source.length === 0) {
      this.calendarEvents.set(year + '-' + month, [])
      onDataLoaded()
    } else {
      const cleanup = infrontSDK.get(InfrontSDK.financialCalendar(options))
      this.subscriptions.push(cleanup)
    }
  }

  dispose = () => {
    this.subscriptions.forEach((x) => x())
    this.disposed = true
  }
}

export type FinancialCalendarSource =
  | InfrontSDK.SymbolId
  | InfrontSDK.SymbolId[]
  | number
  | number[]
  | string
  | string[]

/**
 * ## ⚠️IMPORTANT⚠️
 * ### Requires wrapping component in MobX `observer(...)`
 */
export function useInfrontFinancialCalendarWithPreload(year: number, month: number, source: FinancialCalendarSource) {
  const { infrontSDK } = useInfrontSDK()

  // Takes care of cleaning up when changing stores or unmounting
  const calendarStore = useLocalStore(
    () => (infrontSDK ? new InfrontFinancialCalendarWithPreloadStore(infrontSDK, source) : undefined),
    [infrontSDK, JSON.stringify(source)],
    (store) => store.dispose()
  )

  // Load data
  useEffect(() => {
    if (calendarStore) {
      calendarStore.loadCalendarData(year, month, true)
    }
  }, [year, month, calendarStore])

  return {
    calendarEvents: calendarStore ? calendarStore.calendarEvents.get(year + '-' + month) : [],
    loading: calendarStore ? !calendarStore.isCalendarDataLoaded(year, month) : false,
    preloadedCalendarEvents: calendarStore ? calendarStore.preloadedCalendarEvents : [],
  }
}

/**
 * ## ⚠️IMPORTANT⚠️
 * ### Requires wrapping component in MobX `observer(...)`
 */
export function useInfrontFinancialCalendar(options: {
  customOptions?: CustomOptions
  instrument?: InfrontSDK.SymbolId | InfrontSDK.SymbolId[] | number | number[] | string | string[]
  from: Date
  to: Date
}) {
  const [data, setData] = useState<InfrontSDK.CalendarEvent[]>(undefined)
  const { customOptions, instrument, from, to } = options
  const { infrontSDK, error } = useInfrontSDK()

  useEffect(() => {
    if (infrontSDK) {
      // Clear previous
      setData(undefined)

      const options: InfrontSDK.FinancialCalendarOptions = {
        source: instrument === '' ? '' : instrument || customOptions?.source || 'SE',
        from: from,
        to: to,
        subscribe: false,
        limit: customOptions?.limit ? customOptions?.limit : 6,
        onData: (calendarEvents: InfrontSDK.CalendarEvent[]) => {
          const descCaledarEvents = arraySort(
            calendarEvents,
            (field) => {
              return field.dateTime
            },
            customOptions?.desc ?? false
          )
          setData(descCaledarEvents) //?.slice(0, defaultLimit))
        },
      }

      // Returns dispose method
      infrontSDK.get(InfrontSDK.financialCalendar(options))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infrontSDK, JSON.stringify(instrument), JSON.stringify(options), from?.toString(), to?.toString()])

  return { calendarEvents: data, error }
}
