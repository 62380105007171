import { InstrumentPosition } from '@common/api/response'
import { SkeletonLoader } from '@common/components/SkeletonLoader'
import { SmartTable } from '@common/components/SmartTable'
import { useSelectedHoldingAccount } from '@common/hooks/useHoldings'
import { sum } from '@common/utils/sum'

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Box, Breakpoint, useBreakpoint } from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import { ObservableTableInstrument, useTableInstruments } from '../useTableInstruments'
import { TableOverviewFooter, TableOverviewMobileFooter } from './TableOverviewFooter'
import { TableOverviewMobile } from './TableOverviewMobile'
import { TableOverviewRow } from './TableOverviewRow'

type TableOverviewProps = {
  holdingsInstruments: InstrumentPosition[]
  showPercentage: boolean
  isFund?: boolean
  showMoreColumns: boolean
  tableSortStorageKey: string
}

export type TableOverviewColumnId =
  | 'expander-button'
  | 'name'
  | 'quantity'
  | 'priceChangeOneDay'
  | 'priceChangeToday'
  | 'today'
  | 'lastPrice'
  | 'marketValue'
  | 'unrealized'
  | 'unrealizedOrUnrealizedRatio'
  | 'averageAcquisitionPrice'
  | 'acquisitionCost'
  | 'button'

export const TableOverview = observer(function TableOverview({
  holdingsInstruments,
  showPercentage,
  isFund = false,
  showMoreColumns,
  tableSortStorageKey,
}: TableOverviewProps) {
  const { t } = useTranslation()
  const breakpoint = useBreakpoint()
  const isExtraSmallScreen = breakpoint <= Breakpoint.Xs

  const { account: selectedAccount } = useSelectedHoldingAccount()
  const navigate = useNavigate()

  // Convert to observable table instrument
  const { instruments, error, refetch } = useTableInstruments(holdingsInstruments)
  const addSuffix = (text, suffix = '%') => {
    if (showMoreColumns) return text + ' ' + suffix
    return text
  }

  const totalPerformanceToday = isFund
    ? sum('priceChangeOneDay', instruments ? instruments : [])
    : sum('priceChangeToday', instruments ? instruments : [])
  const totalMarketValues = sum('marketValue', instruments ? instruments : [])
  const totalUnrealized = sum('unrealized', instruments ? instruments : [])
  const totalAcquisitionCost = sum('amount', instruments ? instruments.map((x) => x.acquisitionCost) : [])

  const navigateToTransactions = (instrument: ObservableTableInstrument) => {
    navigate(
      `/overview/transactions/${selectedAccount?.id}?instruments=${JSON.stringify([instrument.key])}&from=2005-01-01`
    )
  }

  useEffect(() => {
    refetch() // Re-init instruments-list using useTableInstruments()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [holdingsInstruments])

  const showExpanderColumn = instruments?.some((instrument) => instrument.hasDetails)

  return (
    <Box>
      <SkeletonLoader
        error={error && !instruments}
        height={300}
        p={16}
        loading={!instruments}
        noDataLoaded={instruments?.length === 0}
      >
        {() => (
          <SmartTable<ObservableTableInstrument, TableOverviewColumnId>
            renderMode={isExtraSmallScreen ? 'custom' : 'table'}
            sessionStorageKey={tableSortStorageKey}
            defaultSortBy="name"
            data={instruments}
            renderRow={({ row: instrument, columns }) => {
              return isExtraSmallScreen ? (
                <TableOverviewMobile
                  key={instrument.key}
                  instrument={instrument}
                  accountCurrencyCode={selectedAccount && selectedAccount.currencyCode}
                  showPercentage={showPercentage}
                  isFund={isFund}
                  transactionOnClick={() => navigateToTransactions(instrument)}
                />
              ) : (
                <TableOverviewRow
                  columns={columns}
                  key={instrument.key}
                  instrument={instrument}
                  accountCurrencyCode={selectedAccount && selectedAccount.currencyCode}
                  showPercentage={showPercentage}
                  transactionOnClick={() => navigateToTransactions(instrument)}
                  isFund={isFund}
                  showMoreColumns={showMoreColumns}
                />
              )
            }}
            renderFooterRow={({ columns }) => {
              return isExtraSmallScreen ? (
                <TableOverviewMobileFooter
                  totalPerformanceToday={totalPerformanceToday}
                  totalMarketValues={totalMarketValues}
                  totalUnrealized={totalUnrealized}
                  showPercentage={showPercentage}
                />
              ) : (
                <TableOverviewFooter
                  columns={columns}
                  totalPerformanceToday={totalPerformanceToday}
                  totalMarketValues={totalMarketValues}
                  totalUnrealized={totalUnrealized}
                  totalAcquisitionCost={totalAcquisitionCost}
                  showPercentage={showPercentage}
                  showMoreColumns={showMoreColumns}
                />
              )
            }}
            columns={[
              {
                id: 'expander-button',
                visible: showExpanderColumn,
                width: 'auto',
              },
              {
                id: 'name',
                width: showMoreColumns ? 'minmax(0,4fr)' : 'minmax(0,2fr)',
                renderHeader: () => t('Namn'),
                sortBy: ({ row }) => row.name,
              },
              {
                id: 'quantity',
                width: '1fr',
                align: 'right',
                renderHeader: () => t('Antal'),
                sortBy: ({ row }) => row.quantity,
              },
              {
                visible: !!showMoreColumns,
                id: isFund ? 'priceChangeOneDay' : 'priceChangeToday',
                width: '1fr',
                align: 'right',
                renderHeader: () => (isFund ? t('1 dag') + ' +/-' : t('Idag') + ' +/-'),
                sortBy: ({ row }) => (isFund ? row.priceChangeOneDay : row.priceChangeToday),
              },
              {
                id: 'today',
                width: '1fr',
                align: 'right',
                renderHeader: () => {
                  if (isExtraSmallScreen) {
                    return isFund ? t('1 dag') : t('Idag')
                  } else {
                    return isFund ? addSuffix(t('1 dag')) : addSuffix(t('Idag'))
                  }
                },
                sortBy: ({ row }) => {
                  if (showPercentage) {
                    return isFund ? row.priceChangeOneDayRatio : row.priceChangeTodayRatio
                  } else {
                    return isFund ? row.priceChangeOneDay : row.priceChangeToday
                  }
                },
              },
              {
                id: 'lastPrice',
                width: '1fr',
                align: 'right',
                renderHeader: () => t('Senast'),
                sortBy: ({ row }) => row.lastPrice,
              },
              {
                id: 'marketValue',
                width: '1fr',
                align: 'right',
                renderHeader: () => t('Marknadsvärde'),
                sortBy: ({ row }) => row.marketValue,
              },
              {
                visible: !!showMoreColumns,
                id: 'unrealized',
                width: '1fr',
                align: 'right',
                renderHeader: () => addSuffix(t('Orealiserat'), '+/-'),
                sortBy: ({ row }) => row.unrealized,
              },
              {
                id: 'unrealizedOrUnrealizedRatio',
                width: '1fr',
                align: 'right',
                renderHeader: () => addSuffix(t('Orealiserat')),
                sortBy: ({ row }) => (showPercentage ? row.unrealizedRatio ?? 0 : row.unrealized ?? 0),
              },
              {
                visible: !!showMoreColumns,
                id: 'averageAcquisitionPrice',
                width: '1fr',
                align: 'right',
                renderHeader: () => t('Ansk. kurs'),
                sortBy: ({ row }) => row.averageAcquisitionPrice?.amount,
              },
              {
                visible: !!showMoreColumns,
                id: 'acquisitionCost',
                width: '1fr',
                align: 'right',
                renderHeader: () => t('Ansk. värde'),
                sortBy: ({ row }) => row.acquisitionCost?.amount,
              },
              {
                id: 'button',
                width: 48,
              },
            ]}
            rowKey={(row) => row.instrumentIdCarnegie}
          />
        )}
      </SkeletonLoader>
    </Box>
  )
})

TableOverview.displayName = 'TableOverview'
