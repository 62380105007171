import { OfferingCard } from '@common/components/OfferingCard'
import { ShowAll } from '@common/components/ShowAll'
import { SkeletonLoader } from '@common/components/SkeletonLoader'
import { Offering, useOfferings } from '@common/hooks/useOfferings'

import { useTranslation } from 'react-i18next'

import {
  Banner,
  Box,
  Breakpoint,
  CSSGrid,
  DuploSpaceProps,
  FlexRow,
  Spacer,
  Text,
  useBreakpoint,
} from '@carnegie/duplo'

export type MyOfferingsProps = {
  className?: string
  limit?: 2 | 3 | 4
  accountIds?: string[]
  minCardSize?: '1/2' | '1/3'
} & DuploSpaceProps

export const MyOfferings = ({
  className,
  limit = 3,
  minCardSize = '1/2',
  accountIds = [],
  ...spaceProps
}: MyOfferingsProps) => {
  const { offerings, error, totalItems } = useOfferings({
    limit,
    accountIds,
    type: null,
  })
  //No offering type

  const hasMore = totalItems > limit
  if (hasMore) offerings.length = limit

  const { t } = useTranslation()
  const breakpoint = useBreakpoint()
  const isExtraSmall = breakpoint < Breakpoint.Small

  // We don't want to show anything if the customer has no offerings
  // At first we showed skeletons but it felt weird when the skeletons would show and then disappear
  if (!offerings || offerings?.length === 0) {
    return null
  }

  return (
    <Box width="full" {...spaceProps}>
      <FlexRow
        justifyContent="space-between"
        css={{
          height: 20 /* Have to force the height to 20 since the UI will "jump" if the show all is hidden and then shown otherwise */,
        }}
      >
        <Text variant="overline" className={className}>
          {t('Mina Erbjudanden')}
        </Text>
        <ShowAll count={totalItems} to={`/offerings`} />
      </FlexRow>
      <Text variant="body2">
        {t(
          'Du har aktuella erbjudanden att besvara kopplat till ditt innehav. Klicka på kortet för att läsa mer och svara.'
        )}
      </Text>
      <Spacer height={8} />
      <SkeletonLoader
        error={error}
        errorComponent={
          <Banner width="full" severity="critical" title={''}>
            {t('Fel vid datahämtning')}
          </Banner>
        }
      >
        {() => (
          <CSSGrid cols={!isExtraSmall ? getCardCols(limit, minCardSize, offerings) : 1} gap={16}>
            {offerings
              ? offerings?.map((offering) => <OfferingCard key={offering.offeringId} offering={offering} />)
              : // Skeleton
                [undefined, undefined, undefined].map((offering, count) => (
                  <OfferingCard key={count} offering={undefined} />
                ))}
          </CSSGrid>
        )}
      </SkeletonLoader>
    </Box>
  )
}

function getCardCols(limit: 3 | 2 | 4, minCardSize: string, offerings: Offering[]) {
  if (minCardSize === '1/2' && offerings?.length <= 2) {
    return 2
  }

  if (minCardSize === '1/3' && offerings?.length <= 3) {
    return 3
  }

  return limit
}
