import { DuploColor, FlexCol, FlexRow, Text, useDuploTheme } from '@carnegie/duplo'

import { AnimatePresence, motion } from 'framer-motion'

type AccountPerformanceTabItemProps = {
  onClick: () => void
  isSelected: boolean
  value: string | number
  label: React.ReactNode
  color?: DuploColor
  suffix?: string
  hideBorder?: boolean
}

export const AccountPerformanceTabItem = ({
  onClick,
  isSelected,
  value,
  label,
  color = 'bunker-main',
  suffix = '%',
  hideBorder = false,
}: AccountPerformanceTabItemProps) => {
  const theme = useDuploTheme()

  return (
    <>
      <FlexCol
        position="relative"
        justifyContent="center"
        alignItems="center"
        flexGrow={1}
        p={4}
        minWidth="auto"
        css={{
          borderRight: `${hideBorder ? 'none' : `1px solid ${theme.colors['bunker-60']}`}`,
          cursor: 'pointer',
          height: 36,
        }}
        onClick={onClick}
      >
        <FlexCol position="relative" alignItems="center" justifyContent="center" css={{ zIndex: '2' }}>
          <Text variant="label2" color="bunker-200">
            {label}
          </Text>
          <FlexRow alignItems="baseline">
            <Text variant="subtitle2" color={color}>
              {value}
            </Text>
            <Text variant="label2" ml={2} color={color}>
              {suffix}
            </Text>
          </FlexRow>
        </FlexCol>
        <SelectedTab isVisible={isSelected} />
      </FlexCol>
    </>
  )
}

const SelectedTab = ({ isVisible }: { isVisible: boolean }) => {
  const theme = useDuploTheme()
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          style={{
            position: 'absolute',
            backgroundColor: theme.colors['regent-st-300'],
            borderRadius: 4,
            height: 40,
            width: '100%',
            zIndex: 1,
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.25 } }}
          exit={{ opacity: 0, transition: { duration: 0.5 } }}
        />
      )}
    </AnimatePresence>
  )
}
