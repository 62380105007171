import { BuySellMenuItem, PlaceFundOrderMenuItem, TransactionMenuItem } from '@common/components/ActionButton'
import { FormattedPercentage } from '@common/components/FormattedNumber'
import { MoreMenuWatchlist } from '@common/components/MoreMenu'
import { ExpandTableButton, ExpandableTr } from '@common/components/SmartTable'
import { fireTrackEvent } from '@common/utils/analyticsEvent'

import { useState } from 'react'

import { Paragraph, Td } from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import { useOpenInstrumentDrawer } from '../../../../drawers/instrumentDrawer/useOpenInstrumentDrawer'
import { useAccountHoldingsSideDrawer } from '../../accountHoldings/useAccountHoldingsSideDrawer'
import { InstrumentName } from '../InstrumentName'
import { UnderlyingAssetsRows } from '../UnderlyingAssetsRows'
import { ObservableTableInstrument } from '../useTableInstruments'
import { TableAboutColumnId } from './TableAbout'

type TableAboutRowProps = {
  isFund: boolean
  instrument: ObservableTableInstrument
  transactionOnClick?: () => void
  columns: { id?: TableAboutColumnId }[]
}

export const TableAboutRow = observer(({ isFund, instrument, transactionOnClick, columns }: TableAboutRowProps) => {
  const { openInstrumentDrawer } = useOpenInstrumentDrawer()
  const [expanded, setExpanded] = useState(false)

  const {
    carnegieInstrumentGroup,
    isoCountryCode,
    marketIso,
    name,
    typeName,
    region,
    weight,
    allocation,
    reservedQuantities,
  } = instrument

  const showFlag = carnegieInstrumentGroup !== 'MutualFunds'

  const hasExpanderColumn = columns.some((columnDef) => columnDef.id === 'expander-button')

  const { openSideDrawer } = useAccountHoldingsSideDrawer()

  return (
    <>
      <ExpandableTr expanded={expanded}>
        {hasExpanderColumn && (
          <Td>
            {instrument.hasDetails && (
              <ExpandTableButton
                expanded={expanded}
                onClick={() => {
                  if (!expanded) {
                    fireTrackEvent('Holdings', 'expand_underlying_fundholding')
                  }
                  setExpanded(!expanded)
                }}
              />
            )}
          </Td>
        )}
        <Td>
          <InstrumentName
            isoCountryCode={isoCountryCode ? isoCountryCode : marketIso}
            name={name}
            reservedQuantities={reservedQuantities}
            onClick={() => openInstrumentDrawer(instrument)}
            showFlag={showFlag}
            showInfoButton={instrument.hasDetails}
            onInfoButtonClick={() => {
              fireTrackEvent('Holdings', 'click_showmore_underlying_fundholding')
              openSideDrawer()
            }}
          />
        </Td>
        <Td>
          <Paragraph variant="body2">{typeName}</Paragraph>
        </Td>
        <Td>
          <Paragraph variant="body2">{allocation}</Paragraph>
        </Td>
        <Td>
          <Paragraph variant="body2">{region}</Paragraph>
        </Td>
        <Td textAlign="right">
          <FormattedPercentage value={weight} ratio decimals={2} justifyContent={'flex-end'} />
        </Td>
        <Td>
          <MoreMenuWatchlist instrumentIdContainer={instrument}>
            {isFund ? (
              <>
                <PlaceFundOrderMenuItem instrumentIdContainer={instrument} fundOrderType={'buy'} />
                <PlaceFundOrderMenuItem instrumentIdContainer={instrument} fundOrderType={'sell'} />
              </>
            ) : (
              <BuySellMenuItem instrumentIdContainer={instrument} />
            )}
            <TransactionMenuItem onClick={transactionOnClick} />
          </MoreMenuWatchlist>
        </Td>
      </ExpandableTr>
      {expanded && <UnderlyingAssetsRows instrument={instrument} columns={columns} />}
    </>
  )
})
