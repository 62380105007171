import { css } from '@emotion/react'

import { useTranslation } from 'react-i18next'

import { Link, Paragraph, Spacer, lg, md, sm, useDuploTheme } from '@carnegie/duplo'

import { FooterMenu } from './Menu'
import Logo from './logotype_carnegie_main_56x128.svg'

export const Footer = () => {
  const { t } = useTranslation()
  const year = new Date().getFullYear()
  const theme = useDuploTheme()

  return (
    <>
      <Spacer height={32} />
      <StyledFooter>
        <MenuHolder>
          <FooterMenu />
        </MenuHolder>
        <Container>
          <Link display="block" to="/overview/home">
            <Logo
              css={{
                width: 128,
                marginTop: 16,
                marginBottom: 16,
                path: {
                  fill: `${theme.colors['carnegie-pink']}`,
                },
              }}
            />
          </Link>
          <ContentHolder>
            <Paragraph variant="body1" color="carnegie-pink">
              &copy; Copyright Carnegie Investment Bank AB {year}.
            </Paragraph>
            <Spacer height={16} />
            <Paragraph variant="body2" color="carnegie-pink">
              {t(
                'Historisk avkastning är ingen garanti för framtida avkastning. Pengar som placeras i värdepapper/fonder kan både öka och minska i värde. Det är inte säkert att du får tillbaka hela det insatta kapitalet.'
              )}
            </Paragraph>
          </ContentHolder>
        </Container>
      </StyledFooter>
    </>
  )
}

const StyledFooter = (props: React.HTMLAttributes<HTMLElement>) => {
  return (
    <footer css={{ background: 'linear-gradient(-225deg, #212d40 0%, #0a0e13 100%)' }} {...props}>
      {props.children}
    </footer>
  )
}

const MenuHolder = (props: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      css={css`
        max-width: 1560px;
        margin: 0 auto;
        padding: 32px 8px 12px 8px;
        ${sm(`
        padding: 32px 16px 12px 16px;
        `)}
        ${md('padding: 32px 16px 12px 16px;')}
      ${lg('padding: 32px 32px 12px 32px;')}
      `}
      {...props}
    >
      {props.children}
    </div>
  )
}

const Container = (props: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      css={css`
        padding: 12px 8px 32px 8px;
        ${sm(`
        padding: 12px 16px 32px 16px;
        `)}
        ${md('padding: 12px 16px 12px 16px;')}
      ${lg('padding: 12px 32px 12px 32px;')}
      max-width: 1560px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        items-align: center;
        ${sm('flex-direction: row-reverse; align-items: center; justify-content: space-between')}
      `}
      {...props}
    >
      {props.children}
    </div>
  )
}

const ContentHolder = (props: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      css={css`
        ${sm('max-width: 480px;')}
        ${md('max-width: 624px;')}
      ${lg('max-width: 672px;')}
      `}
      {...props}
    >
      {props.children}
    </div>
  )
}
