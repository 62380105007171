import { CurrencyPosition, PerformanceSerieResponse } from '@common/api/response'
import { SkeletonLoader } from '@common/components/SkeletonLoader'
import { useAccountSummary } from '@common/hooks/useAccountSummary'
import { useAccountSummaryData } from '@common/hooks/useAccountSummaryData'
import { useSelectedHoldingAccount } from '@common/hooks/useHoldings'

import { ReactNode, useMemo } from 'react'

import { FlexCol, FlexRow, IconButton, IconChevronDown, IconChevronUp, Segment, SkeletonRect } from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import { AccruedCoupon } from './MatrixSideDrawers/AccruedCoupon'
import { AcquisitionValue } from './MatrixSideDrawers/AcquisitionValue'
import { CouponCash } from './MatrixSideDrawers/CouponCash'
import { CurrencyPositions } from './MatrixSideDrawers/CurrencyPositions'
import { DividendCash } from './MatrixSideDrawers/DividendCash'
import MarginRequirement from './MatrixSideDrawers/MarginRequirement'
import { TradingCapacity } from './MatrixSideDrawers/TradingCapacity'
import { UnrealisedResult } from './MatrixSideDrawers/UnrealisedResult'

type AccountSummaryProps = {
  accountId: string
  performanceSerie: PerformanceSerieResponse
  currencyPositions: CurrencyPosition[]
}

export type AccountSummaryItem = {
  uniqueKey?: string
  currencyCode: string
  translatedLabel: string
  value: number
}

const generateAccountSummarySegment = (itemsToDisplay: ReactNode[], itemsPerRow: number) => {
  const items = []
  for (let i = 0; i < itemsToDisplay.length; i += itemsPerRow) {
    const rowItems = itemsToDisplay.slice(i, i + itemsPerRow)
    const rowItemPadding = itemsPerRow - rowItems.length
    items.push(
      <FlexRow key={`row-${i}`}>
        {[...rowItems, ...Array(rowItemPadding).fill(<FlexCol key={`row-space-${i}`} flex={1} mr={16} />)]}
      </FlexRow>
    )
  }
  return items
}

export const AccountSummarySegment = observer(
  ({ accountId, performanceSerie, currencyPositions }: AccountSummaryProps) => {
    const { accountSummary } = useAccountSummary(accountId)
    const { expanded, onExpandClick, shouldShowCoupon, getAccountSummaryItem, isExtraSmallScreen } =
      useAccountSummaryData(accountSummary, performanceSerie)
    const { account } = useSelectedHoldingAccount()

    const hasMarginRequirement = !!accountSummary?.marginRequirements?.amount

    const itemsToDisplay = useMemo(
      () =>
        [
          getAccountSummaryItem('EK'),
          <CurrencyPositions key="CurrencyPositions" currencyPositions={currencyPositions} accountId={accountId} />,
          account?.isTradeable ? <TradingCapacity key="TradingCapacity" summary={accountSummary} /> : null,
          getAccountSummaryItem('MV'),
          <UnrealisedResult key="UnrealisedResult" performanceSerie={performanceSerie} />,
          getAccountSummaryItem('RYR'),
          <AcquisitionValue key="AcquisitionValue" summary={accountSummary} />,
          getAccountSummaryItem('BV'),
          getAccountSummaryItem('KL'),
          shouldShowCoupon ? <CouponCash summary={accountSummary} accountId={accountId} /> : null,
          shouldShowCoupon ? <AccruedCoupon summary={accountSummary} accountId={accountId} /> : null,
          hasMarginRequirement ? <MarginRequirement summary={accountSummary} /> : null,
          accountId ? (
            <DividendCash key="DividendCash" summary={accountSummary} accountId={accountId} />
          ) : (
            <SkeletonRect width={'full'} height={42} />
          ),
        ].filter(Boolean),
      [
        account?.isTradeable,
        accountId,
        accountSummary,
        currencyPositions,
        getAccountSummaryItem,
        hasMarginRequirement,
        performanceSerie,
        shouldShowCoupon,
      ]
    )

    const skeletonLoaderHeight = isExtraSmallScreen ? 270 : 158
    const itemsPerRow = isExtraSmallScreen ? 2 : 4

    return (
      <Segment title="" variant="contained" height={'auto'} overflow="hidden">
        <SkeletonLoader height={expanded ? skeletonLoaderHeight : 42} loading={!accountSummary}>
          {() => {
            return (
              <FlexRow>
                <FlexCol flex={1} mr={16} spaceY={16}>
                  {generateAccountSummarySegment(
                    expanded ? itemsToDisplay : itemsToDisplay.slice(0, itemsPerRow),
                    itemsPerRow
                  )}
                </FlexCol>
                <FlexCol ml="auto" width={40} alignItems="flex-end">
                  <IconButton
                    icon={expanded ? IconChevronUp : IconChevronDown}
                    onClick={onExpandClick}
                    size="large"
                    variant="uncontained"
                  />
                </FlexCol>
              </FlexRow>
            )
          }}
        </SkeletonLoader>
      </Segment>
    )
  }
)
