import { logInfrontError } from '@common/utils/infrontAppInsights'

export async function getFeedMetaData(sdk: InfrontSDK.SDK, feeds: number[]): Promise<Infront.FeedMetaData[]> {
  return new Promise((resolve, reject) => {
    const unsubscribe = sdk.get(
      InfrontSDK.feedInfo({
        feed: feeds,
        infoType: InfrontSDK.FeedInfoType.MetaData,
        subscribe: false,
        onData: (feedMetaData: Infront.FeedMetaData[]) => {
          resolve(feedMetaData)
        },
        onError: (e) => {
          logInfrontError(e)
          reject(e)
        },
      })
    )

    unsubscribe()
  })
}
