import { FavoriteStar } from '@common/components/FavoriteStar'
import { useAccountUserSettings } from '@common/hooks/useAccountUserSettings'

import { useTranslation } from 'react-i18next'

import { Flex, MenuItem } from '@carnegie/duplo'

type ToggleFavoriteMenuItemProps = {
  selectedAccountId: string
  onClick?: () => void
}

export const ToggleFavoriteMenuItem = ({ selectedAccountId, onClick }: ToggleFavoriteMenuItemProps) => {
  const { accountUserSettings, setAccountUserSettings } = useAccountUserSettings()
  const accountUserSettingsItem = accountUserSettings?.[selectedAccountId]
  const { t } = useTranslation()

  return (
    <MenuItem
      onClick={() => {
        setAccountUserSettings(selectedAccountId, { isFavorite: !accountUserSettingsItem?.isFavorite })
        onClick?.()
      }}
      icon={
        <Flex css={{ transform: 'translateY(-1px)' }} alignItems="center" justifyContent="center">
          <FavoriteStar filled={accountUserSettingsItem?.isFavorite} />
        </Flex>
      }
    >
      {accountUserSettingsItem?.isFavorite ? t('Ta bort favoritmarkering') : t('Favoritmarkera konto')}
    </MenuItem>
  )
}
