import { useParams } from 'react-router-dom'

export function useSelectedAccountIdParams() {
  let accountId: string = undefined
  const query = useParams<{ accountId: string }>()

  if (!accountId) {
    accountId = query.accountId
  }
  return accountId
}
