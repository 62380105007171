import { FormattedNumber } from '@common/components/FormattedNumber'
import { useHoldingAccounts } from '@common/hooks/useHoldings'
import { convertToInstrumentIdContainer } from '@common/instrumentIdContainer'
import { useNavigation } from '@common/stores/store'
import { captureMessage } from '@sentry/react'

import { useTranslation } from 'react-i18next'

import { InfrontOrder, formatNumber } from '@carnegie/digital-channels-frontend'
import { LinkButton, SkeletonText } from '@carnegie/duplo'

import { useOpenInstrumentDrawer } from '@/drawers/instrumentDrawer/useOpenInstrumentDrawer'
import { InstrumentName } from '@/pages/accounts/holdingsTable/InstrumentName'

import { observer } from 'mobx-react-lite'

import { transformInfrontOrderStatus } from './CurrentOrders'

type CurrentOrderRowProps = {
  order: InfrontOrder
  cell:
    | 'fullName'
    | 'orderStatus'
    | 'portfolio'
    | 'buyOrSell'
    | 'source'
    | 'pctFill'
    | 'limit'
    | 'changed'
    | 'sValidUntil'
    | 'monetaryAmount'
    | ''
}

export const CurrentOrderRow = observer(({ order, cell }: CurrentOrderRowProps) => {
  const { t } = useTranslation()
  const { openInstrumentDrawer } = useOpenInstrumentDrawer()
  const navigation = useNavigation()
  const {
    isBuyOrder,
    orderStatus,
    orderSystem,
    volumeFilled,
    volume,
    orderPrice,
    formattedValidDate,
    formattedChangedDateTime,
    portfolioName,
    currency,
    fullName,
    monetaryAmount,
  } = order

  const { findAccountByNumber } = useHoldingAccounts()
  const accountId = findAccountByNumber(portfolioName)?.id

  if (!order.symbolId) {
    captureMessage('No symbolId found for order', {
      level: 'error',
      extra: { order }, // Pass order data in the extra field which is valid for Sentry
    })
  }

  const key = order.symbolId ?? { feed: order.feed, ticker: order.ticker }
  const instrumentIdContainer = convertToInstrumentIdContainer(key)
  const accountNumber = order.portfolioName

  switch (cell) {
    case 'fullName':
      return fullName ? (
        <InstrumentName
          showFlag={false}
          name={fullName}
          isoCountryCode={'SE'}
          showInfoButton={false}
          onClick={() => {
            openInstrumentDrawer(instrumentIdContainer)
          }}
        />
      ) : (
        <SkeletonText height={24} width={80} />
      )

    case 'buyOrSell':
      return <>{isBuyOrder ? t('Köp') : t('Sälj')}</>

    case 'portfolio':
      return (
        <LinkButton
          variant="body2"
          minWidth={0}
          onClick={() => navigation.replace(`/accounts/${accountId}?returnroute=/overview/orders/${accountId}`)}
        >
          {accountNumber}
        </LinkButton>
      )

    case 'orderStatus':
      return <>{transformInfrontOrderStatus(orderStatus)}</>

    case 'source':
      return <>{t(orderSystem)}</>

    case 'pctFill':
      return (
        <>{`${formatNumber(volumeFilled, { decimals: { min: 0, max: 6 } })} / ${formatNumber(volume, {
          decimals: { min: 0, max: 6 },
        })}`}</>
      )

    case 'limit':
      return <FormattedNumber unit={currency} value={orderPrice} decimals={2} showZeroAsFallbackValue />

    case 'monetaryAmount':
      return <FormattedNumber value={monetaryAmount} decimals={2} />

    case 'changed':
      return <>{formattedChangedDateTime}</>

    case 'sValidUntil':
      return <>{formattedValidDate}</>

    default:
      return <></>
  }
})
