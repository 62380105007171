import { NoDataDash, NoDataFallback } from '@common/components/NoDataFallback'
import { useInfrontInstrument } from '@common/hooks/infront/sdk/useInfrontInstrument'
import { useCarnegieInstrument } from '@common/hooks/useCarnegieInstrument'
import { useInstrumentWithFallbackData } from '@common/hooks/useInstrumentWithFallbackData'
import { InstrumentIdContainer } from '@common/instrumentIdContainer'
import {
  getMorningStarAnnualReportLink,
  getMorningStarFactsheetLink,
  getMorningStarProspectusLink,
} from '@common/utils/morningstarLink'

import { useTranslation } from 'react-i18next'

import { formatNumber } from '@carnegie/digital-channels-frontend'
import {
  Box,
  Breakpoint,
  FlexCol,
  FlexRow,
  Icon,
  IconDocumentLineChart,
  LinkExternal,
  ListItem,
  ListItemRow,
  Paragraph,
  Segment,
  SkeletonRect,
  Spacer,
  Tag,
  TagLabel,
  Text,
  useBreakpoint,
} from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import { ListItemValue } from '../ListItemValue'

type Props = {
  instrumentIdContainer: InstrumentIdContainer
}

/**
 * Handelsplats saknas. Svar: Undersöker.
 * Listor saknas. Svar: Oklart om instrumentet vet vilka listor det tillhör i fronten. Skulle gå att lösa backend.
 */

export const InstrumentFundsInformation = observer(({ instrumentIdContainer }: Props) => {
  const { t, i18n } = useTranslation()

  const breakpoint = useBreakpoint()
  const isMobile = breakpoint < Breakpoint.Small

  const instrumentWithFallback = useInstrumentWithFallbackData(instrumentIdContainer, [
    'isin',
    'marketplace',
    'marketList',
  ])

  // **** NOTE BUG ****
  // If setting FundDetails true in sdk the data will only come ones!
  const { observableInstrument, isLoading: isLoadingInfrontInstrument } = useInfrontInstrument(
    instrumentIdContainer?.infrontInstrument,
    [
      InfrontSDK.SymbolField.FundUcits,
      InfrontSDK.SymbolField.FundPpmCode,
      InfrontSDK.SymbolField.FundManager,
      InfrontSDK.SymbolField.FundSharpeRatio3Y,
      InfrontSDK.SymbolField.FundStdDev3Years,
      InfrontSDK.SymbolField.FundTotalAssets,
      InfrontSDK.SymbolField.FundInceptionDate,
      InfrontSDK.SymbolField.FundType,
      InfrontSDK.SymbolField.PrimarySegment,
      InfrontSDK.SymbolField.SecondarySegment,
      InfrontSDK.SymbolField.Currency,
      InfrontSDK.SymbolField.FundInvestmentStrategy,
      // InfrontSDK.SymbolField.NetAssetValue,
    ],
    { observe: false }
  )

  const isin = instrumentWithFallback?.isin

  const ucits = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.FundUcits)
  const ppm = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.FundPpmCode)
  const startDateValue = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.FundInceptionDate)
  const startDate = startDateValue !== undefined ? InfrontUtil.formatDate(startDateValue) : undefined

  const fundManager: string[] = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.FundManager)

  const fundManagerParsed: { name: string; date: string }[] =
    fundManager?.map((fundManagerNameAndDate) => {
      return {
        name: fundManagerNameAndDate?.replace(/(\([0-9]+-[0-9]+-[0-9]+\))/, '') ?? '',
        date: /(\([0-9]+-[0-9]+-[0-9]+\))/.exec(fundManagerNameAndDate)?.[0] ?? '',
      }
    }) ?? []

  const fundType = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.FundType)
  const primarySegment = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.PrimarySegment)
  const secondarySegment = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.SecondarySegment)

  const fundSharpeRatio3Y = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.FundSharpeRatio3Y)

  const fundStdDev3Years = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.FundStdDev3Years)

  const fundTotalAssets = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.FundTotalAssets)

  const fundDescription = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.FundInvestmentStrategy)
  const currency = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.Currency)

  const { carnegieInstrument, isLoading: isLoadingCarnegieInstrument } = useCarnegieInstrument(
    instrumentIdContainer,
    i18n.language
  )

  const morningStarDocLink = getMorningStarFactsheetLink(carnegieInstrument?.instrumentIdCarnegie, i18n.language)
  const annualReportUrl = getMorningStarAnnualReportLink(carnegieInstrument?.instrumentIdCarnegie, i18n.language)
  const prospectDocumentURL = getMorningStarProspectusLink(carnegieInstrument?.instrumentIdCarnegie, i18n.language)

  const fundFactSheet =
    isin === undefined ? (
      <SkeletonRect height={20} width={96} />
    ) : (
      <LinkExternal truncate variant="subtitle2" target="_blank" href={morningStarDocLink} title={t('Fondfaktablad')}>
        <FlexRow>
          <Box mr={4}>
            <Icon color="current" icon={IconDocumentLineChart} size={20} />
          </Box>
          <span>{t('Fondfaktablad')}</span>
        </FlexRow>
      </LinkExternal>
    )

  const annualReport =
    isin === undefined ? (
      <SkeletonRect height={20} width={96} />
    ) : (
      <LinkExternal truncate variant="subtitle2" target="_blank" href={annualReportUrl} title={t('Årsrapport')}>
        <FlexRow>
          <Box mr={4}>
            <Icon color="current" icon={IconDocumentLineChart} size={20} />
          </Box>
          <span>{t('Årsrapport')}</span>
        </FlexRow>
      </LinkExternal>
    )

  const prospectDocument =
    isin === undefined ? (
      <SkeletonRect height={20} width={96} />
    ) : (
      <LinkExternal
        truncate
        variant="subtitle2"
        target="_blank"
        href={prospectDocumentURL}
        title={t('Informationsbroschyr')}
      >
        <FlexRow>
          <Box mr={4}>
            <Icon color="current" icon={IconDocumentLineChart} size={20} />
          </Box>
          <span>{t('Informationsbroschyr')}</span>
        </FlexRow>
      </LinkExternal>
    )

  // "NAV" --> const navDate = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.NetAssetValue)

  return (
    <Box>
      {isLoadingInfrontInstrument ? (
        <SkeletonRect width="full" height={128} />
      ) : (
        <Paragraph variant="body1" mb={16}>
          <NoDataFallback>{fundDescription}</NoDataFallback>
        </Paragraph>
      )}
      <Segment noContentPadding>
        <ListItem divider>
          <ListItemRow
            title={<Text variant="support2">{t('Värdepapperstyp')}</Text>}
            value={
              <ListItemValue loading={isLoadingCarnegieInstrument} value={carnegieInstrument?.instrumentTypeName} />
            }
          />
        </ListItem>
        <ListItem divider>
          <ListItemRow title={<Text variant="support2">{t('ISIN')}</Text>} value={<ListItemValue value={isin} />} />
        </ListItem>
        <ListItem divider>
          <ListItemRow
            title={<Text variant="support2">{t('UCITS')}</Text>}
            value={
              <ListItemValue
                loading={isLoadingInfrontInstrument}
                value={ucits === true ? t('Ja') : ucits === false ? t('Nej') : undefined}
              />
            }
          />
        </ListItem>
        <ListItem divider>
          <ListItemRow
            title={<Text variant="support2">{t('PPM-nummer')}</Text>}
            value={<ListItemValue loading={isLoadingInfrontInstrument} value={ppm} />}
          />
        </ListItem>
        <ListItem divider>
          <ListItemRow
            title={<Text variant="support2">{t('Startdatum')}</Text>}
            value={<ListItemValue loading={isLoadingInfrontInstrument} value={startDate} />}
          />
        </ListItem>
        {isMobile ? (
          <ListItem divider>
            <ListItemRow title={fundFactSheet} />
          </ListItem>
        ) : (
          <ListItem divider>
            <ListItemRow title={<Text variant="support2">{t('Faktablad')}</Text>} value={fundFactSheet} />
          </ListItem>
        )}
        {isMobile ? (
          <ListItem divider>
            <ListItemRow title={prospectDocument} />
          </ListItem>
        ) : (
          <ListItem divider>
            <ListItemRow
              title={<Text variant="support2">{t('Informationsbroschyr inkl. hållbarhet')}</Text>}
              value={prospectDocument}
            />
          </ListItem>
        )}
        {isMobile ? (
          <ListItem>
            <ListItemRow title={annualReport} />
          </ListItem>
        ) : (
          <ListItem>
            <ListItemRow
              title={<Text variant="support2">{t('Årsrapport inkl. hållbarhet')}</Text>}
              value={annualReport}
            />
          </ListItem>
        )}
      </Segment>
      <Spacer height={16} />
      <Text variant="overline" mb={4}>
        {t('Fondförvaltare')}
      </Text>
      <FlexCol space={4}>
        {isLoadingInfrontInstrument && <SkeletonRect width={160} height={20} />}
        {fundManagerParsed?.length === 0 && (
          <Text variant="subtitle2">
            <NoDataDash />
          </Text>
        )}
        {fundManagerParsed.map((fm) => (
          <FlexRow key={fm.name} alignItems="baseline" space={4}>
            <Text variant="subtitle2">{fm.name}</Text>
            <Text variant="support1" color="text-low-emphasis">
              {fm.date}
            </Text>
          </FlexRow>
        ))}
      </FlexCol>
      <Spacer height={16} />
      <Text variant="overline" mb={8}>
        {t('Fondkategori')}
      </Text>
      <FlexRow>
        <Box mr={16}>
          {isLoadingInfrontInstrument ? (
            <SkeletonRect height={32} width={48} />
          ) : (
            <Tag>
              <TagLabel>{!fundType ? <NoDataDash /> : t(fundType)}</TagLabel>
            </Tag>
          )}
        </Box>
        <Box mr={primarySegment !== null ? 16 : 0}>
          {isLoadingInfrontInstrument ? (
            <SkeletonRect height={32} width={48} />
          ) : (
            primarySegment !== null && (
              <Tag>
                <TagLabel>{primarySegment ? primarySegment : <NoDataDash />}</TagLabel>
              </Tag>
            )
          )}
        </Box>
        <Box mr={secondarySegment !== null ? 16 : 0}>
          {isLoadingInfrontInstrument ? (
            <SkeletonRect height={32} width={48} />
          ) : (
            secondarySegment !== null && (
              <Tag>
                <TagLabel>{secondarySegment ? secondarySegment : <NoDataDash />}</TagLabel>
              </Tag>
            )
          )}
        </Box>
      </FlexRow>
      <Spacer height={16} />
      <Text variant="overline" mb={8}>
        {t('Nyckeltal')}
      </Text>
      <FlexRow justifyContent="space-between">
        <FlexCol>
          <Text variant="label1">{t('Sharpekvot 3 år')}</Text>
          <ListItemValue
            loading={isLoadingInfrontInstrument}
            skeletonWidth={40}
            showEmptyStateForZeroValues
            value={fundSharpeRatio3Y ? formatNumber(fundSharpeRatio3Y) : fundSharpeRatio3Y}
          />
        </FlexCol>
        <FlexCol>
          <Text variant="label1">{t('Standardavvikelse 3 år')}</Text>
          <ListItemValue
            loading={isLoadingInfrontInstrument}
            unit={'%'}
            skeletonWidth={48}
            showEmptyStateForZeroValues
            value={fundStdDev3Years ? formatNumber(fundStdDev3Years) : fundSharpeRatio3Y}
          />
        </FlexCol>
        <FlexCol>
          <Text variant="label1">{t('Fondförmögenhet')}</Text>
          <ListItemValue
            loading={isLoadingInfrontInstrument}
            skeletonWidth={64}
            unit={`M${t(currency)}`}
            value={
              fundTotalAssets !== null && fundTotalAssets !== undefined
                ? formatNumber(fundTotalAssets / 1000000, { decimals: 0 })
                : fundTotalAssets
            }
          />
        </FlexCol>
      </FlexRow>
    </Box>
  )
})
InstrumentFundsInformation.displayName = 'InstrumentFundsInformation'
