import { GetTransferOrdersResponse } from '@common/api/response'
import { TrashCanButtonWithConfirm } from '@common/components/TrashCanButtonWithConfirm'
import { useHoldingAccounts } from '@common/hooks/useHoldings'

import { useTranslation } from 'react-i18next'

import { formatNumber, useFormatDate } from '@carnegie/digital-channels-frontend'
import { FlexRow, Link, ListItem, ListItemRow, Paragraph } from '@carnegie/duplo'

type Props = { ongoingTransfer: GetTransferOrdersResponse; cancelTransfer: (transferOrderId: number) => Promise<void> }

const OngoingTransferRowMobile = ({ ongoingTransfer, cancelTransfer }: Props) => {
  const { t } = useTranslation()
  const { formatDate } = useFormatDate()
  const { accounts } = useHoldingAccounts()
  const { created, fromAccountNo, toAccountNo, amount, displayState, id, isCancelable } = ongoingTransfer
  const fromInternalAccount = accounts?.find((account) => account.number === fromAccountNo)
  const toInternalAccount = accounts?.find((account) => account.number === toAccountNo)

  return (
    <ListItem
      divider
      action={
        isCancelable && (
          <TrashCanButtonWithConfirm
            size="small"
            bodyText={t('Är du säker på att du vill ta bort överföringen?')}
            confirmButtonText={t('CONFIRM_REMOVE_TRANSFER')}
            iconTooltipText={t('TOOLTIP_REMOVE_TRANSFER')}
            onClick={() => cancelTransfer(id)}
          />
        )
      }
    >
      <ListItemRow
        className="mb-4"
        title={
          <Paragraph variant="subtitle1">{formatDate(created, 'today HH:mm/yesterday HH:mm/yyyy-MM-dd')}</Paragraph>
        }
        value={
          <FlexRow alignItems="baseline">
            <Paragraph variant="subtitle1">{`${formatNumber(amount, { decimals: 2 })}`}</Paragraph>
            <Paragraph variant="support2" ml={2}>
              SEK
            </Paragraph>
          </FlexRow>
        }
      />
      <ListItemRow
        className="mb-4"
        title={
          <FlexRow alignItems="baseline">
            <Paragraph variant="support2" color="text-low-emphasis">{`${t('Från')}:`}</Paragraph>
            {fromInternalAccount ? (
              <Link to={`/accounts/${fromInternalAccount?.id}`} variant="support2" ml={2}>
                {fromInternalAccount?.number}
              </Link>
            ) : (
              <Paragraph variant="support2" ml={2}>
                {fromAccountNo}
              </Paragraph>
            )}
          </FlexRow>
        }
        value={
          <Paragraph variant="support2" color="text-low-emphasis">
            {t(displayState)}
          </Paragraph>
        }
      />
      <ListItemRow
        title={
          <FlexRow alignItems="baseline">
            <Paragraph variant="support2" color="text-low-emphasis">{`${t('Till')}:`}</Paragraph>
            {toInternalAccount ? (
              <Link to={`/accounts/${toInternalAccount?.id}`} variant="support2" ml={2}>
                {toInternalAccount?.number}
              </Link>
            ) : (
              <Paragraph variant="support2" ml={2}>
                {toAccountNo}
              </Paragraph>
            )}
          </FlexRow>
        }
      />
    </ListItem>
  )
}

export default OngoingTransferRowMobile
