import { useTranslation } from 'react-i18next'

import { Box, Button, FlexCol, GridContainer, GridItem, Heading5, Image, Paragraph, Spacer } from '@carnegie/duplo'

import WorkingOnItImage from './workingonit.png'

const email = 'feedbackapp@carnegie.se'
const subject = 'Feedback om PB Online'

type Props = {
  body?: string
}

export const DrawerFeedback = ({ body }: Props) => {
  const { t } = useTranslation()

  return (
    <GridContainer>
      <GridItem xs={12} sm={7} md={7} lg={7}>
        <FlexCol>
          <Box mb={16} css={{ height: 200, width: 268 }}>
            <Image src={WorkingOnItImage} />
          </Box>
          <Heading5>{t('Utveckling pågår')}</Heading5>
          <Paragraph variant="body1">{t(body)}</Paragraph>
          <Spacer height={16} />
          <Button
            css={{ width: 133 }}
            disabled={false}
            onClick={() => (window.location.href = `mailto:${email}?subject=${subject}`)}
            variant="primary"
            size="small"
          >
            {t('Ge feedback')}
          </Button>
        </FlexCol>
      </GridItem>
    </GridContainer>
  )
}

export const InstrumentFeedback = () => {
  return (
    <DrawerFeedback body="Vi jobbar kontinuerligt med värdepapperssidorna och kommer att lansera dem allt eftersom. Vi tar gärna in dina idéer och feedback om vad du vill se på den här sidan." />
  )
}
