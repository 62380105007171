import { ObservableInfrontInstrument } from '../../hooks/infront/sdk/observableInfrontInstrument'

export function getLastOrPreLast(observableInstrument: ObservableInfrontInstrument) {
  const lastValue = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.Last)
  const preLast = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.PreLastTradedAt)

  if (lastValue) {
    // 0, undefined or null
    return lastValue
  } else if (preLast) {
    return preLast
  } else {
    return lastValue
  }
}
