import { useMemo } from 'react'

import { getInfrontPredefinedInstrument } from '../infrontPredefinedInstruments'

type Ticker = {
  instrument: Infront.Instrument
  name: string
}

export const useTickers = (): Ticker[] => {
  const tickers = useMemo(
    () => [
      {
        instrument: getInfrontPredefinedInstrument('OMXNORDICSEKPI'),
        name: 'OMX Nordic PI',
      },
      {
        instrument: getInfrontPredefinedInstrument('OMXS30'),
        name: 'OMX Stockholm 30',
      },
      {
        instrument: getInfrontPredefinedInstrument('OMXSPI'),
        name: 'OMX Stockholm PI',
      },
      {
        instrument: getInfrontPredefinedInstrument('OMXC20'),
        name: 'OMX Copenhagen 20',
      },
      {
        instrument: getInfrontPredefinedInstrument('OMXCPI'),
        name: 'OMX Copenhagen PI',
      },
      {
        instrument: getInfrontPredefinedInstrument('OSEBX'),
        name: 'OSEBX Oslo',
      },
      {
        instrument: getInfrontPredefinedInstrument('OAAX'),
        name: 'Oslo Axess',
      },
      {
        instrument: getInfrontPredefinedInstrument('OMXH25'),
        name: 'OMX Helsinki 25',
      },
      {
        instrument: getInfrontPredefinedInstrument('OMXHPI'),
        name: 'OMX Helsinki PI',
      },
      {
        instrument: getInfrontPredefinedInstrument('CSXSE'),
        name: 'Carnegie Small CSX Sweden (SEK)',
      },
    ],
    []
  )

  return tickers
}
