import { Squircle } from '@common/components/Squircle'

import { useTranslation } from 'react-i18next'

import { Box, DuploColor, FlexRow, Text } from '@carnegie/duplo'

import { RecommendationNames } from '../shared/researchNewsItem'

type Size = 'xs' | 'small' | 'medium' | 'large'

type RecommendationSquircleProps = {
  size?: Size
  type?: RecommendationNames
}

function useRecommendationSquircle(size: Size, type: RecommendationNames) {
  const { t } = useTranslation()

  let iconBg: DuploColor = 'carnegie-dark-blue'
  let iconTextColor: DuploColor = 'text-light'
  let iconText
  let squircleSize
  let variant

  const short = size === 'xs' || size === 'small'
  const isXs = size === 'xs'

  switch (size) {
    case 'large':
      squircleSize = 40
      variant = 'label1'
      break
    case 'medium':
      squircleSize = 32
      variant = 'label2'
      break
    case 'small':
      squircleSize = 24
      variant = 'label1'
      break
    case 'xs':
      squircleSize = 16
      variant = 'label1'
      break
  }

  switch (type.toLowerCase()) {
    case 'buy':
      iconBg = 'carnegie-dark-blue'
      iconText = short ? t('K') : t('Köp') // buy - B
      break
    case 'sell':
      iconBg = 'paprika-main'
      iconText = short ? t('S') : t('Sälj') // sell - S
      break
    case 'hold':
      iconBg = 'carnegie-dark-pink'
      iconTextColor = 'text-low-emphasis'
      iconText = short ? t('B') : t('Behåll') // hold - H
      break
    case 'not rated':
      iconBg = 'carnegie-dark-pink'
      iconTextColor = 'text-low-emphasis'
      iconText = isXs ? '-' : t('NR')
      break
    case 'under bid':
      iconBg = 'carnegie-dark-pink'
      iconTextColor = 'text-low-emphasis'
      iconText = isXs ? '-' : t('UB')
      break
    case 'under review':
      iconBg = 'carnegie-dark-pink'
      iconTextColor = 'text-low-emphasis'
      iconText = isXs ? '-' : t('UR')
      break
  }

  return {
    iconBg,
    iconTextColor,
    iconText,
    squircleSize,
    variant,
  }
}

export const RecommendationSquircle = ({ size, type }: RecommendationSquircleProps) => {
  const { iconBg, iconTextColor, iconText, squircleSize, variant } = useRecommendationSquircle(size, type)

  if (!iconText) return <Box />

  return (
    <Squircle size={squircleSize}>
      <FlexRow
        color={iconBg}
        alignItems="center"
        justifyContent="center"
        width={squircleSize}
        height={squircleSize}
        backgroundColor={iconBg}
      >
        <Text variant={variant} color={iconTextColor}>
          {iconText}
        </Text>
      </FlexRow>
    </Squircle>
  )
}
