import React, { ReactNode } from 'react'

import { IconButton, IconMore, Menu, MenuItems, MenuToggle } from '@carnegie/duplo'

type MoreButtonProps = {
  className?: string
  variant?: 'contained' | 'uncontained'
  size: 'small' | 'medium' | 'large'
  children?: (_: unknown) => ReactNode
}

export const MoreButton = ({ className, children, variant = 'contained', size }: MoreButtonProps) => {
  return (
    <Menu popperPlacementType="bottom-end">
      <MenuToggle>
        <IconButton className={className} size={size} icon={IconMore} variant={variant} />
      </MenuToggle>
      <MenuItems>
        {typeof children === 'function'
          ? React.Children.toArray(children(() => {}))
              .filter(React.isValidElement)
              .map((child, index) => React.cloneElement(child, { key: index }))
          : children}
      </MenuItems>
    </Menu>
  )
}
