import { InvalidCode } from '@common/api/response'
import { AccountSelect } from '@common/components/AccountSelect'
import { ExchangeCurrencyInfo } from '@common/components/ExchangeCurrencyInfo'
import { QuestionsAndAnswersList } from '@common/components/QuestionsAndAnswers'
import { SelectedAccountIdProvider } from '@common/components/SelectedAccountProvider'
import { ShowAtBreakpoint } from '@common/components/ShowAtBreakpoint'
import { SystemMessageList } from '@common/components/systemMessages'
import { useInfrontInstrument } from '@common/hooks/infront/sdk/useInfrontInstrument'
import { useInfrontTradingStatus } from '@common/hooks/infront/useInfrontTradingConnectedAndPortfolioReady'
import { useCarnegieInstrument } from '@common/hooks/useCarnegieInstrument'
import { useHoldingAccounts } from '@common/hooks/useHoldings'
import { useInstrumentWithFallbackData } from '@common/hooks/useInstrumentWithFallbackData'
import { useSelectedAccountId } from '@common/hooks/useSelectedAccountId'
import { useSyncActivePortfolio } from '@common/hooks/useSyncActivePortfolio'
import { InstrumentIdContainer } from '@common/instrumentIdContainer'
import { useApi } from '@common/stores/store'
import { base64encode } from '@common/utils/base64encode'
import { getValidAccount } from '@common/utils/getValidAccount'
import { getFormattedTimestamp } from '@common/utils/infront/getFormattedTimestamp'

import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Banner,
  Box,
  Breakpoint,
  FlexRow,
  GridContainer,
  GridItem,
  Heading3,
  Link,
  Paragraph,
  Segment,
  Spacer,
  Text,
  useBreakpoint,
} from '@carnegie/duplo'

import { CurrentOrdersList } from '@/drawers/orderDrawer/CurrentOrdersList'
import { ValidationErrorMessage } from '@/pages/overview/order/ValidationErrorMessage'

import { observer } from 'mobx-react-lite'

import { SomethingWentWrong } from '../../pages/overview/accounts/SomethingWentWrong'
import { InstrumentPerformance } from '../instrumentDrawer/InstrumentPerformance'
import { useCommonInstrumentType } from '../instrumentDrawer/useCommonInstrumentType'
import { OrderDepth } from './OrderDepth'
import { OrderEntry, OrderEntryRef } from './OrderEntry'
import { OrderLatestTrades } from './OrderLatestTrades'
import { html as orderQAHtmlEn } from './Order_QA_en.md'
import { html as orderQAHtmlSv } from './Order_QA_sv.md'
import { useOrderDrawerProps } from './useOpenOrderDrawer'

type OrderDrawerProps = {
  instrumentIdContainer: InstrumentIdContainer
  initialSelectedAccountId?: string
  initialOrderId?: number
  renderForApp?: boolean
  phone?: string
}

const OrderDrawer = () => {
  const orderDrawerProps = useOrderDrawerProps()

  // Still loading instrument id container
  if (!orderDrawerProps.instrumentIdContainer) return null

  return (
    <OrderDrawerAppAndWeb
      instrumentIdContainer={orderDrawerProps.instrumentIdContainer}
      initialOrderId={orderDrawerProps.initialOrderId}
      initialSelectedAccountId={orderDrawerProps.initialSelectedAccountId}
    />
  )
}

OrderDrawer.displayName = 'OrderDrawer'

// In its own component because it is also used for the App
export const OrderDrawerAppAndWeb = observer(
  ({ instrumentIdContainer, initialOrderId, renderForApp, phone }: OrderDrawerProps) => {
    const { t, i18n } = useTranslation()
    const [, setError] = useState()
    const api = useApi()
    const [isValid, setIsValid] = useState(renderForApp ? true : false)
    const [invalidCode, setInvalidCode] = useState<InvalidCode>()
    const breakpoint = useBreakpoint()
    const isExtraSmallScreen = breakpoint < Breakpoint.Small
    const initialSelectedAccountId = useSelectedAccountId()
    // Are we editing an order?
    const [orderEntryState, setOrderEntryState] = useState<Infront.OrderEntryState>(Infront.OrderEntryState.DATA_ENTRY)
    const [editOrderId, setEditOrderId] = useState(initialOrderId)

    const { accounts, findAccountById } = useHoldingAccounts()
    const [selectedAccountId, setSelectedAccountId] = useState(initialSelectedAccountId)

    // Because we can come from a non-tradable account we make sure to filter that out first
    const selectedValidAccount = accounts ? getValidAccount(findAccountById(selectedAccountId), accounts) : undefined

    // Sync active portfolio with the selected account
    useSyncActivePortfolio(selectedValidAccount)

    const { tradingConnectedAndPortfolioReady, tradingConnectionError, isTgwEnabled } = useInfrontTradingStatus()

    // Disable account selector for all status except DATA_ENTRY = 0
    // https://dev.azure.com/carnegieinvestmentbank/CarnegieIT/_workitems/edit/70791

    const { observableInstrument, isLoading } = useInfrontInstrument(instrumentIdContainer?.infrontInstrument, [
      InfrontSDK.SymbolField.Currency,
      InfrontSDK.SymbolField.TradeTime,
      InfrontSDK.SymbolField.PreLastTradeDate,
      InfrontSDK.SymbolField.Last,
      InfrontSDK.SymbolField.PreLastTradedAt,
      InfrontSDK.SymbolField.SymbolType,
    ])

    const { carnegieInstrument } = useCarnegieInstrument(instrumentIdContainer, i18n.language)

    const instrumentWithFallback = useInstrumentWithFallbackData(instrumentIdContainer, ['name', 'currency'])
    const name = instrumentWithFallback?.name
    const currency = instrumentWithFallback.currency
    const isFund = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.SymbolType) === 'Funds'
    const formattedTimestamp = !isLoading ? getFormattedTimestamp(observableInstrument, isFund) : undefined

    const orderEntryRef = useRef<OrderEntryRef>(null)

    // When someone clicks last price in OrderTrades, OrderDepth, InstrumentHeader etc
    const onPriceClick = (price: number) => {
      if (orderEntryRef.current) orderEntryRef.current.setPrice(price)
    }

    const instrumentType = useCommonInstrumentType(instrumentIdContainer)

    const getOrderEntrySegmentTitle = () => {
      switch (orderEntryState) {
        case Infront.OrderEntryState.DATA_ENTRY:
        case Infront.OrderEntryState.ERROR:
        case Infront.OrderEntryState.RISK_ERROR:
          return t('Order')
        case Infront.OrderEntryState.CONFIRM:
          return t('Bekräfta order')
        case Infront.OrderEntryState.MODIFY:
          return t('Ändra order')
        case Infront.OrderEntryState.SUCCESS:
          return t('Order lagd')
        default:
          return t('Order')
      }
    }

    const validateInstrument = () => {
      if (!instrumentIdContainer?.infrontInstrument) return

      const asyncFn = async () => {
        if (instrumentIdContainer) {
          const encodedInfrontInstrument = base64encode({
            feed: instrumentIdContainer?.infrontInstrument?.feed,
            ticker: instrumentIdContainer?.infrontInstrument?.ticker,
          })
          try {
            const response = await api.validateOrderView(encodedInfrontInstrument)

            if (response) {
              setIsValid(response.isValid)
              setInvalidCode(response.invalidCode)
            }
          } catch (error) {
            setError(() => {
              throw new Error(
                `Could not validate instrument using service (url: /instruments/validate/${encodedInfrontInstrument}/order) the reason was: ${error}`
              )
            })
          }
        }
      }
      asyncFn()
    }

    useEffect(() => {
      if (!renderForApp) validateInstrument()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const orderHeader = renderForApp ? null : (
      <Box>
        <FlexRow alignItems="center">
          <Text variant="overline" color="bunker-main">
            {t('Order')}
          </Text>
          <Text variant="overline" ml={4} mr={4}>
            {formattedTimestamp}
          </Text>
          {currency && <Text variant="overline"> - {currency}</Text>}
        </FlexRow>
        <Heading3>{name}</Heading3>
        <Spacer height={8} />
      </Box>
    )

    // If this is not a valid instrument or we miss infront data we show an error component
    if (!isValid || !instrumentIdContainer?.infrontInstrument) {
      return (
        <Box>
          {orderHeader}
          <Box width={isExtraSmallScreen ? 'full' : '1/2'}>
            <InstrumentPerformance
              onPriceClick={onPriceClick}
              instrumentType={instrumentType}
              instrumentIdContainer={instrumentIdContainer}
              period={'1D'}
              showTooltip={false}
              carnegieInstrument={carnegieInstrument}
              showFeeAndKiid={instrumentType === 'etf'}
            />
          </Box>
          <Spacer height={32} />
          {!instrumentIdContainer?.infrontInstrument ? (
            <ValidationErrorMessage invalidCode={'NotTradableInstrument'} />
          ) : (
            <ValidationErrorMessage invalidCode={invalidCode} />
          )}
        </Box>
      )
    }

    if (isTgwEnabled === false)
      return (
        <SomethingWentWrong
          title={t('Handel ej tillgänglig')}
          bodyComponent={
            <Paragraph variant="body1">
              {t('Du kan för tillfället inte handla i webben.')}&nbsp;
              <Link to="/profile/contact">{t('Kontakta oss')}</Link>
              &nbsp;
              {t('för hjälp med att lägga din order.')}
            </Paragraph>
          }
        />
      )

    let mainContent = <div />

    const tradingError = tradingConnectionError && (
      <Banner
        mb={16}
        severity="critical"
        title={t('Problem med order')}
        description={t('Vi har just nu tekniska problem med att lägga order. Vänligen försök igen senare.')}
      />
    )

    const accountSelect = (
      <AccountSelect
        //https://dev.azure.com/carnegieinvestmentbank/CarnegieIT/_workitems/edit/71105
        //Disabled for all except Infront.OrderEntryState.DATA_ENTRY
        id="order-drawer-account-select"
        disabled={orderEntryState !== Infront.OrderEntryState.DATA_ENTRY}
        onSelectAccount={(accountId) => {
          setSelectedAccountId(accountId)
        }}
        selectedAccount={selectedValidAccount}
        splitByTradable
      />
    )

    const orderDepth = (
      <OrderDepth onPriceClick={onPriceClick} infrontInstrument={instrumentIdContainer?.infrontInstrument} />
    )

    const orderLatestTrades = (
      <OrderLatestTrades onPriceClick={onPriceClick} instrument={instrumentIdContainer?.infrontInstrument} />
    )

    const orderEntrySegment = (
      <>
        <Segment title={getOrderEntrySegmentTitle()} variant="contained" headingVariant="overline">
          {selectedValidAccount ? (
            <>
              <OrderEntry
                // Allows us to call things like set price imperatively since this is an operation we only want to do once
                ref={orderEntryRef}
                // Changing initial order id will destroy and recreate the widget (by design)
                initialOrderId={editOrderId}
                selectedAccount={selectedValidAccount}
                instrument={instrumentIdContainer?.infrontInstrument}
                onStateChanged={(state) => {
                  // Even if editing an order a "place new order" button pops up,
                  // in these cases we wan't to stop edit mode after changing accounts and so on
                  if (state === Infront.OrderEntryState.DATA_ENTRY) {
                    setEditOrderId(undefined)
                  }

                  setOrderEntryState(state)
                }}
                renderForApp={renderForApp}
              />
              {carnegieInstrument?.currency && carnegieInstrument?.currency !== 'SEK' && (
                <Box width="full">
                  <ExchangeCurrencyInfo type="other" />
                </Box>
              )}
            </>
          ) : (
            <div>{t('Välj ett konto för att lägga order.')}</div>
          )}
        </Segment>
        <Spacer height={16} />
        <Paragraph variant="body2" color="bunker-200" mt={16} css={{ lineHeight: '16px', display: 'block' }}>
          {t(
            'För den här transaktionen har Carnegie inte gjort en fullständig utvärdering om du tillhör det finansiella instrumentets målgrupp eller om instrumentet matchar dina hållbarhetspreferenser.'
          )}
        </Paragraph>
      </>
    )

    //Different order for XS
    if (isExtraSmallScreen) {
      mainContent = (
        // On android mobile, make room for a virtual keyboard
        <Box css={renderForApp && phone === 'android' && { paddingBottom: '37%' }}>
          {orderHeader}
          <Box spaceY={16}>
            <SystemMessageList segmentId="stock-orders" />
            <Box width={'full'}>
              <InstrumentPerformance
                onPriceClick={onPriceClick}
                instrumentType={instrumentType}
                instrumentIdContainer={instrumentIdContainer}
                period={'1D'}
                showTooltip={false}
                carnegieInstrument={carnegieInstrument}
                showFeeAndKiid={instrumentType === 'etf'}
              />
            </Box>
          </Box>
          <Spacer height={16} />
          {isValid && (
            <GridContainer spacing={0}>
              <GridItem xs={12} sm={6} lg={6}>
                {instrumentIdContainer?.infrontInstrument && orderDepth}
                <Spacer height={16} />
                {tradingError}
                <Segment variant="contained" headingVariant="overline">
                  {accountSelect}
                </Segment>
                <Spacer height={16} />
                {orderEntrySegment}
                {renderForApp || (
                  <>
                    <Spacer height={16} />
                    {tradingConnectedAndPortfolioReady && (
                      <CurrentOrdersList selectedAccount={selectedValidAccount} renderForApp={renderForApp} />
                    )}
                  </>
                )}
                {renderForApp || (
                  <>
                    <Spacer height={16} />
                    {instrumentIdContainer?.infrontInstrument && orderLatestTrades}
                  </>
                )}
                <Spacer height={16} />
                <Segment noContentPadding title={t('Frågor & svar')}>
                  <QuestionsAndAnswersList
                    htmlFromMarkdownFile={i18n.language === 'sv' ? orderQAHtmlSv : orderQAHtmlEn}
                  />
                </Segment>
                <Spacer height={32} />
              </GridItem>
            </GridContainer>
          )}
        </Box>
      )
    } else {
      mainContent = (
        <Box>
          <Box spaceY={16}>
            {orderHeader}
            <SystemMessageList segmentId="stock-orders" />
            <Box width={'1/2'}>
              <InstrumentPerformance
                onPriceClick={onPriceClick}
                instrumentIdContainer={instrumentIdContainer}
                period={'1D'}
                showTooltip={false}
                instrumentType={instrumentType}
                carnegieInstrument={carnegieInstrument}
                showFeeAndKiid={instrumentType === 'etf'}
              />
            </Box>
          </Box>
          <Spacer height={16} />
          {isValid && (
            <GridContainer spacing={4}>
              <GridItem xs={12} sm={6} lg={6}>
                {tradingError}
                <Segment variant="contained" headingVariant="overline">
                  {accountSelect}
                </Segment>
                <Spacer height={32} />
                {orderEntrySegment}
                <Spacer height={32} />
                {tradingConnectedAndPortfolioReady && (
                  <CurrentOrdersList selectedAccount={selectedValidAccount} renderForApp={renderForApp} />
                )}
              </GridItem>
              <ShowAtBreakpoint greaterThanOrEqual={Breakpoint.Large}>
                <GridItem lg={1}>
                  <Box />
                </GridItem>
              </ShowAtBreakpoint>
              <GridItem xs={12} sm={6} lg={5}>
                {instrumentIdContainer?.infrontInstrument && orderDepth}
                <Spacer height={32} />
                {instrumentIdContainer?.infrontInstrument && orderLatestTrades}
                <Spacer height={32} />
                <Segment noContentPadding title={t('Frågor & svar')}>
                  <QuestionsAndAnswersList
                    htmlFromMarkdownFile={i18n.language === 'sv' ? orderQAHtmlSv : orderQAHtmlEn}
                  />
                </Segment>
                <Spacer height={32} />
              </GridItem>
            </GridContainer>
          )}
        </Box>
      )
    }

    return (
      <SelectedAccountIdProvider selectedAccountId={selectedValidAccount?.id}>{mainContent}</SelectedAccountIdProvider>
    )
  }
)

OrderDrawerAppAndWeb.displayName = 'OrderDrawerAppAndWeb'

export default OrderDrawer
