import { ResearchNews } from '@common/api/response'
import { CountryCodeFlag } from '@common/components/CountryCodeToFlag'
import { useInstrumentWithFallbackData } from '@common/hooks/useInstrumentWithFallbackData'
import { convertToInstrumentIdContainer } from '@common/instrumentIdContainer'

import { useTranslation } from 'react-i18next'

import { checkToday } from '@carnegie/digital-channels-frontend'
import {
  FlexRow,
  Icon,
  IconChange,
  IconCompass,
  IconTarget,
  ListItem,
  ListItemRow,
  Text,
  Tooltip,
} from '@carnegie/duplo'

import { CommissionedResearchIcon, GlobeIcon, MessageIcon, ResearchListItemIcon } from './ResearchListItemIcon'

type ResearchListItemProps = {
  researchNews: ResearchNews
  onClick?: () => void
  showResearchTargets?: boolean
  showCompanyNameAndTargets?: boolean
}

export const ResearchNewsListItem = ({
  researchNews,
  onClick,
  showResearchTargets = false,
  showCompanyNameAndTargets = true,
}: ResearchListItemProps) => {
  const { t } = useTranslation()

  const { analysts, type, relatedInstrument } = researchNews
  const analystName = analysts?.[0]?.name
  const isResearch = type === 'Research'
  const isCommissionedResearch = researchNews.productType?.id === 21

  const instrumentIdContainer = relatedInstrument?.infrontInstrument
    ? convertToInstrumentIdContainer(relatedInstrument?.infrontInstrument)
    : undefined

  const instrumentWithFallback = useInstrumentWithFallbackData(instrumentIdContainer, ['name'])

  return (
    <ListItem
      divider
      noDividerOffset
      support={
        <ResearchListItemIcon>
          {isCommissionedResearch ? (
            <CommissionedResearchIcon hasBackground />
          ) : researchNews.type === 'News' ? (
            <MessageIcon />
          ) : (
            <GlobeIcon />
          )}
        </ResearchListItemIcon>
      }
      onClick={onClick}
    >
      <ListItemRow
        title={
          <Text variant="overline" color="text-low-emphasis" truncate>
            {isResearch
              ? isCommissionedResearch
                ? t('Uppdragsanalys')
                : t('Analys') + (analystName ? ` - ${analystName}` : '')
              : t('Kommentar')}
          </Text>
        }
        value={
          <Text variant="overline" color="text-low-emphasis" truncate>
            {researchNews.created &&
              checkToday(new Date(researchNews.created), {
                short: true,
                showTodayAsTime: true,
              })}
          </Text>
        }
      />
      {showCompanyNameAndTargets && (
        <ListItemRow
          title={
            <FlexRow minWidth={0} alignItems="center" space={4}>
              {researchNews?.marketIso && (
                <CountryCodeFlag size={16} countryCode={researchNews.marketIso.toUpperCase()} />
              )}
              <Text truncate variant="subtitle1">
                {researchNews?.company?.name ?? instrumentWithFallback?.name}
              </Text>
            </FlexRow>
          }
          value={
            showResearchTargets && (
              <FlexRow space={4} alignItems="center">
                {researchNews?.researchTargets?.isTargetFlag && (
                  <Tooltip title={t('Ändrad riktkurs')}>
                    <Icon style={{ display: 'flex', minWidth: 0 }} size={16} icon={IconTarget} />
                  </Tooltip>
                )}
                {researchNews?.researchTargets?.isRatingFlag && (
                  <Tooltip title={t('Ändrad bolagsrekommendation')}>
                    <Icon style={{ display: 'flex', minWidth: 0 }} size={16} icon={IconCompass} />
                  </Tooltip>
                )}
                {researchNews?.researchTargets?.isEstimatesFlag && (
                  <Tooltip title={t('Ändrade EPS-estimat')}>
                    <Icon style={{ display: 'flex', minWidth: 0 }} size={16} icon={IconChange} />
                  </Tooltip>
                )}
              </FlexRow>
            )
          }
        />
      )}
      <ListItemRow
        title={
          <Text truncate color="text-default" variant="subtitle2">
            {researchNews?.headline}
          </Text>
        }
      />
    </ListItem>
  )
}
