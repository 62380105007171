import { BuySellMenuItem, PlaceFundOrderMenuItem } from '@common/components/ActionButton'
import { MoreMenuWatchlist } from '@common/components/MoreMenu'
import { SkeletonLoader } from '@common/components/SkeletonLoader'
import { useInfrontInstrument } from '@common/hooks/infront/sdk/useInfrontInstrument'
import { usePrevious } from '@common/hooks/usePrevious'
import { InstrumentIdContainer } from '@common/instrumentIdContainer'
import { fadeOutIn } from '@common/utils/animations'
import { getValueColor } from '@common/utils/colorUtils'

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { formatNumber } from '@carnegie/digital-channels-frontend'
import { ListItem, ListItemRow, Paragraph, Segment, Text, Tooltip } from '@carnegie/duplo'

import { motion, useAnimation } from 'framer-motion'
import { observer } from 'mobx-react-lite'

import { useOpenInstrumentDrawer } from '../../drawers/instrumentDrawer/useOpenInstrumentDrawer'

type RelatedInstrumentsProps = {
  instruments?: InstrumentIdContainer[]
}

export const RelatedInstruments = observer(({ instruments }: RelatedInstrumentsProps) => {
  const { t } = useTranslation()

  if (!instruments || instruments.length === 0) return null

  const sortedArray = instruments?.slice().sort((a, b) => {
    if (a.infrontInstrument?.ticker < b.infrontInstrument?.ticker) return -1
    if (a.infrontInstrument?.ticker > b.infrontInstrument?.ticker) return 1
    return 0
  })

  return (
    <Segment title={t('Relaterade värdepapper')} noContentPadding variant="contained" headingVariant="overline">
      <SkeletonLoader p={16} height={128} loading={!instruments}>
        {() => sortedArray?.map((instrument, index) => <RelatedInstrument key={index} instrument={instrument} />)}
      </SkeletonLoader>
    </Segment>
  )
})

type Props = {
  instrument: InstrumentIdContainer
}

const RelatedInstrument = observer(({ instrument }: Props) => {
  const { openInstrumentDrawer } = useOpenInstrumentDrawer()

  const { observableInstrument } = useInfrontInstrument(instrument.infrontInstrument, [
    InfrontSDK.SymbolField.FullName,
    InfrontSDK.SymbolField.Currency,
    InfrontSDK.SymbolField.ChangePercent,
    InfrontSDK.SymbolField.Last,
    InfrontSDK.BasicField.PriceDecimals,
  ])

  const name = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.FullName)
  const currency = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.Currency)

  const priceDecimals = observableInstrument?.getFieldValue(InfrontSDK.BasicField.PriceDecimals)

  const lastValueAnimation = useAnimation()
  const lastFormatted = formatNumber(observableInstrument?.getFieldValue(InfrontSDK.SymbolField.Last), {
    decimals: { min: 2, max: priceDecimals },
  })
  const prevLast = usePrevious(lastFormatted)

  const changeValueAnimation = useAnimation()
  const changeFormatted = formatNumber(observableInstrument?.getFieldValue(InfrontSDK.SymbolField.ChangePercent))
  const prevChange = usePrevious(changeFormatted)

  const isFund = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.SymbolType) === 'Funds'

  useEffect(() => {
    if (lastFormatted !== prevLast) {
      fadeOutIn(lastValueAnimation)
    }
    if (changeFormatted !== prevChange) {
      fadeOutIn(changeValueAnimation)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastFormatted, changeFormatted])

  if (!name) return null
  return (
    <ListItem
      onClick={() => {
        openInstrumentDrawer(instrument)
      }}
      action={
        <MoreMenuWatchlist size="medium" instrumentIdContainer={instrument}>
          {isFund ? (
            <>
              <PlaceFundOrderMenuItem instrumentIdContainer={instrument} fundOrderType={'buy'} />
              <PlaceFundOrderMenuItem instrumentIdContainer={instrument} fundOrderType={'sell'} />
            </>
          ) : (
            <BuySellMenuItem instrumentIdContainer={instrument} />
          )}
        </MoreMenuWatchlist>
      }
      divider={true}
    >
      <ListItemRow
        title={
          <Tooltip
            title={
              <Text variant="subtitle1" color="off-white">
                {name}
              </Text>
            }
          >
            <Text
              overflow="hidden"
              display="block"
              variant="subtitle1"
              css={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
            >
              {name}
            </Text>
          </Tooltip>
        }
        value={
          <>
            <motion.div style={{ display: 'flex' }} animate={changeValueAnimation}>
              <Text
                variant="subtitle1"
                color={getValueColor(observableInstrument?.getFieldValue(InfrontSDK.SymbolField.ChangePercent))}
              >
                {changeFormatted}
                <Text
                  variant="label1"
                  ml={2}
                  color={getValueColor(observableInstrument?.getFieldValue(InfrontSDK.SymbolField.ChangePercent))}
                >
                  %
                </Text>
              </Text>
            </motion.div>
          </>
        }
      />
      <ListItemRow
        title={<Paragraph variant="body2">{instrument.infrontInstrument.ticker}</Paragraph>}
        value={
          <Text variant="subtitle1">
            <motion.div animate={lastValueAnimation}>
              {lastFormatted}
              <Text variant="label1" color="bunker-main" ml={2}>
                {currency}
              </Text>
            </motion.div>
          </Text>
        }
      />
    </ListItem>
  )
})
