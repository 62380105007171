import { useTranslation } from 'react-i18next'

import { Paragraph } from '@carnegie/duplo'
import { Box, Breakpoint, FlexCol, FlexRow, Heading5, useBreakpoint } from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import FeedbackImage from '../../error/Feedback.png'

type Props = {
  title?: string
  body?: string
  bodyComponent?: React.ReactElement
}

export const SomethingWentWrong = observer(
  ({
    title = 'Något gick fel',
    body = 'Vi har problem med att hämta kontoinformation just nu. Vänligen försök igen senare.',
    bodyComponent,
  }: Props) => {
    const { t } = useTranslation()
    const breakpoint = useBreakpoint()
    const isExtraSmall = breakpoint <= Breakpoint.Xs

    return (
      <FlexRow>
        <FlexCol width={isExtraSmall ? 'full' : '1/2'} p={isExtraSmall ? 0 : 16}>
          {isExtraSmall && (
            <img css={{ flex: '1', margin: '0 auto', width: '75%' }} alt="feedback" src={FeedbackImage} />
          )}
          <Heading5 pt={isExtraSmall ? 16 : 0}>{t(title)}</Heading5>
          {bodyComponent ? bodyComponent : <Paragraph variant="body1">{t(body)}</Paragraph>}
        </FlexCol>
        {!isExtraSmall && (
          <FlexCol width="1/2" p={16}>
            <Box css={{ maxWidth: 466 }}>
              <img alt="feedback" src={FeedbackImage} />
            </Box>
          </FlexCol>
        )}
      </FlexRow>
    )
  }
)
