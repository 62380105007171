import { InstrumentFlag } from '@common/components/InstrumentFlag'
import { NoDataFallback } from '@common/components/NoDataFallback'
import { useInfrontInstrument } from '@common/hooks/infront/sdk/useInfrontInstrument'
import { useDocumentTitle } from '@common/hooks/useDocumentTitle'
import { useFeatures } from '@common/hooks/useFeatures'
import { useInstrumentWithFallbackData } from '@common/hooks/useInstrumentWithFallbackData'
import { usePrevious } from '@common/hooks/usePrevious'
import { InstrumentIdContainer } from '@common/instrumentIdContainer'
import { useWatchlistsStore } from '@common/stores/store'
import { fadeOutIn } from '@common/utils/animations'
import { getFormattedTimestamp } from '@common/utils/infront/getFormattedTimestamp'
import { css } from '@emotion/react'

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { getTranslatedMarketDescription } from '@carnegie/digital-channels-frontend'
import {
  Box,
  Breakpoint,
  Button,
  ButtonIcon,
  Checkbox,
  FlexCol,
  FlexRow,
  Heading3,
  Icon,
  IconButton,
  IconEye,
  IconEyeFilled,
  Menu,
  MenuItem,
  MenuItems,
  MenuToggle,
  SkeletonRect,
  Text,
  useBreakpoint,
} from '@carnegie/duplo'

import { useAnimation } from 'framer-motion'
import { observer } from 'mobx-react-lite'

import { useOpenFundOrderDrawer } from '../fundOrderDrawer/hooks/useOpenFundOrderDrawer'
import { useOpenOrderDrawer } from '../orderDrawer/useOpenOrderDrawer'
import { allowWatchAndBuy } from './rules'
import { CommonInstrumentType } from './useCommonInstrumentType'

type InstrumentHeaderProps = {
  instrumentIdContainer: InstrumentIdContainer
  instrumentType: CommonInstrumentType
  selectedAccountId?: string
}

/**
 * Tiden verkar inte ge något innan öppning av börsen.
 * Det ska gå att få ut om börsen är öppen eller ej för nordiska marknader.
 * Infront kikar på det. Utgår från öppen orderbok.
 */

export const InstrumentHeader = observer(
  ({ instrumentIdContainer, instrumentType, selectedAccountId }: InstrumentHeaderProps) => {
    const { t } = useTranslation()
    const { openOrderDrawer } = useOpenOrderDrawer()
    const { openFundOrderDrawer } = useOpenFundOrderDrawer()
    const watchlistsStore = useWatchlistsStore()
    const features = useFeatures()

    const showMarket = instrumentType === 'stocks' || instrumentType === 'derivatives' || instrumentType === 'unknown'
    const showTimeAndCurrency = instrumentType !== 'funds'
    const isFund = instrumentType === 'funds'

    const instrumentWithFallback = useInstrumentWithFallbackData(instrumentIdContainer, [
      'name',
      'marketplace',
      'currency',
    ])

    const { observableInstrument, isLoading } = useInfrontInstrument(
      instrumentIdContainer?.infrontInstrument,
      [
        showTimeAndCurrency && InfrontSDK.SymbolField.Currency,
        showTimeAndCurrency && InfrontSDK.SymbolField.TradeTime,
        showTimeAndCurrency && InfrontSDK.SymbolField.PreLastTradeDate,
      ].filter(Boolean)
    )

    const market = instrumentWithFallback?.marketplaceName
    const name = instrumentWithFallback?.name
    const currency = instrumentWithFallback.currency

    const timeFormatted =
      showTimeAndCurrency && !isLoading ? getFormattedTimestamp(observableInstrument, isFund) : undefined

    const timeValueAnimation = useAnimation()
    const prevTime = usePrevious(timeFormatted)
    const smDown = useBreakpoint() <= Breakpoint.Small

    //show delay for all stocksOrOther except for these markets
    //https://dev.azure.com/carnegieinvestmentbank/CarnegieIT/_workitems/edit/57231
    // const realTimeMarkets = [
    //   'XSTO',
    //   'XHEL',
    //   'XCSE',
    //   'SSME',
    //   'FNSE',
    //   'DSME',
    //   'FNDK',
    //   'FNFI',
    //   'FSME',
    //   'XSAT',
    //   'XNGM',
    //   'NSME',
    // ]

    // const isRealTimeMarket = showMarket && realTimeMarkets.indexOf(market) > -1

    useEffect(() => {
      if (timeFormatted !== prevTime) {
        fadeOutIn(timeValueAnimation)
      }
    }, [prevTime, timeFormatted, timeValueAnimation])

    useDocumentTitle(name ? name + ' - Instrument' : undefined)

    const marketDisplay =
      showMarket &&
      (market === undefined ? (
        <SkeletonRect height={24} width={192} />
      ) : (
        <FlexRow alignItems="center">
          {instrumentIdContainer?.infrontInstrument && (
            <Box mr={8}>
              <InstrumentFlag instrumentIdContainer={instrumentIdContainer} />
            </Box>
          )}
          <Text variant="overline">{getTranslatedMarketDescription(market)}</Text>
        </FlexRow>
      ))

    const nameDisplay =
      name !== undefined ? (
        <Heading3
          css={css`
            overflow-wrap: anywhere;
            padding-right: 41px;
          `}
        >
          <NoDataFallback>{name}</NoDataFallback>
        </Heading3>
      ) : (
        <SkeletonRect py={4} maxWidth={288} height={48} width="full" />
      )

    const timeAndCurrency =
      showTimeAndCurrency &&
      (timeFormatted === undefined ? (
        <SkeletonRect height={16} width={80} />
      ) : (
        <FlexRow alignItems="center">
          <Text variant="overline">
            <NoDataFallback>{timeFormatted}</NoDataFallback>
          </Text>
          <Text variant="overline" ml={4} mr={4}>
            -
          </Text>
          <Text variant="overline">
            <NoDataFallback>{currency}</NoDataFallback>
          </Text>
        </FlexRow>
      ))

    const canWatchAndBuy = allowWatchAndBuy(instrumentType)

    return (
      <FlexCol data-testid="instrument-drawer-header">
        {marketDisplay}
        {smDown ? (
          // Mobile
          <Box>
            <FlexRow alignItems="center" justifyContent="space-between" position="relative">
              {nameDisplay}
              {canWatchAndBuy && (
                <Menu
                  id="add-to-watchlist-mobile"
                  popperPlacementType="bottom-end"
                  muiPopperStyle={{
                    zIndex: 10,
                  }}
                  muiPaperStyle={{
                    maxHeight: 400,
                    overflowY: 'auto',
                  }}
                >
                  <MenuToggle>
                    <IconButton
                      color={
                        watchlistsStore?.isWatchingOneOrMoreInstruments(instrumentIdContainer)
                          ? 'status-success'
                          : 'bunker-main'
                      }
                      size="large"
                      variant="contained"
                      icon={
                        watchlistsStore?.isWatchingOneOrMoreInstruments(instrumentIdContainer) ? IconEyeFilled : IconEye
                      }
                      position="absolute"
                      right={0}
                    />
                  </MenuToggle>
                  <MenuItems>
                    <Box>
                      {watchlistsStore?.watchlists?.map((watchlist) => {
                        return (
                          <MenuItem
                            key={watchlist?.id}
                            onClick={async (event) => {
                              event.preventDefault()
                              if (watchlistsStore?.isChecked(watchlist, instrumentIdContainer)) {
                                watchlistsStore.deleteWatchlistItem(watchlist?.id, instrumentIdContainer)
                              } else {
                                watchlistsStore.addWatchlistItem(watchlist?.id, instrumentIdContainer)
                              }
                            }}
                          >
                            <Checkbox
                              label={''}
                              labelPlacement="end"
                              checked={watchlistsStore.isChecked(watchlist, instrumentIdContainer)}
                            />
                            <Text variant="subtitle1">{watchlist?.name}</Text>
                          </MenuItem>
                        )
                      })}
                    </Box>
                  </MenuItems>
                </Menu>
              )}
            </FlexRow>
            {timeAndCurrency}
          </Box>
        ) : (
          <>
            <FlexRow justifyContent="space-between">
              {nameDisplay}
              {canWatchAndBuy && (
                <FlexRow alignItems="center">
                  <Menu
                    popperPlacementType="bottom-end"
                    id="add-to-watchlist"
                    muiPopperStyle={{
                      zIndex: 10,
                    }}
                    muiPaperStyle={{
                      maxHeight: 400,
                      overflowY: 'auto',
                    }}
                  >
                    <MenuToggle>
                      {watchlistsStore?.isWatchingOneOrMoreInstruments(instrumentIdContainer) ? (
                        <Button
                          mr={16}
                          variant="tertiary"
                          size="large"
                          style={{ width: 204 }}
                          startIcon={<Icon color="status-success" icon={IconEyeFilled} />}
                        >
                          {t('Bevakad')}
                        </Button>
                      ) : (
                        <Button
                          mr={16}
                          variant="tertiary"
                          size="large"
                          style={{ width: 204 }}
                          startIcon={<ButtonIcon icon={IconEye} />}
                        >
                          {t('Bevaka')}
                        </Button>
                      )}
                    </MenuToggle>
                    <MenuItems>
                      {watchlistsStore?.watchlists?.map((watchlist) => {
                        return (
                          <MenuItem
                            key={watchlist?.id}
                            checked={watchlistsStore?.isChecked(watchlist, instrumentIdContainer)}
                            onClick={async (event) => {
                              event.preventDefault()
                              if (watchlistsStore?.isChecked(watchlist, instrumentIdContainer)) {
                                watchlistsStore.deleteWatchlistItem(watchlist?.id, instrumentIdContainer)
                              } else {
                                watchlistsStore.addWatchlistItem(watchlist?.id, instrumentIdContainer)
                              }
                            }}
                          >
                            {watchlist?.name}
                          </MenuItem>
                        )
                      })}
                    </MenuItems>
                  </Menu>
                  {isFund && features?.showFundOrder ? (
                    <>
                      <Button
                        minWidth={80}
                        onClick={() => {
                          openFundOrderDrawer(instrumentIdContainer, 'buy')
                        }}
                        size="large"
                        variant="dark-blue"
                        mr={16}
                      >
                        <Text color="text-light">{t('Köp')}</Text>
                      </Button>
                      <Button
                        minWidth={80}
                        onClick={() => {
                          openFundOrderDrawer(instrumentIdContainer, 'sell')
                        }}
                        size="large"
                        variant="red"
                      >
                        <Text color="text-light">{t('Sälj')}</Text>
                      </Button>
                    </>
                  ) : (
                    <Button
                      onClick={() => {
                        openOrderDrawer(instrumentIdContainer, selectedAccountId)
                      }}
                      variant="primary"
                      size="large"
                    >
                      {t('Köp/Sälj')}
                    </Button>
                  )}
                </FlexRow>
              )}
            </FlexRow>
            {timeAndCurrency}
          </>
        )}
      </FlexCol>
    )
  }
)

InstrumentHeader.displayName = 'InstrumentHeader'
