import { AccountsResponse } from '@common/api/response'

/**
 * If the user is coming from a non-tradable / non open account no account will be selected instead.
 * If the user is coming from a page that has no selected account associated with it we pick a good default
 * account instead.
 * @param account
 * @returns
 */
export function getValidAccount(account: AccountsResponse, accounts: AccountsResponse[]) {
  if (account && account.isTradeable && account.isAccountStatusOpen) {
    return account
  } else {
    // No account specified so we fall back to the first tradable account if we can
    const tradableAccounts = accounts?.filter((acc) => acc.isTradeable && acc.isAccountStatusOpen)

    if (tradableAccounts?.length === 1) {
      // If user comes from a non-tradable account, don't do the normal fallback to the one tradable.
      // The user can wrongly think the account they came from was tradable since it's preselected
      if (account && account.id !== tradableAccounts[0].id) {
        return undefined
      }
      return tradableAccounts[0]
    }
  }

  return undefined
}
