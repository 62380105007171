import { useInfrontStore } from '@common/stores/store'

export function useInfrontTradingStatus() {
  const infrontStore = useInfrontStore(true)

  return {
    tradingConnectedAndPortfolioReady: infrontStore
      ? infrontStore?.tradingConnectionStatus === 'connected-and-portfolio-ready'
      : undefined,
    tradingConnectionError: infrontStore ? infrontStore.tradingConnectionStatus === 'error' : undefined,
    isTgwEnabled: infrontStore ? infrontStore.isTgwEnabled : undefined,
    activePortfolioName: infrontStore ? infrontStore.activePortfolioName : undefined,
    // This can be useful for widgets that will still work during reconnecting (after a disconnect)
    lostConnectionOrReconnecting: infrontStore
      ? infrontStore.tradingConnectionStatus === 'reconnecting' ||
        infrontStore.tradingConnectionStatus === 'lost-connection'
      : undefined,
  }
}
