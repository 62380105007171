import { useDuploTheme } from '@carnegie/duplo'

import StarSvg from './favorite_star.svg'

type FavoriteStarProps = {
  className?: string
  filled: boolean
  size?: FavoriteStarSize
  onClick?: () => void
}

type FavoriteStarSize = 'small' | 'medium' | 'large'

const sizeMap: Record<FavoriteStarSize, number> = { large: 24, medium: 20, small: 14 }

const goldenNightmare = '#C1A60B'

export const FavoriteStar = ({ className, filled, size = 'medium', onClick }: FavoriteStarProps) => {
  const duploTheme = useDuploTheme()

  const content = (
    <StarSvg
      width={sizeMap[size]}
      height={sizeMap[size]}
      stroke={goldenNightmare}
      strokeWidth={1}
      fill={filled ? duploTheme.colors['golden-dream'] : 'transparent'}
    />
  )

  return onClick ? (
    <button
      className={className}
      onClick={(evt) => {
        onClick()

        // So we can have it in dropdowns etc
        evt.stopPropagation()
      }}
    >
      {content}
    </button>
  ) : (
    <div className={className}>{content}</div>
  )
}
