import { AccountsResponse, DocumentInfoModel } from '@common/api/response'
import { SkeletonLoader } from '@common/components/SkeletonLoader'
import { SmartTable } from '@common/components/SmartTable'
import { usePageParam } from '@common/hooks/usePageParam'
import { scrollToTop } from '@common/utils/document'

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Banner, Box, Breakpoint, Spacer, Text, useBreakpoint } from '@carnegie/duplo'

import { useReportStatements } from '@/pages/profile/useReportStatements'

import { observer } from 'mobx-react-lite'

import { ReportStatementMobileRow } from './ReportStatementMobileRow'
import { ReportStatementRow } from './ReportStatementRow'

type ReportsStatementProps = {
  selectedAccount: AccountsResponse
}

const STORAGE_KEY = `REPORTS_STATEMENT`
export type ReportStatementColumnId = 'name' | 'statementPeriodEnd' | 'accountNumber' | 'createdDate'

const PAGE_SIZE = 25

export const ReportsStatement = observer(({ selectedAccount }: ReportsStatementProps) => {
  const breakpoint = useBreakpoint()
  const isExtraSmallScreen = breakpoint <= Breakpoint.Xs
  const { t } = useTranslation()
  const navigate = useNavigate()
  const page = usePageParam()

  const [downloadingDocumentError, setDownloadingDocumentError] = useState(false)

  // We do not use the pagination right now since it won't allow us to sort the table across pages
  const { items, error } = useReportStatements(selectedAccount?.id, 1, 200)

  return (
    <SkeletonLoader
      error={error}
      p={16}
      loading={!items}
      noDataLoaded={items?.length === 0}
      noDataComponent={<Text variant="body2">{t('Det finns inga kontoutdrag för valt konto.')}</Text>}
    >
      {() => (
        <Box>
          {downloadingDocumentError && (
            <Box p={16}>
              <Banner
                severity="critical"
                title={t('Något gick fel')}
                description={t('Filen kan inte laddas ner. Kontakta oss för mer information.')}
                onClose={() => {
                  setDownloadingDocumentError(false)
                }}
              />
            </Box>
          )}
          <SmartTable<DocumentInfoModel, ReportStatementColumnId>
            data={items}
            sessionStorageKey={STORAGE_KEY}
            rowKey={(row) => row.documentId + row.createdDate}
            defaultSortBy="createdDate"
            page={page}
            pageSize={PAGE_SIZE}
            onPageChange={(currentPage) => {
              navigate(`/profile/reports/statement/${selectedAccount?.id}/${currentPage}`)
              scrollToTop()
            }}
            columns={[
              {
                id: 'name',
                width: 'minmax(0,2fr)',
                renderHeader: () => t('Namn'),
                sortBy: ({ row: statement }) =>
                  `${t(statement?.name.toString())} ${statement?.suffix}`.replaceAll('+', '_'),
                renderCell: ({ row: statement }) => (
                  <ReportStatementRow statement={statement} selectedAccount={selectedAccount} cell="name" />
                ),
              },
              {
                id: 'statementPeriodEnd',
                width: '1fr',
                renderHeader: () => t('Period'),
                sortBy: ({ row }) => row.statementPeriodEnd,
                renderCell: ({ row: statement }) => (
                  <ReportStatementRow
                    statement={statement}
                    selectedAccount={selectedAccount}
                    cell="statementPeriodEnd"
                  />
                ),
              },
              {
                id: 'accountNumber',
                width: '1fr',
                renderHeader: () => t('Konto'),
                renderCell: ({ row: statement }) => (
                  <ReportStatementRow statement={statement} selectedAccount={selectedAccount} cell="accountNumber" />
                ),
              },
              {
                id: 'createdDate',
                width: '1fr',
                renderHeader: () => t('Skapad'),
                sortBy: ({ row }) => row.createdDate,
                renderCell: ({ row: statement }) => (
                  <ReportStatementRow statement={statement} selectedAccount={selectedAccount} cell="createdDate" />
                ),
                defaultSortOrder: 'desc',
              },
            ]}
            renderMode={isExtraSmallScreen ? 'custom' : 'table'}
            renderRow={
              isExtraSmallScreen
                ? ({ row: statement, index }) => {
                    // Mobile table
                    return <ReportStatementMobileRow key={index} statement={statement} />
                  }
                : undefined
            }
          />
          <Spacer height={2} />
        </Box>
      )}
    </SkeletonLoader>
  )
})
