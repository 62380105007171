import { SkeletonLoader } from '@common/components/SkeletonLoader'
import { SmartTable } from '@common/components/SmartTable'
import { useCancelTransfer, useOngoingTransfers } from '@common/hooks/useTransfer'

import { useTranslation } from 'react-i18next'

import { Banner, Box, Breakpoint, Paragraph, Segment, Spacer, Text, useBreakpoint } from '@carnegie/duplo'

import OngoingTransferRow from './OngoingTransferRow'
import OngoingTransferRowMobile from './OngoingTransferRowMobile'
import { useTransferPage } from './TransferPageContext'

const STORAGE_KEY = `ONGOING_TRANSFERS`

const GRID_COLUMN_FULL = 'minmax(0,1fr)'

const OngoingTransfers = () => {
  const { t } = useTranslation()
  const breakpoint = useBreakpoint()
  const isExtraSmallScreen = breakpoint <= Breakpoint.Xs

  const { ongoingTransfers, networkError } = useOngoingTransfers()
  const { cancelTransfer, cancelTransferError, clearTransferError } = useCancelTransfer()

  const {
    page,
    setPage,
    setRegisteringTransfer,
    accounts: { updateAccounts },
  } = useTransferPage()

  const initiateCancel = async (transferOrderId: number) => {
    await cancelTransfer(transferOrderId)
    setRegisteringTransfer(null)
    updateAccounts()
  }

  return (
    <>
      <Paragraph variant="subtitle1" bold>
        {t('Tidigare överföringar')}
      </Paragraph>
      <Paragraph variant="body1">{t('Här visas överföringar gjorda de senaste sju dagarna.')}</Paragraph>
      <Spacer height={16} />
      {cancelTransferError && (
        <>
          <Banner
            severity="critical"
            title={t('Överföringen kunde inte tas bort')}
            description={t('Försök igen, eller kontakta oss om felet kvarstår.')}
            onClose={() => clearTransferError()}
          />
          <Spacer height={16} />
        </>
      )}
      <Segment noContentPadding>
        <SkeletonLoader
          height={300}
          loading={!ongoingTransfers}
          error={networkError}
          noDataLoaded={ongoingTransfers?.length === 0}
          noDataComponent={
            <Box p={16}>
              <Text variant="body1" color="text-low-emphasis">
                {t('Det finns inga överföringar för de senaste sju dagarna.')}
              </Text>
            </Box>
          }
        >
          {() => (
            <SmartTable
              renderMode={isExtraSmallScreen ? 'custom' : 'table'}
              sessionStorageKey={STORAGE_KEY}
              data={ongoingTransfers}
              defaultSortBy="created"
              pageSize={5}
              page={page}
              onPageChange={(currentPage) => setPage(currentPage)}
              columns={[
                {
                  id: 'created',
                  width: GRID_COLUMN_FULL,
                  defaultSortOrder: 'desc',
                  renderHeader: () => t('Skapad'),
                  sortBy: ({ row }) => new Date(row.created),
                },
                {
                  id: 'from',
                  width: GRID_COLUMN_FULL,
                  renderHeader: () => t('Från'),
                  sortBy: ({ row }) => row.fromAccountNo,
                },
                {
                  id: 'to',
                  width: 'minmax(0,2fr)',
                  renderHeader: () => t('Till'),
                  sortBy: ({ row }) => row.toAccountNo,
                },
                {
                  id: 'amount',
                  width: GRID_COLUMN_FULL,
                  align: 'right',
                  renderHeader: () => t('Belopp'),
                  sortBy: ({ row }) => row.amount,
                },
                {
                  renderHeader: () => '',
                  id: 'spacer',
                  width: 16,
                },
                {
                  id: 'status',
                  width: 48,
                  renderHeader: () => t('Status'),
                  sortBy: ({ row }) => row.displayState,
                },
                {
                  renderHeader: () => '',
                  id: 'button',
                  width: 48,
                },
              ]}
              renderRow={({ row }) =>
                isExtraSmallScreen ? (
                  <OngoingTransferRowMobile key={row.id} ongoingTransfer={row} cancelTransfer={initiateCancel} />
                ) : (
                  <OngoingTransferRow key={row.id} ongoingTransfer={row} cancelTransfer={initiateCancel} />
                )
              }
            />
          )}
        </SkeletonLoader>
      </Segment>
    </>
  )
}

export default OngoingTransfers
