import { ShowAll } from '@common/components/ShowAll'
import SubscriptionButton from '@common/components/SubscriptionButton'
import johnnysFejs from '@common/components/subscription_button_johnny.png'
import { SystemMessageList } from '@common/components/systemMessages'
import { useFeatures } from '@common/hooks/useFeatures'
import { useShortTerm } from '@common/hooks/useSubscriptions'
import { fireTrackEvent } from '@common/utils/analyticsEvent'

import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { formatDate } from '@carnegie/digital-channels-frontend'
import {
  Breakpoint,
  GridContainer,
  GridItem,
  Heading3,
  Segment,
  SegmentWithTabs,
  Spacer,
  Text,
  useBreakpoint,
} from '@carnegie/duplo'

import useOpenSubscriptionDrawer from '@/drawers/subscriptionDrawer/useOpenSubscriptionDrawer'

import { addDays } from 'date-fns'
import { observer } from 'mobx-react-lite'

import { useContentAnalysis } from '../shared/useContentAnalysis'
import { CorporateEvents } from './CorporateEvents'
import { EpsRevisionsList } from './EpsRevisionsList'
import { RatingUpdates } from './RatingUpdates'
import { ResearchAndComments } from './ResearchAndComments'
import { TargetPriceRevisions } from './TargetPriceRevisions'
import { TopPickResearch } from './TopPickResearch'
import TopPickStocks from './TopPickStocks'

type ExploreResearchPageLayoutProps = {
  researchAndComments?: ReactNode
  ratingUpdates?: ReactNode
  targetPriceRevisions?: ReactNode
  epsRevisions?: ReactNode
  topPickResearch?: ReactNode
  corporateEvents?: ReactNode
  topPickStocks?: ReactNode
  shortTerm?: ReactNode
}

const ExploreResearchPageLayout = ({
  researchAndComments,
  ratingUpdates,
  targetPriceRevisions,
  epsRevisions,
  topPickResearch,
  corporateEvents,
  topPickStocks,
  shortTerm,
}: ExploreResearchPageLayoutProps) => {
  const breakpoint = useBreakpoint()
  const { t } = useTranslation()

  const introText = (
    <Text variant="body1">
      {t(
        'Här finns Carnegies analyser och kommentarer samlade. Du kan läsa rapporter, se utvalda värdepapper och få en överblick över de senaste bolagsuppdateringarna.'
      )}
    </Text>
  )

  return breakpoint <= Breakpoint.Small ? (
    <>
      {introText && <GridItem xs={12}>{introText}</GridItem>}
      {researchAndComments && <GridItem xs={12}>{researchAndComments}</GridItem>}
      {topPickResearch && <GridItem xs={12}>{topPickResearch}</GridItem>}
      {corporateEvents && <GridItem xs={12}>{corporateEvents}</GridItem>}
      {shortTerm && <GridItem xs={12}>{shortTerm}</GridItem>}
      {ratingUpdates && <GridItem xs={12}>{ratingUpdates}</GridItem>}
      {targetPriceRevisions && <GridItem xs={12}>{targetPriceRevisions}</GridItem>}
      {topPickStocks && <GridItem xs={12}>{topPickStocks}</GridItem>}
      {epsRevisions && <GridItem xs={12}>{epsRevisions}</GridItem>}
    </>
  ) : (
    <>
      <GridItem xs={12} md={8}>
        {introText}
      </GridItem>
      <GridItem xs={12} md={8}>
        <GridContainer>
          {researchAndComments && <GridItem xs={12}>{researchAndComments}</GridItem>}
          {ratingUpdates && (
            <GridItem xs={12} lg={6}>
              {ratingUpdates}
            </GridItem>
          )}
          {targetPriceRevisions && (
            <GridItem xs={12} lg={6}>
              {targetPriceRevisions}
            </GridItem>
          )}
          {epsRevisions && <GridItem xs={12}>{epsRevisions}</GridItem>}
        </GridContainer>
      </GridItem>
      <GridItem xs={12} md={4}>
        <GridContainer>
          {topPickResearch && <GridItem xs={12}>{topPickResearch}</GridItem>}
          {corporateEvents && <GridItem xs={12}>{corporateEvents}</GridItem>}
          {shortTerm && <GridItem xs={12}>{shortTerm}</GridItem>}
          {topPickStocks && <GridItem xs={12}>{topPickStocks}</GridItem>}
        </GridContainer>
      </GridItem>
    </>
  )
}

export const CorporateEventsSegment = () => {
  const { t } = useTranslation()
  const features = useFeatures()

  return (
    <Segment
      title={t('Bolagshändelser')}
      noContentPadding
      action={
        features.showCorporateEvents && (
          <ShowAll
            onClick={() => {
              fireTrackEvent('Market', 'click_showall_corporateactions')
            }}
            to={`/market/corporate-events`}
          />
        )
      }
    >
      <CorporateEvents />
    </Segment>
  )
}

const ExploreResearchPage = observer(() => {
  const { t } = useTranslation()

  const { researchNews } = useContentAnalysis({
    limit: 6,
    page: 1,
  })

  const { openSubscriptionDrawer } = useOpenSubscriptionDrawer()
  const { shortTerms } = useShortTerm(1, 1)
  const latestShortTerm = shortTerms && shortTerms[0]
  const isOlderThanTenDays = !!latestShortTerm && new Date() > addDays(new Date(latestShortTerm?.publishedDate), 10)

  return (
    <>
      <GridItem xs={12}>
        <Heading3>{t('Utforska')}</Heading3>
        <Spacer height={8} />
        <SystemMessageList />
      </GridItem>
      <ExploreResearchPageLayout
        researchAndComments={
          <Segment
            title={t('Analyser & kommentarer')}
            noContentPadding
            action={
              <ShowAll
                onClick={() => fireTrackEvent('Research', 'click_showall_researchandcomments')}
                to="/research/research-and-comments"
              />
            }
          >
            <ResearchAndComments researchNews={researchNews} />
          </Segment>
        }
        topPickResearch={
          <Segment variant="uncontained" title={t('Utvalda analyser')}>
            <TopPickResearch />
          </Segment>
        }
        corporateEvents={<CorporateEventsSegment />}
        ratingUpdates={
          <Segment
            noContentPadding
            title={t('Ändrade bolagsrekommendationer')}
            action={<ShowAll to="/research/research-and-comments?isRatingFlag=true" />}
          >
            <RatingUpdates />
          </Segment>
        }
        targetPriceRevisions={
          <Segment
            title={t('Ändrade riktkurser')}
            noContentPadding
            action={<ShowAll to="/research/research-and-comments?isTargetFlag=true" />}
          >
            <TargetPriceRevisions />
          </Segment>
        }
        epsRevisions={
          <Segment
            title={t('Ändrade EPS-estimat')}
            noContentPadding
            action={<ShowAll to="/research/research-and-comments?isEstimatesFlag=true" />}
          >
            <EpsRevisionsList />
          </Segment>
        }
        topPickStocks={
          <SegmentWithTabs title={t('Utvalda värdepapper')} variant="contained" headingVariant="overline">
            <TopPickStocks />
          </SegmentWithTabs>
        }
        shortTerm={
          latestShortTerm && !isOlderThanTenDays ? (
            <SubscriptionButton
              onClick={() => {
                fireTrackEvent('Subscription', `open_subscription_${latestShortTerm.subscriptionType}`, {
                  id: latestShortTerm.marketingEmailId,
                  subscriptionCategory: latestShortTerm.subscriptionType,
                })
                openSubscriptionDrawer(latestShortTerm)
              }}
              title={t('Läs senaste Short Term')}
              backgroundImage={johnnysFejs}
              overline={`SHORT TERM | ${formatDate(latestShortTerm.publishedDate, 'HH:mm/yesterday HH:mm/yyyy-MM-dd')}`}
            />
          ) : null
        }
      />
    </>
  )
})

ExploreResearchPage.displayName = 'ExploreResearchPage'

export default ExploreResearchPage
