import { useTranslation } from 'react-i18next'

import { formatNumber } from '@carnegie/digital-channels-frontend'
import { ListItem, ListItemRow, MobileTableRow, Segment, Text } from '@carnegie/duplo'

import { EpsRevisionsMobileTableRowData } from '../researchExplore/EpsRevisionsList'
import { RecommendationSquircle } from '../researchExplore/RecommendationSquircle'
import { ResearchListItemIcon } from '../researchExplore/ResearchListItemIcon'
import { ResearchNewsItem } from '../shared/researchNewsItem'
import { useResearchAndComments } from '../shared/useResearchAndComments'
import { useResearchTargetItems } from '../shared/useResearchTargetItems'

type Props = {
  item: ResearchNewsItem
}

export const UpdateBasedOnResearch = ({ item }: Props) => {
  const { t } = useTranslation()

  const recommendation = item?.recommendation?.name ?? ''
  const previousRecommendation = item?.previousRecommendation?.name ?? ''
  const researchTargets = item?.researchTargets
  const { translateRecommendation } = useResearchAndComments()

  const { arrowIcon, iconBg, iconTextColor, changeText } = useResearchTargetItems(item?.recommendationArrowDirection)

  const shouldShowSegment =
    researchTargets?.isEstimatesFlag || researchTargets?.isRatingFlag || researchTargets?.isTargetFlag

  return shouldShowSegment ? (
    <Segment noContentPadding title={t('Uppdatering baserat på analysen')}>
      {researchTargets?.isTargetFlag && researchTargets.targetTo !== null && (
        <ListItem
          noDividerOffset
          divider
          support={
            <ResearchListItemIcon backgroundColor={iconBg} color={iconTextColor}>
              {arrowIcon}
            </ResearchListItemIcon>
          }
        >
          <ListItemRow
            title={<Text variant="subtitle1">{t('Riktkurs')}</Text>}
            value={
              <Text variant="subtitle2" truncate color="text-default">
                {formatNumber(researchTargets.targetTo)}
                <Text ml={2} variant="label2">
                  {researchTargets.targetCcy}
                </Text>
              </Text>
            }
          />
          <ListItemRow
            title={
              <Text truncate color="text-default" variant="support2">
                {changeText}
              </Text>
            }
            value={
              <Text variant="support2" truncate color="text-low-emphasis">
                ({formatNumber(researchTargets.targetFrom)})
              </Text>
            }
          />
        </ListItem>
      )}
      {researchTargets?.isRatingFlag && (
        <ListItem
          noDividerOffset
          divider
          support={recommendation && <RecommendationSquircle size="large" type={recommendation} />}
        >
          <ListItemRow title={<Text variant="subtitle1">{t('Rekommendation')}</Text>} />
          <ListItemRow
            title={
              <Text truncate color="text-default" variant="support2">
                {`${t('Ändrad från')} ${translateRecommendation(previousRecommendation)} ${t(
                  'till'
                )} ${translateRecommendation(recommendation)}`}
              </Text>
            }
          />
        </ListItem>
      )}
      {researchTargets?.isEstimatesFlag && researchTargets?.estimatesFirstYear !== null && (
        <MobileTableRow title={<Text variant="subtitle2">{t('EPS-estimat')}</Text>}>
          <EpsRevisionsMobileTableRowData researchTargets={researchTargets} />
        </MobileTableRow>
      )}
    </Segment>
  ) : (
    <></>
  )
}
