import { SystemMessageList } from '@common/components/systemMessages'
import { useFeatures } from '@common/hooks/useFeatures'
import { useUpdateUrlNoReload } from '@common/hooks/useUpdateUrlNoReload'

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useMatch } from 'react-router-dom'

import {
  Box,
  FlexRow,
  GridContainer,
  GridItem,
  Heading3,
  Link,
  Paragraph,
  SegmentWithTabs,
  SimpleTabs,
  Spacer,
  useResponsiveProps,
} from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import Deposit from './Deposit'
import OngoingTransfers from './OngoingTransfers'
import Transfer, { TransferType } from './Transfer'
import TransferFAQDeposit from './TransferFAQDeposit'
import TransferFAQInternalExternal from './TransferFAQInternalExternal'
import { TransferPageProvider } from './TransferPageContext'

export enum TransferDrawerTabs {
  DEPOSIT = 'tab-deposit',
  INTERNAL_DEPOSIT = 'tab-internal-deposit',
  WITHDRAWAL = 'tab-withdrawal',
}

const isTab = (tab: string): tab is TransferDrawerTabs =>
  Object.values(TransferDrawerTabs).includes(tab as TransferDrawerTabs)

const TransferNotAvailable = ({ tab }: { tab: TransferDrawerTabs }) => {
  const { t } = useTranslation()
  return (
    <FlexRow alignItems="center" p={16}>
      <Paragraph variant="body2">
        {t('Vi jobbar kontinuerligt med vår nya webb. Tills vidare,')}&nbsp;
        <Link variant="subtitle2" to="/profile/contact">
          {t('kontakta oss')}
        </Link>
        &nbsp;
        {tab === TransferDrawerTabs.INTERNAL_DEPOSIT
          ? t('för att få hjälp med att göra en överföring mellan dina konton.')
          : tab === TransferDrawerTabs.WITHDRAWAL
          ? t('för att få hjälp med att göra ett uttag.')
          : null}
      </Paragraph>
    </FlexRow>
  )
}

const TransfersDrawer = observer(() => {
  const { t } = useTranslation()
  const rp = useResponsiveProps()
  const features = useFeatures()
  const { updateUrlNoReload } = useUpdateUrlNoReload()

  const matchAccountPath = useMatch('/accounts/:accountId/d/transfers/:tab?')
  const matchOverviewPath = useMatch('/overview/accounts/d/transfers/:tab?')
  const location = useLocation()

  const isOverviewPath = location.pathname.startsWith('/overview')
  const match = isOverviewPath ? matchOverviewPath : matchAccountPath

  const accountId = matchAccountPath?.params?.accountId || ''
  const initialDefaultTab = match?.params?.tab || ''

  const [tab, setTab] = useState<TransferDrawerTabs>(
    initialDefaultTab && isTab(initialDefaultTab) ? initialDefaultTab : TransferDrawerTabs.DEPOSIT
  )
  const basePath = isOverviewPath ? '/overview/accounts/' : `/accounts/${accountId}/`

  return (
    <TransferPageProvider includeDefaultAccounts={tab === TransferDrawerTabs.WITHDRAWAL}>
      <GridContainer>
        <GridItem xs={12}>
          <Box spaceY={16}>
            <Box px={rp([8, 0])}>
              <Heading3>{t('Överföringar')}</Heading3>
            </Box>
            <SystemMessageList segmentId="transfers" />
          </Box>
        </GridItem>
        <GridItem xs={12} lg={8}>
          <SegmentWithTabs variant="contained" headingVariant="overline">
            <SimpleTabs
              value={tab}
              onChange={(newTab) => {
                if (isTab(newTab)) {
                  updateUrlNoReload(`${basePath}d/transfers/${newTab}`)
                  setTab(newTab)
                }
              }}
              tabs={[
                {
                  value: TransferDrawerTabs.DEPOSIT,
                  label: t('Insättning'),
                  content: <Deposit />,
                },
                {
                  value: TransferDrawerTabs.INTERNAL_DEPOSIT,
                  label: t('Intern överföring'),
                  content: features.showTransfers ? (
                    <Transfer transferType={TransferType.INTERNAL} defaultAccount={accountId} />
                  ) : (
                    <TransferNotAvailable tab={tab} />
                  ),
                },
                {
                  value: TransferDrawerTabs.WITHDRAWAL,
                  label: t('Uttag'),
                  content: features.showTransfers ? (
                    <Transfer transferType={TransferType.EXTERNAL} defaultAccount={accountId} />
                  ) : (
                    <TransferNotAvailable tab={tab} />
                  ),
                },
              ]}
            />
          </SegmentWithTabs>
          {tab !== TransferDrawerTabs.DEPOSIT && features.showTransfers ? (
            <>
              <Spacer height={32} />
              <OngoingTransfers />
            </>
          ) : null}
          <Spacer height={16} />
          <FlexRow spaceX={4}>
            <Paragraph variant="body2" color="text-low-emphasis">
              {t('För att se genomförda överföringar gå till')}
            </Paragraph>
            <Link to={`/overview/transactions`} variant="body2">
              {t('Transaktioner')}
            </Link>
          </FlexRow>
        </GridItem>
        <GridItem xs={12} lg={4}>
          {tab === TransferDrawerTabs.DEPOSIT ? <TransferFAQDeposit /> : <TransferFAQInternalExternal />}
        </GridItem>
      </GridContainer>
    </TransferPageProvider>
  )
})

export default TransfersDrawer
