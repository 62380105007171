import { NewsHeadline } from '@common/hooks/infront/sdk/useInfrontNews'

import { checkToday } from '@carnegie/digital-channels-frontend'
import { ListItem, ListItemRow, Text } from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import { useOpenNewsDrawer } from '../../../drawers/newsDrawer/useOpenNewsDrawer'

type Props = {
  headlines: NewsHeadline[]
  onClick?: () => void
}

type MarketNewsHeadlineProps = {
  headline: NewsHeadline
  onClick?: () => void
}

const MarketNewsHeadline = observer(({ headline, onClick }: MarketNewsHeadlineProps) => {
  const { openNewsDrawer } = useOpenNewsDrawer()

  const newsItemSelected = async (headline: NewsHeadline) => {
    onClick && onClick()
    openNewsDrawer(headline.id, headline?.infrontNewsHeadline)
  }

  return (
    <ListItem data-testid="market-news-headline" divider onClick={() => newsItemSelected(headline)}>
      <ListItemRow
        title={<Text variant="overline">{headline.source}</Text>}
        value={<Text variant="overline">{checkToday(headline.dateTime)}</Text>}
      />
      <ListItemRow title={<Text variant="subtitle2">{headline.headline}</Text>} />
    </ListItem>
  )
})

export const MarketNews = observer(({ headlines, onClick }: Props) => {
  return (
    <>
      {headlines.map((headline: NewsHeadline) => {
        return <MarketNewsHeadline onClick={onClick} key={headline.id} headline={headline} />
      })}
    </>
  )
})
