import { NoDataDash } from '@common/components/NoDataFallback'

import React from 'react'

import { FlexCol, SkeletonRect, Text, Tooltip } from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

type PerformanceLabelProps = {
  title: string
  value: string
  showTooltip: boolean
  className?: string
  loading: boolean
}
/**
 * A more generic version of the LastPrice and PercentageChange components.
 */

export const PerformanceLabel = observer(({ showTooltip, title, value, className, loading }: PerformanceLabelProps) => {
  return (
    <FlexCol className={className}>
      {!loading ? (
        <>
          <Text variant="label1">{title}</Text>
          {showTooltip ? (
            <Tooltip title={title}>
              <Text variant="subtitle1">{value}</Text>
            </Tooltip>
          ) : (
            <Text variant="subtitle1">{value !== undefined && value != null ? value : <NoDataDash />}</Text>
          )}
        </>
      ) : (
        <SkeletonRect height="full" width="full" />
      )}
    </FlexCol>
  )
})
PerformanceLabel.displayName = 'PerformanceLabel'
