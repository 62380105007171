import { DuploColor } from '@carnegie/duplo'

import { getValueColor } from './colorUtils'

type Props = {
  value: number
  children: (color: DuploColor) => JSX.Element
}

export const ValueColor = ({ value, children }: Props) => {
  const color = getValueColor(value)
  return children(color)
}
