import { useMemo } from 'react'

import { useFormatDate } from '@carnegie/digital-channels-frontend'
import { Box, DuploColor, FlexCol, Heading4, Heading5, Text } from '@carnegie/duplo'

import { Squircle } from './Squircle'

type DateSquircleProps = {
  size?: 'medium' | 'large'
  date: Date
  highlighted?: boolean
}

const DateSquircle = ({ size = 'medium', date, highlighted }: DateSquircleProps) => {
  const { formatDate } = useFormatDate()
  const monthLabel = useMemo(() => formatDate(date, 'MMM'), [date, formatDate])
  const dayLabel = useMemo(() => formatDate(date, 'dd'), [date, formatDate])

  let squircleSize, MonthComponent, DayComponent

  const monthColor: DuploColor = highlighted ? 'text-tooltip' : 'paprika-main'
  const dayColor: DuploColor = highlighted ? 'text-tooltip' : 'carnegie-dark-blue'
  const bgColor: DuploColor = highlighted ? 'paprika-main' : 'carnegie-dark-pink'

  switch (size) {
    case 'medium':
      squircleSize = 40
      DayComponent = (
        <Text variant="label2" color={dayColor} css={{ lineHeight: '10px', textTransform: 'uppercase' }}>
          {monthLabel}
        </Text>
      )
      MonthComponent = (
        <Heading5 color={monthColor} css={{ lineHeight: '24px' }}>
          {dayLabel}
        </Heading5>
      )
      break
    case 'large':
      squircleSize = 64
      DayComponent = (
        <Text variant="overline" color={dayColor}>
          {monthLabel}
        </Text>
      )
      MonthComponent = (
        <Heading4 color={monthColor} css={{ lineHeight: '32px' }}>
          {dayLabel}
        </Heading4>
      )
      break
  }

  return (
    <Squircle size={squircleSize} css={{ position: 'relative' }}>
      <Box
        bottom={0}
        top={0}
        right={0}
        left={0}
        position="absolute"
        backgroundColor={bgColor}
        css={{ opacity: 0.85 }}
      />
      <FlexCol alignItems="center" justifyContent="center" height="full" width="full" css={{ zIndex: 1 }}>
        {DayComponent}
        {MonthComponent}
      </FlexCol>
    </Squircle>
  )
}

export default DateSquircle
