import { AccountsResponse, CarnegieInstrument, FundOrderType, Side } from '@common/api/response'
import { fireTrackEvent } from '@common/utils/analyticsEvent'

import { useTranslation } from 'react-i18next'

import { useSimpleOrderInsert } from '@/pages/overview/order/useSimpleOrder'

type UseOrderSubmitProps = {
  selectedAccount: AccountsResponse
  carnegieInstrument: CarnegieInstrument
  fundOrderType: FundOrderType
}

export const useFundOrderSubmit = ({ selectedAccount, carnegieInstrument, fundOrderType }: UseOrderSubmitProps) => {
  const { insertOrder } = useSimpleOrderInsert()
  const { i18n } = useTranslation()

  const submitOrder = async (numericValue: number, sellAll: boolean) => {
    const analyticsData = {
      instrument: carnegieInstrument?.name,
      side: fundOrderType === 'buy' ? Side.Buy : Side.Sell,
    }

    try {
      const response = await insertOrder({
        accountId: selectedAccount?.id,
        language: i18n.language,
        instrumentId: carnegieInstrument?.instrumentIdCarnegie,
        side: fundOrderType === 'buy' ? Side.Buy : Side.Sell,
        settlementCurrency: 'SEK',
        sellAll: fundOrderType === 'sell' && sellAll,
        amount: sellAll ? undefined : numericValue,
      })

      if (response.orderId && !response.orderError) {
        fireTrackEvent('Order', 'fund_order_successful', analyticsData)
      } else if (response.orderError) {
        fireTrackEvent('Order', 'fund_order_rejected', analyticsData)
      }

      return response
    } catch (err) {
      fireTrackEvent('Order', 'fund_order_error', analyticsData)
      throw err
    }
  }

  return { submitOrder }
}
