import { useInfrontStore } from '../../../stores/store'

/**
 * ## ⚠️IMPORTANT⚠️
 * ### Requires wrapping component in MobX `observer(...)`
 */
export function useInfrontUI() {
  const infrontStore = useInfrontStore()

  // Because hasLoaded is observable the component will be re-rendered when hasLoaded gets changed which will call this hook again
  if (infrontStore.hasLoaded) {
    return infrontStore.infrontUi
  } else {
    return undefined
  }
}
