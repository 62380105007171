import { makeAutoObservable } from 'mobx'

export function useAccountHoldingsSideDrawer() {
  return sideDrawerStore
}

class SideDrawerStore {
  constructor() {
    makeAutoObservable(this)
  }
  openSideDrawer = () => {
    sideDrawerStore.isOpen = true
  }
  closeSideDrawer = () => {
    sideDrawerStore.isOpen = false

    // Clean up
  }
  isOpen: boolean = false
}

const sideDrawerStore = new SideDrawerStore()
