import { SkeletonLoader } from '@common/components/SkeletonLoader'
import { useInfrontInstrument } from '@common/hooks/infront/sdk/useInfrontInstrument'
import { convertToInstrumentIdContainer } from '@common/instrumentIdContainer'

import { useTranslation } from 'react-i18next'

import { LinkButton, Text } from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import { useOpenInstrumentDrawer } from '../../drawers/instrumentDrawer/useOpenInstrumentDrawer'

type RelatedInstrumentsCombinedProps = {
  instruments?: InfrontSDK.SymbolId[]
}

export const RelatedInstrumentsCombined = observer(({ instruments }: RelatedInstrumentsCombinedProps) => {
  const { t } = useTranslation()

  if (!instruments || instruments.length === 0) return null

  const sortedArray = instruments?.slice().sort((a, b) => {
    if (a.ticker < b.ticker) return -1
    if (a.ticker > b.ticker) return 1
    return 0
  })

  return (
    <>
      <Text color="text-low-emphasis" variant="label1">
        {t('Relaterade värdepapper') + ': '}
      </Text>
      <Text>
        <SkeletonLoader p={16} height={128} loading={!instruments}>
          {() =>
            sortedArray &&
            sortedArray?.map((instrument, index) => (
              <RelatedInstrument
                key={index}
                isLast={index + 1 === instruments.length}
                instrument={instrument as Infront.Instrument}
              />
            ))
          }
        </SkeletonLoader>
      </Text>
    </>
  )
})

type RelatedInstrumentCombinedProps = {
  instrument: Infront.Instrument
  isLast: boolean
}

const RelatedInstrument = observer(({ isLast, instrument }: RelatedInstrumentCombinedProps) => {
  const { openInstrumentDrawer } = useOpenInstrumentDrawer()

  const { observableInstrument } = useInfrontInstrument(instrument, [InfrontSDK.SymbolField.FullName])

  const name = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.FullName)
  const infrontInstrument = new Infront.Instrument(instrument.feed, instrument.ticker)
  const instrumentIdContainer = convertToInstrumentIdContainer(infrontInstrument)

  if (!name) return null
  return (
    <>
      <LinkButton
        flexWrap="nowrap"
        color="text-link"
        variant="subtitle1"
        onClick={() => {
          openInstrumentDrawer(instrumentIdContainer)
        }}
      >
        {name}
      </LinkButton>
      {isLast ? `` : `,\xA0`}
    </>
  )
})
