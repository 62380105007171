import { useHoldingAccounts } from '@common/hooks/useHoldings'
import { useRefCallback } from '@common/hooks/useRefCallback'
import { convertToInstrumentIdContainer } from '@common/instrumentIdContainer'
import { useAuth } from '@common/stores/store'
import { fireTrackEvent } from '@common/utils/analyticsEvent'

import { useTranslation } from 'react-i18next'

import { InfrontOrder } from '@carnegie/digital-channels-frontend'
import { IconButton, IconEdit, MenuItem } from '@carnegie/duplo'

import { useOpenOrderDrawer } from '@/drawers/orderDrawer/useOpenOrderDrawer'

import { observer } from 'mobx-react-lite'

export const Edit = observer(
  ({
    order,
    size = 'small',
    showMenuItem = false,
  }: {
    order: InfrontOrder
    size?: 'small' | 'medium' | 'large'
    showMenuItem?: boolean
  }) => {
    const { t } = useTranslation()
    const { openOrderDrawer } = useOpenOrderDrawer()
    const auth = useAuth()
    const { orderId, feed, ticker, isDeletable, portfolioName } = order

    const { findAccountByNumber } = useHoldingAccounts()
    const accountId = findAccountByNumber(portfolioName)?.id

    const instrument = new Infront.Instrument(feed, ticker)

    // Because infront only sets up its modifyClick callback once we get a stale closure if
    // we put code inside it, so we need a reference to a function instead otherwise selectedAccount will always be the same when clicking
    // modifyClick
    const handleModifyClick = useRefCallback((portfolio: string, orderId: number, instrument: Infront.Instrument) => {
      fireTrackEvent('Order', 'click_edit_order')
      openOrderDrawer(convertToInstrumentIdContainer(instrument), portfolio, orderId)
    })

    const onClick = () => {
      auth.isImpersonated
        ? alert('Du saknar rättighet till åtgärden') //todo byt till snackbar (material ui/duplo)
        : handleModifyClick.current(accountId, orderId, instrument)
    }

    if (showMenuItem) {
      return (
        <MenuItem key="edit" onClick={onClick} icon={IconEdit}>
          {t('Ändra order')}
        </MenuItem>
      )
    }

    return (
      isDeletable && (
        <IconButton size={size} variant="uncontained" onClick={onClick} icon={IconEdit} tooltip={t('Ändra order')} />
      )
    )
  }
)
