export const IconOffering = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M12 8.61538L11.3704 9L11.5185 8.26923L11 7.76923L11.7037 7.69231L12 7L12.2963 7.69231L13 7.76923L12.4815 8.26923L12.6296 9L12 8.61538Z"
        stroke="#11151C"
        stroke-miterlimit="10"
      />
      <rect x="5" y="12" width="14" height="10" stroke="black" />
      <path d="M21 3L19 5" stroke="black" stroke-width="0.833333" stroke-linecap="round" />
      <path d="M3 3L5 5" stroke="black" stroke-width="0.833333" stroke-linecap="round" />
      <path d="M19 9.20001L21 11.2" stroke="black" stroke-width="0.833333" stroke-linecap="round" />
      <path d="M5 9.20001L3 11.2" stroke="black" stroke-width="0.833333" stroke-linecap="round" />
      <path d="M19 7H22" stroke="black" stroke-width="0.833333" stroke-linecap="round" />
      <path d="M5 7H2" stroke="black" stroke-width="0.833333" stroke-linecap="round" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.7 12.0368C14.6288 11.7046 16.0963 10.0237 16.0963 8C16.0963 5.73766 14.2623 3.90368 12 3.90368C9.73766 3.90368 7.90368 5.73766 7.90368 8C7.90368 10.0237 9.37116 11.7046 11.3 12.0368H9.04907C7.80675 11.1271 7 9.65774 7 8C7 5.23858 9.23858 3 12 3C14.7614 3 17 5.23858 17 8C17 9.65774 16.1932 11.1271 14.9509 12.0368H12.7Z"
        fill="#11151C"
      />
      <line x1="7.5" y1="14" x2="7.5" y2="20" stroke="black" />
    </svg>
  )
}
