import { convertLinksToAnchorTags } from '@common/utils/urlHelpers'

import { LinkExternal } from '@carnegie/duplo'

import { Text } from 'domhandler'
import parse, { Element, HTMLReactParserOptions, domToReact } from 'html-react-parser'

type Props = {
  html: string
  convertLinks?: boolean
}

export function CarnegieMaterialSimpleHtml({ html, convertLinks = false }: Props) {
  let htmlString = html
  if (convertLinks) {
    htmlString = convertLinksToAnchorTags(html)
  }

  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (domNode instanceof Element) {
        const { attribs, children, name } = domNode

        if (name === 'a') {
          return (
            <LinkExternal target="_blank" href={attribs.href}>
              {domToReact(children, options)}
            </LinkExternal>
          )
        }
      } else if (domNode instanceof Text) {
        const { data } = domNode
        const splitText = data?.split('\n')

        // Only do this for texts that have at least one '\n' in them.
        if (splitText?.length > 1) {
          const sections = splitText?.filter((text) => text !== '').filter((text) => text !== ' ')

          if (sections && sections.length) {
            return (
              <>
                {sections?.map((paragraph, count) => (
                  <p css={{ marginBottom: 16 }} key={count}>
                    {paragraph}
                  </p>
                ))}
              </>
            )
          }
        }
      }
    },
  }
  return <>{parse(htmlString, options)}</>
}
CarnegieMaterialSimpleHtml.displayName = 'CarnegieMaterialSimpleHtml'
