import { AccountOffering, OfferingAnswer, RegisterAccountOfferingResponse } from '@common/api/response'
import { Offering } from '@common/hooks/useOfferings'
import { fireTrackEvent } from '@common/utils/analyticsEvent'

import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { formatNumber } from '@carnegie/digital-channels-frontend'
import {
  Box,
  Breakpoint,
  Button,
  FlexCol,
  FlexRow,
  IconButton,
  IconInfoOutlined,
  LoadingIcon,
  Popper,
  Segment,
  Text,
  useBreakpoint,
} from '@carnegie/duplo'

import { OfferingItem } from './OfferingItem'

const TRACK_CATEGORY = 'Offering' as const
const TRACK_EVENTS = {
  success: 'respond_offering_successful',
  error: 'respond_offering_error',
} as const

type CorporateActionRespondFormProps = {
  offering: Offering | undefined
  // This is set to true when the data is "reloading"/re-validated not quite the same as isLoading which is only set to true the first time
  // data is loaded
  isValidatingData: boolean
  setIsRegistringSuccess: Dispatch<SetStateAction<boolean>>
  registerCorporateActions: (offeringAnswers?: OfferingAnswer[]) => Promise<RegisterAccountOfferingResponse>
}

export type FormOfferingAnswer = {
  isChecked?: boolean
  accountId: string
  /** @format decimal */
  acceptedQuantity: number | ''
  maxQuantity: number
  errors?: FormAccountOfferingError
}

export type FormAccountOfferingError = {
  acceptedQuantityError: string
  notCheckedError: string
}

export function useCorporateActionsResponseForm() {
  const { t } = useTranslation()
  const [offeringAnswers, setOfferingAnswers] = useState<FormOfferingAnswer[]>()

  const changeAnswer = (
    accountOffering: AccountOffering,
    radioButtonValue: boolean,
    acceptedQuantity?: number | ''
  ) => {
    // Update
    const updatedOfferingAnswers = offeringAnswers?.map((formOfferingAnswer) => {
      if (formOfferingAnswer.accountId === accountOffering.accountId) {
        return {
          ...formOfferingAnswer,
          isChecked: radioButtonValue,
          acceptedQuantity: acceptedQuantity ?? acceptedQuantity,
        }
      }
      return formOfferingAnswer
    })

    setOfferingAnswersAndValidate(updatedOfferingAnswers)
  }

  // Like formik almost takes in values and returns errors
  const validate = useCallback(
    (answers: FormOfferingAnswer[]) => {
      if (!answers) return

      for (const answer of answers) {
        answer.errors = { acceptedQuantityError: '', notCheckedError: '' }

        // Since this is a tri-state checkbox we must explicity handle null and undefined (not the same as false)
        if (answer.isChecked === undefined || answer.isChecked === null) {
          answer.errors = { ...answer.errors, notCheckedError: t('Du måste svara för samtliga konton i erbjudandet') }
        }

        const acceptedQuantityNumeric = parseNumberIncludingEmptyString(answer.acceptedQuantity)

        if (answer.isChecked && acceptedQuantityNumeric <= 0) {
          answer.errors = { ...answer.errors, acceptedQuantityError: t('Du måste ange ett antal att delta med') }
        }

        if (answer.isChecked && acceptedQuantityNumeric > answer.maxQuantity) {
          answer.errors = {
            ...answer.errors,
            acceptedQuantityError: t('För stort antal, max är ') + formatNumber(answer.maxQuantity, { decimals: 0 }),
          }
        }
      }
    },
    [t]
  )

  const setOfferingAnswersAndValidate = useCallback(
    (value: FormOfferingAnswer[]) => {
      validate(value)
      setOfferingAnswers(value)
    },
    [validate]
  )

  return { offeringAnswers, changeAnswer, setOfferingAnswers: setOfferingAnswersAndValidate }
}

export const CorporateActionRespondForm = ({
  offering,
  setIsRegistringSuccess,
  registerCorporateActions,
  isValidatingData,
}: CorporateActionRespondFormProps) => {
  const { t } = useTranslation()

  const breakpoint = useBreakpoint()

  const isMobile = breakpoint < Breakpoint.Small
  const isTablet = breakpoint < Breakpoint.Medium
  const isMobileOrTablet = isMobile || isTablet

  const [hasSubmittedOnce, setHasSubmittedOnce] = useState(false)

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [registerResponse, setRegisterResponse] = useState<RegisterAccountOfferingResponse>(undefined)
  const accountOfferings = offering?.accountOfferings

  const { offeringAnswers, setOfferingAnswers, changeAnswer } = useCorporateActionsResponseForm()

  const [unlockForm, setUnlockForm] = useState(false)

  // Set initial form state
  useEffect(() => {
    const formData = accountOfferings?.map((accountOffering) => {
      const formOfferingAnswer: FormOfferingAnswer = {
        accountId: accountOffering.accountId,
        acceptedQuantity: accountOffering.hasResponded
          ? accountOffering.acceptedQuantity
          : accountOffering.totalQuantity,
        isChecked: accountOffering.isChecked,
        maxQuantity: accountOffering.totalQuantity,
      }

      return formOfferingAnswer
    })

    // This will overwrite any local changes (by design)
    setOfferingAnswers(formData)
  }, [offering?.offeringId, accountOfferings, setOfferingAnswers])

  let lockedForm = true

  if (offering?.hasResponded === true && unlockForm) {
    lockedForm = false
  }

  if (offering?.hasResponded === false || offering?.hasResponded === 'mixed') lockedForm = false

  return (
    <Segment
      id="account-offerings"
      noContentPadding
      title={
        <FlexRow width="full" textAlign="left" alignItems="center">
          <Text mr={2}>{t('Konton att svara för').toUpperCase()}</Text>
          <Popper
            id="corporate-action-info"
            toggle={<IconButton size="small" variant="uncontained" icon={IconInfoOutlined} />}
          >
            <FlexCol p={16} maxWidth={256}>
              <Text variant="body2">
                {t('Här visas de konton som du har behörighet att besvara erbjudanden för. Kontakta oss vid frågor.')}
              </Text>
            </FlexCol>
          </Popper>
        </FlexRow>
      }
      variant="contained"
      headingVariant="overline"
    >
      {accountOfferings ? (
        accountOfferings?.map((accountOffering) => (
          <OfferingItem
            submitting={isSubmitting}
            disabled={lockedForm}
            offering={offering}
            showValidationErrors={hasSubmittedOnce}
            onChangeAnswer={changeAnswer}
            offeringAnswer={offeringAnswers?.find((oa) => oa.accountId === accountOffering.accountId)}
            registerResponse={registerResponse}
            key={accountOffering.accountId}
            accountOffering={accountOffering}
            // Set to true when the data is loading or reloading so we don't show the old value
            // before new data has been returned in the case of updating answers
            isValidatingData={isValidatingData}
          />
        ))
      ) : (
        // Skeleton
        <OfferingItem accountOffering={undefined} />
      )}
      <Box
        px={16}
        pb={12}
        pt={16}
        width="full"
        position={isMobileOrTablet ? 'sticky' : undefined}
        bottom={isMobileOrTablet ? 0 : undefined}
        css={{ background: 'linear-gradient(0deg, rgba(255,255,255,1) 70%, rgba(255,255,255,0) 100%)' }}
      >
        <FlexRow width="full">
          {!lockedForm && (
            <Button
              variant="primary"
              width="full"
              size="medium"
              startIcon={isSubmitting ? <LoadingIcon size={24} /> : null}
              disabled={!accountOfferings || isSubmitting}
              onClick={async () => {
                setHasSubmittedOnce(true)

                const hasErrors = offeringAnswers?.some(
                  (oa) => oa.errors.acceptedQuantityError || oa.errors.notCheckedError
                )

                if (hasErrors) {
                  // Scroll to top element
                  scrollToElement('account-offerings')
                  fireTrackEvent(TRACK_CATEGORY, TRACK_EVENTS['error'])
                } else {
                  fireTrackEvent(TRACK_CATEGORY, TRACK_EVENTS['success'])

                  setIsRegistringSuccess(undefined)
                  setIsSubmitting(true)

                  // The backend want the answers in a slightly different format
                  const remappedOfferingAnswers = offeringAnswers?.map((oa) => {
                    let acceptedQuantity = 0

                    if (oa.acceptedQuantity !== '' && oa.isChecked) {
                      acceptedQuantity = oa.acceptedQuantity
                    }

                    const remappedOfferingAnswer: OfferingAnswer = {
                      offeringId: offering.offeringId,
                      acceptedQuantity: acceptedQuantity,
                      accountId: oa.accountId,
                    }
                    return remappedOfferingAnswer
                  })

                  const response = await registerCorporateActions(remappedOfferingAnswers)

                  const isAllItemsSuccessful = response?.offeringAnswerStatuses?.every((o) => o.success)

                  setIsRegistringSuccess(isAllItemsSuccessful)
                  setRegisterResponse(response)
                  setUnlockForm(false)
                  setIsSubmitting(false)

                  scrollToElement('offerings-drawer-top')
                }
              }}
            >
              {offering?.hasResponded === false || offering === undefined
                ? t('CORPORATE_ACTION_RESPOND')
                : t('Uppdatera svar')}
            </Button>
          )}
          {lockedForm && (
            <Button
              width="full"
              size="medium"
              variant="secondary"
              backgroundColor="off-white"
              onClick={() => setUnlockForm(true)}
            >
              {t('Ändra svar')}
            </Button>
          )}
        </FlexRow>
      </Box>
      <FlexRow px={16} pb={8}>
        <Text variant="support2" color="text-low-emphasis">
          {offering?.offeringType === 'Takeover' &&
            t('Om du inte lämnar svar innan sista svarsdatum kommer erbjudandet inte accepteras.')}
          {offering?.offeringType === 'WarrantsExercise' &&
            t(
              'Om du inte besvarar erbjudandet innan sista svarsdatum kommer vi om möjligt sälja outnyttjade teckningsoptioner på sista handelsdagen. Courtage utgår enligt gällande prislista vid eventuell försäljning.'
            )}

          {offering?.offeringType !== 'Takeover' &&
            offering?.offeringType !== 'WarrantsExercise' &&
            t(
              'Om du inte besvarar erbjudandet innan sista svarsdatum kommer vi om möjligt sälja outnyttjade teckningsrätter på sista handelsdagen. Courtage utgår enligt gällande prislista vid eventuell försäljning.'
            )}
        </Text>
      </FlexRow>
    </Segment>
  )
}

function scrollToElement(elementId: string) {
  const el = document.getElementById(elementId)

  if (el) el.scrollIntoView({ behavior: 'smooth' })
}

export function parseNumberIncludingEmptyString(value: number | '') {
  if (value === '') return 0

  return value
}
