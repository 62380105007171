import {
  Icon,
  IconFlagAustralia,
  IconFlagCanada,
  IconFlagDenmark,
  IconFlagEu,
  IconFlagHongkong,
  IconFlagJapan,
  IconFlagMexico,
  IconFlagNewZealand,
  IconFlagNorway,
  IconFlagPhilippines,
  IconFlagPoland,
  IconFlagSingapore,
  IconFlagSouthafrica,
  IconFlagSweden,
  IconFlagSwitzerland,
  IconFlagThailand,
  IconFlagUk,
  IconFlagUsa,
} from '@carnegie/duplo'

export const CurrencyFlag = ({ currency, size = 32 }: { currency: string; size?: number }) => {
  switch (currency) {
    case 'AUD':
      return <Icon icon={IconFlagAustralia} size={size} />
    case 'CAD':
      return <Icon icon={IconFlagCanada} size={size} />
    case 'CHF':
      return <Icon icon={IconFlagSwitzerland} size={size} />
    case 'DKK':
      return <Icon icon={IconFlagDenmark} size={size} />
    case 'EUR':
      return <Icon icon={IconFlagEu} size={size} />
    case 'GBP':
      return <Icon icon={IconFlagUk} size={size} />
    case 'HKD':
      return <Icon icon={IconFlagHongkong} size={size} />
    case 'JPY':
      return <Icon icon={IconFlagJapan} size={size} />
    case 'MXN':
      return <Icon icon={IconFlagMexico} size={size} />
    case 'NOK':
      return <Icon icon={IconFlagNorway} size={size} />
    case 'NZD':
      return <Icon icon={IconFlagNewZealand} size={size} />
    case 'PHP':
      return <Icon icon={IconFlagPhilippines} size={size} />
    case 'PLN':
      return <Icon icon={IconFlagPoland} size={size} />
    case 'SEK':
      return <Icon icon={IconFlagSweden} size={size} />
    case 'SGD':
      return <Icon icon={IconFlagSingapore} size={size} />
    case 'THB':
      return <Icon icon={IconFlagThailand} size={size} />
    case 'USD':
      return <Icon icon={IconFlagUsa} size={size} />
    case 'ZAR':
      return <Icon icon={IconFlagSouthafrica} size={size} />
    default:
      return <></>
  }
}
