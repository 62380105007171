import { InfrontInstrument } from '@common/api/response'
import { useInfrontOrderbook } from '@common/hooks/infront/widgets/useInfrontOrderbook'
import { css } from '@emotion/react'

import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { Segment, SkeletonRect, useDuploTheme } from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

type OrderDepthProps = {
  infrontInstrument: InfrontInstrument
  onPriceClick?: (price: number) => void
}

export const OrderDepth = observer(({ infrontInstrument, onPriceClick }: OrderDepthProps) => {
  const { t } = useTranslation()

  // We need to pass a ref to the callback to infront since they will never re-initialize if we send in a new callback reference
  const onPriceClickRef = useRef(onPriceClick)
  onPriceClickRef.current = onPriceClick

  const { containerId, widgetLoaded } = useInfrontOrderbook({
    infrontInstrument,
    customOptions: {
      volumeBarHeight: '16px',
    },
    onPriceClick: (price) => {
      if (onPriceClickRef.current) onPriceClickRef.current(price)
    },
  })

  return (
    <Segment title={t('Orderdjup')} variant="contained" headingVariant="overline">
      {!widgetLoaded && <SkeletonRect width="full" height={128} />}
      <Wrapper id={containerId} clickable={!!onPriceClick} />
    </Segment>
  )
})
OrderDepth.displayName = 'OrderDepth'

const Wrapper = ({ clickable, children, ...rest }: { clickable: boolean } & React.HTMLAttributes<HTMLDivElement>) => {
  const theme = useDuploTheme()

  return (
    <div
      css={css`
        thead th {
          border: none !important;
          padding: 0;
          color: ${theme.colors['bunker-200']};
          font-weight: 400;
          text-transform: none;
          height: 24px;
        }

        .cell-w-order-book--ask-price.cell-tablecell--interaction,
        .cell-w-order-book--bid-price.cell-tablecell--interaction {
          ${clickable ? undefined : 'cursor: text;'}
        }

        .cell-flex-row .cell-flex-collapse {
          display: none !important;
        }

        .cell-w-order-book__table-header-bid-orders {
          display: none;
        }
        .cell-w-order-book--bid-orders {
          display: none;
        }

        .cell-w-order-book__table-header-ask-orders {
          display: none;
        }

        .cell-w-order-book--ask-orders {
          display: none;
        }

        .cell-w-order-book--bid-bar-size {
          background: ${theme.colors['bunker-20']}
            url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23accbe1' d='M0 0h500v20H0z' fill-rule='evenodd'/%3E%3C/svg%3E")
            100% 50% no-repeat;
          border-right: none;
          height: 16px;
        }

        .cell-w-order-book--ask-bar-size {
          background: ${theme.colors['bunker-20']}
            url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23bd8092' d='M0 0h500v20H0z' fill-rule='evenodd'/%3E%3C/svg%3E")
            0% 50% no-repeat;
          border-left: none;
          height: 16px;
        }

        tbody td {
          line-height: 16px;
          padding: 0;
          border-top: 4px solid ${theme.colors['off-white']}!important;
          border-bottom: 4px solid ${theme.colors['off-white']}!important;
          font-size: 13px;
        }
      `}
      {...rest}
    >
      {children}
    </div>
  )
}
Wrapper.displayName = 'Wrapper'
