import { InstrumentFlag } from '@common/components/InstrumentFlag'
import { NoDataDash, NoDataFallback } from '@common/components/NoDataFallback'
import { useInfrontInstrument } from '@common/hooks/infront/sdk/useInfrontInstrument'
import { useCarnegieInstrument } from '@common/hooks/useCarnegieInstrument'
import { useInstrumentWithFallbackData } from '@common/hooks/useInstrumentWithFallbackData'
import { InstrumentIdContainer } from '@common/instrumentIdContainer'

import { useTranslation } from 'react-i18next'

import { formatAsDate, formatNumber } from '@carnegie/digital-channels-frontend'
import {
  Box,
  ListItem,
  ListItemRow,
  Segment,
  SkeletonRect,
  Spacer,
  Tag,
  TagIcon,
  TagLabel,
  Text,
} from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import i18n from '../../../i18n'
import { ListItemValue } from '../ListItemValue'

type Props = {
  instrumentIdContainer: InstrumentIdContainer
  collateralRatio: number
}

/**
 * Handelsplats saknas. Svar: Undersöker.
 * Listor saknas. Svar: Oklart om instrumentet vet vilka listor det tillhör i fronten. Skulle gå att lösa backend.
 */

export const InstrumentStocksInformation = observer(({ instrumentIdContainer, collateralRatio }: Props) => {
  const { t } = useTranslation()

  const { isin, marketList, marketplaceName } = useInstrumentWithFallbackData(instrumentIdContainer, [
    'isin',
    'marketplace',
    'marketList',
  ])

  const { observableInstrument, isLoading } = useInfrontInstrument(
    instrumentIdContainer?.infrontInstrument,
    [
      InfrontSDK.SymbolField.IssueDate,
      InfrontSDK.SymbolField.PrimarySegmentDescription,
      InfrontSDK.SymbolField.PrimarySegment,
    ],
    { observe: false }
  )

  const issueDateValue = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.IssueDate) as unknown as Date
  const issueDate = issueDateValue !== undefined && issueDateValue !== null ? formatAsDate(issueDateValue) : undefined

  const { carnegieInstrument, isLoading: isLoadingCarnegieInstrument } = useCarnegieInstrument(
    instrumentIdContainer?.instrumentIdCarnegie,
    i18n.language
  )

  return (
    <Box>
      <Segment noContentPadding>
        <ListItem divider>
          <ListItemRow
            title={<Text variant="support2">{t('Värdepapperstyp')}</Text>}
            value={
              <ListItemValue loading={isLoadingCarnegieInstrument} value={carnegieInstrument?.instrumentTypeName} />
            }
          />
        </ListItem>
        <ListItem divider>
          <ListItemRow
            title={<Text variant="support2">{t('Kortnamn')}</Text>}
            value={<Text variant="subtitle2">{instrumentIdContainer.infrontInstrument?.ticker ?? <NoDataDash />}</Text>}
          />
        </ListItem>
        <ListItem divider>
          <ListItemRow title={<Text variant="support2">{t('ISIN')}</Text>} value={<ListItemValue value={isin} />} />
        </ListItem>
        <ListItem divider>
          <ListItemRow
            title={<Text variant="support2">{t('Noteringsdatum')}</Text>}
            value={<ListItemValue loading={isLoading} value={issueDate} />}
          />
        </ListItem>
        <ListItem>
          <ListItemRow
            title={<Text variant="support2">{t('Belåningsgrad')}</Text>}
            value={<ListItemValue value={formatNumber(collateralRatio, { ratio: false, decimals: 0 })} unit="%" />}
          />
        </ListItem>
      </Segment>
      <Spacer height={16} />
      <Text variant="overline">{t('Handelsplats')}</Text>
      <Spacer height={8} />
      {marketplaceName === undefined ? (
        <SkeletonRect height={32} width={100} />
      ) : (
        <Tag>
          {instrumentIdContainer?.infrontInstrument && (
            <TagIcon>
              <InstrumentFlag instrumentIdContainer={instrumentIdContainer} />
            </TagIcon>
          )}
          <TagLabel>
            <NoDataFallback>{marketplaceName}</NoDataFallback>
          </TagLabel>
        </Tag>
      )}
      <Spacer height={16} />
      <Text variant="overline">{t('Lista')}</Text>
      <Spacer height={8} />
      {marketList === undefined ? (
        <SkeletonRect width={100} height={32} />
      ) : (
        <Tag>
          <TagLabel>
            <NoDataFallback>{marketList}</NoDataFallback>
          </TagLabel>
        </Tag>
      )}
    </Box>
  )
})
InstrumentStocksInformation.displayName = 'InstrumentStocksInformation'
