export const arrayInsert = <T>(arr: T[], index: number, item: T) => {
  arr.splice(index, 0, item)
}

export const arrayMove = <T>(arr: T[], from: number, to: number) => {
  arr.splice(to, 0, arr.splice(from, 1)[0])
}

export const arrayRemove = <T>(arr: T[], index: number) => {
  arr.splice(index, 1)
}

export const arrayAny = <T>(arr: T[]) => {
  return arr?.length > 0
}

export const arrayDistinctBy = <T>(array: T[], property: (k: T) => unknown) => {
  return [...new Map(array?.map((x) => [property(x), x])).values()]
}

export const arrayDistinct = <T>(array: T[]) => {
  return [...new Set(array)]
}
