import { FormattedNumber } from '@common/components/FormattedNumber'

import { useTranslation } from 'react-i18next'

import { formatNumber } from '@carnegie/digital-channels-frontend'
import { FlexRow, Td, Text, Tr, useDuploTheme } from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

type Props = {
  totalMarketValue: number
}

export const CurrencyPositionsMobileFooter = observer(({ totalMarketValue }: Props) => {
  const theme = useDuploTheme()
  const { t } = useTranslation()

  return (
    <FlexRow
      p={16}
      css={{ borderTop: `1px solid ${theme.colors['bunker-60']}` }}
      justifyContent="space-between"
      alignItems="center"
    >
      <Text variant="support2">{t('Totalt saldo') + ` (SEK)`}</Text>
      <FlexRow justifyContent="space-between" alignItems="baseline">
        <Text variant="subtitle1">{formatNumber(totalMarketValue)}</Text>
        <Text variant="label1" ml={2} color="bunker-main">
          {'SEK'}
        </Text>
      </FlexRow>
    </FlexRow>
  )
})

export const CurrencyPositionsFooter = observer(({ totalMarketValue }: Props) => {
  const theme = useDuploTheme()
  const { t } = useTranslation()

  return (
    <tfoot css={{ borderTop: `1px solid ${theme.colors['bunker-60']}` }}>
      <Tr>
        <Td textAlign="left" css={{ fontWeight: '500' }}>
          {t('Total')}
        </Td>
        <Td textAlign="right" />
        <Td textAlign="right">
          <FormattedNumber justifyContent="flex-end" unit="SEK" value={totalMarketValue} decimals={2} bold />
        </Td>
        <Td textAlign="right" />
      </Tr>
    </tfoot>
  )
})
