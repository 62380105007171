import { CarnegieInstrument, ResearchNews, TargetAndRecommendationResponse } from '@common/api/response'
import { NoDataDash } from '@common/components/NoDataFallback'
import { InfrontChartPeriod } from '@common/hooks/infront/infrontChartPeriod'
import { useInfrontInstrument } from '@common/hooks/infront/sdk/useInfrontInstrument'
import { useFeatures } from '@common/hooks/useFeatures'
import { usePrevious } from '@common/hooks/usePrevious'
import { InstrumentIdContainer } from '@common/instrumentIdContainer'
import { fadeOutIn } from '@common/utils/animations'
import { getValueColor } from '@common/utils/colorUtils'
import { getMorningStarFactsheetLink } from '@common/utils/morningstarLink'

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { DEFAULT_PRECISION, formatDate, formatNumber } from '@carnegie/digital-channels-frontend'
import {
  Box,
  Breakpoint,
  DataCell,
  FlexCol,
  FlexRow,
  Icon,
  IconDocumentLineChart,
  IconInfoOutlined,
  LinkExternal,
  Popper,
  SkeletonRect,
  Text,
  Tooltip,
  useBreakpoint,
} from '@carnegie/duplo'

import { RecommendationNames } from '@/pages/research/shared/researchNewsItem'
import { useTargetAndRecommendations } from '@/pages/research/shared/useResearchTargetItems'

import { motion, useAnimation } from 'framer-motion'
import { observer } from 'mobx-react-lite'

import { RecommendationSquircle } from '../../pages/research/researchExplore/RecommendationSquircle'
import { useResearchAndComments } from '../../pages/research/shared/useResearchAndComments'
import { InstrumentIndexPerformanceItems } from './index/InstrumentIndexPerformanceItems'
import { showAsIndexType } from './rules'
import { CommonInstrumentType } from './useCommonInstrumentType'

type InstrumentPerformanceProps = {
  instrumentIdContainer: InstrumentIdContainer
  period: InfrontChartPeriod
  carnegieInstrument?: CarnegieInstrument
  showTooltip?: boolean
  instrumentType: CommonInstrumentType
  onPriceClick?: (price: number) => void
  researchNews?: ResearchNews
  showFeeAndKiid?: boolean
}

export const InstrumentPerformance = observer(
  ({
    instrumentIdContainer,
    instrumentType,
    carnegieInstrument,
    period: performancePeriod,
    showTooltip = true,
    showFeeAndKiid = false,
    onPriceClick,
  }: InstrumentPerformanceProps) => {
    const features = useFeatures()
    const { t, i18n } = useTranslation()

    const { targetAndRecommendations, isValidating } = useTargetAndRecommendations(
      carnegieInstrument?.instrumentIdCarnegie
    )

    const kiidLink = !carnegieInstrument?.isin ? (
      <SkeletonRect height={20} width={96} />
    ) : (
      <LinkExternal
        truncate
        variant="subtitle2"
        target="_blank"
        href={getMorningStarFactsheetLink(carnegieInstrument?.instrumentIdCarnegie, i18n.language)}
        title={t('Faktablad')}
      >
        <FlexRow>
          <Box mr={4}>
            <Icon color="current" icon={IconDocumentLineChart} size={20} />
          </Box>
          <span>{t('Faktablad')}</span>
        </FlexRow>
      </LinkExternal>
    )

    return (
      <FlexRow height={'auto'} alignItems="center">
        <PercentageChange
          instrumentIdContainer={instrumentIdContainer}
          period={performancePeriod}
          instrumentType={instrumentType}
          css={{ width: '25%' }}
        />
        {instrumentType === 'funds' ? (
          <PercentageChange
            instrumentIdContainer={instrumentIdContainer}
            period={'1D'}
            instrumentType={instrumentType}
            css={{ width: '25%' }}
          />
        ) : (
          <LastPrice
            instrumentIdContainer={instrumentIdContainer}
            onPriceClick={onPriceClick}
            showTooltip={showTooltip}
            css={{ width: '25%' }}
            instrumentType={instrumentType}
          />
        )}
        {showFeeAndKiid && (
          <>
            <FlexCol width="25%">
              <Text variant="label1">{t('Avgift')}</Text>
              <FlexRow alignItems="baseline">
                <Text variant="subtitle1">
                  {carnegieInstrument?.managementFee ? (
                    formatNumber(carnegieInstrument?.managementFee, { ratio: false })
                  ) : (
                    <NoDataDash />
                  )}
                </Text>
                {carnegieInstrument?.managementFee !== undefined && carnegieInstrument?.managementFee !== null && (
                  <Text variant="label1" ml={2}>
                    %
                  </Text>
                )}
              </FlexRow>
            </FlexCol>
            <FlexCol width="25%">
              <Text variant="label1">{t('Faktablad')}</Text>
              {kiidLink}
            </FlexCol>
          </>
        )}
        {showAsIndexType(instrumentType) && (
          <InstrumentIndexPerformanceItems
            instrumentIdContainer={instrumentIdContainer}
            showTooltip={showTooltip}
            instrumentType={instrumentType}
          />
        )}
        {!isValidating && targetAndRecommendations && features.showAnalysis && (
          <>
            <TargetPrice targetRecommendations={targetAndRecommendations} />
            <Rating targetRecommendations={targetAndRecommendations} />
          </>
        )}
      </FlexRow>
    )
  }
)
InstrumentPerformance.displayName = 'InstrumentPerformance'

type TargetPriceProps = {
  targetRecommendations?: TargetAndRecommendationResponse
}

const TargetPrice = observer(({ targetRecommendations }: TargetPriceProps) => {
  const { t } = useTranslation()

  const isFairValue = targetRecommendations?.useFairValue === true
  const value = isFairValue
    ? `${formatNumber(targetRecommendations.fairValueLow, {
        decimals: { min: 0, max: 1 },
      })}-${formatNumber(targetRecommendations.fairValueHigh, { decimals: { min: 0, max: 1 } })}`
    : `${formatNumber(targetRecommendations?.targetPrice, { decimals: { min: 0, max: 1 } })}`

  return (
    <Box width="25%">
      <Popper
        id="target-price"
        toggle={
          <DataCell icon={!isFairValue && IconInfoOutlined} label={isFairValue ? t('Motiverat värde') : t('Riktkurs')}>
            {targetRecommendations === undefined ? (
              <SkeletonRect height={20} width="50%" />
            ) : (
              <Text variant="subtitle1">
                {targetRecommendations && value}
                {targetRecommendations?.priceCurrency && (
                  <Text variant="label2" ml={2} color="bunker-main">
                    {targetRecommendations?.priceCurrency}
                  </Text>
                )}
              </Text>
            )}
          </DataCell>
        }
      >
        {!isFairValue && (
          <FlexCol p={16}>
            <Text variant="body1">{`${t('Senast ändrad')}: ${
              targetRecommendations && targetRecommendations?.targetPriceRevison
                ? formatDate(new Date(targetRecommendations?.targetPriceRevison), 'HH:mm/yyyy-MM-dd')
                : '-'
            }`}</Text>
            <Text variant="body1">{`${t('Tidigare')}: ${formatNumber(targetRecommendations?.targetPricePrevious, {
              decimals: 0,
            })} ${targetRecommendations?.priceCurrency ?? ''}
              `}</Text>
          </FlexCol>
        )}
      </Popper>
    </Box>
  )
})
TargetPrice.displayName = 'TargetPrice'

type RatingProps = {
  targetRecommendations?: TargetAndRecommendationResponse
}

const Rating = observer(({ targetRecommendations }: RatingProps) => {
  const { t } = useTranslation()

  const breakpoint = useBreakpoint()
  const isExtraSmallScreen = breakpoint < Breakpoint.Small
  const label = isExtraSmallScreen ? t('Rek.') : t('Rekommendation')

  const recommendation = (targetRecommendations?.recommendation as RecommendationNames) ?? ''
  const previousRecommendation = targetRecommendations?.previousRecommendation ?? ''
  const { translateRecommendation } = useResearchAndComments()
  const isFairValue = targetRecommendations && targetRecommendations.useFairValue === true

  return (
    <Box width="25%">
      {!isFairValue && (
        <Popper
          id="rating"
          toggle={
            <DataCell icon={IconInfoOutlined} label={label}>
              {targetRecommendations === undefined ? (
                <SkeletonRect height={20} width="50%" />
              ) : recommendation ? (
                <FlexRow alignItems="center">
                  <RecommendationSquircle size="xs" type={recommendation} />
                  <Text ml={recommendation && 4} variant="subtitle1">
                    {translateRecommendation(recommendation)}
                  </Text>
                </FlexRow>
              ) : (
                '-'
              )}
            </DataCell>
          }
        >
          <FlexCol p={16}>
            <Text variant="body1">{`${t('Senast ändrad')}: ${
              targetRecommendations && targetRecommendations?.recommendationDate
                ? formatDate(new Date(targetRecommendations?.recommendationDate), 'HH:mm/yyyy-MM-dd')
                : '-'
            }`}</Text>
            <Text variant="body1">{`${t('Tidigare')}: ${translateRecommendation(previousRecommendation)}`}</Text>
          </FlexCol>
        </Popper>
      )}
    </Box>
  )
})
Rating.displayName = 'Rating'

type PercentageChangeProps = {
  instrumentIdContainer: InstrumentIdContainer
  period: InfrontChartPeriod
  instrumentType: CommonInstrumentType
  className?: string
}

/**
 * Feedkod: Asset class och marknadsplats
 * Vid lookup krävs: ISIN, valuta, ev primärmarknad
 * En bra approach är att få ticker och feed från Abasec
 */

const PercentageChange = observer(
  ({ instrumentIdContainer, period, instrumentType, className }: PercentageChangeProps) => {
    const { t } = useTranslation()

    const usedField = getQuotePercentagePeriod(period, instrumentType === 'funds')
    const { observableInstrument, isLoading } = useInfrontInstrument(instrumentIdContainer?.infrontInstrument, [
      usedField,
    ])

    const change = observableInstrument?.getFieldValue(usedField)

    const changeValueAnimation = useAnimation()
    const changeFormatted = formatNumber(change)
    const prevChange = usePrevious(changeFormatted)

    useEffect(() => {
      if (prevChange !== changeFormatted) {
        fadeOutIn(changeValueAnimation)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [change])

    return isLoading ? (
      <SkeletonRect height={40} width="25%" className={className} />
    ) : (
      <FlexCol className={className}>
        <Text variant="label1">{t(getPeriodLabel(period, instrumentType))}</Text>
        <FlexRow alignItems="center">
          {period === 'ALL' ? (
            <Tooltip title={t('Ingen data tillgänglig')}>
              <Text variant="subtitle1">-</Text>
            </Tooltip>
          ) : (
            <>
              <motion.div animate={changeValueAnimation} style={{ display: 'flex', alignItems: 'baseline' }}>
                <Text variant="subtitle1" color={getValueColor(change)}>
                  {change ? formatNumber(change) : <NoDataDash />}
                </Text>
                {change !== undefined && change !== null && (
                  <Text variant="label1" ml={2} color={getValueColor(change)}>
                    %
                  </Text>
                )}
              </motion.div>
            </>
          )}
        </FlexRow>
      </FlexCol>
    )
  }
)
PercentageChange.displayName = 'PercentageChange'

type LastPriceProps = {
  instrumentIdContainer: InstrumentIdContainer
  showTooltip?: boolean
  className?: string
  onPriceClick: (price: number) => void
  instrumentType: CommonInstrumentType
}

const LastPrice = observer(
  ({ instrumentIdContainer, showTooltip = true, className, onPriceClick, instrumentType }: LastPriceProps) => {
    const { t } = useTranslation()

    const { observableInstrument, isLoading } = useInfrontInstrument(instrumentIdContainer?.infrontInstrument, [
      InfrontSDK.SymbolField.Last,
      InfrontSDK.SymbolField.PreLastTradedAt,
      InfrontSDK.SymbolField.TradeTime,
      InfrontSDK.BasicField.PriceDecimals,
    ])

    const isIndex = instrumentType === 'index'

    const last = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.Last)
    const preLast = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.PreLastTradedAt)
    const updated = observableInstrument?.getFieldValue(InfrontSDK.SymbolField.TradeTime)

    const priceDecimals = observableInstrument?.getFieldValue(InfrontSDK.BasicField.PriceDecimals)

    const usedLast = last || preLast

    const animation = useAnimation()
    const lastFormatted =
      usedLast !== undefined
        ? formatNumber(usedLast, { decimals: isIndex ? DEFAULT_PRECISION : { min: 2, max: priceDecimals } })
        : usedLast
    const lastPreviousValue = usePrevious(lastFormatted)

    useEffect(() => {
      if (lastPreviousValue !== lastFormatted) {
        fadeOutIn(animation)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [last])

    return (
      <FlexCol
        onClick={onPriceClick ? () => onPriceClick(usedLast) : undefined}
        css={onPriceClick ? { cursor: 'pointer' } : ``}
        className={className}
      >
        {!isLoading ? (
          <>
            <Text variant="label1">{t('Senast')}</Text>
            {showTooltip ? (
              <Tooltip title={updated && InfrontUtil.formatStandardTimestamp(updated)}>
                <Text variant="subtitle1" data-testid="last-price">
                  <motion.div animate={animation}>{lastFormatted ?? <NoDataDash />}</motion.div>
                </Text>
              </Tooltip>
            ) : (
              <Text variant="subtitle1" data-testid="last-price">
                <motion.div animate={animation}>{lastFormatted ?? <NoDataDash />}</motion.div>
              </Text>
            )}
          </>
        ) : (
          <SkeletonRect height="full" width="full" />
        )}
      </FlexCol>
    )
  }
)
LastPrice.displayName = 'LastPrice'

function getQuotePercentagePeriod(period: InfrontChartPeriod, isFund: boolean): InfrontSDK.SymbolField {
  switch (period) {
    case '1D':
      if (isFund) return InfrontSDK.SymbolField.Performance1D

      return InfrontSDK.SymbolField.ChangePercent
    case '5D':
    case '1W':
      if (isFund) return InfrontSDK.SymbolField.Performance1W

      return InfrontSDK.SymbolField.PreChangePercent1W
    case '30D':
    case '1M':
      if (isFund) return InfrontSDK.SymbolField.Performance1M

      return InfrontSDK.SymbolField.PreChangePercent1M
    case '3M':
      if (isFund) return InfrontSDK.SymbolField.Performance3M

      return InfrontSDK.SymbolField.PreChangePercent3M
    case '6M':
      if (isFund) return InfrontSDK.SymbolField.Performance6M

      return InfrontSDK.SymbolField.PreChangePercent6M
    case 'YTD':
      if (isFund) return InfrontSDK.SymbolField.PerformanceSOY

      return InfrontSDK.SymbolField.PreChangePercentYearToDate
    case '1Y':
      if (isFund) return InfrontSDK.SymbolField.Performance1Y

      return InfrontSDK.SymbolField.PreChangePercent1Y
    case '3Y':
      if (isFund) return InfrontSDK.SymbolField.Performance3Y

      return InfrontSDK.SymbolField.PreChangePercent3Y
    case '5Y':
      if (isFund) return InfrontSDK.SymbolField.Performance5Y

      return InfrontSDK.SymbolField.PreChangePercent5Y
    case 'ALL':
      // What to use for funds???

      return InfrontSDK.SymbolField.ChangePercent // TODO: implement correct field infront has implemented it
  }
}

function getPeriodLabel(period: InfrontChartPeriod, instrumentType: CommonInstrumentType) {
  const base = 'Utv.'

  switch (period) {
    case '1D':
      return instrumentType === 'funds' ? `${base} 1 dag` : `${base} idag`
    case '5D':
    case '1W':
      return `${base} 1 vecka`
    case '30D':
    case '1M':
      return `${base} 1 mån`
    case '3M':
      return `${base} 3 mån`
    case '6M':
      return `${base} 6 mån`
    case 'YTD':
      return `${base} i år`
    case '1Y':
      return `${base} 1 år`
    case '3Y':
      return `${base} 3 år`
    case '5Y':
      return `${base} 5 år`
    case 'ALL':
      return `${base} max`
  }
}
