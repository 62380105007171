import { InfrontInstrument } from '@common/api/response'

export function isValidInfrontInstrument(infrontInstrument: InfrontInstrument) {
  return (
    infrontInstrument &&
    infrontInstrument?.feed !== null &&
    infrontInstrument?.feed !== undefined &&
    infrontInstrument?.feed !== 0 &&
    infrontInstrument?.ticker !== null &&
    infrontInstrument?.ticker !== undefined &&
    infrontInstrument?.ticker !== 'null'
  )
}
