import { SkeletonLoader } from '@common/components/SkeletonLoader'
import { useInfrontNews } from '@common/hooks/infront/sdk/useInfrontNews'
import { fireTrackEvent } from '@common/utils/analyticsEvent'

import { observer } from 'mobx-react-lite'

import { MarketNews } from '../../market/news/MarketNews'

export const CorporateEvents = observer(() => {
  const feed = 18045
  const { headlines, error } = useInfrontNews([feed], undefined, 150)

  const headlinesPM = headlines
    ? headlines.filter((newsHeadline) => newsHeadline.infrontNewsHeadline.productCode === 'PM DAGEN,PM').slice(0, 1)
    : headlines

  return (
    <SkeletonLoader
      error={error}
      p={16}
      variant="list"
      loading={!headlinesPM}
      noDataLoaded={headlinesPM && headlinesPM.length === 0}
    >
      {() => <MarketNews onClick={() => fireTrackEvent('Research', 'open_PM')} headlines={headlinesPM} />}
    </SkeletonLoader>
  )
})
