import { DependencyList, useEffect, useRef, useState } from 'react'

import { useInfrontUI } from './useInfrontUI'

function generateLetterString(len: number) {
  let text = ''
  const charset = 'abcdefghijklmnopqrstuvwxyz'
  for (let i = 0; i < len; i++) text += charset.charAt(Math.floor(Math.random() * charset.length))
  return text
}

function useInfrontRef() {
  const ref = useRef(generateLetterString(14))
  return ref.current
}

/**
 * ## ⚠️IMPORTANT⚠️
 * ### Requires wrapping component in MobX `observer(...)`
 */
export function useInfrontWidget<T extends Infront.InfrontWidget>(
  createWidget: (infrontUI: Infront.UI, containerId: string) => T,
  deps?: DependencyList,
  renderForApp?: boolean
) {
  const infrontUi = useInfrontUI()
  const containerId = useInfrontRef()
  const widgetRef = useRef<T>(null)
  const [widgetLoaded, setWidgetLoaded] = useState(false)

  useEffect(() => {
    if (infrontUi) {
      // Make sure id has been assigned to a div etc
      if (!document.getElementById(containerId))
        throw new Error(
          'The widget container id: ' +
            containerId +
            ' could not be found on any element, make sure you have assigned it to an element, for example: <div id={containerId}>...</div>'
        )

      if (!widgetRef.current) {
        widgetRef.current = createWidget(infrontUi, containerId)

        // Hack to get price & amount autofill work in Infronts orderwidget when editing existing order
        // This bug emerges only when react-native-webview is used in conjunction with Infronts orderwidget
        if (renderForApp) {
          setTimeout(() => {
            console.log('Destroy + createWidget(), should Only trigger inside App webview!')
            widgetRef.current.destroy()
            widgetRef.current = createWidget(infrontUi, containerId)
          }, 100)
        }
      }

      setWidgetLoaded(true)

      // This will run when the widget gets destroyed
      return () => {
        if (widgetRef.current) {
          widgetRef.current.destroy()
          widgetRef.current = null
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infrontUi, deps ? JSON.stringify(deps) : undefined]) // By making this a string we avoid some problems with unmemoized objects passed into the deps array

  return { widget: widgetRef.current, containerId: containerId, widgetLoaded }
}
