import { useDrawerProps } from '@common/components/drawer/useDrawerStore'
import { useOpenDrawer } from '@common/components/drawer/useOpenDrawer'

import { NewsDrawerProps } from './NewsDrawer'

export function useOpenNewsDrawer() {
  const { openDrawer } = useOpenDrawer()

  const openNewsDrawer = (
    // To show "my news" leave empty
    newsHeadlineId?: string,
    newsHeadline?: InfrontSDK.NewsHeadline
  ) => {
    const routeProps = convertNewsDrawerPropsToUrl({ newsHeadlineId })

    openDrawer('news', routeProps, { newsHeadline: newsHeadline })
  }

  return { openNewsDrawer }
}

export function useNewsDrawerProps() {
  const drawerProps = useDrawerProps<{ newsHeadline?: InfrontSDK.NewsHeadline }>()

  const newsDrawerProps = convertUrlToNewsDrawerProps(drawerProps.routeProps)
  newsDrawerProps.newsHeadline = drawerProps.props?.newsHeadline

  return newsDrawerProps
}

function convertNewsDrawerPropsToUrl({ newsHeadlineId }: NewsDrawerProps): string {
  if (newsHeadlineId) {
    return newsHeadlineId
  }

  throw new Error('Invalid number of arguments to news drawer props to url')
}

function convertUrlToNewsDrawerProps(urlSegment: string): NewsDrawerProps {
  const parts = urlSegment.split('-')

  if (parts.length === 1) {
    const newsHeadlineId = parts[0]
    return { newsHeadlineId }
  }

  throw new Error('Invalid url arguments to news drawer, the value was: ' + urlSegment)
}
