import { FormattedPercentage } from '@common/components/FormattedNumber'

import { useTranslation } from 'react-i18next'

import { FlexRow, Td, Text, Tr, useDuploTheme } from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import { TableAboutColumnId } from './TableAbout'

export const TableAboutMobileFooter = observer(({ totalWeight }: { totalWeight: number }) => {
  const theme = useDuploTheme()
  const { t } = useTranslation()

  return (
    <>
      <FlexRow
        p={16}
        css={{ borderTop: `1px solid ${theme.colors['bunker-60']}` }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Text variant="support2">{t('Total andel')}</Text>
        <FlexRow justifyContent="space-between" alignItems="baseline">
          <FormattedPercentage value={totalWeight} ratio bold={true} decimals={2} justifyContent={'flex-end'} />
        </FlexRow>
      </FlexRow>
    </>
  )
})

type TableAboutFooterProps = {
  totalWeight: number
  columns: { id?: TableAboutColumnId }[]
}

export const TableAboutFooter = observer(({ totalWeight, columns }: TableAboutFooterProps) => {
  const theme = useDuploTheme()
  const { t } = useTranslation()
  const hasExpanderColumn = columns.some((columnDef) => columnDef.id === 'expander-button')

  return (
    <tfoot css={{ borderTop: `1px solid ${theme.colors['bunker-60']}` }}>
      <Tr>
        {hasExpanderColumn && <Td />}
        <Td textAlign="left" css={{ fontWeight: '500' }}>
          {t('Total')}
        </Td>
        <Td textAlign="left"></Td>
        <Td textAlign="left"></Td>
        <Td textAlign="left"></Td>
        <Td textAlign="right">
          <FormattedPercentage value={totalWeight} ratio bold decimals={2} justifyContent={'flex-end'} />
        </Td>
        <Td textAlign="right"></Td>
      </Tr>
    </tfoot>
  )
})
